// @flow

import { useCallback } from 'react';
import styled from 'styled-components';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

import LineGraph from './line-graph';
import AreaGraph from './area-graph';
import RelativeBarGraph from './rel-bar-graph';
import PieChart from './pie-chart';
import { CHART_TYPE } from '../../constants/graph-options';

import * as QueryResponseListModel from '../../models/query-response-list';
import type { axisTypeT } from '../../utils/graphHelpers';
import type {
  breakdownByEnum,
  aggregationEnum,
  axisGroupingEnum
} from '../../constants/graph-options';
import { breakpoints } from '../../styles/variables';
import { Button } from '@blueprintjs/core';
import { trackEvent, CLICK } from '../../services/mixpanel';

const Tile = styled.div`
  padding: 1rem;
  min-height: 300px;
  border: 1px solid #e6eaee;
  border-top: none;
  background: white;
  border-radius: 0 0 3px 3px;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    padding: 1rem 0;
  }
`;

export type chartDataT = {
  index: string | number,
  compareToPastIndex?: string | number,
  [key: string]: number
};
export type activeLinesT = {
  key: string,
  axisKey: string,
  metricOnlyLabelForAxis: string,
  unitType: string,
  label: string,
  color?: string,
  active: boolean,
  values?: any | QueryResponseListModel.itemT, // "Any" is to support old pages (Demographic Explorer, Compare, Engagement Times, Area Occupancy).
  breakdownBy?: breakdownByEnum,
  demographicType?: string
};
type Props = {
  chartData: chartDataT[],
  pcChartData: chartDataT[],
  activeLines: activeLinesT[],
  chartType: string,
  aggregationPeriod?: aggregationEnum,
  axisGrouping: axisGroupingEnum,
  hasFilterApplied?: boolean,
  axisType?: axisTypeT,
  isCompareToPastActive?: boolean
};

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 10;

  &.hidden {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const GraphTile = ({
  chartData,
  pcChartData,
  activeLines,
  chartType,
  aggregationPeriod,
  axisGrouping,
  hasFilterApplied,
  axisType,
  isCompareToPastActive
}: Props) => {
  const [getAreaPng, { ref: chartRef }] = useCurrentPng();
  const handleAreaDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      FileSaver.saveAs(png, 'chart.png');
    }
  }, [getAreaPng]);

  return (
    <Wrapper>
      <StyledButton onClick={() => {
        trackEvent(CLICK, {
          element: 'graph download',
          context: chartType,
        });
        handleAreaDownload();
      }} icon="floppy-disk" className={chartType === CHART_TYPE.PIE ? 'hidden' : null} />
      <Tile>
        {chartType === CHART_TYPE.LINE && (
          <LineGraph
            chartData={chartData}
            aggregationPeriod={aggregationPeriod}
            axisGrouping={axisGrouping}
            activeLines={activeLines}
            hasFilterApplied={hasFilterApplied}
            axisType={axisType}
            isCompareToPastActive={isCompareToPastActive}
            ref={chartRef}
          />
        )}
        {chartType === CHART_TYPE.AREA && (
          <AreaGraph
            chartData={chartData}
            aggregationPeriod={aggregationPeriod}
            activeLines={activeLines}
            hasFilterApplied={hasFilterApplied}
            axisType={axisType}
            ref={chartRef}
          />
        )}
        {chartType === CHART_TYPE.REL_BAR && (
          <RelativeBarGraph
            chartData={pcChartData}
            activeLines={activeLines}
            aggregationPeriod={aggregationPeriod}
            hasFilterApplied={hasFilterApplied}
            axisType={axisType}
            ref={chartRef}
          />
        )}
        {chartType === CHART_TYPE.PIE && (
          <PieChart
            chartData={chartData}
            activeLines={activeLines}
            hasFilterApplied={hasFilterApplied}
          />
        )}
      </Tile>
    </Wrapper>
  );
};

export default GraphTile;
