import { Fragment } from 'react';
import styled from 'styled-components';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import { tooltipTimeFormatter } from '../../../utils/graphHelpers';

const TooltipWrapper = styled.div`
  padding: 1rem;
  background: var(--dark-blue);
  color: #f0f3f8;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;
const TooltipDate = styled.div`
  font-weight: 600;
`;
const TooltipLabel = styled.div`
  color: #f0f3f8;
  display: flex;
  margin-right: 4px;
  text-transform: capitalize;
`;
const LineRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
`;
const LineColor = styled.div`
  background: ${(props) => props.color};
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin: 0 10px 0 0;
`;

const CustomizedTooltip = ({
  label,
  active,
  payload,
  metrics,
  aggregation
}) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <TooltipDate>{tooltipTimeFormatter(label, aggregation)}</TooltipDate>
        <Fragment>
          {payload.map((data, index) => {
            const { fill, payload } = data;
            const { dataKey, unitType, label } = metrics[index];
            return (
              <LineRow key={index}>
                <LineColor color={fill} />
                <TooltipLabel fill={fill}>
                  {label}:
                  {` ${formatNumbersWithUnits(payload[dataKey], unitType)}`}
                </TooltipLabel>
              </LineRow>
            );
          })}
        </Fragment>
      </TooltipWrapper>
    );
  }
  return null;
};

export default CustomizedTooltip;
