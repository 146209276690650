// @flow

import { useEffect, useState } from 'react';
import { TimePicker } from '@blueprintjs/datetime';
import { Checkbox } from '@blueprintjs/core';
import styled from 'styled-components';

type Props = {
  index: number,
  openingTime: {
    startHour: number,
    startMinute: number,
    stopHour: number,
    stopMinute: number,
  },
  disabled: boolean,
  isClosed?: boolean,
  updateOpeningTime: (number, Object) => void,
  updateIsClosed?: (number, boolean) => void,
};

const Row = styled.div`
  & {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }
  & .time-picker {
    margin-inline-start: 8px;
  }
  & .day-name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30%;
  }`;
const StyledCheckbox = styled(Checkbox)`
  & {
    margin: 0 1em;
  }
`;

const presentationDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'All',
];

const OpeningTime = ({ index, openingTime, disabled, isClosed, updateOpeningTime, updateIsClosed }: Props) => {

  const [notClosedTime, setNotClosedTime] = useState((() => {
    if (!openingTime) {
      return {
        startHour: 9,
        startMinute: 0,
        stopHour: 22,
        stopMinute: 0,
      };
    } else if (
      openingTime.startHour === 0 &&
      openingTime.startMinute === 0 &&
      openingTime.stopHour === 0 &&
      openingTime.stopMinute === 0) {
        return {
          ...openingTime,
          startHour: 9,
          startMinute: 0,
          stopHour: 22,
          stopMinute: 0,
        };
    } else {
      return openingTime;
    }
  })());

  useEffect(() => {
    if (typeof notClosedTime === 'undefined') {
      setNotClosedTime(openingTime);
    }
  }, [notClosedTime, openingTime]);

  const update = (value) => {
    updateOpeningTime(
      index,
      {
        ...openingTime,
        ...value,
      });
  };

  const updateClose = (value) => {
    update({
      ...openingTime,
      stopHour: value.getHours(),
      stopMinute: value.getMinutes(),
    });
  };

  const updateOpen = (value) => {
    update({
      ...openingTime,
      startHour: value.getHours(),
      startMinute: value.getMinutes(),
    });
  };

  const setIsClosed = () => {
    if (isClosed) {
      updateOpeningTime(index, notClosedTime);
    } else {
      updateOpeningTime(index, {
        ...openingTime,
        startHour: 0,
        startMinute: 0,
        stopHour: 0,
        stopMinute: 0,
      });
    }
  };

  const generateDate = (hours, minutes) => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  return (
    <Row>
      <div className="day-name">
        {presentationDays[index] || 'All'}:
      </div>
      { openingTime && (
        <>
          <TimePicker
            className="time-picker"
            disabled={isClosed || disabled}
            // The date is completely ignored as the component only uses the time
            value={generateDate(openingTime.startHour, openingTime.startMinute)}
            onChange={updateOpen}
          />
          <TimePicker
            className="time-picker"
            disabled={isClosed || disabled}
            // The date is completely ignored as the component only uses the time
            value={generateDate(openingTime.stopHour, openingTime.stopMinute)}
            onChange={updateClose}
          />
          {!disabled && typeof isClosed !== 'undefined' && updateIsClosed && (
            <StyledCheckbox
              checked={isClosed}
              label="Closed"
              onChange={(value) => setIsClosed()} />
          )}
        </>
      )}
    </Row>
  );
};

export default OpeningTime;
