import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ResponsiveContainer, PieChart, Pie, Tooltip } from 'recharts';
import CustomTooltip from './tooltip';
import { getChartColors } from '../../styles/variables';
import { demographicValuesSortFn } from '../../utils/graphHelpers';
import { prettyNumber } from '@auravisionlabs/aura-pkg-ui-formatter';
import { formatNumbersWithUnits } from '../../utils/formatNumbers';
import { getUnitTypeFromActiveLine } from '../../selectors/graphSelectors';

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  overflow-y: auto;
  flex-wrap: wrap;
`;
const Chart = styled.div`
  width: 60%;
`;
const Legend = styled.div`
  padding: 0 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LegendItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.5rem;
`;
const LegendCircle = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1rem;
  background: ${props => props.color};
`;
const LegendNumber = styled.div`
  margin: 0.5rem 0;
  font-size: 1rem;
  padding: 0 0.5rem;
`;
const LegendLabel = styled.div`
  font-size: 0.75rem;
  color: var(--mid-grey);
`;

// TODO: fix the ref/graph saving feature

const AuraPie = ({
  chartData,
  activeLines,
  aggregationPeriod,
  hasFilterApplied,
}, chartRef) => {
  const theme = useContext(ThemeContext);
  const chartColors = getChartColors(theme);
  const pieData = activeLines.map(line => ({
    name: line.label,
    // this method below aggregates all values into one pie chart
    // TODO: this assumes that the lines operation is sum, not max. Also it should be aggregated before this point, not in the component itself
    value: chartData.reduce((a, b) => a + (!!b[line.key] ? b[line.key] : 0), 0),
    fill: chartColors[line.key] || line.color,
    key: line.key,
    unitType: getUnitTypeFromActiveLine(line)
  }));

  const PieTooltip = ({ label, payload }) => {
    const activeIndex = payload[0];
    if (!activeIndex) return false;

    const formattedPayload = [
      {
        dataKey: activeIndex.payload.key,
        payload: {
          [activeIndex.payload.key]: activeIndex.value
        },
        color: activeIndex.payload.fill
      }
    ];

    return (
      <CustomTooltip
        label={activeIndex.name}
        formatter={prettyNumber}
        nonDateLabel
        payload={formattedPayload}
        aggregationPeriod={aggregationPeriod}
        hasFilterApplied={hasFilterApplied}
        activeLines={activeLines}
      />
    );
  };

  return (
    <Wrapper>
      <Chart>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart ref={chartRef ? chartRef : null}>
            <Pie data={pieData} dataKey="value"/>
            <Tooltip content={<PieTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Chart>
      <Legend>
        {pieData
          .sort((a, b) => demographicValuesSortFn(a.name, b.name))
          .map(cell => {
            const formattedValue = cell.unitType
              ? formatNumbersWithUnits(cell.value, cell.unitType)
              : prettyNumber(cell.value);
            return (
              <LegendItem key={cell.key}>
                <LegendCircle color={cell.fill} />
                <LegendNumber>{formattedValue}</LegendNumber>
                <LegendLabel>{cell.name}</LegendLabel>
              </LegendItem>
            );
          })}
      </Legend>
    </Wrapper>
  );
};

// $FlowFixMe Flow does not support forwarding refs properly
export default React.forwardRef(AuraPie);
