// @flow

import { useState, useEffect, useCallback } from 'react';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import * as LocationModel from '../../../models/location';
import WidgetUI from '../../widgets-ui/rank';
import { getQueryPayloadType, buildPayload } from '../../../services/query-api';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { prettyTaxonomyLabel } from '../../../utils/taxonomyHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import Pagination from '../../pagination';
import { IS_EMAIL_VIEW } from '../../../config/vars';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';

const RankWidget = ({
  widget,
  availableMetrics,
  locations,
  uiOptions = {},
}: WidgetPropsModel.rankT) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [widgetDescription, setWidgetDescription] = useState('');
  const [metricsForUI, setMetricsForUI] = useState([]);
  const [fetchRequired, setFetchRequired] = useState(false);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible(
    (vi) => uiOptions.disableViewportLoading || vi > 0
  );
  const [visibleLocations, setVisibleLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    IS_EMAIL_VIEW ? -1 : uiOptions.itemsPerPage || -1
  );

  const loadWidgetData = useCallback(
    async (metric) => {
      setLoading(true);
      setError(false);

      // build the request body and fetch data for each metric for each period
      const path = apiEndpointByMetricKey(metric.metricKey);
      const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
        metric.details
      );
      const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

      const commonPayloadInputs = {
        period: widget.period,
        demographicFilter: widget.demographicFilter,
        aggregation: 'day', // we can hardcode for this widget because we know it will use a date range of whole days
        metricKey: metric.metricKey,
      };
      let rows = [];
      let payloadInput = {};

      switch (widget.breakdownBy) {
        case 'location':
        default:
          payloadInput = {
            locations: visibleLocations,
            taxonomies: metric.taxonomies ? metric.taxonomies : undefined,
            breakdownByDimensions: ['entity'],
          };
          rows = visibleLocations.map((locationId) => ({
            title: LocationModel.getLocationName(locationId, locations),
            locationId,
          }));
          break;
        case 'filter':
          const taxonomies = widget.breakdownFilters
            ? widget.breakdownFilters.map(
                (filter) => `${filter.key}:${filter.value}`
              )
            : undefined;
          payloadInput = {
            locations: widget.locations,
            taxonomies: taxonomies,
            breakdownByDimensions: ['taxonomy'],
          };
          rows = widget.breakdownFilters.map((filter) => ({
            title: `${filter.key}: ${filter.value}`,
            filter,
          }));
          break;
        case 'taxonomy':
          payloadInput = {
            locations: widget.locations,
            taxonomies: widget.breakdownTaxonomies,
            breakdownByDimensions: ['taxonomy'],
          };
          rows = widget.breakdownTaxonomies.map((taxonomy) => ({
            title: prettyTaxonomyLabel(taxonomy),
            taxonomy,
          }));
          break;
      }

      const payloadInitialPeriod = buildPayload(queryPayloadType, {
        ...commonPayloadInputs,
        ...payloadInput,
      });

      let builtMetrics = [];

      try {
        const response = await fetchSQLQuery(payloadInitialPeriod, path, {
          returnErrors: true,
          return404AsError: false,
          metricKey: metric.metricKey,
        });

        let compResponse;
        if (widget.comparisonPeriod && widget.comparisonPeriod.active) {
          const payloadComparisonPeriod = buildPayload(queryPayloadType, {
            ...commonPayloadInputs,
            ...payloadInput,
            locations: payloadInput.locations,
            period: widget.comparisonPeriod,
          });

          compResponse = await fetchSQLQuery(
            payloadComparisonPeriod,
            path,
            {
              returnErrors: true,
              return404AsError: false,
              metricKey: metric.metricKey,
            }
          );
        }

        builtMetrics = rows.map((row) => {
          // extract the values and changeValues
          let value = 0;
          let compValue = 0;

          if (widget.breakdownBy === 'location' && row.locationId) {
            value = QueryResponseModel.getSummaryValue({
              response,
              locationId: row.locationId,
              metricKey: metric.metricKey,
            });
            compValue = compResponse
              ? QueryResponseModel.getSummaryValue({
                  response: compResponse,
                  locationId: row.locationId,
                  metricKey: metric.metricKey,
                })
              : 0;
          } else if (widget.breakdownBy === 'filter' && row.filter) {
            value = QueryResponseModel.getSummaryValue({
              response,
              taxonomy: `${row.filter.key}:${row.filter.value}`,
              metricKey: metric.metricKey,
            });
            compValue = compResponse
              ? QueryResponseModel.getSummaryValue({
                  response: compResponse,
                  taxonomy: `${row.filter.key}:${row.filter.value}`,
                  metricKey: metric.metricKey,
                })
              : 0;
          } else if (widget.breakdownBy === 'taxonomy' && row.taxonomy) {
            value = QueryResponseModel.getSummaryValue({
              response,
              taxonomy: row.taxonomy,
              metricKey: metric.metricKey,
            });
            compValue = compResponse
              ? QueryResponseModel.getSummaryValue({
                  response: compResponse,
                  taxonomy: row.taxonomy,
                  metricKey: metric.metricKey,
                })
              : 0;
          }

          const { result } =
            compValue !== null && value !== null
              ? prettyValueToCompare(compValue, value)
              : { result: null };
          const changeValue =
            widget.comparisonPeriod && widget.comparisonPeriod.active
              ? result
              : null;

          // decorate each response with information from availableMetrics, and the API response

          return {
            title: row.title,
            value,
            changeValue,
            unitType: QueryResponseModel.getUnitType(
              response,
              metric.metricKey
            ),
          };
        });
      } catch (e) {
        console.log(e);
      }

      const filteredBuiltMetrics: Array<Object> = builtMetrics.filter(
        (m) => !!m
      );

      if (
        !filteredBuiltMetrics ||
        filteredBuiltMetrics.every((m) => !m.value)
      ) {
        setError(true);
        setLoading(false);
        console.error('Every API call for Rank widget failed');
      } else {
        setMetricsForUI(filteredBuiltMetrics);
        setLoading(false);
      }
    },
    [
      locations,
      widget.breakdownBy,
      widget.breakdownFilters,
      widget.breakdownTaxonomies,
      widget.comparisonPeriod,
      widget.demographicFilter,
      visibleLocations,
      widget.period,
      widget.locations,
    ]
  );

  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      // get the information from available metrics data for the metric
      const metric = {
        ...widget.metric,
        details: AvailableMetricsModel.findMetricDetails(
          availableMetrics,
          widget.metric.metricGroupKey,
          widget.metric.metricKey
        ),
      };

      if (!metric || !metric.details) {
        console.error(
          'No metric found in availableMetrics for the Rank widget'
        );
        setError(true);
      } else {
        const breakdownLabel = {
          taxonomy: 'zone type',
          filter: 'filter',
          location: 'location',
        };

        setAutoTitle(
          `${getColumnLabel(metric, availableMetrics, true, true)}, by ${
            breakdownLabel[widget.breakdownBy]
          }`
        );
        if (!uiOptions.disableDescription) {
          setWidgetDescription(
            AvailableMetricsModel.getMetricDescription(metric.details)
          );
        }
        loadWidgetData(metric);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(() => {
    setFetchRequired(false);

    // get the information from available metrics data for the metric
    const metric = {
      ...widget.metric,
      details: AvailableMetricsModel.findMetricDetails(
        availableMetrics,
        widget.metric.metricGroupKey,
        widget.metric.metricKey
      ),
    };

    if (!metric || !metric.details) {
      console.error('No metric found in availableMetrics for the Rank widget');
      setError(true);
    } else {
      const breakdownLabel = {
        taxonomy: 'zone type',
        filter: 'filter',
        location: 'location',
      };

      setAutoTitle(
        `${getColumnLabel(metric, availableMetrics, true, true)}, by ${
          breakdownLabel[widget.breakdownBy]
        }`
      );
      if (!uiOptions.disableDescription) {
        setWidgetDescription(
          AvailableMetricsModel.getMetricDescription(metric.details)
        );
      }
      loadWidgetData(metric);
    }
  }, [
    widget.metric,
    widget.breakdownBy,
    availableMetrics,
    uiOptions.disableDescription,
    loadWidgetData,
  ]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);
      fetchData();
    }
  }, [fetchRequired, fetchData, isVisible]);

  useEffect(() => {
    const start = page * Math.max(0, itemsPerPage);
    const end =
      itemsPerPage > 0
        ? Math.min(start + itemsPerPage, widget.locations.length)
        : widget.locations.length;
    setVisibleLocations(widget.locations.slice(start, end));
  }, [page, uiOptions, widget.locations, itemsPerPage]);

  useEffect(() => {
    setFetchRequired(true);
  }, [visibleLocations]);

  // Go back to the first page on changing page length
  useEffect(() => {
    setPage(0);
  }, [itemsPerPage, setPage]);

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget);

  // TODO: change UI so that it supports error messages per each row
  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        title={title}
        subtitle={subtitle}
        metrics={metricsForUI}
        sortBy={widget.sortBy}
        description={widgetDescription}
        dataKey="value" // TODO: support this in the props for the widget as a whole?
      >
        <Pagination
          pages={Math.ceil(locations.length / itemsPerPage)}
          setPage={setPage}
          currentPage={page}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
        />
      </WidgetUI>
    </div>
  );
};

export default RankWidget;
