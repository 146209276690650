// @flow

import { Component, Fragment } from 'react';
import styled from 'styled-components';
import DatePicker from './date-picker';
import ComparisonDatePicker from './comparison-date-picker';
import {
  defaultPresetsAllowed,
  defaultPeriodPresetConfig,
  DIVIDER
} from './dates-presets-config';
import * as PREVIOUS_PERIOD_PRESETS from '../../constants/previous-period-presets';
import * as PERIOD_PRESETS from '../../constants/period-presets';

import * as PeriodModel from '../../models/period';
import type {
  defaultPresetsAllowedT,
  periodPresetConfigT
} from './dates-presets-config';
import { withRouter } from 'react-router';
import { IS_EMAIL_VIEW } from '../../config/vars';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${IS_EMAIL_VIEW ? '0' : '0.5rem'};
  justify-content: ${({align}) => align ? align : 'center'};
  color: rgba(0, 0, 0, 0.5);

  & .bp3-button-group {
    justify-content: center;
  }

  & .bp3-button-group .bp3-popover-target {
    flex: none;
  }
`;
const Col = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;
const Text = styled.span`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  margin: ${IS_EMAIL_VIEW ? '0' : '0 1rem'};
`;

type Props = {
  datePresetsAllowed?: defaultPresetsAllowedT,
  currentSelectedDatePreset: PeriodModel.periodPresetsT,
  currentSelectedComparisonDatePreset?: PeriodModel.compareToPastPresetsT,
  handlePresetClick(
    period: PeriodModel.t,
    compareToPeriod: PeriodModel.comparePeriodT
  ): void,
  handleComparisonPresetClick(period: PeriodModel.comparePeriodT): void,
  period: PeriodModel.t,
  compareToPastPeriod: PeriodModel.comparePeriodT,
  disableDatesView?: boolean,
  disableCompareDatesView?: boolean,
  disableComparisonDate?: boolean,
  periodPresetConfig?: periodPresetConfigT,
  location?: Object,
  align?: string,
};

class DateAndComparisonDateSelectors extends Component<Props> {
  handlePresetClickAndUpdateComparison(period: PeriodModel.t) {
    const {
      handlePresetClick,
      currentSelectedComparisonDatePreset = PREVIOUS_PERIOD_PRESETS.none,
      periodPresetConfig,
      compareToPastPeriod
    } = this.props;
    const periodPresetConfigToUse =
      periodPresetConfig || defaultPeriodPresetConfig;

    let newComparePeriod = compareToPastPeriod;

    if (
      currentSelectedComparisonDatePreset &&
      compareToPastPeriod &&
      compareToPastPeriod.active
    ) {
      const presetConfig = periodPresetConfigToUse[period.selectedPreset];
      const match = presetConfig.compPeriodAllowed.includes(
        currentSelectedComparisonDatePreset
      );

      if (!match) {
        const newComparePreset = presetConfig.compPeriodAllowed.filter(
          preset => preset !== DIVIDER
        )[0];
        newComparePeriod = PeriodModel.getExplorerComparePeriod(
          // $FlowFixMe -> Flow thinks that this can be a DIVIDER, but this can't never be a divider, because I'm filtering it before passing it througth here.
          newComparePreset,
          period.selectedDates
        );
      } else if (
        match &&
        currentSelectedComparisonDatePreset !== PREVIOUS_PERIOD_PRESETS.none &&
        currentSelectedComparisonDatePreset !== PREVIOUS_PERIOD_PRESETS.custom
      ) {
        newComparePeriod = PeriodModel.getExplorerComparePeriod(
          currentSelectedComparisonDatePreset,
          period.selectedDates
        );
      }
    }

    handlePresetClick(period, newComparePeriod);
  }

  render() {
    const {
      datePresetsAllowed,
      currentSelectedDatePreset,
      handleComparisonPresetClick,
      currentSelectedComparisonDatePreset = PREVIOUS_PERIOD_PRESETS.none,
      period,
      compareToPastPeriod,
      disableComparisonDate,
      disableDatesView,
      disableCompareDatesView,
      periodPresetConfig,
      align,
    } = this.props;
    const periodPresetConfigToUse =
      periodPresetConfig || defaultPeriodPresetConfig;
    const comparisonDatePresetsAllowed =
      periodPresetConfigToUse[currentSelectedDatePreset].compPeriodAllowed;
    const datePresetsToUse = datePresetsAllowed || defaultPresetsAllowed;
    const datePresetsValidated = disableDatesView
      ? datePresetsToUse
          .filter(preset => preset !== PERIOD_PRESETS.custom)
          .filter(
            (preset, index, list) =>
              preset !== DIVIDER || index !== list.length - 1
          ) // This is to remove the last DIVIDER
      : datePresetsToUse;

    return (
      <Row align={align}>
        <DatePicker
          disableDatesView={disableDatesView}
          datePresetsAllowed={datePresetsValidated}
          currentSelectedDatePreset={currentSelectedDatePreset}
          handlePresetClick={this.handlePresetClickAndUpdateComparison.bind(
            this
          )}
          compareToPastPeriod={compareToPastPeriod}
          period={period}
          periodPresetConfig={periodPresetConfigToUse}
        />
        {!disableComparisonDate && (
          <Fragment>
            <Col>
              <Text>&nbsp;compared to&nbsp;</Text>
            </Col>
            <ComparisonDatePicker
              period={period}
              compareToPastPeriod={compareToPastPeriod}
              disableCompareDatesView={disableCompareDatesView}
              comparisonDatePresetsAllowed={comparisonDatePresetsAllowed}
              currentSelectedComparisonDatePreset={
                currentSelectedComparisonDatePreset
              }
              handleComparisonPresetClick={handleComparisonPresetClick}
              periodPresetConfig={periodPresetConfigToUse}
            />
          </Fragment>
        )}
      </Row>
    );
  }
}

export default withRouter(DateAndComparisonDateSelectors);
