// @flow
import { isEqual } from 'lodash';

export const areTwoArraysEquals = (array1?: any[], array2?: any[]): boolean => {
  if (!array1 && !array2) return true;
  if (!array1 || !array2 || !Array.isArray(array1) || !Array.isArray(array2))
    return false;
  const sortMethod = (a, b) => (a > b ? 1 : -1);
  const sortedArray1 = array1.sort(sortMethod);
  const sortedArray2 = array2.sort(sortMethod);

  return isEqual(sortedArray1, sortedArray2);
};

// Source: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const isEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// The maximum is exclusive and the minimum is inclusive
// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
export const getRandomInt = (min: number, max:  number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};
