// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ORG_HAS_STAFF_DETECTION, DISABLE_AGE_GENDER } from '../constants/features';
import * as Selectors from '../selectors';
import * as PageSettingsActions from '../actions/page-settings';
import SummaryPage from '../components/pages/summary';
import { FETCHING_QUERY_DATA } from '../constants/async-process-names';
import { SUMMARY } from '../constants/page-settings';
import * as QueryResponsesActions from '../actions/query-responses';

function mapStateToProps(state) {
  const queryResponseList = Selectors.getQueryResponseList(state);

  return {
    locations: Selectors.getLocations(state),
    organisationName: Selectors.getOrganisationName(state),
    settings: Selectors.getPageSettings(state, SUMMARY),
    fetchingQueryData: Selectors.asyncProcessHappening(
      state,
      FETCHING_QUERY_DATA
    ),
    showStaffFilters: Selectors.userHasFeature(ORG_HAS_STAFF_DETECTION, state),
    showAgeAndGenderFilters: !Selectors.userHasFeature(DISABLE_AGE_GENDER, state),
    queryResponseList: Selectors.getPageQueryResponseList(
      state,
      queryResponseList,
      SUMMARY
    ),
    availableMetrics: Selectors.getAvailableMetrics(state),
    recordings: Selectors.getRecordings(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      editSettings: PageSettingsActions.editSummaryPageSettings,
      editActiveMetrics: PageSettingsActions.editSummaryPageActiveMetrics,
      clearActiveMetrics: PageSettingsActions.clearSummaryPageActiveMetrics,
      fetchQueryResponses: QueryResponsesActions.fetchQueryResponses,
      clearQueryResponseList: QueryResponsesActions.clearQueryResponseList
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage);
