/* eslint-disable no-undef */

import { USE_SENTRY } from '../config/vars';

export const sentryIdentify = user => {
  if (typeof Raven !== 'undefined' && Raven.setUserContext && USE_SENTRY) {
    const { id, name, email, organisationName, isBeingImpersonated } = user;
    const identifier = isBeingImpersonated ? `IMPERSONATED_${email}` : email;
    Raven.setUserContext({ id, name, email: identifier, organisationName });
  }
};

export const sentryInit = () => {
  if (typeof Raven !== 'undefined' && Raven.config && USE_SENTRY) {
    Raven.config(
      'https://9711a6c8d02c44bdb021cb17951623aa@sentry.io/1193856'
    ).install();
  }
};
