import { filterBreakdownsArray } from '../constants/heatmaps';

// Eventually get showStaffFilters and disableAgeGenderOnFrontEnd from a context rather than redux
const useHeatmapBreakdownOptions = (showStaffFilters, disableAgeAndGenderOnFrontEnd) => {
  let filteredBreakdowns = filterBreakdownsArray;

  if (!showStaffFilters) {
    filteredBreakdowns = filteredBreakdowns.filter(({ key }) => key !== 'role');
  }

  if (disableAgeAndGenderOnFrontEnd) {
    filteredBreakdowns = filteredBreakdowns.filter(({ key }) => key !== 'age' &&
      key !== 'gender');
  }

  return filteredBreakdowns;
}

export default useHeatmapBreakdownOptions;
