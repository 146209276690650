import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';
import { Panel, PanelHeader } from '../../panel';

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const DemographicsPanelHeader = styled(PanelHeader)`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--widget-max-width);
  margin: auto;
`;
export const Wrapper = styled.div`
  flex: 1;
  margin: 0 auto 2rem;
  max-width: var(--widget-max-width);
  width: 100%;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    max-width: 100%;
  }
`;
export const DatePickerWrapper = styled.div`
  display: inline-flex;
`;
const StyledTopBar = styled(Panel)`
  margin: 0 auto;
  width: 100%;
  max-width: var(--widget-max-width);
`;
const StyledTopBarHeader = styled(PanelHeader)`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: var(--widget-box-shadow);
`;
export const TopBar = ({ children }) => (
  <StyledTopBar>
    <StyledTopBarHeader>{children}</StyledTopBarHeader>
  </StyledTopBar>
);
export const DemographicsBar = styled(Panel)`
  width: 100%;
  margin: 0 auto;
  max-width: var(--widget-max-width);
  box-shadow: var(--widget-box-shadow);
`;
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  .bp3-switch {
    margin-bottom: 0;
  }
`;
export const UpperRow = styled(Row)`
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
export const EmailButton = styled.div`
  margin-left: auto;

  @media (max-width: 940px) {
    margin-left: 0;
  }
`;
