import React from 'react';
import styled from 'styled-components';
import { Panel, PanelHeader } from '../panel';
import * as LocationModel from '../../models/location';
import * as RecordingModel from '../../models/recording';
import { FilterOptions } from '.';
import AuraSearch, { AuraSelect } from '../search';
import { breakpoints } from '../../styles/variables';
import { Button, ButtonGroup, Position } from '@blueprintjs/core';
import moment from 'moment';
import { DatePicker } from '@blueprintjs/datetime';
import { Popover2 } from '@blueprintjs/popover2';
import ButtonIconColorer from '../button-icon-colorer';
import {
  filterBreakdownsArray,
  filterTypesArray,
} from '../../constants/heatmaps';
import useHeatmapBreakdownOptions from '../../hooks/use-heatmap-breakdown-options';

const Wrapper = styled.div`
  box-shadow: var(--widget-box-shadow);
`;
const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    justify-content: center;
  }
`;
const SelectorLabel = styled.p`
  margin: 0 1rem 0 0;
`;

const ChevronButton = styled(Button)`
  ${({ marginDirection }) =>
    marginDirection
      ? `margin-${marginDirection}: 0.5rem`
      : 'margin-right: 0.5rem'}
`;

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: max-content;
`;

const Spacer = styled.span`
  width: 1rem;
`;

export const DateAndTypePanel = ({ filterOptions, updateFilterOptions }) => {
  return (
    <Wrapper>
      <Panel>
        <StyledPanelHeader>
          <Container>
            {/* < prev date btn */}
            <ChevronButton
              marginDirection={'right'}
              icon="chevron-left"
              onClick={() =>
                updateFilterOptions(
                  'date',
                  moment(filterOptions.selectedDate)
                    .subtract(1, 'days')
                    .toDate(),
                )
              }
            />
            {/* Date picker */}
            {filterOptions.selectedDate && (
              <ButtonGroup>
                <Popover2
                  content={
                    <DatePicker
                      value={moment(filterOptions.selectedDate).toDate()}
                      // date will be null if the user chooses the same date again
                      onChange={(date) =>
                        date ? updateFilterOptions('date', date) : true
                      }
                      maxDate={moment().subtract(1, 'day').toDate()}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                >
                  <ButtonIconColorer>
                    <Button
                      icon="calendar"
                      rightIcon="caret-down"
                      text={moment(filterOptions.selectedDate).format('MMM Do')}
                    />
                  </ButtonIconColorer>
                </Popover2>
              </ButtonGroup>
            )}
            {/* > next date btn */}
            <ChevronButton
              marginDirection={'left'}
              icon="chevron-right"
              onClick={() =>
                updateFilterOptions(
                  'date',
                  moment(filterOptions.selectedDate).add(1, 'days').toDate(),
                )
              }
              disabled={moment(filterOptions.selectedDate).isAfter(
                moment().subtract(2, 'days').toDate(),
              )}
            />
          </Container>

          {/* Heatmap type btns */}
          <AuraSelect
            activeItem={filterOptions.type}
            items={filterTypesArray}
            textSelector={'display'}
            keySelector={'key'}
            defaultText={'Select Heatmap Type'}
            handleChange={(value) => updateFilterOptions('type', value)}
          />
        </StyledPanelHeader>
      </Panel>
    </Wrapper>
  );
};

type RecordingFilterPanelProps = {
  locations: LocationModel.t[],
  recordings: RecordingModel.t[],
  updateFilterOptions: (filterOptions: FilterOptions) => void,
  showStaffFilters: boolean,
  disableAgeAndGenderOnFrontEnd: boolean,
};

const RecordingFilterPanel = ({
  locations,
  recordings,
  filterOptions,
  updateFilterOptions,
  showStaffFilters,
  disableAgeAndGenderOnFrontEnd,
}: RecordingFilterPanelProps) => {
  return (
    <Wrapper>
      <Panel>
        <StyledPanelHeader>
          <Container>
            {/* Location - search */}
            <AuraSearch
              activeItem={filterOptions.location}
              items={locations}
              textSelector={'name'}
              keySelector={'id'}
              defaultText={'Select Location'}
              handleChange={(location) =>
                updateFilterOptions('location', location)
              }
            />
            <Spacer />
            {/* Recording - search */}
            {filterOptions.location &&
              filterOptions.location.id &&
              recordings && (
                <AuraSearch
                  activeItem={filterOptions.recording}
                  items={recordings}
                  textSelector={'name'}
                  keySelector={'id'}
                  defaultText={'Select Recording'}
                  handleChange={(recording) =>
                    updateFilterOptions('recording', recording)
                  }
                />
              )}
          </Container>
          <Container>
            {/* Select - ages, genders, roles, all  */}
            <SelectorLabel>Show heatmap for:</SelectorLabel>
            <AuraSelect
              activeItem={filterOptions.breakdown}
              items={useHeatmapBreakdownOptions(
                showStaffFilters,
                disableAgeAndGenderOnFrontEnd,
              )}
              textSelector={'display'}
              keySelector={'display'}
              defaultText={'Select Breakdown'}
              handleChange={(value) => updateFilterOptions('breakdown', value)}
            />
          </Container>
        </StyledPanelHeader>
      </Panel>
    </Wrapper>
  );
};

export default RecordingFilterPanel;
