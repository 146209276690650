// @flow

/* TODO: Remove this component and replace all implementations with RowHeatmap */

import RowHeatmap from '../row-heatmap';

import type { unitTypeT } from '../../../models/query-response';

// TODO widgetPropsT is duplicated because importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description?: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean,
  /** 'col' | 'table': whether to apply shading of cells with respect to numbers in the same row or in the whole table */
  shadeBy?: string,
};

type labelT = {
  label: string,
  link?: string,
  description?: string,
};

type Props = widgetPropsT & {
  /** The data to display */
  tableColumns: {
    /** The label to display on the left */
    label: string,
    /** The link for the label on the left */
    link?: string,
    /** The description text */
    description?: string,
    /** The unit type */
    unitType: unitTypeT,
    /** The value to display in the cell */
    rows: {
      value: number,
      changeValue: number,
      unitType: string,
    }[],
  }[],
  /** The rows down the side */
  tableRows: labelT[],
  /** The key from the row data */
  dataKey: string,
  children?: any,
  colorScheme?: string,
};

const transposeRows = (rows, cols) =>
  rows.map((row, index) => ({
    cols: cols.map((col) => col.rows[index]),
    label: row.label,
    description: row.description,
    link: row.link,
  }));

const transposeCols = (cols) =>
  cols.map(({ label, link, description }) => ({
    label,
    link,
    description,
  }));

const ColHeatmap = (props: Props) => (
  <RowHeatmap
    {...props}
    dataKey={props.dataKey}
    tableColumns={transposeCols(props.tableColumns)}
    tableRows={transposeRows(props.tableRows, props.tableColumns)}
    shadeBy="col"
  />
);

export default ColHeatmap;
