
import { sendMessage } from '../services/sql-api';

export const fetchThumbnails = async (
  recordingIds?: Array<string>,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'recording',
      returnEntityType: 'thumbnail',
      entities: undefined,
    };

    if (
      recordingIds &&
      Array.isArray(recordingIds) &&
      recordingIds.length > 0
    ) {
      query.entities = recordingIds;
    }

    const results = await sendMessage('detail', 'list', query);

    if (!results || !Array.isArray(results)) {
      return null;
    }

    return results;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchThumbnailsForRecordings = async (
  recordingIds: Array<string>,
): Object => {
  const results = await sendMessage('detail', 'list', {
    entities: recordingIds,
    entityType: 'recording',
    returnEntityType: 'thumbnail',
  });

  let thumbnailsByRecording = new Map();
  if (results && Array.isArray(results) && results.length > 0) {
    recordingIds.forEach((recordingId) => {
      const thumbnails = results.filter(
        (thumb) => thumb.recording_id === recordingId,
      );

      if (!thumbnailsByRecording.get(recordingId)) {
        thumbnailsByRecording.set(recordingId, []);
      }

      thumbnailsByRecording.set(recordingId, thumbnails);
    });
  }

  return thumbnailsByRecording;
};