// @flow
import type { FluxStandardAction } from 'flux-standard-action';

import * as ACTIONS from '../constants/actions';
import * as UserModel from '../models/user';

export type ReducerType = {
  model: ?UserModel.t
};

export const INITIAL_STATE = {
  model: {}
};

export default function userReducer(
  state: ReducerType = INITIAL_STATE,
  { type, payload: user }: FluxStandardAction<string, UserModel.t, *>
): ReducerType {
  switch (type) {
    case ACTIONS.SET_USER:
      if (!user || !user.model) {
        return state;
      }
      return {
        ...INITIAL_STATE,
        model: user.model
      };

    case ACTIONS.LOGOUT:
    case ACTIONS.REFRESH_USER_ERROR:
      return INITIAL_STATE;

    default:
      return state;
  }
}
