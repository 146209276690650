// @flow

import { useContext } from 'react';
import {
  Switch,
  Button,
  Popover,
  Position,
  PopoverInteractionKind,
  Icon,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import styled, { ThemeContext } from 'styled-components';
import {
  getColumnLabel,
  getColumnLabelAsObject
} from '../../utils/metricsTableHelpers';
import { getDescriptionFromAvailableMetrics } from '../../utils/metricsTableHelpers';
import LocationFilterButtons from '../location-filter-buttons';
import HeadingLabel from '../metrics-table/heading-label';
import ImageDisplayerMenu from '../image-displayer-menu';

import * as LocationModel from '../../models/location';
import * as PageSettingsModel from '../../models/page-settings';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as QueryApiRequestModel from '../../models/query-api-request';
import type { aggregationEnum } from '../../constants/graph-options';
import * as QueryResponseModel from '../../models/query-response';

export const STANDARD_PADDING = '1.5rem';

export const MetricItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  gap: 1rem;
`;
const MetricTitle = styled.div`
  margin: 0;
  align-items: center;
  display: flex;
`;
const Number = styled.p`
  color: var(--gray);
  margin: 0;
`;
const StyledButton = styled(Button)`
  color: var(--gray);
  margin-left: auto;

  &:hover {
    color: red;
  }
`;
const DemographicFilter = styled(Switch)`
  margin: 0;
  flex-shrink: 0;
`;
const ColoredDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 0.5rem;
  flex-shrink: 0;
  flex-grow: 0;
`;
const StyledIcon = styled(Icon)`
  padding: 0.3rem;
  ${({ disabled }) => disabled ? 'opacity: 25%;' : 'opacity: 50%;'}

  ${({ disabled }) =>
    !disabled &&
    `  &:hover {
    opacity: 60%;
  }`}
`;
const IconWrapper = styled.div`
  margin-left: ${STANDARD_PADDING};
  opacity: 50%;

  &:hover {
    cursor: pointer;
    opacity: 60%;
  }
`;

type Props = {
  metricItemConfig: PageSettingsModel.metricItemT,
  index: number,
  availableMetrics: AvailableMetricsModel.t,
  updateMetricsLocations(string[], metricId: ?number): void,
  handleExcludeStaff(index: number): void,
  handleDeleteMetric(index: number): void,
  showStaffFilters: boolean,
  locations: LocationModel.t[],
  queryResponseList: QueryResponseListModel.t,
  aggregation: aggregationEnum,
  period: QueryApiRequestModel.periodT,
  loading?: boolean,
  setMetricPreviewImagesForModal(previews: string[]): void,
};

const TableRowItem = ({
  index,
  metricItemConfig,
  availableMetrics,
  updateMetricsLocations,
  handleDeleteMetric,
  handleExcludeStaff,
  showStaffFilters,
  locations,
  queryResponseList,
  period,
  aggregation,
  loading,
  setMetricPreviewImagesForModal,
}: Props) => {
  const theme = useContext(ThemeContext);
  const {
    metric,
    demographicFilter,
    location: metricLocations
  } = metricItemConfig;
  const labelObject = getColumnLabelAsObject(metric, availableMetrics);
  const color = theme ? theme.chartColors[index % theme.chartColors.length] : null;

  const { metricGroupKey, metricKey, taxonomies, filter } = metric;
  const findCriteria = {
    locations: metricLocations,
    metricGroupKey,
    metricKey,
    taxonomies: taxonomies ? taxonomies : filter ? [`${filter.key}:${filter.value}`] : undefined,
    isComparisonActive: false,
    isAllLocations: false,
    excludeStaff:
      (showStaffFilters &&
      !!demographicFilter &&
      demographicFilter.role === 'customer') || !showStaffFilters,
    aggregation,
    period
  };

  const queryResponse = QueryResponseListModel.findOne(
    findCriteria,
    queryResponseList
  );

  const data = queryResponse ? queryResponse.data : null;

  // TODO: fetch previews from the Core API if necessary
  const previewImageUrls = !!data && QueryResponseModel.getPreviews(data);

  const loadAllImages = () => {
    if (loading || !previewImageUrls || previewImageUrls.length <= 1)
      return null;

    setMetricPreviewImagesForModal(previewImageUrls);
  };

  const description = getDescriptionFromAvailableMetrics(
    metric,
    availableMetrics
  );

  return (
    <MetricItemContainer>
      <ColoredDot color={color} />
      <Number>{index + 1}</Number>
      {previewImageUrls && previewImageUrls.length > 0 && (
        <Popover
          className="bp3-popover-wrapper-custom"
          position={Position.TOP}
          interactionKind={PopoverInteractionKind.HOVER}
          transitionDuration={0}
          hoverCloseDelay={150}
          hoverOpenDelay={0}
          boundary="viewport"
          modifiers={{
            preventOverflow: {
              enabled: true
            },
            hide: { enabled: false }
          }}
          disabled={loading}
          content={
            <ImageDisplayerMenu
              previews={previewImageUrls}
              setMetricPreviewImagesForModal={loadAllImages}
            />
          }
        >
          <StyledIcon iconSize={17} icon="camera" disabled={loading} />
        </Popover>
      )}
      <MetricTitle>
        {labelObject ? (
          <HeadingLabel labelObject={labelObject} color={theme.properties['--gray']} />
        ) : (
          getColumnLabel(metric, availableMetrics)
        )}
        {description && (
          <IconWrapper>
            <Tooltip2 content={description} position={Position.TOP}>
              <Icon icon="help" iconSize={17} />
            </Tooltip2>
          </IconWrapper>
        )}
      </MetricTitle>
      <LocationFilterButtons
        minimalistic
        defaultLabel="All locations"
        locations={locations}
        filteredLocations={metricLocations}
        metricId={index}
        updateFilteredLocations={filteredLocations =>
          updateMetricsLocations(filteredLocations, index)
        }
      />
      {showStaffFilters && (
        <DemographicFilter
          checked={demographicFilter.role === 'customer'}
          label="Exclude staff"
          onChange={() => handleExcludeStaff(index)}
        />
      )}
      <StyledButton
        icon="trash"
        intent=""
        onClick={() => handleDeleteMetric(index)}
      />
    </MetricItemContainer>
  );
};

export default TableRowItem;
