// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import * as AuthActions from '../actions/auth';
import Sidebar from '../components/sidebar';
import { SHOW_HEADCOUNT_PLANNER } from '../constants/features';

function mapStateToProps(state) {
  return {
    // TODO: use react-router-redux to connect the state and match the pathname for getting the active link (or use react router active link?)
    user: Selectors.getUserModel(state),
    showLivePage: false, //Selectors.userHasFeature(SHOW_LIVE_PAGE, state),
    showDemoLivePage: false, //Selectors.userHasFeature(SHOW_DEMO_LIVE_PAGE, state)
    showHeadcountPlanner: Selectors.userHasFeature(
      SHOW_HEADCOUNT_PLANNER,
      state
    ),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return bindActionCreators(
    {
      logout: AuthActions.logout,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
