import Dexie from 'dexie';
import { neverCache } from '../../utils/cacheExclusions';

export let db = null;

export const dropTables = () => {
  if (db && db.isOpen()) {
    return Promise.all([
      db.coreAPICache ? db.coreAPICache.clear() : true,
      db.metricAPICache ? db.metricAPICache.clear() : true,
      db.theme ? db.theme.clear() : true,
    ]);
  }
  return true;
};

export const dropAPICache = () => {
  if (db && db.isOpen()) {
    return Promise.all([
      db.coreAPICache ? db.coreAPICache.clear() : true,
      db.metricAPICache ? db.metricAPICache.clear() : true,
    ]);
  }
  return true;
};

export const localDBInit = async () => {
  if (db && db.isOpen()) return db;
  try {
    db = new Dexie('aura');
    db.version(4).stores({
      theme: 'name',
      coreAPICache: '[path+method+params], timestamp',
      metricAPICache: '[path+payload], timestamp',
    });

    // delete old records
    await db.coreAPICache
      .where('timestamp')
      .below(new Date() - 10800000)
      .delete();
    await db.metricAPICache
      .where('timestamp')
      .below(new Date() - 10800000)
      .delete();

    // delete records that should not have been cached
    await db.coreAPICache.toCollection().modify(function (record) {
      if (neverCache(record.path)) {
        delete this.value;
      }
    });
    await db.metricAPICache.toCollection().modify(function (record) {
      if (neverCache(record.path)) {
        delete this.value;
      }
    });
  } catch (err) {
    console.log(`Failed to init Dexie: ${err}`);
  }
  return db;
};
