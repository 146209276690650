import { Card } from '@blueprintjs/core';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';

const StyledCard = styled(Card)`
  border-top: 0;
  position: relative;
  width: 50%;
  box-shadow: var(--widget-box-shadow);

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    margin-top: -1px;
    left: 0;
    top: 0;
    background: var(--light-blue);
  }
`;
const OccupancyCard = ({ children }) => (
  <StyledCard>
    {children}
  </StyledCard>
);

export default OccupancyCard;
