// From: https://stackoverflow.com/questions/66123016/interpolate-between-two-colours-based-on-a-percentage-value

// extract numeric r, g, b values from `rgb(nn, nn, nn)` string
const getRgb = (color) => {
  let [r, g, b] = color
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((str) => Number(str));
  return {
    r,
    g,
    b,
  };
};

/**
 * Interpolates between two colours
 *
 * @param {int} colorA
 * @param {int} colorB
 * @param {number} mix - 0 -> 1, the mix of colours
 * @returns
 */
const colorInterpolate = (colorA, colorB, mix) => {
  const rgbA = getRgb(colorA),
    rgbB = getRgb(colorB);
  const colorVal = (prop) =>
    Math.round(rgbA[prop] * (1 - mix) + rgbB[prop] * mix);
  return {
    r: colorVal('r'),
    g: colorVal('g'),
    b: colorVal('b'),
  };
};

export const getInterpolatedColor = (colorA, colorB, mix) => {
  const color = colorInterpolate(colorA, colorB, mix);
  return `rgb(${color.r}, ${color.g}, ${color.b})`;
};
