import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IS_EMAIL_VIEW } from '../config/vars.js';

const footers = {
  o2: require('../components/footers/o2.js').default,
};

const Footer = () => {
  const theme = useContext(ThemeContext);

  if (!IS_EMAIL_VIEW && theme && theme.name && footers[theme.name]) {
    const CustomFooter = footers[theme.name];
    return <CustomFooter />;
  }
  return null;
};

export default Footer;
