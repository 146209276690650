// @flow

import { Component } from 'react';
import styled from 'styled-components';
import { Popover, ButtonGroup, Button, Position } from '@blueprintjs/core';
import { getDateRangeLabelFormat } from '../../utils/dates';
import DatePickerMenu from './date-picker-menu';
import DatePickerWithConfirm from './date-picker-with-confirm';
import { IS_EMAIL_VIEW } from '../../config/vars';

import * as PeriodModel from '../../models/period';
import type {
  defaultPresetsAllowedT,
  periodPresetConfigT,
} from './dates-presets-config';

export const Backdrop = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;
const StyledButton = styled(Button)`
  font-size: 12px;
`;
const StyledButtonGroup = styled(ButtonGroup)`
  flex-wrap: wrap;
`;

type Props = {
  datePresetsAllowed: defaultPresetsAllowedT,
  currentSelectedDatePreset: PeriodModel.periodPresetsT,
  handlePresetClick(period: PeriodModel.t): void,
  period: PeriodModel.t,
  disableDatesView?: boolean,
  periodPresetConfig: periodPresetConfigT,
};

type State = {
  isDatePickerMenuOpen: boolean,
  isDatePickerWithConfirmOpen: boolean,
};

class DatePicker extends Component<Props, State> {
  state = {
    isDatePickerMenuOpen: false,
    isDatePickerWithConfirmOpen: false,
  };

  setIsDatePickerWithConfirmOpen(value: boolean) {
    this.setState({
      isDatePickerWithConfirmOpen: value,
    });
  }

  setIsDatePickerMenuOpen(value: boolean) {
    this.setState({
      isDatePickerMenuOpen: value,
    });
  }

  handleIsDatePickerWithConfirmOpen() {
    const { isDatePickerWithConfirmOpen, isDatePickerMenuOpen } = this.state;

    if (isDatePickerMenuOpen) {
      this.setIsDatePickerMenuOpen(false);
    }

    this.setIsDatePickerWithConfirmOpen(!isDatePickerWithConfirmOpen);
  }

  handleIsDatePickerMenuOpen() {
    const { isDatePickerMenuOpen, isDatePickerWithConfirmOpen } = this.state;

    if (isDatePickerWithConfirmOpen) {
      this.setIsDatePickerWithConfirmOpen(false);
    }

    this.setIsDatePickerMenuOpen(!isDatePickerMenuOpen);
  }

  handlePresetClick(period: PeriodModel.t) {
    const { handlePresetClick } = this.props;

    this.setIsDatePickerMenuOpen(false);

    handlePresetClick(period);
  }

  render() {
    const {
      datePresetsAllowed,
      currentSelectedDatePreset,
      handlePresetClick,
      period,
      disableDatesView,
      periodPresetConfig,
    } = this.props;
    const { isDatePickerMenuOpen, isDatePickerWithConfirmOpen } = this.state;
    const presetConfig = periodPresetConfig[currentSelectedDatePreset];
    const rangeDateLabel = getDateRangeLabelFormat(
      period.selectedDates,
      presetConfig
    );

    return (
      <>
        {(isDatePickerMenuOpen || isDatePickerWithConfirmOpen) && (
          <Backdrop
            onClick={() => {
              this.setIsDatePickerWithConfirmOpen(false);
              this.setIsDatePickerMenuOpen(false);
            }}
          />
        )}
        <StyledButtonGroup>
          {IS_EMAIL_VIEW ? (
            <span>{presetConfig.title}</span>
          ) : (
            <Popover
              isOpen={isDatePickerMenuOpen}
              content={
                <DatePickerMenu
                  datePresetsAllowed={datePresetsAllowed}
                  currentSelectedDatePreset={currentSelectedDatePreset}
                  handlePresetClick={this.handlePresetClick.bind(this)}
                  handleOpenDatePickerWithConfirm={this.handleIsDatePickerWithConfirmOpen.bind(
                    this
                  )}
                  periodPresetConfig={periodPresetConfig}
                  currentPeriod={period}
                />
              }
              position={Position.BOTTOM}
            >
              <StyledButton
                icon="calendar"
                rightIcon="chevron-down"
                text={presetConfig.title}
                onClick={this.handleIsDatePickerMenuOpen.bind(this)}
              />
            </Popover>
          )}
          {!disableDatesView && (
            <Popover
              isOpen={isDatePickerWithConfirmOpen}
              content={
                <DatePickerWithConfirm
                  // $FlowFixMe -> Cannot create `DatePicker` element because property `customPeriodDates` is missing in  `explorerComparePeriodT` [1] but exists in  `explorerPeriodT` [2] in the first argument of property `handlePresetClick`.
                  handlePresetClick={handlePresetClick}
                  handleOpenDatePickerWithConfirm={this.handleIsDatePickerWithConfirmOpen.bind(
                    this
                  )}
                  currentPeriod={period}
                />
              }
              position={Position.BOTTOM}
            >
              <StyledButton
                text={rangeDateLabel}
                onClick={this.handleIsDatePickerWithConfirmOpen.bind(this)}
              />
            </Popover>
          )}
        </StyledButtonGroup>
      </>
    );
  }
}

export default DatePicker;
