import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { logrocketIdentify } from '../services/logrocket';
import { sentryIdentify } from '../services/sentry';
import { mixpanelIdentify } from '../services/mixpanel';

import reducers from '../reducers';
import { hydrate } from '../services/hydration';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

const hydratedStore = hydrate() || {};

if (hydratedStore && hydratedStore.user && hydratedStore.user.model) {
  logrocketIdentify(hydratedStore.user.model);
  sentryIdentify(hydratedStore.user.model);
  mixpanelIdentify(hydratedStore.user);
}

const store = createStore(
  reducers,
  hydratedStore,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
