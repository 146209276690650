// @flow

import { errorToast } from '../utils/toaster';
import { getAuth0Client } from './auth0';
import history from '../root/history';
import { LOGOUT } from '../constants/routes';

type callArgs = {
  path: string,
  fetchOptions?: Object,
  otherOptions?: Object,
  responseExpected?: boolean,
  responseMethod?: 'json' | 'blob'
};

const call = async ({
  path,
  fetchOptions,
  otherOptions = {},
  responseExpected = true,
  responseMethod = 'json'
}: callArgs): Promise<any> => {
  const fetchOpts = {
    ...fetchOptions,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: undefined
    }
  };
  let res = null;

  const auth0Client = getAuth0Client();
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    if (token) {
      fetchOpts.headers.Authorization = `Bearer ${token}`;
    }
  }

  res = await fetch((path: string), (fetchOpts: Object));

  if (res) {
    if (res.ok && responseExpected) {
      // $FlowFixMe Flow doesnt know if a response can have a json or blob method
      return await res[responseMethod]();
    } else if (res.ok && !responseExpected) {
      return true;
    }

    // if response is 401 show a toast prompting the user to log back in
    if (res.status === 401 && !otherOptions.disable401Toast) {
      errorToast({
        message: 'Couldn\'t authenticate. You probably need to log back in',
        action: {
          text: 'Log in',
          onClick: e => history.push(LOGOUT)
        },
        timeout: 0
      });
    }

    if (!otherOptions.disableErrorToasts) {
      errorToast({
        message: `Sorry, something went wrong. This has been logged (code: ${res.statusText})`
      });
    }
    console.error('Error response', res);
    console.error('Error response body', await res.json());
    throw new Error('The HTTP call returned an error');
  }
};

export { call };
