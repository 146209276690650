import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAuth0Client } from '../../../services/auth0';

const Wrapper = styled.div`
  word-break: break-all;
  max-width: var(--widget-max-width);
  background: var(--widget-background);
  border-radius: var(--widget-border-radius);
  margin: 2rem auto;
  padding: 2rem;
`;

const ViewToken = () => {
  const [token, setToken] = useState(null);
  const auth0Client = getAuth0Client();

  useEffect(() => {
    const getToken = async () => {
      setToken(await auth0Client.getTokenSilently());
    };

    getToken();
  }, [auth0Client]);

  return <Wrapper>{token}</Wrapper>;
};

export default ViewToken;
