// @flow

import { useState, useEffect } from 'react';
import moment from 'moment';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import WidgetUI from '../../widgets-ui/calendar-heatmap';
import {
  getQueryPayloadType,
  buildPayload
} from '../../../services/query-api';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';

const CalendarHeatmapWidget = ({
  widget,
  availableMetrics,
  uiOptions = {}
}: WidgetPropsModel.calendarHeatmapT) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [widgetDescription, setWidgetDescription] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [unitType, setUnitType] = useState('number');
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible((vi) => uiOptions.disableViewportLoading || vi > 0);
  const loadWidgetData = async metric => {
    setLoading(true);
    setError(false);

    const path = apiEndpointByMetricKey(metric.metricKey);
    const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
      metric.details
    );
    const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

    let taxonomies;
    if (metric.taxonomy) {
      taxonomies = [metric.taxonomy];
    } else if (metric.filter) {
      taxonomies = [`${metric.filter.key}:${metric.filter.value}`];
    }

    const payloadInput = {
      period: widget.period,
      locations: widget.locations,
      taxonomies,
      demographicFilter: widget.demographicFilter,
      aggregation: 'day', // this has to be day for this component to be work
      metricKey: metric.metricKey
    };

    const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

    try {
      const response = await fetchSQLQuery(payloadInitialPeriod, path, {
        returnErrors: true,
        return404AsError: false,
        metricKey: metric.metricKey,
      });

      let builtRows = [];

      if (response.segments) {
        response.segments.forEach(segment => {
          const { index, overallValue } = segment;
          const day = moment(index)
            .format('ddd')
            .toLowerCase();

          // get the monday for this week
          let weeksMonday = moment(index).day(1);
          if (day === 'sun') weeksMonday = weeksMonday.subtract(7, 'days');
          const weeksMondayFormatted = weeksMonday.format('YYYY-MM-DD');

          const rowIndex = builtRows.findIndex(
            r => r.label === weeksMondayFormatted
          );

          if (rowIndex > -1) {
            builtRows[rowIndex].cols.push({
              name: day,
              value: overallValue
            });
          } else {
            builtRows.push({
              label: weeksMondayFormatted,
              cols: [
                {
                  name: day,
                  value: overallValue
                }
              ]
            });
          }
        });
      }

      if (builtRows.length > 0) {
        const sortedRows: Object[] = builtRows.sort((a, b) =>
          a.label > b.label ? 1 : -1
        );

        setTableRows(sortedRows);
        setUnitType(QueryResponseModel.getUnitType(response, metric.metricKey));
        setLoading(false);
      } else {
        // TODO: show a no data found error instead of a generic error
        setError(true);
        setLoading(false);
      }
    } catch (e) {
      setError(true);
      setLoading(false);
      console.error('API call for Calendar Heatmap widget failed');
    }
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      const metric = {
        ...widget.metric,
        details: AvailableMetricsModel.findMetricDetails(
          availableMetrics,
          widget.metric.metricGroupKey,
          widget.metric.metricKey
        )
      };

      if (!metric || !metric.details) {
        console.error(
          'No metric found in availableMetrics for the Calendar Heatmap widget'
        );
        setError(true);
      } else {
        setAutoTitle(`${getColumnLabel(metric, availableMetrics, true, true)}`);
        if (!uiOptions.disableDescription) {
          setWidgetDescription(
            AvailableMetricsModel.getMetricDescription(metric.details)
          );
        }
        loadWidgetData(metric);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget);

  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        title={title}
        description={widgetDescription}
        subtitle={subtitle}
        unitType={unitType}
        tableRows={tableRows}
      />
    </div>
  );
};

export default CalendarHeatmapWidget;
