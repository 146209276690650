// @flow

import { uniqBy, flatten, groupBy, pick } from 'lodash';
import * as HeatmapModel from './heatmap';
import * as LocationModel from './location';
import * as CameraServerModel from './cameraServer';
import { THUMBNAILER_BASE } from '../config/vars';

export type uploadingScheduleItemT = {
  day: string,
  start: string,
  stop: string
};
export type uploadingScheduleT = uploadingScheduleItemT[];
export type areaContextsItemsT = {
  activeDates: Object,
  areaId: string,
  createdAt: string,
  id: string,
  locationEntry: Object,
  recording: string,
  taxonomies: string[],
  type: string
};
export type areaContextsT = areaContextsItemsT[];
export type t = {
  id: string,
  name: string,
  timezone: string,
  heatmaps: HeatmapModel.t[],
  location: LocationModel.t,
  areaContexts?: ?areaContextsT,
  uploadingSchedule: ?uploadingScheduleT,
  cameraServer: string
};

export const create = (args: t): t => args;

export const findById = (recordings: t[], id?: string): ?t => {
  if (!id) return null;
  return recordings.find(r => r.id === id);
};

export const findManyById = (recordings: t[], ids: string[]): t[] => {
  return recordings.filter(r => ids.includes(r.id));
};

export const getTimezone = ({ timezone }: t): string =>
  timezone || 'Europe/London';

export const getHeatmaps = ({ heatmaps }: t): Object[] => heatmaps || [];

export const matchLocationId = (recording: t, locationId: string): boolean => {
  return recording.location.id === locationId;
};

export const getRecordingsForLocation = (
  recordings: t[],
  location: LocationModel.t
): t[] => {
  return recordings.filter(rec => matchLocationId(rec, location.id));
};

/**
 * Converts a list of recordings to a object of recordings sorted into location buckets.
 *
 * @param {t[]} recordings An array of recordings
 * @returns {Object}
 */
export const getRecordingsForAllLocations = (recordings: t[]): Object => {
  const locationRecordings = {};
  recordings.forEach((recording: t) => {
    const locationId = recording.location.id;
    if (!locationRecordings[locationId]) {
      locationRecordings[locationId] = [];
    }
    locationRecordings[locationId].push(recording);
  });
  return locationRecordings;
};

export const getRecordingsForCameraServer = (
  recordings: t[],
  cameraServer: CameraServerModel.t
): t[] => {
  return recordings.filter(rec => rec.cameraServer === cameraServer.id);
};

// TODO: fix this to fix live occupancy pages
// export const getTaxonomyContextsFromRecordings = (
//   recordings: t[]
// ): areaContextsT => {
//   const contexts = flatten(
//     recordings.map(rec => rec.areaContexts).filter(item => !!item)
//   );
//   return uniqBy(
//     contexts.filter(ctx => ctx.type === 'taxonomy'),
//     'id'
//   );
// };

export const getImageUrlsFromAreaContexts = (contexts: areaContextsT) => {
  const urlBase = `${THUMBNAILER_BASE}/v1/contexts`;
  const areaContextsByRec = groupBy(contexts, 'recording');
  // $FlowIgnore
  return Object.entries(areaContextsByRec).map(([recId, contexts]) => {
    // $FlowIgnore
    const trimmedContexts = contexts.map(c =>
      pick(c, ['areaId', 'taxonomy', 'id'])
    );
    return `${urlBase}/${recId}?areaContexts=${encodeURIComponent(
      JSON.stringify(trimmedContexts)
    )}`;
  });
};
