import styled from 'styled-components';

const UptimeCircleIndicator = styled.div`
  background-color: ${({ background }) => background};
  border-radius: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BinaryUptimeIndicator = ({ status }: { status: boolean }) => {
  return (
    <Wrapper>
      <UptimeCircleIndicator
        background={status ? 'var(--ok)' : 'var(--error)'}
      />
    </Wrapper>
  );
};
