// @flow

import { Component } from 'react';
import { Table, TableWrapper } from './table';
import MetricsChart from './metrics-chart';
import { metricsTableGridMaker } from '../../utils/metricsTableHelpers';
import LocationThumbnailTableMenu from '../locations-thumbnails-table-menu';
import { LOCATIONS, METRICS } from '../../utils/metricsTableHelpers';

import type { breakdownByEnum } from '../../constants/graph-options';
import type { sortByT } from './index';
import * as LocationModel from '../../models/location';
import * as ActiveMetricsModel from '../../models/active-metrics';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as RecordingModel from '../../models/recording';
import * as PeriodModel from '../../models/period';

type Props = {
  period: PeriodModel.t,
  compareToPeriod?: PeriodModel.comparePeriodT,
  useInvertedMetrics: boolean,
  isComparisonActive: boolean,
  breakdownBy: breakdownByEnum,
  locations: LocationModel.t[],
  handleSortBy: (sortBy: sortByT) => void,
  sortBy: sortByT,
  availableMetrics: AvailableMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  filteredLocations: string[],
  activeMetrics: ActiveMetricsModel.t,
  recordings: RecordingModel.t[],
  excludeStaff: boolean,
  toggleLocation: (location: string | string[]) => void,
  locationsSelectedForComparison: Array<string>,
  showAllLocationsTotal: boolean,
};

type State = {
  thumbnailPreviewsForMetrics: string[]
};

class TableBody extends Component<Props, State> {
  state = {
    thumbnailPreviewsForMetrics: []
  };

  setMetricPreviewImagesForModal = (previewsImages?: string[]) => {
    if (previewsImages) {
      this.setState({
        thumbnailPreviewsForMetrics: previewsImages
      });
    }
  };

  render() {
    const {
      activeMetrics,
      queryResponseList,
      filteredLocations,
      locations,
      availableMetrics,
      breakdownBy,
      handleSortBy,
      sortBy,
      recordings,
      isComparisonActive,
      useInvertedMetrics,
      period,
      compareToPeriod,
      excludeStaff,
      toggleLocation,
      locationsSelectedForComparison,
      showAllLocationsTotal,
    } = this.props;

    const activeMetricsHeadings = ActiveMetricsModel.getColumnsFromActiveMetrics(
      activeMetrics
    );
    const locationHeadingConfig = {
      isLocationHeading: true,
      metricGroupKey: LOCATIONS,
      metricKey: LOCATIONS
    };
    const metricHeadingConfig = {
      isMetricHeading: true,
      metricGroupKey: METRICS,
      metricKey: METRICS
    };

    const useSplits = breakdownBy !== 'total_only';

    const tableMetricsGridConfig = {
      excludeStaff,
      period: period.selectedDates,
      compareToPeriod: compareToPeriod ? compareToPeriod.selectedDates : {},
      recordings,
      useSplits,
      queryResponseList,
      filteredLocationList: filteredLocations,
      locations,
      isComparisonActive,
      activeMetricsHeadings,
      breakdownBy
    };

    const tableMetricsTotalConfig = {
      ...tableMetricsGridConfig,
      isTotalsMetrics: true
    };

    const tableMetricsGrid =
      activeMetrics && compareToPeriod
        ? // $FlowFixMe -> Flow thinks that compareToPeriod can be undefined and can not be passed through metricsTableGridMaker()
          metricsTableGridMaker(tableMetricsGridConfig)
        : [];

    const tableMetricsTotal =
      filteredLocations.length > 1 && activeMetricsHeadings
        ? // $FlowFixMe -> Flow thinks that compareToPeriod can be undefined and can not be passed through metricsTableGridMaker()
          metricsTableGridMaker(tableMetricsTotalConfig)
        : [];

    const { thumbnailPreviewsForMetrics } = this.state;

    return (
      <TableWrapper>
        <LocationThumbnailTableMenu
          setMetricPreviewImagesForModal={this.setMetricPreviewImagesForModal.bind(
            this
          )}
          previewImages={thumbnailPreviewsForMetrics}
        />
        <Table>
          <MetricsChart
            sortBy={sortBy}
            locationHeadingConfig={locationHeadingConfig}
            metricHeadingConfig={metricHeadingConfig}
            // $FlowFixMe -> Not Sure how to fix this
            activeMetricsHeadings={activeMetricsHeadings}
            handleSortBy={handleSortBy}
            tableMetricsTotal={tableMetricsTotal}
            availableMetrics={availableMetrics}
            numberActiveLocations={filteredLocations.length}
            breakdownBy={breakdownBy}
            setMetricPreviewImagesForModal={this.setMetricPreviewImagesForModal.bind(
              this
            )}
            toggleLocation={toggleLocation}
            locationsSelectedForComparison={locationsSelectedForComparison}
            excludeStaff={excludeStaff}
            period={period}
            tableMetricsGrid={tableMetricsGrid}
            isComparisonActive={isComparisonActive}
            useInvertedMetrics={useInvertedMetrics}
            activeMetrics={activeMetrics}
            filteredLocations={filteredLocations}
            compareToPastPeriod={compareToPeriod}
            showAllLocationsTotal={showAllLocationsTotal}
          />
        </Table>
      </TableWrapper>
    );
  }
}

export default TableBody;
