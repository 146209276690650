// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SingleLocationLiveOccupancyPage from '../components/pages/single-location-live-occupancy';
import * as Selectors from '../selectors';
import { IS_DEMO_USER } from '../constants/features';

import type { Dispatch } from 'redux';

function mapStateToProps(state, ownProps) {
  return {
    location: Selectors.getLocationById(
      state,
      ownProps.match.params.locationId
    ),
    recordings: Selectors.getRecordings(state),
    isDemoUser: Selectors.userHasFeature(IS_DEMO_USER, state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleLocationLiveOccupancyPage);
