import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 70%;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: 80%;
  }
`;
const Highlights = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    flex-direction: column;
  }
`;

const SmallSpan = styled.span`
  font-size: 0.4em;
  font-weight: normal;
`;
const LiveFigure = styled.div`
  margin: 1em 0;
  font-size: 2.2em;
  font-weight: bold;
  color: #19212F;
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
`;
const ColouredH3 = styled.div`
  color: ${(props) => props.color};
`;
const ProgressBarWrapper = styled.div`
  padding: 0 1em;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
`;

export {
  Wrapper,
  Highlights,
  SmallSpan,
  LiveFigure,
  ColouredH3,
  ProgressBarWrapper,
  TitleWrapper,
};
