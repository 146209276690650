// @flow

import { Component } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { Switch, Button, H1 } from '@blueprintjs/core';

import WidgetGrid from './widget-grid';
import ReportTabs from './report-tabs';
import TopBarLoading from '../../top-bar-loading';
import LocationFilterButtons from '../../location-filter-buttons';
import Layout from '../../layouts/default-logged-in';
import EmailLayout from '../../layouts/email';
import ScheduledReportJobExplorer from '../../scheduled-report-job-explorer';
import {
  Row,
  DemographicsPanelHeader,
  TopBar,
  DemographicsBar,
  SwitchContainer,
  UpperRow,
  EmailButton,
} from '../summary/layout';

import * as Selectors from '../../../selectors';
import * as ReportModel from '../../../models/report';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';
import * as PERIOD_PRESETS from '../../../constants/period-presets';
import { updateReportConfigByHierarchy } from '../../../utils/reports';

import * as PeriodModel from '../../../models/period';
import * as LocationModel from '../../../models/location';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import type { BrowserHistory } from 'history/createBrowserHistory';
import { IS_EMAIL_VIEW } from '../../../config/vars';
import { Tablet } from '../../layouts/devices-sizes';
import styled from 'styled-components';

type State = {
  report: ReportModel.t,
  isScheduledReportJobExplorerOpen: boolean
};

type Props = {
  history: BrowserHistory,
  allReports: ReportModel.t[],
  startingReport: ReportModel.t,
  allLocationsList: LocationModel.t[], // TODO: need to supply this, and use it in the locations selector
  fetchingQueryData: boolean, // TODO: need to supply this, and also fire an action every time a widget starts/ends a request
  availableMetrics: AvailableMetricsModel.t,
  showStaffFilters: boolean,
  startOfWeek: 'monday' | 'sunday',
};

const StyledH1 = styled(H1)`
  color: var(--report-heading-text);
  font-family: var(--fonts-heading);
  max-width: var(--widget-max-width);
  width: 100%;
  text-align: center;
  margin: 0.5rem auto 2rem;
`;

const LocationCountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: var(--widget-max-width);
  margin: 1rem auto 2rem;
`;

const LocationCount = styled.div`
  color: rgba(0,0,0,0.5);
  text-align: center;
  padding: 1rem;
  font-size: 14px;
  max-width: 30rem;
`;

class SingleReportPage extends Component<Props, State> {
  state = {
    report: {},
    isScheduledReportJobExplorerOpen: false
  };

  componentDidMount() {
    try {
      trackEvent(ENTER_PAGE, 'REPORTS');
      if (!this.props.startingReport) return false;
      this.setState({ report: cloneDeep(this.props.startingReport) });
    } catch (e) {
      console.error(e);
    }
  }

  componentDidUpdate() {
    try {
      const { startingReport = {} } = this.props;
      const { report = {} } = this.state;

      if (!startingReport || !report) return false;

      if (startingReport.id !== report.id) {
        this.setState({ report: cloneDeep(startingReport) });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleTopLevelExcludeStaff() {
    const excludeStaff = Selectors.isDemographicFilterRoleCustomer(
      this.state.report.config
    ) || !this.props.showStaffFilters;

    // toggle the demographicFilter - if already enabled revert to an empty object othweise add customer only filter
    const newValue = excludeStaff ? {} : { role: 'customer' };

    this.updateReportConfig({
      property: 'demographicFilter',
      newValue,
      hierarchyLevel: 0,
      hierarchyIndex: 0
    });
  }

  updateReportConfig({
    property,
    newValue,
    hierarchyLevel,
    hierarchyIndex
  }: {
    property: string,
    newValue: any,
    hierarchyLevel: number,
    hierarchyIndex: number
  }) {
    if (isEmpty(this.state.report)) return;

    const report = updateReportConfigByHierarchy(
      this.state.report,
      property,
      newValue,
      hierarchyLevel,
      hierarchyIndex
    );

    this.setState({ report });
  }

  openScheduledReportJobExplorer() {
    this.setState({ isScheduledReportJobExplorerOpen: true });
  }
  closeScheduledReportJobExplorer() {
    this.setState({ isScheduledReportJobExplorerOpen: false });
  }

  render() {
    const {
      allReports,
      history,
      allLocationsList,
      fetchingQueryData = false, // TODO: get this data somehow. Can't use normal way as API fetches aren't going through redux actions. Need to dispatch load events when doing API calls
      availableMetrics,
      showStaffFilters,
      startOfWeek,
    } = this.props;
    const { report = {}, isScheduledReportJobExplorerOpen } = this.state;

    const { config: reportConfig = {} } = report || {};

    const getHierarchyConfig = (
      parent: Object = {},
      child: Object = {}
    ): Object => {
      return {
        locations: child.locations || parent.locations,
        period: child.period || parent.period,
        comparisonPeriod: child.comparisonPeriod || parent.comparisonPeriod,
        demographicFilter: child.demographicFilter || parent.demographicFilter
      };
    };

    const transformHierarchyConfig = (config: Object): Object => {
      // get the dateoverride from the URL, if present
      const params = (new URL(window.document.location)).searchParams;
      const overrideDateString = params.get('overrideDate');
      let overrideDate;
      if (overrideDateString) {
        overrideDate = new Date(overrideDateString);
      }

      // set a hard default to stop flow complaining
      let period = PeriodModel.getExplorerPeriod(PERIOD_PRESETS.lastFullDay, overrideDate);
      // set no default comp period
      let comparisonPeriod = {};

      if (config.period) {
        // if the period is custom then it should already have the dates
        if (
          config.period.selectedPreset === PERIOD_PRESETS.custom &&
          config.period.selectedDates
        ) {
          period = config.period;
        } else {
          period = PeriodModel.getExplorerPeriod(config.period.selectedPreset, overrideDate);
        }
      }

      if (config.comparisonPeriod && period && period.selectedDates) {
        comparisonPeriod = PeriodModel.getExplorerComparePeriod(
          config.comparisonPeriod.selectedPreset,
          period.selectedDates
        );
      }

      return {
        locations:
          config.locations === 'all'
            ? allLocationsList.map(l => l.id)
            : config.locations,
        period,
        comparisonPeriod,
        demographicFilter: config.demographicFilter
      };
    };

    const hierarchyConfig = getHierarchyConfig(reportConfig);
    const topLevelConfig = transformHierarchyConfig(hierarchyConfig);

    if (IS_EMAIL_VIEW)
      return (
        <EmailLayout>
          <WidgetGrid
            report={report}
            getHierarchyConfig={getHierarchyConfig}
            transformHierarchyConfig={transformHierarchyConfig}
            updateReportConfig={this.updateReportConfig.bind(this)}
            availableMetrics={availableMetrics}
            allLocationsList={allLocationsList}
            startOfWeek={startOfWeek}
          />
          {allLocationsList && topLevelConfig && topLevelConfig.locations &&
          <LocationCountWrapper>
            <LocationCount>
              This report covers {topLevelConfig.locations.length} of {allLocationsList.length} locations.<br/><br/>
              {report.id}
            </LocationCount>
          </LocationCountWrapper>}
        </EmailLayout>
      );

    return (
      <Layout reports={allReports}>
        <Tablet>
          <TopBar style={{marginBottom: '1rem'}}>
            {fetchingQueryData && (
              <UpperRow>
                  <TopBarLoading />
              </UpperRow>
            )}
            <Row>
              <ReportTabs allReports={allReports} history={history} />
            </Row>
          </TopBar>
        </Tablet>
        <StyledH1>{report.name}</StyledH1>
        <DemographicsBar>
          <DemographicsPanelHeader>
            {showStaffFilters && (
              <div>
                <SwitchContainer>
                  <Switch
                    disabled={fetchingQueryData}
                    checked={Selectors.isDemographicFilterRoleCustomer(
                      topLevelConfig
                    )}
                    label="Exclude staff from counts"
                    onChange={this.handleTopLevelExcludeStaff.bind(this)}
                  />
                </SwitchContainer>
              </div>
            )}
            <div>
              <LocationFilterButtons
                disabled={fetchingQueryData}
                locations={allLocationsList}
                filteredLocations={topLevelConfig.locations}
                updateFilteredLocations={locations =>
                  this.updateReportConfig({
                    property: 'locations',
                    newValue: locations,
                    hierarchyLevel: 0,
                    hierarchyIndex: 0
                  })
                }
              />
            </div>
            <EmailButton>
              <Button
                icon="notifications"
                onClick={this.openScheduledReportJobExplorer.bind(this)}
              >
                Email schedule
              </Button>
            </EmailButton>
          </DemographicsPanelHeader>
        </DemographicsBar>
        <WidgetGrid
          report={report}
          getHierarchyConfig={getHierarchyConfig}
          transformHierarchyConfig={transformHierarchyConfig}
          updateReportConfig={args => this.updateReportConfig(args)}
          availableMetrics={availableMetrics}
          allLocationsList={allLocationsList}
          startOfWeek={startOfWeek}
        />
        <ScheduledReportJobExplorer
          reports={allReports}
          isOpen={isScheduledReportJobExplorerOpen}
          onClose={this.closeScheduledReportJobExplorer.bind(this)}
        />
      </Layout>
    );
  }
}

export default SingleReportPage;
