import { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Button, Icon, Tag, Card, Callout } from '@blueprintjs/core';

import { TAB_ACTIVE_JOBS } from '../index';
import ReportSelector from '../shared/report-selector';
import DistributionSelector from '../shared/distribution-selector';
import ScheduleSelector from '../shared/schedule-selector';
import * as Selectors from '../../../selectors';
import * as UserModel from '../../../models/user';
import * as API from '../../../services/api';
import { CAN_MANAGE_REPORTS } from '../../../constants/permissions';
import {
  SELECTED_REPORT_DEFAULT,
  SELECTED_DISTRIBUTION_DEFAULT,
  SELECTED_SCHEDULE_DEFAULT,
} from '../helpers/consts';
import { validate } from '../helpers/validate';
import { getEmailsAsArray } from '../helpers/getEmailsAsArray';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1.5em;
`;
const StyledIcon = styled(Icon)`
  margin: 0 0.5em 0 0;
`;
const Row = styled(Card)`
  display: flex;
  width: 100%;
  margin: 0 0 1em 0;
`;
const ButtonRow = styled.div``;
const NumberCol = styled.div`
  width: 10%;
`;
const FormCol = styled.div`
  width: 90%;
`;
const ColTitle = styled.div`
  display: flex;
  margin: 0 0 1em 0;
  border-bottom: 1px solid #ced9e0;
`;
const StyledCallout = styled(Callout)`
  margin: 1em 0;
`;

const NewJobTab = ({ reports, setCurrentTab, user, canManageReports }) => {
  const [selectedReport, setSelectedReport] = useState(SELECTED_REPORT_DEFAULT);
  const [selectedDistribution, setSelectedDistribution] = useState(
    SELECTED_DISTRIBUTION_DEFAULT
  );
  const [selectedSchedule, setSelectedSchedule] = useState(
    SELECTED_SCHEDULE_DEFAULT
  );
  const [restrictToLocations, setRestrictToLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState({});

  const ErrorMessage = ({ error }) => {
    if (error) return <StyledCallout intent="warning">{error}</StyledCallout>;
    return null;
  };

  const createNewJob = async () => {
    setApiResponse({});
    if (
      !validate(
        setErrors,
        selectedReport,
        selectedDistribution,
        selectedSchedule
      )
    )
      return false;

    const payload = {
      dashboardReportToImage: {
        dashboardReportId: selectedReport.id,
      },
      schedule: selectedSchedule,
      distribution: {
        sendType: selectedDistribution.sendType,
        toEmails: getEmailsAsArray(selectedDistribution),
      },
      impersonationAuth0Id: UserModel.getAuth0Id(user),
      organisation: UserModel.getOrganisationId(user),
      jobType: 'dashboardReportToImage',
    };

    if (restrictToLocations && restrictToLocations.length > 0) {
      payload.dashboardReportToImage.restrictToLocations = restrictToLocations;
    }

    setLoading(true);
    try {
      await API.scheduledReportJobs.create(payload);

      setSelectedReport(SELECTED_REPORT_DEFAULT);
      setSelectedDistribution(SELECTED_DISTRIBUTION_DEFAULT);
      setSelectedSchedule(SELECTED_SCHEDULE_DEFAULT);

      setApiResponse({
        intent: 'success',
        message: 'Report created successfully',
      });
      setCurrentTab(TAB_ACTIVE_JOBS);
    } catch (e) {
      console.error(
        'Got an API error when trying to create scheduled report job',
        e
      );
      setApiResponse({
        intent: 'warning',
        message: 'Sorry, there was an error - report not created',
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <Row>
        <NumberCol>
          <Tag large minimal round intent="primary">
            1
          </Tag>
        </NumberCol>
        <FormCol>
          <ColTitle>
            <StyledIcon intent="primary" icon="clipboard" />
            <h5 className="bp3-heading">Choose which report to send</h5>
          </ColTitle>
          <ErrorMessage error={errors.report} />
          <ReportSelector
            reports={reports}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            restrictToLocations={restrictToLocations}
            setRestrictToLocations={setRestrictToLocations}
          />
        </FormCol>
      </Row>
      <Row>
        <NumberCol>
          <Tag large minimal round intent="primary">
            2
          </Tag>
        </NumberCol>
        <FormCol>
          <ColTitle>
            <StyledIcon intent="primary" icon="envelope" />
            <h5 className="bp3-heading">
              Choose who should get sent the report
            </h5>
          </ColTitle>
          <ErrorMessage error={errors.distribution} />
          <DistributionSelector
            selectedDistribution={selectedDistribution}
            setSelectedDistribution={setSelectedDistribution}
          />
        </FormCol>
      </Row>
      <Row>
        <NumberCol>
          <Tag large minimal round intent="primary">
            3
          </Tag>
        </NumberCol>
        <FormCol>
          <ColTitle>
            <StyledIcon intent="primary" icon="timeline-events" />
            <h5 className="bp3-heading">
              Set a schedule for this email report
            </h5>
          </ColTitle>
          <ErrorMessage error={errors.schedule} />
          <ScheduleSelector
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
          />
        </FormCol>
      </Row>
      <ButtonRow>
        {!isEmpty(apiResponse) && (
          <Callout intent={apiResponse.intent}>{apiResponse.message}</Callout>
        )}
        <ButtonContainer>
          <Button
            icon="arrow-left"
            onClick={(e) => setCurrentTab(TAB_ACTIVE_JOBS)}
          >
            Back
          </Button>
          {!canManageReports && (
            <Callout intent="warning">
              You do not have permission to create new email reports
            </Callout>
          )}
          <Button
            intent="primary"
            onClick={createNewJob}
            loading={loading}
            disabled={loading || !canManageReports}
          >
            Create
          </Button>
        </ButtonContainer>
      </ButtonRow>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    user: Selectors.getUserModel(state),
    locations: Selectors.getLocations(state),
    canManageReports: Selectors.userHasPermission(CAN_MANAGE_REPORTS, state),
  };
}

export default connect(mapStateToProps)(NewJobTab);
