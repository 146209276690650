// @flow

import { Component, Fragment } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ButtonGroup,
  Icon,
  MenuDivider,
  InputGroup,
} from '@blueprintjs/core';
import { isLocationDisplayed } from '../../utils/summaryPageHelpers';
import * as LocationModel from '../../models/location';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  max-height: 50vh;
  overflow-y: auto;
`;

const StyledInputGroup = styled(InputGroup)`
  margin-bottom: 2px;
`;

const NoLocations = styled.div`
  margin: 1rem 0;
  color: #999;
  width: 100%;
  text-align: center;
`;

type Props = {
  locations: LocationModel.t[],
  filteredLocations: string[],
  updateFilteredLocations(string[]): void
};

type State = {
  currentFilteredLocations: string[],
  visibleLocations: LocationModel.t[],
};

export default class FilterLocationsMenu extends Component<Props, State> {
  state = {
    currentFilteredLocations: this.props.filteredLocations,
    visibleLocations: this.props.locations,
  };

  handleFilterLocationChange = (locId: string) => {
    const { currentFilteredLocations } = this.state;
    if (isLocationDisplayed(locId, currentFilteredLocations)) {
      // Handle delete location from filteredLocations
      const newFilteredLocations = currentFilteredLocations.filter(
        loc => loc !== locId
      );
      this.setState({ currentFilteredLocations: newFilteredLocations });
    } else {
      //Add new location to filteredLocations
      const newFilteredLocations = [...currentFilteredLocations, locId];
      this.setState({ currentFilteredLocations: newFilteredLocations });
    }
  };

  handleSelectAllLocations = () => {
    const { locations } = this.props;
    this.setState({
      currentFilteredLocations: locations.map(loc => loc.id)
    });
  };

  handleSelectZeroLocations = () => {
    this.setState({ currentFilteredLocations: [] });
  };

  filterLocationsByName = (event: Object) => {
    const { locations } = this.props;
    const visibleLocations = locations.filter(loc => loc.name.toLowerCase().includes(event.target.value.toLowerCase()))
    this.setState({
      visibleLocations
    });
  };

  render() {
    const { locations, updateFilteredLocations } = this.props;
    const { currentFilteredLocations } = this.state;

    return (
      <Menu>
        <StyledInputGroup id="text-input" placeholder="Search..." onChange={this.filterLocationsByName} />
        <MenuDivider />
        <StyledMenu>
          {this.state.visibleLocations.length > 0 && this.state.visibleLocations.map(loc => (
            <MenuItem
              key={loc.id}
              text={loc.name}
              shouldDismissPopover={false}
              label={
                isLocationDisplayed(loc.id, currentFilteredLocations) ? (
                  <Icon icon="tick" />
                ) : null
              }
              onClick={() => this.handleFilterLocationChange(loc.id)}
            />
          ))}
          {this.state.visibleLocations.length === 0 && (
            <NoLocations>No locations found</NoLocations>
          )}
        </StyledMenu>
        {this.state.visibleLocations.length > 0 && (
          <Fragment>
            <ButtonGroup fill={true}>
              <Button
                icon="filter-list"
                disabled={currentFilteredLocations.length === locations.length}
                onClick={this.handleSelectAllLocations}
              >
                Select all
              </Button>
              <Button
                icon="cross"
                disabled={currentFilteredLocations.length === 0}
                onClick={this.handleSelectZeroLocations}
              >
                Unselect all
              </Button>
            </ButtonGroup>
            <Button
              intent="success"
              style={{marginTop: '0.25rem'}}
              fill={true}
              disabled={currentFilteredLocations.length === 0}
              onClick={() => {
                updateFilteredLocations(currentFilteredLocations);
              }}
            >
              Filter locations
            </Button>
          </Fragment>
        )}
      </Menu>
    );
  }
}
