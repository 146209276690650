// @flow

import { useState } from 'react';
import _ from 'lodash';
import { Button, Spinner } from '@blueprintjs/core';
import download from 'downloadjs';
import { sanitiseString } from '../../utils/summaryPageHelpers';
import { Desktop, Mobile } from '../layouts/devices-sizes';
import {
  trackEvent,
  DOWNLOAD_CSV_BY_DAY_BUTTON,
} from '../../services/mixpanel';

import * as ActiveMetricsModel from '../../models/active-metrics';
import * as LocationModel from '../../models/location';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as QueryApiRequestModel from '../../models/query-api-request';
import type { locationConfigT } from './summary-btn';
import moment from 'moment';
import { fetchSQLQuery } from '../../services/sql-api';
import { buildPayload, getQueryPayloadType } from '../../services/query-api';
import { apiEndpointByMetricKey } from '../../constants/api-endpoints';
import * as QueryResponseModel from '../../models/query-response';

// Note: never use 'none', null or undefined, it'll break things
const DEFAULT_EMPTY_VALUE = '';

type Props = {
  disabled: boolean,
  locations: LocationModel.t[],
  queryResponseList: QueryResponseListModel.t,
  activeMetrics: ActiveMetricsModel.t,
  filteredLocationIds: string[],
  filename: string,
  availableMetrics: AvailableMetricsModel.t,
  period: QueryApiRequestModel.periodT,
  filteredLocationsConfigList: locationConfigT[],
  excludeStaff: boolean,
};

type ActiveMetricListItem = {
  metricKey: string,
  metricGroupKey: string,
  taxonomies: string[] | null,
};

/**
 * Returns the value of a given metric category for a specific date, location, gender, role and age range.
 *
 * @param {ActiveMetricListItem} metric - The metric category to retrieve the value for.
 * @param {string} date - The date to retrieve the metric value for.
 * @param {QueryResponseModel.t|null} response - The query response object containing the metric value.
 * @param {string} locationId - The location ID to retrieve the metric value for.
 * @param {'male'|'female'} [gender] - The gender to retrieve the metric value for.
 * @param {'customer'|'staff'} [role] - The role to retrieve the metric value for.
 * @param {0|16|25|35|45|55|65} [ageLower] - The lower bound of the age range to retrieve the metric value for.
 *
 * @returns {string} The value of the given metric category for the specified parameters, or DEFAULT_EMPTY_VALUE if not found.
 */
const getCategoryValue = (
  metric: ActiveMetricListItem,
  date: string,
  segments: Object | null,
  locationId?: string,
  taxonomy?: string,
  gender?: 'male' | 'female',
  role?: 'customer' | 'staff',
  ageLower?: 0 | 16 | 25 | 35 | 45 | 55 | 65
) => {
  if (segments) {
    const formattedDate = new Date(date).toISOString().replace('Z', '');

    if (locationId) {
      if (segments[locationId] && segments[locationId][formattedDate]) {
        const found = segments[locationId][formattedDate].find(
          (segment) =>
            (gender ? segment.gender === gender : true) &&
            (role ? segment.role === role : true) &&
            (ageLower
              ? parseInt(segment.age.split('_')[0]) === ageLower
              : true) &&
            (taxonomy ? segment.taxonomy === taxonomy : true)
        );

        return found ? found[metric.metricKey] : DEFAULT_EMPTY_VALUE;
      }
    } else {
      if (segments[formattedDate]) {
        const found = segments[formattedDate].find(
          (segment) =>
            (gender ? segment.gender === gender : true) &&
            (role ? segment.role === role : true) &&
            (ageLower
              ? parseInt(segment.age.split('_')[0]) === ageLower
              : true) &&
            (taxonomy ? segment.taxonomy === taxonomy : true)
        );

        return found ? found[metric.metricKey] : DEFAULT_EMPTY_VALUE;
      }
    }
  }
  return DEFAULT_EMPTY_VALUE;
};

/**
 * Convert segments from flat objects to nested objects.
 *
 */
const convertSegments = (segments: QueryResponseModel.segmentsT) => {
  const nestedSegments = {};

  segments.forEach((segment) => {
    const { location, index } = segment;
    if (location && index) {
      if (!nestedSegments[location]) {
        nestedSegments[location] = {};
      }
      if (!nestedSegments[location][index]) {
        nestedSegments[location][index] = [];
      }
      nestedSegments[location][index].push(segment);
    } else if (typeof index !== 'undefined') {
      if (!nestedSegments[index]) {
        nestedSegments[index] = [];
      }
      nestedSegments[index].push(segment);
    }
  });

  return nestedSegments;
};

const getDates = (period: QueryApiRequestModel.periodT): Array<string> => {
  const dates = [];
  for (
    let i = moment(period.start);
    !i.isSame(moment(period.end).add(1, 'day'));
    i.add(1, 'day')
  ) {
    dates.push(i.format('YYYY-MM-DD'));
  }
  return dates;
};

const addRow = ({
  rows,
  date,
  excludeStaff,
  convertedSegments,
  locationName,
  label,
  metric,
  locationId,
  taxonomy,
}: {
  rows: Array<Object>,
  date: string,
  excludeStaff: boolean,
  convertedSegments: Array<Object>,
  locationName: string,
  label: string,
  metric: ActiveMetricListItem,
  locationId?: string,
  taxonomy?: string,
}) => {
  const row = [
    date,
    locationName,
    label,
    taxonomy ? taxonomy : 'n/a',
    getCategoryValue(
      metric,
      date,
      convertedSegments[0],
      locationId,
      taxonomy,
      undefined,
      undefined,
      undefined
    ),
    ...[
      65,
      'none',
      0,
      'none',
      16,
      'none',
      25,
      'none',
      35,
      'none',
      45,
      'none',
      55,
      'none',
    ].map((ageLower) =>
      ageLower === 'none'
        ? excludeStaff
          ? 'none'
          : DEFAULT_EMPTY_VALUE
        : getCategoryValue(
            metric,
            date,
            convertedSegments[1],
            locationId,
            taxonomy,
            'female',
            undefined,
            ageLower
          )
    ),
    ...[
      65,
      'none',
      0,
      'none',
      16,
      'none',
      25,
      'none',
      35,
      'none',
      45,
      'none',
      55,
      'none',
    ].map((ageLower) =>
      ageLower === 'none'
        ? excludeStaff
          ? 'none'
          : DEFAULT_EMPTY_VALUE
        : getCategoryValue(
            metric,
            date,
            convertedSegments[1],
            locationId,
            taxonomy,
            'male',
            undefined,
            ageLower
          )
    ),

    // breakdown by age,
    ...[65, 0, 16, 25, 35, 45, 55].map((ageLower) =>
      ageLower === 'none'
        ? excludeStaff
          ? 'none'
          : DEFAULT_EMPTY_VALUE
        : getCategoryValue(
            metric,
            date,
            convertedSegments[2],
            locationId,
            taxonomy,
            undefined,
            undefined,
            ageLower
          )
    ),

    // breakdown by gender
    getCategoryValue(
      metric,
      date,
      convertedSegments[3],
      locationId,
      taxonomy,
      'female',
      undefined,
      undefined
    ),
    getCategoryValue(
      metric,
      date,
      convertedSegments[3],
      locationId,
      taxonomy,
      'male',
      undefined,
      undefined
    ),

    // breakdown by role
    getCategoryValue(
      metric,
      date,
      convertedSegments[4],
      locationId,
      taxonomy,
      undefined,
      'customer',
      undefined
    ),
    excludeStaff
      ? 'none'
      : getCategoryValue(
          metric,
          date,
          convertedSegments[4],
          locationId,
          taxonomy,
          undefined,
          'staff',
          undefined
        ),
  ];
  rows.push(row.filter((item) => item !== 'none'));
};

const getAllLocationsRows = (
  period: QueryApiRequestModel.periodT,
  filteredLocationsConfigList: locationConfigT[],
  activeMetricsList: ActiveMetricListItem[],
  responses,
  excludeStaff: boolean
) => {
  const rows = [];

  const dates = getDates(period);

  // for each date
  dates.forEach((date) => {
    // for each metric
    activeMetricsList.forEach((metric) => {
      if (metric.taxonomies) {
        metric.taxonomies.forEach((taxonomy) => {
          const response = responses.find(
            (r) =>
              r.metricKey === metric.metricKey &&
              _.isEqual(r.taxonomies, [taxonomy]) &&
              r.metricGroupKey === metric.metricGroupKey
          );

          if (response) {
            const convertedSegments = [
              response.data[0]
                ? convertSegments(response.data[0].segments)
                : null,
              response.data[1]
                ? convertSegments(response.data[1].segments)
                : null,
              response.data[2]
                ? convertSegments(response.data[2].segments)
                : null,
              response.data[3]
                ? convertSegments(response.data[3].segments)
                : null,
              response.data[4]
                ? convertSegments(response.data[4].segments)
                : null,
            ];
            addRow({
              rows,
              date,
              excludeStaff,
              convertedSegments,
              locationName: 'All locations',
              label: response.label,
              metric,
              taxonomy,
            });
          }
        });
      } else {
        const response = responses.find((r) => {
          return (
            r.metricKey === metric.metricKey &&
            !r.taxonomies &&
            r.metricGroupKey === metric.metricGroupKey
          );
        });

        if (response) {
          const convertedSegments = [
            response.data[0]
              ? convertSegments(response.data[0].segments)
              : null,
            response.data[1]
              ? convertSegments(response.data[1].segments)
              : null,
            response.data[2]
              ? convertSegments(response.data[2].segments)
              : null,
            response.data[3]
              ? convertSegments(response.data[3].segments)
              : null,
            response.data[4]
              ? convertSegments(response.data[4].segments)
              : null,
          ];
          addRow({
            rows,
            date,
            excludeStaff,
            convertedSegments,
            locationName: 'All locations',
            label: response.label,
            metric,
          });
        }
      }
    });
  });

  return rows;
};

/**
 * Returns an array of rows to be used as the body of a table in a download-by-day report.
 *
 * @param {QueryResponseModel.t[]} responses - An array of query response objects.
 * @param {ActiveMetricListItem[]} activeMetricsList - An array of active metric items.
 * @param {locationConfigT[]} filteredLocationsConfigList - An array of filtered location configurations.
 * @param {boolean} excludeStaff - A boolean value indicating whether to exclude staff data from the report.
 *
 * @returns {Array<Array<string>>} An array of rows to be used as the body of a table in a download-by-day report.
 */
const getBodyRows = (
  period: QueryApiRequestModel.periodT,
  filteredLocationsConfigList: locationConfigT[],
  activeMetricsList: ActiveMetricListItem[],
  responses,
  excludeStaff: boolean
) => {
  const rows = [];

  const dates = getDates(period);

  // for each date
  dates.forEach((date) => {
    // for each location
    filteredLocationsConfigList.forEach((location) => {
      // for each metric
      activeMetricsList.forEach((metric) => {
        if (metric.taxonomies) {
          metric.taxonomies.forEach((taxonomy) => {
            const response = responses.find(
              (r) =>
                r.metricKey === metric.metricKey &&
                _.isEqual(r.taxonomies, [taxonomy]) &&
                r.metricGroupKey === metric.metricGroupKey
            );

            if (response) {
              const convertedSegments = [
                response.data[0]
                  ? convertSegments(response.data[0].segments)
                  : null,
                response.data[1]
                  ? convertSegments(response.data[1].segments)
                  : null,
                response.data[2]
                  ? convertSegments(response.data[2].segments)
                  : null,
                response.data[3]
                  ? convertSegments(response.data[3].segments)
                  : null,
                response.data[4]
                  ? convertSegments(response.data[4].segments)
                  : null,
              ];
              addRow({
                rows,
                date,
                excludeStaff,
                convertedSegments,
                locationName: location.locationName,
                label: response.label,
                metric,
                locationId: location.locationId,
                taxonomy,
              });
            }
          });
        } else {
          const response = responses.find(
            (r) =>
              r.metricKey === metric.metricKey &&
              !r.taxonomies &&
              r.metricGroupKey === metric.metricGroupKey
          );

          if (response) {
            const convertedSegments = [
              response.data[0]
                ? convertSegments(response.data[0].segments)
                : null,
              response.data[1]
                ? convertSegments(response.data[1].segments)
                : null,
              response.data[2]
                ? convertSegments(response.data[2].segments)
                : null,
              response.data[3]
                ? convertSegments(response.data[3].segments)
                : null,
              response.data[4]
                ? convertSegments(response.data[4].segments)
                : null,
            ];
            addRow({
              rows,
              date,
              excludeStaff,
              convertedSegments,
              locationName: location.locationName,
              label: response.label,
              metric,
              locationId: location.locationId,
            });
          }
        }
      });
    });
  });

  return rows;
};

/**
 * Returns an array of headings to be used as the header of a table in a download-by-day report.
 *
 * @param {boolean} excludeStaff - A boolean value indicating whether to exclude staff data from the report.
 *
 * @returns {Array<string>} An array of headings to be used as the header of a table in a download-by-day report.
 */
const getHeadings = (excludeStaff: boolean) => {
  if (excludeStaff) {
    return [
      'date',
      'location',
      'metric',
      'context',
      'overallValue',
      'category:female_+65_customer',
      'category:female_-15_customer',
      'category:female_16-24_customer',
      'category:female_25-34_customer',
      'category:female_35-44_customer',
      'category:female_45-54_customer',
      'category:female_55-64_customer',
      'category:male_+65_customer',
      'category:male_-15_customer',
      'category:male_16-24_customer',
      'category:male_25-34_customer',
      'category:male_35-44_customer',
      'category:male_45-54_customer',
      'category:male_55-64_customer',
      'breakdownBy:age: +65',
      'breakdownBy:age: -15',
      'breakdownBy:age: 16-24',
      'breakdownBy:age: 25-34',
      'breakdownBy:age: 35-44',
      'breakdownBy:age: 45-54',
      'breakdownBy:age: 55-64',
      'breakdownBy:gender: female',
      'breakdownBy:gender: male',
      'breakdownBy:role: customer',
    ];
  } else {
    return [
      'date',
      'location',
      'metric',
      'context',
      'overallValue',
      'category:female_+65_customer',
      'category:female_+65_staff',
      'category:female_-15_customer',
      'category:female_-15_staff',
      'category:female_16-24_customer',
      'category:female_16-24_staff',
      'category:female_25-34_customer',
      'category:female_25-34_staff',
      'category:female_35-44_customer',
      'category:female_35-44_staff',
      'category:female_45-54_customer',
      'category:female_45-54_staff',
      'category:female_55-64_customer',
      'category:female_55-64_staff',
      'category:male_+65_customer',
      'category:male_+65_staff',
      'category:male_-15_customer',
      'category:male_-15_staff',
      'category:male_16-24_customer',
      'category:male_16-24_staff',
      'category:male_25-34_customer',
      'category:male_25-34_staff',
      'category:male_35-44_customer',
      'category:male_35-44_staff',
      'category:male_45-54_customer',
      'category:male_45-54_staff',
      'category:male_55-64_customer',
      'category:male_55-64_staff',
      'breakdownBy:age: +65',
      'breakdownBy:age: -15',
      'breakdownBy:age: 16-24',
      'breakdownBy:age: 25-34',
      'breakdownBy:age: 35-44',
      'breakdownBy:age: 45-54',
      'breakdownBy:age: 55-64',
      'breakdownBy:gender: female',
      'breakdownBy:gender: male',
      'breakdownBy:role: customer',
      'breakdownBy:role: staff',
    ];
  }
};

const DownloadByDayButton = ({
  disabled,
  locations,
  queryResponseList,
  activeMetrics,
  filteredLocationIds,
  filename,
  availableMetrics,
  period,
  filteredLocationsConfigList,
  excludeStaff,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const onDownloadByDay = async () => {
    setLoading(true);
    try {
      const activeMetricsList: ActiveMetricListItem[] = [];

      Object.entries(activeMetrics).forEach(
        ([metricGroupKey, metricGroup]: [string, Object]) => {
          return metricGroup.metrics.forEach((metric) => {
            if (metricGroup.taxonomies || metricGroup.filters) {
              let taxonomies = metricGroup.taxonomies;
              if (metricGroup.filters) {
                taxonomies = metricGroup.filters.map(
                  (filter) => `${filter.key}:${filter.value}`
                );
              }

              activeMetricsList.push(
                ...taxonomies.map((taxonomy) => ({
                  metricGroupKey,
                  metricKey: metric,
                  taxonomies: [taxonomy],
                }))
              );
            } else {
              activeMetricsList.push({
                metricGroupKey,
                metricKey: metric,
                taxonomies: null,
              });
            }
          });
        }
      );

      const responses = [];
      const allLocationResponses = [];

      activeMetricsList.forEach((item) => {
        const endpoint = apiEndpointByMetricKey(item.metricKey);

        const metricDetails = AvailableMetricsModel.findMetricDetails(
          availableMetrics,
          item.metricGroupKey,
          item.metricKey
        );

        const endpointDetails =
          AvailableMetricsModel.getMetricEndpoint(metricDetails);

        const payloadType = getQueryPayloadType(endpoint, endpointDetails);

        const isSalesMetric = item.metricGroupKey === 'sales';

        const demographicFilter =
          excludeStaff && !isSalesMetric ? { role: 'customer' } : {};

        const basePayload = {
          locations: filteredLocationIds,
          period: {
            selectedPreset: 'custom',
            selectedDates: period,
          },
          demographicFilter,
          taxonomies: item.taxonomies ? item.taxonomies : undefined,
          aggregation: 'day',
          metricKey: item.metricKey,
          breakdownByDimensions: item.taxonomies ? ['taxonomy'] : [],
          facets: ['segments', 'estimates'],
        };

        const allAges = [
          '0_15',
          '16_24',
          '25_34',
          '35_44',
          '45_54',
          '55_64',
          '65_100',
        ];

        // total
        const payloadInput0 = buildPayload(payloadType, {
          ...basePayload,
          locations: filteredLocationIds,
          breakdownByDimensions: [
            ...basePayload.breakdownByDimensions,
            'entity',
          ],
        });

        // age and gender breakdown
        const payloadInput1 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              ages: allAges,
              genders: ['male', 'female'],
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'entity',
                'age',
                'gender',
              ],
            })
          : null;

        // age breakdown
        const payloadInput2 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              ages: allAges,
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'entity',
                'age',
              ],
            })
          : null;

        // gender breakdown
        const payloadInput3 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              genders: ['male', 'female'],
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'entity',
                'gender',
              ],
            })
          : null;

        // role breakdown
        const payloadInput4 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              demographicFilter: {},
              roles: ['staff', 'customer'],
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'entity',
                'role',
              ],
            })
          : null;

        // all locations total
        const payloadInput5 = buildPayload(payloadType, {
          ...basePayload,
          locations: filteredLocationIds,
        });

        // all locations age and gender breakdown
        const payloadInput6 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              ages: allAges,
              genders: ['male', 'female'],
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'age',
                'gender',
              ],
            })
          : null;

        // all locations age breakdown
        const payloadInput7 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              ages: allAges,
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'age',
              ],
            })
          : null;

        // all locations gender breakdown
        const payloadInput8 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              genders: ['male', 'female'],
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'gender',
              ],
            })
          : null;

        // all locations role breakdown
        const payloadInput9 = !isSalesMetric
          ? buildPayload(payloadType, {
              ...basePayload,
              demographicFilter: {},
              roles: ['staff', 'customer'],
              breakdownByDimensions: [
                ...basePayload.breakdownByDimensions,
                'role',
              ],
            })
          : null;

        responses.push(
          new Promise(async (resolve) => {
            const data = [];

            const payloadInputs = [
              payloadInput0,
              payloadInput1,
              payloadInput2,
              payloadInput3,
              payloadInput4,
            ];

            for (let i = 0; i < payloadInputs.length; i++) {
              if (payloadInputs[i]) {
                data[i] = await fetchSQLQuery(payloadInputs[i], endpoint);
              }
            }

            resolve({
              metricGroupKey: item.metricGroupKey,
              metricKey: item.metricKey,
              taxonomies: item.taxonomies ? item.taxonomies : undefined,
              label: metricDetails ? metricDetails.label : item.metricKey,
              data,
            });
          })
        );

        allLocationResponses.push(
          new Promise(async (resolve) => {
            const data = [];

            const payloadInputs = [
              payloadInput5,
              payloadInput6,
              payloadInput7,
              payloadInput8,
              payloadInput9,
            ];

            for (let i = 0; i < payloadInputs.length; i++) {
              if (payloadInputs[i]) {
                data[i] = await fetchSQLQuery(payloadInputs[i], endpoint);
              }
            }

            resolve({
              metricGroupKey: item.metricGroupKey,
              metricKey: item.metricKey,
              taxonomies: item.taxonomies ? item.taxonomies : undefined,
              label: metricDetails ? metricDetails.label : item.metricKey,
              data,
            });
          })
        );
      });

      const headerRow = getHeadings(excludeStaff);

      const bodyRows = getBodyRows(
        period,
        filteredLocationsConfigList,
        activeMetricsList,
        await Promise.all(responses),
        excludeStaff
      );

      const allLocationRows = getAllLocationsRows(
        period,
        filteredLocationsConfigList,
        activeMetricsList,
        await Promise.all(allLocationResponses),
        excludeStaff
      );

      const document = [
        headerRow.map((col) => sanitiseString(col)).join(','),
        ...bodyRows.map((row) =>
          row.map((col) => sanitiseString(col)).join(',')
        ),
        ...allLocationRows.map((row) =>
          row.map((col) => sanitiseString(col)).join(',')
        ),
      ].join('\n');

      const fullFilename = `all_locations_summary_data_by_day-${filename}.csv`;
      download(document, fullFilename, 'text/csv');
      trackEvent(DOWNLOAD_CSV_BY_DAY_BUTTON);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Button
      data-automation-trigger="download-by-day-csv"
      disabled={disabled || loading}
      onClick={onDownloadByDay}
      icon={loading ? <Spinner size={18} /> : 'floppy-disk'}
    >
      <Desktop>
        {loading ? 'Loading...' : 'Export visible data by day (CSV)'}
      </Desktop>
      <Mobile>{loading ? 'Loading...' : 'Export visible by day (CSV)'}</Mobile>
    </Button>
  );
};

export default DownloadByDayButton;
