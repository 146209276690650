// @flow

import { isEqual } from 'lodash';

import type { FluxStandardAction } from 'flux-standard-action';
import * as QueryApiRequestModel from '../models/query-api-request';

import * as ACTIONS from '../constants/actions';
import * as PROCESSES from '../constants/async-process-names';

export type queryDataOngoingProcessesT = {
  process: string,
  metricKey: string,
  query: QueryApiRequestModel.t
};

export type oneOffProcessT = {
  started: boolean,
  completed: boolean,
  responseFailureCode?: number
};

export type ReducerType = {
  ongoingProcesses: (string | queryDataOngoingProcessesT)[],
  userRefresh: oneOffProcessT
};

const INITIAL_STATE = {
  ongoingProcesses: [],
  userRefresh: { started: false, completed: false }
};

export default function asyncProcessesReducer(
  state: ReducerType = INITIAL_STATE,
  { type, payload }: FluxStandardAction<string, *>
): ReducerType {
  switch (type) {
    case ACTIONS.FETCH_RECORDINGS_REQUEST:
      return {
        ...state,
        ongoingProcesses: [
          ...state.ongoingProcesses,
          PROCESSES.FETCHING_RECORDINGS
        ]
      };

    case ACTIONS.FETCH_LOCATIONS_REQUEST:
      return {
        ...state,
        ongoingProcesses: [
          ...state.ongoingProcesses,
          PROCESSES.FETCHING_LOCATIONS
        ]
      };

    case ACTIONS.FETCH_HEATMAPS_REQUEST:
      return {
        ...state,
        ongoingProcesses: [
          ...state.ongoingProcesses,
          PROCESSES.FETCHING_HEATMAPS
        ]
      };
    case ACTIONS.FETCH_HEATMAP_SOURCE_URL_REQUEST:
      return {
        ...state,
        ongoingProcesses: [
          ...state.ongoingProcesses,
          PROCESSES.FETCHING_HEATMAP_SOURCE_URL
        ]
      };
    case ACTIONS.FETCH_QUERY_DATA_REQUEST: {
      const queryDataOngoingProcesses = {
        process: PROCESSES.FETCHING_QUERY_DATA,
        metricKey: payload.metricKey,
        query: payload.query
      };
      return {
        ...state,
        ongoingProcesses: [...state.ongoingProcesses, queryDataOngoingProcesses]
      };
    }

    case ACTIONS.REFRESH_USER_REQUEST:
      return {
        ...state,
        userRefresh: {
          started: true,
          completed: false
        }
      };

    case ACTIONS.FETCH_REPORTS_REQUEST:
      return {
        ...state,
        ongoingProcesses: [
          ...state.ongoingProcesses,
          PROCESSES.FETCHING_REPORTS
        ]
      };

    case ACTIONS.FETCH_RECORDINGS_SUCCESS:
    case ACTIONS.FETCH_RECORDINGS_ERROR:
      return {
        ...state,
        ongoingProcesses: state.ongoingProcesses.filter(
          val => val !== PROCESSES.FETCHING_RECORDINGS
        )
      };

    case ACTIONS.FETCH_LOCATIONS_SUCCESS:
    case ACTIONS.FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        ongoingProcesses: state.ongoingProcesses.filter(
          val => val !== PROCESSES.FETCHING_LOCATIONS
        )
      };

    case ACTIONS.FETCH_QUERY_DATA_SUCCESS:
    case ACTIONS.FETCH_QUERY_DATA_GENERAL_ERROR:
    case ACTIONS.FETCH_QUERY_DATA_METRICS_ERROR: {
      const queryDataOngoingProcesses = {
        process: PROCESSES.FETCHING_QUERY_DATA,
        metricKey: payload.metricKey,
        query: payload.query
      };
      return {
        ...state,
        ongoingProcesses: state.ongoingProcesses.filter(
          val => !isEqual(val, queryDataOngoingProcesses)
        )
      };
    }

    case ACTIONS.FETCH_HEATMAPS_SUCCESS:
    case ACTIONS.FETCH_HEATMAPS_ERROR:
      return {
        ...state,
        ongoingProcesses: state.ongoingProcesses.filter(
          val => val !== PROCESSES.FETCHING_HEATMAPS
        )
      };

    case ACTIONS.FETCH_HEATMAP_SOURCE_URL_SUCCESS:
    case ACTIONS.FETCH_HEATMAP_SOURCE_URL_ERROR:
      return {
        ...state,
        ongoingProcesses: state.ongoingProcesses.filter(
          val => val !== PROCESSES.FETCHING_HEATMAP_SOURCE_URL
        )
      };

    case ACTIONS.REFRESH_USER_SUCCESS:
      return {
        ...state,
        userRefresh: {
          ...state.userRefresh,
          completed: true
        }
      };

    case ACTIONS.REFRESH_USER_ERROR:
      return {
        ...state,
        userRefresh: {
          ...state.userRefresh,
          completed: true,
          responseFailureCode: payload.statusCode
        }
      };

    case ACTIONS.FETCH_REPORTS_SUCCESS:
    case ACTIONS.FETCH_REPORTS_ERROR:
      return {
        ...state,
        ongoingProcesses: state.ongoingProcesses.filter(
          val => val !== PROCESSES.FETCHING_REPORTS
        )
      };

    case ACTIONS.LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}
