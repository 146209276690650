// @flow

import { Fragment } from 'react';
import SortableTableHeading from './sortable-table-heading';
import { getColumnLabel } from '../../utils/metricsTableHelpers';

import * as AvailableMetricsModel from '../../models/available-metrics';
import * as ActiveMetricsModel from '../../models/active-metrics';
import type { sortByT } from './index';

type Props = {
  headersToOutput: ActiveMetricsModel.activeMetricsHeadingsT[],
  handleSortBy(sortBy: sortByT): void,
  sortBy: sortByT,
  availableMetrics: AvailableMetricsModel.t,
  isComparisonActive: boolean,
  currentCellWidth?: number,
  useInvertedMetrics: boolean
};

const TableHeadingRow = ({
  headersToOutput,
  handleSortBy,
  sortBy,
  availableMetrics,
  isComparisonActive,
  currentCellWidth,
  useInvertedMetrics
}: Props) => {
  return (
    <Fragment>
      {headersToOutput &&
        headersToOutput.map((heading, index) => {
          const key = `${heading.metricGroupKey}-${heading.metricKey}-${index}`;
          const label = !useInvertedMetrics
            ? getColumnLabel(heading, availableMetrics)
            : heading.label;

          return (
            <SortableTableHeading
              handleSortBy={handleSortBy}
              sortBy={sortBy}
              availableMetrics={availableMetrics}
              isComparisonActive={isComparisonActive}
              key={key}
              columnsNumber={1 + headersToOutput.length}
              label={label || ''}
              columnDetails={heading}
              currentCellWidth={currentCellWidth}
            />
          );
        })}
    </Fragment>
  );
};

export default TableHeadingRow;
