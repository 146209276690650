// @flow

import { connect } from 'react-redux';
import * as Selectors from '../selectors';
import ManageSingleLocation from '../components/pages/settings/manage/single-location';
import { Dispatch, bindActionCreators } from 'redux';
import * as LocationActions from '../actions/locations';

function mapStateToProps(state) {
  return {
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      updateLocation: LocationActions.updateLocation
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSingleLocation);
