// @flow

// TODO: refactor this to a functional component and location-occupancy-row to match context-occupancy-row

import { Component } from 'react';
import type { Location } from 'react-router-dom';
import {
  Switch,
  Tabs,
  Tab,
  Popover,
  Menu,
  MenuItem,
  Icon,
  Tag,
} from '@blueprintjs/core';
import { Row, TopBar } from '../summary/layout';
import styled from 'styled-components';
import Layout from '../../layouts/default-logged-in';
import LocationOccupancyRow from './location-occupancy-row';
import WithinLocationOccupancyTab from './within-location-tab';
import {
  getLocationConfig,
  getDwellAreaContextsForSingleLocation,
} from '../../../utils/live-occupancy';
import {
  getUrlHashAsObject,
  convertFromObjectToUrlHash,
} from '../../../utils/urlHelpers';

import * as RecordingModel from '../../../models/recording';
import * as LocationModel from '../../../models/location';
import type { locationConfigT } from './location-occupancy-row';
import type { dwellAreaContextsForLocationsT } from '../../../utils/live-occupancy';
import * as api from '../../../services/api';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;
const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
`;
const StyledSwitch = styled(Switch)`
  margin: 0;
`;

type Props = {
  locations: LocationModel.t[],
  recordings: RecordingModel.t[],
  location: Location, // from react-router-dom
  showStaffFilters: boolean,
  isDemoUser: boolean,
};

type State = {
  locations: locationConfigT[],
  selectedTab: string,
  zonesTabSelectedLocation: LocationModel.t | null,
  excludeStaff: boolean,
  taxonomyMetas: Object[],
  totalOccupancyHistory: Object,
  staffOccupancyHistory: Object,
  customerOccupancyHistory: Object,
  locationsAndDwellAreas: dwellAreaContextsForLocationsT[],
};

class LiveOccupancyPage extends Component<Props, State> {
  state = {
    locations: [],
    selectedTab: 'store',
    zonesTabSelectedLocation: null,
    excludeStaff: false,
    taxonomyMetas: [],
    totalOccupancyHistory: {},
    staffOccupancyHistory: {},
    customerOccupancyHistory: {},
    locationsAndDwellAreas: [],
  };

  async componentDidMount() {
    try {
      const urlState = getUrlHashAsObject(this.props.location.hash);
      const { selectedTab, excludeStaff } = urlState;
      if (selectedTab) this.setState({ selectedTab });
      if (excludeStaff || excludeStaff === false)
        this.setState({ excludeStaff });

      // get the list of taxonomyMetas from the API
      try {
        const taxonomyMetas = await api.taxonomyMetas.list();
        if (taxonomyMetas) this.setState({ taxonomyMetas });
      } catch (e) {
        console.error(e);
      }
    } catch {
      // clear the malformed hash
      window.location.hash = '';
    }

    this.setDefaultLocations();

    if (this.props.locations && this.props.locations.length > 0) {
      this.setState({ zonesTabSelectedLocation: this.props.locations[0] });
    }
  }

  handleTabClick(selectedTab: string) {
    this.handleStateChange({ selectedTab });
  }

  setExcludeStaff(excludeStaff: boolean) {
    this.handleStateChange({ excludeStaff });
  }

  handleStateChange(changesToState: Object) {
    this.setState(changesToState);
    const currentUrlState = getUrlHashAsObject(this.props.location.hash);
    if (!currentUrlState) return;
    const newUrlState = { ...currentUrlState, ...changesToState };
    window.location.hash = convertFromObjectToUrlHash(newUrlState);
  }

  setDefaultLocations() {
    const { locations, recordings } = this.props;
    const newLocations = locations.map((loc) => {
      const locationConfig = getLocationConfig({
        location: loc,
        locations,
        recordings,
      });

      return locationConfig;
    });

    this.setState(
      {
        locations: newLocations,
      },
      () => this.getLocationAreaContexts()
    );
  }

  setZonesTabSelectedLocation(location: LocationModel.t) {
    this.setState({ zonesTabSelectedLocation: location });
  }

  getLocationAreaContexts() {
    const { locations, recordings } = this.props;
    const locationsWithLiveOccupancyActive =
      LocationModel.getLocationsWithLiveOccupancyActive(locations, recordings);

    this.setState({
      locationsAndDwellAreas: locationsWithLiveOccupancyActive.map((loc) =>
        getDwellAreaContextsForSingleLocation(loc, recordings)
      ),
    });
  }

  render() {
    const {
      selectedTab,
      zonesTabSelectedLocation,
      excludeStaff,
      taxonomyMetas,
    } = this.state;
    const { locations, showStaffFilters, isDemoUser } = this.props;
    const zonesTabTitle =
      locations.length === 1
        ? `Occupancy within ${locations[0].name}`
        : 'Occupancy within ';

    return (
      <Layout location={this.props.location}>
        <TopBar>
          <StyledRow>
            <Tabs
              id="LiveOccTabs"
              selectedTabId={selectedTab}
              onChange={this.handleTabClick.bind(this)}
            >
              <Tab id="store" key="store" title="Whole store occupancy" />
              <Tab id="zones" key="zones" title={zonesTabTitle}>
                {locations.length > 1 && (
                  <Popover>
                    <Tag intent="primary">
                      {zonesTabSelectedLocation &&
                        zonesTabSelectedLocation.name}
                      <Icon icon="chevron-down" />
                    </Tag>
                    <Menu>
                      {this.props.locations
                        .map((location) => (
                          <MenuItem
                            key={location.id}
                            text={location.name}
                            onClick={() =>
                              this.setZonesTabSelectedLocation(location)
                            }
                            icon={
                              zonesTabSelectedLocation &&
                              zonesTabSelectedLocation.id === location.id
                                ? 'tick'
                                : ''
                            }
                          />
                        ))}
                    </Menu>
                  </Popover>
                )}
              </Tab>
            </Tabs>
            {showStaffFilters && (
              <StyledSwitch
                checked={excludeStaff}
                label="Exclude staff from counts"
                onChange={() => this.setExcludeStaff(!excludeStaff)}
              />
            )}
          </StyledRow>
        </TopBar>

        <Wrapper>
          {selectedTab === 'store' &&
            this.state.locations
              .map((location) => (
                <LocationOccupancyRow
                  location={
                    this.props.locations.filter(
                      (loc) => loc.id === location.id
                    )[0] || null
                  }
                  locationConfig={location}
                  key={location.id}
                  excludeStaff={excludeStaff}
                  locationAndDwellAreas={
                    this.state.locationsAndDwellAreas.filter(
                      (loc) => loc.location.id === location.id
                    )[0] || null
                  }
                  isDemoUser={isDemoUser}
                />
              ))}
          {selectedTab === 'zones' && zonesTabSelectedLocation && (
            <WithinLocationOccupancyTab
              recordings={RecordingModel.getRecordingsForLocation(
                this.props.recordings,
                zonesTabSelectedLocation
              )}
              location={zonesTabSelectedLocation}
              excludeStaff={excludeStaff}
              taxonomyMetas={taxonomyMetas.filter(
                (tm) => tm.location === zonesTabSelectedLocation.id
              )}
              isDemoUser={isDemoUser}
            />
          )}
        </Wrapper>
      </Layout>
    );
  }
}

export default LiveOccupancyPage;
