// @flow

import moment from 'moment';
import * as PREVIOUS_PERIOD_PRESETS from '../constants/previous-period-presets';
import * as PERIOD_PRESETS from '../constants/period-presets';
import type { previousPeriodPresetsEnum } from '../constants/previous-period-presets';
import type { periodPresetsEnum } from '../constants/period-presets';
import { getQuarterStartDateFromDate } from '../utils/dates-quarters';
import { standardDateFormat } from '../constants/dates-formats';

import * as QueryApiRequestModel from './query-api-request';

export type periodPresetsT = periodPresetsEnum;
export type compareToPastPresetsT = previousPeriodPresetsEnum;

export type t = {
  selectedPreset: periodPresetsT,
  customPeriodDates?: QueryApiRequestModel.periodT,
  selectedDates: QueryApiRequestModel.periodT
};

export type unsetDatesT = {
  selectedPreset: periodPresetsT,
  customPeriodDates?: QueryApiRequestModel.periodT,
  selectedDates?: QueryApiRequestModel.periodT
};

export type comparePeriodT = {
  active?: boolean,
  selectedPreset?: compareToPastPresetsT,
  selectedDates?: QueryApiRequestModel.periodT
};
export type activeComparePeriodT = {
  active: boolean,
  selectedPreset?: compareToPastPresetsT,
  selectedDates: QueryApiRequestModel.periodT
};

export const getStandardFormatDate = (date: Date | string): string => {
  const formatedDate = moment(date).format(standardDateFormat);
  return formatedDate;
};

// Optionally override the date for simulating the state of the UI in the past
// e.g. for generating old reports
export const getExplorerPeriod = (preset: periodPresetsT, overrideDate?: Date): t => {
  let newStart = moment(overrideDate);
  let newEnd = moment(overrideDate);

  switch (preset) {
    case PERIOD_PRESETS.lastFullDay:
      newStart = newStart.subtract(1, 'days').toDate();
      newEnd = newStart;
      break;
    case PERIOD_PRESETS.last7Days:
      newStart = newStart.subtract(7, 'days').toDate();
      newEnd = newEnd.subtract(1, 'days').toDate();
      break;
    case PERIOD_PRESETS.lastFullWeek:
      newStart = newStart.day(1 - 7).toDate(); // 1 - 7 is the last Monday
      newEnd = newEnd.day(0).toDate(); // 0 is the last Sunday
      break;
    case PERIOD_PRESETS.lastFullUSWeek:
      newStart = newStart.day(0 - 7).toDate(); // 0 - 7 is the last Sunday
      newEnd = newEnd.day(0 - 1).toDate(); // 0 - 1 is the last Saturday
      break;
    case PERIOD_PRESETS.last4FullWeeks:
      newStart = newStart.day(1 - 28).toDate(); // 1 is this Monday, then take 28 days off for 4 weeks
      newEnd = newEnd.day(0).toDate(); // 0 is the last Sunday
      break;
    case PERIOD_PRESETS.last4FullUSWeeks:
      newStart = newStart.day(0 - 28).toDate(); // 0 is the last Sunday, then take 28 days off for 4 weeks
      newEnd = newEnd.day(0 - 1).toDate(); // 0 - 1 is the last Saturday
      break;
    case PERIOD_PRESETS.last30Days:
      newStart = newStart.subtract(30, 'days').toDate();
      newEnd = newEnd.subtract(1, 'days').toDate();
      break;
    case PERIOD_PRESETS.lastFullMonth:
      newStart = newStart
        .subtract(1, 'months')
        .date(1)
        .toDate();
      newEnd = newEnd
        .date(1)
        .subtract(1, 'days')
        .toDate();
      break;
    case PERIOD_PRESETS.last3FullMonths:
      newStart = newStart
        .subtract(3, 'months')
        .date(1)
        .toDate();
      newEnd = newEnd
        .date(1)
        .subtract(1, 'days')
        .toDate();
      break;
    case PERIOD_PRESETS.last90Days:
      newStart = newStart.subtract(90, 'days').toDate();
      newEnd = newEnd.subtract(1, 'days').toDate();
      break;
    case PERIOD_PRESETS.last12Months:
      newStart = newStart.subtract(12, 'months').toDate();
      newEnd = newEnd.subtract(1, 'days').toDate();
      break;
    case PERIOD_PRESETS.monthToDate:
      newStart = newStart.date(1).toDate();
      newEnd = newEnd.toDate();
      break;
    case PERIOD_PRESETS.lastFullYear:
      newEnd = newEnd
        .date(1)
        .month(0)
        .subtract(1, 'days')
        .toDate();
      newStart = newStart
        .date(1)
        .month(0)
        .subtract(1, 'years')
        .toDate();
      break;
    case PERIOD_PRESETS.yearToDate:
      newStart = newStart
        .date(1)
        .month(0)
        .toDate();
      newEnd = newEnd.subtract(1, 'days').toDate();
      break;
    case PERIOD_PRESETS.quarterToDate:
      {
        const quarterStartDate = getQuarterStartDateFromDate(newStart);

        newStart = moment(quarterStartDate, standardDateFormat).toDate();
        newEnd = newEnd.toDate();
      }
      break;
    case PERIOD_PRESETS.lastFullQuarter:
      {
        const quarterStartDate = getQuarterStartDateFromDate(newStart);

        newStart = moment(quarterStartDate, standardDateFormat)
          .subtract(3, 'months')
          .toDate();
        newEnd = moment(quarterStartDate, standardDateFormat)
          .subtract(1, 'days')
          .toDate();
      }
      break;
    case PERIOD_PRESETS.weekToDate:
      newStart = newStart.day(1).toDate(); // 1 is the last Monday
      newEnd = newEnd.toDate();
      break;
    case PERIOD_PRESETS.custom:
      // custom period selected - dates are stored separately in the
      // Period model (`customPeriodDates`) so there's nothing to work out here
      break;
    default:
      console.error(`Invalid preset ${preset} supplied in getExplorerPeriod`);
  }

  const newPeriod = {
    selectedPreset: preset,
    selectedDates: {
      start: getStandardFormatDate(newStart),
      end: getStandardFormatDate(newEnd)
    }
  };

  return newPeriod;
};

export const getExplorerComparePeriod = (
  preset: compareToPastPresetsT,
  period: QueryApiRequestModel.periodT
): comparePeriodT => {
  const { start, end } = period;
  let newPeriod = {
    active: true,
    selectedPreset: preset,
    selectedDates: undefined,
    customPeriodDates: undefined
  };
  let newStart = moment(start);
  let newEnd = moment(end);

  switch (preset) {
    case PREVIOUS_PERIOD_PRESETS.none:
      return {
        active: false
      };
    case PREVIOUS_PERIOD_PRESETS.previousDay:
      newStart = newStart.subtract(1, 'days');
      newEnd = newEnd.subtract(1, 'days');
      break;

    case PREVIOUS_PERIOD_PRESETS.previousWeek:
      newStart = newStart.subtract(7, 'days');
      newEnd = newEnd.subtract(7, 'days');
      break;

    case PREVIOUS_PERIOD_PRESETS.previousMonth:
      newStart = newStart.subtract(1, 'months');
      newEnd = newEnd.subtract(1, 'months');
      break;

    case PREVIOUS_PERIOD_PRESETS.previousQuarter:
      newStart = newStart.subtract(3, 'months');
      newEnd = newEnd.subtract(3, 'months');
      break;
    case PREVIOUS_PERIOD_PRESETS.previousYear:
      newStart = newStart.subtract(12, 'months');
      newEnd = newEnd.subtract(12, 'months');
      break;

    case PREVIOUS_PERIOD_PRESETS.sameWeekLastYear:
      newStart = newStart.subtract(52, 'weeks');
      newEnd = newEnd.subtract(52, 'weeks');

      break;

    case PREVIOUS_PERIOD_PRESETS.previousPeriod:
      {
        const difference = newStart.diff(newEnd, 'days');
        const resultDiff = Math.abs(difference);

        newStart = newStart.subtract(resultDiff + 1, 'days');
        newEnd = newEnd.subtract(resultDiff + 1, 'days');
      }
      break;
    case undefined:
      // no comparion period preset defined - so no dates to work out here
      break;
    default:
      console.error(
        `Invalid comparison preset ${preset} supplied in getExplorerComparePeriod`
      );
  }

  newPeriod.selectedDates = {
    start: newStart.format(standardDateFormat),
    end: newEnd.format(standardDateFormat)
  };

  if (preset === PREVIOUS_PERIOD_PRESETS.custom) {
    newPeriod.customPeriodDates = {
      start: newStart.format(standardDateFormat),
      end: newEnd.format(standardDateFormat)
    };
  }

  return newPeriod;
};
