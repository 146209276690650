// @flow

import styled from 'styled-components';
import { prettyTaxonomyLabel } from '../../utils/taxonomyHelpers';

import type { labelObjectT } from '../../utils/metricsTableHelpers';

type Props = {
  labelObject: labelObjectT,
  color?: string
};

export const Taxonomy = styled.span`
  display: block;
  font-weight: 400;
  ${({ color }) => (color ? `color: ${color};` : '')}
`;

export const HeadingLabel = ({ labelObject, color }: Props) => {
  return (
    <div>
      {labelObject.label}
      <Taxonomy color={color}>
        {prettyTaxonomyLabel(labelObject.subLabel)}
      </Taxonomy>
    </div>
  );
};

export default HeadingLabel;
