import styled from 'styled-components';

export const CenteredCell = styled.td`
  text-align: center !important;
`;
export const HeaderCell = styled.th`
  text-align: ${({ center }) => (center ? 'center !important' : 'left')};
  min-width: 150px;
  ${({ sortable }) => sortable && 'cursor: pointer;'}
`;

export const StyledTable = styled.table`
  width: 100%;
  > tbody > tr > td {
    vertical-align: middle;
  }
`;

export const StyledHeader = styled.thead`
  ${({ themeFont }) => themeFont && `font-family: ${themeFont};`}
  > tr > th {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
  }
`;

export const StyledRow = styled.tr`
  ${({ hover }) =>
    hover &&
    `cursor: pointer;
   &:hover {
    background-color: rgba(191, 204, 214, 0.5); // bp3 but less opaque
  }`}
`;
