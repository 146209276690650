// @flow

import styled from 'styled-components';
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { DIVIDER } from './dates-presets-config';
import { getDateRangeLabelFormat } from '../../utils/dates';
import * as PERIOD_PRESETS from '../../constants/period-presets';

import * as PeriodModel from '../../models/period';
import type {
  defaultPresetsAllowedT,
  periodPresetConfigT
} from './dates-presets-config';

const StyledMenuItem = styled(MenuItem)`
  .bp3-text-overflow-ellipsis {
    margin-right: 2rem;
  }
`;

type menuProps = {
  datePresetsAllowed: defaultPresetsAllowedT,
  currentSelectedDatePreset: PeriodModel.periodPresetsT,
  handlePresetClick(period: PeriodModel.t): void,
  handleOpenDatePickerWithConfirm(): void,
  currentPeriod: PeriodModel.t,
  periodPresetConfig: periodPresetConfigT
};
const DatePickerMenu = ({
  datePresetsAllowed,
  currentSelectedDatePreset,
  handlePresetClick,
  handleOpenDatePickerWithConfirm,
  currentPeriod,
  periodPresetConfig
}: menuProps) => {
  return (
    <Menu>
      {datePresetsAllowed.map((datePreset, index) => {
        if (datePreset === DIVIDER) {
          return <MenuDivider key={`${index}-${datePreset}`} />;
        }

        // $FlowFixMe -> Flow dones't check the content of DIVIDER, and thinks that this could be at any point a DIVIDER instead of an periodPresetsT
        const presetConfig = periodPresetConfig[datePreset];
        // $FlowFixMe -> Flow dones't check the content of DIVIDER, and thinks that this could be at any point a DIVIDER instead of an periodPresetsT
        const period = PeriodModel.getExplorerPeriod(datePreset);

        const customLabel =
          currentSelectedDatePreset === PERIOD_PRESETS.custom && currentPeriod
            ? getDateRangeLabelFormat(currentPeriod.selectedDates, presetConfig)
            : '';
        const label =
          datePreset === PERIOD_PRESETS.custom
            ? customLabel
            : getDateRangeLabelFormat(period.selectedDates, presetConfig);

        const handleMenuItemClick = () => {
          if (datePreset === PERIOD_PRESETS.custom) {
            handleOpenDatePickerWithConfirm();
          } else {
            handlePresetClick(period);
          }
        };

        return (
          <StyledMenuItem
            key={`${index}-${datePreset}`}
            icon={currentSelectedDatePreset === datePreset ? 'tick' : 'blank'}
            text={presetConfig.title}
            label={label}
            onClick={handleMenuItemClick}
          />
        );
      })}
    </Menu>
  );
};

export default DatePickerMenu;
