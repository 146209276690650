export const PoweredBy = ({ fill = '#575757', textFill = '#000' }) => (
  <svg
    width="32.595097mm"
    height="5.8283243mm"
    viewBox="0 0 32.595097 5.8283243"
    version="1.1"
    id="svg29897"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs29894" />
    <g id="layer1" transform="translate(-104.73305,-45.415463)">
      <g
        fill={fill}
        id="g162"
        transform="matrix(0.11326354,0,0,0.11326354,104.72184,47.645128)"
      >
        <path
          d="M 70.64,26.67 H 68 l -2.55,-6 H 55.16 l -2.55,6 H 50 L 58.84,5.84 h 3 z M 64.57,18.45 60.32,8.29 56.07,18.45 Z"
          id="path140"
        />
        <path
          d="M 94.71,5.84 V 17.7 a 9.12,9.12 0 0 1 -2.47,6.68 9.3,9.3 0 0 1 -12.77,0 A 9.12,9.12 0 0 1 77,17.7 V 5.84 h 2.36 v 11.92 a 6.86,6.86 0 0 0 2,5.26 6.33,6.33 0 0 0 4.47,1.6 6.44,6.44 0 0 0 4.5,-1.6 6.81,6.81 0 0 0 2,-5.26 V 5.84 Z"
          id="path142"
        />
        <path
          d="m 106.64,26.67 h -2.36 V 5.84 h 10 a 7.22,7.22 0 0 1 5.1,1.76 6.07,6.07 0 0 1 1.92,4.75 5.29,5.29 0 0 1 -3.3,5.32 9.66,9.66 0 0 1 -1.79,0.6 l 5.47,8.4 h -2.8 l -5,-8 h -7.24 z m 0,-10.23 h 7.2 q 3.34,0 4.6,-2.08 a 3.88,3.88 0 0 0 0.47,-2 A 3.9,3.9 0 0 0 117.56,9.23 5.34,5.34 0 0 0 114,8.07 h -7.33 z"
          id="path144"
        />
        <path d="m 195.53,26.67 h -2.36 V 5.84 h 2.36 z" id="path146" />
        <path
          d="m 207.74,9 a 2.54,2.54 0 0 0 -0.8,1.15 4.18,4.18 0 0 0 -0.21,1.34 1.8,1.8 0 0 0 0.62,1.3 4.73,4.73 0 0 0 1.62,1 16.79,16.79 0 0 0 2.29,0.72 l 2.63,0.65 c 0.89,0.22 1.77,0.47 2.63,0.75 a 9.91,9.91 0 0 1 2.29,1.09 4.37,4.37 0 0 1 2.24,3.87 5.13,5.13 0 0 1 -2.19,4.47 10.35,10.35 0 0 1 -6.06,1.54 c -2.91,0 -5.14,-0.61 -6.67,-1.83 a 6.4,6.4 0 0 1 -2.32,-4.65 h 2.45 a 3.88,3.88 0 0 0 2.08,3.27 9,9 0 0 0 4.5,1 c 3.9,0 5.85,-1.22 5.85,-3.68 a 2.26,2.26 0 0 0 -1.06,-2 9.53,9.53 0 0 0 -2.64,-1.2 c -1.06,-0.31 -2.2,-0.6 -3.43,-0.88 a 21.18,21.18 0 0 1 -3.43,-1.05 7.86,7.86 0 0 1 -2.64,-1.7 3.86,3.86 0 0 1 -1.06,-2.85 5.13,5.13 0 0 1 2,-4.15 q 2,-1.61 5.88,-1.61 a 9.28,9.28 0 0 1 6.14,1.83 5.42,5.42 0 0 1 2.1,4.12 h -2.42 Q 217.77,9 214.76,8.1 A 9.61,9.61 0 0 0 212.1,7.79 8.64,8.64 0 0 0 209.42,8.15 4.88,4.88 0 0 0 207.74,9 Z"
          id="path148"
        />
        <path d="m 231.78,26.67 h -2.36 V 5.84 h 2.36 z" id="path150" />
        <path
          d="m 250.85,5.58 a 10.12,10.12 0 0 1 7.64,3.12 10.42,10.42 0 0 1 2.93,7.55 10.42,10.42 0 0 1 -2.93,7.55 10.93,10.93 0 0 1 -15.29,0 10.42,10.42 0 0 1 -2.93,-7.55 10.42,10.42 0 0 1 2.93,-7.55 10.12,10.12 0 0 1 7.65,-3.12 z m 0,19 a 7.94,7.94 0 0 0 6,-2.42 8.35,8.35 0 0 0 2.2,-5.95 8.32,8.32 0 0 0 -2.2,-5.94 8.65,8.65 0 0 0 -12,0 8.29,8.29 0 0 0 -2.21,5.94 8.31,8.31 0 0 0 2.21,5.95 7.91,7.91 0 0 0 6,2.46 z"
          id="path152"
        />
        <path
          d="m 287.88,5.84 v 20.83 h -2.74 L 272.28,9.08 v 17.59 h -2.36 V 5.84 h 2.73 l 12.87,17.52 V 5.84 Z"
          id="path154"
        />
        <path
          d="M 29.82,8 23.38,23.88 22,20.58 28,5.46 A 16,16 0 0 0 2.2,24.08 L 10.11,5.46 h 3.12 v 0.08 l 8.92,21 H 19.29 L 15.8,18.25 11.67,8.36 7.61,18.08 h 5.75 l 1,2.42 H 6.59 L 4,26.54 A 16,16 0 0 0 29.82,8 Z"
          id="path156"
        />
        <path
          d="m 177.08,26.67 h -2.41 L 166.24,5.84 h 2.58 z m 1.41,-2.77 7.32,-18.06 h -2.58 l -5.95,15 z"
          id="path158"
        />
        <path
          d="m 139.39,5.84 h -2.95 l -8.85,20.83 h 2.62 l 2.54,-6.04 h 7.7 l -0.92,-2.18 h -5.86 l 4.24,-10.16 4.25,10.16 0.91,2.18 2.55,6.04 h 2.62 z"
          id="path160"
        />
      </g>
      <text
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '2.11667px',
          lineHeight: '1.25',
          fontFamily: 'sans-serif',
          fillOpacity: '1',
          stroke: 'none',
          strokeWidth: '0.0933291',
          fill: textFill,
        }}
        x="104.55588"
        y="47.023636"
        id="text2841"
      >
        <tspan
          id="tspan2839"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontSize: '2.11667px',
            fontFamily: 'sans-serif',
            InkscapeFontSpecification: 'sans-serif',
            strokeWidth: '0.0933291',
            fill,
          }}
          x="104.55588"
          y="47.023636"
        >
          Powered by
        </tspan>
      </text>
    </g>
  </svg>
);
