export const ORG_HAS_STAFF_DETECTION = 'orgHasStaffDetection';
export const SHOW_LIVE_PAGE = 'showLivePage';
export const SHOW_DEMO_LIVE_PAGE = 'showDemoLivePage';
export const IS_DEMO_USER = 'isDemoUser';
export const DISABLE_AGE_GENDER = 'disableAgeAndGenderOnFrontEnd';
export const IGNORE_LINGER_UNDER = 'ignoreLingerUnderOverride';
export const SHOW_HEADCOUNT_PLANNER = 'showHeadcountPlanner';

// NB. IF ADDING A NEW FEATURE ABOVE, IT NEEDS TO BE ALSO ADDED TO THE LIST BELOW TO BE DETECTED ON AUTH

export const ALL_FEATURES_LIST = [
  ORG_HAS_STAFF_DETECTION,
  SHOW_LIVE_PAGE,
  SHOW_DEMO_LIVE_PAGE,
  DISABLE_AGE_GENDER,
  IGNORE_LINGER_UNDER,
  IS_DEMO_USER,
  SHOW_HEADCOUNT_PLANNER,
];
