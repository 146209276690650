// @flow

import { useState, useEffect } from 'react';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import * as LocationModel from '../../../models/location';
import WidgetUI from '../../widgets-ui/map';
import { getQueryPayloadType, buildPayload } from '../../../services/query-api';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { IS_EMAIL_VIEW } from '../../../config/vars';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';

const MapWidget = ({
  widget,
  availableMetrics,
  locations,
  uiOptions = {},
}: WidgetPropsModel.mapT) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [widgetDescription, setWidgetDescription] = useState('');
  const [unitType, setUnitType] = useState('number');
  const [points, setPoints] = useState([]);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible(
    (vi) => IS_EMAIL_VIEW || uiOptions.disableViewportLoading || vi > 0
  );

  const usingComps =
    !!widget.comparisonPeriod &&
    widget.comparisonPeriod.active &&
    widget.showChangeValue;

  const loadWidgetData = async (metric) => {
    setLoading(true);
    setError(false);

    // build the request body and fetch data for each metric for each period
    const path = apiEndpointByMetricKey(metric.metricKey);
    const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
      metric.details
    );
    const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

    const rows = widget.locations.map((locationId) => ({
      ...LocationModel.getLocationLatLng(locationId, locations), // spread the lat and lng
      label: LocationModel.getLocationName(locationId, locations),
      locationId,
    }));

    const payloadInput = {
      demographicFilter: widget.demographicFilter,
      aggregation: 'day', // we can hardcode for this widget because we know we don't need segments
      metricKey: metric.metricKey,
      period: widget.period,
      locations: widget.locations,
      taxonomies: metric.taxonomies ? metric.taxonomies : undefined,
      filter: metric.filter,
      breakdownByDimensions: ['entity'],
    };

    const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

    const response = await fetchSQLQuery(payloadInitialPeriod, path, {
      returnErrors: true,
      return404AsError: false,
      metricKey: metric.metricKey,
    });

    let compResponse = null;
    if (usingComps) {
      const payloadComparisonPeriod = buildPayload(queryPayloadType, {
        ...payloadInput,
        period: widget.comparisonPeriod,
      });

      compResponse = await fetchSQLQuery(payloadComparisonPeriod, path, {
        returnErrors: true,
        return404AsError: false,
        metricKey: metric.metricKey,
      });
    }

    const builtPoints = rows.map((row) => {
      // extract the values and changeValues

      const value = QueryResponseModel.getSummaryValue({
        response,
        locationId: row.locationId,
        metricKey: metric.metricKey,
      });

      const compValue = compResponse
        ? QueryResponseModel.getSummaryValue({
            response: compResponse,
            locationId: row.locationId,
            metricKey: metric.metricKey,
          })
        : null;

      const { result } =
        compValue === null || value === null
          ? { result: null }
          : prettyValueToCompare(compValue, value);
      const changeValue = usingComps ? result : null;

      // this will repeat multiple times but it's OK because it will always be the same value
      setUnitType(QueryResponseModel.getUnitType(response, metric.metricKey));

      // decorate each response with information from availableMetrics, and the API response
      return {
        label: row.label,
        value,
        changeValue,
        lat: row.lat,
        lng: row.lng,
      };
    });

    if (!builtPoints || builtPoints.every((p) => !p)) {
      setError(true);
      setLoading(false);
      console.error('Every API call for Map widget failed');
    } else {
      const points: Array<Object> = builtPoints.filter((p) => !!p);
      setPoints(points);
      setLoading(false);
    }
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      // get the information from available metrics data for the metric
      const metric = {
        ...widget.metric,
        details: AvailableMetricsModel.findMetricDetails(
          availableMetrics,
          widget.metric.metricGroupKey,
          widget.metric.metricKey
        ),
      };

      if (!metric || !metric.details) {
        console.error('No metric found in availableMetrics for the Map widget');
        setError(true);
      } else {
        setAutoTitle(
          `${getColumnLabel(metric, availableMetrics, true, true)}, by location`
        );
        if (!uiOptions.disableDescription) {
          setWidgetDescription(
            AvailableMetricsModel.getMetricDescription(metric.details)
          );
        }
        loadWidgetData(metric);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget, true);

  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        title={title}
        subtitle={subtitle}
        description={widgetDescription}
        points={points}
        unitType={unitType}
        dataKey={widget.showChangeValue ? 'changeValue' : 'value'}
      />
    </div>
  );
};

export default MapWidget;
