import { useState, useEffect } from 'react';
import history from '../../../root/history';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';

const Item = styled.h3`
  color: ${({ selected, depth }) =>
    selected && depth === 0
      ? 'var(--sidebar-active-text)'
      : 'var(--sidebar-text)'};
  font-size: 0.9rem;
  background: ${({ selected, depth }) =>
    selected && depth === 0 ? 'var(--menu-active)' : null};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: ${({ depth }) => (depth > 0 ? `calc(calc(16px * ${depth}) + 2.25rem)` : null)};
  margin: 0;

  &:hover {
    color: ${({ selected, depth }) =>
      selected && depth === 0
        ? 'var(--sidebar-active-text)'
        : 'var(--sidebar-hover-text)'};
  }
`;
const ItemIcon = styled(Icon)`
  margin-right: 1rem;
`;

const MenuItem = ({ item, depth = 0, location }) => {
  const getSelected = (route, depth) => {
    let selected = false;
    if (route.includes('?')) route = route.split('?')[0];
    if (route === window.location.pathname) selected = true;

    if (!selected) {
      if (depth === 0 && typeof window === 'object') {
        // find the first part of the route that is not blank
        const routeFirstPart = route.split('/').find((str) => !!str);
        // find the first part of the window.location.pathname that is not blank
        const currentPathFirstPart = window.location.pathname
          .split('/')
          .find((str) => !!str);
        selected = routeFirstPart === currentPathFirstPart;
      }
    }

    return selected;
  };

  const [selected, setSelected] = useState(getSelected(item.path, depth));

  const getOnClick = (route, location) => (e) => {
    history.push(route, location.state);
  };

  useEffect(() => {
    return history.listen((location) =>
      setSelected(getSelected(item.path, depth)),
    );
  }, [depth, item.path]);

  return (
    <>
      <Item
        key={item.label}
        onClick={getOnClick(item.path, location)}
        selected={selected}
        depth={depth || 0}
      >
        <ItemIcon icon={item.icon} />
        <span>{item.label}</span>
      </Item>
      {(selected || item.alwaysExpanded) &&
        item.children &&
        item.children.map((child) => (
          <MenuItem
            key={child.label}
            item={child}
            depth={depth + 1}
            location={location}
          />
        ))}
    </>
  );
};

export default MenuItem;
