import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';

export const Desktop = styled.div`
  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    display: none;
  }
`;

export const Mobile = styled.div`
  @media (min-width: ${breakpoints.TABLET_SIZE_PLUS_1}) {
    display: none;
  }
`;

export const BiggerTablet = styled.div`
  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    display: none;
  }
`;

export const Tablet = styled.div`
  @media (min-width: ${breakpoints.SMALL_PC_SIZE_PLUS_1}) {
    display: none;
  }
`;
