// @flow
import { useContext } from 'react';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import { ThemeContext } from 'styled-components';

type Props = {
  x?: number,
  y?: number,
  payload?: { value: number },
  unitType: string,
  textAnchor: string,
};

const CustomizedYAxisTick = ({
  x,
  y,
  payload,
  unitType,
  textAnchor,
}: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor={textAnchor}
        fill={theme ? theme.properties['--widget-heading-text'] : null}
        transform="rotate(0)"
      >
        {formatNumbersWithUnits(payload ? payload.value : 0, unitType)}
      </text>
    </g>
  );
};

export default CustomizedYAxisTick;
