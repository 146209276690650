const { AxiosHttpClient } = require('./axios-http-client');
const { BaseApiException } = require('./exceptions/base-api-exception');

/**
 * Base class api functionality
 * This must be constructed with
 * a httpAdapter that implements
 * makeRequest method as defined
 * in AxiosHttpClient.makeRequest
 *
 * logger - a function that
 * takes an object {message, contextObject}
 * and logs it as 'info' e.g. .info at
 * aura-emailer.git/src/services/logger/index.js
 *
 * envBasePath - the environment url for the
 * api in use
 *
 * jwtGenerator - function that when called
 * returns a valid JWT
 *
 * cacheFacade - found in
 * @auravisionlabs/aura-pkg-cache-facade
 */
// decorateUrlQuery cannot be static as it is called by CoreApi using {this}
// Late static binding not available in JS
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["decorateUrlQuery"] }] */
class BaseApi {
  constructor(logger, envBasePath, jwtGenerator, cacheFacade = null) {
    this.httpAdapter = AxiosHttpClient;
    this.cacheFacade = cacheFacade;
    this.logger = logger;
    this.envBasePath = envBasePath;
    this.jwtGenerator = jwtGenerator;
  }

  /**
   * Allows updates to the client post construction
   * @param client
   */
  setHttpClient(client) {
    this.httpAdapter = client;
  }

  /**
   * Gets the base headers and allows merge of
   * additional headers
   *
   * @param headersToMerge
   * @returns {object}
   */
  async getHeaders(headersToMerge = {}) {
    return {
      ...headersToMerge,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${await this.getAuthToken()}`,
    };
  }

  /**
   * Decorates a URL with a query passed
   * as on object of param / value pairs
   *
   * @param url
   * @param queryObject
   * @returns {string}
   */
  decorateUrlQuery(url, queryObject) {
    const urlObj = new URL(url);
    const query = urlObj.search;
    const searchParams = new URLSearchParams(query);

    Object.entries(queryObject).map(([searchKey, searchValue]) =>
      searchParams.append(searchKey, searchValue),
    );

    urlObj.search = searchParams.toString();
    return urlObj.toString();
  }

  /**
   * Makes a request via http adapter
   * Return the full response payload
   *
   * @param method
   * @param url
   * @param headers
   * @param payload
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async makeRequest(
    method,
    url,
    headers,
    payload,
    shouldCache,
    cacheTtl,
    timeout,
  ) {
    const params = {
      logger: this.logger,
      method,
      headers,
      url,
      payload,
      shouldCache,
      cacheFacade: this.cacheFacade,
      cacheTtl,
      timeout,
    };
    try {
      const response = await this.httpAdapter.makeRequest(params);
      return {
        payload: response.payload,
      };
    } catch (error) {
      throw new BaseApiException(
        `BaseApi.makeRequest has failed for params: ${JSON.stringify({
          method,
          headers,
          url,
          payload,
        })}`,
        error,
      );
    }
  }

  /**
   * Return Auth token for endpoint
   *
   * @returns {*}
   */
  async getAuthToken() {
    return this.jwtGenerator();
  }
}

module.exports = {
  BaseApi,
};
