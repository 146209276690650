// @flow

export const AGGREGATION_PERIOD = {
  QUARTER_HOUR: '15min',
  HOUR: 'hour',
  DAY: 'day',
  WEEK_ISO: 'week_iso',
  WEEK_US: 'week_us',
  MONTH: 'month',
  DAY_OF_WEEK: 'dayofweek',
  HOUR_OF_DAY: 'hourofday',
  DOW_HOD: 'dayofweek-hourofday',
  COMBINED: 'combined',
};

export const AGGREGATION_PERIOD_LABELS = {
  [AGGREGATION_PERIOD.QUARTER_HOUR]: 'Quarter hourly',
  [AGGREGATION_PERIOD.HOUR]: 'Hourly',
  [AGGREGATION_PERIOD.DAY]: 'Daily',
  [AGGREGATION_PERIOD.WEEK_ISO]: 'Weekly (Mon-Sun)',
  [AGGREGATION_PERIOD.WEEK_US]: 'Weekly (Sun-Sat)',
  [AGGREGATION_PERIOD.MONTH]: 'Monthly',
  [AGGREGATION_PERIOD.COMBINED]: 'Entire date range',
  [AGGREGATION_PERIOD.DAY_OF_WEEK]: 'Day of week',
  [AGGREGATION_PERIOD.HOUR_OF_DAY]: 'Hour of day',
  [AGGREGATION_PERIOD.DOW_HOD]: 'Day of week, and hour of day',
};

export const AGGREGATION_PERIOD_LABELS_SENTENCE = {
  [AGGREGATION_PERIOD.QUARTER_HOUR]: 'quarter hour',
  [AGGREGATION_PERIOD.HOUR]: 'hour',
  [AGGREGATION_PERIOD.DAY]: 'day',
  [AGGREGATION_PERIOD.WEEK_ISO]: 'week (Mon-Sun)',
  [AGGREGATION_PERIOD.WEEK_US]: 'week (Sun-Sat)',
  [AGGREGATION_PERIOD.MONTH]: 'month',
  [AGGREGATION_PERIOD.COMBINED]: 'entire period',
  [AGGREGATION_PERIOD.DAY_OF_WEEK]: 'day of week',
  [AGGREGATION_PERIOD.HOUR_OF_DAY]: 'hour of day',
  [AGGREGATION_PERIOD.DOW_HOD]: 'day of week, and hour of day',
};

export const CHART_TYPE = {
  LINE: 'line',
  AREA: 'area',
  REL_BAR: 'rel_bar',
  PIE: 'pie',
};

export const BREAKDOWN_BY = {
  TOTAL_ONLY: 'total_only',
  AGE: 'age',
  GENDER: 'gender',
  ROLE: 'role',
  LOCATION: 'location',
};

export const CHART_DATA = {
  COMPARE: '__compare',
  CHANGE: '_change',
};

export const AXIS_TYPE = {
  CONTINUOUS: 'continuous',
  DAY_OF_MONTH: 'dayofmonth',
  DAY_OF_WEEK: 'dayofweek',
  HOUR_OF_DAY: 'hourofday',
  DOW_HOD: 'dayofweek-hourofday',
};

export const AXIS_GROUPING = {
  SINGLE: 'single',
  BY_METRIC: 'byMetric',
  BY_UNIT: 'byUnit',
};
export const AXIS_GROUPING_LABELS = {
  [AXIS_GROUPING.SINGLE]: 'Combined Y axis',
  [AXIS_GROUPING.BY_METRIC]: 'Separated Y axis by metric',
  [AXIS_GROUPING.BY_UNIT]: 'Separated Y axis by unit',
};

// These are hacks for flow - I can't get it to auto-populate an enum from an object...
export type aggregationEnum =
  | '15min'
  | 'hour'
  | 'day'
  | 'week_iso'
  | 'week_us'
  | 'month'
  | 'combined'
  | 'dayofweek'
  | 'hourofday'
  | 'dayofweek-hourofday';
export type chartTypeEnum = 'line' | 'area' | 'rel_bar' | 'pie';
export type breakdownByEnum =
  | 'total_only'
  | 'age'
  | 'gender'
  | 'role'
  | 'location'
  | 'taxonomy';
export type axisGroupingEnum = 'single' | 'byMetric' | 'byUnit';

export const MAX_DAYS_SUPPORTED: { [key: aggregationEnum]: number } = {
  [AGGREGATION_PERIOD.QUARTER_HOUR]: 7,
  [AGGREGATION_PERIOD.HOUR]: 31,
  [AGGREGATION_PERIOD.DAY]: Infinity,
  [AGGREGATION_PERIOD.WEEK_ISO]: Infinity,
  [AGGREGATION_PERIOD.WEEK_US]: Infinity,
  [AGGREGATION_PERIOD.MONTH]: Infinity,
  [AGGREGATION_PERIOD.DAY_OF_WEEK]: Infinity,
  [AGGREGATION_PERIOD.HOUR_OF_DAY]: Infinity,
  [AGGREGATION_PERIOD.COMBINED]: Infinity,
};
