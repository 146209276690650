//@flow

import styled from 'styled-components';
import { Icon, Checkbox, Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { TableHeading } from './table';
import HeadingLabel from './heading-label';
import {
  getColumnLabelAsObject,
  getDescriptionFromAvailableMetrics
} from '../../utils/metricsTableHelpers';
import SortMetricsMenu from '../pickers/sort-metrics-menu';
import { areTwoArraysEquals } from '../../utils/generalHelpers';
import { breakpoints } from '../../styles/variables';

import type { sortByT } from './index';
import type { labelObjectT } from '../../utils/metricsTableHelpers';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as ActiveMetricsModel from '../../models/active-metrics';

export const IconStyled = styled(Icon)`
  margin: 0;
  margin-left: 0.5em;
  &:hover {
    cursor: pointer;
    color: gray;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  &&& {
    display: inline;
  }

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    &&& {
      display: none;
    }
  }
`;
const IconsWrapper = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
`;
const IconWrapperLeft = styled.div`
  display: inline-block;
  margin-right: auto;
  flex: 1;
  width: fit-content;

  &:hover {
    cursor: pointer;
    color: gray;
  }
`;
const IconWrapperRight = styled.div`
  display: inline-block;
  flex: 1;
  width: fit-content;
`;

type Props = {
  label: string | number | labelObjectT,
  sortBy: sortByT,
  handleSortBy(sortBy: sortByT): void,
  columnDetails: ActiveMetricsModel.itemT,
  columnsNumber: number,
  availableMetrics?: AvailableMetricsModel.t,
  isComparisonActive?: boolean,
  currentCellWidth?: number,
  locationsSelectedForComparison?: string[],
  filteredLocations?: string[],
  toggleLocation?: (location: string | string[]) => void
};

const SortableTableHeading = ({
  label,
  sortBy,
  columnDetails,
  handleSortBy,
  columnsNumber,
  availableMetrics,
  isComparisonActive,
  currentCellWidth,
  locationsSelectedForComparison,
  filteredLocations,
  toggleLocation
}: Props) => {
  const labelObject =
    availableMetrics && getColumnLabelAsObject(columnDetails, availableMetrics);

  const isAllLocationSelected =
    locationsSelectedForComparison &&
    areTwoArraysEquals(locationsSelectedForComparison, filteredLocations || []);

  const description =
    availableMetrics &&
    getDescriptionFromAvailableMetrics(columnDetails, availableMetrics);

  return (
    <TableHeading
      columnsNumber={columnsNumber}
      currentCellWidth={currentCellWidth}
    >
      {ActiveMetricsModel.isGeneralLocationHeading(columnDetails) && (
        <StyledCheckbox
          checked={isAllLocationSelected}
          onChange={() => {
            if (toggleLocation && filteredLocations) {
              toggleLocation(filteredLocations);
            }
          }}
        />
      )}
      {labelObject ? (
        <HeadingLabel labelObject={labelObject} />
      ) : typeof label === 'object' ? (
        <HeadingLabel labelObject={label} />
      ) : (
        label
      )}
      <IconsWrapper>
        {description && (
          <IconWrapperLeft>
            <Tooltip2 content={description} position={Position.TOP}>
              <Icon icon="help" iconSize={15} />
            </Tooltip2>
          </IconWrapperLeft>
        )}
        <IconWrapperRight>
          <SortMetricsMenu
            columnDetails={columnDetails}
            isComparisonActive={isComparisonActive}
            sortBy={sortBy}
            handleSortBy={handleSortBy}
          />
        </IconWrapperRight>
      </IconsWrapper>
    </TableHeading>
  );
};

export default SortableTableHeading;
