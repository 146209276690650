export const getEmailsAsArray = (selectedDistribution) => {
  if (Array.isArray(selectedDistribution.toEmails)) return selectedDistribution.toEmails;

  const emailsStr = selectedDistribution.toEmails;

  if (!emailsStr || typeof emailsStr !== 'string' || !emailsStr.length)
    return [];

  return emailsStr.split(',').map((str) => str.trim());
};
