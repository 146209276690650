import { getDeviceType } from '../../utils/device-type';
import { dayOfWeekLabel } from '../../utils/dates';
import RowHeatmap from '../widgets-ui/row-heatmap';

// transpose Sun -> Sat to Mon -> Sun
const transposeDays = (rows) => {
  return rows && rows.length ? [rows[1], rows[2], rows[3], rows[4], rows[5], rows[6], rows[0]] : rows;
};

const WidgetWrapper = ({
  title,
  subtitle,
  data,
  colHeadings,
  loading,
  error,
  colorScheme,
}) => {
  if ((data && data.length) || loading || error) {
    const rows = data
      ? data.map((day, i) => ({
          label: dayOfWeekLabel(i, getDeviceType() === 'phone'),
          cols: day.map((hour) => ({
            value: hour,
            changeValue: 0,
          })),
        }))
      : [];

    const transposedRows = transposeDays(rows);

    return (
      <RowHeatmap
        title={title}
        subtitle={subtitle}
        tableColumns={colHeadings.map((h) => ({ label: h }))}
        tableRows={transposedRows.filter(d => !!d)}
        dataKey={'value'}
        loading={loading}
        errors={error}
        shadeBy={'table'}
        colorScheme={colorScheme}
        accessible={true}
      />
    );
  }
  return <></>;
};

export default WidgetWrapper;
