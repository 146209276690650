// @flow

import { connect } from 'react-redux';
import ViewToken from '../components/pages/view-token';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  };
}

export default connect(mapStateToProps)(ViewToken);
