// @flow

import { connect } from 'react-redux';
import * as Selectors from '../selectors';
import { ORG_HAS_STAFF_DETECTION } from '../constants/features';
import SingleReportPage from '../components/pages/single-report';
import { getUrlHashAsObject } from '../utils/urlHelpers';
import { makeAmendmentsToReport } from '../utils/reports';
import { getOptions } from '../models/user';

function mapStateToProps(state, ownProps) {
  const { match, location } = ownProps;

  const sourceReportData = Selectors.getOneReport(match.params.reportId, state);
  let startingReport = sourceReportData;

  if (location.hash) {
    try {
      const amendments = getUrlHashAsObject(location.hash);
      startingReport = makeAmendmentsToReport(sourceReportData, amendments);
    } catch (e) {
      console.error(
        'Couldn\'t make desired amends to report using the URL hash'
      );
    }
  }

  return {
    ...ownProps,
    allReports: Selectors.getReports(state),
    startingReport,
    showStaffFilters: Selectors.userHasFeature(ORG_HAS_STAFF_DETECTION, state),
    allLocationsList: Selectors.getLocations(state),
    availableMetrics: Selectors.getAvailableMetrics(state),
    startOfWeek: getOptions(state.user.model).startOfWeek,
  };
}

export default connect(mapStateToProps)(SingleReportPage);
