import { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getAuth0Client } from '../../../services/auth0';
import * as ROUTES from '../../../constants/routes';
import PreInitLoading from '../pre-init-loading';

class LoginPage extends Component {
  state = { redirectToHome: false };

  async componentDidMount() {
    const auth0 = getAuth0Client();
    if (auth0) {
      const isAuthenticated = await auth0.isAuthenticated();
      let connection = 'Username-Password-Authentication';

      const params = (new URL(document.location)).searchParams;
      if (params.get('connection')) {
        connection = params.get('connection');
      }

      if (isAuthenticated) {
        this.setState({ redirectToHome: true });
      } else {
        console.log('User is not authenticated');
        await auth0.loginWithRedirect({
          redirect_uri: window.location.origin,
          connection,
        });
      }
    }
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to={{ pathname: ROUTES.HOME }} />;
    }

    return <PreInitLoading />;
  }
}

export default LoginPage;
