// @flow

export type endpointT = {
  sendNoFilters: boolean,
};
export type metricT = {
  key: string,
  label: string,
  endpoint: endpointT,
  showOnStartup: true,
  description: string,
};
export type filterItemT = {
  key: string,
  values: string[],
};

export type filtersT = Array<filterItemT>;

export type itemT = {
  description: string,
  title: string,
  metrics: metricT[],
  taxonomies?: string[],
  filters?: filtersT,
  showOnStartupFilters?: true,
  showOnStartupTaxonomies: string[],
  taxonomySelectLabel?: string,
};

export type t = {
  [metricGroupKey: string]: itemT,
};

export const findMetricDetails = (
  availableMetrics: t,
  metricGroupKey: string,
  metricKey: string
): ?metricT => {
  return availableMetrics &&
    availableMetrics[metricGroupKey] &&
    availableMetrics[metricGroupKey].metrics &&
    availableMetrics[metricGroupKey].metrics.length > 0
    ? availableMetrics[metricGroupKey].metrics.find((m) => m.key === metricKey)
    : null;
};

export const getMetricLabel = (metric: ?metricT): string =>
  (metric && metric.label) || '';

export const getMetricDescription = (metric: ?metricT): string =>
  (metric && metric.description) || '';

export const getMetricEndpoint = (metric: ?metricT): endpointT =>
  (metric && metric.endpoint) || {};
