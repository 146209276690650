// @flow

import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { getStatus, getStatusColour } from '../../../utils/live-occupancy';

import type { locationConfigT } from '../live-occupancy/location-occupancy-row';

const LiveStatusText = styled.div`
  font-size: 4em;
  color: rgba(255, 255, 255, 0.75);
`;
const StatusSectionWrapper = styled.div`
  width: 60%;
  padding: 5%;
  background: ${props => props.bgColor};
`;
const TypingIndicator = styled.div`
  will-change: transform;
  width: auto;
  display: table;
  position: relative;

  span {
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 1px;
    background-color: white;
    display: block;
    border-radius: 50%;
    opacity: 0.25;

    &:nth-of-type(1) {
      animation: 1s blink infinite ${1 * 0.3333}s;
    }
    &:nth-of-type(2) {
      animation: 1s blink infinite ${2 * 0.3333}s;
    }
    &:nth-of-type(3) {
      animation: 1s blink infinite ${3 * 0.3333}s;
    }
  }
}
@keyframes blink {
  50% {
    opacity: 0.8;
  }
}`;

type Props = {
  location: locationConfigT,
  isDemoUser: boolean,
};

const StatusSection = ({ location, isDemoUser }: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <StatusSectionWrapper
      bgColor={getStatusColour({ location, excludeStaff: false }, theme)}
    >
      <LiveStatusText>
        <h3>{getStatus({ location, excludeStaff: false, isDemoUser })}</h3>
        <TypingIndicator>
          <span />
          <span />
          <span />
        </TypingIndicator>
      </LiveStatusText>
    </StatusSectionWrapper>
  );
};

export default StatusSection;
