// @flow

import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import {
  ordinalSuffix,
  dayOfWeekLabel,
  hourOfDayLabel,
  weekLabel,
  getDatesListInRangePeriod,
  dowHodLabel,
} from './dates';
import { getColumnLabel, ALL_LOCATIONS } from './metricsTableHelpers';
import { prettyValueToCompare } from '../utils/summaryPageHelpers';
import { prettyLabel } from './prettyLabel';
import {
  AGGREGATION_PERIOD,
  CHART_TYPE,
  BREAKDOWN_BY,
  CHART_DATA,
  AXIS_TYPE,
} from '../constants/graph-options';
import { getUnitTypeFromActiveLine } from '../selectors/graphSelectors';

import * as PeriodModel from '../models/period';
import * as ActiveMetricsModel from '../models/active-metrics';
import * as PageSettingsModel from '../models/page-settings';
import * as AvailableMetricsModel from '../models/available-metrics';
import * as QueryResponseListModel from '../models/query-response-list';
import * as QueryApiRequestModel from '../models/query-api-request';
import * as QueryResponseModel from '../models/query-response';
import * as LocationModel from '../models/location';
import type { activeLinesT, chartDataT } from '../components/graph-tile';
import type {
  chartTypeEnum,
  aggregationEnum,
  breakdownByEnum,
} from '../constants/graph-options';
import type { itemT } from '../models/query-response-list';

export type axisTypeT =
  | 'continuous'
  | 'dayofMonth'
  | 'dayofweek'
  | 'hourofday'
  | 'dayofweek-hourofday';

const LOCATIONS_NAMES_CAP = 50;

const stringHacks = (str) => str.replace('+', '').replace('cant', 'zzz');
export const demographicValuesSortFn = (a: string, b: string): 1 | -1 => {
  return stringHacks(a) > stringHacks(b) ? 1 : -1;
};
export const orderDemographicValues = (values: string[]): string[] => {
  // this will order the age values. Need to remove the plus sign to
  // correctly order (e.g. +60 becomes 60)
  return values.sort(demographicValuesSortFn);
};

export const getAxisInterval = (
  aggregationPeriod?: aggregationEnum
): ?number => {
  if (aggregationPeriod === AGGREGATION_PERIOD.HOUR) {
    return 11;
  }
};

export const axisTimeFormatter = (
  value: any,
  aggregationPeriod?: aggregationEnum,
  axisType?: axisTypeT,
  shortDayOfWeekLabel?: boolean
): ?string => {
  if (axisType && axisType.toLowerCase() === AXIS_TYPE.DAY_OF_MONTH) {
    return ordinalSuffix(parseInt(value));
  }
  if (axisType && axisType.toLowerCase() === AXIS_TYPE.DAY_OF_WEEK) {
    return dayOfWeekLabel(value, shortDayOfWeekLabel);
  }
  if (axisType && axisType.toLowerCase() === AXIS_TYPE.HOUR_OF_DAY) {
    return hourOfDayLabel(value);
  }
  if (axisType && axisType.toLowerCase() === AXIS_TYPE.DOW_HOD) {
    return dayOfWeekLabel(value, shortDayOfWeekLabel);
  }

  const {
    QUARTER_HOUR,
    HOUR,
    MONTH,
    HOUR_OF_DAY,
    WEEK_ISO,
    WEEK_US,
    DAY_OF_WEEK,
    DOW_HOD,
  } = AGGREGATION_PERIOD;

  if (aggregationPeriod) {
    if ([QUARTER_HOUR, HOUR].includes(aggregationPeriod.toLowerCase())) {
      return moment(value).format('ddd HH:mm');
    }
    if (aggregationPeriod.toLowerCase() === MONTH) {
      return moment(value).format('MMM YYYY');
    }
    if (aggregationPeriod.toLowerCase() === HOUR_OF_DAY) {
      return hourOfDayLabel(value);
    }
    if (
      (aggregationPeriod.toLowerCase() === WEEK_ISO ||
        aggregationPeriod.toLowerCase() === WEEK_US) &&
      typeof value === 'string'
    ) {
      return weekLabel(value, true);
    }
    if (aggregationPeriod.toLowerCase() === DOW_HOD) {
      return dowHodLabel(value);
    }
    if (aggregationPeriod.toLowerCase() === DAY_OF_WEEK) {
      return dayOfWeekLabel(value, shortDayOfWeekLabel);
    }
  }

  return moment(value).format('Do MMM YYYY');
};

export const tooltipTimeFormatter = (
  value: string,
  aggregationPeriod: aggregationEnum
): string => {
  switch (aggregationPeriod.toLowerCase()) {
    case AGGREGATION_PERIOD.QUARTER_HOUR:
    case AGGREGATION_PERIOD.HOUR:
      return moment(value).format('ddd, Do MMM HH:mm');
    case AGGREGATION_PERIOD.MONTH:
      return moment(value).format('MMM YYYY');

    case AGGREGATION_PERIOD.HOUR_OF_DAY: {
      return hourOfDayLabel(Number(value));
    }
    case AGGREGATION_PERIOD.WEEK_ISO:
    case AGGREGATION_PERIOD.WEEK_US: {
      return weekLabel(value, false);
    }
    case AGGREGATION_PERIOD.DAY_OF_WEEK: {
      const day = Number(value) - 1;
      return dayOfWeekLabel(day) || value;
    }
    case AGGREGATION_PERIOD.DOW_HOD: {
      return dowHodLabel(value) || value;
    }
    default:
      return moment(value).format('ddd, Do MMM');
  }
};

export const getPcChartData = (
  chartData: chartDataT[],
  activeLines: activeLinesT[]
): chartDataT[] => {
  const pcChartData = [];
  const activeKeys = activeLines.map((line) => line.key);

  chartData.forEach((item) => {
    const newRow = { index: item.index };
    const total = activeKeys.reduce((a, b) => a + (!!item[b] && item[b]), 0);
    activeKeys.forEach((key) => {
      newRow[key] = !!item[key] ? (item[key] / total) * 100 : 0;
    });
    pcChartData.push(newRow);
  });

  return pcChartData;
};

export const getChartDataWithChangeInformation = (
  chartData: chartDataT[],
  isComparisonActive?: boolean = false
): chartDataT[] => {
  return chartData.map((singleChartData, index, arr) => {
    const newSingleChartData = cloneDeep(singleChartData);

    Object.entries(singleChartData).forEach(
      ([singleChartDataKey, singleChartDataValue]) => {
        const isNotIndex =
          singleChartDataKey !== 'index' &&
          singleChartDataKey !== 'compareToPastIndex';
        const isNotCompare = !singleChartDataKey.endsWith(CHART_DATA.COMPARE);
        const isValidChartDataKey = isComparisonActive
          ? isNotIndex && isNotCompare
          : isNotIndex && isNotCompare && index !== 0 && arr[index - 1];

        if (isValidChartDataKey) {
          let previousValue = 0;

          const valueToCompare = isComparisonActive
            ? arr[index][`${singleChartDataKey}${CHART_DATA.COMPARE}`]
            : arr[index - 1][singleChartDataKey];

          if (valueToCompare) {
            previousValue = valueToCompare;
          }
          const { result } = prettyValueToCompare(
            Number(previousValue),
            Number(singleChartDataValue)
          );

          newSingleChartData[`${singleChartDataKey}${CHART_DATA.CHANGE}`] =
            result;
        }
      }
    );

    return newSingleChartData;
  });
};

type getKeyFromActiveMetricArgs = ActiveMetricsModel.itemT & {
  index: number,
  appendToKey?: string,
};

export const getKeyFromActiveMetric = ({
  index,
  appendToKey,
  metricGroupKey,
  metricKey,
  taxonomies,
}: getKeyFromActiveMetricArgs): string => {
  const regExp = / /g; // Match all spaces in a string
  let base = `${index}-${metricGroupKey}-${metricKey}`;

  if (taxonomies) {
    base = `${base}-${taxonomies
      .map((taxonomy) => taxonomy.replace(regExp, '-'))
      .join('-')}`;
  }

  if (appendToKey) {
    base = `${base}__${appendToKey}`;
  }

  return base;
};

const locationsLabelFromLocationIncludes = (
  location: string[],
  locations: LocationModel.t[]
): string => {
  const locationNamesArray = location.reduce(
    (finalLocationNames, metricLocation) => {
      const locationFound = locations.find(
        (location) => location.id === metricLocation
      );

      if (locationFound && locationFound.name) {
        finalLocationNames.push(locationFound.name);
      }

      return finalLocationNames;
    },
    []
  );

  let locationNames = locationNamesArray.join(', ');

  if (locationNames.length > LOCATIONS_NAMES_CAP) {
    const firstPart = locationNames.slice(0, LOCATIONS_NAMES_CAP);
    locationNames = `${firstPart}...`;
  }

  return locationNames;
};

const getActiveLineLabel = ({
  metricItem,
  locations,
  breakdownBy,
  demographicType,
  isComparison,
  availableMetrics,
}: {
  metricItem: PageSettingsModel.metricItemT,
  locations: LocationModel.t[],
  breakdownBy?: breakdownByEnum,
  demographicType?: string,
  isComparison: boolean,
  availableMetrics: AvailableMetricsModel.t,
}): {
  label: string,
  metricOnlyLabelForAxis: string,
} => {
  const { metric, location } = metricItem;
  const addExtraLabelInformation =
    breakdownBy !== BREAKDOWN_BY.TOTAL_ONLY && demographicType;

  let label = getColumnLabel(metric, availableMetrics, true, true);

  if (addExtraLabelInformation && demographicType) {
    if (breakdownBy === 'location') {
      label = LocationModel.getLocationName(demographicType, locations);
    } else {
      label = prettyLabel(demographicType);
    }
  } else if (demographicType) {
    label = prettyLabel(demographicType);
  }

  if (isComparison) {
    label = `Compared to: ${label}`;
  }

  let finalLabel = label;

  if (breakdownBy === BREAKDOWN_BY.TOTAL_ONLY) {
    if (location.length === 1) {
      finalLabel = `${label} - ${locationsLabelFromLocationIncludes(
        location,
        locations
      )}`;
    } else if (location.length === locations.length) {
      finalLabel = `${label} - ${ALL_LOCATIONS}`;
    } else if (location.length > 1) {
      finalLabel = `${label} - ${location.length} Locations`;
    }
  }

  const metricOnlyLabelForAxis = getColumnLabel(
    metric,
    availableMetrics,
    false,
    false
  );

  return { label: finalLabel, metricOnlyLabelForAxis };
};

export const getChartData = (
  axisLabelList: string[],
  activeLines: activeLinesT[],
  breakdownBy: breakdownByEnum,
  aggregation: QueryApiRequestModel.aggregationEnumT
): chartDataT[] => {
  const chartData = {};
  const isDateChart = ![
    AGGREGATION_PERIOD.DAY_OF_WEEK,
    AGGREGATION_PERIOD.HOUR_OF_DAY,
    AGGREGATION_PERIOD.DOW_HOD,
  ].includes(aggregation.toLowerCase());

  let axisLabels = axisLabelList;

  if (isDateChart) {
    axisLabels = axisLabelList.map((label) =>
      label ? moment.utc(label).toISOString().replace('Z', '') : label
    );
  }

  activeLines.forEach(
    (line: {
      values?: itemT,
      key: string,
      breakdownBy?: breakdownByEnum,
      demographicType?: string,
    }) => {
      const { values, key, breakdownBy, demographicType } = line;
      const segments = values && QueryResponseListModel.getSegments(values);

      if (segments && values) {
        segments
          .filter(
            (segment) =>
              !breakdownBy ||
              (breakdownBy !== 'total_only' &&
                segment[breakdownBy] === demographicType)
          )
          .forEach((segment) => {
            let segmentIndex = segment.index;
            if (typeof segmentIndex !== 'undefined') {
              if (isDateChart) {
                segmentIndex = moment
                  .utc(segment.index)
                  .toISOString()
                  .replace('Z', '');
              }

              if (aggregation === AGGREGATION_PERIOD.HOUR_OF_DAY) {
                segmentIndex = JSON.stringify(segmentIndex).padStart(2, '0');
              }

              const segmentKey = values.metricKey.split(':')[0];
              const value = segment[segmentKey] === null ? 0 : segment[segmentKey];
              if (!chartData[segmentIndex]) {
                chartData[segmentIndex] = {
                  index: segmentIndex,
                  [key]: typeof value === 'number' ? value : undefined,
                };
              } else {
                chartData[segmentIndex] = {
                  ...chartData[segmentIndex],
                  [key]: typeof value === 'number' ? value : undefined,
                };
              }
            }
          });
      }
    }
  );

  const finalChartData = axisLabels.map((label) =>
    chartData[`${label}`]
      ? chartData[`${label}`]
      : {
          index: label,
        }
  );

  return finalChartData;
};

export const mergeChartDateInOneSingleChartItem = (
  chartData: chartDataT[]
): chartDataT[] => {
  return chartData.reduce(
    (finalChartData, row, rowIndex) => {
      Object.entries(row).forEach(([rowKey, rowValue]) => {
        if (rowKey !== 'index' || (rowIndex === 0 && rowKey === 'index')) {
          finalChartData[0][rowKey] = rowValue;
        }
      });

      return finalChartData;
    },
    // $FlowFixMe -> Flow is complaining for mix type when you use object.entries
    [{}]
  );
};

const getPieChartData = (
  activeLines: activeLinesT[],
  period: QueryApiRequestModel.periodT
): chartDataT[] => {
  const date = `${period.start}/${period.end}`;
  const chartData = activeLines.map((activeLine, index) => {
    const value =
      activeLine.values &&
      activeLine.values.data &&
      QueryResponseModel.getSummaryValue({
        response: activeLine.values.data,
        metricKey: activeLine.values.metricKey,
      });
    return {
      index: date,
      [activeLine.key]: value,
    };
  });

  return mergeChartDateInOneSingleChartItem(chartData);
};

const getPieChartDataWithBreakdownByActive = (
  activeLines: activeLinesT[],
  period: QueryApiRequestModel.periodT
): chartDataT[] => {
  const date = `${period.start}/${period.end}`;
  const rawPieChartData = activeLines.map((activeLine) => {
    const { breakdownBy: rawBreakdownBy, demographicType, values } = activeLine;
    const breakdownBy: string = rawBreakdownBy
      ? rawBreakdownBy.replace('total_only', 'total')
      : '';

    const value =
      activeLine.values &&
      activeLine.values.data &&
      activeLine.values.data.summary &&
      breakdownBy &&
      values
        ? QueryResponseModel.getSummaryByBreakdown({
            response: activeLine.values.data,
            metricKey: values.metricKey,
          })
        : null;

    return {
      index: date,
      [activeLine.key]:
        value && value[breakdownBy] && value[breakdownBy][demographicType]
          ? value[breakdownBy][demographicType]
          : 0,
    };
  });

  return mergeChartDateInOneSingleChartItem(rawPieChartData);
};

type getActiveLinesFromSingleMetricArgsT = {
  metricItem: PageSettingsModel.metricItemT,
  availableMetrics: AvailableMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  activeLineIndex: number,
  appendToIndex?: number,
  breakdownBy?: breakdownByEnum,
  demographicType?: string,
  isComparison: boolean,
  isAllLocations: boolean,
  locations: LocationModel.t[],
  showStaffFilters: boolean,
};

export const getActiveLinesFromSingleMetric = (
  {
    metricItem,
    availableMetrics,
    queryResponseList,
    period,
    aggregation,
    activeLineIndex,
    appendToIndex,
    breakdownBy,
    demographicType,
    isComparison,
    isAllLocations,
    locations,
    showStaffFilters,
  }: getActiveLinesFromSingleMetricArgsT,
  theme: Object
): activeLinesT => {
  const { metric, demographicFilter, location } = metricItem;

  const addExtraLabelInformation =
    breakdownBy !== BREAKDOWN_BY.TOTAL_ONLY && demographicType;

  const appendToKey =
    addExtraLabelInformation &&
    breakdownBy &&
    demographicType &&
    `${breakdownBy}__${demographicType}`;

  let key = getKeyFromActiveMetric({
    ...metric,
    index: activeLineIndex,
    appendToKey: appendToKey ? appendToKey : undefined,
  });

  if (isComparison) {
    key = `${key}${CHART_DATA.COMPARE}`;
  }

  const axisKey = JSON.stringify(
    `${metric.metricGroupKey}-${metric.metricKey}`
  ); // a key to group all lines that have come from a metric so they can go on the same axis

  const { label, metricOnlyLabelForAxis } = getActiveLineLabel({
    metricItem,
    locations,
    breakdownBy,
    demographicType,
    isComparison,
    availableMetrics,
  });

  let breakdownByValue = undefined;

  if (['location', 'organisation'].includes(breakdownBy)) {
    breakdownByValue = ['entity'];
  } else if (breakdownBy === 'total_only') {
    breakdownByValue = undefined;
  } else if (breakdownBy) {
    breakdownByValue = [breakdownBy];
  }

  const excludeStaff =
    (!!demographicFilter && demographicFilter.role === 'customer') ||
    !showStaffFilters;

  let findCriteria = {
    ...metric,
    isComparisonActive: isComparison,
    excludeStaff,
    isAllLocations,
    locations: location,
    period,
    aggregation,
    taxonomies: metric.taxonomies ? metric.taxonomies : undefined,
  };

  if (breakdownBy) {
    findCriteria = {
      ...findCriteria,
      breakdownByDimensions: breakdownByValue,
    };
  }

  if (findCriteria.filter) {
    findCriteria.taxonomies = [
      `${findCriteria.filter.key}:${findCriteria.filter.value}`,
    ];
    delete findCriteria.filter;
  }

  const values = QueryResponseListModel.findOne(
    findCriteria,
    queryResponseList
  );

  const unitType =
    values && values.data
      ? QueryResponseModel.getUnitType(values.data, metric.metricKey)
      : 'peopleCount';

  const color =
    appendToIndex && appendToKey && theme
      ? theme.chartColors[
          (activeLineIndex + appendToIndex) % theme.chartColors.length
        ]
      : theme.chartColors[activeLineIndex % theme.chartColors.length];

  const activeLine = {
    axisKey,
    key,
    unitType,
    label,
    metricOnlyLabelForAxis,
    color,
    active: true,
    values,
    demographicType: addExtraLabelInformation ? demographicType : undefined,
    breakdownBy: addExtraLabelInformation ? breakdownBy : undefined,
  };

  return activeLine;
};

export const getColorIndex = (number: number, colorCount: number): number =>
  number % colorCount;

type getActiveLinesFromMetricsArgsT = {
  metrics: PageSettingsModel.metricItemT[],
  availableMetrics: AvailableMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  isComparison: boolean,
  isAllLocations: boolean,
  breakdownBy: breakdownByEnum,
  locations: LocationModel.t[],
  showStaffFilters: boolean,
};

export const getActiveLinesFromMetrics = (
  {
    metrics,
    availableMetrics,
    queryResponseList,
    period,
    aggregation,
    isComparison,
    isAllLocations,
    breakdownBy,
    locations,
    showStaffFilters,
  }: getActiveLinesFromMetricsArgsT,
  theme: Object
): activeLinesT[] => {
  const activeLines = metrics.reduce(
    (finalActiveLines, metric, activeLineIndex) => {
      if (breakdownBy === BREAKDOWN_BY.TOTAL_ONLY) {
        const activeLineConfig = {
          metricItem: metric,
          availableMetrics,
          queryResponseList,
          period,
          aggregation,
          isComparison,
          isAllLocations,
          activeLineIndex,
          breakdownBy,
          locations,
          showStaffFilters,
        };
        const activeLine = getActiveLinesFromSingleMetric(
          activeLineConfig,
          theme
        );
        finalActiveLines.push(activeLine);
      } else {
        const keysList =
          QueryResponseListModel.getSplitsKeysFromSegmentsUsingAKey(
            queryResponseList,
            breakdownBy
          );

        Array.from(keysList).forEach(
          (key: string, demographicBreakdownIndex) => {
            const activeLineConfig = {
              metricItem: metric,
              availableMetrics,
              queryResponseList,
              period,
              aggregation,
              activeLineIndex,
              breakdownBy,
              demographicType: key,
              isAllLocations,
              isComparison,
              appendToIndex: demographicBreakdownIndex,
              locations,
              showStaffFilters,
            };
            const activeLine = getActiveLinesFromSingleMetric(
              activeLineConfig,
              theme
            );

            finalActiveLines.push(activeLine);
          }
        );
      }

      return finalActiveLines;
    },
    []
  );
  return activeLines;
};

const handleGlobalGetChartData = (
  activeLines: activeLinesT[],
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  chartType: chartTypeEnum,
  breakdownBy: breakdownByEnum
): chartDataT[] => {
  const rangeDatesList = getDatesListInRangePeriod(period, aggregation);

  if (breakdownBy !== BREAKDOWN_BY.TOTAL_ONLY) {
    if (chartType === CHART_TYPE.PIE) {
      return getPieChartDataWithBreakdownByActive(activeLines, period);
    } else {
      return getChartData(
        rangeDatesList,
        activeLines,
        breakdownBy,
        aggregation
      );
    }
  }

  if (chartType === CHART_TYPE.PIE) {
    return getPieChartData(activeLines, period);
  }

  return getChartData(
    rangeDatesList,
    activeLines,
    BREAKDOWN_BY.TOTAL_ONLY,
    aggregation
  );
};

type getActiveLinesAndChartDataArgsT = {
  metrics: PageSettingsModel.metricItemT[],
  availableMetrics: AvailableMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  breakdownBy: breakdownByEnum,
  chartType: chartTypeEnum,
  isAllLocations: boolean,
  isComparison: boolean,
  locations: LocationModel.t[],
  showStaffFilters: boolean,
};

export const getActiveLinesAndChartData = (
  {
    metrics,
    availableMetrics,
    queryResponseList,
    period,
    aggregation,
    breakdownBy,
    chartType,
    isAllLocations,
    isComparison,
    locations,
    showStaffFilters,
  }: getActiveLinesAndChartDataArgsT,
  theme: Object
): {
  chartData: chartDataT[],
  activeLines: activeLinesT[],
} => {
  const activeLinesConfig = {
    metrics,
    availableMetrics,
    queryResponseList,
    period,
    aggregation,
    breakdownBy,
    isAllLocations,
    isComparison,
    locations,
    showStaffFilters,
  };
  const activeLines = getActiveLinesFromMetrics(activeLinesConfig, theme);

  const chartData = handleGlobalGetChartData(
    activeLines,
    period,
    aggregation,
    chartType,
    breakdownBy
  );

  return {
    activeLines,
    chartData,
  };
};

type mergeCompareToPastChartWithDataChartDataArgs = {
  chartData: chartDataT[],
  compareToPastChartDataRaw: chartDataT[],
};

export const mergeCompareToPastChartDataWithChartData = ({
  chartData,
  compareToPastChartDataRaw,
}: mergeCompareToPastChartWithDataChartDataArgs): chartDataT[] => {
  return chartData.map((singleChartData, index) => {
    const mergedSingleChartData = cloneDeep(singleChartData);

    if (compareToPastChartDataRaw[index]) {
      Object.entries(compareToPastChartDataRaw[index]).forEach(
        ([key, value]) => {
          if (key === 'index') {
            mergedSingleChartData.compareToPastIndex =
              compareToPastChartDataRaw[index].index;
          } else if (key !== 'index') {
            mergedSingleChartData[key] = value;
          }
        }
      );
    }

    return mergedSingleChartData;
  });
};

type getCompareToPastActiveLinesAndChartDataArgsT = {
  chartType: chartTypeEnum,
  chartData: chartDataT[],
  metrics: PageSettingsModel.metricItemT[],
  availableMetrics: AvailableMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  period: PeriodModel.t,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  compareToPast: PeriodModel.comparePeriodT,
  breakdownBy: breakdownByEnum,
  locations: LocationModel.t[],
  showStaffFilters: boolean,
};

export const getCompareToPastActiveLinesAndChartData = (
  {
    chartData,
    compareToPast,
    period,
    metrics,
    availableMetrics,
    queryResponseList,
    aggregation,
    chartType,
    breakdownBy,
    locations,
    showStaffFilters,
  }: getCompareToPastActiveLinesAndChartDataArgsT,
  theme: Object
): {
  compareToPastActiveLines: activeLinesT[],
  allChartData: chartDataT[],
} => {
  const compareToPastPeriod =
    chartType === CHART_TYPE.LINE &&
    compareToPast.active &&
    compareToPast.selectedPreset &&
    PeriodModel.getExplorerComparePeriod(
      compareToPast.selectedPreset,
      period.selectedDates
    );

  const rangeDatesList =
    compareToPastPeriod &&
    compareToPastPeriod.selectedDates &&
    getDatesListInRangePeriod(compareToPastPeriod.selectedDates, aggregation);

  const compareToPastActiveLinesRaw =
    compareToPastPeriod &&
    compareToPastPeriod.selectedDates &&
    getActiveLinesFromMetrics(
      {
        metrics,
        availableMetrics,
        queryResponseList,
        period: compareToPastPeriod.selectedDates,
        aggregation,
        isComparison: true,
        isAllLocations: false,
        breakdownBy,
        locations,
        showStaffFilters,
      },
      theme
    );

  const compareToPastChartDataRaw =
    rangeDatesList &&
    compareToPastActiveLinesRaw &&
    getChartData(
      rangeDatesList,
      compareToPastActiveLinesRaw,
      breakdownBy,
      aggregation
    );

  const compareToPastChartData =
    compareToPastChartDataRaw &&
    mergeCompareToPastChartDataWithChartData({
      chartData,
      compareToPastChartDataRaw,
    });

  const chartDataWithChange = getChartDataWithChangeInformation(chartData);
  const compareToPastChartDataWithChange =
    compareToPastChartData &&
    getChartDataWithChangeInformation(compareToPastChartData, true);

  return {
    compareToPastActiveLines: compareToPastActiveLinesRaw || [],
    allChartData: compareToPastChartDataWithChange || chartDataWithChange,
  };
};

type getGraphConfigArgsT = {
  metrics: PageSettingsModel.metricItemT[],
  availableMetrics: AvailableMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  aggregation: aggregationEnum,
  chartType: chartTypeEnum,
  breakdownBy: breakdownByEnum,
  compareToPast: PeriodModel.comparePeriodT,
  period: PeriodModel.t,
  locations: LocationModel.t[],
  showStaffFilters: boolean,
};

export const getGraphConfig = (
  {
    metrics,
    availableMetrics,
    queryResponseList,
    aggregation,
    chartType,
    breakdownBy,
    compareToPast,
    period,
    locations,
    showStaffFilters,
  }: getGraphConfigArgsT,
  theme: Object
): {
  activeLines: activeLinesT[],
  chartData: chartDataT[],
  pcChartData: chartDataT[],
} => {
  const { activeLines, chartData } = getActiveLinesAndChartData(
    {
      metrics,
      availableMetrics,
      queryResponseList,
      period: period.selectedDates,
      aggregation,
      chartType,
      breakdownBy,
      locations,
      isAllLocations: false,
      isComparison: false,
      showStaffFilters,
    },
    theme
  );

  const { compareToPastActiveLines, allChartData } =
    getCompareToPastActiveLinesAndChartData(
      {
        chartData,
        compareToPast,
        period,
        metrics,
        breakdownBy,
        chartType,
        availableMetrics,
        queryResponseList,
        aggregation,
        locations,
        showStaffFilters,
      },
      theme
    );

  let pcChartData = [];

  if (metrics.length === 1) {
    pcChartData = getPcChartData(chartData, activeLines);
  }

  const allActiveLines = [...activeLines, ...compareToPastActiveLines];

  return {
    activeLines: allActiveLines,
    chartData: allChartData,
    pcChartData,
  };
};

export const splitActiveLinesByUnitType = (
  activeLines: activeLinesT[]
): {
  filteredActiveLines: activeLinesT[],
  percentageActiveLines: activeLinesT[],
} => {
  return activeLines.reduce(
    (splitActiveLines, singleActiveLine) => {
      const unitType = getUnitTypeFromActiveLine(singleActiveLine);

      if (unitType && unitType === 'percentage') {
        splitActiveLines.percentageActiveLines.push(singleActiveLine);
      } else {
        splitActiveLines.filteredActiveLines.push(singleActiveLine);
      }

      return splitActiveLines;
    },
    { filteredActiveLines: [], percentageActiveLines: [] }
  );
};
