// @flow

import * as QueryResponseModel from '../models/query-response';
import type { activeLinesT } from '../components/graph-tile';

export const getUnitTypeFromActiveLine = (
  activeLine?: activeLinesT
): ?QueryResponseModel.unitTypeT =>
  activeLine &&
  activeLine.values &&
  activeLine.values.data &&
  QueryResponseModel.getUnitType(
    activeLine.values.data,
    activeLine.key.split('-')[2]
  );
