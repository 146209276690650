// @flow

import * as ActiveMetricsModel from '../models/active-metrics';

export const prettyTaxonomyLabel = (label: string): string => {
  return label.split(':').join(' > ');
};

export const taxonomiesConfigurationObjectCreator = (
  taxonomiesList: string[]
): ActiveMetricsModel.taxonomyConfgObjtT[] => {
  //Build a taxonomy string to correct depth
  const buildTaxonomyString = (taxonomy, depth) => {
    return taxonomy
      .split(':')
      .slice(0, depth)
      .join(':');
  };
  //Builds children recursively from bottom up
  const buildChild = (taxonomy, depth) => {
    const splitTaxonomy = taxonomy.split(':');
    const taxonomyItems = splitTaxonomy.length;

    let childArray = [];
    if (depth < taxonomyItems) {
      childArray.push(buildChild(taxonomy, depth + 1));
    }
    return {
      label: splitTaxonomy[depth - 1],
      taxonomy: buildTaxonomyString(taxonomy, depth),
      children: childArray
    };
  };
  //Merges the unmerged correctly formed taxonomies recursively from top down
  const merge = (unMergedArray, newArray = []) => {
    unMergedArray.forEach(item => {
      if (newArray.length < 1) {
        newArray.push(item);
      }
      let itemAlreadyExists = newArray.findIndex(
        newItem => item.label === newItem.label
      );
      if (itemAlreadyExists === -1) {
        newArray.push(item);
      } else {
        merge(item.children, newArray[itemAlreadyExists].children);
      }
    });
    return newArray;
  };
  //Run the merge of the buildChild result
  const mergedResult = merge(
    taxonomiesList.map(eachTaxonomy => buildChild(eachTaxonomy, 1))
  );
  return mergedResult;
};
