// @flow

import { Button } from '@blueprintjs/core';
import Layout from '../../../layouts/default-logged-in';
import SettingsPageHeader from '../settings-page-header';
import { Wrapper } from '../settings-page-styles';
import type { BrowserHistory } from 'history/createBrowserHistory';
import { dropAPICache } from '../../../../models/database';
import { Panel, PanelBody } from '../../../panel';
import styled from 'styled-components';

type Props = {
  history: BrowserHistory,
};

const StyledPanel = styled(Panel)`
  max-width: var(--widget-max-width);
  box-shadow: var(--widget-box-shadow);
`;
const StyledPanelBody = styled(PanelBody)`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SettingsTools = ({ history }: Props) => {
  const clearCache = async () => {
    await dropAPICache();
  };

  return (
    <Layout>
      <SettingsPageHeader history={history} />
      <Wrapper>
        <StyledPanel>
          <StyledPanelBody>
              Clear the local cache using this button if asked to by a support
              representative, or if your dashboard data is out of date.
            <Button onClick={clearCache}>Clear Cache</Button>
          </StyledPanelBody>
        </StyledPanel>
      </Wrapper>
    </Layout>
  );
};

export default SettingsTools;
