// @flow

import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

import Layout from '../../layouts/default-logged-in';
import * as ReportModel from '../../../models/report';
import NoAreasPanel from '../../no-areas-panel';
import { Wrapper } from '../summary/layout';

type Props = {
  reports: ReportModel.t[],
  fetchingReports: boolean
};

const ReportsPage = ({ reports, fetchingReports }: Props) => {
  if ((!reports || !reports.length) && !fetchingReports) {
    return (
      <Layout>
        <Wrapper>
          <NoAreasPanel message="No report to display" />
        </Wrapper>
      </Layout>
    );
  }

  const defaultReport = reports[0];

  return (
    <Redirect
      to={{
        pathname: ROUTES.SINGLE_REPORT.replace(':reportId', defaultReport.id)
      }}
    />
  );
};

export default ReportsPage;
