// @flow

import { getDateRangeLabelFormat } from '../../../utils/dates';
import { fixTitleModifiers } from '../../../utils/datesPresetsConfigHelpers.js';
import {
  defaultPeriodPresetConfig,
  comparisonPeriodPresetConfig
} from '../../date-and-comparison-date-selector/dates-presets-config';
import type { commonWidgetPropsT } from '../../../models/widget-props';

export const buildWidgetDateString = (
  widget:
    | commonWidgetPropsT
    | (commonWidgetPropsT & { showChangeValue: boolean }),
  comparisonOnlyDisplayedWithShowChangeValueProp?: boolean
) => {
  // $FlowIgnore
  const { period, comparisonPeriod, showChangeValue } = widget;

  const periodPreset = defaultPeriodPresetConfig[period.selectedPreset];
  const periodPresetTitle = periodPreset.title;

  const periodDates = getDateRangeLabelFormat(
    period.selectedDates,
    periodPreset
  );

  if (
    comparisonPeriod &&
    comparisonPeriod.selectedPreset &&
    comparisonPeriod.selectedDates &&
    comparisonPeriod.active &&
    (comparisonOnlyDisplayedWithShowChangeValueProp ? showChangeValue : true)
  ) {
    const compPeriodPreset =
      comparisonPeriodPresetConfig[comparisonPeriod.selectedPreset];

    const compPresetTitle = fixTitleModifiers(
      compPeriodPreset.title,
      comparisonPeriod
    );

    const compDates = getDateRangeLabelFormat(
      comparisonPeriod.selectedDates,
      compPeriodPreset
    );

    return `${periodPresetTitle} compared to ${compPresetTitle} (${periodDates} compared to  ${compDates})`;
  }

  return `${periodPresetTitle} (${periodDates})`;
};
