// @flow

import { Component } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  FormGroup,
  InputGroup,
  Classes,
  Button,
  Intent
} from '@blueprintjs/core';
import * as UserModel from '../../models/user';
import { errorToast } from '../../utils/toaster';
import { isEmail } from '../../utils/generalHelpers';
import { inviteUser } from '../../services/auth-api';
import { ROLES } from '../../constants/user-roles';
import RolePicker from '../pickers/user-role-picker';

const StyledDialog = styled(Dialog)`
  width: 600px;
  padding: 0;
`;
const TopRow = styled.div`
  display: flex;
  padding: 1rem;
`;
const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;
const ColumnLeft = styled.div`
  flex: 3;
`;
const ColumnRight = styled.div`
  flex: 2;
  margin-left: 1rem;
`;

type Props = {
  isOpen: boolean,
  currentUser: UserModel.t,
  closeMenu(): void,
  onSendInviteFinished(): void
};

type State = {
  emailInput: string,
  roleIdsToAddToUser: number[],
  inviteInProgress: boolean
};

const DEFAULT_ROLE_IDS = Object.values(ROLES)
  // $FlowFixMe -> Object.entries mixed type error
  .filter(r => r.required)
  // $FlowFixMe -> Object.entries mixed type error
  .map(r => r.id);

class InviteUserMenu extends Component<Props, State> {
  state = {
    emailInput: '',
    roleIdsToAddToUser: DEFAULT_ROLE_IDS,
    inviteInProgress: false
  };

  handleEmailInputChange(e: Object) {
    this.setState({
      emailInput: e.target.value
    });
  }

  toggleRoleIdToAddToUser(roleId: number) {
    const newIds = this.state.roleIdsToAddToUser.includes(roleId)
      ? this.state.roleIdsToAddToUser.filter(id => id !== roleId)
      : [...this.state.roleIdsToAddToUser, roleId];

    this.setState({
      roleIdsToAddToUser: newIds
    });
  }

  resetState() {
    this.setState({
      emailInput: '',
      roleIdsToAddToUser: DEFAULT_ROLE_IDS,
      inviteInProgress: false
    });
  }

  validateUserInvitation(): string[] {
    const { emailInput, roleIdsToAddToUser } = this.state;

    const errors = [];

    if (!emailInput || emailInput === '') {
      errors.push('Email can not be empty.');
    }

    if (emailInput) {
      const isValid = isEmail(emailInput);

      if (!isValid) {
        errors.push('Email is not valid');
      }
    }

    if (!roleIdsToAddToUser.length) {
      errors.push('Select a role.');
    }

    return errors;
  }

  async handleSendUserInvitation() {
    const errors = this.validateUserInvitation();
    const { currentUser, closeMenu, onSendInviteFinished } = this.props;
    const { roleIdsToAddToUser } = this.state;

    if (errors && errors.length > 0) {
      errors.forEach(error =>
        errorToast({
          message: error
        })
      );
    } else if (currentUser.organisationName) {
      const { emailInput } = this.state;

      this.setState({ inviteInProgress: true });
      try {
        await inviteUser(
          currentUser.organisationName,
          emailInput,
          roleIdsToAddToUser,
          currentUser.organisationId
        );

        errorToast({
          message: 'User invited successfully.',
          intent: Intent.SUCCESS
        });
      } catch (e) {
        errorToast({
          message: 'Falied to invite the user.',
          intent: Intent.DANGER
        });
      }

      closeMenu();
      this.resetState();
      onSendInviteFinished();
    }
  }

  render() {
    const { isOpen, closeMenu } = this.props;
    const { emailInput, roleIdsToAddToUser, inviteInProgress } = this.state;

    return (
      <StyledDialog
        isOpen={isOpen}
        onClose={closeMenu}
        title="Invite New User"
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        className={Classes.OVERLAY_SCROLL_CONTAINER}
      >
        <TopRow>
          <ColumnLeft>
            <FormGroup label="Email" labelFor="email-input">
              <InputGroup
                id="email-input"
                placeholder="example@email.com"
                value={emailInput}
                type="email"
                onChange={this.handleEmailInputChange.bind(this)}
              />
            </FormGroup>
          </ColumnLeft>
          <ColumnRight>
            <FormGroup label="Roles" labelFor="role-button">
              <RolePicker
                selectedRoles={roleIdsToAddToUser.map(id => ({ id }))}
                onClick={roleId => this.toggleRoleIdToAddToUser(roleId)}
              />
            </FormGroup>
          </ColumnRight>
        </TopRow>
        <BottomRow>
          <Button
            loading={inviteInProgress}
            disabled={inviteInProgress}
            text="Send invite"
            intent="success"
            onClick={this.handleSendUserInvitation.bind(this)}
          />
        </BottomRow>
      </StyledDialog>
    );
  }
}
export default InviteUserMenu;
