// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import { Card, Button } from '@blueprintjs/core';
import PlaceHolderImage from '../placeholder-image';
import SingleImageDisplayer from './single-image-displayer';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  max-height: 70vh;
  overflow-y: scroll;
  &&& {
    box-shadow: none;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

type Props = {
  previews: string[],
  setMetricPreviewImagesForModal?: (previews: string[]) => void | null,
  useSimpleImageTag?: boolean
};

const ImageDisplayerMenu = ({
  setMetricPreviewImagesForModal,
  previews,
  useSimpleImageTag
}: Props) => {
  const showButton = previews && previews.length > 1;

  return (
    <StyledCard>
      {previews && (
        <Fragment>
          {useSimpleImageTag ? (
            <PlaceHolderImage src={previews[0]} width="100%" height="auto" />
          ) : (
            <SingleImageDisplayer imgUrl={previews[0]} />
          )}
          {setMetricPreviewImagesForModal && showButton && (
            <StyledButton
              onClick={() => setMetricPreviewImagesForModal(previews)}
              icon="search"
              intent="primary"
            >
              Show {previews.length - 1} more images
            </StyledButton>
          )}
        </Fragment>
      )}
    </StyledCard>
  );
};

export default ImageDisplayerMenu;
