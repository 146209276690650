export const API_BASE = process.env.REACT_APP_API_BASE;
export const QUERY_API_BASE = process.env.REACT_APP_QUERY_API_BASE;
export const THUMBNAILER_BASE = process.env.REACT_APP_THUMBNAILER_BASE;
export const IOT_API_BASE = process.env.REACT_APP_IOT_API_BASE;
export const USE_LOGROCKET = process.env.REACT_APP_USE_LOGROCKET === 'true';
export const USE_SENTRY = process.env.REACT_APP_USE_SENTRY === 'true';
export const USE_GOOGLE_ANALYTICS =
  process.env.REACT_APP_USE_GOOGLE_ANALYTICS === 'true';
export const SPLIT_API_KEY = process.env.REACT_APP_SPLIT_API_KEY;
export const USE_MIXPANEL = process.env.REACT_APP_USE_MIXPANEL === 'true';
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const LIVE_OCC_API_BASE = process.env.REACT_APP_LIVE_OCC_API_BASE;
export const LIVE_OCC_API_KEY = process.env.REACT_APP_LIVE_OCC_API_KEY;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const AUTH_SERVICE_API_BASE =
  process.env.REACT_APP_AUTH_SERVICE_API_BASE;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const EMAILER_API_BASE = process.env.REACT_APP_EMAILER_API_BASE;

export const IS_EMAIL_VIEW = window.location.search.includes('emailView');
export const DISABLE_CACHE = process.env.REACT_APP_DISABLE_CACHE === 'true';
