// @flow

import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../styles/variables';

import {
  WidgetOverflow,
  WidgetBodyWrapper,
  MetricTitle
} from '../common/styles';
import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';

import RankBar from '../common/rank-bar';
import type { unitTypeT } from '../../../models/query-response';

const MetricRow = styled.div`
  align-items: center;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 200px auto;
  margin: 12px 0px;
  text-align: right;
  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    display: block;
    text-align: left;
  }
`;

const MetricTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-right: 8px;
  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    justify-content: flex-start;
    margin-bottom: 4px;
    margin-right: 0px;
  }
`;

// TODO widgetPropsT is duplicated because importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description?: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean
};

type Props = widgetPropsT & {
  /** The metrics to display */
  metrics: {
    title: string,
    value: number,
    unitType: unitTypeT,
    changeValue: number
  }[],
  //** The metric to order by */
  sortBy?: 'value' | 'changeValue',
  /** The key from the data */
  dataKey: string,
  children?: any,
};

const Rank = ({
  title,
  subtitle,
  description,
  metrics,
  sortBy = 'value',
  dataKey = 'value',
  loading,
  errors,
  children,
}: Props) => {
  const [extendedMetrics, setExtendedMetrics] = useState([]);

  useEffect(() => {
    setExtendedMetrics(
      metrics.map((metric, index) => {
        return {
          ...metric,
          percentage: Math.abs(
            (100 * metric[dataKey]) /
              Math.max(...metrics.map(data => data[dataKey]))
          )
        };
      })
    );
  }, [metrics]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WidgetWrapper
      loading={loading}
      errors={errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapper>
        <WidgetOverflow>
          {extendedMetrics
            .sort((a, b) => b[sortBy] - a[sortBy])
            .map((metric, index) => {
              const {
                title,
                value,
                unitType,
                changeValue,
                percentage
              } = metric;

              return (
                <MetricRow key={index}>
                  <MetricTitleWrapper>
                    <MetricTitle fontSize="16px">{title}</MetricTitle>
                  </MetricTitleWrapper>
                  <RankBar
                    value={value}
                    unitType={unitType}
                    changeValue={changeValue}
                    barWidth={percentage}
                  />
                </MetricRow>
              );
            })}
        </WidgetOverflow>
      </WidgetBodyWrapper>
      {children}
    </WidgetWrapper>
  );
};

export default Rank;
