// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LiveOccupancyPage from '../components/pages/live-occupancy';
import * as Selectors from '../selectors';
import { ORG_HAS_STAFF_DETECTION, IS_DEMO_USER } from '../constants/features';

import type { Dispatch } from 'redux';

function mapStateToProps(state) {
  return {
    locations: Selectors.getLocations(state),
    recordings: Selectors.getRecordings(state),
    showStaffFilters: Selectors.userHasFeature(ORG_HAS_STAFF_DETECTION, state),
    isDemoUser: Selectors.userHasFeature(IS_DEMO_USER, state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveOccupancyPage);
