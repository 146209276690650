const axios = require('axios');
const md5 = require('md5');
const { HttpClientException } = require('./exceptions/http-client-exception');

class AxiosHttpClient {
  /**
   * Perform http requests via Axios
   * Can use local caching (shouldCache = true)
   * Will return a simple object
   * {statusCode, payload, header}
   * on 2xx or HttpClientException
   * otherwise
   *
   * logger should be a function that
   * takes an object {message, contextObject}
   * and logs it as 'info' e.g. .info at
   * aura-emailer.git/src/services/logger/index.js
   *
   * @param logger
   * @param method
   * @param headers
   * @param url
   * @param cacheFacade
   * @param cacheTtl
   * @param payload
   * @param shouldCache
   * @param cacheErrorCodes
   * @param timeout
   * @throws {HttpClientException}
   * @returns {Promise<object>}
   */
  static async makeRequest({
    logger,
    method,
    headers,
    url,
    cacheFacade = null,
    cacheTtl = 900,
    payload = null,
    shouldCache = false,
    cacheErrorCodes = [404],
    timeout = 20000,
  }) {
    // We'll explicitly build a request object as we
    // will use it in the exception
    const request = {
      method,
      url,
      headers,
      timeout,
    };

    // We'll only add a data property if there is a payload
    if (payload) request.data = JSON.stringify(payload);

    logger({
      message: 'AxiosHttpClient.makeRequest',
      contextObject: request,
    });

    // Make the call via axios
    return AxiosHttpClient.callHttp({
      logger,
      request,
      shouldCache,
      cacheFacade,
      cacheTtl,
      cacheErrorCodes,
    });
  }

  /**
   * Hashes an object using md5
   *
   * @param obj
   * @returns {string}
   */
  static getCacheKey(obj) {
    const stringToHash = JSON.stringify(obj);
    return md5(stringToHash);
  }

  /**
   * Make a request via axios
   *
   * @param logger
   * @param request
   * @param shouldCache
   * @param cacheFacade
   * @param cacheTtl
   * @param cacheErrorCodes
   * @returns {Promise<{headers: *, payload: *, statusCode: *}>}
   */
  static async callHttp({
    logger,
    request,
    shouldCache,
    cacheFacade,
    cacheTtl,
    cacheErrorCodes,
  }) {
    let cacheKey = null;
    let httpResult = null;

    logger({ message: 'AxiosHttpClient.callHttp' });

    try {
      // Generate a cache key for the request
      cacheKey = AxiosHttpClient.getCacheKey(request);

      // Check the cache and return if found
      const cachedResult =
        await AxiosHttpClient.checkCacheForPreviousRequestResult(
          logger,
          shouldCache,
          cacheFacade,
          cacheKey,
          request,
        );

      if (cachedResult) return cachedResult;

      // Did not find in cache so let's make the call and log the duration
      const startDate = new Date();
      const start = startDate.getTime();
      const response = await axios(request);
      const endDate = new Date();
      const end = endDate.getTime();
      logger({
        message: `${end - start} milliseconds duration for HTTP call to ${
          request.url
        }`,
      });

      httpResult = {
        statusCode: response.status,
        payload: response.data,
        headers: response.headers,
      };

      // Cache the result if required to
      AxiosHttpClient.cacheResultOfRequest(
        logger,
        shouldCache,
        cacheFacade,
        cacheTtl,
        cacheKey,
        httpResult,
      );

      return httpResult;
    } catch (error) {
      if (error.response) {
        const httpErrorResult = {
          statusCode: error.response.status,
          payload: error.response.data,
          headers: error.response.headers,
        };

        // Should we cache the result of the error code?

        if (cacheErrorCodes.includes(error.response.status)) {
          logger({
            message:
              'AxiosHttpClient.callHttp, result has error status code of ' +
              `${error.response.status}, which should be cached`,
          });

          // Cache the result
          await AxiosHttpClient.cacheResultOfRequest(
            logger,
            shouldCache,
            cacheFacade,
            cacheKey,
            httpErrorResult,
          );
        }

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new HttpClientException(
          'AxiosHttpClient request response indicates error',
          request,
          httpErrorResult,
        );
      } else if (error.request) {
        throw new HttpClientException(
          'AxiosHttpClient failed to make request, no response received',
          request,
          null,
          error.request,
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new HttpClientException(
          'AxiosHttpClient failed, unknown error',
          request,
          null,
          null,
          error,
        );
      }
    }
  }

  /**
   * Returns caches result if found. If cached
   * result is not a 2xx then the result is
   * thrown
   *
   * @param logger
   * @param shouldCache
   * @param cacheFacade
   * @param cacheKey
   * @param request
   * @returns {*}
   */
  static async checkCacheForPreviousRequestResult(
    logger,
    shouldCache,
    cacheFacade,
    cacheKey,
    request,
  ) {
    if (shouldCache && cacheFacade) {
      logger({
        message:
          'AxiosHttpClient.checkCacheForPreviousRequestResult, ' +
          `Checking cache for key ${cacheKey}`,
      });
      const cachedResult = await cacheFacade.getKey(cacheKey);

      // if we've got a cache hit, then return the cached value
      // and do not make the http request
      if (cachedResult) {
        logger({
          message: `AxiosHttpClient.callHttp, Cache hit for key ${cacheKey}`,
        });

        if (cachedResult.statusCode < 200 || cachedResult.statusCode >= 300) {
          logger({
            message:
              'AxiosHttpClient.checkCacheForPreviousRequestResult, cache' +
              ` entry for ${cacheKey} is non 2xx, so going to throw it ` +
              'and allow stack to deal with it',
          });

          throw new HttpClientException(
            'AxiosHttpClient request response indicates error',
            request,
            {
              status: cachedResult.statusCode,
              data: cachedResult.payload,
              headers: cachedResult.headers,
            },
          );
        }
        // Otherwise it's a 2xx from the cache so return as normal
        return cachedResult;
      }
      logger({
        message: `AxiosHttpClient.callHttp, Cache miss for key ${cacheKey}`,
      });
    }

    return false;
  }

  /**
   *
   * @param logger
   * @param shouldCache
   * @param cacheFacade
   * @param cacheTtl
   * @param cacheKey
   * @param httpResult
   */
  static async cacheResultOfRequest(
    logger,
    shouldCache,
    cacheFacade,
    cacheTtl,
    cacheKey,
    httpResult,
  ) {
    if (shouldCache && cacheFacade) {
      logger({
        message: `AxiosHttpClient.cacheResultOfRequest, setting cache value for key ${cacheKey}`,
      });
      await cacheFacade.setKey(cacheKey, httpResult, cacheTtl);
    }
  }
}

module.exports = {
  AxiosHttpClient,
};
