// @flow

import { Component, Fragment } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import SubMenu from './sub-menu';
import { trackEvent, CHANGE_METRICS } from '../../services/mixpanel';
import { errorToast } from '../../utils/toaster';

import * as ActiveMetricsModel from '../../models/active-metrics';
import * as AvailableMetricsModel from '../../models/available-metrics';
import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';

export const icons = {
  movement: 'walk',
  movementWithFilters: 'walk',
  zones: 'widget',
  serviceAreas: 'time',
  sales: 'dollar'
};

const StyledDialog = styled(Dialog)`
  &&& {
    padding: 0;
  }

  /* Override the z-index set on this blueprint element to stop the zone types popover being behind it */
  & .bp3-dialog-header {
    z-index: 19;
    border-radius: var(--blueprint-dialog-border-radius) var(--blueprint-dialog-border-radius) 0 0;
  }

  & .bp3-button {
    border-radius: var(--button-border-radius);
  }
`;
const MenuFooter = styled.div`
  padding: 1.5em;
  background-color: var(--light-grey);
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 var(--blueprint-dialog-border-radius) var(--blueprint-dialog-border-radius);
`;

// Exported for a test
export const MetricsMenuLauncherButton = styled(Button)``;

export type State = {
  isMenuOpen: boolean,
  currentSubMenuOpen: string,
  beforeActiveMetrics: ActiveMetricsModel.t,
  showTaxonomyTagWarning: boolean,
  skipContinueAnywayErrors: boolean
};

export type Props = {
  editActiveMetrics: (config: editSummaryPageActiveMetricsArgsT) => void,
  activeMetrics: ActiveMetricsModel.t,
  availableMetrics: AvailableMetricsModel.t,
  fetchingQueryData: boolean,
  strictModeSelection?: boolean,
  buttonLabel?: string,
  minimalistic?: boolean,
  onMenuClose: () => void,
  resetState?: () => void
};

class MetricsMenu extends Component<Props, State> {
  state = {
    isMenuOpen: false,
    currentSubMenuOpen: '',
    beforeActiveMetrics: {},
    showTaxonomyTagWarning: false,
    skipContinueAnywayErrors: false
  };

  setShowTaxonomyTagWarning = (value: boolean) => {
    this.setState({
      showTaxonomyTagWarning: value
    });
  };

  updateBeforeActiveMetrics() {
    const { activeMetrics } = this.props;
    this.setState({
      beforeActiveMetrics: { ...activeMetrics }
    });
  }

  isActiveMetricsChanged() {
    const { activeMetrics } = this.props;
    const { beforeActiveMetrics } = this.state;
    return !isEqual(activeMetrics, beforeActiveMetrics);
  }

  handleMenuOpen() {
    const { isMenuOpen } = this.state;
    this.setState({ isMenuOpen: !isMenuOpen }, this.updateBeforeActiveMetrics);
  }

  handleMenuClose() {
    const { activeMetrics, onMenuClose, strictModeSelection } = this.props;
    const { showTaxonomyTagWarning, skipContinueAnywayErrors } = this.state;

    if (strictModeSelection) {
      const { activeMetrics, availableMetrics } = this.props;
      const errors = ActiveMetricsModel.getErrorMessagesWhenMissingRequiredFields(
        activeMetrics,
        availableMetrics,
        skipContinueAnywayErrors
      );

      if (errors.length > 0) {
        this.setShowTaxonomyTagWarning(true);

        errors.forEach(error => {
          let action;

          if (error.allowContinueAnyway) {
            action = {
              text: 'Continue anyway',
              onClick: e =>
                this.setState(
                  { skipContinueAnywayErrors: true },
                  this.handleMenuClose
                ),
              icon: 'tick'
            };
          }

          errorToast({
            message: error.text,
            timeout: error.allowContinueAnyway ? 0 : 3500,
            action
          });
        });

        return null;
      }
    }

    if (showTaxonomyTagWarning) {
      this.setShowTaxonomyTagWarning(false);
    }

    if (this.isActiveMetricsChanged() && onMenuClose) {
      trackEvent(CHANGE_METRICS, { activeMetrics });

      onMenuClose();
    }

    this.handleMenuOpen();
  }

  resetLocalStateAndCloseMenu() {
    const { resetState } = this.props;

    this.setState(
      {
        isMenuOpen: false,
        currentSubMenuOpen: '',
        beforeActiveMetrics: {}
      },
      resetState
    );
  }

  handleCurrentSubMenuOpen(newSubMenuName: string) {
    const newName =
      newSubMenuName !== this.state.currentSubMenuOpen ? newSubMenuName : '';
    this.setState({
      currentSubMenuOpen: newName
    });
  }

  render() {
    const {
      availableMetrics,
      activeMetrics,
      editActiveMetrics,
      fetchingQueryData,
      buttonLabel,
      minimalistic,
      resetState
    } = this.props;
    if (!availableMetrics) return null;
    const {
      isMenuOpen,
      currentSubMenuOpen,
      showTaxonomyTagWarning
    } = this.state;
    const subMenuCommmonProps = {
      handleCurrentSubMenuOpen: this.handleCurrentSubMenuOpen.bind(this),
      currentSubMenuOpen,
      activeMetrics,
      editActiveMetrics,
      showTaxonomyTagWarning
    };

    return (
      <Fragment>
        <MetricsMenuLauncherButton
          icon={minimalistic ? 'plus' : 'series-configuration'}
          intent={minimalistic ? 'none' : 'primary'}
          loading={fetchingQueryData}
          onClick={this.handleMenuOpen.bind(this)}
          disabled={fetchingQueryData}
        >
          {buttonLabel ? buttonLabel : 'Change Metrics'}
        </MetricsMenuLauncherButton>
        <StyledDialog
          isOpen={isMenuOpen}
          onClose={
            resetState
              ? this.resetLocalStateAndCloseMenu.bind(this)
              : this.handleMenuClose.bind(this)
          }
          title="Select Metrics"
          canOutsideClickClose={true}
          isCloseButtonShown={true}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
        >
          <div>
            {availableMetrics &&
              Object.entries(availableMetrics).map(
                ([subMenuKey, metrics], index) => {
                  return (
                    <SubMenu
                      key={`${subMenuKey}-${index}`}
                      // $FlowFixMe - not fixing because we haven't worked out how to define returns from Object.entries
                      subMenuConfiguration={metrics}
                      icon={icons[subMenuKey]}
                      subMenuKey={subMenuKey}
                      {...subMenuCommmonProps}
                    />
                  );
                }
              )}
          </div>
          <MenuFooter>
            <Button intent="primary" onClick={this.handleMenuClose.bind(this)}>
              Update Metrics
            </Button>
          </MenuFooter>
        </StyledDialog>
      </Fragment>
    );
  }
}

export default MetricsMenu;
