// @flow

import moment from 'moment';
import { isCurrentDateInSchedule } from './dates';
import { flatten } from 'lodash';

import * as RecordingModel from '../models/recording';
import * as LocationModel from '../models/location';
import type { locationConfigT } from '../components/pages/live-occupancy/location-occupancy-row';
import { LOCATION_DWELL } from '../constants/area-contexts';

export const UPDATE_LIVE_TIMEOUT = 5000;
export const HISTORY_LENGTH = 7;

export const currentOccupancyMissing = (location: locationConfigT): boolean => {
  if (!location.currentOccupancy && location.currentOccupancy !== 0)
    return true;
  return false;
};

export const capacityMissing = (location: locationConfigT): boolean => {
  if (!location.capacity && !location.allowWithoutCapacity) return true;
  return false;
};

export const getOccupancyErrors = (
  occupancyItem: locationConfigT
): ?{ fatal: boolean, message: string } => {
  if (occupancyItem.areaContextsNotAvailable) {
    return {
      fatal: true,
      message: 'Not available for this location',
    };
  }
  if (occupancyItem.isShopClosed) {
    return {
      fatal: true,
      message: 'This location is closed',
    };
  }
  if (occupancyItem.missingData) {
    return {
      fatal: false,
      message: 'Temporarily missing data',
    };
  }
  if (currentOccupancyMissing(occupancyItem) || capacityMissing(occupancyItem))
    return {
      fatal: false,
      message: 'Waiting for data',
    };

  return null;
};

export const getOccupancy = ({
  location,
  excludeStaff,
}: {
  location: locationConfigT,
  excludeStaff: boolean,
}): string => {
  if (currentOccupancyMissing(location) || capacityMissing(location))
    return 'Waiting for data';
  const currentOcc = getCurrentOccupancyValue(location, excludeStaff);
  return `${currentOcc || currentOcc === 0 ? currentOcc : ''}`;
};

export const getLastUpdatedTimestamp = (location: locationConfigT): string => {
  if (!location.latestTimestamp) return 'Unknown';
  return moment(location.latestTimestamp).format('HH:mm:ss');
};

export const getCapacity = (location: locationConfigT): string => {
  if (currentOccupancyMissing(location) || capacityMissing(location)) return '';
  if (!location.capacity && location.allowWithoutCapacity) return 'Not set';
  // $FlowIgnore - location.capacity is checked within capacityMissing()
  return `${location.capacity}`;
};

export const getCurrentOccupancyValue = (
  location: locationConfigT,
  excludeStaff?: boolean
): ?number => {
  if (excludeStaff) return location.currentOccupancyCustomers;
  return location.currentOccupancy;
};

export const getCurrentOccupancyPercentageValue = (
  location: locationConfigT,
  excludeStaff?: boolean
): number => {
  const currentOcc = getCurrentOccupancyValue(location, excludeStaff);
  if (!currentOcc || !location.capacity) return 0;
  return currentOcc / location.capacity;
};

export const getOccupancyAsPc = ({
  location,
  short,
  excludeStaff,
  isDemoUser = false,
}: {
  location: locationConfigT,
  short?: boolean,
  excludeStaff: boolean,
  isDemoUser?: boolean,
}): string => {
  if (!location.capacity) return '-';

  const error = isDemoUser ? null : getOccupancyErrors(location);
  if (error) {
    return error.message;
  }

  const currentOccPc = getCurrentOccupancyPercentageValue(
    location,
    excludeStaff
  );

  const prettyPc = Math.round(currentOccPc * 100);

  if (short) return `${prettyPc}%`;
  return `${prettyPc}% Occupancy`;
};

export const getStatus = ({
  location,
  excludeStaff,
  isDemoUser = false,
}: {
  location: locationConfigT,
  excludeStaff: boolean,
  isDemoUser?: boolean,
}): string => {
  const error = isDemoUser ? null : getOccupancyErrors(location);
  if (error) {
    return error.message;
  }

  const pc = getCurrentOccupancyPercentageValue(location, excludeStaff);

  if (pc < 0.9) return 'Safe to enter';
  if (pc < 1) return 'Warning';
  if (pc === 1) return 'At capacity';
  if (pc > 1) return 'Over capacity';

  return '';
};

export const getStatusColour = (
  {
    location,
    excludeStaff,
  }: {
    location: locationConfigT,
    excludeStaff: boolean,
  },
  theme: Object
): string => {
  if (
    currentOccupancyMissing(location) ||
    !location.capacity ||
    location.missingData
  ) {
    return theme.properties['--darkblue'];
  }

  const pc = getCurrentOccupancyPercentageValue(location, excludeStaff);

  if (pc < 0.9) return theme.properties['--ok'];
  if (pc < 1) return theme.properties['--warning'];
  if (pc >= 1) return theme.properties['--error'];

  return '#5c7080';
};

export type getDefaultLocationConfigArgsT = {
  location: LocationModel.t,
  locations: LocationModel.t[],
  recordings: RecordingModel.t[],
};
export const getDefaultLocationConfig = ({
  location,
  locations,
  recordings,
}: getDefaultLocationConfigArgsT): locationConfigT => {
  const locationConfig: locationConfigT = {
    id: location.id,
    name: location.name,
  };

  const locationsWithLiveOccupancyActive =
    LocationModel.getLocationsWithLiveOccupancyActive(locations, recordings);

  const locationMatch = locationsWithLiveOccupancyActive.find(
    (loc) => loc.id === location.id
  );

  if (!locationMatch) {
    locationConfig.areaContextsNotAvailable = true;
  } else {
    const recordingsInLocation = locationMatch
      ? RecordingModel.getRecordingsForLocation(recordings, locationMatch)
      : null;

    if (!recordingsInLocation || !recordingsInLocation.length) {
      locationConfig.areaContextsNotAvailable = false;
      return locationConfig;
    }

    const firstRecordingData = recordingsInLocation[0];

    if (firstRecordingData && firstRecordingData.uploadingSchedule) {
      const isShopOpen = isCurrentDateInSchedule(
        firstRecordingData.uploadingSchedule,
        firstRecordingData.timezone
      );

      if (!isShopOpen) {
        locationConfig.isShopClosed = true;
      }
    }
  }

  return locationConfig;
};

export const checkIfLocationIsValid = ({
  location,
  recordings,
}: {
  location: LocationModel.t,
  recordings: RecordingModel.t[],
}): locationConfigT => {
  const locationConfig: locationConfigT = {
    id: location.id,
    name: location.name,
  };

  const isLiveOccupancyActive =
    LocationModel.getLocationsWithLiveOccupancyActive([location], recordings)
      .length === 1;

  if (!isLiveOccupancyActive) {
    locationConfig.areaContextsNotAvailable = true;
    return locationConfig;
  }

  const recordingsInLocation = RecordingModel.getRecordingsForLocation(
    recordings,
    location
  );

  if (!recordingsInLocation || !recordingsInLocation.length) {
    locationConfig.areaContextsNotAvailable = false;
    return locationConfig;
  }

  const firstRecordingData = recordingsInLocation[0];

  if (firstRecordingData && firstRecordingData.uploadingSchedule) {
    const isShopOpen = isCurrentDateInSchedule(
      firstRecordingData.uploadingSchedule,
      firstRecordingData.timezone
    );

    if (!isShopOpen) {
      locationConfig.isShopClosed = true;
    }
  }

  return locationConfig;
};

// TODO: combine getLocationConfigWithData and generateTaxonomyOccupancyItem
export const getLocationConfigWithData = ({
  location,
  data,
}: {
  location: LocationModel.t,
  data: Object,
}): locationConfigT => {
  const locationConfig = {
    id: location.id,
    name: location.name,
    capacity: LocationModel.getLiveOccupancyCapacity(location),
    currentOccupancy: data.overallOccupancy.occupancy,
    currentOccupancyStaff: data.overallOccupancy.occupancyStaff,
    currentOccupancyCustomers: data.overallOccupancy.occupancyCustomers,
    missingData: data.status === 'issues',
  };

  return locationConfig;
};

export const generateTaxonomyOccupancyItem = (
  taxonomyName: string,
  data: Object
): locationConfigT => {
  const timestamps = flatten(
    data.results.map((rec) => rec.areas.map((area) => area.timestamp))
  );
  const latestTimestamp = timestamps.sort((a, b) => (a < b ? 1 : -1))[0];

  return {
    id: taxonomyName,
    name: taxonomyName,
    allowWithoutCapacity: true,
    currentOccupancy: data.overallOccupancy.occupancy,
    currentOccupancyStaff: data.overallOccupancy.occupancyStaff,
    currentOccupancyCustomers: data.overallOccupancy.occupancyCustomers,
    missingData: data.status === 'issues',
    latestTimestamp,
  };
};

export type getLocationConfigArgsT = {
  location: LocationModel.t,
  locations?: LocationModel.t[],
  recordings?: RecordingModel.t[],
  data?: Object,
};
export const getLocationConfig = ({
  location,
  data,
  locations,
  recordings,
}: getLocationConfigArgsT): locationConfigT => {
  if (data && !locations && !recordings) {
    return getLocationConfigWithData({
      location,
      data,
    });
  } else if (!data && locations && recordings) {
    return getDefaultLocationConfig({
      location,
      locations,
      recordings,
    });
  }

  return {};
};

export const getUrlQueryFromContexts = (
  contexts: RecordingModel.areaContextsT
): string => {
  if (!contexts || !contexts.length) return '';

  const areasQueryUrl = contexts.map((aC) => {
    return [
      {
        areaId: aC.areaId,
      },
      {
        recId: aC.recording,
      },
    ];
  });
  const url = encodeURI(JSON.stringify(areasQueryUrl));
  return url;
};

export const getAreasForUrlQueryFormat = (
  location: dwellAreaContextsForLocationsT
): ?string => {
  if (!location.areaContexts) return null;

  const areasQueryUrl = location.areaContexts.map((aC) => {
    return [
      {
        areaId: aC.areaId,
      },
      {
        recId: aC.recording,
      },
    ];
  });
  const url = encodeURI(JSON.stringify(areasQueryUrl));
  return url;
};

export type dwellAreaContextsForLocationsT = {
  location: LocationModel.t,
  areaContexts: Object[],
};

export const getDwellAreaContextsForSingleLocation = (
  location: LocationModel.t,
  recordings: RecordingModel.t[]
): dwellAreaContextsForLocationsT => {
  const recordingsMatch = RecordingModel.getRecordingsForLocation(
    recordings,
    location
  );

  const locWithAC = {
    location,
    areaContexts: [],
  };

  if (recordingsMatch) {
    recordingsMatch.forEach((recording) => {
      if (recording.areaContexts) {
        recording.areaContexts.forEach((aC) => {
          if (aC.type === LOCATION_DWELL) {
            const newAreaOccupancy = {
              ...aC,
              recordingData: recording,
            };
            locWithAC.areaContexts.push(newAreaOccupancy);
          }
        });
      }
    });
  }

  return locWithAC;
};

export const getDwellAreaContextsForLocations = (
  locations: LocationModel.t[],
  recordings: RecordingModel.t[]
): dwellAreaContextsForLocationsT[] => {
  return locations.map((loc) => {
    return getDwellAreaContextsForSingleLocation(loc, recordings);
  });
};

export const filterForLocationsWithAreaContexts = (
  locations: dwellAreaContextsForLocationsT[]
): dwellAreaContextsForLocationsT[] => {
  return locations.filter((loc) => loc.areaContexts.length > 0);
};

export const generateIncrement = (location: locationConfigT): number => {
  const rand = Math.random();
  if (
    location &&
    location.currentOccupancy &&
    location.capacity
  ) {
    const canIncrement2 = location.currentOccupancy < location.capacity * 1.1 + 2;
    const canDecrement2 = location.currentOccupancy - 2 > 0;

    if (rand < 0.25) return canDecrement2 ? -1 : +1;
    if (rand < 0.5) return canIncrement2 ? +1 : -1;
    if (rand < 0.75) return canDecrement2 ? -2 : +2;
    if (rand < 1) return canIncrement2 ? +2 : -2;
  }

  return 0;
};

export const get3QValue = (arr: any[]) =>
  [...arr].sort((a, z) => a - z)[Math.floor((arr.length * 3) / 4)];