import React from 'react';
import ReactDOM from 'react-dom';

import App from './root';
import moment from 'moment';
import 'moment/locale/en-gb';

// Set the global moment locale to en-GB initially
moment.locale('en-gb');

// Include React everywhere
window.React = React;

ReactDOM.render(<App />, document.getElementById('root'));
