import { Component } from 'react';
import DefaultLoggedIn from '../../layouts/default-logged-in';
import TopBarLoading from '../../top-bar-loading';

class LoadRecordings extends Component {
  state = {
    recordingsFetchStarted: false,
    isReady: false,
  };

  componentDidMount() {
    const { recordings, locations, fetchRecordings } = this.props;

    // TODO: this check won't work if a user has no actual recordings in DB...
    if (!!recordings.length && !!locations.length) {
      // skip fetching anything if we already have data
      this.setState({ isReady: true });
    } else {
      // get the recordings first
      if (!this.props.recordings.length || !this.props.location.length) fetchRecordings();
      this.setState({ recordingsFetchStarted: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchingRecordings } = this.props;
    const { recordingsFetchStarted, isReady } = this.state;

    if (recordingsFetchStarted && !isReady && !fetchingRecordings) {
      this.setState({ isReady: true });
    }
  }

  render() {
    return this.state.isReady ? (
      this.props.children
    ) : (
      <DefaultLoggedIn>
        <TopBarLoading />
      </DefaultLoggedIn>
    );
  }
}
export default LoadRecordings;
