import styled from 'styled-components';
import { Select } from '@blueprintjs/select';
import { Button, Checkbox, Label, MenuItem, Switch } from '@blueprintjs/core';

const smoothingPresets = [
  {
    label: 'None',
    value: 1,
  },
  {
    label: 'Low',
    value: 3,
  },
  {
    label: 'Medium',
    value: 5,
  },
  {
    label: 'High',
    value: 7,
  },
  {
    label: 'Extreme',
    value: 9,
  },
];

const Advanced = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
`;
const Option = styled.div`
  border-radius: 8px;
  background: var(--super-light-grey);
  padding: 1rem;
`;
const StyledLabel = styled(Label)`
  && {
    margin: 0 0 0.5rem;
  }
`;
const InlineLabel = styled(Label)`
  && {
    display: inline-block;
  }
`;
const StyledSwitch = styled(Switch)`
  margin: 0;
`;
const StyledCheckbox = styled(Checkbox)`
  display: inline-block;
  margin-left: 0.5rem;
`;

const AdvancedOptions = ({
  period,
  setPeriod,
  windowSize,
  setWindowSize,
  showSteps,
  setShowSteps,
  includedDays,
  setIncludedDays,
}) => {
  const updateIncludedDays = (index, value, includedDays) => {
    const newIncludedDays = [...includedDays];
    newIncludedDays[index] = value;
    return newIncludedDays;
  };

  return (
    <Advanced>
      <Option>
        <StyledLabel htmlFor="smoothing">Data smoothing</StyledLabel>
        <Select
          id="smoothing"
          filterable={false}
          items={smoothingPresets}
          itemRenderer={(item, { handleClick }) => (
            <MenuItem
              key={item.value}
              text={item.label}
              active={windowSize ? windowSize === item.value : false}
              onClick={handleClick}
            />
          )}
          onItemSelect={(item) => setWindowSize(item.value)}
        >
          <Button
            text={smoothingPresets.find((e) => e.value === windowSize).label}
            intent="primary"
            rightIcon="caret-down"
          />
        </Select>
      </Option>
      <Option>
        <StyledLabel htmlFor="steps">Show steps in the calculation</StyledLabel>
        <StyledSwitch
          id="steps"
          value={showSteps}
          onChange={(e) => setShowSteps(!showSteps)}
        />
      </Option>
      <Option>
        <StyledLabel>Include days:</StyledLabel>
        <StyledCheckbox
          id="monday"
          checked={includedDays[1]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(1, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="monday">Monday</InlineLabel>
        <StyledCheckbox
          id="tuesday"
          checked={includedDays[2]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(2, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="tuesday">Tuesday</InlineLabel>
        <StyledCheckbox
          id="wednesday"
          checked={includedDays[3]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(3, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="wednesday">Wednesday</InlineLabel>
        <StyledCheckbox
          id="thursday"
          checked={includedDays[4]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(4, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="thursday">Thursday</InlineLabel>
        <StyledCheckbox
          id="friday"
          checked={includedDays[5]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(5, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="friday">Friday</InlineLabel>
        <StyledCheckbox
          id="saturday"
          checked={includedDays[6]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(6, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="saturday">Saturday</InlineLabel>
        <StyledCheckbox
          id="sunday"
          checked={includedDays[0]}
          onChange={(e) =>
            setIncludedDays(
              updateIncludedDays(0, !!e.currentTarget.checked, includedDays)
            )
          }
        />
        <InlineLabel for="sunday">Sunday</InlineLabel>
      </Option>
    </Advanced>
  );
};

export default AdvancedOptions;
