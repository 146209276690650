// @flow

import moment from 'moment-timezone';
import { demographicValuesSortFn } from '../utils/graphHelpers';

type TimePeriodT = {
  period: string,
  start: Date
};

export type DemographicDescriptorT = {
  age: string,
  gender: string,
  role: string
};

export type t = {
  id: string,
  heatmapType: string,
  timePeriod: TimePeriodT,
  demographicDescriptor: DemographicDescriptorT,
  sourceUrl: string
};

const TIME_PERIODS = {
  DAY: 'day',
  WEEK: 'week',
};

export const create = (args: t): t => args;

export const getDemographicKeysFromList = (heatmaps: t[] = []): Object => {
  const ageValues = new Set();
  const genderValues = new Set();
  const roleValues = new Set();
  heatmaps.forEach(h => {
    const { demographicDescriptor: d } = h;
    !ageValues.has(d.age) && ageValues.add(d.age);
    !genderValues.has(d.gender) && genderValues.add(d.gender);
    !roleValues.has(d.role) && roleValues.add(d.role);
  });
  return {
    ageValues: Array.from(ageValues)
      .filter(v => !!v)
      .sort(demographicValuesSortFn),
    genderValues: Array.from(genderValues).filter(v => !!v),
    roleValues: Array.from(roleValues).filter(v => !!v)
  };
};

export const getTimePeriodsFromList = (heatmaps: t[] = []): Object => {
  const days = new Set();
  const weeks = new Set();
  heatmaps.forEach(h => {
    const { timePeriod: tp } = h;
    const { DAY, WEEK } = TIME_PERIODS;
    if (tp.period === DAY && !days.has(tp.start)) days.add(tp.start);
    if (tp.period === WEEK && !weeks.has(tp.start)) weeks.add(tp.start);
  });
  const sortingFn = (a, b) => (new Date(a) < new Date(b) ? 1 : -1);
  return {
    days: Array.from(days).sort(sortingFn),
    weeks: Array.from(weeks).sort(sortingFn)
  };
};

export const getHeatmapTypesFromList = (heatmaps: t[] = []): string[] => {
  const types = new Set();
  heatmaps.forEach(h => !types.has(h.heatmapType) && types.add(h.heatmapType));

  const showExtraHeatmaps = window.localStorage.getItem('showExtraHeatmaps');

  return Array.from(types).filter(type =>
    !showExtraHeatmaps && ['entry', 'exit', 'lingerPeopleCount'].includes(type)
      ? false
      : true
  );
};

type timePeriodT = {
  periodType?: string,
  periodValue?: string
};

export const getHeatmapsForSelectedDay = (
  heatmaps: t[] = [],
  timePeriod: timePeriodT,
  timezone: string
): t[] => {
  const selectedHeatmapDate = moment(timePeriod.periodValue);

  return heatmaps.filter(h => {
    if (timePeriod.periodType !== 'day') return false; // only support daily heatmaps right now
    const thisHeatmapDate = moment(h.timePeriod.start).tz(timezone);
    return selectedHeatmapDate.tz(timezone).isSame(thisHeatmapDate, 'day');
  });
};

export const getInitialPeriod = (heatmaps: t[] = []): Object | null => {
  const { days, weeks } = getTimePeriodsFromList(heatmaps);
  if (!!weeks.length) {
    return {
      periodType: 'week',
      periodValue: weeks[0]
    };
  }
  if (!!days.length) {
    return {
      periodType: 'day',
      periodValue: days[0]
    };
  }
  return null;
};
