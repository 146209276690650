// @flow

import { breakpoints } from '../styles/variables';

export const getDeviceType = () => {
  if  (window.innerWidth <= parseInt(breakpoints.PHONE_SIZE)) {
    return 'phone';
  }

  if (window.innerWidth <= parseInt(breakpoints.TABLET_SIZE) && window.innerWidth > parseInt(breakpoints.PHONE_SIZE)) {
    return 'tablet';
  }

  return 'desktop';
};
