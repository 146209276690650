// @flow

import { PRESET_TITLE_MODIFIER } from '../components/date-and-comparison-date-selector/dates-presets-config';
import { getDaysBetweenDates } from '../utils/dates';

import * as PeriodModel from '../models/period';
import type { periodPresetConfigT } from '../components/date-and-comparison-date-selector/dates-presets-config';

export const removePresetFromCompPeriodAllowed = (
  periodPresetConfig: periodPresetConfigT,
  preset: PeriodModel.compareToPastPresetsT
): periodPresetConfigT => {
  return Object.entries(periodPresetConfig)
    .map(([key, value]) => {
      const newCompPeriodAllowed =
        value && Array.isArray(value.compPeriodAllowed)
          ? value.compPeriodAllowed.filter(
              (compPeriod) => preset !== compPeriod
            )
          : [];
      const newValue = {
        ...value,
        compPeriodAllowed: newCompPeriodAllowed
      };

      return [key, newValue];
    })
    .reduce((finalPeriodPresetConfig, [key, value]) => {
      finalPeriodPresetConfig[key] = value;
      return finalPeriodPresetConfig;
    }, {});
};

export const fixTitleModifiers = (
  title: string,
  period: PeriodModel.comparePeriodT | PeriodModel.activeComparePeriodT
): string => {
  let newTitle = title;

  if (title.match(PRESET_TITLE_MODIFIER.DAYS_COUNT)) {
    if (
      period &&
      period.selectedDates &&
      period.selectedDates.start &&
      period.selectedDates.end
    ) {
      const difference = Math.round(
        getDaysBetweenDates(
          period.selectedDates.start,
          period.selectedDates.end
        )
      );
      newTitle = newTitle.replace(
        PRESET_TITLE_MODIFIER.DAYS_COUNT,
        String(difference + 1)
      );
      if (difference + 1 === 1) {
        newTitle = newTitle.replace('days', 'day');
      }
    }
  }

  return newTitle;
};
