// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import {
  getQueryPayloadType,
  QUERY_API_BODY_PAYLOAD_TYPES
} from '../services/query-api';
import { errorToast } from '../utils/toaster';
import * as ACTIONS from '../constants/actions';
import { IGNORE_LINGER_UNDER } from '../constants/features';
import * as Selectors from '../selectors';
import { getStandardFormatDate } from '../utils/dates';

import * as ActiveMetricsModel from '../models/active-metrics';
import * as AvailableMetricsModel from '../models/available-metrics';
import * as QueryResponseModel from '../models/query-response';
import * as QueryApiRequestModel from '../models/query-api-request';
import { fetchSQLQuery } from '../services/sql-api';
import { apiEndpointByMetricKey } from '../constants/api-endpoints';
import { ROLES } from '../constants/demographic-types';
export type queryWrapperT = {
  queryTracking: QueryApiRequestModel.t,
  extraMetaConfig: {
    isComparison: boolean,
    isAllLocations: boolean,
    activeMetrics: ActiveMetricsModel.t,
    metric?: ActiveMetricsModel.itemT
  },
  queryRequest?: QueryApiRequestModel.t
};

const getDefaultQuery = (
  period: QueryApiRequestModel.periodT,
  includedLocations: string[],
  breakdownByDimensions?: string[],
  ages?: string[],
  genders?: string[],
  roles?: string[],
  aggregation?: QueryApiRequestModel.aggregationEnumT = 'day'
): QueryApiRequestModel.t => {
  let start = '';
  let end = '';

  // $FlowFixMe -> Flow warns that selectedStart and selectedEnd doesn't exist in periodT
  const { selectedStart, selectedEnd } = period;

  if (
    selectedStart &&
    (typeof selectedStart === 'string' || selectedStart instanceof Date) &&
    selectedEnd &&
    (typeof selectedEnd === 'string' || selectedEnd instanceof Date)
  ) {
    start = getStandardFormatDate(selectedStart);
    end = getStandardFormatDate(selectedEnd);
  } else if (
    period.start &&
    period.end &&
    typeof period.start === 'string' &&
    typeof period.end === 'string'
  ) {
    start = period.start;
    end = period.end;
  }

  return {
    locations: includedLocations,
    period: {
      start,
      end
    },
    breakdownByDimensions,
    ages,
    genders,
    roles,
    aggregation
  };
};

export const getQueryAndMetric = ({
  queryWrapper,
  availableMetrics,
  metricGroupKey,
  metricKey
}: {
  queryWrapper: queryWrapperT,
  availableMetrics: AvailableMetricsModel.t,
  metricGroupKey: string,
  metricKey: string
}): {
  defaultQuery: QueryApiRequestModel.t,
  endpointDetails: AvailableMetricsModel.endpointT
} => {
  const { queryTracking } = queryWrapper;
  const { period, locations, aggregation, breakdownByDimensions, ages, genders, roles } = queryTracking;

  const metricDetails = AvailableMetricsModel.findMetricDetails(
    availableMetrics,
    metricGroupKey,
    metricKey
  );

  const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
    metricDetails
  );

  const defaultQuery = getDefaultQuery(period, locations, breakdownByDimensions, ages, genders, roles, aggregation);

  return { defaultQuery, endpointDetails };
};

export const executeRequest = ({
  request,
  dispatch
}: {
  request: queryWrapperT,
  dispatch: () => void
}) => {
  /*
    NOTE: query VS queryWrapper
    - queryRequest -> Contains the query that is going to be send in the request to the API.
    - queryTracking -> Is the query Configurations that the Frontend uses to track the normal query
  */
  const { extraMetaConfig, queryRequest } = request;
  const { metric } = extraMetaConfig;

  if (metric) {
    const { metricKey } = metric;

    if (queryRequest) {
      const path = apiEndpointByMetricKey(metricKey);

      if (path && path !== '') {
        try {
          fetchQueryDataRequest(queryRequest, metricKey, dispatch);
          fetchSQLQuery(queryRequest, path, { metricKey }).then(res => {
            if (res) {
              onFetchSuccess({
                queryWrapper: request,
                res,
                dispatch
              });
            } else {
              fetchQueryDataGeneralError(queryRequest, metricKey, dispatch);
            }
          });
        } catch (e) {
          fetchQueryDataGeneralError(queryRequest, metricKey, dispatch);
        }
      } else {
        fetchQueryDataGeneralError(queryRequest, metricKey, dispatch);
      }
    }
  } else {
    throw new Error('No metric available for request');
  }
};

const fetchQueryDataRequest = (
  query: QueryApiRequestModel.t,
  metricKey: string,
  dispatch: any => void
): void => {
  try {
    const payload = { metricKey, query };
    dispatch({ type: ACTIONS.FETCH_QUERY_DATA_REQUEST, payload });
  } catch (error) {}
};

const fetchQueryDataSuccess = (
  query: QueryApiRequestModel.t,
  metricKey: string,
  payload,
  dispatch
): void => {
  try {
    const { period, aggregation } = query;
    const { excludeStaff, filter } = payload;
    const payloadQueryData = {
      ...payload,
      filter,
      excludeStaff,
      period,
      aggregation,
      metricKey,
      query
    };

    dispatch({
      type: ACTIONS.FETCH_QUERY_DATA_SUCCESS,
      payload: payloadQueryData
    });
  } catch (error) {}
};

const fetchQueryDataGeneralError = (
  query: QueryApiRequestModel.t,
  metricKey: string,
  dispatch: any => void
): void => {
  // Handle errors that arent 404 server responses
  try {
    const payload = { metricKey, query };
    dispatch({ type: ACTIONS.FETCH_QUERY_DATA_GENERAL_ERROR, payload });
  } catch (error) {}
};

const fetchQueryDataMetricsError = (
  query,
  metricKey,
  payloadDefault,
  dispatch
) => {
  // Handle 404 response errors
  try {
    const payload = { ...payloadDefault, metricKey, query };
    dispatch({
      type: ACTIONS.FETCH_QUERY_DATA_METRICS_ERROR,
      payload
    });
  } catch (error) {}
};

type onFetchSuccessArgs = {
  queryWrapper: queryWrapperT,
  res: QueryResponseModel.t | QueryResponseModel.errorT,
  dispatch: (action: FluxStandardAction<string, *>) => void
};
export const onFetchSuccess = ({
  queryWrapper,
  res,
  dispatch
}: onFetchSuccessArgs): void => {
  const { queryTracking, extraMetaConfig, queryRequest } = queryWrapper;
  const { locations, excludeStaff, breakdownByDimensions, ages, genders, roles } = queryTracking;
  const { isAllLocations, isComparison, metric } = extraMetaConfig;

  if (metric && queryRequest) {
    const { metricGroupKey, metricKey, taxonomies, filter } = metric;
    const { aggregation, period } = queryRequest;

    if (metricKey) {
      const payload = {
        period,
        aggregation,
        excludeStaff,
        isComparison,
        isAllLocations,
        locations,
        metricGroupKey,
        metricKey,
        taxonomies,
        filter,
        breakdownByDimensions,
        ages,
        genders,
        roles,
        res
      };

      if (res.status === 404) {
        fetchQueryDataMetricsError(queryRequest, metricKey, payload, dispatch);
      } else {
        fetchQueryDataSuccess(queryRequest, metricKey, payload, dispatch);
      }
    }
  }
};

const getQueryRequests = ({
  queryWrapper,
  availableMetrics,
  dispatch,
  ignoreLingerUnderFromFeature
}: {
  availableMetrics: ?AvailableMetricsModel.t,
  queryWrapper: queryWrapperT,
  dispatch: () => void,
  ignoreLingerUnderFromFeature: string
}): queryWrapperT[] => {
  const { extraMetaConfig, queryTracking } = queryWrapper;
  const { activeMetrics } = extraMetaConfig;
  const { excludeStaff } = queryTracking;

  const queryList = [];

  Object.entries(activeMetrics).forEach(
    ([metricGroupKey, activeMetricObject]) => {
      availableMetrics &&
        // $FlowFixMe - not fixing because we haven't worked out how to define returns from Object.entries
        activeMetricObject.metrics &&
        Array.isArray(activeMetricObject.metrics) &&
        activeMetricObject.metrics.length > 0 &&
        activeMetricObject.metrics.forEach(async metricKey => {
          if (typeof metricKey !== 'string') return null;
          const {
            defaultQuery,
            endpointDetails
          } = getQueryAndMetric({
            queryWrapper,
            availableMetrics,
            metricGroupKey,
            metricKey
          });

          const path = apiEndpointByMetricKey(metricKey);

          const queryBodyType = getQueryPayloadType(
            path,
            endpointDetails
          );

          if (typeof metricKey === 'string') {
            switch (queryBodyType) {
              case QUERY_API_BODY_PAYLOAD_TYPES.SALES: {
                const query = {
                  ...defaultQuery,
                  roles: undefined,
                };
                queryList.push({
                  ...queryWrapper,
                  extraMetaConfig: {
                    ...queryWrapper.extraMetaConfig,
                    metric: { metricGroupKey, metricKey }
                  },
                  queryRequest: query
                });
                break;
              }
              case QUERY_API_BODY_PAYLOAD_TYPES.AREAS: {
                if (
                  // $FlowFixMe - not fixing because we haven't worked out how to define returns from Object.entries
                  activeMetricObject.taxonomies &&
                  Array.isArray(activeMetricObject.taxonomies) &&
                  activeMetricObject.taxonomies.length > 0
                ) {
                  // $FlowFixMe - not fixing because we haven't worked out how to define returns from Object.entries
                  activeMetricObject.taxonomies.forEach(async taxonomyKey => {
                    const query = {
                      ...defaultQuery,
                      taxonomies: [taxonomyKey],
                      areaType: 'taxonomy',
                      roles: excludeStaff ? ['customer'] : ROLES,
                    };
                    queryList.push({
                      ...queryWrapper,
                      extraMetaConfig: {
                        ...queryWrapper.extraMetaConfig,
                        metric: {
                          metricGroupKey,
                          metricKey,
                          taxonomies: [taxonomyKey],
                          areaType: 'taxonomy',
                        }
                      },
                      queryRequest: query
                    });
                  });
                } else {
                  errorToast({
                    message: 'Please select at least 1 zone type'
                  });
                }
                break;
              }
              case QUERY_API_BODY_PAYLOAD_TYPES.CUSTOM_MOVEMENT_WITHIN_LOCATION: {
                const withinLocationKey = metricKey.split('__')[1];
                if (queryWrapper.extraMetaConfig &&
                  queryWrapper.extraMetaConfig.activeMetrics &&
                  queryWrapper.extraMetaConfig.activeMetrics.movementWithFilters &&
                  queryWrapper.extraMetaConfig.activeMetrics.movementWithFilters.filters &&
                  queryWrapper.extraMetaConfig.activeMetrics.movementWithFilters.filters.length) {

                  queryWrapper.extraMetaConfig.activeMetrics.movementWithFilters.filters.forEach((filter) => {
                    const taxonomyKey = `${filter.key}:${filter.value}`;
                    const query = {
                      ...defaultQuery,
                      withinLocationKey,
                      taxonomies: [taxonomyKey],
                      roles: excludeStaff ? ['customer'] : ROLES,
                    };
                    queryList.push({
                      ...queryWrapper,
                      extraMetaConfig: {
                        ...queryWrapper.extraMetaConfig,
                        metric: {
                          metricGroupKey,
                          metricKey,
                          taxonomies: [taxonomyKey],
                        }
                      },
                      queryRequest: query
                    });
                  });
                } else {
                  const query = {
                    ...defaultQuery,
                    withinLocationKey,
                    roles: excludeStaff ? ['customer'] : ROLES,
                  };
                  queryList.push({
                    ...queryWrapper,
                    extraMetaConfig: {
                      ...queryWrapper.extraMetaConfig,
                      metric: {
                        metricGroupKey,
                        metricKey,
                      }
                    },
                    queryRequest: query
                  });
                }
                break;
              }
              case QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT_WITHIN_LOCATION: {
                if (
                  activeMetricObject &&
                  activeMetricObject.filters &&
                  Array.isArray(activeMetricObject.filters)
                ) {
                  activeMetricObject.filters.forEach((filter) => {
                    if (filter && filter.key && filter.value && typeof filter.key === 'string' && typeof filter.value === 'string') {
                      const taxonomyKey = `${filter.key}:${filter.value}`;
                      const query = {
                        ...defaultQuery,
                        taxonomies: [taxonomyKey],
                        roles: excludeStaff ? ['customer'] : ROLES,
                      };

                      queryList.push({
                        ...queryWrapper,
                        extraMetaConfig: {
                          ...queryWrapper.extraMetaConfig,
                          metric: {
                            metricGroupKey,
                            metricKey,
                            taxonomies: [taxonomyKey],
                          }
                        },
                        queryRequest: query
                      });
                    }
                  });
                }
                break;
              }
              case QUERY_API_BODY_PAYLOAD_TYPES.SALES_CONVERSION_RATE: {
                const query = {
                  ...defaultQuery,
                  // conversion rate on the API is always just the customers
                  roles: undefined,
                };
                queryList.push({
                  ...queryWrapper,
                  extraMetaConfig: {
                    ...queryWrapper.extraMetaConfig,
                    metric: { metricGroupKey, metricKey }
                  },
                  queryRequest: query
                });
                break;
              }
              case QUERY_API_BODY_PAYLOAD_TYPES.LOCATION_AREAS:
              case QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT:
              default: {
                const query = {
                  ...defaultQuery,
                  roles: excludeStaff ? ['customer'] : ROLES,
                };
                queryList.push({
                  ...queryWrapper,
                  extraMetaConfig: {
                    ...queryWrapper.extraMetaConfig,
                    metric: {
                      metricGroupKey,
                      metricKey
                    }
                  },
                  queryRequest: query
                });
                break;
              }
            }
          }
        });
    }
  );

  // $FlowFixMe -> Flow thinks that queryItems are MIX type
  return queryList;
};

export const fetchQueryResponses = (
  queryWrapper: queryWrapperT
): FluxStandardAction<string, QueryResponseModel.t, *> => async (
  dispatch,
  getState
) => {
  const state = getState();
  const availableMetrics = Selectors.getAvailableMetrics(state);
  const ignoreLingerUnderFromFeature = Selectors.valueOfUserFeature(
    IGNORE_LINGER_UNDER,
    state
  );

  const queryRequests = getQueryRequests({
    queryWrapper,
    dispatch,
    availableMetrics,
    ignoreLingerUnderFromFeature
  });

  for (let i = 0; i < queryRequests.length; i++) {
    executeRequest({
      queryWrapper,
      request: queryRequests[i],
      dispatch
    });
  }
};

export const clearQueryResponseList = (): FluxStandardAction<string> => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ACTIONS.CLEAR_QUERY_RESPONSE_LIST });
  } catch (error) {
    errorToast({ message: `Error: ${error}` });
  }
};
