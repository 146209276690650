// @flow

import styled from 'styled-components';

import { WidgetTooltip } from './widget-tooltip';
import { breakpoints } from '../../../styles/variables';

const WidgetHeaderWrapper = styled.div`
  padding: 2rem;
  border-bottom: var(--widget-divider);
  background-color: var(--white);
  border-radius: var(--widget-border-radius) var(--widget-border-radius) 0 0;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 1.5rem 1.5rem;
  }
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  color: var(--widget-heading-text);
  margin: 0;
  margin-right: 12px;
  font-size: 1.55em;
  font-family: var(--fonts-heading);
`;

const Subtitle = styled.p`
  color: rgba(114, 127, 128);
  margin: 0;
  margin-bottom: 0;
  font-size: 1.18em;
`;

type Props = {
  title: string,
  subtitle: string,
  description?: string
};

const WidgetHeader = ({ title, subtitle, description }: Props) => {
  return (
    <WidgetHeaderWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        {description && <WidgetTooltip description={description} />}
      </TitleWrapper>
      <Subtitle>{subtitle}</Subtitle>
    </WidgetHeaderWrapper>
  );
};

export default WidgetHeader;
