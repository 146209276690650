// @flow

export type t = {
  id?: string,
  name?: string,
  description?: string,
  config?: Object,
  status?: string,
  order?: number,
};

export const create = (args: t): t => args;

export const findById = (reports: t[], id: string): ?t => {
  if (!reports || !reports.length) return null;
  return reports.find(r => r.id === id);
};
