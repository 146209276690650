export const prettyLabel = value => {
  switch (value) {
    case 'path':
      return 'Popular paths';
    case 'linger':
      return 'Dwell areas';
    case 'entry':
      return 'Entry points';
    case 'exit':
      return 'Exit points';
    case 'lingerAccumulatedTime':
      return 'Dwell: total time';
    case 'interaction':
      return 'Overcrowding hotspots';
    case 'lingerAverageTime':
      return 'Dwell: average time';
    case 'lingerPeopleCount':
      return 'Dwell: count of people';
    case 'male':
      return 'Male';
    case 'female':
      return 'Female';
    case 'males':
      return 'Males';
    case 'females':
      return 'Females';
    case 'cant':
      return '?';
    case '0_15':
      return '<16';
    case '16_24':
    case '25_34':
    case '35_44':
    case '45_54':
    case '55_64':
      return value.replace('_', '-');
    case '65_100':
      return '>65';
    case 'staff':
      return 'Staff';
    case 'customer':
      return 'Customers';
    case 'role':
      return 'Roles';
    case 'age':
      return 'Ages';
    case 'gender':
      return 'Genders';
    case 'undefined':
      return 'Unknown';
    // interaction heatmaps
    case 'interaction-customer-customer':
      return 'Customers close to other customers';
    case 'interaction-customer-staff':
      return 'Customers close to staff';
    case 'interaction-staff-staff':
      return 'Staff close to other staff';
    default:
      return value;
  }
};

export const unitTypeLabeler = value => {
  switch (value) {
    case 'peopleCount':
      return 'Count of people';
    case 'seconds':
      return 'Time spent';
    case 'number':
      return 'Number';
    case 'rate':
      return 'Rate';
    case 'percentage':
      return 'Percentage';
    default: {
      if (value.startsWith('currency-')) {
        const currencyCode = value.split('currency-')[1];
        return currencyCode;
      }
      return value;
    }
  }
};

export const getAgeLabel = (age) => {
  const ages = {
    '0_15': 'Under 16',
    '16_24': '16-24',
    '25_34': '25-34',
    '35_44': '35-44',
    '45_54': '45-54',
    '55_64': '55-64',
    '65_100': 'Over 65',
  };
  return ages[age] || null;
};