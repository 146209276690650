// @flow

import styled from 'styled-components';
import { Tabs, Tab } from '@blueprintjs/core';
import type { BrowserHistory } from 'history/createBrowserHistory';
import * as ReportModel from '../../../models/report';

const StyledTabs = styled(Tabs)`
  overflow-x: auto;
  width: 100%;

  .bp3-tab {
    color: #666;
    font-size: 1rem;
    font-family: var(--fonts-heading);

    &[aria-selected="true"] {
      font-weight: bold;
    }
  }
`;

type Props = {
  allReports: ReportModel.t[],
  history: BrowserHistory
};

const ReportTabs = ({ allReports = [], history }: Props) => {
  const handleTabClick = tab => {
    const path = `/reports/${tab}`;
    if (!history) window.location.pathname = path;
    history.push(path);
  };

  const getSelectedTabId = () => {
    if (!history) return null;
    const { location = {} } = history;
    const { pathname = '' } = location;
    return pathname.split('/').pop();
  };

  const selectedTabId = getSelectedTabId();

  return (
    <StyledTabs
      id="SettingsTab"
      selectedTabId={selectedTabId}
      onChange={handleTabClick}
    >
      {allReports.map(({ name, id }) => (
        <Tab id={id} key={id} title={name} />
      ))}
    </StyledTabs>
  );
};

export default ReportTabs;
