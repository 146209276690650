import { Icon } from '@blueprintjs/core';
import styled from 'styled-components';

import { WidgetTooltip } from '../common/widget-tooltip';

import { DISPLAY_CHART_TYPE } from '../../../utils/widgets-ui';

const LegendWrapper = styled.ul`
  display: flex;
  padding: 0;
  margin-left: 60px;
  text-transform: capitalize;
`;
const LegendItemWrapper = styled.li`
  color: ${(props) => props.color};
  display: inline-flex;
  margin-right: 40px;
`;
const LegendItemText = styled.span`
  display: flex;
  color: var(--widget-heading-text);
  margin-left: 4px;
`;

const GraphLegend = (props) => {
  const { payload, metrics } = props;

  return (
    <LegendWrapper>
      {payload.map((data, index) => {
        const { color, type } = data;
        const { label, description } = metrics[index];
        return (
          <LegendItemWrapper color={color} key={index}>
            <Icon
              style={{
                fill: 'inherit',
              }}
              icon={
                type === DISPLAY_CHART_TYPE.LINE
                  ? 'timeline-line-chart'
                  : 'grouped-bar-chart'
              }
            />
            <LegendItemText>
              {description ? (
                <WidgetTooltip text={label} description={description} />
              ) : (
                label
              )}
            </LegendItemText>
          </LegendItemWrapper>
        );
      })}
    </LegendWrapper>
  );
};

export default GraphLegend;
