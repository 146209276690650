export const ALL = 'all';
export const GENDER = 'gender';
export const AGE = 'age';
export const ROLE = 'role';

export const SORTED_AGE_KEYS = [
  '0_15',
  '16_24',
  '25_34',
  '35_44',
  '45_54',
  '55_64',
  '65_100',
];

export const GENDERS = ['male', 'female'];

export const ROLES = ['staff', 'customer'];