// @flow

import { useEffect, useState } from 'react';
import OpeningTime from './opening-time';
import * as LocationModel from '../../models/location';
import { Checkbox } from '@blueprintjs/core';

type Props = {
  openingTimes: LocationModel.openTimeT[],
  disabled: boolean,
  updateEdits(Object): Promise<any>
};

const EditOpeningTimes = ({ openingTimes, disabled, updateEdits }: Props) => {
  const [allSame, setAllSame] = useState(false);
  const [allDaysOpeningTime, setAllDaysOpeningTime] = useState({
    startHour: 9,
    startMinute: 0,
    stopHour: 22,
    stopMinute: 0
  });
  const [isClosed, setIsClosed] = useState(
    (() => {
      const closed = new Array(7);
      openingTimes.forEach(openingTime => {
        if (
          openingTime.startHour === 0 &&
          openingTime.startMinute === 0 &&
          openingTime.stopHour === 0 &&
          openingTime.stopMinute === 0
        ) {
          closed[openingTime.day] = true;
        } else {
          closed[openingTime.day] = false;
        }
      });
      return closed;
    })()
  );

  useEffect(() => {
    const closed = new Array(7);
    openingTimes.forEach(openingTime => {
      if (
        openingTime.startHour === 0 &&
        openingTime.startMinute === 0 &&
        openingTime.stopHour === 0 &&
        openingTime.stopMinute === 0
      ) {
        closed[openingTime.day] = true;
      } else {
        closed[openingTime.day] = false;
      }
    });
    setIsClosed(closed);
  }, [openingTimes]);

  const updateOpeningTime = (day: number, value: Object) => {
    if (typeof openingTimes !== 'undefined') {

      const newOpeningTimes = [...openingTimes];
      const index = openingTimes.findIndex((openingTime) => openingTime.day === day);
      newOpeningTimes[index] = value;
      updateEdits({
        openingTimes: newOpeningTimes,
      });
    }
  };

  const updateAllOpeningTimes = (_, value: Object) => {
    setAllDaysOpeningTime(value);

    updateEdits({
      openingTimes: (() => {
        const openingTimes = [];

        for (let i = 0; i < 7; i +=1) {
          openingTimes.push({
            ...value,
            day: i,
          });
        }

        return openingTimes;
      })()
    });
  };

  const updateDayIsClosed = (day: number, value: boolean) => {
    const newIsClosed = [...isClosed];
    newIsClosed[day] = value;
    setIsClosed(newIsClosed);
  };

  const buildTimePickers = () => {
    return openingTimes.map((openingTime: LocationModel.openTimeT, i: number) => (
        <OpeningTime
          key={`opening_time_${i}`}
          index={openingTime.day}
          openingTime={openingTime}
          isClosed={isClosed[openingTime.day]}
          updateOpeningTime={updateOpeningTime}
          updateIsClosed={updateDayIsClosed}
          disabled={disabled}
        />
    ));
  };

  return (
    <>
      {!disabled && (
        <Checkbox
          checked={allSame}
          label="All days are the same"
          onChange={event => {
            setAllSame(!allSame);
          }}
        />
      )}
      {allSame && !disabled && (
        <OpeningTime
          key="opening_time_all"
          index={7}
          openingTime={allDaysOpeningTime}
          updateOpeningTime={updateAllOpeningTimes}
          disabled={false}
        />
      )}
      {!allSame && openingTimes && buildTimePickers()}
    </>
  );
};

export default EditOpeningTimes;
