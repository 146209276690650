// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import * as ReportActions from '../actions/reports';
import { FETCHING_REPORTS } from '../constants/async-process-names';
import LoadReports from '../components/pages/load-reports';

function mapStateToProps(state) {
  return {
    reports: Selectors.getReports(state),
    fetchingReports: Selectors.asyncProcessHappening(state, FETCHING_REPORTS)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      fetchReports: ReportActions.fetch
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadReports);
