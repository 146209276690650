import { Fragment, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { MetricText } from '../common/styles';
import { getMetricChangeValueColorAndIcon } from '../../../utils/widgets-ui';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';

const config = {
  left: {
    gridTemplate: 'grid-1 grid-2 grid-3',
    gridColumns: 'auto 60px 100px',
    justifyContent: 'flex-start',
    margin: '0 0 0 4px',
  },
  right: {
    gridTemplate: 'grid-3 grid-2 grid-1',
    gridColumns: '100px 60px auto',
    justifyContent: 'flex-end',
    margin: '0 4px 0 0',
  },
};

const MetricGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: ${({ align }) => `${config[align].gridColumns}`};
  grid-template-areas: ${({ align }) => `'${config[align].gridTemplate}'`};
  margin: ${({ align }) => `${config[align].margin}`};
`;

const MetricBarWrapper = styled.div.attrs(({ align }) => ({
  style: {
    justifyContent: `${config[align].justifyContent}`,
  },
}))`
  display: flex;
  width: 100%;
`;

const MetricBar = styled.div.attrs(({ backgroundColor, width }) => ({
  style: {
    background: backgroundColor,
    width: `${width}%`,
  },
}))`
  height: 30px;
  min-width: 2px;
  margin-right: 10px;
`;

const RankBar = ({
  align = 'left',
  value,
  unitType,
  changeValue,
  barWidth,
}) => {
  const theme = useContext(ThemeContext);
  const { prefix, color } = getMetricChangeValueColorAndIcon(
    changeValue,
    theme
  );
  return (
    <Fragment>
      <MetricGrid align={align}>
        <MetricBarWrapper align={align} style={{ gridArea: 'grid-1' }}>
          <MetricBar backgroundColor={color} width={barWidth} />
        </MetricBarWrapper>
        <MetricText style={{ gridArea: 'grid-2' }}>
          {formatNumbersWithUnits(value, unitType)}
        </MetricText>
        <MetricText color={color} style={{ gridArea: 'grid-3' }}>
          {`(${prefix}${formatNumbersWithUnits(changeValue, 'percentage')})`}
        </MetricText>
      </MetricGrid>
    </Fragment>
  );
};

export default RankBar;
