// @flow

import { useState, useEffect, Fragment, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  WidgetOverflow,
  WidgetGrid,
  WidgetCells,
  WidgetBox,
  MetricText,
  WidgetBodyWrapper,
} from '../common/styles';
import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';

import { getFormattedHeatmapDate } from '../../../utils/dates';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import {
  getRgbaWithDynamicAlpha,
  getColorPercentage
} from '../../../utils/widgets-ui';
import type { unitTypeT } from '../../../models/query-response';

const DAYS_OF_WEEK = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const DAYS_OF_WEEK_ABBREVIATION = ['m', 't', 'w', 't', 'f', 's', 's'];

// TODO widgetPropsT is duplicated becase importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean,
};

type Props = widgetPropsT & {
  /** The cols to display */
  tableRows: {
    label: string,
    cols: {
      name: string,
      value: number,
      changeValue: number
    }[]
  }[],
  /** The unit type */
  unitType: unitTypeT
};

const CalendarHeatmap = ({
  title,
  subtitle,
  description,
  //$FlowIgnore - no time to investigate right now
  tableRows,
  unitType = 'number',
  loading,
  errors,
}: Props) => {
  const theme = useContext(ThemeContext);
  const [extendedTableRows, setExtendedTableRows] = useState([]);

  useEffect(() => {
    const totalValues = tableRows
      .map(metric => {
        const { cols } = metric;
        return cols.map(entry => entry.value);
      })
      .flat();

    setExtendedTableRows(
      tableRows.map(metric => {
        const { label, cols } = metric;
        return {
          label,
          cols: DAYS_OF_WEEK.map((day, index) => {
            if (cols.map(entry => entry.name).includes(day)) {
              let srcData = cols.find(cols => cols.name === day);
              return {
                ...srcData,
                alpha: getColorPercentage(srcData.value, totalValues)
              };
            }
            return {
              name: day,
              alpha: 0,
              unitType,
            };
          })
        };
      })
    );
  }, [tableRows, unitType]);

  return (
    <WidgetWrapper
      loading={loading}
      errors={errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapper>
        <WidgetOverflow>
          <WidgetGrid>
            <span />
            <WidgetCells>
              {DAYS_OF_WEEK_ABBREVIATION.map((day, index) => (
                <WidgetBox key={index} align="center">
                  <MetricText key={index} align="center">
                    {day}
                  </MetricText>
                </WidgetBox>
              ))}
            </WidgetCells>

            {extendedTableRows.map((metric, index) => {
              const { label, cols } = metric;
              return (
                <Fragment key={index}>
                  <MetricText align="right" whiteSpace="nowrap">
                    <WidgetBox align="flex-end" hasBorder={false}>
                      {getFormattedHeatmapDate(label)}
                    </WidgetBox>
                  </MetricText>
                  <WidgetCells key={index}>
                    {cols.map((entry, index) => {
                      const { value, alpha } = entry;
                      return (
                        <WidgetBox
                          key={index}
                          align="center"
                          backgroundColor={
                            !value && value !== 0 && theme
                              ? theme.properties['--light-gray']
                              : getRgbaWithDynamicAlpha(theme.properties['--heatmap-highlight'], alpha)
                          }
                          hasBorder={true}
                        >
                          <MetricText align="center" color={theme ? theme.properties['--white'] : null}>
                            {formatNumbersWithUnits(value, unitType)}
                          </MetricText>
                        </WidgetBox>
                      );
                    })}
                  </WidgetCells>
                </Fragment>
              );
            })}
          </WidgetGrid>
        </WidgetOverflow>
      </WidgetBodyWrapper>
    </WidgetWrapper>
  );
};

export default CalendarHeatmap;
