// @flow

import { PanelBody, NotFoundText, NotFoundContainer } from '../panel';

type Props = {
  message?: string | Object // Not sure How to type this Object as a react Component
};

const NoAreasPanel = ({ message }: Props) => {
  const isObject = typeof message === 'object';

  return (
    <PanelBody>
      {isObject && <NotFoundContainer>{message}</NotFoundContainer>}
      {!isObject && (
        <NotFoundText>{message || 'No recordings have areas.'}</NotFoundText>
      )}
    </PanelBody>
  );
};

export default NoAreasPanel;
