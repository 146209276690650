const { AuthAPI } = require('../services/adapters/authServiceAdapter');

/**
 * Abstracts api call over BootstrapBuilder.getBootstrap
 * if we have BootstrapBuilder, or the authServiceAdapter
 * if do not have BootstrapBuilder (we're not in the Auth
 * Service)
 * @param BootstrapBuilder
 * @param token
 * @param auth0Id
 * @param skipCache
 * @param authEnvBasePath
 * @param logger
 */
const getBootstrapFromAuthService = async (
  BootstrapBuilder,
  token,
  auth0Id,
  skipCache,
  authEnvBasePath,
  logger,
) => {
  logger.info({
    message: 'User.getBootstrapFromAuthService start',
    contextObject: {
      BootstrapBuilder: !!BootstrapBuilder,
      auth0Id,
      skipCache,
      authEnvBasePath,
    },
  });

  if (BootstrapBuilder) {
    logger.info({
      message: 'User.getBootstrapFromAuthService have BootstrapBuilder, so call '
        + 'getBootstrap directly with:',
      contextObject: { auth0Id, skipCache },
    });
    return BootstrapBuilder.getBootstrap(auth0Id, skipCache);
  }

  logger.info({
    message: 'User.getBootstrapFromAuthService do not have BootstrapBuilder '
      + ', so call via authAPI with:',
    contextObject: {
      authEnvBasePath, auth0Id, skipCache,
    },
  });

  const authAPI = new AuthAPI(logger.info, authEnvBasePath, null);

  return (await authAPI.getBootstrap(
    auth0Id, token, skipCache,
  )).payload;
};

module.exports = {
  getBootstrapFromAuthService,
};
