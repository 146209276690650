import { useEffect } from 'react';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';
import HeadcountPlanner from '../../headcount-planner';
import Layout from '../../layouts/default-logged-in';

const HeadcountPlannerPage = ({ locations, availableMetrics, isDemoUser }) => {
  // run only of first load, not re-render
  useEffect(() => {
    trackEvent(ENTER_PAGE, 'HEADCOUNT_PLANNER');
  }, []);

  return (
    <Layout>
      <HeadcountPlanner
        locations={locations}
        availableMetrics={availableMetrics}
        isDemoUser={isDemoUser}
      />
    </Layout>
  );
};

export default HeadcountPlannerPage;
