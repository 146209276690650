// @flow

import { Fragment, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { ProgressBar, Tag, Button } from '@blueprintjs/core';
import { LIVE_OCC } from '../../../constants/routes';
import * as LocationModel from '../../../models/location';
import {
  getStatus,
  getOccupancyAsPc,
  getOccupancy,
  getCapacity,
  getStatusColour,
  getCurrentOccupancyPercentageValue,
  currentOccupancyMissing,
  getOccupancyErrors,
  getLocationConfigWithData,
  HISTORY_LENGTH,
  UPDATE_LIVE_TIMEOUT,
  get3QValue,
  generateIncrement,
} from '../../../utils/live-occupancy';
import OccupancyCard from './occupancy-card';
import OccupancyTitle from './ocuppancy-title';
import LocationErrorRow from './location-error-row';
import { liveOccupancy as liveOccupancyAPI } from '../../../services/live-api';
import {
  Highlights,
  SmallSpan,
  LiveFigure,
  ColouredH3,
  ProgressBarWrapper,
  TitleWrapper,
} from './common';
import { getAreasForUrlQueryFormat } from '../../../utils/live-occupancy';
import { useInterval } from '../../widgets/common/hooks';
import { getRandomInt } from '../../../utils/generalHelpers';
export type locationConfigT = {
  id: string,
  name?: string,
  capacity?: number,
  allowWithoutCapacity?: boolean,
  currentOccupancy?: number,
  currentOccupancyStaff?: number,
  currentOccupancyCustomers?: number,
  areaContextsNotAvailable?: boolean,
  missingData?: boolean,
  isShopClosed?: boolean,
  occupancyMax?: number,
  occupancyMin?: number,
  latestTimestamp?: string,
};

type Props = {
  location: LocationModel.t,
  locationConfig: locationConfigT,
  redirectTo?: string,
  excludeStaff: boolean,
  locationAndDwellAreas?: Object,
  isDemoUser?: boolean,
};

const LocationOccupancyRow = ({
  location,
  locationConfig: locConfig,
  redirectTo,
  excludeStaff,
  locationAndDwellAreas,
  isDemoUser = false,
}: Props) => {
  const theme = useContext(ThemeContext);
  const [totalOccupancyHistory, setTotalOccupancyHistory] = useState([]);
  const [staffOccupancyHistory, setStaffOccupancyHistory] = useState([]);
  const [customerOccupancyHistory, setCustomerOccupancyHistory] = useState([]);
  const [demoCurrentOccupancy, setDemoCurrentOccupancy] = useState(0);

  const [status, setStatus] = useState(null);
  const [locationConfig, setLocationConfig] = useState<locationConfigT>({});

  useEffect(() => {
    setLocationConfig(locConfig);
    if (isDemoUser) {
      setDemoCurrentOccupancy(getRandomInt(0, locConfig.capacity || 10));
    }
  }, [isDemoUser, locConfig]);

  // Clear the history after excluding/including staff
  useEffect(() => {
    setTotalOccupancyHistory([]);
    setStaffOccupancyHistory([]);
    setCustomerOccupancyHistory([]);
    updateValue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeStaff]);

  useEffect(() => {
    const overallOccupancy = {
      occupancy: get3QValue(totalOccupancyHistory),
      occupancyStaff: get3QValue(staffOccupancyHistory),
      occupancyCustomers: get3QValue(customerOccupancyHistory),
    };

    if (location && typeof overallOccupancy.occupancy !== 'undefined') {
      const newLocationConfig = getLocationConfigWithData({
        location,
        data: {
          overallOccupancy,
          status,
        },
      });

      setLocationConfig(newLocationConfig);
    }
  }, [
    customerOccupancyHistory,
    location,
    staffOccupancyHistory,
    status,
    totalOccupancyHistory,
  ]);

  const addLocResToState = (location: LocationModel.t, data: Object) => {
    const historyLength = isDemoUser ? 5 : HISTORY_LENGTH;
    // Smooth the occupancy data by taking the middle values from the history arrays
    const getNewArray = (arr, item) => [...arr, item].slice(-1 * historyLength);

    if (data && data.overallOccupancy) {
      if (typeof data.overallOccupancy.occupancy === 'number') {
        setTotalOccupancyHistory(getNewArray(
          totalOccupancyHistory,
          data.overallOccupancy.occupancy
        ));
      }
      if (typeof data.overallOccupancy.occupancyStaff === 'number')
        setStaffOccupancyHistory(
          getNewArray(
            staffOccupancyHistory,
            data.overallOccupancy.occupancyStaff
          )
        );
      if (typeof data.overallOccupancy.occupancyCustomers === 'number')
        setCustomerOccupancyHistory(
          getNewArray(
            customerOccupancyHistory,
            data.overallOccupancy.occupancyCustomers
          )
        );
    }
  };

  const updateValue = async () => {
    if (!isDemoUser && locationAndDwellAreas) {
      // TODO: re-enable this when fixing the live occupancy API
      // const isShopOpen = LocationModel.isLocationOpen(locationAndDwellAreas);
      // if (isShopOpen) {
      //   const areasQueryUrlFormat = getAreasForUrlQueryFormat(
      //     locationAndDwellAreas
      //   );
      //   if (areasQueryUrlFormat) {
      //     const res = await liveOccupancyAPI.fetchLiveOccupancyByArea(
      //       areasQueryUrlFormat
      //     );

      //     if (res) {
      //       setStatus(res.status);
      //       addLocResToState(location, res);
      //     }
      //   }
      // }
    } else if (isDemoUser) {
      const occupancy =
        demoCurrentOccupancy +
        generateIncrement(locationConfig);

      setStatus('');
      addLocResToState(location, {
        overallOccupancy: {
          occupancy,
          occupancyStaff: Math.floor(occupancy * 0.2),
          occupancyCustomers: Math.ceil(occupancy * 0.8),
        },
      });
    }
  };

  useInterval(updateValue, isDemoUser ? 2000 : UPDATE_LIVE_TIMEOUT);
  useEffect(() => {
    updateValue()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationAndDwellAreas, isDemoUser]);


  const getIntent = (value) => {
    if (value >= 1) return 'danger';
    if (value >= 0.9) return 'warning';
    return 'success';
  };

  const getProgressBarProps = ({
    location,
    excludeStaff,
  }: {
    location: locationConfigT,
    excludeStaff: boolean,
  }): ?{ value: number, intent: ?string } => {
    if (currentOccupancyMissing(location) || !location.capacity) return null;
    const value = getCurrentOccupancyPercentageValue(location, excludeStaff);
    const intent = getIntent(value);
    return { value, intent };
  };

  const occupancyError = isDemoUser ? null : getOccupancyErrors(locationConfig);

  if (occupancyError && occupancyError.fatal) {
    return (
      <LocationErrorRow
        title={locationConfig.name}
        errorText={occupancyError.message}
      />
    );
  }

  const showCapacityDetails =
    !locationConfig.areaContextsNotAvailable &&
    !locationConfig.missingData &&
    locationConfig.capacity;

  return (
    <Fragment key={locationConfig.name}>
      <TitleWrapper>
        <OccupancyTitle>{locationConfig.name}</OccupancyTitle>
        <Link to={`${redirectTo || LIVE_OCC}/${locationConfig.id}`}>
          <Button icon="fullscreen">Open in TV mode</Button>
        </Link>
      </TitleWrapper>
      <Highlights>
        <OccupancyCard>
          <Tag>Occupancy: Right now</Tag>
          <LiveFigure>
            <h3>
              {getOccupancyAsPc({ location: locationConfig, excludeStaff })}
            </h3>
            {showCapacityDetails && (
              <SmallSpan>
                {getOccupancy({ location: locationConfig, excludeStaff })}{' '}
                people present ({getCapacity(locationConfig)} maximum)
              </SmallSpan>
            )}
          </LiveFigure>
        </OccupancyCard>
        <OccupancyCard>
          <Tag>Occupancy: Right now</Tag>
          <LiveFigure>
            <ColouredH3
              color={getStatusColour(
                {
                  location: locationConfig,
                  excludeStaff,
                },
                theme
              )}
            >
              {getStatus({ location: locationConfig, excludeStaff })}
            </ColouredH3>
          </LiveFigure>
          <ProgressBarWrapper>
            {!locationConfig.missingData && (
              <ProgressBar
                {...getProgressBarProps({
                  location: locationConfig,
                  excludeStaff,
                })}
              />
            )}
          </ProgressBarWrapper>
        </OccupancyCard>
      </Highlights>
    </Fragment>
  );
};

export default LocationOccupancyRow;
