// @flow

import { useState, useEffect } from 'react';
import Layout from '../../layouts/default-logged-in';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';
import { trackEvent, CLICK, ENTER_PAGE } from '../../../services/mixpanel';
import { Panel, PanelBody, PanelHeader } from '../../panel';

const videos = [
  ['Introduction'],
  ['1: Course Introduction', 'k6VABV0IH6Y'],
  ['2: Installing Aura Vision', 'vuv-rZ-G17M'],
  ['3: Dashboard Tour', 'HstI07eLPzw'],
  ['4: Dashboard Setup', 'BciPjkfTm0s'],
  ['How to increase sales'],
  ['5: How to increase sales for Leaders', 'B41rLC1PCZI'],
  ['How to increase traffic'],
  ['6: How to increase traffic for Marketing teams', 'tYtnOrprhRw'],
  ['How to increase conversion'],
  ['7: How to increase conversion for Store Operations teams', 'BPXhcFvoyJY'],
  [
    '8: How to increase customer engagement for Store Design teams',
    'RcYN8egPNBI',
  ],
  [
    '9: How to improve demographic targeting for Marketing teams',
    'eV9i9mDkcIc',
  ],
  [
    '10: How to improve queue and transaction times for Store Operations teams',
    'kM9RuF6mR6Q',
  ],
  ['How to increase productivity'],
  ['11: How to increase productivity for Leaders', 'cEifyAZ60ok'],
  ['How to improve staff rota allocations'],
  [
    '12: How to improve staff rota allocations for Store & Area Managers',
    'drW6wRKHQiM',
  ],
  [
    '13: How to allocate staff in real-time for Store & Area Managers',
    'bytQuGfYxIU',
  ],
  [
    '14: How to speed up store compliance for Visual Merchandising teams',
    'pCWVYgLsQws',
  ],
  ['15: How to speed up reporting for all teams', 'bxgxiXdCH_g'],
  ['How to prove your impact'],
  ['16: How to prove the impact of your actions for Leaders', '4fcERASJz8c'],
];

const VideoList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 80vh;

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    max-height: 30vh;
    width: 100%;
  }
`;

const VideoListContainer = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
`;

const VideoListItem = styled.li`
  width: 100%;
`;

const VideoPage = styled(Panel)`
  width: 100%;
  max-width: var(--widget-max-width);
  margin-bottom: 1rem;
  padding: 0;
`;

const Button = styled.button`
  background-color: transparent;
  font-size: 1em;
  border: none;
  cursor: pointer;
  padding: 0.75rem 1rem;
  outline: none;
  width: 100%;
  text-align: start;
  color: #182026;
  font-family: var(--fonts-default);
  font-weight: 300;
  line-height: 1.5;

  &:hover,
  &.selected {
    background-color: rgb(239, 243, 246) !important;
  }
`;

const VideoListTitle = styled.h3`
  padding: 0 1rem;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  background-color: #000;
  position: relative;
`;

const Content = styled(PanelBody)`
  display: flex;
  padding: 0 !important;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: var(--widget-box-shadow);

  & .not-desktop {
    display: none;
  }

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    flex-direction: column;

    & .desktop {
      display: none;
    }

    & .not-desktop {
      display: block;
    }
  }
`;

const VideoTitle = styled.h2`
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    position: relative;
  }
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

const StyledPanel = styled(Panel)`
  width: 100%;
  max-width: var(--widget-max-width);
  margin: 0 auto 1rem;
  box-shadow: var(--widget-box-shadow);

  & h2 {
    color: var(--heading-text);
    font-family: var(--fonts-heading);
    margin: 0;
  }
`;

const VideoContainerElem = ({ video, className }) => (
  <VideoContainer className={className}>
    <VideoTitle>{video[0]}</VideoTitle>
    <iframe
      style={{ width: '100%', aspectRatio: '16 / 9' }}
      src={`https://www.youtube.com/embed/${video[1]}?modestbranding=1&playlist=${video[1]}&rel=0`}
      title={video[0]}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </VideoContainer>
);

const TrainingPage = () => {
  const [video: { title: string, id: string }, setVideo] = useState(videos[1]);

  // run only of first load, not re-render
  useEffect(() => {
    trackEvent(ENTER_PAGE, 'TRAINING');
  }, []);

  return (
    <Layout>
      <Centered>
        <StyledPanel>
          <PanelHeader>
            <h2>Data to Action</h2>
          </PanelHeader>
        </StyledPanel>
        <VideoPage>
          <Content>
            <VideoContainerElem className="not-desktop" video={video} />
            <VideoListContainer>
              <VideoList>
                {videos.map((v, i) => {
                  if (v.length === 1) {
                    return (
                      <li key={i} className="title">
                        <VideoListTitle>{v[0]}</VideoListTitle>
                      </li>
                    );
                  } else {
                    return (
                      <VideoListItem key={i}>
                        <Button
                          onClick={() => {
                            trackEvent(CLICK, {
                              element: 'tutorial video',
                              context: i,
                            });
                            setVideo(v);
                          }}
                          className={v[1] === video[1] ? 'selected' : ''}
                        >
                          {v[0]}
                        </Button>
                      </VideoListItem>
                    );
                  }
                })}
              </VideoList>
            </VideoListContainer>
            <VideoContainerElem className="desktop" video={video} />
          </Content>
        </VideoPage>
      </Centered>
    </Layout>
  );
};

export default TrainingPage;
