import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getFonts } from '../../../../../../styles/variables';
import { HeaderCell, StyledHeader, StyledTable } from '.';
import { LocationRow } from './location-row';
import { SortableHeader } from './sortable-header';
import _ from 'lodash';

const LocationTable = ({ data, loadingRecordingStatus }) => {
  const theme = useContext(ThemeContext);
  const { headingFont } = getFonts(theme);
  const [filteredData, setFilteredData] = useState();
  const [filterOptions, setFilterOptions] = useState({
    name: true,
  });

  function updateFilter(key, value) {
    setFilterOptions({ [key]: value });
  }

  function sortBy(key, value) {
    return _.sortBy(data, [value ? -key : key]);
  }
  function orderBy(keys, value) {
    return _.orderBy(data, keys, [value ? 'asc' : 'desc']);
  }

  const sortRows = (key, value) => {
    switch (key) {
      case 'name':
        return orderBy([key], value);
      case 'recordingsUp':
        return sortBy(key, value);
      case 'recordingPercent':
        return orderBy([key], value);
      case 'status':
        return orderBy([key, 'name'], value);
      default:
        break;
    }
  };

  useEffect(() => {
    setFilteredData(
      sortRows(
        Object.keys(filterOptions)[0],
        filterOptions[Object.keys(filterOptions)[0]],
      ),
    );
  }, [filterOptions, data]);

  return (
    <StyledTable className="bp3-html-table bp3-html-table-striped">
      <StyledHeader themeFont={headingFont}>
        <tr>
          <SortableHeader
            order={filterOptions.name}
            keyName="name"
            label="Name"
            updateFilter={updateFilter}
          />
          <HeaderCell center>Map</HeaderCell>
          <SortableHeader
            order={filterOptions.recordingsUp}
            keyName="recordingsUp"
            label="Online"
            updateFilter={updateFilter}
            center={true}
            tooltipContent="Location Status"
          />
          <SortableHeader
            order={filterOptions.recordingPercent}
            keyName="recordingPercent"
            label="Online Cameras"
            updateFilter={updateFilter}
            center={true}
            tooltipContent="Percentage of Cameras Online"
          />
          <SortableHeader
            order={filterOptions.status}
            keyName="status"
            label="Contract Status"
            updateFilter={updateFilter}
            center={true}
          />
        </tr>
      </StyledHeader>
      <tbody>
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((item, i) => (
            <LocationRow
              {...item}
              index={i}
              key={item.name}
              loadingRecordingStatus={loadingRecordingStatus}
            />
          ))
        ) : (
          <tr>
            <td colSpan={5} style={{ textAlign: 'center' }}>
              No data available
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
};

export default LocationTable;
