import styled from 'styled-components';
import moment from 'moment';
import { Dialog, Tab, Tabs } from '@blueprintjs/core';
import { breakpoints } from '../../styles/variables';
import DateSelector from './date-selector';
import { statElems } from '../../services/thumbnailer';
import { useState } from 'react';

const StyledDialog = styled(Dialog)`
  max-width: var(--widget-max-width);
  width: 60%;

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    width: 70%;
  }

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    width: 80%;
  }

  & .bp3-dialog-header {
    border-radius: var(--blueprint-dialog-border-radius)
      var(--blueprint-dialog-border-radius) 0 0;
  }

  & .bp3-button {
    border-radius: var(--button-border-radius);
  }
`;

const NoThumbnail = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LargeImage = styled.img`
  margin: 1rem 1rem 0;
  width: calc(100% - 2rem);
`;

const StyledTabs = styled(Tabs)`
  padding: 1rem;
`;

const Spacer = styled.div`
  height: calc(40px + 2rem);
`;

const Popup = ({
  dates,
  selectedRecording,
  setSelectedRecording,
  popupSelectedDate,
  setPopupSelectedDate,
}) => {
  const [statElemImage, setStatElemImage] = useState('');
  const [currentTab, setCurrentTab] = useState('preview');

  const getSelectedThumbnailURL = (selectedRecording, popupSelectedDate) => {
    if (
      !selectedRecording ||
      typeof selectedRecording !== 'object' ||
      !selectedRecording.thumbnails ||
      !selectedRecording.timezone
    ) {
      return null;
    }

    const selectedThumbnail = selectedRecording.thumbnails.find(
      (thumb) =>
        moment(thumb.local_time).format('L') ===
        popupSelectedDate
    );
    if (selectedThumbnail && selectedThumbnail.url) {
      return selectedThumbnail.url;
    }

    return null;
  };

  const fetchStatElems = async () => {
    if (statElemImage === '') {
      const imageBlob = await statElems.getImage(selectedRecording.id);
      const imageURL = URL.createObjectURL(imageBlob);
      setStatElemImage(imageURL);
    }
  };

  return (
    <StyledDialog
      isOpen={selectedRecording}
      onClose={() => {
        setSelectedRecording(null);
        setCurrentTab('preview');
        setStatElemImage('');
      }}
      canOutsideClickClose={true}
      title={selectedRecording ? selectedRecording.name : ''}
    >
      <StyledTabs
        selectedTabId={currentTab}
        onChange={(newTab) => {
          if (newTab === 'statelems') {
            fetchStatElems();
          }
          setCurrentTab(newTab);
        }}
      >
        <Tab
          title="Preview"
          id="preview"
          panel={
            <>
              {(() => {
                const url = getSelectedThumbnailURL(
                  selectedRecording,
                  popupSelectedDate
                );
                if (url) {
                  return (
                    <LargeImage
                      loading="lazy"
                      src={getSelectedThumbnailURL(
                        selectedRecording,
                        popupSelectedDate
                      )}
                    />
                  );
                }

                return (
                  <NoThumbnail>
                    <span>Image Unavailable</span>
                  </NoThumbnail>
                );
              })()}
              {dates.length > 1 && (
                <>
                  <p></p>
                  <DateSelector
                    dates={dates}
                    selectedDate={popupSelectedDate}
                    setSelectedDate={setPopupSelectedDate}
                  />
                </>
              )}
            </>
          }
        />
        <Tab
          title="Lines and Areas (beta)"
          id="statelems"
          panel={
            <>
              {statElemImage !== '' ? (
                <LargeImage loading="lazy" src={statElemImage} />
              ) : (
                <NoThumbnail>
                  <span>Image Unavailable</span>
                </NoThumbnail>
              )}
              <Spacer></Spacer>
            </>
          }
        />
      </StyledTabs>
    </StyledDialog>
  );
};

export default Popup;
