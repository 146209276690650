// @flow

import styled from 'styled-components';
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { getDateRangeLabelFormat } from '../../utils/dates';
import { fixTitleModifiers } from '../../utils/datesPresetsConfigHelpers';
import { comparisonPeriodPresetConfig, DIVIDER } from './dates-presets-config';
import * as PREVIOUS_PERIOD_PRESETS from '../../constants/previous-period-presets';

import * as PeriodModel from '../../models/period';
import type { compPeriodAllowedT } from './dates-presets-config';

const StyledMenuItem = styled(MenuItem)`
  .bp3-text-overflow-ellipsis {
    margin-right: 2rem;
  }
`;

type Props = {
  period: PeriodModel.t,
  comparisonDatePresetsAllowed: compPeriodAllowedT,
  currentSelectedComparisonDatePreset: PeriodModel.compareToPastPresetsT,
  handleComparisonPresetClick(period: PeriodModel.comparePeriodT): void,
  handleOpenDatePickerWithConfirm(): void
};

const ComparisonDatePickerMenu = ({
  period,
  comparisonDatePresetsAllowed,
  currentSelectedComparisonDatePreset,
  handleComparisonPresetClick,
  handleOpenDatePickerWithConfirm
}: Props) => {
  return (
    <Menu>
      {comparisonDatePresetsAllowed.map((preset, index) => {
        if (preset === DIVIDER)
          return <MenuDivider key={`${index}-${preset}`} />;

        // $FlowFixMe -> Flow doesnt check the content of DIVIDER
        const presetConfig = comparisonPeriodPresetConfig[preset];
        const comparePeriod = PeriodModel.getExplorerComparePeriod(
          // $FlowFixMe -> Flow dones't check the content of DIVIDER, and thinks that this could be at any point a DIVIDER instead of an compareToPastPresetsT
          preset,
          period.selectedDates
        );

        let rangeDateLabel =
          comparePeriod &&
          comparePeriod.selectedDates &&
          preset !== PREVIOUS_PERIOD_PRESETS.none
            ? getDateRangeLabelFormat(comparePeriod.selectedDates, presetConfig)
            : '';

        if (
          preset === PREVIOUS_PERIOD_PRESETS.custom &&
          currentSelectedComparisonDatePreset !== PREVIOUS_PERIOD_PRESETS.custom
        ) {
          rangeDateLabel = '';
        }

        const handleMenuItemClick = () => {
          if (preset === PREVIOUS_PERIOD_PRESETS.custom) {
            handleOpenDatePickerWithConfirm();
          } else {
            handleComparisonPresetClick(comparePeriod);
          }
        };

        return (
          <StyledMenuItem
            key={`${index}-${preset}`}
            icon={
              preset === currentSelectedComparisonDatePreset ? 'tick' : 'blank'
            }
            text={fixTitleModifiers(presetConfig.title, comparePeriod)}
            label={rangeDateLabel}
            onClick={handleMenuItemClick}
          />
        );
      })}
    </Menu>
  );
};

export default ComparisonDatePickerMenu;
