import { db } from '../models/database';

export const setTheme = async (theme) => {
  try {
    if (!theme && db.theme) {
      await db.theme.put({ name: 'current', theme: { ...defaultTheme } });
    } else {
      await db.theme.put({ name: 'current', theme: { ...theme } });
    }
  } catch (err) {
    console.log(`Failed to set theme in local db: ${err}`);
  }
};

export const getTheme = async () => {
  try {
    if (db.theme) {
      let stored = null;
      stored = await db.theme.get({ name: 'current' });
      if (stored) {
        return stored.theme;
      }
    }
  } catch (err) {
    console.log(`Failed to get theme from local db: ${err}`);
  }
  return defaultTheme;
};

export const defaultTheme = {
  name: 'default',
  cobranded: false,
  overrides: null,
  chartColors: [
    '#056e12',
    '#830c6f',
    '#90a65d',
    '#7220f6',
    '#e78607',
    '#0b0f25',
    '#fe707d',
    '#088490',
    '#962b35',
    '#6e9df7',
    '#b765b8',
    '#5a3100',
    '#ef45e1',
    '#012d03',
    '#f34207',
    '#324ae4',
    '#ae896e',
    '#ff0087',
    '#20b465',
    '#3a427d',
  ],
  properties: {
    '--fonts-default': '"Open Sans", sans-serif',
    '--fonts-heading': '"Open Sans", sans-serif',
    '--fonts-widget-body': '"Open Sans", sans-serif',

    '--white': '#ffffff',
    '--dark-blue': '#19212F',
    '--light-blue': '#EFF3F6',
    '--light-grey': '#F8FAFB',
    '--mid-grey': '#818FA2',
    '--blue': '#2DA0F7',
    '--super-light-blue': '#E4F1FE',
    '--super-light-grey': '#F2F3F5',
    '--medium-gray': '#f5f8fa',
    '--gray': '#9299A7',
    '--gray-undefined': '#d0d0d0',
    '--dark-purple': 'rgba(47, 83, 163, 1)',
    '--light-yellow': 'rgba(246, 212, 98, 1)',
    '--light-gray': 'rgba(220, 220, 220, 1)',

    '--positive': 'rgb(18, 196, 87)',
    '--negative': 'rgb(232, 76, 86)',
    '--neutral': 'rgb(247, 222, 79)',

    '--positive-hover': '#0e9a44',
    '--negative-hover': '#cb1a26',

    '--ok': 'var(--positive)',
    '--ok-hover': 'var(--positive-hover)',
    '--warning': '#fc9c2d',
    '--error': 'var(--negative)',
    '--error-hover': 'var(--negative-hover)',

    '--primary': '#137cbd',
    '--primary-hover': '#106ba3',

    '--gender-male': '#479DEE',
    '--gender-female': '#e0a0db',
    '--gender-undefined': '#d0d0d0',

    '--menu-active': '#333D4D',

    '--icon-highlight': '#137cbd',

    '--selected-text': '#106ba3',
    '--heading-text': 'rgba(100, 112, 159, 1)',

    '--widget-divider': '1px solid rgba(0, 0, 0, 0.1)',
    '--widget-shadow': 'none',
    '--widget-border': 'none',
    '--widget-border-radius': '12px',
    '--widget-heading-text': 'rgba(100, 112, 159, 1)',
    '--widget-background': 'rgba(251, 251, 254, 1)',
    '--widget-max-width': 'min(1750px, 100%)',
    '--widget-section-divider': '1px solid #64709f54',
    '--widget-section-background': 'none',
    '--widget-section-padding': '0 0 2em 0',
    '--widget-box-shadow': 'none',

    '--heatmap-highlight': 'rgb(47, 83, 163, 1)',

    '--overlay-body-background': 'rgba(251, 251, 254, 1)',
    '--main-background': 'rgb(239, 243, 246)',
    '--sidebar-background': '#19212F',

    '--mobile-header-background': '#19212F',
    '--mobile-header-text': '#FFF',

    '--sidebar-divider': '#2c3545',
    '--sidebar-text': 'rgba(255, 255, 255, 0.8)',
    '--sidebar-highlighted-text': '#fff',
    '--sidebar-hover-text': '#fff',
    '--sidebar-active-text': '#fff',

    '--filter-bar-background': 'rgb(241, 245, 248)',

    '--panel-border': '1px solid #e6eaee',
    '--panel-background': '#fafbfc',
    '--panel-header-background': '#fff',
    '--panel-footer-background': '#fff',

    '--button-background': '#f1f1f1',
    '--button-hover-background': '#e0e0e0',
    '--button-active-background': '#d8e1e8',
    '--button-inactive-background': '#c0c0c0',
    '--button-text': '#182026',
    '--button-text-primary': '#fff',
    '--button-text-success': '#fff',
    '--button-text-error': '#fff',
    '--button-inactive-text': '#182026',
    '--button-icon-color': '#5c7080',
    '--button-border-radius': '8px',
    '--button-border': '1px solid rgba(0, 0, 0, 0.05)',
    '--button-hover-border': 'i1px solid rgba(0, 0, 0, 0.1)',

    '--blueprint-card-border':
      '0 0 0 1px rgb(16 22 26 / 15%), 0 0 0 rgb(16 22 26 / 0%), 0 0 0 rgb(16 22 26 / 0%)',
    '--blueprint-dialog-border-radius': '12px',
    '--blueprint-dialog-background': '#ebf1f5',

    '--report-heading-text': 'var(--heading-text)',

    '--chart-widget-line': 'var(--light-yellow)',
    '--chart-widget-bar': 'var(--dark-purple)',
  },
};
