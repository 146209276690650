const ONE_MILLION = 1000000;
const ONE_HUNDRED_THOUSAND = 100000;
const TEN_THOUSAND = 10000;
const ONE_THOUSAND = 1000;

const ONE_MINUTE = 60;
const ONE_HOUR = 3600;
const ONE_DAY = 86400;
const ONE_WEEK = ONE_DAY * 7;

const prettyLongNumber = (number, fix = 2) => {
  if (typeof number !== "number") return "-";

  if (number >= ONE_MILLION) {
    return `${(Math.round(number) / ONE_MILLION).toFixed(2)}M`;
  }
  if (number >= ONE_HUNDRED_THOUSAND) {
    return `${(Math.round(number) / ONE_THOUSAND).toFixed(0)}K`;
  }
  if (number >= TEN_THOUSAND) {
    return `${(Math.round(number) / ONE_THOUSAND).toFixed(1)}K`;
  }
  if (number >= ONE_THOUSAND) {
    return `${(Math.round(number) / ONE_THOUSAND).toFixed(2)}K`;
  }

  if (Number.isInteger(number)) return number.toString();

  return number.toLocaleString(undefined, {
    minimumFractionDigits: fix,
    maximumFractionDigits: fix
  });
};

const prettyTime = secs => {
  if (typeof secs !== "number") return "-";
  if (secs === 0) return "0";
  const result = [];
  let weeks = 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = secs;

  if (seconds >= ONE_WEEK) {
    weeks = Math.floor(seconds / ONE_WEEK);
    seconds -= weeks * ONE_WEEK;
  }
  if (seconds >= ONE_DAY) {
    days = Math.floor(seconds / ONE_DAY);
    seconds -= days * ONE_DAY;
  }
  if (seconds >= ONE_HOUR) {
    hours = Math.floor(seconds / ONE_HOUR);
    seconds -= hours * ONE_HOUR;
  }
  if (seconds >= ONE_MINUTE) {
    minutes = Math.floor(seconds / ONE_MINUTE);
    seconds -= minutes * ONE_MINUTE;
  }

  seconds = minutes
    ? Math.round(seconds)
    : Number.isInteger(seconds)
    ? seconds
    : seconds.toFixed(1);

  if (weeks) result.push(`${weeks}w`);
  if (days) result.push(`${days}d`);
  // only add hours if the total time is <1 week
  if (!!hours && !weeks) result.push(`${hours}h`);
  // only add minutes if the total time is <1 day
  if (!!minutes && !weeks && !days) result.push(`${minutes}m`);
  // only add seconds if the total time is <1 hour
  if (!!seconds && !weeks && !days && !hours) result.push(`${seconds}s`);

  return result.join(" ");
};

const prettyNumber = number =>
  number && typeof number.toLocaleString === "function"
    ? number.toLocaleString()
    : "0";

const prettyPercentage = (number, fix = 2, emptyString) => {
  if (typeof number !== "number") return emptyString || "";
  if (isNaN(number)) return emptyString || "";
  if (Number.isInteger(number)) return `${number}%`

  const absNum = Math.abs(number);
  if (absNum < 10) return `${number.toFixed(2)}%`;
  if (absNum > 10 && absNum < 100) return `${number.toFixed(1)}%`;
  if (absNum >= 100) return `${number.toFixed(0)}%`;
};

const getCurrencySymbol = currency =>
  (0)
    .toLocaleString("en", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, "")
    .trim();

const prettyCurrency = (number, currencyCode, fix = 2) => {
  if (typeof number !== "number" || isNaN(number)) return "-";

  const prettyNumber = prettyLongNumber(number, fix);

  if (currencyCode === "unset") {
    return `${prettyNumber} (unknown currency)`;
  }
  if (currencyCode === "mixed") {
    return `${prettyNumber} (mixed currency)`;
  }

  try {
    const symbol = getCurrencySymbol(currencyCode);
    return `${symbol}${prettyNumber}`;
  } catch (e) {
    //invalid currency code - just return it as is
    return `${currencyCode} ${prettyNumber}`;
  }
};

const prettyRate = (number) => {
  const fix = number < 10 ? 2 : 1
  const baseNumber = prettyLongNumber(number, fix)
  return `${baseNumber}X`;
}

module.exports = {
  prettyLongNumber,
  prettyTime,
  prettyNumber,
  prettyPercentage,
  prettyCurrency,
  prettyRate
};
