import { CenteredCell, StyledRow } from '.';
import {
  getLabelForDate,
  getLabelForTime,
} from '../../../../../../utils/dates';
import { firstLetterUppercase } from '../../../../../../utils/firstLetterUppercase';
import { BinaryUptimeIndicator } from './binary-uptime-indicator';
import { ImageCell } from './image-cell';
import moment from 'moment';

function isDateValid(date) {
  return !isNaN(new Date(date));
}
const DateCell = ({ lastSeenUtc, lastSeenLocalTime, timezone }) => {
  if (!isDateValid(lastSeenUtc) && !isDateValid(lastSeenLocalTime)) {
    return <CenteredCell>Camera Not Set-up</CenteredCell>;
  }

  const timeSinceSeen = moment.utc(lastSeenUtc).fromNow();

  const displayTime = `${getLabelForTime(
    lastSeenLocalTime,
  )} ${getLabelForDate(lastSeenLocalTime)}  (${timezone})`;
  return (
    <CenteredCell>
      {firstLetterUppercase(timeSinceSeen)}
      <br />
      <br />
      {displayTime}
    </CenteredCell>
  );
};

export const RecordingRow = ({
  name,
  status,
  preview,
  lastSeenUtc,
  lastSeenLocalTime,
  onClick,
  timezone,
}) => {
  return (
    <StyledRow onClick={onClick} hover>
      <td>{name}</td>
      {preview ? (
        <ImageCell altText={`${name} Camera Preview`} src={preview} />
      ) : (
        <td>
          <span
            style={{
              display: 'block',
              height: '150px',
              width: '250px',
              background: 'transparent',
            }}
          ></span>
        </td>
      )}
      <CenteredCell>
        <BinaryUptimeIndicator status={status === 'online'} />
      </CenteredCell>
      <DateCell
        lastSeenLocalTime={lastSeenLocalTime}
        lastSeenUtc={lastSeenUtc}
        timezone={timezone}
      />
    </StyledRow>
  );
};
