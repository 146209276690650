// @flow

import { useState, useEffect } from 'react';
import { map } from 'awaity';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import WidgetUI from '../../widgets-ui/row-heatmap';
import { getQueryPayloadType, buildPayload } from '../../../services/query-api';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { AGGREGATION_PERIOD_LABELS_SENTENCE } from '../../../constants/graph-options';
import {
  getDatesListInRangePeriod,
  getFormatDateByAggregation,
} from '../../../utils/dates';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { axisTimeFormatter } from '../../../utils/graphHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';
import * as PERIOD_PRESETS from '../../../constants/period-presets';

const DateHeatmapWidget = ({
  widget,
  availableMetrics,
  uiOptions = {},
}: WidgetPropsModel.dateHeatmapT) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible(
    (vi) => uiOptions.disableViewportLoading || vi > 0
  );

  const usingComps =
    !!widget.comparisonPeriod &&
    widget.comparisonPeriod.active &&
    widget.showChangeValue;

  const loadWidgetData = async (metricsDetails) => {
    setError(false);
    setLoading(true);
    setProgress(0);

    const totalQueries = usingComps
      ? metricsDetails.length * 2
      : metricsDetails.length;
    let completeQueries = 0;

    let displayStartOfWeek = uiOptions.startOfWeek;

    if (widget.period.selectedPreset === PERIOD_PRESETS.lastFullUSWeek || widget.period.selectedPreset === PERIOD_PRESETS.last4FullUSWeeks) {
      displayStartOfWeek = 'sunday';
    } else if (widget.period.selectedPreset === PERIOD_PRESETS.lastFullWeek || widget.period.selectedPreset === PERIOD_PRESETS.last4FullWeeks) {
      displayStartOfWeek = 'monday';
    }

    const indexes = getDatesListInRangePeriod(
      widget.period.selectedDates,
      widget.aggregation,
      true,
      displayStartOfWeek,
    );

    let compIndexes;
    if (usingComps) {
      compIndexes = getDatesListInRangePeriod(
        widget.comparisonPeriod.selectedDates,
        widget.aggregation,
        true,
        displayStartOfWeek,
      );
    }
    const indexesForTableColumns = indexes.map((index) => ({
      label:
        axisTimeFormatter(
          parseInt(index),
          widget.aggregation,
          undefined,
          true
        ) || index,
    }));

    setTableColumns(indexesForTableColumns);

    const builtRows = await map(metricsDetails, async (metric) => {
      const row = {
        label: getColumnLabel(metric, availableMetrics, true, true),
        description:
          !uiOptions.disableDescription &&
          AvailableMetricsModel.getMetricDescription(metric.details),
        cols: Array(indexes.length)
          .fill(null)
          .map(
            (): {
              value: number | null,
              changeValue: number | null,
              unitType: string,
            } => ({ value: null, changeValue: null, unitType: '' })
          ),
      };

      // build the request body and fetch data for each metric for each period
      const path = apiEndpointByMetricKey(metric.metricKey);
      const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
        metric.details
      );

      const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

      let taxonomies;
      if (metric.taxonomy) {
        taxonomies = [metric.taxonomy];
      } else if (metric.filter) {
        taxonomies = [`${metric.filter.key}:${metric.filter.value}`];
      }

      const payloadInput = {
        locations: widget.locations,
        period: widget.period,
        demographicFilter: widget.demographicFilter,
        taxonomies,
        aggregation: widget.aggregation,
        metricKey: metric.metricKey,
      };

      const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

      try {
        const response = await fetchSQLQuery(payloadInitialPeriod, path, {
          returnErrors: true,
          return404AsError: false,
          metricKey: metric.metricKey,
        });
        completeQueries++;
        setProgress(completeQueries / totalQueries);

        let compResponse;
        if (usingComps) {
          const payloadCompPeriod = buildPayload(queryPayloadType, {
            ...payloadInput,
            period: widget.comparisonPeriod,
          });

          compResponse = await fetchSQLQuery(payloadCompPeriod, path, {
            returnErrors: true,
            return404AsError: false,
            metricKey: metric.metricKey,
          });
          completeQueries++;
          setProgress(completeQueries / totalQueries);
        }
        const unitType = QueryResponseModel.getUnitType(
          response,
          metric.metricKey
        );

        const data = response.segments;

        if (data) {
          data.forEach((segment) => {
            const colIndex = indexes.findIndex(
              (i) =>
                i ===
                JSON.stringify(segment.index) ||
                  i === getFormatDateByAggregation(segment.index, widget.aggregation)
            );

            if (colIndex > -1) {
              const value = QueryResponseModel.getSegmentOverallValue(
                segment,
                metric.metricKey
              );
              row.cols[colIndex].value = value;
            } else {
              // this should not happen because the cols array is pre-populated above
              console.error(
                `Received segment with an unexpected index: ${colIndex}: ${JSON.stringify(
                  segment
                )}}`
              );
            }
          });
        }

        if (compResponse && compResponse.segments) {
          compResponse.segments.forEach((segment) => {
            // find the comparable comp segment
            const colIndex = compIndexes.findIndex((i) => i ===
            JSON.stringify(segment.index) ||
              i === getFormatDateByAggregation(segment.index, widget.aggregation));

            if (colIndex > -1) {
              const compValue = QueryResponseModel.getSegmentOverallValue(
                segment,
                metric.metricKey
              );
              const { value } = row.cols[colIndex];
              const { result } = prettyValueToCompare(
                compValue,
                value || -Infinity
              );
              row.cols[colIndex].changeValue = result;
            } else {
              // this should not happen because the cols array is pre-populated above
              console.error(
                `Received segment with an unexpected index: ${colIndex}: ${JSON.stringify(
                  segment
                )}}`
              );
            }
          });
        }

        row.cols.forEach((col) => (col.unitType = unitType));

        return {
          ...row,
        };
      } catch (e) {
        // got an error with the API call or the transform
        console.error(e);
        return null;
      }
    });

    const trimmedBuiltRows = builtRows.filter((r) => !!r);

    if (!trimmedBuiltRows || !trimmedBuiltRows.length) {
      setError(true);
    } else {
      setTableRows(trimmedBuiltRows);
    }

    setLoading(false);
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      // get the information from available metrics data for each metric
      const metricsDetails = widget.metrics
        .map((m) => ({
          ...m,
          details: AvailableMetricsModel.findMetricDetails(
            availableMetrics,
            m.metricGroupKey,
            m.metricKey
          ),
        }))
        .filter((m) => !!m && !!m.details);

      const aggLabel = AGGREGATION_PERIOD_LABELS_SENTENCE[widget.aggregation.toLowerCase()];
      setAutoTitle(`KPIs, by ${aggLabel || widget.aggregation}`);

      if (!metricsDetails || !metricsDetails.length) {
        console.error(
          'No metrics found in availableMetrics for the Date Heatmap widget'
        );
        setError(true);
      } else {
        loadWidgetData(metricsDetails);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget, true);

  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        progress={progress}
        title={title}
        subtitle={subtitle}
        tableColumns={tableColumns}
        tableRows={tableRows}
        dataKey={widget.showChangeValue ? 'changeValue' : 'value'}
      />
    </div>
  );
};

export default DateHeatmapWidget;
