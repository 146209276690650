// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import { Menu, MenuDivider, Popover, Button, Icon } from '@blueprintjs/core';
import type { subMenuConfigurationT } from '../metrics-menu/sub-menu';

import * as ActiveMetricsModel from '../../models/active-metrics';
import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';

const sortByLabel = (a, b) => {
  const numberA = Number(a.label);
  const numberB = Number(b.label);

  if (!isNaN(numberA) && !isNaN(numberB)) {
    return numberA > numberB ? 1 : -1;
  }

  if (a.label > b.label) {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }

  return 0;
};
const BASE_LEFT_PADDING = 0.5;
const IconActiveTaxonomy = styled(Icon)`
  &&& {
    margin-left: auto;
    max-height: 13px;
    color: rgba(0, 0, 0, 0.5);
  }
`;
export const MenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  padding-left: ${({ space }) =>
    space < 1 ? space : 1 * space + BASE_LEFT_PADDING}em;
  padding-right: ${BASE_LEFT_PADDING}em;
  &:hover {
    cursor: pointer;
    background-color: rgba(167, 182, 194, 0.3);
  }
`;
const MenuStyled = styled(Menu)`
  max-height: 80vh;
  overflow-y: auto;
`;

type Props = {
  availableTaxonomyList: ActiveMetricsModel.taxonomyConfgObjtT[],
  editActiveMetrics(config: editSummaryPageActiveMetricsArgsT): void,
  subMenuKey: string,
  subMenuConfiguration: subMenuConfigurationT,
  activeMetrics: ActiveMetricsModel.t
};

const isTaxonomyActive = (
  activeMetrics: ActiveMetricsModel.t,
  subMenuKey: string,
  taxonomy: string
): boolean => {
  const result =
    activeMetrics[subMenuKey] &&
    activeMetrics[subMenuKey].taxonomies &&
    !!activeMetrics[subMenuKey].taxonomies.includes(taxonomy);
  return (result || false);
};

const displayTaxonomies = (
  childTaxonomies: ActiveMetricsModel.taxonomyConfgObjtT[],
  subMenuKey: string,
  activeMetrics: ActiveMetricsModel.t,
  editActiveMetrics: (config: editSummaryPageActiveMetricsArgsT) => void,
  index: number,
  taxonomy: string,
  numLevelsDeep: number
) => {
  return (
    childTaxonomies &&
    childTaxonomies.length > 0 &&
    childTaxonomies.sort(sortByLabel).map((childTaxonomy, idx) => {
      const isActive = isTaxonomyActive(
        activeMetrics,
        subMenuKey,
        childTaxonomy.taxonomy
      );
      const key = `${taxonomy}-${index}-${childTaxonomy.taxonomy}-${idx}`;
      const headerConfig = {
        subMenuKey,
        taxonomy: childTaxonomy.taxonomy
      };

      return (
        <Fragment key={key}>
          <MenuItemStyled
            space={numLevelsDeep}
            onClick={() => editActiveMetrics(headerConfig)}
          >
            {childTaxonomy.label}
            {isActive && <IconActiveTaxonomy icon="tick" />}
          </MenuItemStyled>
          {childTaxonomy.children.length > 0 &&
            displayTaxonomies(
              childTaxonomy.children,
              subMenuKey,
              activeMetrics,
              editActiveMetrics,
              idx,
              taxonomy,
              numLevelsDeep + 1
            )}
        </Fragment>
      );
    })
  );
};

const TaxonomyMenu = ({
  availableTaxonomyList,
  editActiveMetrics,
  subMenuKey,
  subMenuConfiguration,
  activeMetrics
}: Props) => {
  const showMenuDivider = index =>
    subMenuConfiguration.taxonomies &&
    subMenuConfiguration.taxonomies.length > 2 &&
    index !== availableTaxonomyList.length - 1;
  const { taxonomySelectLabel } = subMenuConfiguration;

  return (
    <Popover usePortal={false}>
      <Button icon="exchange">
        {taxonomySelectLabel || 'Select zone types'}
      </Button>
      <MenuStyled>
        {availableTaxonomyList
          .sort(sortByLabel)
          .map(({ taxonomy, label, children }, index) => {
            const headerConfig = {
              subMenuKey,
              taxonomy
            };

            return (
              <Fragment key={taxonomy}>
                <MenuItemStyled
                  space={BASE_LEFT_PADDING}
                  onClick={() => editActiveMetrics(headerConfig)}
                >
                  {label}
                  {isTaxonomyActive(activeMetrics, subMenuKey, taxonomy) && (
                    <IconActiveTaxonomy icon="tick" />
                  )}
                </MenuItemStyled>
                {displayTaxonomies(
                  children,
                  subMenuKey,
                  activeMetrics,
                  editActiveMetrics,
                  index,
                  taxonomy,
                  1
                )}
                {showMenuDivider(index) && <MenuDivider />}
              </Fragment>
            );
          })}
      </MenuStyled>
    </Popover>
  );
};

export default TaxonomyMenu;
