// @flow

import { useState, useEffect } from 'react';
import { Dialog, Tabs, Tab } from '@blueprintjs/core';
import styled from 'styled-components';
import { scheduledReportJobs } from '../../services/api';
import ActiveJobsTab from './active-jobs-tab/index.js';
import NewJobTab from './new-job-tab/index.js';
import EditJobTab from './edit-job-tab/index.js';
import * as ReportModel from '../../models/report';
import { errorToast } from '../../utils/toaster';
import { breakpoints } from '../../styles/variables';

const StyledDialog = styled(Dialog)`
  width: 800px;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: 100%;
  }

  .bp3-dialog-header {
    border-radius: var(--blueprint-dialog-border-radius)
      var(--blueprint-dialog-border-radius) 0 0;
  }

  .bp3-button {
    border-radius: var(--button-border-radius);
  }
`;

const DialogBody = styled.div`
  flex: 1 1 auto;
  margin: 30px 30px 10px 30px;
  line-height: 18px;
  background: var(--blueprint-dialog-background);
  border-radius: var(--blueprint-dialog-border-radius);

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    margin: 20px 20px 0 20px;
  }
`;
const StyledTabs = styled(Tabs)`
  .tab-remove-margin {
    margin-top: 0;
  }
`;

type Props = {
  reports: ReportModel.t[],
  isOpen: boolean,
  onClose: () => void,
};

export const TAB_ACTIVE_JOBS = 'active';
export const TAB_NEW_JOB = 'create';
export const TAB_EDIT_JOB = 'edit';

const ScheduledReportJobExplorer = ({ reports, isOpen, onClose }: Props) => {
  const [jobs, setJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [currentTab, setCurrentTab] = useState(TAB_ACTIVE_JOBS);
  const [selectedJob, setSelectedJob] = useState(null);

  const deleteJob = async (jobId) => {
    setLoadingJobs(true);
    // do the delete
    try {
      await scheduledReportJobs.delete(jobId);
      errorToast({ message: 'Email report removed', intent: 'success' });
    } catch (e) {
      errorToast({ message: 'Sorry, the report could not be deleted' });
      // catch an error and show a toast
    }
    await loadJobs();
  };

  const editJob = (job) => {
    setSelectedJob(job);
    setCurrentTab(TAB_EDIT_JOB);
  };

  const loadJobs = async () => {
    try {
      setLoadingJobs(true);
      const response = await scheduledReportJobs.list();
      setJobs(response);
    } catch (e) {
      console.log('Error getting scheduled report jobs');
    }
    setLoadingJobs(false);
  };

  useEffect(() => {
    if (isOpen && currentTab === TAB_ACTIVE_JOBS) {
      loadJobs();
    }
  }, [isOpen, currentTab]);

  // only support dashboardReportToImage jobs for now
  const jobsToShow = jobs.filter(
    (job) => job.jobType === 'dashboardReportToImage'
  );

  return (
    <StyledDialog
      icon="notifications"
      title="Email reports schedule"
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogBody>
        <StyledTabs onChange={setCurrentTab} selectedTabId={currentTab}>
          <Tab
            id={TAB_ACTIVE_JOBS}
            panelClassName="tab-remove-margin"
            panel={
              <ActiveJobsTab
                loading={loadingJobs}
                jobs={jobsToShow}
                reports={reports}
                setCurrentTab={setCurrentTab}
                deleteJob={deleteJob}
                editJob={editJob}
              />
            }
          />
          <Tab
            id={TAB_NEW_JOB}
            panelClassName="tab-remove-margin"
            panel={
              <NewJobTab reports={reports} setCurrentTab={setCurrentTab} />
            }
          />
          <Tab
            id={TAB_EDIT_JOB}
            panelClassName="tab-remove-margin"
            panel={
              <EditJobTab
                reports={reports}
                setCurrentTab={setCurrentTab}
                selectedJob={selectedJob}
                loadJobs={loadJobs}
              />
            }
          />
        </StyledTabs>
      </DialogBody>
    </StyledDialog>
  );
};

export default ScheduledReportJobExplorer;
