// @flow

import styled from 'styled-components';

const ButtonIconColorer = styled.div`
  .bp3-icon:not([data-icon='caret-down']) {
    color: var(--icon-highlight);
  }
`;

export default ButtonIconColorer;
