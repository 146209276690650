// @flow

import * as AvailableMetricsModel from '../models/available-metrics';
import * as QueryApiRequestModel from '../models/query-api-request';
import * as PeriodModel from '../models/period';
import type { demographicFilterT } from '../models/page-settings';

import { apiEndpoints } from '../constants/api-endpoints';
import type { aggregationEnum } from '../constants/graph-options';
import { AGGREGATION_PERIOD } from '../constants/graph-options';

import * as Selectors from '../selectors';
import { LOCATION_DWELL } from '../constants/area-contexts';
import { ROLES } from '../constants/demographic-types';

export const QUERY_API_BODY_PAYLOAD_TYPES = {
  SALES: 'sales',
  SALES_CONVERSION_RATE: 'salesConversionRate',
  AREAS: 'areas',
  LOCATION_AREAS: 'locationAreas',
  CUSTOM_MOVEMENT_WITHIN_LOCATION: 'customMovementWithinLocation',
  MOVEMENT_WITHIN_LOCATION: 'movementWithinLocation',
  MOVEMENT: 'movement',
};

export const CSV_GEN_PAYLOAD_TYPES = {
  SALES: 'sales',
  SALES_CONVERSION_RATE: 'salesConversionRate',
  AREAS: 'zones',
  LOCATION_AREAS: 'locationAreas',
  CUSTOM_MOVEMENT_WITHIN_LOCATION: 'customMovementWithinLocation',
  MOVEMENT_WITHIN_LOCATION: 'movementWithinLocation',
  MOVEMENT: 'movement',
  SERVICE_AREAS: 'serviceAreas',
};

export const getQueryPayloadType = (
  path: string,
  endpointDetails: AvailableMetricsModel.endpointT,
): string => {
  switch (path) {
    case apiEndpoints.SALES_TRANSACTION_COUNT:
    case apiEndpoints.SALES_VOLUME:
    case apiEndpoints.SALES_ATV:
    case apiEndpoints.SALES_UNITS:
    case apiEndpoints.SALES_UPT: {
      return QUERY_API_BODY_PAYLOAD_TYPES.SALES;
    }
    case apiEndpoints.SALES_CONVERSION_RATE: {
      return QUERY_API_BODY_PAYLOAD_TYPES.SALES_CONVERSION_RATE;
    }
    case apiEndpoints.AREA_AVERAGE_OCCUPANCY:
    case apiEndpoints.AREA_MAX_OCCUPANCY:
    case apiEndpoints.AREA_UTILISATION:
    case apiEndpoints.AREA_TOTAL_DWELL_TIME:
    case apiEndpoints.AREA_AVERAGE_DWELL_TIME:
    case apiEndpoints.AREA_ENTRIES:
    case apiEndpoints.AREA_ENTRIES_PER_LOCATION_ENTRANT:
    case apiEndpoints.AREA_DWELL_PER_LOCATION_ENTRANT: {
      return QUERY_API_BODY_PAYLOAD_TYPES.AREAS;
    }
    case apiEndpoints.AVERAGE_DWELL_TIME:
    case apiEndpoints.AVERAGE_OCCUPANCY: {
      return QUERY_API_BODY_PAYLOAD_TYPES.LOCATION_AREAS;
    }
    case apiEndpoints.INTERNAL_LINE_CROSSINGS: {
      return QUERY_API_BODY_PAYLOAD_TYPES.CUSTOM_MOVEMENT_WITHIN_LOCATION;
    }
    case apiEndpoints.INTERNAL_ENTRIES:
    case apiEndpoints.INTERNAL_CAPTURE_RATE:
    case apiEndpoints.INTERNAL_PASSERS_BY: {
      if (endpointDetails.sendNoFilters)
        return QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT;
      return QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT_WITHIN_LOCATION;
    }
    case apiEndpoints.ENTRIES:
    case apiEndpoints.PASSERS_BY:
    case apiEndpoints.CAPTURE_RATE: {
      return QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT;
    }
    default: {
      return QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT;
    }
  }
};

type buildPayloadInputPropsT = {
  locations: string[],
  period: PeriodModel.t | PeriodModel.activeComparePeriodT,
  demographicFilter: demographicFilterT,
  aggregation: aggregationEnum,
  taxonomies?: string[],
  metricKey: string,
  breakdownByDimensions?: string[],
  ages?: string[],
  genders?: string[],
  roles?: string[],
  facets?: string[],
};

const checkMinAggregationType = (aggregation, payloadType) => {
  const { QUARTER_HOUR, HOUR } = AGGREGATION_PERIOD;
  if ([QUARTER_HOUR, HOUR].includes(aggregation)) {
    // TODO: send some feedback to whatever function is trying to build the payload instead?
    console.error(
      `Payload for Query API will fail because for payloadType: ${payloadType}, an aggregation of ${aggregation} is not allowed`,
    );
  }
};

// TODO: validate the input gets the right properties
export const buildPayload = (
  payloadType: string,
  input: buildPayloadInputPropsT,
): QueryApiRequestModel.t => {
  const breakdownByDimensions = input.breakdownByDimensions
    ? input.breakdownByDimensions.filter((d) => !!d)
    : undefined;

  let roles = ROLES;

  if (input.roles) {
    roles = input.roles;
  } else if (Selectors.isDemographicFilterRoleCustomer(input)) {
    roles = ['customer'];
  }

  switch (payloadType) {
    case QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT:
    default: {
      return {
        locations: input.locations,
        period: input.period.selectedDates,
        aggregation: input.aggregation,
        ages: input.ages,
        genders: input.genders,
        roles,
        taxonomies: input.taxonomies,
        breakdownByDimensions,
        facets: input.facets,
      };
    }
    case QUERY_API_BODY_PAYLOAD_TYPES.SALES:
      checkMinAggregationType(input.aggregation, 'SALES');
      return {
        locations: input.locations,
        period: input.period.selectedDates,
        aggregation: input.aggregation,
        breakdownByDimensions,
        facets: input.facets,
        // no `roles` here
      };
    case QUERY_API_BODY_PAYLOAD_TYPES.SALES_CONVERSION_RATE:
      checkMinAggregationType(input.aggregation, 'SALES_CONVERSION_RATE');
      return {
        locations: input.locations,
        period: input.period.selectedDates,
        aggregation: input.aggregation,
        breakdownByDimensions,
        facets: input.facets,
        // conversion rate on the API is always just the customers, so no `roles` here
      };
    case QUERY_API_BODY_PAYLOAD_TYPES.AREAS:
      return {
        locations: input.locations,
        period: input.period.selectedDates,
        aggregation: input.aggregation,
        ages: input.ages,
        genders: input.genders,
        roles,
        taxonomies: input.taxonomies,
        areaType: input.taxonomies ? 'taxonomy' : LOCATION_DWELL,
        breakdownByDimensions,
        facets: input.facets,
      };
    case QUERY_API_BODY_PAYLOAD_TYPES.CUSTOM_MOVEMENT_WITHIN_LOCATION:
      const withinLocationKey =
        input.metricKey && input.metricKey.split('__')[1];
      return {
        locations: input.locations,
        withinLocationKey,
        period: input.period.selectedDates,
        aggregation: input.aggregation,
        ages: input.ages,
        genders: input.genders,
        roles,
        taxonomies: input.taxonomies,
        breakdownByDimensions,
        facets: input.facets,
      };
    case QUERY_API_BODY_PAYLOAD_TYPES.MOVEMENT_WITHIN_LOCATION:
      return {
        locations: input.locations,
        period: input.period.selectedDates,
        aggregation: input.aggregation,
        ages: input.ages,
        genders: input.genders,
        roles,
        taxonomies: input.taxonomies,
        breakdownByDimensions,
        facets: input.facets,
      };
  }
};
