/**
 * Paths that are never locally cached
 */

const paths = [
  '/v1/users/profile',
  '/v1/organisation/userHasMembership',
];
const regexPaths = [
  /\/v1\/command\/devel\/[a-f0-9]*\/HelloCommand/i,
  /\/v1\/heatmap\/[a-f0-9]*\/get-source-url/i,
];

export const neverCache = (path) => {
  if (paths.includes(path)) {
    return true;
  }

  for (let regex of regexPaths) {
    if (regex.test(path)) {
      return true;
    }
  }

  return false;
};
