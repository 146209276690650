// @flow

import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { WidgetBodyWrapperGrid } from '../common/styles';
import { WidgetTooltip } from '../common/widget-tooltip';
import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';

import { getMetricChangeValueColorAndIcon } from '../../../utils/widgets-ui';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import type { unitTypeT } from '../../../models/query-response';
import { breakpoints } from '../../../styles/variables';

const MetricWrapper = styled.div``;
const MetricTitle = styled.h3`
  display: flex;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
  margin-bottom: 4px;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    justify-content: center;
  }
`;
const MetricValue = styled.p`
  color: var(--widget-heading-text);
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 0.25em 0;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    justify-content: center;
  }
`;
const MetricChangeValue = styled.p`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 25px;
  margin: 0;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    justify-content: center;
  }
`;
const StyledIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

// TODO widgetPropsT is duplicated becase importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description?: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean,
  progress?: number,
};

type Props = widgetPropsT & {
  /** The metrics to display */
  metrics: {
    title: string,
    description: string,
    value: number,
    unitType: unitTypeT,
    changeValue: number
  }[]
};

const Kpi = ({
  title,
  subtitle,
  description,
  metrics,
  loading,
  progress,
  errors,
}: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <WidgetWrapper
      loading={loading}
      progress={progress}
      errors={errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapperGrid>
        {metrics.map((metric, index) => {
          const { title, description, value, unitType, changeValue } = metric;
          const { color, icon } = getMetricChangeValueColorAndIcon(changeValue, theme);
          return (
            <MetricWrapper key={index}>
              <MetricTitle>
                { description ? (
                  <WidgetTooltip text={title} description={description} />
                ) : title }
              </MetricTitle>
              <MetricValue>
                {formatNumbersWithUnits(value, unitType)}
              </MetricValue>

              <MetricChangeValue color={color}>
                {formatNumbersWithUnits(changeValue, 'percentage')}
                {icon !== 'blank' && (<StyledIcon icon={icon} color={color} iconSize={19} />)}
              </MetricChangeValue>
            </MetricWrapper>
          );
        })}
      </WidgetBodyWrapperGrid>
    </WidgetWrapper>
  );
};

export default Kpi;
