import styled from 'styled-components';
import Sidebar from '../../../containers/sidebar';
import Footer from '../../../containers/footer';
import Header from '../../../containers/header';
import { breakpoints } from '../../../styles/variables';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  background-attachment: fixed;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    flex-direction: column;
    max-width: 100vw;
  }
`;

const Content = styled.section`
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: column;
  background: var(--main-background);
  height: 100vh;
  overflow: auto;

  @media (min-width: ${breakpoints.TABLET_SIZE_PLUS_1}) {
    width: 0;
  }
`;

const Padding = styled.div`
  padding: 2rem;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 1rem;
  }
`;

const DefaultLoggedIn = ({ children, location, sidebarBlur, reports }) => (
  <>
    <Wrapper className="logged-in">
      <Sidebar blur={sidebarBlur} location={location} reports={reports} />
      <Content>
        <Header />
        <Padding>{children}</Padding>
        <Footer />
      </Content>
    </Wrapper>
  </>
);

export default DefaultLoggedIn;
