// @flow

import { Fragment, useState, useCallback } from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { Icon, Intent, ProgressBar, Spinner } from '@blueprintjs/core';

import { WidgetMainWrapper } from './styles';

export const IconWrapper = styled(WidgetMainWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  flex-direction: column;
  height: ${({ height }) => (height === -1 ? 'auto' : `${height}px`)};
`;

export const ErrorWrapper = styled(WidgetMainWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 2rem 1rem;
  color: var(--widget-heading-text);
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 1rem;
`;

const StyledProgressBar = styled(ProgressBar)`
  max-width: 300px;
  height: 0.6rem;
  margin-top: 2rem;
`;

type Props = {
  children: any,
  header?: Node,
  loading?: boolean,
  errors?: boolean,
  progress?: number,
};

const WidgetWrapper = ({
  children,
  header,
  loading,
  errors,
  progress,
}: Props) => {
  const [height, setHeight] = useState(-1);
  const loadedDataWrapper = useCallback((node) => {
    if (node !== null && !loading) {
      setHeight(node.getBoundingClientRect().height);
    } else {
      setHeight(-1);
    }
  }, [loading]);
  return (
    <WidgetMainWrapper>
      <Fragment>{header}</Fragment>
      {loading && (
        <IconWrapper height={height}>
          <Spinner />
          {typeof progress !== 'undefined' && (
            <StyledProgressBar
              intent={Intent.PRIMARY}
              stripes={false}
              value={progress}
            />
          )}
        </IconWrapper>
      )}
      {errors && (
        <ErrorWrapper>
          <StyledIcon icon="heart-broken" iconSize={22} />
          <div>An error has occurred</div>
        </ErrorWrapper>
      )}
      {!loading && !errors && <div ref={loadedDataWrapper}>{children}</div>}
    </WidgetMainWrapper>
  );
};

export default WidgetWrapper;
