// @flow

import { getAuth0Client } from '../services/auth0';
import history from '../root/history';
import { LOGOUT } from '../constants/routes';
import { errorToast } from '../utils/toaster';
import { AUTH_SERVICE_API_BASE } from '../config/vars';

type callArgs = {
  path: string,
  fetchOptions?: Object,
  otherOptions?: Object,
  responseExpected?: boolean,
  responseMethod?: 'json' | 'blob'
};

// TODO: combine this `call` method and use it in all the *api.js services files
const call = async ({
  path,
  fetchOptions,
  otherOptions = {},
  responseExpected = true,
  responseMethod = 'json'
}: callArgs): Promise<any> => {
  const fetchOpts = {
    ...fetchOptions,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    }
  };

  const auth0Client = getAuth0Client();
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    if (token && !otherOptions.dontAddAuthorization) {
      fetchOpts.headers.Authorization = `Bearer ${token}`;
    }
  }

  const res = await fetch(
    (AUTH_SERVICE_API_BASE + path: string),
    (fetchOpts: Object)
  );

  if (res.ok && responseExpected) {
    // $FlowFixMe Flow doesnt know if a response can have a json or blob method
    return await res[responseMethod]();
  } else if (res.ok && !responseExpected) {
    return res;
  }

  // if response is 401 show a toast prompting the user to log back in
  if (res.status === 401 && !otherOptions.disable401Toast) {
    errorToast({
      message: 'Couldn\'t authenticate. You probably need to log back in',
      action: {
        text: 'Log in',
        onClick: e => history.push(LOGOUT)
      },
      timeout: 0
    });
    const responseBody = await res.json();
    console.error(responseBody);
  }

  const error: { response?: Object } = new Error(res.statusText);
  error.response = res;
  throw error;
};

export const getActiveUsers = (orgId: string) => {
  const path = `/api/v1/users?pageSize=100&query[ownerOrgId]=${orgId}&query[status]=active`;
  const fetchOptions = { method: 'GET' };

  return call({ path, fetchOptions });
};

export const getRulesForUser = (userId: string, orgId: string) => {
  const path = `/api/v1/users/${userId}/rules?query[type]=location&query[orgId]=${orgId}`;
  const fetchOptions = { method: 'GET' };

  return call({ path, fetchOptions });
};

export const getRolesForUser = (userId: number, orgId: string) => {
  const path = `/api/v1/users/${userId}/roles?query[orgId]=${orgId}`;
  const fetchOptions = { method: 'GET' };

  return call({ path, fetchOptions });
};

export const getInvitedUsers = (orgId: string) => {
  const path = `/api/v1/users?pageSize=100&query[ownerOrgId]=${orgId}&query[status]=created`;
  const fetchOptions = { method: 'GET' };

  return call({ path, fetchOptions });
};

export const getAdoptedUsers = (orgId: string) => {
  const path = `/api/v1/adopted-users?pageSize=100&query[ownerOrgId]=${orgId}&query[status]=active`;

  const fetchOptions = { method: 'GET' };

  return call({ path, fetchOptions });
};

export const inviteUser = (
  orgName: string,
  email: string,
  roles: number[],
  orgToInviteTo: string
) => {
  const path = '/api/v1/invite-user';

  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({
      invitingOrgName: orgName,
      email,
      roles,
      orgToInviteTo
    })
  };

  return call({ path, fetchOptions, responseExpected: false });
};

export const getAllRoles = () => {
  const path = '/api/v1/roles';
  const fetchOptions = { method: 'GET' };

  return call({ path, fetchOptions });
};

export const deleteUser = (userId: number) => {
  const path = `/api/v1/users/${userId}`;

  const fetchOptions = {
    method: 'DELETE'
  };

  return call({ path, fetchOptions, responseExpected: false });
};

export const deleteAdoptedUser = (userId: number, orgId: string) => {
  const path = `/api/v1/users/${userId}/organisations/${orgId}`;

  const fetchOptions = {
    method: 'DELETE'
  };

  return call({ path, fetchOptions, responseExpected: false });
};

export const createUserRole = (
  userId: number,
  roleId: number,
  orgId: string
) => {
  const path = `/api/v1/users/${userId}/roles`;

  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({ orgId, roleId })
  };

  return call({ path, fetchOptions });
};

export const deleteUserRole = (
  userId: number,
  roleId: number,
  orgId: string
) => {
  const path = `/api/v1/users/${userId}/roles/${roleId}?query[orgId]=${orgId}`;

  const fetchOptions = {
    method: 'DELETE'
  };

  return call({ path, fetchOptions, responseExpected: false });
};

export const user = {
  update: (userId: string, updatedUser: Object) => {
    const body = JSON.stringify(updatedUser);
    return call({
      path: `/api/v1/users/${userId}`,
      fetchOptions: { method: 'PUT', body }
    });
  }
};
