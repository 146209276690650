import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  Popover,
  Menu,
  MenuItem,
  Position,
  Button,
  MenuDivider,
  Classes,
} from '@blueprintjs/core';
import defaultLogo from '../../styles/logos/mobile-dashboard-logo.png';
import history from '../../root/history';

const Wrapper = styled.section`
  width: 100%;
  background: var(--mobile-header-background);
  color: var(--mobile-header-text);
  flex: 1;
  order: -1;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LogoImg = styled.img`
  max-width: 180px;
  max-height: 30px;
`;
const BoldMenuItem = styled(MenuItem)`
  font-weight: 600;

  & div {
    color: rgb(24, 32, 38);
  }
`;

const MobileSidebar = ({ user = {}, logout, productMenuItems, location }) => {
  const theme = useContext(ThemeContext);
  const logo =
    theme && theme.logo && theme.logo.mobile
      ? `../assets/logos/${theme.logo.mobile}`
      : defaultLogo;
  const activeItem = productMenuItems.find(
    (item) =>
      (typeof window === 'object' && item.path) === window.location.pathname
  );

  const productMenu = (
    <Menu>
      <BoldMenuItem icon="user" disabled={true} text={user.name} />
      <MenuItem icon="office" disabled={true} text={user.organisationName} />
      <MenuDivider />
      {productMenuItems.map((item, index) => (
        <MenuItem
          icon={item.icon}
          key={item.label || index}
          text={item.label}
          location={location}
          onClick={(e) => {
            if (item.path.includes('https://')) {
              window.location = item.path;
            } else {
              history.push(item.path);
            }
          }}
          disabled={
            (typeof window === 'object' && item.path) ===
            window.location.pathname
          }
        />
      ))}
      <MenuItem icon="log-out" text="Logout" onClick={logout} />
    </Menu>
  );

  return (
    <Wrapper>
      <LogoImg src={logo} alt="Aura Vision Labs logo" />
      <div>{activeItem && activeItem.label}</div>
      <Popover content={productMenu} position={Position.BOTTOM_LEFT}>
        <Button icon="menu" className={Classes.MINIMAL} />
      </Popover>
    </Wrapper>
  );
};

export default MobileSidebar;
