// @flow

import { connect } from 'react-redux';
import * as Selectors from '../selectors';
import { FETCHING_REPORTS } from '../constants/async-process-names';
import ReportPage from '../components/pages/reports';

function mapStateToProps(state) {
  return {
    reports: Selectors.getReports(state),
    fetchingReports: Selectors.asyncProcessHappening(state, FETCHING_REPORTS),
  };
}

export default connect(mapStateToProps)(ReportPage);
