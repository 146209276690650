const jsonwebtoken = require('jsonwebtoken');
const get = require('lodash.get');
const { GenericAuthError } = require('./error/genericAuthError');
const { NotAuthorisedError } = require('./error/notAuthorisedError');
const { getBootstrapFromAuthService } = require('./getBootstrap');

const impersonateClaim = 'http://auravision.ai/impersonate';
const subClaim = 'sub';

/**
 * Manages which bootstrap data to retrieve and
 * whether to use cache or not
 *
 * Use skipImpersonation = true to get actual bootstrap for
 * sub of the token, rather than the impersonated user
 * This will be needed when calling authorisation on the
 * bootstrap endpoint
 *
 * NOTE: This method does not check the validity of the token,
 * but does use it to check for permission to impersonate
 *
 * @param token
 * @param cacheFacade
 * @param cacheKeyPrefix
 * @param authEnvBasePath
 * @param logger
 * @param BootstrapBuilder
 * @param skipImpersonation
 * @returns {Promise<{}>}
 */
const getUserBootstrap = async (
  token,
  cacheFacade,
  cacheKeyPrefix,
  authEnvBasePath,
  logger,
  BootstrapBuilder,
  skipImpersonation = false,
) => {
  try {
    logger.info({
      message: 'User.getUserBootstrap start',
      contextObject: {},
    });
    let bootstrap = null;
    const decodedToken = jsonwebtoken.decode(token, { complete: true });
    // if we can't decode the token then throw NotAuthorisedError
    if (!decodedToken) throw new NotAuthorisedError('Token failed to decode');
    delete decodedToken.signature;
    const impersonate = get(
      decodedToken,
      `payload['${impersonateClaim}']`,
      false,
    );
    const sub = get(decodedToken, `payload.${subClaim}`, false);

    logger.info({
      message: 'User.getUserBootstrap decoded token',
      contextObject: { decodedToken, impersonate, sub },
    });

    // if skipImpersonation == true then skip this section
    // in order to get the actual bootstrap, rather than
    // impersonated bootstrap
    if (skipImpersonation) {
      logger.info({
        message: 'User.getUserBootstrap skipping impersonation',
        contextObject: { skipImpersonation },
      });
    } else {
      logger.info({
        message: 'User.getUserBootstrap Not skipping impersonation',
        contextObject: { skipImpersonation },
      });

      // If attempting to impersonate another user
      if (impersonate) {
        logger.info({
          message: 'User.getUserBootstrap token attempting to impersonate a user. Check if we have the impersonated users bootstrap in the cache.',
          contextObject: {
            impersonatee: impersonate,
          },
        });

        const bootstrapFromCache = await checkCacheForBootstrap(
          cacheFacade,
          `${cacheKeyPrefix}${impersonate}`,
          logger,
        );
        if (bootstrapFromCache) return bootstrapFromCache;

        // Otherwise go to auth service bootstrap api to ensure that requester has
        // impersonation authority (allow cache search)
        logger.info({
          message:
            'User.getUserBootstrap token attempting to impersonate. '
            + 'Getting bootstrap from auth endpoint to authorise ability to '
            + `impersonate. Do we have local BootstrapBuilder: ${!!BootstrapBuilder}`,
          contextObject: { impersonate },
        });

        return getBootstrapFromAuthService(
          null,
          token,
          impersonate,
          false,
          authEnvBasePath,
          logger,
        );
      }
    }

    // otherwise, request is for bootstrap of tokens sub, token
    // is authenticated and everybody can get their own bootstrap,
    // so no authorisation required
    bootstrap = await checkCacheForBootstrap(
      cacheFacade,
      `${cacheKeyPrefix}${sub}`,
      logger,
    );
    if (bootstrap) return bootstrap;

    // Otherwise we'll get it from auth service bootstrap api.
    // Set skipCache as follows. If we have a cache then
    // skipCache = true else
    // skipCache = false
    return getBootstrapFromAuthService(
      BootstrapBuilder,
      token,
      sub,
      !!cacheFacade,
      authEnvBasePath,
      logger,
    );
  } catch (err) {
    if (err instanceof NotAuthorisedError) throw err;
    throw new GenericAuthError('User.getUserBootstrap failed', err);
  }
};

/**
 *
 * @param cacheFacade
 * @param key
 * @param logger
 * @returns {Promise<{[p: string]: *}>}
 */
const checkCacheForBootstrap = async (cacheFacade, key, logger) => {
  if (cacheFacade) {
    logger.info({
      message: `User.getUserBootstrap Check cache facade for ${key}`,
      contextObject: { cacheFacade: !!cacheFacade },
    });

    const bootstrap = await cacheFacade.getKey(`${key}`);

    if (bootstrap) {
      logger.info({
        message: `User.getUserBootstrap cache hit for ${key}`,
        contextObject: { bootstrap },
      });
      return { ...bootstrap };
    }

    logger.info({
      message: `User.getUserBootstrap cache miss for ${key}`,
      contextObject: {},
    });
    return false;
  }

  logger.info({
    message: `User.getUserBootstrap Not checking cache facade for ${key}`,
    contextObject: { cacheFacade: !!cacheFacade },
  });
  return false;
};

module.exports = {
  getUserBootstrap,
};
