// @flow

import { EXPLORER, SUMMARY } from '../constants/page-settings';

import * as UserModel from '../models/user';
import * as RecordingModel from '../models/recording';
import * as LocationModel from '../models/location';
import * as ReportModel from '../models/report';
import type { ReducerType as UserReducerT } from '../reducers/user';
import type { ReducerType as RecordingReducerT } from '../reducers/recordings';
import type { ReducerType as LocationReducerT } from '../reducers/locations';
import type { ReducerType as PageSettingsReducerT } from '../reducers/pageSettings';
import type { ReducerType as AsyncProcessesReducerT } from '../reducers/asyncProcesses';
import type { ReducerType as ReportReducerT } from '../reducers/reports';
import type { CombinedReducersT } from '../reducers';
import * as ActivesMetricsModel from '../models/active-metrics';
import * as QueryResponseListModel from '../models/query-response-list';
import * as AvailableMetricsModel from '../models/available-metrics';
import { ORG_HAS_STAFF_DETECTION } from '../constants/features';

export const userHasFeature = (
  featureName: string,
  {
    user,
  }: {
    user: UserReducerT,
  }
): boolean => {
  if (!user || !user.model) return false;
  const { features } = user.model;
  if (!features) return false;

  const result = features[featureName];

  if (!result) return false;
  if (result === 'on') return true;
  if (result === 'off') return false;
  return false;
};

export const valueOfUserFeature = (
  featureName: string,
  {
    user,
  }: {
    user: UserReducerT,
  }
): string => {
  if (!user || !user.model) return '';
  const { features } = user.model;
  if (!features) return '';
  const result = features[featureName];
  if (!result) return '';
  return result;
};

export const userHasPermission = (
  permissionName: string,
  {
    user,
  }: {
    user: UserReducerT,
  }
): boolean => {
  if (!user || !user.model) return false;
  const { permissions } = user.model;
  if (!permissions) return false;
  return permissions[permissionName];
};

export const getUserModel = ({
  user,
}: {
  user: UserReducerT,
}): ?UserModel.t => {
  if (!!user && !!user.model) return user.model;
  return null;
};

export const getLocations = ({
  locations,
}: {
  locations: LocationReducerT,
}): LocationModel.t[] => {
  return (
    locations.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    }) || []
  );
};

export const getOrganisationName = ({
  user,
}: {
  user: UserReducerT, // TODO: this uses the reducer type, but shouldn't it use the model type?
}): string => {
  if (!!user && !!user.model) return UserModel.getOrganisationName(user.model);
  return '';
};

export const getOrganisationId = ({
  user,
}: {
  user: UserReducerT, // TODO: this uses the reducer type, but shouldn't it use the model type?
}): string => {
  if (!!user && !!user.model) return UserModel.getOrganisationId(user.model);
  return '';
};

export const userRefreshCompleted = ({
  asyncProcesses,
}: {
  asyncProcesses: AsyncProcessesReducerT,
}): boolean => asyncProcesses.userRefresh.completed;

export const userRefreshAuthFailure = ({
  asyncProcesses,
}: {
  asyncProcesses: AsyncProcessesReducerT,
}): boolean =>
  userRefreshCompleted({ asyncProcesses }) &&
  !!asyncProcesses.userRefresh.responseFailureCode &&
  asyncProcesses.userRefresh.responseFailureCode >= 400 &&
  asyncProcesses.userRefresh.responseFailureCode < 500;

export const asyncProcessHappening = (
  { asyncProcesses }: { asyncProcesses: AsyncProcessesReducerT }, // todo: should use AsyncProcessesReducerT here but it doesnt work
  key: string
): boolean =>
  asyncProcesses.ongoingProcesses.some((activeProcess) =>
    typeof activeProcess === 'string'
      ? activeProcess.startsWith(key)
      : activeProcess.process.startsWith(key)
  );

export const getRecordings = ({
  recordings,
}: {
  recordings: RecordingReducerT,
}): RecordingReducerT => recordings;

export const getReports = ({
  reports,
}: {
  reports: ReportReducerT,
}): ReportReducerT => reports;

export const getOneReport = (
  id: string,
  state: CombinedReducersT
): ?ReportModel.t => {
  const reports = getReports(state);
  if (!reports) return undefined;
  return ReportModel.findById(reports, id);
};

export const getOneRecording = (
  {
    recordings,
  }: {
    recordings: RecordingReducerT,
  },
  id: ?string
): ?RecordingModel.t => {
  // TODO: use RecordingModel.findById (which in turn uses Arr.find)
  const filtered = recordings.filter((r) => r.id === id);
  return filtered.length === 1 ? filtered[0] : null;
};

export const getPageSettings = (
  { pageSettings }: { pageSettings: PageSettingsReducerT },
  pageName: string
) => pageSettings[pageName];

export const getQueryResponseList = ({
  queryResponseList,
}: {
  queryResponseList: QueryResponseListModel.t,
}) => queryResponseList;

export const getAvailableMetrics = ({
  user,
}: {
  user: UserReducerT,
}): ?AvailableMetricsModel.t => {
  const model = user && getUserModel({ user });
  return model && UserModel.getAvailableMetrics(model);
};

export const isDemographicFilterRoleCustomer = (state?: Object): boolean => {
  if (!state || !state.demographicFilter || !state.demographicFilter.role) {
    return false;
  }

  return state.demographicFilter.role === 'customer';
};

export const isUserNewlyCreated = (state: CombinedReducersT): boolean => {
  const userModel = getUserModel(state);
  if (!userModel) return false;
  const status = UserModel.getUserStatus(userModel);
  return status === 'created';
};

export const getPageQueryResponseList = (
  state: CombinedReducersT,
  queryResponseList: QueryResponseListModel.t,
  pageSettingsType?: 'explorer' | 'summary'
) => {
  if (
    !pageSettingsType ||
    (pageSettingsType !== EXPLORER && pageSettingsType !== SUMMARY)
  ) {
    return queryResponseList;
  }

  const settings = getPageSettings(state, pageSettingsType);

  const { metrics, period, aggregation, activeMetrics } = settings;
  let metricsToUse = [];
  let aggregationToUse = pageSettingsType === EXPLORER ? aggregation : 'day';

  if (pageSettingsType === EXPLORER) {
    metricsToUse = metrics;
  } else if (pageSettingsType === SUMMARY) {
    metricsToUse =
      ActivesMetricsModel.getColumnsFromActiveMetrics(activeMetrics);
  }

  let filteredComparisonQueryResponseList = [];

  const filteredQueryResponseList = metricsToUse
    ? metricsToUse.reduce((finalFilteredQueryResponseList, metric) => {
        const correctMetric =
          pageSettingsType === EXPLORER && metric.metric
            ? metric.metric
            : metric;
        const correctPeriod = period.selectedDates;

        const excludeStaff =
          pageSettingsType === EXPLORER
            ? isDemographicFilterRoleCustomer(metric) ||
              !userHasFeature(ORG_HAS_STAFF_DETECTION, state)
            : isDemographicFilterRoleCustomer(settings) ||
              !userHasFeature(ORG_HAS_STAFF_DETECTION, state);

        if (pageSettingsType === SUMMARY) {
          /*
            This block is being called when:
            - In SUMMARY page we get each single location (as string)
          */
          const findCriteria = {
            ...correctMetric,
            excludeStaff,
            isComparisonActive: false,
            aggregation: aggregationToUse,
            period: correctPeriod,
            isAllLocations: false,
            locations: settings.filteredLocations,
            breakdownByDimensions: ['entity'],
            taxonomies: correctMetric.taxonomies
              ? correctMetric.taxonomies
              : undefined,
          };

          if (findCriteria.filter) {
            findCriteria.taxonomies = [
              `${findCriteria.filter.key}:${findCriteria.filter.value}`,
            ];
            delete findCriteria.filter;
          }

          const queryResponse = QueryResponseListModel.findAll(
            findCriteria,
            queryResponseList
          );

          if (queryResponse) {
            finalFilteredQueryResponseList.push(...queryResponse);
          }
        }

        /*
          This block is being called when:
            - In SUMMARY page we get the allLocations queryResponses (as an array)
            - In EXPLORER page we get all the locations we need in location (as an array)
        */
        const findCriteria = {
          ...correctMetric,
          excludeStaff,
          isComparisonActive: false,
          aggregation: aggregationToUse,
          period: correctPeriod,
          isAllLocations: false,
          locations:
            pageSettingsType === EXPLORER
              ? // $FlowFixMe -> Flow doesn't know what metric is
                metric.location
              : settings.filteredLocations,
          breakdownByDimensions: undefined,
          taxonomies: correctMetric.taxonomies
            ? correctMetric.taxonomies
            : undefined,
        };

        if (findCriteria.filter) {
          findCriteria.taxonomies = [
            `${findCriteria.filter.key}:${findCriteria.filter.value}`,
          ];
          delete findCriteria.filter;
        }

        if (pageSettingsType === SUMMARY) {
          findCriteria.isAllLocations = true;
        }

        const queryResponse = QueryResponseListModel.findAll(
          findCriteria,
          queryResponseList,
          {
            ignoreBreakdownBy: true,
          }
        );

        if (queryResponse) {
          finalFilteredQueryResponseList.push(...queryResponse);
        }

        return finalFilteredQueryResponseList;
      }, [])
    : [];

  /*
    This block is being called when:
      - In SUMMARY page you have isComparisonActive.
      - In EXPLORER page you have compareToPast.active
  */
  if (settings.compareToPast.active && settings.compareToPast.selectedDates) {
    filteredComparisonQueryResponseList = metricsToUse
      ? metricsToUse.reduce((finalFilteredQueryResponseList, metric) => {
          const correctMetric =
            pageSettingsType === EXPLORER && metric.metric
              ? metric.metric
              : metric;
          const correctPeriod = settings.compareToPast.selectedDates;

          const excludeStaff =
            pageSettingsType === EXPLORER
              ? isDemographicFilterRoleCustomer(metric) ||
                !userHasFeature(ORG_HAS_STAFF_DETECTION, state)
              : isDemographicFilterRoleCustomer(settings) ||
                !userHasFeature(ORG_HAS_STAFF_DETECTION, state);

          if (pageSettingsType === SUMMARY) {
            /*
              This block is being called when:
                - In SUMMARY page we get each single location (as string).
            */
            const findCriteria = {
              ...correctMetric,
              excludeStaff,
              isComparisonActive: true,
              isAllLocations: false,
              aggregation: aggregationToUse,
              period: correctPeriod,
              locations: settings.filteredLocations,
              breakdownByDimensions: ['entity'],
              taxonomies: correctMetric.taxonomies
                ? correctMetric.taxonomies
                : undefined,
            };

            if (findCriteria.filter) {
              findCriteria.taxonomies = [
                `${findCriteria.filter.key}:${findCriteria.filter.value}`,
              ];
              delete findCriteria.filter;
            }

            const queryResponse = QueryResponseListModel.findAll(
              findCriteria,
              queryResponseList
            );

            if (queryResponse) {
              finalFilteredQueryResponseList.push(...queryResponse);
            }
          }

          /*
            This block is being called when:
              - In SUMMARY page we get the allLocations queryResponses (as an array).
              - In EXPLORER page we get all the locations we need in location.include (as an array).
          */
          const findCriteria = {
            ...correctMetric,
            excludeStaff,
            aggregation: aggregationToUse,
            period: correctPeriod,
            isComparisonActive: true,
            isAllLocations: false,
            locations:
              pageSettingsType === EXPLORER
                ? // $FlowFixMe -> Flow doesn't know what metric is
                  metric.location
                : settings.filteredLocations,
            breakdownByDimensions: undefined,
            taxonomies: correctMetric.taxonomies
              ? correctMetric.taxonomies
              : undefined,
          };

          if (findCriteria.filter) {
            findCriteria.taxonomies = [
              `${findCriteria.filter.key}:${findCriteria.filter.value}`,
            ];
            delete findCriteria.filter;
          }

          if (pageSettingsType === SUMMARY) {
            findCriteria.isAllLocations = true;
          }

          const queryResponse = QueryResponseListModel.findAll(
            findCriteria,
            queryResponseList,
            {
              ignoreBreakdownBy: true,
            }
          );

          if (queryResponse) {
            finalFilteredQueryResponseList.push(...queryResponse);
          }

          return finalFilteredQueryResponseList;
        }, [])
      : [];
  }

  return [...filteredComparisonQueryResponseList, ...filteredQueryResponseList];
};

export const getLocationById = (
  state: CombinedReducersT,
  id: string
): ?LocationModel.t => {
  const locations = getLocations(state);

  if (locations && locations.length > 0) {
    const location = locations.find((loc) => loc.id === id);

    return location || null;
  }

  return null;
};
