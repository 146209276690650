// @flow

import { Fragment } from 'react';
import { Tag, Icon, Intent } from '@blueprintjs/core';
import styled from 'styled-components';
import TaxonomyMenu from '../pickers/taxonomy-menu';
import FiltersMenu from '../pickers/filters-menu';
import { taxonomiesConfigurationObjectCreator } from '../../utils/taxonomyHelpers';

import * as ActiveMetricsModel from '../../models/active-metrics';
import type { subMenuConfigurationT } from './sub-menu';
import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';

export const TagStyled = styled(Tag)`
  margin: 0 0.5em;
  font-weight: 0;
`;
const TaxonomyTag = styled(Tag)`
  margin: 0 1rem;
`;
export const SubMenuHeaderContainer = styled.div`
  display: flex;
  padding: 1em;
  border-bottom: solid 1px var(--super-light-grey);
  &:hover {
    cursor: pointer;
    background-color: var(--super-light-grey);
  }
`;
const MiddleColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-left: 1.5em;
  margin-right: 1.5em;
`;
const RightColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled(Icon)`
  color: var(--gray);
`;
export const CircularIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--super-light-blue);
  border-radius: 50%;
  border: 3px solid var(--blue);
  color: var(--blue);
  flex-shrink: 0;
  flex-grow: 0;
`;
const MenuItemTitle = styled.div`
  color: var(--gray);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;
const TaxonomiesAndTagsRow = styled.div`
  padding: 1em 2em;
  display: flex;
`;

type Props = {
  handleCurrentSubMenuOpen(name: string): void,
  subMenuConfiguration: subMenuConfigurationT,
  showSubMenuOpen: boolean,
  editActiveMetrics(config: editSummaryPageActiveMetricsArgsT): void,
  subMenuKey: string,
  activeMetrics: ActiveMetricsModel.t,
  icon: string,
  tags?: number,
  showTaxonomyTagWarning: boolean
};

const SubMenuHeader = ({
  handleCurrentSubMenuOpen,
  subMenuConfiguration,
  showSubMenuOpen,
  editActiveMetrics,
  subMenuKey,
  activeMetrics,
  icon,
  tags,
  showTaxonomyTagWarning
}: Props) => {
  const availableTaxonomyList =
    subMenuConfiguration.taxonomies &&
    subMenuConfiguration.taxonomies.length > 0 &&
    taxonomiesConfigurationObjectCreator(subMenuConfiguration.taxonomies);
  const numberOfActivesTaxonomies = ActiveMetricsModel.getListPropLength(
    activeMetrics,
    subMenuKey,
    'taxonomies'
  );

  const availableFilterList = subMenuConfiguration.filters;
  const numberOfActiveFilters = ActiveMetricsModel.getListPropLength(
    activeMetrics,
    subMenuKey,
    'filters'
  );

  return (
    <Fragment>
      <SubMenuHeaderContainer
        onClick={() => handleCurrentSubMenuOpen(subMenuConfiguration.title)}
      >
        <div>
          <CircularIcon>
            <Icon icon={icon} intent="primary"></Icon>
          </CircularIcon>
        </div>
        <MiddleColumn>
          <MenuItemTitle>
            {subMenuConfiguration.title}
            {!!tags && <TagStyled>{tags} DISPLAYED</TagStyled>}
          </MenuItemTitle>
          <div>{subMenuConfiguration.description}</div>
        </MiddleColumn>
        <RightColumn>
          <StyledIcon
            icon={!showSubMenuOpen ? 'chevron-right' : 'chevron-down'}
          ></StyledIcon>
        </RightColumn>
      </SubMenuHeaderContainer>
      {availableTaxonomyList &&
        availableTaxonomyList.length > 0 &&
        showSubMenuOpen &&
        subMenuConfiguration.taxonomies && (
          <TaxonomiesAndTagsRow>
            <TaxonomyMenu
              availableTaxonomyList={availableTaxonomyList}
              editActiveMetrics={editActiveMetrics}
              subMenuKey={subMenuKey}
              subMenuConfiguration={subMenuConfiguration}
              activeMetrics={activeMetrics}
            />
            <TaxonomyTag
              large
              round
              intent={
                numberOfActivesTaxonomies === 0 ||
                (showTaxonomyTagWarning && numberOfActivesTaxonomies === 0)
                  ? Intent.DANGER
                  : Intent.PRIMARY
              }
            >
              {`${numberOfActivesTaxonomies} Selected`}
            </TaxonomyTag>
          </TaxonomiesAndTagsRow>
        )}

      {availableFilterList &&
        availableFilterList.length > 0 &&
        showSubMenuOpen &&
        subMenuConfiguration.filters && (
          <TaxonomiesAndTagsRow>
            <FiltersMenu
              availableFilterList={availableFilterList}
              editActiveMetrics={editActiveMetrics}
              subMenuKey={subMenuKey}
              activeMetrics={activeMetrics}
            />
            <TaxonomyTag
              large
              round
              intent={
                numberOfActiveFilters === 0 ||
                (showTaxonomyTagWarning && numberOfActiveFilters === 0)
                  ? Intent.DANGER
                  : Intent.PRIMARY
              }
            >
              {`${numberOfActiveFilters} Selected`}
            </TaxonomyTag>
          </TaxonomiesAndTagsRow>
        )}
    </Fragment>
  );
};

export default SubMenuHeader;
