import { sendMessage } from '../services/sql-api';

export const fetchAreaContextsForRecordings = async (
  recordingIds: Array<string>,
): Object => {
  const results = await sendMessage('detail', 'list', {
    entities: recordingIds,
    entityType: 'recording',
    returnEntityType: 'area_context',
  });

  let areaContextsByRecording = new Map();
  if (results && Array.isArray(results) && results.length > 0) {
    recordingIds.forEach((recordingId) => {
      const thumbnails = results.filter(
        (thumb) => thumb.recording_id === recordingId,
      );

      areaContextsByRecording.set(recordingId, thumbnails);
    });
  }

  return areaContextsByRecording;
};
