// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import * as RecordingActions from '../actions/recording';
import { FETCHING_RECORDINGS } from '../constants/async-process-names';
import LoadRecordings from '../components/pages/load-recordings';

function mapStateToProps(state) {
  return {
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
    fetchingRecordings: Selectors.asyncProcessHappening(
      state,
      FETCHING_RECORDINGS
    )
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      fetchRecordings: () => RecordingActions.fetch(ownProps.include)
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadRecordings);
