// @flow

import { useState } from 'react';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { Card, Button } from '@blueprintjs/core';
import styled from 'styled-components';
import * as ReportModel from '../../../models/report';
import * as LocationModel from '../../../models/location';
import { dayOfWeekLabel, ordinalSuffix } from '../../../utils/dates';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  align-items: start;

  &:last-child {
    margin-bottom: 0;
  }
`;
const EndAlignedRow = styled(Row)`
  justify-content: end;
`;
const P = styled.p`
  margin-bottom: 0;
  line-height: 28px;
`;
const StyledButton = styled(Button)`
  margin-left: 0.25rem;
`;
const Col = styled.div`
  margin: 0 0.15rem;
  display: flex;
  align-items: center;
`;
const NumberCol = styled(Col)`
  margin-right: 1rem;
`;
const ScrollableCard = styled(Card)`
  margin-bottom: 0.5rem;
`;
const Datum = styled.span`
  background: rgba(19, 124, 189, 0.15);
  padding: 0.25rem 0.5rem;
  color: #137cbd;
  border-radius: 4px;
  margin: 0 0.25rem;
`;
const ClickableDatum = styled(Datum)`
  cursor: pointer;
`;
const LocationList = styled.span`
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: block;
`;
const LocationListItem = styled.span`
  display: block;
  line-height: 28px;
`;

const labels = {
  sendType: {
    'email - specific users': 'certain users',
    'email - all org users': 'all users',
    unknown: 'Not set',
  },
  cadence: {
    hourly: 'hourly',
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    unknown: 'Not set',
  },
};

const localTzHour = (hour) => {
  const utcTime = moment().utc().hour(hour);
  const userTz = moment.tz.guess();
  const localHour = moment.tz(utcTime, userTz).format('ha');
  const userTzAbbr = moment.tz.zone(userTz).abbr(utcTime);

  return `${localHour} (${userTzAbbr})`;
};

type Props = {
  reports: ReportModel.t[],
  number: number,
  job: Object, //TODO: type this as a scheduledReportJob
  locations: LocationModel.t[],
  setJobToDeleteId: (jobId: string) => void,
  setJobToTest: (jobId: string) => void,
  editJob: (jobId: string) => void,
  showDeleteButton: boolean,
  showTestButton: boolean,
  showEditButton: boolean,
};

const DashboardReportDetailsRow = ({
  reports,
  job,
  number,
  locations,
  setJobToDeleteId,
  setJobToTest,
  editJob,
  showDeleteButton,
  showTestButton,
  showEditButton,
}: Props) => {
  const [showEmails, setShowEmails] = useState(false);
  const [showLocations, setShowLocations] = useState(false);

  const sendType = get(job, 'distribution.sendType', 'unknown');
  const toEmails = get(job, 'distribution.toEmails', 'unknown');
  const cadence = get(job, 'schedule.cadence', 'unknown');
  const sendDay = get(job, 'schedule.sendDay', 'unknown');
  const sendHour = get(job, 'schedule.sendHour', 'unknown');
  const reportId = get(
    job,
    'dashboardReportToImage.dashboardReportId',
    'unknown'
  );
  const restrictToLocations = get(
    job,
    'dashboardReportToImage.restrictToLocations',
    false
  );

  let scheduleLabel = '';
  const cadenceLabel = labels.cadence[cadence];

  switch (cadence) {
    case 'hourly':
    default:
      scheduleLabel = cadenceLabel;
      break;
    case 'daily':
      scheduleLabel = `${cadenceLabel} at ${localTzHour(sendHour)}`;
      break;
    case 'weekly':
      {
        const dow = dayOfWeekLabel(sendDay) || '';
        scheduleLabel = `${cadenceLabel}, ${dow} at ${localTzHour(sendHour)}`;
      }
      break;
    case 'monthly':
      scheduleLabel = `${cadenceLabel}, on the ${ordinalSuffix(
        sendDay
      )} day of the month at ${localTzHour(sendHour)}`;
      break;
  }

  const report = ReportModel.findById(reports, reportId) || {};
  const reportName = report.name;

  const sendTypeLabel = labels.sendType[sendType];

  let locationNames = [];
  if (restrictToLocations) {
    locationNames = restrictToLocations.map((locationId) =>
      LocationModel.getLocationName(locationId, locations)
    );
  }

  return (
    <ScrollableCard>
      <Row>
        <NumberCol>
          <Datum minimal round>
            {number}
          </Datum>
        </NumberCol>
        <P>
          <strong>{reportName}&nbsp;</strong>
          {restrictToLocations && !!restrictToLocations.length && (
            <>
              for
              {showLocations ? (
                <LocationList onClick={() => setShowLocations(false)}>
                  {locationNames.map((name) => (
                    <LocationListItem>
                      <Datum>{name}</Datum>
                    </LocationListItem>
                  ))}
                </LocationList>
              ) : (
                <ClickableDatum onClick={() => setShowLocations(true)}>
                  {restrictToLocations.length} locations
                </ClickableDatum>
              )}
            </>
          )}
          to&nbsp;
          {sendTypeLabel === 'certain users' ? (
            <ClickableDatum
              onClick={() => setShowEmails(!showEmails)}
              large
              minimal
              intent="primary"
            >
              {showEmails
                ? toEmails.join(', ')
                : `${toEmails.length} user${toEmails.length > 1 ? 's' : ''}`}
            </ClickableDatum>
          ) : (
            <span>{sendTypeLabel}&nbsp;</span>
          )}
          <span>{scheduleLabel}&nbsp;</span>
          {job.scheduledSendingDisabled && (
            <Datum minimal intent="warning" icon="warning-sign">
              Disabled
            </Datum>
          )}
        </P>
      </Row>

      {(showDeleteButton || showTestButton || showEditButton) && (
        <EndAlignedRow>
          {showTestButton && (
            <StyledButton
              minimal
              icon="lab-test"
              text="Test"
              onClick={(e) => setJobToTest(job)}
            />
          )}
          {showEditButton && (
            <StyledButton minimal icon="edit" onClick={(e) => editJob(job)} />
          )}
          {showDeleteButton && (
            <StyledButton
              intent="danger"
              icon="trash"
              onClick={(e) => setJobToDeleteId(job.id)}
            />
          )}
        </EndAlignedRow>
      )}
    </ScrollableCard>
  );
};

export default DashboardReportDetailsRow;
