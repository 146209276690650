export const variables = {
  desktopNavbarWidth: '18em',
  columnsNumberNeededBeforeHavingFixWitdh: 5,
};

export const getChartColors = (theme) => {
  if (theme) {
    return {
      all: theme.chartColors[8],
      filtered: theme.chartColors[8],
      other: theme.chartColors[9],
      gender_male: theme.properties['--gender-male'],
      gender_female: theme.properties['--gender-female'],
      gender_undefined: theme.properties['--gender-undefined'],
      age_0_15: theme.chartColors[0],
      age_16_24: theme.chartColors[1],
      age_25_34: theme.chartColors[2],
      age_35_44: theme.chartColors[3],
      age_45_54: theme.chartColors[4],
      age_55_64: theme.chartColors[5],
      age_65_100: theme.chartColors[6],
      age_undefined: theme.grayUndefined,
      role_staff: theme.chartColors[3],
      role_customer: theme.chartColors[4],
      role_undefined: theme.properties['--gray-undefined'],
    };
  }
};

// TODO: make this less gender normative
export const getGenderColor = (key, theme) => {
  if (theme) {
    if (key.includes('female')) return theme.properties['--gender-female'];
    if (key.includes('male')) return theme.properties['--gender-male'];
  }
};

export const breakpoints = {
  PHONE_SIZE: '480px',
  PHONE_SIZE_PLUS_1: '481px',
  TABLET_SIZE: '820px',
  TABLET_SIZE_PLUS_1: '821px',
  SMALL_PC_SIZE: '1000px',
  SMALL_PC_SIZE_PLUS_1: '1001px',
  MED_PC_SIZE: '1300px',
  MED_PC_SIZE_PLUS_1: '1301px',
};

export const getFonts = (theme) => {
  if (theme) {
    return {
      defaultFont: theme.properties['--fonts-default'],
      headingFont: theme.properties['--fonts-heading'],
      widgetFont: theme.properties['--fonts-widget-body'],
    };
  } else {
    return;
  }
};