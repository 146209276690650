// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import CameraPreview from '../components/pages/camera-preview';
import { IS_DEMO_USER } from '../constants/features';

function mapStateToProps(state, ownProps) {
  return {
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
    isDemoUser: Selectors.userHasFeature(IS_DEMO_USER, state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CameraPreview);
