// @flow

import { useState } from 'react';
import _ from 'lodash';
import { Button, Spinner } from '@blueprintjs/core';
import { Desktop, Mobile } from '../layouts/devices-sizes';
import {
  trackEvent,
  DOWNLOAD_CSV_BY_DAY_BUTTON,
} from '../../services/mixpanel';

import * as ActiveMetricsModel from '../../models/active-metrics';
import * as LocationModel from '../../models/location';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as QueryApiRequestModel from '../../models/query-api-request';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { generateCSVFromSQLQuery } from '../../services/sql-api';
import { errorToast } from '../../utils/toaster';
import type { locationConfigT } from './summary-btn';
import {
  GENDERS,
  ROLES,
  SORTED_AGE_KEYS,
} from '../../constants/demographic-types';
import { apiEndpointByMetricKey } from '../../constants/api-endpoints';
import { CSV_GEN_PAYLOAD_TYPES } from '../../services/query-api';

type Props = {
  disabled: boolean,
  locations: LocationModel.t[],
  queryResponseList: QueryResponseListModel.t,
  activeMetrics: ActiveMetricsModel.t,
  filteredLocationIds: string[],
  availableMetrics: AvailableMetricsModel.t,
  period: QueryApiRequestModel.periodT,
  filteredLocationsConfigList: locationConfigT[],
  excludeStaff: boolean,
  excludeAgeAndGender: boolean,
  locations: LocationModel.t[],
};

type ActiveMetricListItem = {
  label: string,
  metricKey: string,
  metricGroupKey: string,
  taxonomies: string[] | null,
};

const ageOptions = SORTED_AGE_KEYS;
const genderOptions = GENDERS;
const baseRowFilters = ['index', 'location'];
const baseColumns = [
  {
    heading: 'Local Time',
    fetchValue: 'index',
  },
  {
    heading: 'Location Name',
    fetchValue: 'location_name',
  },
];
const baseSortBy = [1, 0];

const generateRowFilters = (
  excludeStaff: boolean,
  excludeAgeAndGender: boolean,
) => {
  const rowFilters = [...baseRowFilters];
  if (!excludeAgeAndGender) {
    rowFilters.push('age', 'gender');
  }
  if (!excludeStaff) {
    rowFilters.push('role');
  }
  return rowFilters;
};

const DownloadByDayCSV = ({
  disabled,
  activeMetrics,
  filteredLocationIds,
  period,
  excludeStaff,
  excludeAgeAndGender,
  locations,
  availableMetrics,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const onDownloadByDay = async () => {
    setLoading(true);
    try {
      // Format times
      const start = moment.utc(period.start).toISOString();
      const end = moment.utc(period.end);
      end.add(1, 'day');
      end.subtract(1, 'second');
      const csvFormat = {
        sortBy: [...baseSortBy],
        rowFilters: generateRowFilters(excludeStaff, excludeAgeAndGender),
        columns: [...baseColumns],
      };
      const metricRequests = [];

      const activeMetricsList: ActiveMetricListItem[] = [];

      // Format the active metrics list
      Object.entries(activeMetrics).forEach(
        ([metricGroupKey, metricGroup]: [string, Object]) =>
          metricGroup.metrics.forEach((metric) => {
            const metricDetails = AvailableMetricsModel.findMetricDetails(
              availableMetrics,
              metricGroupKey,
              metric,
            );

            const metricLabel =
              AvailableMetricsModel.getMetricLabel(metricDetails) || metric;

            if (metricGroup.taxonomies || metricGroup.filters) {
              let taxonomies = metricGroup.taxonomies;
              if (metricGroup.filters) {
                taxonomies = metricGroup.filters.map(
                  (filter) => `${filter.key}:${filter.value}`,
                );
              }

              activeMetricsList.push(
                ...taxonomies.map((taxonomy) => ({
                  label: `${metricLabel} - ${taxonomy}`,
                  metricGroupKey,
                  metricKey: metric,
                  taxonomies: [taxonomy],
                })),
              );
            } else {
              activeMetricsList.push({
                label: metricLabel,
                metricGroupKey,
                metricKey: metric,
                taxonomies: null,
              });
            }
          }),
      );
      // This is where all the queries get stored
      const metricQueries = [];

      // Build multiple requests for each active metric
      // TODO: move this logic server side - it will be more efficient and make it simpler for the API end users

      activeMetricsList.forEach((item) => {
        // Base Metric Query -- need to update Breakdowns, metricId, name and roles.
        const baseQuery: {
          start: string,
          end: string,
          aggregationPeriod: string,
          facets: string[],
          entityType: string,
          insideRecordingTimes: boolean,
          roles?: string[],
          entities?: string[],
          name?: string,
          metricId?: string,
          taxonomy?: string[],
          breakdownByDimensions?: string[],
          ages?: string[],
          areaType?: string,
          lineType?: string,
        } = {
          start,
          end: end.toISOString(),
          aggregationPeriod: 'day',
          facets: ['segments'],
          entityType: 'location',
          insideRecordingTimes: true,
          entities: locations.map((loc) => loc.id),
        };

        if (item.metricGroupKey !== CSV_GEN_PAYLOAD_TYPES.SALES) {
          baseQuery.roles = ['customer'];
        }

        if (locations.length !== filteredLocationIds.length) {
          baseQuery.entities = filteredLocationIds;
        }

        if (item.metricKey) {
          baseQuery.name = apiEndpointByMetricKey(item.metricKey);
        }

        if (item.metricGroupKey) {
          baseQuery.metricId = item.taxonomies
            ? `${item.metricKey}_${item.taxonomies.join('_')}`
            : item.metricKey;
        }

        if (item.taxonomies) {
          baseQuery.taxonomy = item.taxonomies;

          if (
            [
              CSV_GEN_PAYLOAD_TYPES.AREAS,
              CSV_GEN_PAYLOAD_TYPES.LOCATION_AREAS,
              CSV_GEN_PAYLOAD_TYPES.SERVICE_AREAS,
            ].includes(item.metricGroupKey)
          ) {
            baseQuery.areaType = 'taxonomy';
          }
        }

        // Generate metric query column 1 instead of many
        const baseMetricColumn: {
          heading: string,
          field: string,
          filter?: { taxonomy: string },
        } = {
          heading: item.label,
          field: item.metricKey,
          metricId: item.taxonomies
            ? `${item.metricKey}_${item.taxonomies.join('_')}`
            : item.metricKey,
        };

        if (baseQuery.taxonomy) {
          baseQuery.taxonomy
            ? (baseMetricColumn.filter = { taxonomy: baseQuery.taxonomy[0] })
            : (baseMetricColumn.filter = {});
        }

        csvFormat.columns.push(baseMetricColumn);

        // Generate metric queries for row filters, default: age and gender
        // Transactions cannot be broken down by age or gender
        const breakdownByDimensions =
          baseQuery.taxonomy && baseQuery.taxonomy.length
            ? ['entity', 'taxonomy']
            : ['entity'];

        const totalQueryProperties = {
          breakdownByDimensions: [...breakdownByDimensions],
        };

        const totalQuery = cloneDeep({ ...baseQuery, ...totalQueryProperties });

        const metricQueryVariations = [totalQuery];

        if (item.metricGroupKey !== CSV_GEN_PAYLOAD_TYPES.SALES) {
          if (!excludeAgeAndGender) {
            const ageQueryProperties = {
              breakdownByDimensions: [...breakdownByDimensions, 'age'],
              ages: ageOptions,
            };

            const genderQueryProperties = {
              breakdownByDimensions: [...breakdownByDimensions, 'gender'],
              genders: genderOptions,
            };

            const ageAndGenderQueryProperties = {
              breakdownByDimensions: [
                ...breakdownByDimensions,
                'age',
                'gender',
              ],
              ages: ageOptions,
              genders: genderOptions,
            };

            const ageQuery = cloneDeep({ ...baseQuery, ...ageQueryProperties });

            const genderQuery = cloneDeep({
              ...baseQuery,
              ...genderQueryProperties,
            });

            const ageAndGenderQuery = cloneDeep({
              ...baseQuery,
              ...ageAndGenderQueryProperties,
            });
            metricQueryVariations.push(
              ageQuery,
              genderQuery,
              ageAndGenderQuery,
            );
          }
          if (!excludeStaff) {
            const roleQuery = cloneDeep({
              ...baseQuery,
              roles: ROLES,
              breakdownByDimensions: ['role', 'entity'],
            });
            metricQueryVariations.push(roleQuery);
          }
        }

        // Push all variants to the metric queries array
        metricQueries.push(...metricQueryVariations);
      });

      // Add metric requests to csv format
      metricRequests.push(...metricQueries);

      // Send request to SQL API
      const response = await generateCSVFromSQLQuery({
        csvFormat,
        metricRequests,
      });

      if (response && response.body) {
        const signedUrl = response.body.filter((o) => o.signedUrl)[0].signedUrl;

        const a = document.createElement('a');
        a.href = signedUrl;
        a.click();
      } else {
        errorToast({ message: 'Error generating CSV', timeout: 2500 });
      }
      trackEvent(DOWNLOAD_CSV_BY_DAY_BUTTON);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={disabled || loading}
      onClick={onDownloadByDay}
      icon={loading ? <Spinner size={18} /> : 'floppy-disk'}
    >
      <Desktop>
        {loading ? 'Loading...' : 'Export visible data by day (CSV)'}
      </Desktop>
      <Mobile>
        {loading ? 'Loading...' : 'Export visible data by day (CSV)'}
      </Mobile>
    </Button>
  );
};

export default DownloadByDayCSV;
