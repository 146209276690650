// @flow

import { Fragment } from 'react';
import ChartCell from './chart-cell';
import { TableRow } from './table';
import { isCellLocationBeingSelected } from '../../utils/metricsTableHelpers';

import * as PeriodModel from '../../models/period';
import type { breakdownByEnum } from '../../constants/graph-options';
import type { metricsTableT } from '../../utils/metricsTableHelpers';

type Props = {
  tableMetricsGrid: metricsTableT,
  breakdownBy: breakdownByEnum,
  setMetricPreviewImagesForModal(previews: string[]): void,
  toggleLocation: (location: string | string[]) => void,
  excludeStaff: boolean,
  period: PeriodModel.t,
  locationsSelectedForComparison: Array<string>,
  compareToPastPeriod: ?PeriodModel.comparePeriodT
};

const TableBodyRows = ({
  tableMetricsGrid,
  breakdownBy,
  setMetricPreviewImagesForModal,
  toggleLocation,
  excludeStaff,
  period,
  locationsSelectedForComparison,
  compareToPastPeriod
}: Props) => {
  return (
    <Fragment>
      {tableMetricsGrid.map((row, index) => {
        return (
          <TableRow key={`metrics_table_row:${index}`}>
            {row.map((cell, index) => (
              <ChartCell
                key={index}
                cell={cell}
                index={index}
                breakdownBy={breakdownBy}
                setMetricPreviewImagesForModal={setMetricPreviewImagesForModal}
                toggleLocation={toggleLocation}
                isLocationSelected={isCellLocationBeingSelected(
                  cell,
                  locationsSelectedForComparison
                )}
                excludeStaff={excludeStaff}
                period={period}
                compareToPastPeriod={compareToPastPeriod}
              />
            ))}
          </TableRow>
        );
      })}
    </Fragment>
  );
};

export default TableBodyRows;
