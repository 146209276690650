// @flow

import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { Popover, Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { ROLES } from '../../constants/user-roles';
import * as UserListModel from '../../models/user-list';

const RoleDescription = styled.div`
  opacity: 0.75;
`;
const RequiredLabel = styled.span`
  font-size: 0.9em;
  margin-left: 0.7em;
`;
// override the disabled styles for the Blueprint menu
const StyledMenu = styled(Menu)`
  .bp3-menu-item.bp3-disabled {
    color: #182026 !important;
  }

  .bp3-menu-item.bp3-disabled > .bp3-icon,
  .bp3-menu-item > .bp3-icon {
    color: #0d8050 !important;
  }
`;

type simpleRoleT = { id: number };

type Props = {
  selectedRoles?: simpleRoleT[] | UserListModel.rolesT,
  onClick(roleId: number): any,
  disabled?: boolean,
  updating?: boolean
};

const RolePicker = ({
  selectedRoles = [],
  disabled,
  onClick,
  updating
}: Props) => {
  const rolesForDisplay = Object.entries(cloneDeep(ROLES))
    .map(([key, role]) => {
      return {
        // $FlowFixMe -> Object.entries mixed type error
        label: role.label,
        // $FlowFixMe -> Object.entries mixed type error
        id: role.id,
        // $FlowFixMe -> Object.entries mixed type error
        description: role.description,
        // $FlowFixMe -> Object.entries mixed type error
        showAsOption: role.showAsOption,
        // $FlowFixMe -> Object.entries mixed type error
        required: role.required,
        selected: selectedRoles.find(
          selectedRole =>
            // $FlowFixMe -> Object.entries mixed type error
            selectedRole.name === role.match || selectedRole.id === role.id
        )
      };
    })
    .filter(r => r.showAsOption);

  const roleMenuLabel =
    rolesForDisplay
      .filter(r => r.selected)
      .map(r => r.label)
      .join(', ') || 'Select a role';

  return (
    <Popover disabled={disabled} position={Position.LEFT}>
      <Button
        loading={updating}
        text={roleMenuLabel}
        disabled={disabled}
        icon="caret-down"
      />
      <StyledMenu>
        {rolesForDisplay.map(role => (
          <MenuItem
            key={role.id}
            text={
              <div>
                <div>
                  {role.label}
                  {role.required && <RequiredLabel>(required)</RequiredLabel>}
                </div>
                <RoleDescription>{role.description}</RoleDescription>
              </div>
            }
            icon={role.selected ? 'tick' : 'blank'}
            onClick={() => onClick(role.id)}
            disabled={role.required || updating}
            shouldDismissPopover={false}
          />
        ))}
      </StyledMenu>
    </Popover>
  );
};

export default RolePicker;
