import * as EmailValidator from 'email-validator';
import { isEmpty } from 'lodash';
import {
  SEND_TYPE_SPECIFIC,
  CADENCE_HOURLY,
  CADENCE_MONTHLY,
  CADENCE_WEEKLY,
} from './consts';
import { getEmailsAsArray } from './getEmailsAsArray';

export const validate = (
  setErrors,
  selectedReport,
  selectedDistribution,
  selectedSchedule
) => {
  const newErrors = {};
  setErrors({});

  // validate the report selection
  if (!selectedReport || !selectedReport.id) {
    newErrors.report = 'Select a report';
  }
  if (selectedReport && selectedReport.id) {
    if (
      selectedReport.locationRestriction &&
      !selectedReport.locationRestriction.length
    ) {
      newErrors.report =
        'Select at least one location, or include all locations in report';
    }
  }

  // validate the distribution selection
  if (!selectedDistribution) {
    newErrors.distribution = 'Select a distribution type';
  }
  if (selectedDistribution.sendType === SEND_TYPE_SPECIFIC) {
    const emails =
      typeof selectedDistribution.toEmails === 'string'
        ? getEmailsAsArray(selectedDistribution)
        : selectedDistribution.toEmails;
    if (Array.isArray(emails) && emails.length) {
      // validate each email
      const allValid = emails.every((email) => EmailValidator.validate(email));
      if (!allValid) {
        newErrors.distribution = 'One or more email addresses are invalid';
      }
    } else {
      newErrors.distribution =
        'Enter one or more email addresses to send the report to';
    }
  }

  // validate the schedule selection
  if (!selectedSchedule.cadence) {
    newErrors.schedule = 'Select a schedule';
  } else {
    if (
      selectedSchedule.cadence !== CADENCE_HOURLY &&
      !selectedSchedule.sendHour &&
      !(selectedSchedule.sendHour > -1)
    ) {
      newErrors.schedule = 'Select the hour to send the report on';
    }
    if (
      [CADENCE_WEEKLY, CADENCE_MONTHLY].includes(selectedSchedule.cadence) &&
      !selectedSchedule.sendDay &&
      !(selectedSchedule.sendDay > -1)
    ) {
      newErrors.schedule = 'Select the day to send the report on';
    }
  }

  setErrors(newErrors);

  return isEmpty(newErrors);
};
