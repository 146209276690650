import styled from 'styled-components';

const Title = styled.h1`
  font-size: 1.8em;
  color: var(--report-heading-text);
  margin: 0;
  margin-right: 1rem;
`;

const OccupancyTitle = ({ children }) => <Title>{children}</Title>;

export default OccupancyTitle;
