// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import SingleImageDisplayer from '../image-displayer-menu/single-image-displayer';

const ImageContainer = styled.div``;

const ImagesWrapper = styled.div`
  margin: 0 1rem;

  ${ImageContainer} {
    margin-bottom: 1rem;
  }

  ${ImageContainer}:first-child {
    margin-top: 1rem;
  }

  ${ImageContainer}:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  setMetricPreviewImagesForModal: (recordings: string[]) => void,
  previewImages?: string[]
};

const LocationThumbnailTableMenu = ({
  setMetricPreviewImagesForModal,
  previewImages
}: Props) => {
  const isLocationThumbnailOpen = previewImages && previewImages.length > 0;
  return (
    <Fragment>
      <Dialog
        icon="camera"
        isOpen={isLocationThumbnailOpen}
        onClose={() => setMetricPreviewImagesForModal([])}
        title="All preview images"
      >
        <ImagesWrapper>
          {previewImages &&
            previewImages.length > 0 &&
            previewImages.map((img, i) => (
              <ImageContainer key={i}>
                <SingleImageDisplayer key={i} imgUrl={img} />
              </ImageContainer>
            ))}
        </ImagesWrapper>
      </Dialog>
    </Fragment>
  );
};

export default LocationThumbnailTableMenu;
