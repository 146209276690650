// @flow

import { Component } from 'react';
import styled from 'styled-components';
import { ButtonGroup, Button, Popover, Position } from '@blueprintjs/core';
import { InactiveButton, InactiveButtonIcon } from '../inactive-button';
import * as LocationModel from '../../models/location';
import FilterLocationsMenu from '../pickers/filter-locations-menu';
import { getFilterLocationsButtonText } from '../../utils/summaryPageHelpers';

const ButtonGroupStyled = styled(ButtonGroup)`
  flex-shrink: 0;
`;

type Props = {
  locations: LocationModel.t[],
  filteredLocations: string[],
  fetchingQueryData?: boolean,
  updateFilteredLocations(string[]): void,
  minimalistic?: boolean,
  defaultLabel?: string,
};

type State = {
  isLocationMenuOpen: boolean
};

class LocationFilterButtons extends Component<Props, State> {
  state = {
    isLocationMenuOpen: false
  };

  handleIsLocationMenuOpen = (value: boolean) => {
    this.setState({
      isLocationMenuOpen: value
    });
  };

  handleUpdateFilteredLocations = (filteredLocations: string[]) => {
    const { updateFilteredLocations } = this.props;
    this.handleIsLocationMenuOpen(false);

    updateFilteredLocations(filteredLocations);
  };

  render() {
    const {
      locations = [],
      filteredLocations = [],
      fetchingQueryData,
      minimalistic,
      defaultLabel
    } = this.props;
    const { isLocationMenuOpen } = this.state;

    return (
      <ButtonGroupStyled>
        {!minimalistic && (
          <InactiveButton>
            <InactiveButtonIcon icon="filter-list" />
            Locations
          </InactiveButton>
        )}
        {locations && locations.length > 0 && (
          <Popover
            isOpen={isLocationMenuOpen}
            onClose={() => this.handleIsLocationMenuOpen(false)}
            position={Position.BOTTOM}
          >
            <Button
              rightIcon="chevron-down"
              loading={fetchingQueryData}
              onClick={() => this.handleIsLocationMenuOpen(true)}
            >
              {getFilterLocationsButtonText(
                locations.length,
                filteredLocations.length,
                defaultLabel
              )}
            </Button>
            <FilterLocationsMenu
              locations={locations}
              filteredLocations={filteredLocations}
              updateFilteredLocations={this.handleUpdateFilteredLocations.bind(
                this
              )}
            />
          </Popover>
        )}
      </ButtonGroupStyled>
    );
  }
}

export default LocationFilterButtons;
