// @flow

import { User, authPkgConfig } from '@auravisionlabs/aura-pkg-auth-service';
import { AUTH_SERVICE_API_BASE } from '../config/vars';
import { getAuth0Client } from './auth0';

let authPkgUser;

export const initAuthPkgUser = async () => {
  const auth0Client = getAuth0Client();
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    const cacheFacade = {
      getKey: () => {}
    };

    try {
      authPkgUser = new User(token, cacheFacade, AUTH_SERVICE_API_BASE, console);
      await authPkgUser.refresh();
      return authPkgUser;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  return new Promise(() => {});
};

export const getAuthPkgUser = (): Object => authPkgUser;

export const userCanViewSettingsPage = async (): Promise<boolean> => {
  const { user } = authPkgConfig.resources;
  const { read } = authPkgConfig.authActions;

  if (authPkgUser) {
    try {
      const orgId = await authPkgUser.getPrimaryOrgId();

      const canCrud = await authPkgUser
        .can(user, read, { orgIds: [orgId] })
        .evaluate();

      return canCrud;
    } catch (e) {
      return false;
    }
  }

  return false;
};

export const userCanManageReports = async (): Promise<boolean> => {
  const { scheduledReportJob } = authPkgConfig.resources;
  const { create, update, delete: deleteAction } = authPkgConfig.authActions;

  if (authPkgUser) {
    try {
      const orgId = await authPkgUser.getPrimaryOrgId();

      const [canC, canU, canD] = await Promise.all([
        authPkgUser
          .can(scheduledReportJob, create, { orgIds: [orgId] })
          .evaluate(),
        authPkgUser
          .can(scheduledReportJob, update, { orgIds: [orgId] })
          .evaluate(),
        authPkgUser
          .can(scheduledReportJob, deleteAction, { orgIds: [orgId] })
          .evaluate(),
      ]);

      return canC && canU && canD;
    } catch (e) {
      return false;
    }
  }

  return false;
};

/**
 * Whether the user can manage (crud) all the locations provided.
 *
 * @param {string[]} locationIds a list of location IDs
 * @returns {boolean}
 */
export const userCanManageLocations = async (
  locationIds: string[]
): Promise<boolean> => {
  const { update } = authPkgConfig.authActions;

  if (authPkgUser) {
    try {
      const canU = await authPkgUser
        .can(authPkgConfig.resources.location, update, { ruleIds: locationIds })
        .evaluate();
      return canU;
    } catch (e) {
      return false;
    }
  }

  return false;
};
