// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as AuthActions from '../actions/auth';
import LogoutPage from '../components/pages/logout';

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      logout: AuthActions.logout
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(LogoutPage);
