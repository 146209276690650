const kernelTemplate = [1, 0.5, 0.25, 0.125, 0.05625];

const smooth1D = (arr, center, windowSize) => {
  // Get the kernel
  const kernel = [];
  for (let i = 0; i < arr.length; i++) {
    kernel[i] = arr[i] === null ? 0 : kernelTemplate[Math.abs(center - i)];
  }
  const sum = kernel.reduce((acc, item) => acc + item);
  const value = arr
    .map((item, i) => (item * kernel[i]) / sum)
    .reduce((acc, item) => acc + item);
  return value;
};

// windowSize must be odd
export const smoothData = (arr, windowSize) => {
  if (Array.isArray(arr)) {
    const newArr = [];

    arr.forEach((item, i) => {
      if (item === null) {
        newArr[i] = null;
      } else {
        const lower = Math.max(0, i - Math.floor(windowSize / 2));
        const upper = Math.min(i + Math.ceil(windowSize / 2), arr.length);
        newArr[i] = smooth1D(arr.slice(lower, upper), i - lower, windowSize);
      }
    });

    return newArr;
  }
  return arr;
};

export const normaliseData = (arr) => {
  const newArr = [];

  const max = [...arr]
    .flat()
    .filter((e) => !!e)
    .sort((a, z) => a - z)
    .slice(-1);

  arr.forEach((day, i) => {
    const newDayArr = [];

    day.forEach((item, j) => {
      if (item === null) {
        newDayArr[j] = null;
      } else {
        newDayArr[j] = item / max;
      }
    });

    newArr[i] = newDayArr;
  });

  return newArr;
};

export const assignHours = (arr, totalHours) => {
  if (arr.length > 0) {
    let remainingHours = totalHours;

    const allocation = [];
    const rankedHours = [];

    arr.forEach((day, dayIndex) =>
      day.forEach((hour, hourIndex) => {
        rankedHours.push({
          day: dayIndex,
          hour: hourIndex,
          value: hour,
          staff: null,
        });
      })
    );

    // sort from highest to lowest
    rankedHours.sort((a, z) => z.value - a.value);

    // if the first item is zero, then they all are
    if (rankedHours[0].value === null) {
      remainingHours = 0;
    }

    while (remainingHours > 0) {
      rankedHours[0].staff =
        rankedHours[0].staff === null ? 1 : rankedHours[0].staff + 1;
      remainingHours--;
      rankedHours.sort((a, z) => z.value / z.staff - a.value / a.staff);
    }

    rankedHours.forEach(({ day, hour, staff }) => {
      if (!allocation[day]) allocation[day] = [];
      allocation[day][hour] = staff;
    });

    return allocation;
  }
};

export const filterPredicate = (query, item) => {
  if (item && item.name) {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }
};

export const padData = (arr, earliestStart, latestEnd) =>
  arr.map((day) => {
    const newDay = [];
    for (let i = earliestStart; i < latestEnd; i++) {
      const hour = day[i];
      newDay[i] = typeof hour === 'undefined' ? null : hour;
    }
    return newDay.filter((e) => typeof e !== 'undefined');
  });
