import LogRocket from 'logrocket';
import { USE_LOGROCKET } from '../config/vars';

export const logrocketIdentify = user => {
  if (!USE_LOGROCKET) return false;

  const { email, name, organisationName, isBeingImpersonated } = user;
  const identifier = isBeingImpersonated ? `IMPERSONATED_${email}` : email;

  LogRocket.identify(identifier, {
    name,
    organisationName
  });
};

export const logrocketInit = () => {
  if (!USE_LOGROCKET) return false;
  LogRocket.init('aura-vision-labs/frontend');
};
