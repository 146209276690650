// @flow

import { Component } from 'react';
import styled from 'styled-components';
import { Popover, ButtonGroup, Button, Position } from '@blueprintjs/core';
import { comparisonPeriodPresetConfig } from './dates-presets-config';
import { getDateRangeLabelFormat } from '../../utils/dates';
import { fixTitleModifiers } from '../../utils/datesPresetsConfigHelpers';
import DatePickerWithConfirm from './date-picker-with-confirm';
import ComparisonDatePickerMenu from './comparison-date-picker-menu';
import * as PREVIOUS_PERIOD_PRESETS from '../../constants/previous-period-presets';
import { Backdrop } from './date-picker';
import { IS_EMAIL_VIEW } from '../../config/vars';

import * as PeriodModel from '../../models/period';
import type { compPeriodAllowedT } from './dates-presets-config';

const StyledButton = styled(Button)`
  font-size: 12px;
`;

const StyledText = styled.span`
  text-transform: lowercase;
`;

type Props = {
  period: PeriodModel.t,
  compareToPastPeriod: PeriodModel.comparePeriodT,
  comparisonDatePresetsAllowed: compPeriodAllowedT,
  currentSelectedComparisonDatePreset: PeriodModel.compareToPastPresetsT,
  handleComparisonPresetClick(period: PeriodModel.comparePeriodT): void,
  disableCompareDatesView?: boolean,
};

type State = {
  isComparisonDatePickerMenuOpen: boolean,
  isDatePickerWithConfirmOpen: boolean,
  displayDatePickerWithConfirmMenuButton: boolean,
};

class CompareDatePicker extends Component<Props, State> {
  state = {
    isComparisonDatePickerMenuOpen: false,
    isDatePickerWithConfirmOpen: false,
    displayDatePickerWithConfirmMenuButton: false,
  };

  componentDidMount() {
    if (
      this.props.currentSelectedComparisonDatePreset !==
      PREVIOUS_PERIOD_PRESETS.none
    ) {
      this.setDisplayDatePickerWithConfirmMenuButton(true);
    }
  }

  setDisplayDatePickerWithConfirmMenuButton(value: boolean) {
    this.setState({
      displayDatePickerWithConfirmMenuButton: value,
    });
  }

  setIsDatePickerWithConfirmOpen(value: boolean) {
    this.setState({
      isDatePickerWithConfirmOpen: value,
    });
  }

  setIsComparisonDatePickerMenuOpen(value: boolean) {
    this.setState({
      isComparisonDatePickerMenuOpen: value,
    });
  }

  handleDisplayDatePickerWithConfirmMenuButton(
    period: PeriodModel.comparePeriodT
  ) {
    if (period.active) {
      this.setDisplayDatePickerWithConfirmMenuButton(true);
    } else {
      this.setDisplayDatePickerWithConfirmMenuButton(false);
    }
  }

  handleIsDatePickerWithConfirmOpen() {
    const { isComparisonDatePickerMenuOpen, isDatePickerWithConfirmOpen } =
      this.state;

    if (isComparisonDatePickerMenuOpen) {
      this.setIsComparisonDatePickerMenuOpen(false);
    }

    this.setDisplayDatePickerWithConfirmMenuButton(true);
    this.setIsDatePickerWithConfirmOpen(!isDatePickerWithConfirmOpen);
  }

  handleIsComparisonDatePickerMenuOpen() {
    const { isComparisonDatePickerMenuOpen, isDatePickerWithConfirmOpen } =
      this.state;

    if (isDatePickerWithConfirmOpen) {
      this.setIsDatePickerWithConfirmOpen(false);
    }

    this.setIsComparisonDatePickerMenuOpen(!isComparisonDatePickerMenuOpen);
  }

  handleComparisonPresetClick(period: PeriodModel.comparePeriodT) {
    const { handleComparisonPresetClick } = this.props;

    this.setIsComparisonDatePickerMenuOpen(false);
    this.handleDisplayDatePickerWithConfirmMenuButton(period);

    handleComparisonPresetClick(period);
  }

  render() {
    const {
      period,
      compareToPastPeriod,
      comparisonDatePresetsAllowed,
      currentSelectedComparisonDatePreset,
      handleComparisonPresetClick,
      disableCompareDatesView,
    } = this.props;
    const {
      isDatePickerWithConfirmOpen,
      isComparisonDatePickerMenuOpen,
      displayDatePickerWithConfirmMenuButton,
    } = this.state;

    const comparisonDatePresetsAllowedToUse = disableCompareDatesView
      ? comparisonDatePresetsAllowed.filter(
          (preset) => preset !== PREVIOUS_PERIOD_PRESETS.custom
        )
      : comparisonDatePresetsAllowed;

    const presetConfig = comparisonPeriodPresetConfig[
      currentSelectedComparisonDatePreset
    ] || { title: 'Preset not found' };

    const rangeDateLabel =
      compareToPastPeriod &&
      compareToPastPeriod.selectedDates &&
      currentSelectedComparisonDatePreset !== PREVIOUS_PERIOD_PRESETS.none
        ? getDateRangeLabelFormat(
            compareToPastPeriod.selectedDates,
            presetConfig
          )
        : 'Select comparison period';

    return (
      <ButtonGroup>
        {(isComparisonDatePickerMenuOpen || isDatePickerWithConfirmOpen) && (
          <Backdrop
            onClick={() => {
              this.setIsDatePickerWithConfirmOpen(false);
              this.setIsComparisonDatePickerMenuOpen(false);
            }}
          />
        )}
        {IS_EMAIL_VIEW ? (
          <StyledText>
            {fixTitleModifiers(presetConfig.title, compareToPastPeriod)}
          </StyledText>
        ) : (
          <Popover
            isOpen={isComparisonDatePickerMenuOpen}
            content={
              <ComparisonDatePickerMenu
                period={period}
                comparisonDatePresetsAllowed={comparisonDatePresetsAllowedToUse}
                currentSelectedComparisonDatePreset={
                  currentSelectedComparisonDatePreset
                }
                handleComparisonPresetClick={this.handleComparisonPresetClick.bind(
                  this
                )}
                handleOpenDatePickerWithConfirm={this.handleIsDatePickerWithConfirmOpen.bind(
                  this
                )}
              />
            }
            position={Position.LEFT_BOTTOM}
          >
            <StyledButton
              text={fixTitleModifiers(presetConfig.title, compareToPastPeriod)}
              rightIcon="chevron-down"
              onClick={this.handleIsComparisonDatePickerMenuOpen.bind(this)}
            />
          </Popover>
        )}
        {!disableCompareDatesView && displayDatePickerWithConfirmMenuButton && (
          <Popover
            isOpen={isDatePickerWithConfirmOpen}
            content={
              <DatePickerWithConfirm
                // $FlowFixMe -> Cannot create `CompareDatePicker` element because property `active` is missing in  `explorerPeriodT` [1] but exists in  `explorerComparePeriodT` [2] in the first argument of property `handleComparisonPresetClick`.
                handlePresetClick={handleComparisonPresetClick}
                handleOpenDatePickerWithConfirm={this.handleIsDatePickerWithConfirmOpen.bind(
                  this
                )}
                currentPeriod={period}
                isComparisonPeriod
                currentCompareToPeriod={compareToPastPeriod}
              />
            }
            position={Position.LEFT_BOTTOM}
          >
            <StyledButton
              text={rangeDateLabel}
              onClick={this.handleIsDatePickerWithConfirmOpen.bind(this)}
            />
          </Popover>
        )}
      </ButtonGroup>
    );
  }
}

export default CompareDatePicker;
