// @flow

import {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from 'react';

import {
  WidgetOverflow,
  WidgetGrid,
  WidgetCells,
  WidgetBox,
  MetricText,
  MetricLinkText,
  Tooltip,
} from '../common/styles';
import { WidgetBodyWrapper } from '../common/styles';
import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';
import history from '../../../root/history';

import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import {
  getMetricChangeValueColorAndIcon,
  getRgbaWithDynamicAlpha,
  getColorPercentage,
  getTrafficLightColor,
} from '../../../utils/widgets-ui';

import { ThemeContext } from 'styled-components';
import { Button } from '@blueprintjs/core';
import { downloadData } from './download';
import { IS_EMAIL_VIEW } from '../../../config/vars';

// TODO widgetPropsT is duplicated because importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description?: string,
  /** Displays the loading state */
  loading?: boolean,
  progress?: number,
  /** Displays the error state */
  errors?: boolean,
  /** 'row' | 'table': whether to apply shading of cells with respect to numbers in the same row or in the whole table */
  shadeBy?: string,
  /** 'trafficLight': the colours to use on the table, overriding the theme default */
  colorScheme?: string,
  /** Whether to use accessible text */
  accessible?: boolean,
  /** The column to sort by (number, as string, or `label`) */
  sortByColumn?: string,
  /** The direction to sort the column (`asc` or `desc`) */
  sortDirection?: string,
};

type labelT = {
  label: string,
  link?: string,
  description?: string,
};

type Props = widgetPropsT & {
  /** The columns across the top */
  tableColumns: labelT[],
  /** The data to display */
  tableRows: {
    /** The label to display on the left */
    label: string,
    /** The link for the label on the left */
    link?: string,
    /** The description text */
    description?: string,
    /** The value to display in the cell */
    cols: {
      value: number,
      changeValue: number,
      unitType: string,
    }[],
  }[],
  /** The key from the row data */
  dataKey: string,
  children?: any,
};

const RowHeading = ({ description, label, link }) => {
  let wrapper;
  const contents = description ? (
    <Tooltip boundary="viewport" content={description}>
      {label}
    </Tooltip>
  ) : (
    label
  );

  if (link) {
    wrapper = (
      <MetricLinkText
        align="right"
        justifyContent="flex-end"
        margin="0 16px 0 0"
        onClick={() => history.push(link)}
        whiteSpace="normal"
      >
        {contents}
      </MetricLinkText>
    );
  } else {
    wrapper = (
      <MetricText
        align="right"
        justifyContent="flex-end"
        margin="0 16px 0 0"
        whiteSpace="normal"
      >
        {contents}
      </MetricText>
    );
  }

  return wrapper;
};

const ColHeading = ({ link, index, description, label }) => {
  return (
    <MetricText key={index} align="center">
      {link ? (
        <MetricLinkText
          key={index}
          align="center"
          justifyContent="center"
          onClick={() => history.push(link)}
        >
          {description ? (
            <Tooltip boundary="viewport" content={description}>
              {label}
            </Tooltip>
          ) : (
            label
          )}
        </MetricLinkText>
      ) : (
        <MetricText key={index} align="center" justifyContent="center">
          {description ? (
            <Tooltip boundary="viewport" content={description}>
              {label}
            </Tooltip>
          ) : (
            label
          )}
        </MetricText>
      )}
    </MetricText>
  );
};

const RowHeatmap = ({
  title,
  subtitle,
  description,
  tableColumns,
  tableRows,
  dataKey,
  loading,
  progress,
  errors,
  shadeBy = 'row',
  children,
  colorScheme,
  accessible,
  sortByColumn,
  sortDirection = 'desc',
}: Props) => {
  const theme = useContext(ThemeContext);
  const [extendedTableRows, setExtendedTableRows] = useState([]);
  let widgetColorScheme = useRef(colorScheme);
  let widgetAccessible = useRef(accessible);

  let sortByColumnNumber = parseInt(sortByColumn);
  if (isNaN(sortByColumnNumber) || sortByColumnNumber < 0) {
    sortByColumnNumber = -1;
  }

  useEffect(() => {
    let totalValues = [];
    let totalValuesArr = [];

    if (shadeBy === 'table') {
      totalValues = tableRows
        .map((data) =>
          data.cols
            .map((entry) => entry && entry[dataKey])
            .filter((value) => value !== null)
            .flat()
        )
        .flat();
    }

    if (shadeBy === 'col' && tableRows.length && tableRows[0].cols) {
      totalValuesArr = tableRows[0].cols.map((col, index) =>
        tableRows
          .map((row) => (row.cols[index] ? row.cols[index][dataKey] : null))
          .filter((value) => value !== null)
      );
    }

    let newTableRows = tableRows.map((data) => {
      const { label, link, description, cols } = data;

      if (shadeBy === 'row') {
        totalValues = cols
          .map((entry) => entry && entry[dataKey])
          .filter((value) => value !== null)
          .flat();
      }

      return {
        label,
        description,
        link,
        cols: cols.map((entry, index) => {
          if (!entry || typeof entry[dataKey] !== 'number') {
            return {
              ...entry,
              backgroundColor: theme ? theme.properties['--light-gray'] : null,
              prefix: '',
            };
          }
          let backgroundColor;
          let unitTypeToUse = entry.unitType;
          let prefixToUse = '';

          if (totalValuesArr.length) {
            totalValues = totalValuesArr[index];
          }

          if (typeof widgetColorScheme.current === 'undefined') {
            // Flow does not recognise that the array has been filtered and null values removed
            // $FlowFixMe
            const alpha = getColorPercentage(entry[dataKey], totalValues);
            backgroundColor = theme
              ? theme.properties['--heatmap-highlight']
              : '#fff';
            backgroundColor = getRgbaWithDynamicAlpha(backgroundColor, alpha);
          }

          if (dataKey === 'changeValue') {
            let { changeValue } = entry || 0;
            const { prefix } = getMetricChangeValueColorAndIcon(
              changeValue,
              theme
            );
            widgetColorScheme.current = 'trafficLight';
            prefixToUse = prefix;
            unitTypeToUse = 'percentage';
          }

          if (widgetColorScheme.current === 'trafficLight') {
            backgroundColor = getTrafficLightColor(
              entry[dataKey],
              totalValues,
              theme,
              false
            );
            widgetAccessible.current = true;
          }

          if (widgetColorScheme.current === 'reverseTrafficLight') {
            backgroundColor = getTrafficLightColor(
              entry[dataKey],
              totalValues,
              theme,
              true
            );
            widgetAccessible.current = true;
          }

          return {
            ...entry,
            backgroundColor,
            unitType: unitTypeToUse,
            prefix: prefixToUse,
          };
        }),
      };
    });

    if (
      newTableRows &&
      newTableRows.length &&
      typeof sortByColumn !== 'undefined' &&
      sortByColumn !== null
    ) {
      newTableRows = newTableRows.sort((a, b) => {
        let item1 = a;
        let item2 = b;

        if (sortDirection === 'desc') {
          item1 = b;
          item2 = a;
        }

        if (sortByColumn === 'label') {
          const label1 = item1.label.toUpperCase();
          const label2 = item2.label.toUpperCase();
          if (label1 < label2) {
            return -1;
          }
          if (label1 > label2) {
            return 1;
          }
        }

        if (
          sortByColumnNumber !== -1 &&
          sortByColumnNumber < item1.cols.length &&
          sortByColumnNumber < item2.cols.length
        ) {
          return (
            item1.cols[sortByColumnNumber].value -
            item2.cols[sortByColumnNumber].value
          );
        }

        return 0;
      });
    }

    setExtendedTableRows(newTableRows);
  }, [tableRows, tableColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WidgetWrapper
      loading={loading}
      progress={progress}
      errors={!dataKey || errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapper>
        <WidgetOverflow>
          <WidgetGrid rowGap={shadeBy === 'row' ? '8px' : 0}>
            <span />
            <WidgetCells>
              {tableColumns.map(({ label, link, description }, index) => {
                return (
                  <WidgetBox
                    padding="6px 0"
                    key={index}
                    align="center"
                    altAlign={'center'}
                    height={'auto'}
                  >
                    <ColHeading
                      index={index}
                      label={
                        sortByColumnNumber === index
                          ? `label ${sortDirection === 'asc' ? '▲' : '▼'}`
                          : label
                      }
                      link={link}
                      description={description}
                    />
                  </WidgetBox>
                );
              })}
            </WidgetCells>
            {extendedTableRows.map((rowData, index) => {
              const { label, description, link, cols } = rowData;
              return (
                <Fragment key={index}>
                  <RowHeading
                    label={label}
                    description={description}
                    link={link}
                  />
                  <WidgetCells gridGap={shadeBy === 'col' ? '8px' : 0}>
                    {cols.map((entry, colIndex) => {
                      const { backgroundColor, unitType, prefix } = entry;
                      return (
                        <WidgetBox
                          key={colIndex}
                          align="center"
                          backgroundColor={backgroundColor}
                          hasBorder={true}
                        >
                          <MetricText
                            margin="0"
                            align="center"
                            justifyContent="center"
                            width="100%"
                            color={theme ? theme.properties['--white'] : null}
                            accessible={widgetAccessible.current}
                          >
                            {`${prefix}${formatNumbersWithUnits(
                              entry[dataKey],
                              unitType
                            )}`}
                          </MetricText>
                        </WidgetBox>
                      );
                    })}
                  </WidgetCells>
                </Fragment>
              );
            })}
          </WidgetGrid>
        </WidgetOverflow>
      </WidgetBodyWrapper>
      {!IS_EMAIL_VIEW && (
        <Button
          className="save"
          onClick={() =>
            downloadData(tableColumns, extendedTableRows, dataKey, title)
          }
          icon="floppy-disk"
        >
          CSV
        </Button>
      )}
      {children}
    </WidgetWrapper>
  );
};

export default RowHeatmap;
