import download from 'downloadjs';
import { trackEvent, CLICK } from '../../../services/mixpanel';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import { sanitiseString } from '../../../utils/summaryPageHelpers';

export const downloadData = (columns, rows, dataKey, filename) => {
  // column headings
  let payload = `,${columns
    .map(({ label }) => sanitiseString(label))
    .join(',')}\r\n`;

  // data
  rows.forEach((row) => {
    const { label, cols } = row;
    payload += `${sanitiseString(label)},${cols
      .map((entry) => {
        const { unitType, prefix } = entry;
        return `${prefix}${formatNumbersWithUnits(entry[dataKey], unitType)}`;
      })
      .join(',')}\r\n`;
  });

  const fullFilename = filename ? `${filename}.csv` : 'widget-data.csv';
  download(payload, fullFilename, 'text/csv');
  trackEvent(CLICK, {
    element: 'row widget CSV download',
  });
};
