module.exports = {
  resources: {
    user: 'user',
    rule: 'rule',
    role: 'role',
    capability: 'capability',
    capabilityRole: 'capability-role',
    roleUser: 'role-user',
    userOrg: 'user-org',
    userRole: 'user-role',
    userRule: 'user-rule',
    adoptedUser: 'adopted-user',
    adminRole: 'admin-role',
    adminRoleUser: 'admin-role-user',
    adminRoleCapability: 'admin-role-capability',
    orgUser: 'org-user',
    inviteUser: 'invite-user',
    bootstrapCache: 'bootstrap-cache',
    impersonate: 'impersonate',
    areaContext: 'areaContext',
    lineContext: 'lineContext',
    statElemsConfig: 'statElemsConfig',
    cameraServer: 'cameraServer',
    heatmap: 'heatmap',
    location: 'location',
    locationTransaction: 'location-transaction',
    recording: 'recording',
    recordingSegment: 'recording-segment',
    recordingHeatmap: 'recording-heatmap',
    recordingSources: 'recording-sources',
    recordingProcessingConfig: 'recording-processingConfig',
    recordingStatElems: 'recording-statElems',
    recordingConfiguration: 'recording-configuration',
    report: 'report',
    segment: 'segment',
    visitorEvent: 'visitor-event',
    organisation: 'organisation',
    processingConfig: 'processingConfig',
    ruleEvaluation: 'ruleEvaluation',
    dashboardReport: 'dashboardReport',
    deviceIOTAllCommands: 'device-iot-all-commands',
    deviceIOTSingleCommand: 'device-iot-single-command_',
    scheduledReportJob: 'scheduledReportJob',
    taxonomyMeta: 'taxonomyMeta',
    message: 'message',
  },
  authActions: {
    create: 'create',
    update: 'update',
    delete: 'delete',
    read: 'read',
  },
};
