// @flow

import { connect } from 'react-redux';
import Training from '../components/pages/training';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  };
}

export default connect(mapStateToProps)(Training);
