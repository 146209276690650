// @flow

export const previousDay = 'previousDay';
export const previousWeek = 'previousWeek';
export const previousMonth = 'previousMonth';
export const previousQuarter = 'previousQuarter';
export const previousYear = 'previousYear';
export const sameWeekLastYear = 'sameWeekLastYear';
export const previousPeriod = 'previousPeriod';
export const custom = 'custom';
export const none = 'none';

export type previousPeriodPresetsEnum =
  | 'previousDay'
  | 'previousWeek'
  | 'previousMonth'
  | 'previousQuarter'
  | 'previousYear'
  | 'sameWeekLastYear'
  | 'previousPeriod'
  | 'custom'
  | 'none';
