// @flow

import { isCurrentDateInSchedule } from '../utils/dates';

import {
  getDwellAreaContextsForLocations,
  filterForLocationsWithAreaContexts,
} from '../utils/live-occupancy';

import * as RecordingModel from './recording';
import type { dwellAreaContextsForLocationsT } from '../utils/live-occupancy';

export type transactionT = {
  index: string,
  quantity: number,
};

export type openTimeT = {
  day: number,
  startHour: number,
  startMinute: number,
  stopHour: number,
  stopMinute: number,
  isClosed: boolean,
};

export type t = {
  id: string,
  name: string,
  coordinates: string,
  metadata: string[],
  organisation: string,
  liveOccupancyConfiguration?: {
    liveOccupancyStatsActive: boolean,
    locationCapacity: number,
    occupancyDerivedVia: string,
  },
  status: string,
  currency?: string,
  timezone?: string,
  openingTimes?: openTimeT[],
};

type emptyT = Object;

export const create = (args: t): t => args;

export const findLocation = (
  locationId: string,
  locations: t[]
): t | emptyT => {
  return locations.find((location) => location.id === locationId) || {};
};

export const getLocationName = (locationId: string, locations: t[]): string => {
  const location = findLocation(locationId, locations);
  return location.name || 'Unknown location';
};

export const getLocationLatLng = (
  locationId: string,
  locations: t[]
): {
  lat: string | null,
  lng: string | null,
} => {
  const location = findLocation(locationId, locations);
  if (!location.coordinates)
    return {
      lat: null,
      lng: null,
    };
  const [lat, lng] = location.coordinates.split(',');
  return { lat, lng };
};

export const filterForLocationsWithLiveOccupancyStatsActive = (
  locations: t[]
): t[] => {
  return locations.filter(
    (loc) =>
      loc.liveOccupancyConfiguration &&
      loc.liveOccupancyConfiguration.liveOccupancyStatsActive
  );
};

export const getLocationsWithLiveOccupancyActive = (
  locations: t[],
  recordings: RecordingModel.t[]
): t[] => {
  const locationWithAreaContextsRecordings = getDwellAreaContextsForLocations(
    locations,
    recordings
  );
  const locationsWithAreaContexts = filterForLocationsWithAreaContexts(
    locationWithAreaContextsRecordings
  );

  const newLocations = locationsWithAreaContexts.map((loc) => loc.location);

  const locationsWithLiveOccupancyActive =
    filterForLocationsWithLiveOccupancyStatsActive(newLocations);

  return locationsWithLiveOccupancyActive;
};

export const getLiveOccupancyCapacity = (location: t): number => {
  return location.liveOccupancyConfiguration
    ? location.liveOccupancyConfiguration.locationCapacity
    : 0;
};

// TODO: fix this to fix live occupancy pages
// export const isLocationOpen = (
//   location: dwellAreaContextsForLocationsT
// ): boolean => {
//   if (location.areaContexts && location.areaContexts.length > 0) {
//     const firstRecordingData = location.areaContexts[0].recordingData;
//     const isShopOpen = isCurrentDateInSchedule(
//       firstRecordingData.uploadingSchedule,
//       firstRecordingData.timezone
//     );

//     return isShopOpen;
//   }

//   return false;
// };
