// @flow

import { useState } from 'react';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import ContextOccupancyRow from './context-occupancy-row';
import LocationErrorRow from '../location-error-row';
import TaxonomyManager from './taxonomy-manager';
import * as RecordingModel from '../../../../models/recording';
import * as LocationModel from '../../../../models/location';
import * as ActiveMetricsModel from '../../../../models/active-metrics';
import LocationThumbnailTableMenu from '../../../locations-thumbnails-table-menu';
import {
  checkIfLocationIsValid,
  getOccupancyErrors
} from '../../../../utils/live-occupancy';
import {
  getUrlHashAsObject,
  convertFromObjectToUrlHash
} from '../../../../utils/urlHelpers';
import { getRandomInt } from '../../../../utils/generalHelpers';

const StyledTable = styled.table`
  width: 100%;

  td {
    text-align: center;
    padding: 1rem;
  }
`;
const LeftAlignTh = styled.th`
  text-align: left;
`;

type Props = {
  recordings: RecordingModel.t[],
  location: LocationModel.t,
  excludeStaff: boolean,
  taxonomyMetas: Object[],
  isDemoUser: boolean,
};

const WithinLocationOccupancyTab = ({
  recordings,
  location,
  excludeStaff,
  taxonomyMetas,
  isDemoUser = false,
}: Props) => {
  const locationConfig = checkIfLocationIsValid({ location, recordings });
  const occupancyError = isDemoUser ? null : getOccupancyErrors(locationConfig);
  const [expandedPreviewImages, setExpandedPreviewImages] = useState<string[]>(
    []
  );

  const getInitialActiveMetricsState = () => {
    const emptyActiveMetrics = {
      [subMenuKey]: {
        taxonomies: []
      }
    };

    try {
      const currentUrlState = getUrlHashAsObject(window.location.hash);
      if (!currentUrlState || !currentUrlState.activeMetrics)
        return emptyActiveMetrics;
      return currentUrlState.activeMetrics;
    } catch {
      return emptyActiveMetrics;
    }
  };

  const subMenuKey = 'x'; // this can be set to anything since we're not using the whole metrics menu here
  const [activeMetrics, setActiveMetrics] = useState<ActiveMetricsModel.t>(
    // $FlowIgnore - the TaxonomyMenu component needs refactoring to allow more basic usage like here
    getInitialActiveMetricsState()
  );

  if (occupancyError && occupancyError.fatal) {
    return (
      <LocationErrorRow
        title={location.name}
        errorText={occupancyError.message}
      />
    );
  }

  // TODO: re-add this when fixing the live-occupancy page
  const contexts = [];//RecordingModel.getTaxonomyContextsFromRecordings(recordings);
  const uniqTaxonNames = uniqBy(contexts.map(ctx => ctx.taxonomies).flat());
  const anyTaxonsSelected = (activeMetrics[subMenuKey].taxonomies || []).length > 0;

  const handleActiveMetricsChange = newActiveMetrics => {
    setActiveMetrics(newActiveMetrics);
    const currentUrlState = getUrlHashAsObject(window.location.hash);
    if (!currentUrlState) return;
    const newUrlState = { ...currentUrlState, activeMetrics: newActiveMetrics };
    window.location.hash = convertFromObjectToUrlHash(newUrlState);
  };

  const getCapacityFromTaxonomyMetas = taxonomyName => {
    const tm = taxonomyMetas.find(tm => tm.taxonomy === taxonomyName);
    if (tm) return tm.capacity;
  };

  return (
    <>
      <TaxonomyManager
        uniqTaxonNames={uniqTaxonNames}
        activeMetrics={activeMetrics}
        setActiveMetrics={handleActiveMetricsChange}
        subMenuKey={subMenuKey}
      />
      {anyTaxonsSelected && (
        <StyledTable>
          <thead>
            <tr>
              <LeftAlignTh>Zone name</LeftAlignTh>
              <th>Max occupancy</th>
              <th>Current occupancy</th>
              <th>% occupancy</th>
            </tr>
          </thead>
          <tbody>
            {(activeMetrics[subMenuKey].taxonomies || []).map(taxonomyName => (
              <ContextOccupancyRow
                contexts={contexts.filter(
                  ctx =>
                    taxonomyName === ctx.taxonomies ||
                    !!ctx.taxonomies.find(t => t.startsWith(`${taxonomyName}:`))
                )}
                key={taxonomyName}
                taxonomyName={taxonomyName}
                setExpandedPreviewImages={setExpandedPreviewImages}
                excludeStaff={excludeStaff}
                capacity={isDemoUser ? getRandomInt(10, 30) : getCapacityFromTaxonomyMetas(taxonomyName)}
                isDemoUser={isDemoUser}
              />
            ))}
          </tbody>
        </StyledTable>
      )}
      <LocationThumbnailTableMenu
        setMetricPreviewImagesForModal={setExpandedPreviewImages}
        previewImages={expandedPreviewImages}
      />
    </>
  );
};

export default WithinLocationOccupancyTab;
