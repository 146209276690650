// @flow

import { Component } from 'react';
import { map } from 'awaity/esm';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { Button, Intent } from '@blueprintjs/core';

import SettingsPageHeader from '../settings-page-header';
import { Wrapper, TablesBody, TablesWrapper } from '../settings-page-styles';
import Layout from '../../../layouts/default-logged-in';
import ActiveUsersTable from '../../../settings-tables/active-users';
import InvitedUsersTable from '../../../settings-tables/invited-users';
import InviteUserMenu from '../../../invite-user-menu';

import { errorToast } from '../../../../utils/toaster';
import { USER_TYPE } from '../../../../constants/user-types';
import * as AuthAPI from '../../../../services/auth-api';
import { trackEvent, ENTER_PAGE } from '../../../../services/mixpanel';

import type { BrowserHistory } from 'history/createBrowserHistory';
import * as UserModel from '../../../../models/user';
import * as LocationModel from '../../../../models/location';
import * as PageSettingsModel from '../../../../models/page-settings';
import * as UserListModel from '../../../../models/user-list';
import { PanelHeader } from '../../../panel';

const InviteButton = styled(Button)`
  &&& {
    display: block;
    margin-left: auto;
  }
`;

const sortUsersFn = (a, b) => (a.email > b.email ? 1 : -1);

type Props = {
  locations: LocationModel.t[],
  history: BrowserHistory,
  settings: PageSettingsModel.userSettingsT,
  editSettings(settings: PageSettingsModel.userSettingsT): void,
  user: UserModel.t
};

type State = {
  pageLoadError: boolean,
  isLoading: boolean,
  isInviteMenuOpen: boolean,
  permissionToViewUsers: boolean,
};

class SettingsUsers extends Component<Props, State> {
  state = {
    pageLoadError: false,
    isLoading: false,
    isInviteMenuOpen: false,
    permissionToViewUsers: true,
  };

  async componentDidMount() {
    trackEvent(ENTER_PAGE, 'SETTINGS_USERS');

    const { users } = this.props.settings;

    try {
      if (!users || users.length === 0) {
        this.fetchAllUsers();
      }
    } catch (e) {
      this.setState({ pageLoadError: true });
    }
  }

  async fetchAllUsers() {
    const { settings, editSettings } = this.props;

    this.setIsLoading(true);

    const [normalUsers, invitedUsers, adoptedUsers] = await Promise.all([
      this.getUsersList(USER_TYPE.NORMAL),
      this.getUsersList(USER_TYPE.ADOPTED),
      this.getUsersList(USER_TYPE.INVITED),
    ]);
    const newUsers = [...normalUsers, ...invitedUsers, ...adoptedUsers];

    if (normalUsers || invitedUsers) {
      const newSettings = {
        ...settings,
        users: newUsers.sort(sortUsersFn)
      };

      editSettings(newSettings);
      this.setIsLoading(false);
    }
  }

  async getUsersList(userListType: UserListModel.userListTypeEnumT) {
    this.setState({
      permissionToViewUsers: true,
    });
    try {
      const { organisationId } = this.props.user;

      let apiMethod;
      switch (userListType) {
        case USER_TYPE.NORMAL:
          apiMethod = AuthAPI.getActiveUsers;
          break;
        case USER_TYPE.ADOPTED:
          apiMethod = AuthAPI.getAdoptedUsers;
          break;
        case USER_TYPE.INVITED:
          apiMethod = AuthAPI.getInvitedUsers;
          break;
        default:
          throw new Error('Unknown userListType supplied');
      }

      const { data } = await apiMethod(organisationId);

      if (data && data.length > 0) {
        const updatedUsersData = await this.getRolesAndRulesForUsers(data);

        const users: UserListModel.t = updatedUsersData.map((user: UserListModel.userT) => ({
          ...user,
          userListType
        }))
        // hide AV users from the list
        .filter(user => !user.email.includes('@auravision.ai'));

        return users;
      }
    } catch (e) {
      console.error(e);
      this.setState({
        permissionToViewUsers: false,
      });
    }

    return [];
  }

  async getRolesAndRulesForUsers(
    users: UserListModel.t
  ): Promise<UserListModel.t> | UserListModel.t {
    const { organisationId } = this.props.user;

    if (users && users.length > 0) {
      const updatedUsers = await map(users, async user => {
        const newUser = cloneDeep(user);

        // Skip getting the rules for now to speed it up and trigger fewer requests

        // try {
        // const rules = await AuthAPI.getRulesForUser(user.id, organisationId);
        // if (rules && rules.data) {
        //   newUser.rules = rules.data;
        // }
        // } catch (e) {
        //   console.error(`Failed to fetch rules for user ${user.id}`);
        // }

        try {
          const roles = await AuthAPI.getRolesForUser(user.id, organisationId);
          if (roles && roles.data) {
            newUser.roles = roles.data;
          }
        } catch (e) {
          console.error(`Failed to fetch roles for user ${user.id}`);
        }

        return newUser;
      });

      return updatedUsers;
    }

    return users;
  }

  removeSingleUserFromTable(userId: number) {
    const { settings, editSettings } = this.props;

    const users = [...settings.users.filter(u => u.id !== userId)];

    editSettings({
      ...settings,
      users
    });
  }

  async deleteUser(userId: number, userType: UserListModel.userListTypeEnumT) {
    try {
      if (userType === USER_TYPE.NORMAL || userType === USER_TYPE.INVITED) {
        await AuthAPI.deleteUser(userId);
      } else if (userType === USER_TYPE.ADOPTED) {
        const { organisationId } = this.props.user;
        await AuthAPI.deleteAdoptedUser(userId, organisationId);
      }

      errorToast({
        message: 'User removed successfully.',
        intent: Intent.SUCCESS
      });

      this.removeSingleUserFromTable(userId);
    } catch (e) {
      errorToast({
        message: 'User coudn\'t be removed.',
        intent: Intent.WARNING
      });
    }
  }

  setIsLoading(value: boolean) {
    this.setState({
      isLoading: value
    });
  }

  setIsInviteMenuOpen(value: boolean) {
    this.setState({
      isInviteMenuOpen: value
    });
  }

  async updateSingleUsersRolesInTable(userToUpdate: UserListModel.userT) {
    const { user, settings, editSettings } = this.props;
    const { organisationId } = user;

    const { data: newRoles } = await AuthAPI.getRolesForUser(
      userToUpdate.id,
      organisationId
    );

    const users = [
      ...settings.users.filter(u => u.id !== userToUpdate.id),
      { ...userToUpdate, roles: newRoles }
    ].sort(sortUsersFn);

    editSettings({
      ...settings,
      users
    });
  }

  async addRole(userToUpdate: UserListModel.userT, roleId: number) {
    try {
      const { organisationId } = this.props.user;

      await AuthAPI.createUserRole(userToUpdate.id, roleId, organisationId);
      await this.updateSingleUsersRolesInTable(userToUpdate);

      errorToast({
        message: 'User role updated successfully',
        intent: Intent.SUCCESS
      });
    } catch (e) {
      errorToast({
        message: 'User role couldn\'t be changed.',
        intent: Intent.WARNING
      });
    }
  }

  async removeRole(userToUpdate: UserListModel.userT, roleId: number) {
    try {
      const { organisationId } = this.props.user;

      await AuthAPI.deleteUserRole(userToUpdate.id, roleId, organisationId);
      await this.updateSingleUsersRolesInTable(userToUpdate);

      errorToast({
        message: 'User role updated successfully',
        intent: Intent.SUCCESS
      });
    } catch (e) {
      errorToast({
        message: 'User role couldn\'t be changed.',
        intent: Intent.WARNING
      });
    }
  }

  render() {
    const { pageLoadError, isLoading, isInviteMenuOpen } = this.state;
    const { history, settings, user } = this.props;
    const { users } = settings;

    const normalAndAdoptedUsers = users.filter(
      ({ userListType }) =>
        userListType === USER_TYPE.NORMAL || userListType === USER_TYPE.ADOPTED
    );
    const invitedUsers = users.filter(
      ({ userListType }) => userListType === USER_TYPE.INVITED
    );

    return (
      <Layout>
        <SettingsPageHeader history={history} />
        {pageLoadError && (
          <Wrapper>
            <TablesBody>
              Could not load this page. You may not have the correct permissions
            </TablesBody>
          </Wrapper>
        )}
        {!pageLoadError && (
          <Wrapper>
            <TablesWrapper>
              {this.state.permissionToViewUsers && (<PanelHeader>
                <InviteButton
                  text="Invite New User"
                  icon="plus"
                  intent="primary"
                  onClick={() => this.setIsInviteMenuOpen(true)}
                />
              </PanelHeader>)}

              <TablesBody>
                <ActiveUsersTable
                  loading={isLoading}
                  users={normalAndAdoptedUsers}
                  currentUser={user}
                  deleteUser={this.deleteUser.bind(this)}
                  addRole={this.addRole.bind(this)}
                  removeRole={this.removeRole.bind(this)}
                  permission={this.state.permissionToViewUsers}
                />
              </TablesBody>

              {!isLoading && invitedUsers.length !== 0 && (
                <TablesBody>
                  <InvitedUsersTable
                    loading={isLoading}
                    users={invitedUsers}
                    currentUser={user}
                    deleteUser={this.deleteUser.bind(this)}
                    addRole={this.addRole.bind(this)}
                    removeRole={this.removeRole.bind(this)}
                  />
                </TablesBody>
              )}
            </TablesWrapper>

            <InviteUserMenu
              isOpen={isInviteMenuOpen}
              currentUser={user}
              closeMenu={() => this.setIsInviteMenuOpen(false)}
              onSendInviteFinished={this.fetchAllUsers.bind(this)}
            />
          </Wrapper>
        )}
      </Layout>
    );
  }
}

export default SettingsUsers;
