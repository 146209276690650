// @flow

import { uniq } from 'lodash';
import { getInterpolatedColor } from './color';

export const DISPLAY_CHART_TYPE = {
  LINE: 'line',
  BAR: 'bar'
};

export type displayChartTypeEnum = 'line' | 'bar';

const MINIMUM_ALPHA = 0.4;

export const getMetricChangeValueColorAndIcon = (
  number: number,
  theme: Object,
): {
  color: string,
  icon: string,
  prefix: string
} => {
  if (theme) {
    if (number > 0) {
      return { prefix: '+', color: theme.properties['--positive'], icon: 'upload' };
    }

    if (number < 0) {
      return { prefix: '', color: theme.properties['--negative'], icon: 'download' };
    }

    if (number === 0) {
      return { prefix: '', color: theme.properties['--heading-text'], icon: 'blank' };
    }

    return { prefix: '', color: theme.properties['--heading-text'], icon: 'blank' };
  }

  return {
    prefix: '',
    color: '',
    icon: 'blank'
  };
};

export const getRgbaWithDynamicAlpha = (rgb: string, alpha: number) =>
  rgb.replace(/[\d.]+\)$/g, `${alpha})`);

export const getColorPercentage = (value: number, totalValues: number[]) => {
  if (totalValues.length === 1) return 0.7; // nothing to compare to, return an arbitrary number
  if (uniq(totalValues).length === 1) return 0.7; // all values are the same, no logic can be applied, return the arbitrary number

  const max = Math.max(...totalValues);
  const min = Math.min(...totalValues);

  const someValuesBelowZero = totalValues.some(value => value <= 0);
  const allValuesBelowZero = totalValues.every(value => value <= 0);

  if (someValuesBelowZero)
    return (
      Math.abs(
        ((1 - MINIMUM_ALPHA) * value) / (allValuesBelowZero ? min : max)
      ) + MINIMUM_ALPHA
    );

  return ((value - min) / (max - min)) * (1 - MINIMUM_ALPHA) + MINIMUM_ALPHA;
};

export const getTrafficLightColor = (value: number, totalValues: number[], theme: Object, reverse: boolean) => {
  const neutral = theme.properties['--neutral'];
  const positive = theme.properties['--positive'];
  const negative = theme.properties['--negative'];

  const max = Math.max(...totalValues);
  const min = Math.min(...totalValues);

  // only one value
  if (totalValues.length === 1) return neutral;

  // approximate median
  const median = totalValues.sort((a, z) => a - z)[Math.floor(totalValues.length / 2)];
  const difference = Math.abs(value - median);
  const range = value < median ? median - min : max - median;
  let color;

  if (reverse) {
    color = value < median ? positive : negative;
  } else {
    color = value < median ? negative : positive;
  }

  if (range === 0) return neutral;

  return getInterpolatedColor(neutral, color, difference / range);
};
