// @flow

import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN, USE_MIXPANEL } from '../config/vars';
import {
  CHANGE_COMPARISON_DATES,
  CHANGE_METRICS,
  CHANGE_QUERY_DATES,
  CHANGE_SPLIT_BY_DEMOGRAPHICS,
  DOWNLOAD_CSV_BUTTON,
  DOWNLOAD_CSV_BY_DAY_BUTTON,
  ENTER_PAGE,
  ANALYTICS_QUERY,
  CLICK,
} from '../constants/mixpanel-events';

import * as UserModel from '../models/user';

// helper so we can use one import statement to get these
// constants, as well as the trackEvent function
export {
  CHANGE_QUERY_DATES,
  CHANGE_COMPARISON_DATES,
  CHANGE_SPLIT_BY_DEMOGRAPHICS,
  DOWNLOAD_CSV_BUTTON,
  DOWNLOAD_CSV_BY_DAY_BUTTON,
  CHANGE_METRICS,
  ENTER_PAGE,
  ANALYTICS_QUERY,
  CLICK,
} from '../constants/mixpanel-events';

let IS_TRACK_AVAILABLE = false;

export const mixpanelIdentify = ({ model }: { model: UserModel.t }) => {
  const isUserBeingImpersonated = UserModel.isBeingImpersonated(model);
  if (!isUserBeingImpersonated) {
    IS_TRACK_AVAILABLE = true;
    mixpanelInit();
  } else {
    IS_TRACK_AVAILABLE = false;
    return null;
  }

  if (!model || !IS_TRACK_AVAILABLE || !USE_MIXPANEL) {
    return null;
  }

  const userId = UserModel.getId(model);
  const email = UserModel.getEmail(model);
  const name = UserModel.getName(model);
  const organisationName = UserModel.getOrganisationName(model);
  const organisationId = UserModel.getOrganisationId(model);

  mixpanel.identify(userId);
  mixpanel.people.set({
    $email: email,
    USER_ID: userId,
    $name: name,
    ORGANISATION_NAME: organisationName,
    ORGANISATION_ID: organisationId
  });
};

export const mixpanelInit = () => {
  if (!IS_TRACK_AVAILABLE || !USE_MIXPANEL) {
    return null;
  }
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: false
  });
};

export const trackEvent = (eventType: string, args: any) => {
  if (!IS_TRACK_AVAILABLE || !USE_MIXPANEL) return null;

  switch (eventType) {
    case CHANGE_COMPARISON_DATES:
      {
        const {
          selectedStart,
          selectedEnd,
          comparisonSelectedStart,
          comparisonSelectedEnd
        } = args;

        mixpanel.track(CHANGE_COMPARISON_DATES, {
          selectedStart: selectedStart,
          selectedEnd: selectedEnd,
          comparisonSelectedStart: comparisonSelectedStart,
          comparisonSelectedEnd: comparisonSelectedEnd
        });
      }
      break;
    case CHANGE_QUERY_DATES:
      {
        const { selectedDates } = args;

        mixpanel.track(CHANGE_QUERY_DATES, {
          selectedStart: selectedDates.start,
          selectedEnd: selectedDates.end
        });
      }
      break;
    case ENTER_PAGE:
      mixpanel.track(ENTER_PAGE, { pageName: args });
      break;
    case ANALYTICS_QUERY:
      const { path, body, responseStatus } = args;
      const {
        excludeStaff,
        locations = {},
        taxonomies,
        aggregation,
        period
      } = JSON.parse(body);
      mixpanel.track(ANALYTICS_QUERY, {
        path,
        responseStatus,
        excludeStaff,
        locations,
        taxonomies,
        aggregation,
        period
      });
      break;
    case CHANGE_METRICS:
    case CHANGE_SPLIT_BY_DEMOGRAPHICS:
    case DOWNLOAD_CSV_BUTTON:
    case DOWNLOAD_CSV_BY_DAY_BUTTON:
    case CLICK:
    default: {
      mixpanel.track(eventType, args);
    }
  }
};
