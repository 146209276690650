import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { fetchHeatmaps, isValidFilter } from '../../actions/heatmap';
import { errorToast } from '../../utils/toaster';
import RecordingFilterPanel, {
  DateAndTypePanel,
} from './recording-filter-panel';
import { Spinner } from '@blueprintjs/core';
import HeatmapImageGrid from './heatmap-image-grid';
import { Panel } from '../panel';
import {
  ENTITY_TYPES,
  filterBreakdownsArray,
  filterTypesArray,
} from '../../constants/heatmaps';
import moment from 'moment';
import usePrevious from '../../hooks/use-previous';
import { SORTED_AGE_KEYS } from '../../constants/demographic-types';

type Props = {
  locations: LocationModel.t[],
  recordings: RecordingModel.t[],
  showStaffFilters: boolean,
  disableAgeAndGenderOnFrontEnd: boolean,
};

export type FilterOptions = {
  locationId: string,
  recordingId: string,
  breakdown: string,
  type: string[],
  start: string,
  end: string,
};

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: var(--widget-max-width);
  margin: auto;
`;

const StyledPanel = styled(Panel)`
  margin-top: 1rem;
`;

const LoadingSpinner = styled(Spinner)`
  margin: 2rem auto;
`;

const NoHeatmapsMessage = styled.p`
  margin: 2rem 1rem;
  text-align: center;
`;

const NoHeatmapsFound = () => (
  <NoHeatmapsMessage>
    No heatmaps found for the selected options.
  </NoHeatmapsMessage>
);

const getDayStart = (d) => {
  const dAsDate = new Date(d);
  dAsDate.setUTCHours(0, 0, 0, 0);
  return dAsDate;
};

const getDayEnd = (d) => {
  const dAsDate = new Date(d);
  dAsDate.setUTCHours(23, 59, 59, 999);
  return dAsDate;
};

const findRecordings = (locationId, recordings) =>
  recordings
    .filter((recording) => recording.location.id === locationId)
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));

const shouldFetchHeatmaps = (prevFilterOptions, filterOptions) =>
  filterOptions?.location?.id !== prevFilterOptions?.location?.id ||
  filterOptions?.selectedDate !== prevFilterOptions?.selectedDate;

const HeatmapViewer = ({ locations, recordings, showStaffFilters, disableAgeAndGenderOnFrontEnd }: Props) => {
  const [filterOptions, setFilterOptions] = useState({
    location: locations && locations.length ? locations[0] : {}, // store whole location object
    recording: findRecordings(locations[0].id, recordings)[0], // store whole recording object
    breakdown: filterBreakdownsArray[0], // Default breakdown
    type: filterTypesArray[0],
    selectedDate: moment().subtract(1, 'day').toISOString(), // Default to yesterday
  });
  const prevFilterOptions = usePrevious(filterOptions);
  const [isLoading, setIsLoading] = useState(false);
  const [heatmaps, setHeatmaps] = useState([]);
  const [visibleHeatmaps, setVisibleHeatmaps] = useState([]);
  const [filteredRecordings, setFilteredRecordings] = useState(
    findRecordings(locations[0].id, recordings),
  );

  // on load
  useEffect(() => {
    fetchHeatmapData();
  }, []);
  // After initial load, only fetch heatmaps if location or date changes
  useEffect(() => {
    if (
      prevFilterOptions &&
      shouldFetchHeatmaps(prevFilterOptions, filterOptions)
    ) {
      fetchHeatmapData();
    }
  }, [filterOptions]);

  useEffect(() => {
    // on load, filter heatmaps
    // filter heatmaps for all heatmap or filterOption changes
    filterVisibleHeatmaps(heatmaps, filterOptions);
  }, [filterOptions, heatmaps]);

  const fetchHeatmapData = async () => {
    const { selectedDate, location } = filterOptions;

    const date = window.isDemoOrg ? '2020-01-01T00:00:00Z' : selectedDate;

    const filter = {
      start: moment(getDayStart(date)).toISOString(),
      end: moment(getDayEnd(date)).toISOString(),
      heatmapType: filterTypesArray.flatMap((t) => t.key),
    };
    if (isValidFilter(filter)) {
      setIsLoading(true);
      // Call method from heatmaps actions -- yet to be written
      const response = await fetchHeatmaps(
        [location.id],
        ENTITY_TYPES.LOCATION,
        filter,
      );
      if (response) {
        setHeatmaps(response);
        setIsLoading(false);
      } else {
        errorToast({ message: 'Error fetching heatmaps', timeout: 2500 });
        setIsLoading(false);
      }
    } else {
      errorToast({ message: 'Invalid filter options', timeout: 2500 });
      setIsLoading(false);
    }
  };

  const handleFilterChange = (type, value) => {
    switch (type) {
      case 'location':
        setFilterOptions({
          ...filterOptions,
          location: value,
          recording: findRecordings(value.id, recordings)[0],
        });
        setFilteredRecordings(findRecordings(value.id, recordings));
        break;
      case 'recording':
        setFilterOptions({ ...filterOptions, recording: value });
        break;
      case 'breakdown':
        setFilterOptions({ ...filterOptions, breakdown: value });
        break;
      case 'type':
        setFilterOptions({ ...filterOptions, type: value });
        break;
      case 'date':
        setFilterOptions({
          ...filterOptions,
          selectedDate: moment(value).toISOString(),
        });
      default:
        break;
    }
  };

  const handleFilterChangeCallback = useCallback(handleFilterChange, [
    filterOptions,
    setFilterOptions,
  ]);

  const validBreakdown = (heatmap, breakdown) => {
    switch (breakdown) {
      case 'all':
        return !heatmap.age && !heatmap.role && !heatmap.gender;
      case 'age':
        return heatmap.age && heatmap.age.length > 0;
      case 'gender':
        return heatmap.gender && heatmap.gender.length > 0;
      case 'role':
        return (
          heatmap.role &&
          (heatmap.role === 'staff' || heatmap.role === 'customer')
        );
      default:
        break;
    }
  };

  const filterVisibleHeatmaps = (heatmaps, filterOptions) => {
    const filtered = heatmaps.filter(
      (heatmap) =>
        // filter by recording, heatmap type and breakdown
        heatmap.recording_id === filterOptions.recording.id &&
        heatmap.heatmap_type === filterOptions.type.key &&
        validBreakdown(heatmap, filterOptions.breakdown.key),
    );
    filtered.sort(
      (a, b) => SORTED_AGE_KEYS.indexOf(a.age) - SORTED_AGE_KEYS.indexOf(b.age),
    );
    setVisibleHeatmaps(filtered);
  };

  return (
    <Wrapper>
      <RecordingFilterPanel
        locations={locations}
        recordings={filteredRecordings}
        filterOptions={filterOptions}
        updateFilterOptions={handleFilterChangeCallback}
        showStaffFilters={showStaffFilters}
        disableAgeAndGenderOnFrontEnd={disableAgeAndGenderOnFrontEnd}
      />
      <DateAndTypePanel
        filterOptions={filterOptions}
        updateFilterOptions={handleFilterChangeCallback}
      />
      <StyledPanel>
        {isLoading && <LoadingSpinner size="25" />}
        {!isLoading &&
        (heatmaps.length === 0 || visibleHeatmaps.length === 0) ? (
          <NoHeatmapsFound />
        ) : (
          !isLoading && <HeatmapImageGrid heatmapItems={visibleHeatmaps} />
        )}
      </StyledPanel>
    </Wrapper>
  );
};

export default HeatmapViewer;
