module.exports.HttpClientException = class extends Error {
  constructor(message, request, response, httpClientRequest, childError) {
    super(message);
    this.name = 'HttpClientException';
    this.request = request;
    this.response = response;
    this.httpClientRequest = httpClientRequest;
    this.childError = childError;
  }
};
