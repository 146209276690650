// @flow

import OccupancyCard from '../occupancy-card';
import OccupancyTitle from '../ocuppancy-title';
import { Highlights, LiveFigure, TitleWrapper } from '../common';

const ContextsNotFoundRow = ({ taxonomyName }: { taxonomyName: string }) => (
  <>
    <TitleWrapper>
      <OccupancyTitle>{taxonomyName}</OccupancyTitle>
    </TitleWrapper>
    <Highlights>
      <OccupancyCard>
        <LiveFigure>
          <h3>No "{taxonomyName}" zones found in this location</h3>
        </LiveFigure>
      </OccupancyCard>
    </Highlights>
  </>
);

export default ContextsNotFoundRow;
