// @flow

import { useState, useEffect } from 'react';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import WidgetUI from '../../widgets-ui/demographic-rank';
import { getQueryPayloadType, buildPayload } from '../../../services/query-api';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';
import { prettyLabel } from '../../../utils/prettyLabel';
import { GENDERS, SORTED_AGE_KEYS } from '../../../constants/demographic-types';

const DemographicRankWidget = ({
  widget,
  availableMetrics,
  uiOptions = {},
}: WidgetPropsModel.demographicRankT) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [widgetDescription, setWidgetDescription] = useState('');
  const [rowsForUI, setRowsForUI] = useState([]);
  const [unitType, setUnitType] = useState('number');
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible(
    (vi) => uiOptions.disableViewportLoading || vi > 0
  );

  const loadWidgetData = async (metric) => {
    setError(false);
    setLoading(true);

    // build the request body and fetch data for each metric for each period
    const path = apiEndpointByMetricKey(metric.metricKey);
    const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
      metric.details
    );
    const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

    let taxonomies;
    if (metric.taxonomy) {
      taxonomies = [metric.taxonomy];
    } else if (metric.filter) {
      taxonomies = [`${metric.filter.key}:${metric.filter.value}`];
    }

    const payloadInput = {
      period: widget.period,
      locations: widget.locations,
      taxonomies,
      demographicFilter: { role: 'customer' }, // we're going to hardcode this for now too. This metric is kinda pointless without staff excluded
      genders: GENDERS,
      ages: SORTED_AGE_KEYS,
      aggregation: 'day', // we can hardcode for this widget because we know we don't need segments
      metricKey: metric.metricKey,
      breakdownByDimensions: ['age', 'gender'],
    };

    const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

    try {
      const response = await fetchSQLQuery(payloadInitialPeriod, path, {
        returnErrors: true,
        return404AsError: false,
        metricKey: metric.metricKey,
      });
      let compResponse;
      if (widget.comparisonPeriod && widget.comparisonPeriod.active) {
        const payloadComparisonPeriod = buildPayload(queryPayloadType, {
          ...payloadInput,
          period: widget.comparisonPeriod,
        });

        compResponse = await fetchSQLQuery(
          payloadComparisonPeriod,
          path,
          {
            returnErrors: true,
            return404AsError: false,
            metricKey: metric.metricKey,
          }
        );
      }

      const ages = [
        '65_100',
        '55_64',
        '45_54',
        '35_44',
        '25_34',
        '16_24',
        '0_15',
      ];

      const rows: Array<Object> = ages.map((age) => {
        const male = QueryResponseModel.getSummaryValue({
          response,
          metricKey: metric.metricKey,
          age,
          gender: 'male'
        });

        const female = QueryResponseModel.getSummaryValue({
          response,
          metricKey: metric.metricKey,
          age,
          gender: 'female'
        });

        let maleChangeValue = null;
        let femaleChangeValue = null;

        if (compResponse) {
          const compMale = QueryResponseModel.getSummaryValue({
            response: compResponse,
            metricKey: metric.metricKey,
            age,
            gender: 'male'
          });

          const compFemale = QueryResponseModel.getSummaryValue({
            response: compResponse,
            metricKey: metric.metricKey,
            age,
            gender: 'female'
          });

          maleChangeValue = prettyValueToCompare(
            compMale ? compMale : 0,
            male ? male : 0
          ).result;

          femaleChangeValue = prettyValueToCompare(
            compFemale ? compFemale : 0,
            female ? female : 0
          ).result;
        }

        return {
          age: age ? prettyLabel(age) : 'All ages',
          male: {
            value: male ? male : 0,
            changeValue: maleChangeValue,
          },
          female: {
            value: female ? female : 0,
            changeValue: femaleChangeValue,
          },
        };
      });

      setUnitType(QueryResponseModel.getUnitType(response, metric.metricKey));
      setRowsForUI(rows);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      console.error('API calls for Demographic Rank widget failed. ', e);
    }
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      // get the information from available metrics data for the metric
      const metric = {
        ...widget.metric,
        details: AvailableMetricsModel.findMetricDetails(
          availableMetrics,
          widget.metric.metricGroupKey,
          widget.metric.metricKey
        ),
      };

      if (!metric || !metric.details) {
        console.error(
          'No metric found in availableMetrics for the Demographic Rank widget'
        );
        setError(true);
      } else {
        setAutoTitle(`${getColumnLabel(metric, availableMetrics, true, true)}`);
        if (!uiOptions.disableDescription) {
          setWidgetDescription(
            AvailableMetricsModel.getMetricDescription(metric.details)
          );
        }
        loadWidgetData(metric);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget);

  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        title={title}
        subtitle={subtitle}
        rows={rowsForUI}
        description={widgetDescription}
        unitType={widget.overrideUnitType ? widget.overrideUnitType : unitType}
      />
    </div>
  );
};

export default DemographicRankWidget;
