import { Fragment } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import {
  Button,
  Menu,
  MenuItem,
  Popover,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core';

import {
  SEND_TYPE_ALL,
  SEND_TYPE_ALL_LABEL,
  SEND_TYPE_SPECIFIC,
  SEND_TYPE_SPECIFIC_LABEL,
} from '../helpers/consts';

const StyledFormGroup = styled(FormGroup)`
  margin-top: 1em;
`;

const getLabel = (sendType) => {
  if (sendType === SEND_TYPE_ALL) return SEND_TYPE_ALL_LABEL;
  if (sendType === SEND_TYPE_SPECIFIC) return SEND_TYPE_SPECIFIC_LABEL;
  return '';
};

const DistributionSelector = ({
  selectedDistribution,
  setSelectedDistribution,
}) => {
  const col2Icon = (sendType) =>
    isEmpty(selectedDistribution)
      ? undefined
      : selectedDistribution.sendType === sendType
      ? { icon: 'tick' }
      : { icon: 'blank' };

  return (
    <Fragment>
      <Popover>
        <Button
          rightIcon="caret-down"
          text={getLabel(selectedDistribution.sendType)}
        />
        <Menu>
          <MenuItem
            text={SEND_TYPE_ALL_LABEL}
            onClick={(e) =>
              setSelectedDistribution({
                sendType: SEND_TYPE_ALL,
              })
            }
            {...col2Icon(SEND_TYPE_ALL)}
          />
          <MenuItem
            text={SEND_TYPE_SPECIFIC_LABEL}
            onClick={(e) =>
              setSelectedDistribution({
                sendType: SEND_TYPE_SPECIFIC,
              })
            }
            {...col2Icon(SEND_TYPE_SPECIFIC)}
          />
        </Menu>
      </Popover>
      {selectedDistribution.sendType === SEND_TYPE_SPECIFIC && (
        <StyledFormGroup
          label="Enter the email addresses to send report to"
          helperText="Separate multiple emails with commas"
          labelFor="email-input"
        >
          <InputGroup
            id="email-input"
            placeholder="Email addresses to send to"
            value={selectedDistribution.toEmails}
            onChange={(e) =>
              setSelectedDistribution({
                sendType: SEND_TYPE_SPECIFIC,
                sendTypeLabel: SEND_TYPE_SPECIFIC_LABEL,
                toEmails: e.target.value,
              })
            }
          />
        </StyledFormGroup>
      )}
    </Fragment>
  );
};

export default DistributionSelector;
