export const HOME = '/';

export const REPORTS = '/reports';
export const SINGLE_REPORT = '/reports/:reportId';

export const SUMMARY = '/key-metrics';
export const HEATMAPS = '/heatmaps';
export const CAMERA_PREVIEW = '/camera-preview';
export const EXPLORER = '/explorer';
export const HEADCOUNT_PLANNER = '/headcount-planner';

export const LIVE_OCC = '/live';

export const LOGIN = '/login';
export const LOGOUT = '/logout';

export const SETTINGS = '/settings';
export const SETTINGS_USERS = '/settings/users';
export const SETTINGS_TOOLS = '/settings/tools';
export const SETTINGS_MANAGE_LOCATIONS = '/settings/manage/locations';

export const SETTINGS_MANAGE_SINGLE_LOCATION = '/settings/manage/location';
export const SETTINGS_MANAGE_SINGLE_CAMERA_SERVER = '/settings/manage/camera-server';
export const SETTINGS_MANAGE_SINGLE_RECORDING = '/settings/manage/camera';

export const TRAINING = '/training';

export const VIEW_TOKEN = '/token';
