import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  background: var(--light-blue);

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    flex-direction: column;
    max-width: 100vw;
  }
`;

const Content = styled.section`
  flex: 1;
`;

const DefaultLoggedIn = ({ children, location }) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
);

export default DefaultLoggedIn;
