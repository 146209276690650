// @flow

import { getDatesListInRangePeriod } from './dates';
import {
  AGGREGATION_PERIOD,
  MAX_DAYS_SUPPORTED,
  CHART_TYPE
} from '../constants/graph-options';

import * as PeriodModel from '../models/period';
import * as QueryApiRequestModel from '../models/query-api-request';
import * as PageSettingsModel from '../models/page-settings';
import type { chartTypeEnum } from '../constants/graph-options';

export type disabledChartTypesItemT = {
  chartType: chartTypeEnum,
  disabledMessage: string
};

export const getAggreationsButtonsBlocked = (
  period: PeriodModel.t
): QueryApiRequestModel.aggregationEnumT[] => {
  const blockedOptions = [];
  const { selectedDates } = period;

  if (selectedDates) {
    const numberIndexsForPeriod = getDatesListInRangePeriod(
      selectedDates,
      AGGREGATION_PERIOD.DAY
    ).length;

    if (numberIndexsForPeriod > MAX_DAYS_SUPPORTED[AGGREGATION_PERIOD.HOUR]) {
      blockedOptions.push(AGGREGATION_PERIOD.HOUR);
    }

    if (
      numberIndexsForPeriod >
      MAX_DAYS_SUPPORTED[AGGREGATION_PERIOD.QUARTER_HOUR]
    ) {
      blockedOptions.push(AGGREGATION_PERIOD.QUARTER_HOUR);
    }
  }

  return blockedOptions;
};

export const getChartTypeButtonsBlocked = (
  metrics: PageSettingsModel.metricItemT[]
): disabledChartTypesItemT[] => {
  const blockedButtons = [];

  if (metrics.length !== 1) {
    const configItem = {
      chartType: CHART_TYPE.REL_BAR,
      disabledMessage: 'Select just one metric to use the % Bar chart'
    };
    blockedButtons.push(configItem);
  }

  return blockedButtons;
};
