// @flow
import { useState, useEffect, Fragment, useContext } from 'react';
import { Checkbox } from '@blueprintjs/core';
import styled, { ThemeContext } from 'styled-components';

import {
  GoogleMap,
  HeatmapLayer,
  Marker,
  OverlayView
} from '@react-google-maps/api';

import {
  WidgetBodyWrapper,
} from '../common/styles';

import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';

import { getMetricChangeValueColorAndIcon } from '../../../utils/widgets-ui';
import { formatNumbersWithUnits } from '../../../utils/formatNumbers';
import type { unitTypeT } from '../../../models/query-response';

import {
  mapStyles,
  markerIconStyles,
  positiveGradient,
  negativeGradient
} from './map-styles';

const OverlayWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -100%);
`;

const OverlayBody = styled.div`
  padding: 1rem;
  background-color: var(--overlay-body-background);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  min-width: 250px;
`;

const OverlayButton = styled.button`
  cursor: pointer;
  border: none;
  color: var(--widget-heading-text);
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
  position: absolute;
  top: 2px;
  right: 2px;
`;

const OverlayLabel = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 4px;
`;

const OverlayText = styled.div`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  text-align: left;
`;

const LegendWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 1rem;
  background-color: var(--widget-background);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 999;
`;

const LegendBody = styled.div`
  align-items: center;
  display: flex;
  label {
    align-items: center;
    display: flex;
    margin-bottom: 0px;
  }
  label:nth-child(2) {
    margin-left: 16px;
  }
`;

// TODO widgetPropsT is duplicated becase importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean
};

type Props = widgetPropsT & {
  /** The points on the map to display */
  points: {
    label: string,
    lat: number,
    lng: number,
    value: number,
    changeValue: number
  }[],
  /** The unit type */
  unitType: unitTypeT,
  /** The key from the row data */
  dataKey: string,
};

const Map = ({
  title,
  subtitle,
  description,
  points,
  dataKey,
  unitType,
  loading,
  errors,
}: Props) => {
  const theme = useContext(ThemeContext);
  const [mapRef, setMapRef] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0
  });
  const [mapZoom, setMapZoom] = useState(0);
  const [heatmapPoints, setHeatmapPoints] = useState([]);
  const [positiveHeatmapPoints, setPositiveHeatmapPoints] = useState([]);
  const [negativeHeatmapPoints, setNegativeHeatmapPoints] = useState([]);
  const [currentMarker, setCurrentMarker] = useState(null);

  const [showPositiveHeatmap, setShowPositiveHeatmap] = useState(true);
  const [showNegativeHeatmap, setShowNegativeHeatmap] = useState(true);

  useEffect(() => {
    if (mapRef) {
      const formatHeatmapPoints = data => {
        return data.map((point, index) => {
          const { lat, lng, label, changeValue } = point;

          let colorToUse = theme ? theme.properties['--heatmap-highlight'] : null;
          let prefixToUse = '';
          let unitTypeToUse = unitType;
          if (dataKey === 'changeValue') {
            const { color, prefix } = getMetricChangeValueColorAndIcon(
              changeValue,
              theme,
            );
            colorToUse = color;
            prefixToUse = prefix;
            unitTypeToUse = 'percentage';
          }

          return {
            location: new window.google.maps.LatLng(lat, lng),
            weight: point[dataKey],
            label,
            index,
            value: point[dataKey],
            color: colorToUse,
            prefix: prefixToUse,
            unitType: unitTypeToUse
          };
        });
      };

      setHeatmapPoints(formatHeatmapPoints(points));

      setPositiveHeatmapPoints(
        formatHeatmapPoints(points.filter(point => point[dataKey] > 0))
      );

      setNegativeHeatmapPoints(
        formatHeatmapPoints(
          points
            .filter(point => point[dataKey] < 0)
            .map(point => {
              return {
                ...point,
                value: Math.abs(point.value),
                changeValue: Math.abs(point.changeValue),
                unitType,
              };
            })
        )
      );
    }
  }, [mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapRef) {
      // This is a bit gross, i'll see if there's a more functional way to do this
      let bounds = new window.google.maps.LatLngBounds();

      points.forEach(point => {
        const { lat, lng } = point;
        bounds.extend(new window.google.maps.LatLng(lat, lng));
      });

      mapRef.fitBounds(bounds);
    }
  }, [heatmapPoints]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMapLoaded = map => {
    setMapRef(map);
  };

  const handleMapZoom = () => {
    if (mapRef) {
      setMapZoom(mapRef.zoom);
    }
  };

  const handleMarkerClick = (location: any, index: number) => {
    setCurrentMarker(index);
    setMapZoom(8);
    setMapCenter(location);
  };

  const handleInfoWindowClose = () => {
    setCurrentMarker(null);
  };

  const handlePositiveCheckboxChange = () => {
    setShowPositiveHeatmap(!showPositiveHeatmap);
  };

  const handleNegativeCheckboxChange = () => {
    setShowNegativeHeatmap(!showNegativeHeatmap);
  };

  return (
    <WidgetWrapper
      loading={loading}
      errors={errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapper>
        {dataKey === 'changeValue' && (
          <LegendWrapper>
            <LegendBody>
              {positiveHeatmapPoints.length > 0 && (
                <Checkbox
                  checked={showPositiveHeatmap}
                  onChange={handlePositiveCheckboxChange}
                >
                  <OverlayText color={theme ? theme.properties['--map-green'] : null}>Positive change</OverlayText>
                </Checkbox>
              )}
              {negativeHeatmapPoints.length > 0 && (
                <Checkbox
                  checked={showNegativeHeatmap}
                  onChange={handleNegativeCheckboxChange}
                >
                  <OverlayText color={theme ? theme.properties['--map-red'] : null}>Negative change</OverlayText>
                </Checkbox>
              )}
            </LegendBody>
          </LegendWrapper>
        )}
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '500px'
          }}
          options={{
            styles: mapStyles,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
          }}
          center={mapCenter}
          zoom={mapZoom}
          onZoomChanged={handleMapZoom}
          onLoad={handleMapLoaded}
          onUnmount={() => {}}
        >
          {mapRef ? (
            <Fragment>
              {showPositiveHeatmap && (
                <HeatmapLayer
                  data={positiveHeatmapPoints}
                  options={{
                    radius: 20,
                    gradient:
                      dataKey !== 'changeValue'
                        ? null
                        : ['rgba(0, 255, 255, 0)', ...positiveGradient]
                  }}
                />
              )}
              {showNegativeHeatmap && (
                <HeatmapLayer
                  data={negativeHeatmapPoints}
                  options={{
                    radius: 20,
                    gradient: ['rgba(0, 255, 255, 0)', ...negativeGradient]
                  }}
                />
              )}
              {heatmapPoints.map((marker, index) => {
                const {
                  location,
                  label,
                  value,
                  prefix,
                  color,
                  unitType
                } = marker;
                return (
                  <Fragment key={index}>
                    <Marker
                      position={location}
                      icon={{
                        ...markerIconStyles,
                        path: window.google.maps.SymbolPath.CIRCLE
                      }}
                      onClick={() => handleMarkerClick(location, index)}
                    />
                    {index === currentMarker && (
                      <Fragment>
                        <OverlayView
                          position={location}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <OverlayWrapper>
                            <OverlayBody>
                              <OverlayButton
                                onClick={handleInfoWindowClose}
                                type="button"
                              >
                                x
                              </OverlayButton>
                              <OverlayLabel>{label}</OverlayLabel>
                              <OverlayText color={color}>
                                {`${prefix}${formatNumbersWithUnits(
                                  value,
                                  unitType
                                )}`}
                              </OverlayText>
                            </OverlayBody>
                          </OverlayWrapper>
                        </OverlayView>
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
            </Fragment>
          ) : null}
        </GoogleMap>
      </WidgetBodyWrapper>
    </WidgetWrapper>
  );
};

export default Map;
