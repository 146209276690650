// @flow
import { DIVIDER } from '../../date-and-comparison-date-selector/dates-presets-config';
import * as PERIOD_PRESETS from '../../../constants/period-presets';
import type { defaultPresetsAllowedT } from '../../date-and-comparison-date-selector/dates-presets-config';

export const datePresetsAllowed: defaultPresetsAllowedT = [
  PERIOD_PRESETS.lastFullDay,
  PERIOD_PRESETS.last7Days,
  PERIOD_PRESETS.lastFullWeek,
  PERIOD_PRESETS.lastFullUSWeek,
  PERIOD_PRESETS.weekToDate,
  DIVIDER,
  PERIOD_PRESETS.last4FullWeeks,
  PERIOD_PRESETS.last4FullUSWeeks,
  PERIOD_PRESETS.last30Days,
  PERIOD_PRESETS.lastFullMonth,
  PERIOD_PRESETS.monthToDate,
  DIVIDER,
  PERIOD_PRESETS.last3FullMonths,
  PERIOD_PRESETS.lastFullQuarter,
  PERIOD_PRESETS.quarterToDate,
  DIVIDER,
  PERIOD_PRESETS.lastFullYear,
  PERIOD_PRESETS.yearToDate,
];
