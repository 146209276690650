// @flow

import { Component } from 'react';
import { uniq } from 'lodash';
import styled from 'styled-components';
import LiveSection from './live-section';
import StatusSection from './status-section';
import Layout from '../../layouts/fullscreen';
import { liveOccupancy as liveOccupancyAPI } from '../../../services/live-api';
import {
  getLocationConfig,
  getAreasForUrlQueryFormat,
  getDwellAreaContextsForSingleLocation,
  generateIncrement
} from '../../../utils/live-occupancy';

import * as LocationModel from '../../../models/location';
import * as RecordingModel from '../../../models/recording';
import type { locationConfigT } from '../live-occupancy/location-occupancy-row';
import type { dwellAreaContextsForLocationsT } from '../../../utils/live-occupancy';
import { getRandomInt } from '../../../utils/generalHelpers';

const UPDATE_LIVE_TIMEOUT = 5000;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`;

type Props = {
  location: ?LocationModel.t,
  recordings: RecordingModel.t[],
  match: Object,
  isDemoUser: boolean,
};

type State = {
  location: locationConfigT,
  timers: number[],
  demoCurrentOccupancy: number,
};

class LiveOccPage extends Component<Props, State> {
  state = {
    location: {
      name: '',
      id: ''
    },
    timers: [],
    demoCurrentOccupancy: 0,
  };

  componentDidMount() {
    const { location } = this.props;

    if (location) {
      this.setDefaultLocation(location);

      if (this.props.isDemoUser) {
        this.setState({
          demoCurrentOccupancy: getRandomInt(0, location.liveOccupancyConfiguration ? location.liveOccupancyConfiguration.locationCapacity : 10)
        });
      }
    }

  }

  componentWillUnmount() {
    this.state.timers.forEach(timer => {
      window.clearTimeout(timer);
    });
  }

  setDefaultLocation(currentLocation: LocationModel.t) {
    const { location, recordings } = this.props;

    if (location) {
      const locationConfig = getLocationConfig({
        location: currentLocation,
        locations: [location],
        recordings
      });

      this.setState(
        {
          location: locationConfig
        },
        () => this.doFirstFetchForLocation()
      );
    }
  }

  doFirstFetchForLocation() {
    const { location, recordings } = this.props;

    if (location) {
      const locationsWithLiveOccupancyActive = this.props.isDemoUser ? [location] : LocationModel.getLocationsWithLiveOccupancyActive(
        [location],
        recordings
      );

      locationsWithLiveOccupancyActive.forEach(loc => {
        if (loc.id === location.id) {
          const locationWithAC = getDwellAreaContextsForSingleLocation(
            loc,
            recordings
          );
          this.generateAndSetTimeout(locationWithAC);
        }
      });
    }
  }

  generateAndSetTimeout(location: dwellAreaContextsForLocationsT) {
    const timer = window.setTimeout(
      () => this.updateValue(location),
      this.props.isDemoUser ? 2000 : UPDATE_LIVE_TIMEOUT
    );

    this.setState({
      timers: uniq([...this.state.timers, timer])
    });
  }

  addLocResToState(location: LocationModel.t, data: Object) {
    const newLocationConfig = getLocationConfig({ location, data });

    this.setState({
      location: newLocationConfig
    });
  }

  async updateValue(location: dwellAreaContextsForLocationsT) {
    if (!this.props.isDemoUser) {
      // TODO: re-enable this when fixing the live occupancy API
      // const isShopOpen = LocationModel.isLocationOpen(location);
      // if (isShopOpen) {
      //   const areasQueryUrlFormat = getAreasForUrlQueryFormat(location);
      //   if (areasQueryUrlFormat) {
      //     const res = await liveOccupancyAPI.fetchLiveOccupancyByArea(
      //       areasQueryUrlFormat
      //     );

      //     if (res) {
      //       this.addLocResToState(location.location, res);
      //       this.generateAndSetTimeout(location);
      //     }
      //   }
      // }
    } else if (this.props.isDemoUser) {
      const occupancy =
        this.state.demoCurrentOccupancy +
        generateIncrement(this.state.location);

      this.addLocResToState(location.location, {
        overallOccupancy: {
          occupancy: occupancy,
          occupancyStaff: Math.floor(occupancy * 0.2),
          occupancyCustomers: Math.ceil(occupancy * 0.8),
        },
      });
      this.generateAndSetTimeout(location);
    }
  }

  render() {
    const { location } = this.state;
    const { isDemoUser } = this.props;

    return (
      <Layout>
        <Wrapper>
          <LiveSection location={location} isDemoUser={isDemoUser} />
          <StatusSection location={location} isDemoUser={isDemoUser} />
        </Wrapper>
      </Layout>
    );
  }
}

export default LiveOccPage;
