// @flow

import { standardDateFormat } from '../constants/dates-formats';

import moment from 'moment';

export const getQuarterStartDateFromDate = (date: Date | string): ?string => {
  return moment(date)
    .startOf('quarter')
    .format(standardDateFormat);
};
