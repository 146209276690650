import { cloneDeep } from 'lodash';

export const makeAmendmentsToReport = (startingReport, amendments) => {
  return amendments.reduce((prevReport, currentAmendment) => {
    const {
      property,
      newValue,
      hierarchyLevel,
      hierarchyIndex
    } = currentAmendment;
    return updateReportConfigByHierarchy(
      prevReport,
      property,
      newValue,
      hierarchyLevel,
      hierarchyIndex
    );
  }, startingReport);
};

export const updateReportConfigByHierarchy = (
  report,
  property,
  newValue,
  hierarchyLevel,
  hierarchyIndex
) => {
  console.log(
    `set ${property} to`,
    newValue,
    `at hierarchyLevel ${hierarchyLevel}, and hierarchyIndex ${hierarchyIndex}`
  );

  const newReport = cloneDeep(report);

  if (hierarchyLevel === 0) {
    newReport.config[property] = newValue;
  }

  if (hierarchyLevel === 1) {
    newReport.config.sections[hierarchyIndex][property] = newValue;
  }

  return newReport;
};
