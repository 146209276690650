// @flow

import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

const TooltipStyled = styled(Tooltip2)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    margin-left: 1rem;
    opacity: 0.85;
  }
`;
const InformationIcon = styled(Icon)`
  padding: 1px;
`;

type Props = {
  description: string
};

const InformationSection = ({ description }: Props) => {
  if (!description) {
    return null;
  }

  return (
    <TooltipStyled content={description} placement="top">
      <InformationIcon icon="help" />
    </TooltipStyled>
  );
};

export default InformationSection;
