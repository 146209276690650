// @flow

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import CustomTooltip from './tooltip';
import { groupBy } from 'lodash';
import { getChartColors, getGenderColor } from '../../styles/variables';
import {
  prettyNumber,
  prettyPercentage
} from '@auravisionlabs/aura-pkg-ui-formatter';
import { formatNumbersWithUnits } from '../../utils/formatNumbers';
import { unitTypeLabeler } from '../../utils/prettyLabel';
import {
  axisTimeFormatter,
  getAxisInterval,
  splitActiveLinesByUnitType
} from '../../utils/graphHelpers';
import { CHART_DATA, AXIS_GROUPING } from '../../constants/graph-options';

import type { chartDataT, activeLinesT } from './index';
import type {
  aggregationEnum,
  axisGroupingEnum
} from '../../constants/graph-options';
import type { axisTypeT } from '../../utils/graphHelpers';

type Props = {
  chartData: chartDataT[],
  activeLines: activeLinesT[],
  aggregationPeriod?: aggregationEnum,
  axisGrouping: axisGroupingEnum,
  hasFilterApplied?: boolean,
  axisType?: axisTypeT,
  isCompareToPastActive?: boolean,
};

const LineGraph = ({
  chartData,
  activeLines,
  aggregationPeriod,
  axisGrouping,
  hasFilterApplied,
  axisType,
  isCompareToPastActive,
}: Props, chartRef: () => void) => {
  const theme = useContext(ThemeContext);
  const {
    filteredActiveLines,
    percentageActiveLines
  } = splitActiveLinesByUnitType(activeLines);
  const chartColors = getChartColors(theme);
  const getLineProps = (l: Object): Object => ({
    type: 'monotone',
    strokeWidth: '2',
    activeDot: { r: 6 },
    key: l.key,
    name: l.label,
    stroke: chartColors[l.key] || getGenderColor(l.key, theme) || l.color,
    dot: {
      r: 2,
      fill: chartColors[l.key] || getGenderColor(l.key, theme) || l.color
    },
    dataKey: l.key
  });

  return (<>
    <ResponsiveContainer width="100%" height={500}>
      <LineChart data={chartData} ref={chartRef ? chartRef : null}>
        <XAxis
          dataKey="index"
          tickFormatter={value =>
            axisTimeFormatter(value, aggregationPeriod, axisType)
          }
          interval={getAxisInterval(aggregationPeriod)}
        />
        {axisGrouping === AXIS_GROUPING.SINGLE &&
          filteredActiveLines.length > 0 && (
            <YAxis yAxisId="left" tickFormatter={prettyNumber} />
          )}
        {axisGrouping === AXIS_GROUPING.SINGLE &&
          percentageActiveLines.length > 0 && (
            <YAxis
              yAxisId="right"
              tickFormatter={prettyPercentage}
              orientation={filteredActiveLines.length === 0 ? 'left' : 'right'}
            />
          )}

        {axisGrouping === AXIS_GROUPING.BY_METRIC &&
          Object.entries(groupBy(activeLines, 'axisKey')).map(
            ([axisKey, lines], index) => {
              // $FlowIgnore - Object.entries thing
              const unitType = lines[0] ? lines[0].unitType : ''; // these lines are all the same metric so should have the same unitType
              const metricOnlyLabelForAxis =
                // $FlowIgnore - Object.entries thing
                lines[0] && lines[0].metricOnlyLabelForAxis
                  ? lines[0].metricOnlyLabelForAxis
                  : axisKey; // these lines are all the same metric so should have the same label

              return (
                <YAxis
                  key={axisKey}
                  yAxisId={`axis_${axisKey}`}
                  label={{
                    value: metricOnlyLabelForAxis,
                    angle: -90,
                    position: index % 2 === 0 ? 'insideLeft' : 'insideRight',
                    offset: 10
                  }}
                  width={80}
                  tickFormatter={val => formatNumbersWithUnits(val, unitType)}
                  orientation={index % 2 === 0 ? 'left' : 'right'}
                />
              );
            }
          )}

        {axisGrouping === AXIS_GROUPING.BY_UNIT &&
          Object.entries(groupBy(activeLines, 'unitType')).map(
            ([unitType, l], index) => {
              return (
                <YAxis
                  key={unitType}
                  yAxisId={`axis_${unitType}`}
                  label={{
                    value: unitTypeLabeler(unitType),
                    angle: -90,
                    position: index % 2 === 0 ? 'insideLeft' : 'insideRight',
                    offset: 10
                  }}
                  width={80}
                  tickFormatter={val => formatNumbersWithUnits(val, unitType)}
                  orientation={index % 2 === 0 ? 'left' : 'right'}
                />
              );
            }
          )}

        <CartesianGrid vertical={false} />
        <Tooltip
          formatter={prettyNumber}
          content={
            <CustomTooltip
              axisType={axisType}
              activeLines={activeLines}
              hasFilterApplied={hasFilterApplied}
              aggregationPeriod={aggregationPeriod}
              chartData={chartData}
              isCompareToPastActive={isCompareToPastActive}
            />
          }
        />
        <Legend />
        {axisGrouping === AXIS_GROUPING.SINGLE &&
          filteredActiveLines.map(l => (
            <Line
              {...getLineProps(l)}
              yAxisId="left"
              isAnimationActive={false}
              strokeDasharray={l.key.match(CHART_DATA.COMPARE) ? '5 5' : null}
            />
          ))}
        {axisGrouping === AXIS_GROUPING.SINGLE &&
          percentageActiveLines.map(l => (
            <Line
              {...getLineProps(l)}
              yAxisId="right"
              isAnimationActive={false}
              strokeDasharray={l.key.match(CHART_DATA.COMPARE) ? '5 5' : null}
            />
          ))}
        {axisGrouping === AXIS_GROUPING.BY_METRIC &&
          activeLines.map((l, index) => (
            <Line
              {...getLineProps(l)}
              yAxisId={`axis_${l.axisKey}`}
              isAnimationActive={false}
              strokeDasharray={l.key.match(CHART_DATA.COMPARE) ? '5 5' : null}
            />
          ))}
        {axisGrouping === AXIS_GROUPING.BY_UNIT &&
          activeLines.map((l, index) => (
            <Line
              {...getLineProps(l)}
              yAxisId={`axis_${l.unitType}`}
              isAnimationActive={false}
              strokeDasharray={l.key.match(CHART_DATA.COMPARE) ? '5 5' : null}
            />
          ))}
      </LineChart>
    </ResponsiveContainer></>
  );
};

// $FlowFixMe Flow does not support forwarding refs properly
export default React.forwardRef(LineGraph);
