// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import { SUMMARY, EXPLORER, USER_SETTINGS } from '../constants/page-settings';
import * as PageSettingsModel from '../models/page-settings';
import type { filterT } from '../models/active-metrics';

const editPageSettings = (
  settings:
    | PageSettingsModel.summaryT
    | PageSettingsModel.explorerT
    | PageSettingsModel.userSettingsT,
  pageName: string
): FluxStandardAction<string, *> => ({
  type: ACTIONS.EDIT_PAGE_SETTINGS,
  payload: {
    pageName,
    settings
  }
});

export const editSummaryPageSettings = (
  settings: PageSettingsModel.summaryT
): FluxStandardAction<string, *> => editPageSettings(settings, SUMMARY);

export type editSummaryPageActiveMetricsArgsT = {
  subMenuKey: string,
  metricElementKey?: string,
  taxonomy?: string,
  filter?: filterT
};

export const editSummaryPageActiveMetrics = ({
  subMenuKey,
  metricElementKey,
  taxonomy,
  filter
}: editSummaryPageActiveMetricsArgsT): FluxStandardAction<string, *> => {
  return {
    type: ACTIONS.EDIT_SUMMARY_PAGE_ACTIVE_METRICS,
    payload: {
      subMenuKey,
      metricElementKey,
      taxonomy,
      filter
    }
  };
};

export const clearSummaryPageActiveMetrics = (): FluxStandardAction<string, *> => {
  return {
    type: ACTIONS.CLEAR_SUMMARY_PAGE_ACTIVE_METRICS,
    payload: {},
  };
};

export const editExplorerPageSettings = (
  settings: PageSettingsModel.explorerT
): FluxStandardAction<string, *> => editPageSettings(settings, EXPLORER);

export const editUserSettingsPageSettings = (
  settings: PageSettingsModel.explorerT
): FluxStandardAction<string, *> => editPageSettings(settings, USER_SETTINGS);
