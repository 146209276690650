// @flow

import { Component } from 'react';
import styled, { keyframes, css, withTheme } from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { getChartColors } from '../../styles/variables';
import { tooltipTimeFormatter } from '../../utils/graphHelpers';
import { prettyPercentage } from '@auravisionlabs/aura-pkg-ui-formatter';
import {
  ordinalSuffix,
  dayOfWeekLabel,
  hourOfDayLabel,
  dowHodLabel,
} from '../../utils/dates';
import { formatNumbersWithUnits } from '../../utils/formatNumbers';
import {
  AGGREGATION_PERIOD,
  CHART_DATA,
  AXIS_TYPE,
} from '../../constants/graph-options';
import { getUnitTypeFromActiveLine } from '../../selectors/graphSelectors';

import type { ElementRef } from 'react';
import type { chartDataT, activeLinesT } from './index';
import type { aggregationEnum } from '../../constants/graph-options';
import type { axisTypeT } from '../../utils/graphHelpers';

const INNER_PADDING = 45;

const scrollUpAndDown = (props) => {
  const difference = props.linesWrapperHeight
    ? `-${
        props.linesWrapperHeight -
        props.linesWrapperParentHeight +
        INNER_PADDING
      }px`
    : '0px';

  return keyframes`
    0% {
      transform: translateY(0%);
    }

    50% {
      transform: translateY(${difference})
    }

    100% {
      transform: translateY(0%);
    }
  `;
};

const LinesWithAnimation = css`
  ${(props) => `
      animation-name: ${scrollUpAndDown(props)};
  `}
  animation-fill-mode: backwards;
  animation-duration: ${(props) => 5 + 0.3 * props.numberOfLines}s;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
`;
const LinesWithoutAnimation = css`
  height: fit-content;
`;
const AnimationWrapper = styled.div`
  position: relative;
  overflow-y: hidden;
`;
const LinesWrapper = styled.div`
  background: var(--dark-blue);
  height: fit-content;
  position: relative;
  ${(props) => {
    const isAnimationActive =
      typeof props.linesWrapperHeight === 'number' &&
      typeof props.linesWrapperParentHeight === 'number' &&
      props.linesWrapperHeight >= props.linesWrapperParentHeight;

    return isAnimationActive ? LinesWithAnimation : LinesWithoutAnimation;
  }}
`;
const Wrapper = styled.div`
  background: var(--dark-blue);
  position: relative;
  padding: 0.5em;
  color: #f0f3f8;
  border-radius: 2px;
  max-height: 70vh;
  overflow-y: hidden;

  &&& {
    z-index: 100;
  }
`;
const FilterIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
`;
const TooltipLabel = styled.h4`
  color: #f0f3f8;
  font-size: 0.95em;
  border-bottom: 1px solid #323f53;
  text-align: center;
  margin: 0;
  padding: 0 0 0.5em 0;
  font-weight: bold;
`;
const LineRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 0.9em;
`;
const LineColor = styled.div`
  background: ${(props) => props.color};
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;
const LineLabel = styled.div`
  padding-left: 0.5em;
`;
const LineValue = styled.div`
  padding-left: 1.5em;
  font-weight: bold;
`;
const LineChange = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding-left: 1.5em;
  color: ${(props) => props.color};
`;
const LineChangeValue = styled.div`
  padding-left: 0.5em;
  padding-right: 0.5rem;
`;

const getLineChangeDescriptionLabel = (aggregationPeriod?) => {
  if (aggregationPeriod === AGGREGATION_PERIOD.DAY) {
    return 'on previous day';
  } else if (aggregationPeriod === AGGREGATION_PERIOD.HOUR) {
    return 'on previous hour';
  } else if (aggregationPeriod === AGGREGATION_PERIOD.WEEK_ISO || aggregationPeriod === AGGREGATION_PERIOD.WEEK_US) {
    return 'on previous week';
  } else if (aggregationPeriod === AGGREGATION_PERIOD.MONTH) {
    return 'on previous month';
  }

  return '';
};

const getLineLabel = (
  line: Object,
  chartData?: chartDataT[],
  axisType?: axisTypeT,
  nonDateLabel: boolean,
  aggregationPeriod?: aggregationEnum,
  isCompareToPastActive?: boolean
) => {
  if (
    isCompareToPastActive &&
    chartData &&
    line.dataKey.match(CHART_DATA.COMPARE)
  ) {
    const index = chartData.findIndex(
      (singleChartData) => singleChartData.index === line.payload.index
    );
    if (index >= 0) {
      const dateBeingCompared = chartData[index].compareToPastIndex;
      const date = getToolTipLabelFormat({
        label: dateBeingCompared,
        axisType,
        nonDateLabel,
        aggregationPeriod,
      });
      const newName = line.name.replace(
        'Compared to:',
        `Previous period, ${date} - `
      );

      return newName;
    }
  }

  return line.name;
};

type getToolTipLabelFormatArgsT = {
  label?: number | string,
  axisType?: axisTypeT,
  nonDateLabel: boolean,
  aggregationPeriod?: aggregationEnum,
};

export const getToolTipLabelFormat = ({
  label,
  axisType,
  nonDateLabel,
  aggregationPeriod,
}: getToolTipLabelFormatArgsT): string => {
  const type = axisType || aggregationPeriod;
  if (type && type.toLowerCase() === AXIS_TYPE.DAY_OF_MONTH && typeof label === 'number') {
    return `${ordinalSuffix(label)} day of the month`;
  }
  if (type && type.toLowerCase() === AXIS_TYPE.DAY_OF_WEEK && typeof label !== 'undefined') {
    const weeksLabel = dayOfWeekLabel(label);
    return weeksLabel ? `${weeksLabel}` : `${label}`;
  }
  if (type && type.toLowerCase() === AXIS_TYPE.HOUR_OF_DAY && typeof label !== 'undefined') {
    return `${hourOfDayLabel(label)}`;
  }
  if (type && type.toLowerCase() === AXIS_TYPE.DOW_HOD && typeof label === 'string') {
    return `${dowHodLabel(label)}`;
  }
  if (!nonDateLabel && aggregationPeriod && typeof label === 'string') {
    return tooltipTimeFormatter(label, aggregationPeriod);
  }
  return label ? `${label}` : '';
};

type Props = {
  label?: number | string,
  aggregationPeriod?: aggregationEnum,
  payload?: any[],
  hasFilterApplied?: boolean,
  nonDateLabel?: boolean,
  axisType?: axisTypeT,
  formatter?: (args: any) => any,
  activeLines: activeLinesT[],
  compareToPastChartDataRaw?: chartDataT[],
  chartData?: chartDataT[],
  isCompareToPastActive?: boolean,
  theme: Object,
};

type State = {};

class Tooltip extends Component<Props, State> {
  linesWrappers: ?ElementRef<'div'>;

  render() {
    const {
      label,
      aggregationPeriod,
      payload = [],
      hasFilterApplied,
      nonDateLabel = false,
      axisType,
      formatter,
      activeLines,
      chartData,
      isCompareToPastActive,
      theme,
    } = this.props;

    const tooltipLabel = getToolTipLabelFormat({
      label,
      axisType,
      nonDateLabel,
      aggregationPeriod,
    });

    const linesWrapperHeight =
      this.linesWrappers && this.linesWrappers.offsetHeight;
    const linesWrapperParentHeight =
      this.linesWrappers &&
      this.linesWrappers.offsetParent &&
      // $FlowFixMe -> Flow thinks that linesWrapper does not have a offsetParent prop
      this.linesWrappers.offsetParent.offsetParent &&
      // $FlowFixMe -> Flow thinks that offsetParent does not have a offsetHeight prop
      this.linesWrappers.offsetParent.offsetParent.offsetHeight;

    const chartColors = getChartColors(theme);

    return (
      <Wrapper>
        {hasFilterApplied && (
          <FilterIcon>
            <Icon icon="filter-keep" iconSize="12" />
          </FilterIcon>
        )}
        <TooltipLabel>{tooltipLabel}</TooltipLabel>
        {payload && (
          <AnimationWrapper>
            <LinesWrapper
              numberOfLines={payload.length}
              innerRef={(c) => (this.linesWrappers = c)}
              linesWrapperHeight={linesWrapperHeight}
              linesWrapperParentHeight={linesWrapperParentHeight}
            >
              {payload &&
                payload.reverse().map((line) => {
                  const color = chartColors[line.dataKey] || line.color;
                  const changeValue =
                    line.payload[`${line.dataKey}${CHART_DATA.CHANGE}`];
                  const changePcValue = prettyPercentage(changeValue, 1);
                  let changeIcon = null;
                  let changeColor = null;
                  const showChange = !!changeValue && changeValue !== 0;

                  if (showChange && changeValue > 0) {
                    changeColor = '#3A9337';
                    changeIcon = 'arrow-top-right';
                  }
                  if (showChange && changeValue < 0) {
                    changeColor = '#FF7800';
                    changeIcon = 'arrow-bottom-right';
                  }

                  const value = line.payload[line.dataKey];
                  const activeLine =
                    activeLines &&
                    activeLines.find(
                      (activeLine) => activeLine.key === line.dataKey
                    );
                  const unitType = getUnitTypeFromActiveLine(activeLine);
                  let formattedValue = 0;

                  if (unitType) {
                    formattedValue = formatNumbersWithUnits(value, unitType);
                  } else if (formatter) {
                    formattedValue = formatter(value);
                  } else {
                    formattedValue = value;
                  }

                  const lineChangeDescriptionLabel = isCompareToPastActive
                    ? 'on previous period'
                    : getLineChangeDescriptionLabel(aggregationPeriod);

                  return (
                    <LineRow key={line.dataKey}>
                      <LineColor color={color} />
                      <LineLabel>
                        {getLineLabel(
                          line,
                          chartData,
                          axisType,
                          nonDateLabel,
                          aggregationPeriod,
                          isCompareToPastActive
                        )}
                      </LineLabel>
                      <LineValue>{formattedValue}</LineValue>
                      {showChange && (
                        <LineChange color={changeColor}>
                          <Icon icon={changeIcon} iconSize={12} />
                          <LineChangeValue>{changePcValue}</LineChangeValue>
                          {lineChangeDescriptionLabel}
                        </LineChange>
                      )}
                    </LineRow>
                  );
                })}
            </LinesWrapper>
          </AnimationWrapper>
        )}
      </Wrapper>
    );
  }
}

export default withTheme(Tooltip);
