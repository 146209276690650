// @flow

export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';

export const REFRESH_USER_REQUEST = 'REFRESH_USER_REQUEST';
export const REFRESH_USER_SUCCESS = 'REFRESH_USER_SUCCESS';
export const REFRESH_USER_ERROR = 'REFRESH_USER_ERROR';

export const FETCH_RECORDINGS_REQUEST = 'FETCH_RECORDINGS_REQUEST';
export const FETCH_RECORDINGS_SUCCESS = 'FETCH_RECORDINGS_SUCCESS';
export const FETCH_RECORDINGS_ERROR = 'FETCH_RECORDINGS_ERROR';

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const FETCH_HEATMAPS_REQUEST = 'FETCH_HEATMAPS_REQUEST';
export const FETCH_HEATMAPS_SUCCESS = 'FETCH_HEATMAPS_SUCCESS';
export const FETCH_HEATMAPS_ERROR = 'FETCH_HEATMAPS_ERROR';

export const FETCH_HEATMAP_SOURCE_URL_REQUEST =
  'FETCH_HEATMAP_SOURCE_URL_REQUEST';
export const FETCH_HEATMAP_SOURCE_URL_SUCCESS =
  'FETCH_HEATMAP_SOURCE_URL_SUCCESS';
export const FETCH_HEATMAP_SOURCE_URL_ERROR = 'FETCH_HEATMAP_SOURCE_URL_ERROR';

export const FETCH_QUERY_DATA_REQUEST = 'FETCH_QUERY_DATA_REQUEST';
export const FETCH_QUERY_DATA_SUCCESS = 'FETCH_QUERY_DATA_SUCCESS';
export const FETCH_QUERY_DATA_GENERAL_ERROR = 'FETCH_QUERY_DATA_GENERAL_ERROR';

export const EDIT_PAGE_SETTINGS = 'EDIT_PAGE_SETTINGS';

export const EDIT_SUMMARY_PAGE_ACTIVE_METRICS =
  'EDIT_SUMMARY_PAGE_ACTIVE_METRICS';
export const CLEAR_SUMMARY_PAGE_ACTIVE_METRICS = 'CLEAR_SUMMARY_PAGE_ACTIVE_METRICS';

export const FETCH_QUERY_DATA_METRICS_ERROR = 'FETCH_QUERY_DATA_METRICS_ERROR';
export const CLEAR_QUERY_RESPONSE_LIST = 'CLEAR_QUERY_RESPONSE_LIST';

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';

export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_ERROR = 'EDIT_LOCATION_ERROR';
