// @flow

import { LIVE_OCC_API_BASE, LIVE_OCC_API_KEY } from '../config/vars';
import { errorToast } from '../utils/toaster';
import history from '../root/history';
import { LOGOUT } from '../constants/routes';
import { UNKNOWN_FAIL } from '../constants/error-messages';
import { getAuth0Client } from '../services/auth0';

type callArgs = {
  path: string,
  fetchOptions?: Object,
  otherOptions?: {
    dontAddAuthorization?: boolean,
    disable401Toast?: boolean
  }
};

const call = async ({
  path,
  fetchOptions = {},
  otherOptions = {}
}: callArgs): Promise<any> => {
  const fetchOpts = {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const auth0Client = getAuth0Client();
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    if (token && !otherOptions.dontAddAuthorization) {
      fetchOpts.headers.Authorization = `Bearer ${token}`;
    }
  }

  return fetch((LIVE_OCC_API_BASE + path: string), (fetchOpts: Object))
    .then(async res => {
      if (res.ok) {
        return res;
      }

      // if response is 401 show a toast prompting the user to log back in
      if (res.status === 401 && !otherOptions.disable401Toast) {
        errorToast({
          message: 'Couldn\'t authenticate. You probably need to log back in',
          action: {
            text: 'Log in',
            onClick: e => history.push(LOGOUT)
          },
          timeout: 0
        });
        const responseBody = await res.json();
        console.error(responseBody);
      }

      const error: { response?: Object } = new Error(res.statusText);
      error.response = res;
      throw error;
    })
    .then(res => {
      return res.json();
    })
    .catch(error => {
      errorToast({
        message: UNKNOWN_FAIL
      });
    });
};

export const liveOccupancy = {
  fetchLiveOccupancyByArea: (areasIds: string) =>
    call({
      path: `/api/v1/live-occupancy-by-area?query=${areasIds}`,
      otherOptions: {
        dontAddAuthorization: true
      },
      fetchOptions: {
        method: 'GET',
        headers: {
          'x-api-key': LIVE_OCC_API_KEY
        }
      }
    })
};
