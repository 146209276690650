import { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  Elevation,
  FormGroup,
  InputGroup
} from '@blueprintjs/core';
import Layout from '../../layouts/default-logged-in';
import * as AuthAPI from '../../../services/auth-api';
import { errorToast } from '../../../utils/toaster';

const STANDARD_BLUR = '1.5px';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const Content = styled.div`
  position: relative;
  z-index: 110;
  padding: 1em;
  margin-top: 4em;
  display: flex;
  justify-content: center;
`;

const IntroText = styled.p`
  margin-bottom: 2em;
`;

const NewUserSplash = ({ user, refreshUser }) => {
  const [name, setName] = useState('');
  // const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);

  const updateUser = async () => {
    if (!name) {
      errorToast({
        message: 'Please enter your name.'
      });
      return;
    }
    if (name.length <= 2) {
      errorToast({
        message: 'Please enter a longer name.'
      });
      return;
    }

    setLoading(true);

    try {
      // update the name and status on the Users API
      await AuthAPI.user.update(user.id, {
        fullName: name,
        status: 'active',
        email: user.email,
        primaryOrgId: user.organisationId
      });

      refreshUser();
    } catch (e) {
      errorToast({
        message: 'Couldn\'t update. Please contact support.'
      });
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <Layout sidebarBlur={STANDARD_BLUR}>
      <Overlay />
      <Content>
        <Card interactive={true} elevation={Elevation.TWO}>
          <h3 className="bp3-heading">
            Welcome to the Aura Vision Data Dashboard
          </h3>
          <IntroText>
            Before we finalise your account, please update the following details
          </IntroText>

          <Card elevation={Elevation.ONE}>
            <FormGroup label="Your name" labelFor="name" labelInfo="(required)">
              <InputGroup
                onChange={e => setName(e.target.value)}
                type="text"
                id="name"
                value={name}
                placeholder="Your name"
                required
              />
            </FormGroup>
            {/*<FormGroup label="Your role" labelFor="role">
              <HTMLSelect
                id="role"
                onChange={e => setRole(e.target.value)}
                value={role}
                placeholder="Your role"
                options={[
                  '',
                  'Analytics/BI Team',
                  'Executive',
                  'Store Manager/Area Manager',
                  'Other'
                ]}
              />
            </FormGroup>*/}

            <Button loading={loading} intent="primary" onClick={updateUser}>
              Go
            </Button>
          </Card>
        </Card>
      </Content>
    </Layout>
  );
};

export default NewUserSplash;
