// @flow

import { Component } from 'react';
import { get, isEqual } from 'lodash';
import { Switch } from '@blueprintjs/core';
import Layout from '../../layouts/default-logged-in';
import DemographicBreakdownPicker from '../../pickers/demographic-breakdown';
import LocationFilterButtons from '../../location-filter-buttons';
import DateAndComparisonDateSelectors from '../../date-and-comparison-date-selector';
import MetricsTable from '../../metrics-table';
import * as Selectors from '../../../selectors';
import {
  trackEvent,
  CHANGE_COMPARISON_DATES,
  CHANGE_QUERY_DATES,
  CHANGE_SPLIT_BY_DEMOGRAPHICS,
  ENTER_PAGE,
} from '../../../services/mixpanel';

import { SUMMARY } from '../../../constants/page-settings';
import * as PREVIOUS_PERIOD_PRESETS from '../../../constants/previous-period-presets';

import {
  convertFromUrlHashToObject,
  convertFromObjectToUrlHash,
} from '../../../utils/urlHelpers';

import * as PeriodModel from '../../../models/period';
import * as LocationModel from '../../../models/location';
import * as QueryResponseListModel from '../../../models/query-response-list';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as RecordingModel from '../../../models/recording';
import * as PageSettingsModel from '../../../models/page-settings';
import * as ActiveMetricsModel from '../../../models/active-metrics';

import type { BrowserHistory } from 'history/createBrowserHistory';
import type { breakdownByEnum } from '../../../constants/graph-options';
import type { queryWrapperT } from '../../../actions/query-responses';
import type { editSummaryPageActiveMetricsArgsT } from '../../../actions/page-settings';

import {
  Row,
  Wrapper,
  DatePickerWrapper,
  TopBar,
  DemographicsBar,
  DemographicsPanelHeader,
  SwitchContainer,
} from './layout';
import Pagination from '../../pagination';
import styled from 'styled-components';
import { GENDERS, ROLES, SORTED_AGE_KEYS } from '../../../constants/demographic-types';

type Props = {
  fetchQueryResponses: (queryWrapper: queryWrapperT) => void,
  locations: LocationModel.t[],
  fetchingQueryData: boolean,
  settings: PageSettingsModel.summaryT,
  clearActiveMetrics: () => void,
  editActiveMetrics: (config: editSummaryPageActiveMetricsArgsT) => void,
  clearQueryResponseList: () => void,
  editSettings: (settings: PageSettingsModel.summaryT) => void,
  queryResponseList: QueryResponseListModel.t,
  availableMetrics: AvailableMetricsModel.t,
  recordings: RecordingModel.t[],
  showStaffFilters: boolean,
  showAgeAndGenderFilters: boolean,
  history: BrowserHistory,
};

type State = {
  breakdownBy: breakdownByEnum,
};

const StyledDemographicsPanelHeader = styled(DemographicsPanelHeader)`
  max-width: none;
`;

class SummaryPage extends Component<Props, State> {
  state = {
    breakdownBy: 'total_only',
  };

  componentDidMount() {
    trackEvent(ENTER_PAGE, 'SUMMARY');

    const hash = get(this.props, 'history.location.hash', '');

    if (hash) {
      // use the hash to set the state
      const data = convertFromUrlHashToObject(hash, SUMMARY);
      this.handleGenericUpdate(data, true, false);
    } else {
      // otherwise use the defaults - this sets up filteredLocations, demographicFilter, and
      // activeMetrics (those that are enabled on startup in availableMetrics)
      if (this.settingsAreEmpty()) this.setDefaultState();
    }
  }

  handleGenericUpdate = (
    settings: PageSettingsModel.summaryT,
    updateQueryResponseList?: boolean,
    updateHashUrl?: boolean
  ) => {
    this.props.editSettings(settings);

    if (updateQueryResponseList) {
      this.fetchAllQueryResponses(settings);
    }

    if (updateHashUrl) {
      this.updateHashUrl(settings);
    }
  };

  updateHashUrl = (state: PageSettingsModel.summaryT) => {
    const hash = convertFromObjectToUrlHash(state);
    this.props.history.push(hash);
  };

  fetchAllQueryResponses(settings: PageSettingsModel.summaryT) {
    const isComparisonActive =
      !!settings.compareToPast && !!settings.compareToPast.active;

    // This fetches single Locations
    this.fetchData(settings, false, false, true);
    if (isComparisonActive) {
      this.fetchData(settings, false, true);
    }

    if (settings.filteredLocations.length > 1) {
      // This fetches allLocations
      this.fetchData(settings, true, false);

      if (isComparisonActive) {
        this.fetchData(settings, true, true);
      }
    }
  }

  getVisibleLocations(
    settings: PageSettingsModel.summaryT,
    filteredLocations: string[]
  ): string[] {
    if (settings.itemsPerPage < 1) {
      return filteredLocations;
    }
    const start = settings.page * settings.itemsPerPage;
    const end = Math.min(
      (settings.page + 1) * settings.itemsPerPage,
      filteredLocations.length
    );
    return filteredLocations.slice(start, end);
  }

  fetchData(
    settings: PageSettingsModel.summaryT,
    isAllLocations: boolean,
    isComparisonPeriod: boolean,
    clearStateQueryResponseList?: boolean
  ) {
    const { period, filteredLocations, activeMetrics, compareToPast } =
      settings;
    const { fetchQueryResponses, clearQueryResponseList } = this.props;
    const excludeStaff =
      Selectors.isDemographicFilterRoleCustomer(settings) ||
      !this.props.showStaffFilters;

    const visibleLocations = this.getVisibleLocations(
      settings,
      filteredLocations
    );

    if (clearStateQueryResponseList) {
      clearQueryResponseList();
    }

    const breakdownBy = isAllLocations
      ? settings.breakdownBy === 'total_only'
        ? undefined
        : [settings.breakdownBy]
      : settings.breakdownBy === 'total_only'
      ? ['entity']
      : ['entity', settings.breakdownBy];

    const queryWrapper = {
      queryTracking: {
        excludeStaff,
        period:
          isComparisonPeriod && compareToPast.selectedDates
            ? compareToPast.selectedDates
            : period.selectedDates,
        aggregation: 'day',
        locations: isAllLocations ? filteredLocations : visibleLocations,
        genders:
          settings.breakdownBy && settings.breakdownBy === 'gender'
            ? GENDERS
            : undefined,
        ages:
          settings.breakdownBy && settings.breakdownBy === 'age'
            ? SORTED_AGE_KEYS
            : undefined,
        roles:
          settings.breakdownBy && settings.breakdownBy === 'role'
            ? ROLES
            : undefined,
        breakdownByDimensions: breakdownBy,
        facets: ['summary', 'thumbnails'],
      },
      extraMetaConfig: {
        activeMetrics,
        isAllLocations: false, // it may be all on the page, but not all in the org
        isComparison: isComparisonPeriod,
      },
    };

    fetchQueryResponses(queryWrapper);
  }

  getDefaultActiveMetrics() {
    let defaultActiveMetrics = {};

    const { availableMetrics } = this.props;

    if (availableMetrics) {
      Object.entries(availableMetrics).forEach(
        ([metricGroupKey, metricGroup]: [string, any]) => {
          if (metricGroup) {
            const { showOnStartupFilters, showOnStartupTaxonomies, metrics } =
              metricGroup;

            if (showOnStartupFilters && metrics) {
              showOnStartupFilters.forEach((filter) => {
                filter.values.forEach((value) => {
                  defaultActiveMetrics =
                    ActiveMetricsModel.getUpdatedActiveMetrics(
                      defaultActiveMetrics,
                      {
                        subMenuKey: metricGroupKey,
                        changeToFilter: {
                          key: filter.key,
                          value,
                        },
                      }
                    );
                });
              });
            }

            if (showOnStartupTaxonomies && metrics) {
              showOnStartupTaxonomies.forEach((taxonomy) => {
                defaultActiveMetrics =
                  ActiveMetricsModel.getUpdatedActiveMetrics(
                    defaultActiveMetrics,
                    {
                      subMenuKey: metricGroupKey,
                      changeToTaxonomy: taxonomy,
                    }
                  );
              });
            }

            metrics.forEach((metric, metricIndex) => {
              if (metric.showOnStartup) {
                defaultActiveMetrics =
                  ActiveMetricsModel.getUpdatedActiveMetrics(
                    defaultActiveMetrics,
                    {
                      subMenuKey: metricGroupKey,
                      metricElementKey: metric.key,
                    }
                  );
              }
            });
          }
        }
      );
    }

    return defaultActiveMetrics;
  }

  getSettingsWithDemographicFiltersUpdated(
    key: string,
    value?: string
  ): PageSettingsModel.summaryT {
    const { settings } = this.props;

    const newSettings = {
      ...settings,
      demographicFilter: {
        ...settings.demographicFilter,
        [key]: value,
      },
    };

    return newSettings;
  }

  settingsAreEmpty() {
    const { settings } = this.props;
    const appWideDefaults = PageSettingsModel.defaults[SUMMARY];
    return isEqual(settings, appWideDefaults);
  }

  setDefaultState() {
    const { settings, locations = [], showStaffFilters } = this.props;

    let initialSettings = settings;

    if (showStaffFilters) {
      initialSettings = this.getSettingsWithDemographicFiltersUpdated(
        'role',
        'customer'
      );
    }

    const filteredLocations = locations.map((loc) => loc.id);

    const activeMetrics = this.getDefaultActiveMetrics();

    const newSettings = {
      ...initialSettings,
      filteredLocations,
      activeMetrics,
    };

    this.handleGenericUpdate(newSettings, true, true);
  }

  updateFilteredLocations(filteredLocations: string[]) {
    const newSettings = {
      ...this.props.settings,
      filteredLocations,
    };

    this.handleGenericUpdate(newSettings, true, true);
  }

  setPage(newPage: number) {
    const newSettings = {
      ...this.props.settings,
      page: newPage,
    };

    this.handleGenericUpdate(newSettings, true, true);
  }

  setItemsPerPage(newSize: number) {
    const newSettings = {
      ...this.props.settings,
      itemsPerPage: newSize,
      page: 0,
    };

    this.handleGenericUpdate(newSettings, true, true);
  }

  handleExcludeStaff() {
    const { settings, showStaffFilters } = this.props;
    const { demographicFilter } = settings;

    let settingsToUse = settings;

    if (showStaffFilters && demographicFilter && !demographicFilter.role) {
      settingsToUse = this.getSettingsWithDemographicFiltersUpdated(
        'role',
        'customer'
      );
    } else if (
      showStaffFilters &&
      Selectors.isDemographicFilterRoleCustomer(settings)
    ) {
      settingsToUse = this.getSettingsWithDemographicFiltersUpdated(
        'role',
        undefined
      );
    }
    const newSettings = {
      ...settingsToUse,
    };

    this.handleGenericUpdate(newSettings, true, true);
  }

  updateComparisonPeriod(period: PeriodModel.comparePeriodT) {
    const { settings } = this.props;

    const newSettings = {
      ...settings,
      compareToPast: {
        ...settings.compareToPast,
        ...period,
      },
    };

    this.handleGenericUpdate(newSettings, true, true);

    if (
      period.selectedDates &&
      period.selectedDates.start &&
      period.selectedDates.end
    ) {
      trackEvent(CHANGE_COMPARISON_DATES, {
        selectedStart: this.props.settings.period.selectedDates.start,
        selectedEnd: this.props.settings.period.selectedDates.end,
        comparisonSelectedStart: period.selectedDates.start,
        comparisonSelectedEnd: period.selectedDates.end,
      });
    }
  }

  updatePeriod(
    period: PeriodModel.t,
    compareToPast: PeriodModel.comparePeriodT
  ) {
    const { settings } = this.props;
    const newSettings = {
      ...settings,
      period: {
        ...settings.period,
        ...period,
      },
      compareToPast: {
        ...settings.compareToPast,
        ...compareToPast,
      },
    };

    this.handleGenericUpdate(newSettings, true, true);

    trackEvent(CHANGE_QUERY_DATES, period);
  }

  changeBreakdownBy(breakdownBy: breakdownByEnum) {
    this.setState({ ...this.state, breakdownBy }, () => {
      this.handleGenericUpdate({ ...this.props.settings, breakdownBy }, true);
    });
    trackEvent(CHANGE_SPLIT_BY_DEMOGRAPHICS, {
      breakdownBy,
    });
  }

  handleOnCloseMetricsMenu() {
    const { settings } = this.props;
    this.handleGenericUpdate(settings, true, true);
  }

  handleConfirmSelectedComparisonDates = () => {
    const { settings } = this.props;
    this.handleGenericUpdate(settings, true, true);
  };

  render() {
    const {
      locations,
      settings,
      fetchingQueryData,
      editActiveMetrics,
      queryResponseList,
      availableMetrics,
      recordings,
      showStaffFilters,
      showAgeAndGenderFilters,
      clearActiveMetrics,
    } = this.props;

    const { filteredLocations, activeMetrics, period } = settings;

    const visibleLocations = this.getVisibleLocations(
      settings,
      filteredLocations
    );

    const isComparisonActive = !!settings.compareToPast.active;
    const excludeStaff =
      Selectors.isDemographicFilterRoleCustomer(settings) || !showStaffFilters;

    return (
      <Layout>
        <DemographicsBar style={{ marginBottom: '1rem' }}>
          <StyledDemographicsPanelHeader>
            <div>
              <LocationFilterButtons
                fetchingQueryData={fetchingQueryData}
                locations={locations}
                filteredLocations={filteredLocations}
                updateFilteredLocations={this.updateFilteredLocations.bind(
                  this
                )}
              />
            </div>
            {showStaffFilters && (
              <div>
                <SwitchContainer>
                  <Switch
                    disabled={fetchingQueryData}
                    checked={excludeStaff}
                    label="Exclude staff from counts"
                    onChange={this.handleExcludeStaff.bind(this)}
                  />
                </SwitchContainer>
              </div>
            )}
            <div>
              <DemographicBreakdownPicker
                selected={this.state.breakdownBy}
                onChange={this.changeBreakdownBy.bind(this)}
                showRole={showStaffFilters}
                showLocations={false}
              />
            </div>
          </StyledDemographicsPanelHeader>
        </DemographicsBar>
        <TopBar>
          <Row>
            <DatePickerWrapper>
              <DateAndComparisonDateSelectors
                currentSelectedDatePreset={settings.period.selectedPreset}
                handlePresetClick={this.updatePeriod.bind(this)}
                currentSelectedComparisonDatePreset={
                  settings.compareToPast.active &&
                  settings.compareToPast.selectedPreset
                    ? settings.compareToPast.selectedPreset
                    : PREVIOUS_PERIOD_PRESETS.none
                }
                handleComparisonPresetClick={this.updateComparisonPeriod.bind(
                  this
                )}
                period={period}
                compareToPastPeriod={settings.compareToPast}
              />
            </DatePickerWrapper>
          </Row>
        </TopBar>
        <Wrapper>
          {availableMetrics && Object.keys(availableMetrics).length > 0 && (
            <MetricsTable
              excludeStaff={excludeStaff}
              excludeAgeAndGender={!showAgeAndGenderFilters}
              handleOnCloseMetricsMenu={this.handleOnCloseMetricsMenu.bind(
                this
              )}
              editActiveMetrics={editActiveMetrics}
              clearActiveMetrics={clearActiveMetrics}
              activeMetrics={activeMetrics}
              queryResponseList={queryResponseList}
              filteredLocations={visibleLocations}
              locations={locations}
              breakdownBy={this.state.breakdownBy}
              availableMetrics={availableMetrics}
              settings={settings}
              recordings={recordings}
              fetchingQueryData={fetchingQueryData}
              isComparisonActive={isComparisonActive}
              showAllLocationsTotal={
                settings.itemsPerPage === -1 ||
                settings.itemsPerPage > filteredLocations.length
              }
              fetchQueryResponses={this.props.fetchQueryResponses.bind(this)}
            >
              <Pagination
                pages={filteredLocations.length / settings.itemsPerPage}
                setPage={this.setPage.bind(this)}
                currentPage={settings.page}
                itemsPerPage={settings.itemsPerPage}
                setItemsPerPage={this.setItemsPerPage.bind(this)}
              />
            </MetricsTable>
          )}
        </Wrapper>
      </Layout>
    );
  }
}

export default SummaryPage;
