// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ExplorerPage from '../components/pages/explorer';
import { ORG_HAS_STAFF_DETECTION } from '../constants/features';
import { EXPLORER } from '../constants/page-settings';
import { FETCHING_QUERY_DATA } from '../constants/async-process-names';
import * as Selectors from '../selectors';
import * as PageSettingsActions from '../actions/page-settings';
import * as QueryResponsesActions from '../actions/query-responses';

import type { Dispatch } from 'redux';

function mapStateToProps(state) {
  const queryResponseList = Selectors.getQueryResponseList(state);

  return {
    locations: Selectors.getLocations(state),
    organisationName: Selectors.getOrganisationName(state),
    settings: Selectors.getPageSettings(state, EXPLORER),
    showStaffFilters: Selectors.userHasFeature(ORG_HAS_STAFF_DETECTION, state),
    fetchingQueryData: Selectors.asyncProcessHappening(
      state,
      FETCHING_QUERY_DATA
    ),
    queryResponseList: Selectors.getPageQueryResponseList(
      state,
      queryResponseList,
      EXPLORER
    ),
    availableMetrics: Selectors.getAvailableMetrics(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      editSettings: PageSettingsActions.editExplorerPageSettings,
      fetchQueryResponses: QueryResponsesActions.fetchQueryResponses
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerPage);
