// @flow

import { Fragment, Component } from 'react';
import styled from 'styled-components';
import SortableTableHeading from './sortable-table-heading';
import { TableHeaders, TableBodyContainer, TotalTableRow } from './table';
import {
  isCellLocationBeingSelected,
  invertMetricsGridTable,
  sortMetricsGridTable
} from '../../utils/metricsTableHelpers';
import ChartCell from './chart-cell';
import { getLocationLabel } from '../../utils/summaryPageHelpers';
import { debounce } from 'lodash';
import TableBodyRows from './table-body-rows';
import TableHeadingRow from './table-heading-row';

import * as AvailableMetricsModel from '../../models/available-metrics';
import * as ActiveMetricsModel from '../../models/active-metrics';
import * as PeriodModel from '../../models/period';
import type { ElementRef } from 'react';
import type { metricsTableT } from '../../utils/metricsTableHelpers';
import type { sortByT } from './index';
import type { breakdownByEnum } from '../../constants/graph-options';

const StickyHeading = styled.tr`
  background: var(--medium-gray);
  z-index: 10;
  position: absolute;
  top: ${({ offsetFromTop }) => (offsetFromTop ? 0 + offsetFromTop : 0)}px;
  left: 0;
  right: 0;
`;

type Props = {
  sortBy: sortByT,
  locationHeadingConfig: ActiveMetricsModel.activeMetricsHeadingsT,
  metricHeadingConfig: ActiveMetricsModel.activeMetricsHeadingsT,
  activeMetricsHeadings: ActiveMetricsModel.activeMetricsHeadingsT[],
  handleSortBy(sortBy: sortByT): void,
  tableMetricsGrid: metricsTableT,
  tableMetricsTotal: metricsTableT,
  availableMetrics: AvailableMetricsModel.t,
  numberActiveLocations: number,
  breakdownBy: breakdownByEnum,
  setMetricPreviewImagesForModal(previews: string[]): void,
  isComparisonActive: boolean,
  toggleLocation: (location: string | string[]) => void,
  locationsSelectedForComparison: Array<string>,
  excludeStaff: boolean,
  period: PeriodModel.t,
  compareToPastPeriod: ?PeriodModel.comparePeriodT,
  useInvertedMetrics: boolean,
  activeMetrics: ActiveMetricsModel.t,
  filteredLocations: string[],
  showAllLocationsTotal: boolean,
};

type State = {
  areHeadersFixed: boolean,
  offsetFromTop: number,
  currentCellWidth: ?number,
};

class MetricsChart extends Component<Props, State> {
  staticHeaders: ?ElementRef<'tr'>;

  state = {
    areHeadersFixed: false,
    offsetFromTop: 0,
    currentCellWidth: undefined,
  };

  componentDidMount() {
    const handleOnScroll = () => {
      const { areHeadersFixed } = this.state;
      const scrollTop = window.pageYOffset;
      if (
        this.staticHeaders &&
        this.staticHeaders.offsetParent &&
        // $FlowFixMe - For some reason it doenst know what the Parent Element type is
        this.staticHeaders.offsetParent.offsetTop &&
        typeof this.staticHeaders.offsetParent.offsetTop === 'number'
      ) {
        const tableHeadingsHeight = this.staticHeaders.offsetParent.offsetTop;

        if (!areHeadersFixed && scrollTop > tableHeadingsHeight) {
          // This is when the user has scrolled past the point where fixed headers should begin
          this.handleAreHeadersFixed();
        }

        if (areHeadersFixed && scrollTop > tableHeadingsHeight) {
          // It Handles when the users has passed the scroll headers line will update the possition for the sticky header
          const cellWidth =
            this.staticHeaders.children.length >= 2 &&
            this.staticHeaders.children[1].offsetWidth;

          this.setOffsetFromTop(scrollTop - tableHeadingsHeight);
          if (cellWidth) {
            this.setCurrentCellWidth(cellWidth);
          }
        }

        if (areHeadersFixed && scrollTop < tableHeadingsHeight) {
          // It Handles If the users goes back before the beguining of the headers
          this.handleAreHeadersFixed();
        }
      }
    };

    window.onscroll = debounce(handleOnScroll, 0);
  }

  setCurrentCellWidth = (cellWidth: number) => {
    this.setState({
      currentCellWidth: cellWidth
    });
  };

  setOffsetFromTop = (offsetFromTop: number) => {
    this.setState({
      offsetFromTop
    });
  };

  handleAreHeadersFixed = () => {
    const { areHeadersFixed } = this.state;
    this.setState({
      areHeadersFixed: !areHeadersFixed
    });
  };

  render() {
    const {
      sortBy,
      locationHeadingConfig,
      metricHeadingConfig,
      activeMetricsHeadings,
      handleSortBy,
      tableMetricsGrid,
      tableMetricsTotal,
      availableMetrics,
      numberActiveLocations,
      breakdownBy,
      setMetricPreviewImagesForModal,
      isComparisonActive,
      toggleLocation,
      locationsSelectedForComparison,
      excludeStaff,
      period,
      useInvertedMetrics,
      activeMetrics,
      filteredLocations,
      compareToPastPeriod,
      showAllLocationsTotal,
    } = this.props;

    const { areHeadersFixed, offsetFromTop, currentCellWidth } = this.state;

    const commonHeadingsProps = {
      handleSortBy,
      sortBy
    };
    const {
      invertedMetricsTable,
      invertedActiveMetricsHeadings
    } = invertMetricsGridTable(
      tableMetricsGrid,
      activeMetricsHeadings,
      availableMetrics,
      showAllLocationsTotal ? tableMetricsTotal : undefined,
    );

    const sortedInvertedTableMetricsGrid = activeMetrics
      ? sortMetricsGridTable(sortBy, invertedMetricsTable, isComparisonActive)
      : invertedMetricsTable;

    const sortedTableMetricsGrid = activeMetrics
      ? sortMetricsGridTable(sortBy, tableMetricsGrid, isComparisonActive)
      : tableMetricsGrid;

    const renderTableHeaders = (currentCellWidth?: number) => {
      return (
        <Fragment>
          {!useInvertedMetrics ? (
            <SortableTableHeading
              {...commonHeadingsProps}
              columnsNumber={1 + activeMetricsHeadings.length}
              label={getLocationLabel(numberActiveLocations)}
              columnDetails={locationHeadingConfig}
              currentCellWidth={currentCellWidth}
              locationsSelectedForComparison={locationsSelectedForComparison}
              filteredLocations={filteredLocations}
              toggleLocation={toggleLocation}
            />
          ) : (
            <SortableTableHeading
              {...commonHeadingsProps}
              label="Metrics"
              columnsNumber={1 + invertedActiveMetricsHeadings.length}
              columnDetails={metricHeadingConfig}
              currentCellWidth={currentCellWidth}
            />
          )}
          <TableHeadingRow
            headersToOutput={
              useInvertedMetrics
                ? invertedActiveMetricsHeadings
                : activeMetricsHeadings
            }
            handleSortBy={handleSortBy}
            sortBy={sortBy}
            availableMetrics={availableMetrics}
            isComparisonActive={isComparisonActive}
            currentCellWidth={currentCellWidth}
            useInvertedMetrics={useInvertedMetrics}
          />
        </Fragment>
      );
    };

    return (
      <Fragment>
        <TableHeaders>
          <tr ref={c => (this.staticHeaders = c)}>{renderTableHeaders()}</tr>
          {areHeadersFixed && (
            <StickyHeading offsetFromTop={offsetFromTop}>
              {renderTableHeaders(currentCellWidth ? currentCellWidth : 0)}
            </StickyHeading>
          )}
        </TableHeaders>
        <TableBodyContainer>
          <TableBodyRows
            tableMetricsGrid={
              useInvertedMetrics
                ? sortedInvertedTableMetricsGrid
                : sortedTableMetricsGrid
            }
            breakdownBy={breakdownBy}
            setMetricPreviewImagesForModal={setMetricPreviewImagesForModal}
            toggleLocation={toggleLocation}
            excludeStaff={excludeStaff}
            period={period}
            locationsSelectedForComparison={locationsSelectedForComparison}
            compareToPastPeriod={compareToPastPeriod}
          />

          {!useInvertedMetrics &&
            showAllLocationsTotal &&
            tableMetricsTotal.map((row, index) => {
              return (
                <TotalTableRow key={`total_metrics_table_row:${index}`}>
                  {row.map((cell, index) => (
                    <ChartCell
                      key={index}
                      cell={cell}
                      index={index}
                      breakdownBy={breakdownBy}
                      setMetricPreviewImagesForModal={
                        setMetricPreviewImagesForModal
                      }
                      toggleLocation={toggleLocation}
                      isLocationSelected={isCellLocationBeingSelected(
                        cell,
                        locationsSelectedForComparison
                      )}
                      excludeStaff={excludeStaff}
                      period={period}
                      compareToPastPeriod={compareToPastPeriod}
                    />
                  ))}
                </TotalTableRow>
              );
            })}
        </TableBodyContainer>
      </Fragment>
    );
  }
}

export default MetricsChart;
