// @flow
import styled from 'styled-components';
import {
  Button,
  Icon,
  Label,
  MenuItem,
  NumericInput,
  Switch,
} from '@blueprintjs/core';
import { filterPredicate } from './helpers';
import { Select } from '@blueprintjs/select';
import TaxonomyMenu from '../pickers/taxonomy-menu';
import FilterMenu from '../pickers/filters-menu';
import { taxonomiesConfigurationObjectCreator } from '../../utils/taxonomyHelpers';
import { Row } from '../panel';
import DateAndComparisonDateSelector from '../date-and-comparison-date-selector';
import * as PERIOD_PRESETS from '../../constants/period-presets';
import { DIVIDER } from '../date-and-comparison-date-selector/dates-presets-config';
import type { defaultPresetsAllowedT } from '../date-and-comparison-date-selector/dates-presets-config';

const datePresetsAllowed: defaultPresetsAllowedT = [
  PERIOD_PRESETS.lastFullWeek,
  PERIOD_PRESETS.lastFullUSWeek,
  PERIOD_PRESETS.last4FullWeeks,
  PERIOD_PRESETS.last4FullUSWeeks,
  PERIOD_PRESETS.last30Days,
  PERIOD_PRESETS.lastFullMonth,
  DIVIDER,
  PERIOD_PRESETS.last3FullMonths,
  PERIOD_PRESETS.lastFullQuarter,
  PERIOD_PRESETS.quarterToDate,
  DIVIDER,
  PERIOD_PRESETS.lastFullYear,
  PERIOD_PRESETS.yearToDate,
];

const StyledRow = styled(Row)`
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StepsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const AdvancedSection = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  && {
    margin: 0 0 0.5rem;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-top: 1rem;
`;

const StyledSelect = styled(Select)`
  margin-right: 0.5rem;
`;

const Step = styled.div`
  border-radius: 8px;
  padding: 1rem;
  background: var(--super-light-blue);
`;

const Options = ({
  availableMetrics,
  selectedTaxonomy,
  setSelectedTaxonomy,
  selectedFilter,
  setSelectedFilter,
  selectedLocation,
  locations,
  setResponse,
  setSelectedLocation,
  metrics,
  selectedMetric,
  setSelectedMetric,
  headcountHours,
  setHeadcountHours,
  totalOpenHours,
  setAdvancedOptionsVisible,
  advancedOptionsVisible,
  period,
  setPeriod,
  clearData,
  clearDateCorrected,
}) => {
  if (
    !availableMetrics.movementWithFilters ||
    !availableMetrics.movementWithFilters.filters
  ) {
    metrics = metrics.filter((metric) => metric.type !== 'within-location');
  }
  return (
    <>
      <StyledRow>
        <StepsSection>
          <Step>
            <StyledLabel htmlFor="location">Choose a location</StyledLabel>
            <StyledSelect
              id="location"
              items={locations}
              itemPredicate={filterPredicate}
              itemRenderer={(location, { handleClick }) => (
                <MenuItem
                  key={location.name}
                  text={location.name}
                  active={
                    selectedLocation
                      ? location.name === selectedLocation.name
                      : false
                  }
                  onClick={handleClick}
                />
              )}
              onItemSelect={(location) => {
                clearData();
                setResponse(null);
                setSelectedLocation(location);
              }}
            >
              <Button
                text={
                  selectedLocation ? selectedLocation.name : 'Select a location'
                }
                intent="primary"
                rightIcon="caret-down"
              />
            </StyledSelect>
          </Step>
          {selectedLocation && (
            <>
              <Icon icon="caret-right" />
              <Step>
                <StyledLabel htmlFor="metric">Choose a metric</StyledLabel>
                <StyledSelect
                  id="metric"
                  items={metrics}
                  filterable={false}
                  itemRenderer={(metric, { handleClick }) => (
                    <MenuItem
                      key={metric.name}
                      text={metric.name}
                      active={
                        selectedMetric
                          ? metric.name === selectedMetric.name
                          : false
                      }
                      onClick={handleClick}
                    />
                  )}
                  onItemSelect={(metric) => {
                    clearData();
                    setResponse(null);
                    setSelectedMetric(metric);
                  }}
                >
                  <Button
                    text={
                      selectedMetric ? selectedMetric.name : 'Select a metric'
                    }
                    intent="primary"
                    rightIcon="caret-down"
                  />
                </StyledSelect>
              </Step>
            </>
          )}
          {selectedLocation &&
            selectedMetric &&
            selectedMetric.type === 'area' && (
              <>
                <Icon icon="caret-right" />
                <Step>
                  <StyledLabel htmlFor="taxonomy">Choose an area</StyledLabel>
                  <TaxonomyMenu
                    id="taxonomy"
                    availableTaxonomyList={taxonomiesConfigurationObjectCreator(
                      [
                        ...(availableMetrics.zones?.taxonomies || []),
                        ...(availableMetrics.serviceAreas?.taxonomies || []),
                      ]
                    )}
                    editActiveMetrics={({ taxonomy }) => {
                      selectedTaxonomy === taxonomy
                        ? setSelectedTaxonomy(null)
                        : setSelectedTaxonomy(taxonomy);
                    }}
                    subMenuKey={'key'}
                    subMenuConfiguration={{
                      taxonomySelectLabel: selectedTaxonomy || 'Select area',
                    }}
                    activeMetrics={{ key: { taxonomies: [selectedTaxonomy] } }}
                  />
                </Step>
              </>
            )}
          {selectedLocation &&
            selectedMetric &&
            selectedMetric.type === 'within-location' &&
            availableMetrics.movementWithFilters && (
              <>
                <Icon icon="caret-right" />
                <Step>
                  <StyledLabel htmlFor="filter">Choose a filter</StyledLabel>
                  <FilterMenu
                    id="filter"
                    availableFilterList={
                      availableMetrics.movementWithFilters.filters || []
                    }
                    editActiveMetrics={({ filter }) => {
                      selectedFilter === filter
                        ? setSelectedFilter(null)
                        : setSelectedFilter(filter);
                    }}
                    selectedFilter={selectedFilter}
                    subMenuKey={'key'}
                    activeMetrics={{ key: { filters: [selectedFilter] } }}
                  />
                </Step>
              </>
            )}
          {selectedLocation && selectedMetric && (
            <>
              <Icon icon="caret-right" />
              <Step onClick={() => clearDateCorrected()}>
                <StyledLabel htmlFor="date">Choose a date range</StyledLabel>
                <DateAndComparisonDateSelector
                  id="date"
                  currentSelectedDatePreset={period.selectedPreset}
                  datePresetsAllowed={datePresetsAllowed}
                  handlePresetClick={setPeriod}
                  period={period}
                  disableComparisonDate={true}
                  align="flex-start"
                />
              </Step>
            </>
          )}
          {selectedLocation && selectedMetric && period && (
            <>
              <Icon icon="caret-right" />
              <Step>
                <StyledLabel htmlFor="hours">
                  How many hours to allocate?
                </StyledLabel>
                <NumericInput
                  id="hours"
                  value={headcountHours}
                  onValueChange={(val) => setHeadcountHours(val)}
                  min={totalOpenHours}
                  max={2000}
                />
              </Step>
            </>
          )}
        </StepsSection>
        <AdvancedSection>
          <StyledSwitch
            value={advancedOptionsVisible}
            label="Advanced"
            onChange={(e) => setAdvancedOptionsVisible(!advancedOptionsVisible)}
          />
        </AdvancedSection>
      </StyledRow>
    </>
  );
};

export default Options;
