// @flow

import { useEffect, useState } from 'react';
import Layout from '../../../../layouts/default-logged-in';
import SettingsPageHeader from '../../settings-page-header';
import { Wrapper, TablesBody, TablesWrapper } from '../../settings-page-styles';
import type { BrowserHistory } from 'history/createBrowserHistory';
import * as RecordingModel from '../../../../../models/recording';
import * as LocationModel from '../../../../../models/location';

import styled from 'styled-components';

import LocationTable from '../shared/components/location-table';
import { fetchRecordingUptimeStatus } from '../../../../../actions/uptime';
import { fetchLocations } from '../../../../../actions/locations';
import { ProgressBar } from '@blueprintjs/core';

// The page will load this many locations first, then load the rest after
// This gives the illusion of a faster loading time, but actually takes longer
const preloadXLocations = 25;

const LoadingText = styled.div`
  text-align: center;
  padding: 2em 0 2em;
  color: var(--mid-grey);
`;

const LoadingBar = styled(ProgressBar)`
  margin: 0 auto 2em;
  max-width: 500px;
`;

type Props = {
  history: BrowserHistory,
  locations: LocationModel.t[],
  recordings: RecordingModel.t[],
};

const mapLocationDataToUptime = (locationData, recordingUptimeStatus) => locationData.map((location) => {
    const matchedRecordings = recordingUptimeStatus.filter(
      (recording) => recording.location_id === location.id,
    );

    const recordingsUp = matchedRecordings.filter(
      (r) => r.status === 'online',
    ).length;
    const recordingsTotal = matchedRecordings.length;

    return {
      ...location,
      recordingsUp,
      recordingsTotal,
      recordingPercent:
        recordingsTotal === 0 ? 0 : recordingsUp / recordingsTotal,
    };
  });

const ManageLocations = ({ history, locations, recordings }: Props) => {
  const [loadingRecordingStatus, setLoadingRecordingStatus] = useState(true);
  const [locationData, setLocationData] = useState(locations);
  const [progress, setProgress] = useState(0.1);

  const fetchRecordingUptimeData = async (locationData: LocationModel.t[]) => {
    const firstXLocationIds = locationData.slice(0, preloadXLocations).map((location) => location.id);
    const firstXLocationRecordings = recordings.filter(
      (recording) =>
        firstXLocationIds.includes(recording.location.id),
    ).map((recording) => recording.id);

    const firstXLocationUptimeStatus = (await fetchRecordingUptimeStatus(firstXLocationRecordings)) || [];

    setLoadingRecordingStatus(false);

    setLocationData(
      mapLocationDataToUptime(locationData, firstXLocationUptimeStatus),
    );

    const recordingUptimeStatus = (await fetchRecordingUptimeStatus()) || [];
    setLocationData(
      mapLocationDataToUptime(locationData, recordingUptimeStatus),
    );
  };

  useEffect(() => {
    startTimer(progress);
  }, []);

  useEffect(() => {
    if (locationData && loadingRecordingStatus) {
      fetchRecordingUptimeData(locationData);
    }
  }, [locationData]);

  const startTimer = (newProgress) => {
    if (!locationData && newProgress <= 1) {
      setProgress(newProgress);
      setTimeout(() => {
        startTimer(newProgress + (Math.random() / 10));
      }, Math.random() * 1000);
    } else {
      setProgress(1);
    }
  };


  return (
    <Layout>
      <SettingsPageHeader history={history} />
      <Wrapper>
        <TablesWrapper>
          <TablesBody>
            {!locationData && progress < 1 && (
              <>
                <LoadingText>Fetching location uptime data...</LoadingText>
                <LoadingBar intent="primary" value={progress} animate={false} />
              </>
            )}
            {!locationData && progress === 1 && (
              <>
                <LoadingText>Processing location uptime data...</LoadingText>
                <LoadingBar intent="primary" value={1} />
              </>
            )}
            {!!locationData && (
              <LocationTable
                data={locationData}
                loadingRecordingStatus={loadingRecordingStatus}
              />
            )}
          </TablesBody>
        </TablesWrapper>
      </Wrapper>
    </Layout>
  );
};

export default ManageLocations;
