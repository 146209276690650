// @flow

import styled, { css } from 'styled-components';
import bluePrintStyles from '../../../styles/blueprint.css';
import { Tooltip2 } from '@blueprintjs/popover2';
import { breakpoints } from '../../../styles/variables';
// $FlowFixMe
import FrutigerBlackFont from 'url:../../../styles/fonts/Frutiger-Black.woff';
// $FlowFixMe
import FrutigerRegularFont from 'url:../../../styles/fonts/Frutiger-Regular.woff';

// Fonts
const fontFaces = css`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  @font-face {
    font-family: 'Frutiger Black';
    src: url('${FrutigerBlackFont}') format('woff');
  }
  @font-face {
    font-family: 'Frutiger';
    src: url('${FrutigerRegularFont}') format('woff');
  }
`;

export const WidgetMainWrapper = styled.div`
  ${fontFaces}

  @import ${bluePrintStyles};

  font-family: var(--fonts-widget-body);
  border-radius: var(--widget-border-radius);
  border: var(--widget-border);
  box-shadow: var(--widget-shadow);
  background-color: var(--widget-background);
  width: 100%;
  margin-bottom: 1rem;
  position: relative;

  & .save {
    top: 2rem;
    right: 2rem;
    position: absolute;
  }
`;

export const WidgetBodyWrapper = styled.div`
  position: relative;
  padding: 2rem;
  min-height: 120px;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 1.5rem 1.5rem;
  }

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    padding: 1.5rem 0.5rem;
  }
`;

export const WidgetBodyWrapperGrid = styled(WidgetBodyWrapper)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2rem;
`;

export const WidgetOverflow = styled.div`
  overflow: auto;
`;

const metricTextCSS = css`
  color: ${({ color }) => color || 'var(--widget-heading-text)'};
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0 2px'};
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  text-align: ${({ align }) => align};
  text-transform: capitalize;
  white-space: ${({ whiteSpace }) => whiteSpace};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

  ${({ accessible }) =>
    accessible
      ? `
    width: 100%;
    max-width: 120px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    height: 100%;
    color: #333;
  `
      : ''};

  @media (min-width: ${breakpoints.MED_PC_SIZE_PLUS_1}) {
    font-size: 16px;
  }
`;

export const MetricText = styled.div`
  ${metricTextCSS}
`;

export const MetricLinkText = styled.a`
  ${metricTextCSS}

  &:hover {
    text-decoration: none;
  }
`;

export const MetricTitle = styled.div`
  color: var(--widget-heading-text);
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || '20px'};
  line-height: normal;
  text-align: ${({ align }) => align};
  white-space: ${({ whiteSpace }) => whiteSpace};
`;

export const WidgetGrid = styled.div`
  display: grid;
  grid-template-columns: fit-content(220px) auto;
  row-gap: ${({ rowGap }) => rowGap};

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    grid-template-columns: fit-content(160px) auto;
  }
`;

export const WidgetCells = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  grid-auto-flow: column;
  grid-gap: ${({ gridGap }) => gridGap};

  @media (max-width: 1100px) {
    grid-gap: ${({ gridGap }) => (gridGap ? '6px' : 0)};
  }
`;

export const WidgetBox = styled.div.attrs((props) => ({
  style: {
    background: props.backgroundColor,
    alignItems: props.altAlign || 'center',
    height: props.height || '40px',
    border: props.hasBorder ? '1px solid var(--white)' : 'none',
    maxHeight: props.maxHeight,
    justifyContent: props.align,
    padding: props.padding || '4px',
  },
}))`
  border-radius: 4px;
  display: flex;
`;

export const Tooltip = styled(Tooltip2)`
  cursor: help;
`;
