// @flow

export const getFilterLocationsButtonText = (
  locationsLength: number,
  filteredLocationsLength: number,
  defaultLabel: ?string
): string => {
  if (locationsLength - filteredLocationsLength === 0)
    return defaultLabel ? defaultLabel : `All (${filteredLocationsLength} of ${locationsLength})`;
  return `${filteredLocationsLength} of ${locationsLength}`;
};

export const isLocationDisplayed = (
  id: string,
  filteredLocations: string[]
): boolean => {
  return !!filteredLocations.find(loc => loc === id);
};

export type prettyValueToCompareT = {
  difference: number,
  result: number
};

export const prettyValueToCompare = (
  valueToCompare: number,
  value: number
): prettyValueToCompareT => {
  const difference = value - valueToCompare;
  const result = (difference / valueToCompare) * 100;

  if (!result || typeof result !== 'number' || result === Infinity) {
    return {
      difference: 0,
      result: 0
    };
  }

  return { difference, result };
};

export const sanitiseString = (word: any): string => {
  if (typeof word !== 'string') return word;
  return word.replace(/,/g, '');
};

export const getLocationLabel = (numberActiveLocations: number): string => {
  const nuberOfLocationsLabel =
    numberActiveLocations && numberActiveLocations > 0
      ? ` (${numberActiveLocations})`
      : '';
  return `Locations${nuberOfLocationsLabel}`;
};
