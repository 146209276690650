// @flow

import { useState, useEffect } from 'react';
import { map } from 'awaity';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import WidgetUI from '../../widgets-ui/kpi';
import { getQueryPayloadType, buildPayload } from '../../../services/query-api';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';

const KpiWidget = ({
  widget,
  availableMetrics,
  uiOptions = {},
}: WidgetPropsModel.kpiT) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [metricsForUI, setMetricsForUI] = useState([]);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible(
    (vi) => uiOptions.disableViewportLoading || vi > 0
  );

  const loadWidgetData = async (metricsDetails) => {
    setError(false);
    setLoading(true);
    setProgress(0);

    const comparisonActive =
      widget.comparisonPeriod && widget.comparisonPeriod.active;

    const totalQueries = comparisonActive
      ? metricsDetails.length * 2
      : metricsDetails.length;
    let completeQueries = 0;

    // for each metric
    const builtMetrics = await map(metricsDetails, async (metric) => {
      // build the request body and fetch data for each metric for each period
      const path = apiEndpointByMetricKey(metric.metricKey);
      const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
        metric.details
      );

      const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

      let taxonomies;
      if (metric.taxonomy) {
        taxonomies = [metric.taxonomy];
      } else if (metric.filter) {
        taxonomies = [`${metric.filter.key}:${metric.filter.value}`];
      }

      const payloadInput = {
        locations: widget.locations,
        period: widget.period,
        demographicFilter: widget.demographicFilter,
        taxonomies,
        aggregation: 'day', // we can hardcode for the KPI widget because we know we don't need segments
        metricKey: metric.metricKey,
      };

      const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

      let response;
      let compResponse;

      try {
        response = await fetchSQLQuery(payloadInitialPeriod, path, {
          returnErrors: true,
          return404AsError: false,
          metricKey: metric.metricKey,
        });
        completeQueries++;
        setProgress(completeQueries / totalQueries);

        if (comparisonActive) {
          const payloadComparisonPeriod = buildPayload(queryPayloadType, {
            ...payloadInput,
            period: widget.comparisonPeriod,
          });

          compResponse = await fetchSQLQuery(
            payloadComparisonPeriod,
            path,
            {
              returnErrors: true,
              return404AsError: false,
              metricKey: metric.metricKey,
            }
          );
          completeQueries++;
          setProgress(completeQueries / totalQueries);
        }
      } catch (e) {
        return {
          title: getColumnLabel(metric, availableMetrics, true, true),
          value: null,
          changeValue: null,
        };
      }

      // extract the values and changeValues
      const value = parseFloat(
        QueryResponseModel.getSummaryValue({
          response: response,
          metricKey: metric.metricKey,
        })
      );

      let changeValue = null;
      if (comparisonActive && compResponse) {
        const compValue = parseFloat(
          QueryResponseModel.getSummaryValue({
            response: compResponse,
            metricKey: metric.metricKey,
          })
        );
        const { result } = compValue
          ? prettyValueToCompare(compValue, value)
          : { result: null };
        changeValue = comparisonActive ? result : null;
      }

      // decorate each response with information from availableMetrics, and the API response
      return {
        title: getColumnLabel(metric, availableMetrics, true, true),
        description:
          !uiOptions.disableDescription &&
          AvailableMetricsModel.getMetricDescription(metric.details),
        value,
        changeValue,
        unitType: QueryResponseModel.getUnitType(response, metric.metricKey),
      };
    });

    setMetricsForUI(builtMetrics);
    setLoading(false);
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      // get the information from available metrics data for each metric
      const metricsDetails = widget.metrics
        .map((m) => ({
          ...m,
          details: AvailableMetricsModel.findMetricDetails(
            availableMetrics,
            m.metricGroupKey,
            m.metricKey
          ),
        }))
        .filter((m) => !!m && !!m.details);

      if (!metricsDetails || !metricsDetails.length) {
        console.error(
          `No metrics found in availableMetrics for the KPI widget (${widget.metrics
            .map((metric) => JSON.stringify(metric.metricKey))
            .join(', ')})`
        );
        setError(true);
      } else {
        loadWidgetData(metricsDetails);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || 'Key Metrics Summary';
  const subtitle = buildWidgetDateString(widget);

  // TODO: also as a future change, make the UI support an error in a single metric and show the labels and descriptions at least
  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        progress={progress}
        title={title}
        subtitle={subtitle}
        metrics={metricsForUI}
      />
    </div>
  );
};

export default KpiWidget;
