import { connect } from 'react-redux';
import { Tabs, Tab } from '@blueprintjs/core';
import { CAN_VIEW_SETTINGS_PAGE } from '../../../constants/permissions';
import * as Selectors from '../../../selectors';

const SettingsNavbarTabs = ({ history, showSettingsPage }) => {
  const handleTabClick = (tab) => {
    let path = `/settings/${tab}`;

    if (tab === 'locations') {
      path = '/settings/manage/locations';
    }

    if (!history) window.location.pathname = path;
    history.push(path);
  };

  const getSelectedTabId = () => {
    if (!history) return null;
    const { location = {} } = history;
    const { pathname = '' } = location;
    return pathname.split('/').pop();
  };

  const selectedTabId = getSelectedTabId();

  return (
    <>
      <Tabs
        id="SettingsTab"
        selectedTabId={selectedTabId}
        onChange={handleTabClick}
      >
        <Tab id="locations" title="Locations" />
        {showSettingsPage && <Tab id="users" title="Users" />}
        {/*<Tab disabled id="integrations" title="Integrations" />*/}
        <Tab id="tools" title="Tools" />
      </Tabs>
    </>
  );
};

function mapStateToProps(state) {
  return {
    showSettingsPage: Selectors.userHasPermission(
      CAN_VIEW_SETTINGS_PAGE,
      state,
    ),
  };
}

export default connect(mapStateToProps)(SettingsNavbarTabs);
