import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { ThemeContext } from 'styled-components';
import { getFonts } from '../../../../../../styles/variables';
import { H1 } from '@blueprintjs/core';
import { SETTINGS_MANAGE_SINGLE_RECORDING } from '../../../../../../constants/routes';
import { HeaderCell, StyledHeader, StyledTable } from '.';
import { RecordingRow } from './recording-row';
import { Spinner, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { fetchThumbnailsForRecordings } from '../../../../../../actions/thumbnails';

const PING_STATE = {
  NULL: '0',
  LOADING: '1',
  SUCCESS: '2',
  ERROR: '3',
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CenteredIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClickableCenteredIcon = styled(CenteredIcon)`
  cursor: pointer;
`;

export const CameraServerTable = ({
  name,
  recordings,
  locationId,
  location,
}) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const { headingFont } = getFonts(theme);
  const [pingState, setPingState] = useState(PING_STATE.IDLE);
  const [thumbnails, setThumbnails] = useState();

  const pingDevice = async () => {
    setPingState(PING_STATE.LOADING);

    try {
      await iotApi.device.ping(name);
      setPingState(PING_STATE.SUCCESS);
    } catch (error) {
      console.log(`Failed to get response from ${name}`);
      setPingState(PING_STATE.ERROR);
    }
  };

  useEffect(() => {
    pingDevice();
  }, []);

  const navigate = (recording) =>
    history.push(`${SETTINGS_MANAGE_SINGLE_RECORDING}/${recording.id}`, {
      ...location.state,
      recording,
    });

  return (
    <>
      <Row>
        <H1 style={{ fontFamily: headingFont }}>{name.toLocaleUpperCase()}</H1>
        {/* <>
          {pingState === PING_STATE.NULL && (
            <CenteredIcon>
              <Icon icon="small-minus" />
            </CenteredIcon>
          )}
          {pingState === PING_STATE.LOADING && <Spinner size="24" />}
          {pingState === PING_STATE.SUCCESS && (
            <ClickableCenteredIcon onClick={pingDevice}>
              <Tooltip2 content="This camera server currently has power and is connected to the internet. Click to re-test.">
                <Icon intent="success" icon="tick-circle" size={24} />
              </Tooltip2>
            </ClickableCenteredIcon>
          )}
          {pingState === PING_STATE.ERROR && (
            <ClickableCenteredIcon onClick={pingDevice}>
              <Tooltip2 content="This camera server is currently powered down or doesn't have a suitable internet connection. Click to re-test.">
                <Icon intent="warning" icon="error" size={24} />
              </Tooltip2>
            </ClickableCenteredIcon>
          )}
        </> */}
      </Row>
      <StyledTable className="bp3-html-table bp3-html-table-striped">
        <StyledHeader themeFont={headingFont}>
          <tr>
            <HeaderCell>Camera</HeaderCell>
            <HeaderCell center={true}>Preview</HeaderCell>
            <HeaderCell center={true}>
              <Tooltip2 content="Camera Online Status">Status</Tooltip2>
            </HeaderCell>
            <HeaderCell center={true}>
              <Tooltip2 content="Most Recent Time Data Has Been Received">
                Last Check In
              </Tooltip2>
            </HeaderCell>
          </tr>
        </StyledHeader>
        <tbody>
          {recordings &&
            recordings.map((recording) => (
              <RecordingRow
                onClick={() => navigate(recording)}
                key={recording.id}
                name={recording.name}
                status={recording.status}
                preview={
                  recording.thumbnails && recording.thumbnails.length
                    ? recording.thumbnails[0].url
                    : null
                }
                lastSeenUtc={recording.last_seen_utc}
                lastSeenLocalTime={recording.last_seen_local_time}
                timezone={recording.timezone}
              />
            ))}
        </tbody>
      </StyledTable>
    </>
  );
};
