// @flow

import { useContext } from 'react';
import Dispatch, { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { breakpoints } from '../../styles/variables';
import * as AuthActions from '../../actions/auth';
import * as Selectors from '../../selectors';
import { PoweredBy } from '../logos/powered-by';
import logo from '../../styles/logos/o2-business-horizontal-logo.png';

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    flex-direction: column;
  }
`;

const A = styled.a`
  font-family: var(--fonts-default);
  font-size: 0.7rem;
  color: #878787;
  text-decoration: underline;

  &:hover,
  &:visited,
  &:active {
    color: #878787;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding-top: 2em;
  }
`;

const Links = styled.div`
  display: flex;
`;

const StyledLogo = styled.img`
  width: 120px;
  display: block;
`;

const PoweredByLogo = styled.div`
  margin-top: 1em;
  width: 140px;
  > svg {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  width: 18em;
  height: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    background: none;
    margin-top: 1em;
  }
`;

const Spacer = styled.div`
  width: 18em;

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    width: 10em;
  }

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    display: none;
  }
`;

const Link = styled.div`
  padding: 0 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.4);

  &:last-of-type {
    border-right: none;
  }
`;

const UserDetails = styled.span`
  color: #878787;
  font-size: 0.7rem;

  & .email {
    font-weight: bold;
  }
`;

const Logout = styled.a`
  color: #0019a5;
  text-decoration: underline;
  margin-inline-start: 6px;
`;

const CustomFooter = ({ logout, user }) => {
  const theme = useContext(ThemeContext);
  let logoutAction = logout;

  if (theme.overrides && theme.overrides.logout) {
    logoutAction = () => logout({ returnTo: theme.overrides.logout });
  }

  return (
    <Footer>
      <Spacer></Spacer>
      <CenterContainer>
        <UserDetails>
          <span>
            Signed in as <span className="email">{user.email}</span>
          </span>
          <Logout href="#" onClick={logoutAction}>
            Sign out
          </Logout>
        </UserDetails>
        <Links>
          <Link>
            <A
              href="https://www.o2.co.uk/termsandconditions"
              rel="noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </A>
          </Link>
          <Link>
            <A
              href="https://www.o2.co.uk/termsandconditions/privacy-policy"
              rel="noreferrer"
              target="_blank"
            >
              Privacy
            </A>
          </Link>
          <Link>
            <A href="mailto:support-ai@o2.com" rel="noreferrer" target="_blank">
              Contact Us
            </A>
          </Link>
        </Links>
      </CenterContainer>
      <LogoContainer>
        <StyledLogo src={logo} alt="Telefonica/O2 logo" />
        {theme.cobranded && (
          <a href="https://auravision.ai" rel="noreferrer" target="_blank">
            <PoweredByLogo>
              <PoweredBy />
            </PoweredByLogo>
          </a>
        )}
      </LogoContainer>
    </Footer>
  );
};

function mapStateToProps(state) {
  return {
    // TODO: use react-router-redux to connect the state and match the pathname for getting the active link (or use react router active link?)
    user: Selectors.getUserModel(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return bindActionCreators(
    {
      logout: AuthActions.logout,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFooter);
