import { CenteredCell } from '.';

export const ImageCell = ({
  src,
  height = '150px',
  width = '250px',
  altText = 'Camera preview image',
}) => (
  <CenteredCell>
    <img
      src={src}
      alt={altText}
      height={height.includes('px') ? height : `${height}px`}
      width={width.includes('px') ? width : `${width}px`}
    />
  </CenteredCell>
);
