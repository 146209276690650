// @flow

import { connect } from 'react-redux';
import * as Selectors from '../selectors';
import ManageSingleRecording from '../components/pages/settings/manage/single-recording';

function mapStateToProps(state) {
  return {
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
  };
}

export default connect(mapStateToProps)(ManageSingleRecording);
