import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';

const Wrapper = styled.div`
  position: relative;
  background: var(--light-blue);
  // margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width};
  height: ${props => props.height};
  padding-top: ${props => (props.showPadding ? '62.5%' : '0')};
`;
const CameraImg = styled.img`
  position: relative;
  z-index: 1;
`;
const StyledIcon = styled(Icon)`
  fill: var(--dark-blue);
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const PlaceholderImage = ({
  src,
  width,
  height,
  placeholderIcon = 'media'
}) => (
  <Wrapper showPadding={!src} width={width} height={height}>
    <StyledIcon iconSize={25} icon={placeholderIcon} />
    {src && <CameraImg width={width} loading="lazy" height={height} src={src} />}
  </Wrapper>
);

export default PlaceholderImage;
