const get = require('lodash.get');
const { User } = require('../authoriser/user');
const { NotAuthorisedError } = require('../authoriser/error/notAuthorisedError');
const { GenericAuthError } = require('../authoriser/error/genericAuthError');

const bearerPrefix = 'Bearer ';

/**
 * Middleware to construct the user auth object
 * from token.
 *
 * Allows override of:
 * - assignToProp the property on req which the user instance is assigned
 * - authHeader which header the token can be found within
 * - BootstrapBuilder inject it here if available (i.e. in Auth Service)
 * - bootstrapCacheFacade inject it here is available (i.e. in a
 * server side service)
 * - OverrideNotAuthorisedError inject it here if pkg/NotAuthorisedError is not
 * caught in your implementation
 * - noImpersonateOnArray - array of strings of route resources that impersonation
 * should be skipped for e.g. ['/api/v1/bootstrap/']
 * - noUserOnArray - array of strings of route resources that user should
 * no be constructed or refreshed on
 * should be skipped for e.g. ['/api/v1/bootstrap/']
 *
 * @param authEnvBasePath
 * @param logger
 * @param BootstrapBuilder
 * @param assignToProp
 * @param overrideAuthHeader
 * @param bootstrapCacheFacade
 * @param noImpersonateOnArray
 * @param OverrideNotAuthorisedError
 * @param noUserOnArray
 * @returns {function(...[*]=)}
 */
module.exports.createUserFromToken = (
  authEnvBasePath,
  logger,
  BootstrapBuilder = null,
  assignToProp = null,
  overrideAuthHeader = null,
  bootstrapCacheFacade = null,
  noImpersonateOnArray = [],
  OverrideNotAuthorisedError = NotAuthorisedError,
  noUserOnArray = [],
) => async (req, res, next) => {
  try {
    logger.info({
      message: 'createUserFromToken.start',
    });

    // Should we skip user build on this resource?
    if (isResourceInArray(req, noUserOnArray, logger)) {
      logger.info({
        message: 'createUserFromToken - skipping user construction for this route',
      });
      return next();
    }

    // Default the authUser param to assign user to on req
    // Default which header we get the token from
    const authParam = assignToProp || 'authUser';
    const authHeader = overrideAuthHeader || 'authorization';

    // Strip out the token from 'Bearer xxxx'
    const [, token] = get(
      req,
      `headers.${authHeader}`,
      `${bearerPrefix} null`,
    ).split(bearerPrefix);

    // Construct the user auth object
    const user = new User(token, bootstrapCacheFacade, authEnvBasePath, logger,
      BootstrapBuilder);

    // Get the bootstrap for the user
    await user.refresh(isResourceInArray(req, noImpersonateOnArray, logger));

    // Assign it the the authUser (or custom) property of req
    req[authParam] = user;
  } catch (err) {
    // If the user object fails with NotAuthorisedError, return NotAuthorisedError OR
    // the overridden error
    if (err instanceof NotAuthorisedError) return next(new OverrideNotAuthorisedError());
    // Otherwise log and return the error
    await logger.error({
      message: 'createUserFromToken - Failed',
      contextObject: err,
    });
    return next(err);
  }

  // User auth object built, let's go to next middleware
  return next();
};

/**
 * Returns true if the current resource path is present in an array
 * of resource paths
 * @param req
 * @param resourceArray
 * @param logger
 * @return boolean
 */
const isResourceInArray = (req, resourceArray, logger) => {
  logger.info({
    message: 'createUserFromToken.isResourceInArray start. ',
    contextObject: resourceArray,
  });

  const requestedResourcePath = getResourcePath(req);

  const isPresent = resourceArray.some(
    (route) => requestedResourcePath.startsWith(route),
  );

  logger.info({
    message: `createUserFromToken.isResourceInArray isPresent: ${isPresent}`,
    contextObject: { requestedResourcePath, isPresent },
  });

  return isPresent;
};

/**
 * Get the current resource path
 * @param req
 */
const getResourcePath = (req) => {
  const requestedResourcePath = get(req, '_parsedUrl.path', false);

  if (!requestedResourcePath) {
    throw new GenericAuthError(
      'createUserFromToken.getResourcePath Error. '
      + 'Unable to resolve requestContext.path from req',
      req,
    );
  }
  return requestedResourcePath;
};
