// @flow

import {
  prettyLongNumber,
  prettyPercentage,
  prettyTime,
  prettyCurrency,
  prettyRate,
} from '@auravisionlabs/aura-pkg-ui-formatter';

export const formatNumbersWithUnits = (
  value: number,
  unitType: string = ''
): string => {
  if (value === null || isNaN(value)) {
    return '-';
  }
  if (typeof value === 'number' && unitType === 'percentage') {
    return prettyPercentage(value, 2, '-');
  }
  if (typeof value === 'number' && unitType === 'peopleCount') {
    return prettyLongNumber(value, 0);
  }
  if (typeof value === 'number' && unitType === 'seconds') {
    return prettyTime(value);
  }
  if (typeof value === 'number' && unitType === 'number') {
    return prettyLongNumber(value);
  }
  if (typeof value === 'number' && unitType === 'rate') {
    return prettyRate(value);
  }
  if (typeof value === 'number' && unitType.startsWith('currency-')) {
    const currencyCode = unitType.split('currency-')[1];
    return prettyCurrency(value, currencyCode);
  }

  return prettyLongNumber(value);
};
