// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import { locations } from '../services/api';
import * as LocationModel from '../models/location';
import { errorToast } from '../utils/toaster';
import { getOptions } from '../models/user';
import { sendMessage } from '../services/sql-api';

export const fetch =
  (): FluxStandardAction<string, null, *> => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTIONS.FETCH_LOCATIONS_REQUEST });

      const response = await fetchLocations();
      const openingTimesResponse = await fetchOpeningTimes();

      if (response && openingTimesResponse) {
        const payload = {
          locations: response
            .filter(
              (r) =>
                (getOptions(getState().user.model).showSettingUpLocations &&
                  r.status === 'setting up') ||
                !['created', 'setting up'].includes(r.status),
            )
            .map((r) => {
              const matchedOpeningTimes = openingTimesResponse.find(
                (ot) => ot.location_id === r.id,
              );

              return LocationModel.create({
                id: r.id,
                name: r.name,
                coordinates: r.coordinates,
                metadata: r.metadata,
                organisation: r.organisation_id,
                liveOccupancyConfiguration: undefined,
                // liveOccupancyConfiguration: r.liveOccupancyConfiguration
                //   ? {
                //       liveOccupancyStatsActive:
                //         r.liveOccupancyConfiguration.liveOccupancyStatsActive,
                //       locationCapacity:
                //         r.liveOccupancyConfiguration.locationCapacity,
                //       occupancyDerivedVia:
                //         r.liveOccupancyConfiguration.occupancyDerivedVia,
                //     }
                //   : undefined,
                status: r.status,
                currency: r.currency,
                openingTimes: matchedOpeningTimes.map((ot) => ({
                  day: parseInt(ot.day),
                  startHour: parseInt(ot.start_time.split(':')[0]),
                  startMinute: parseInt(ot.start_time.split(':')[1]),
                  stopHour: parseInt(ot.end_time.split(':')[0]),
                  stopMinute: parseInt(ot.end_time.split(':')[1]),
                })),
                timezone: r.timezone,
              });
            }),
        };

        dispatch({ type: ACTIONS.FETCH_LOCATIONS_SUCCESS, payload });
      } else {
        throw new Error();
      }
    } catch (error) {
      errorToast({ message: 'Error fetching location data.', timeout: 2500 });
      dispatch({ type: ACTIONS.FETCH_LOCATIONS_ERROR });
    }
  };

export const fetchOne = async (locationId: string) => {
  try {
    const response = await locations.fetchOne(locationId);
    return response;
  } catch (error) {
    console.error(error);
    errorToast({ message: 'Error fetching location data.', timeout: 2500 });
    return;
  }
};

export const updateLocation =
  (id: string, locationChanges: Object) =>
  async (dispatch: (any) => void): FluxStandardAction<string, *> => {
    try {
      await locations.update(id, locationChanges);
      await dispatch(updateLocationState({ id, ...locationChanges }));
      return true;
    } catch (err) {
      await dispatch(updateLocationStateError());
      errorToast({ message: 'Error updating location data.', timeout: 2500 });
      return false;
    }
  };

const updateLocationState = (
  locationChanges: Object,
): FluxStandardAction<string, *> => {
  return {
    type: ACTIONS.EDIT_LOCATION_SUCCESS,
    payload: locationChanges,
  };
};

const updateLocationStateError = (
  locationChanges: Object,
): FluxStandardAction<string, *> => {
  return {
    type: ACTIONS.EDIT_LOCATION_ERROR,
  };
};

export const fetchRecordingsForLocations = async (
  locationIds: Array<string>,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'location',
      entities: locationIds,
      returnEntityType: 'recording',
    };

    const results = await sendMessage('detail', 'list', query);

    if (!results || !Array.isArray(results)) {
      return null;
    }

    return results.filter((r) => !!r.camera_server_id || window.isDemoOrg);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchLocations = async (
  locationIds?: Array<string>,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'location',
      entities: undefined,
    };

    if (locationIds && Array.isArray(locationIds) && locationIds.length > 0) {
      query.entities = locationIds;
    }

    const results = await sendMessage('detail', 'list', query);

    if (!results || !Array.isArray(results)) {
      return null;
    }

    return results.filter(
      (l) =>
        !['created', 'setting up'].includes(l.status) ||
        (window.showSettingUpLocations && l.status !== 'created'),
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchOpeningTimes = async (locationIds?: Array<string>) => {
  try {
    const query = {
      entityType: 'location',
      returnEntityType: 'opening_times',
      entities: undefined,
    };

    if (locationIds && Array.isArray(locationIds) && locationIds.length > 0) {
      query.entities = locationIds;
    }

    const results = await sendMessage('detail', 'list', query);

    if (!results || !Array.isArray(results)) {
      return null;
    }

    return results;
  } catch (error) {
    console.log(error);
    return null;
  }
};
