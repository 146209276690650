// @flow

import styled from 'styled-components';
import {
  Position,
  Icon,
  Popover,
  PopoverInteractionKind
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import {
  getMetricFromGridItem,
  redirectWithStateToExplorerPage
} from '../../utils/urlHelpers';
import ImageDisplayerMenu from '../image-displayer-menu';

import * as PeriodModel from '../../models/period';
import type { metricsTableCellT } from '../../utils/metricsTableHelpers';
import { Desktop } from '../layouts/devices-sizes';

const Icons = styled.div`
  max-height: 18px;
  display: flex;
  margin-left: 1rem;
  justify-content: flex-end;
`;
export const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  opacity: 40%;
  z-index: 5;

  &:hover {
    cursor: pointer;
    opacity: 50%;
  }
`;

const StyledTooltip = styled(Tooltip2)`
  margin: 0;
  max-height: 18px;

  .bp3-popover-target {
    max-height: 18px;
  }
`;
const StyledDesktop = styled(Desktop)`
  max-height: 18px;
`;

type Props = {
  cell: metricsTableCellT,
  excludeStaff: boolean,
  period: PeriodModel.t,
  setMetricPreviewImagesForModal(previews: string[]): void,
  compareToPastPeriod: ?PeriodModel.comparePeriodT
};

export const CellIcons = ({
  cell,
  excludeStaff,
  period,
  setMetricPreviewImagesForModal,
  compareToPastPeriod
}: Props) => {
  const redirectSingletMetricToExplorerPage = () => {
    const metric = getMetricFromGridItem({ ...cell, excludeStaff });

    redirectWithStateToExplorerPage([metric], period, compareToPastPeriod);
  };

  return (
    <Icons>
      <StyledDesktop>
        <StyledTooltip content="Explore in depth" position={Position.BOTTOM}>
          <StyledIcon
            onClick={redirectSingletMetricToExplorerPage}
            icon="series-search"
          />
        </StyledTooltip>
      </StyledDesktop>

      {cell.previews && cell.previews.length > 0 && (
        <Popover
          className="bp3-popover-wrapper-custom"
          position={Position.TOP}
          interactionKind={PopoverInteractionKind.HOVER}
          hoverOpenDelay={0}
          hoverCloseDelay={150}
          boundary="viewport"
          modifiers={{
            preventOverflow: {
              enabled: true
            },
            hide: { enabled: false }
          }}
          content={
            <ImageDisplayerMenu
              setMetricPreviewImagesForModal={setMetricPreviewImagesForModal}
              previews={cell.previews}
            />
          }
        >
          <StyledIcon icon="camera" />
        </Popover>
      )}
    </Icons>
  );
};

export default CellIcons;
