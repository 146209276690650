// @flow

import { useState, useEffect } from 'react';
import * as ReportModel from '../../../models/report';
import DefaultLoggedIn from '../../layouts/default-logged-in';
import TopBarLoading from '../../top-bar-loading';

type Props = {
  children: any,
  reports: ReportModel.t[],
  fetchingReports: boolean,
  fetchReports: () => void,
};

const LoadReports = ({
  children,
  reports,
  fetchingReports,
  fetchReports,
}: Props) => {
  const [reportsFetchStarted, setReportsFetchStarted] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchInProgress = reportsFetchStarted && fetchingReports;
    const fetchCompleted = reportsFetchStarted && !fetchingReports;
    const alreadyHasReports = reports && !!reports.length;

    if (!fetchInProgress) {
      if (fetchCompleted || alreadyHasReports) {
        setIsReady(true);
      } else {
        fetchReports();
        setReportsFetchStarted(true);
      }
    }
  }, [reports, fetchingReports, fetchReports, reportsFetchStarted]);

  return isReady ? (
    children
  ) : (
    <DefaultLoggedIn>
      <TopBarLoading />
    </DefaultLoggedIn>
  );
};

export default LoadReports;
