import styled from 'styled-components';
import header from '../../assets/headers/header.png';
import { breakpoints } from '../../styles/variables';
import DividerSVG from '../divider';

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  color: #fff;
  margin: 0;
  margin-bottom: 2rem;
  position: relative;
  font-family: 'Frutiger Black', Arial, sans-serif;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.02em;
  z-index: 20;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    margin-bottom: 1rem;
    font-size: 36px;
  }
`;
const HeaderImage3 = styled.div`
  background: url(${header});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  background-position-y: bottom;
  background-position-x: center;

  @media (min-width: ${breakpoints.TABLET_SIZE_PLUS_1}) {
    aspect-ratio: 1440 / 400;
    max-height: 400px;
  }
`;
const StyledButton = styled.a`
  display: block;
  text-decoration: none;
  color: white;
  height: 32px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin: 0 50px 10px 0;
  letter-spacing: -0.02em;
  font-family: Frutiger, Arial, sans-serif;
  white-space: nowrap;
  text-align: center;

  &.active,
  &:hover {
    background: rgb(51, 71, 183);
    color: white;
    border-radius: 3px;
    text-decoration: none;
  }

  @media (max-width: 820px) {
    margin-right: 40px;
  }

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    margin: 10px 0;
  }
`;
const Buttons = styled.div`
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Breadcrumbs = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Crumb = styled.a`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-family: Frutiger, Arial, sans-serif;
  text-decoration: none;
  color: #333;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;
const Divider = styled.div`
  margin: 0 26px;
`;

const CustomHeader = () => (
  <Header>
    <HeaderImage3>
      {/* <HeaderImage src={siHeader} /> */}
      <Title>Spatial Insights</Title>
      <Buttons>
        <StyledButton href="https://www.o2motion.co.uk/products/si/overview">
          Product Overview
        </StyledButton>
        <StyledButton className="active">Data Dashboard</StyledButton>
        <StyledButton href="https://www.o2motion.co.uk/products/si/specifications">
          Specifications
        </StyledButton>
      </Buttons>
    </HeaderImage3>
    {/* <HeaderImage src={siHeader2} z`/> */}
    <Breadcrumbs>
      <Crumb href="https://www.o2motion.co.uk/">
        O<sub>2</sub> MOTION
      </Crumb>
      <Divider>
        <DividerSVG />
      </Divider>
      <Crumb href="https://www.o2motion.co.uk/products">PRODUCTS</Crumb>
      <Divider>
        <DividerSVG />
      </Divider>
      <Crumb>SPATIAL INSIGHTS</Crumb>
    </Breadcrumbs>
  </Header>
);

export default CustomHeader;
