import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useCloseRequestsOnLocationChange = () => {
  const [loc, setLoc] = useState();
  const location = useLocation();

  useEffect(() => {
    if (typeof location !== 'object' || typeof window.queryWorker === 'undefined') {
      return;
    }

    if (loc === location.pathname) {
      return;
    }

    setLoc(location.pathname);
    window.queryWorker.postMessage({ clearMetricRequests: true });
  }, [location]);

  return <></>;
};

export default useCloseRequestsOnLocationChange;
