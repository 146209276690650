const cloneDeep = require('lodash.clonedeep');
const get = require('lodash.get');
const jsonwebtoken = require('jsonwebtoken');
const { NotAuthorisedError } = require('./error/notAuthorisedError');
const { GenericAuthError } = require('./error/genericAuthError');
const { getUserBootstrap } = require('./bootstrapRetrievalStrategy');
const { cptlze } = require('../utilities/string');
const { CapArray, SetArray } = require('../utilities/array');

const subClaim = 'sub';

const ownOrg = 'own org';
const personal = 'personal';
const rule = 'rule';
const notSet = 'NOT_SET';
const inactive = 'inactive';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["cleanseEvaluators"] }] */
class User {
  /**
   * Passed a token, will retrieve and populate
   * local instance property with bootstrap
   *
   * IMPORTANT - it is assumed that the token has been
   * authenticated prior to class construction. Passing
   * an unauthorised token will retrieve a users bootstrap
   * directly from the bootstrapCache, without authentication
   *
   * An unauthorised token will fail fallback to bootstrap api
   *
   * @param token
   * @param cacheFacade (compatible with @auravisionlabs/aura-pkg-cache-facade)
   * @param authEnvBasePath
   * @param logger (should expose info and async error methods)
   * @param BootstrapBuilder
   * @param cacheKeyPrefix
   */
  constructor(token, cacheFacade, authEnvBasePath, logger, BootstrapBuilder, cacheKeyPrefix = 'bootstrap-') {
    this.token = token;
    this.cacheFacade = cacheFacade;
    this.authEnvBasePath = authEnvBasePath;
    this.logger = logger;
    this.cacheKeyPrefix = cacheKeyPrefix;
    this.BootstrapBuilder = BootstrapBuilder;

    this.logger.info({
      message: 'User.constructor start',
      contextObject: {
        authEnvBasePath,
        cacheKeyPrefix,
        cacheFacade: !!cacheFacade,
        BootstrapBuilder: !!BootstrapBuilder,
      },
    });
  }

  /**
   * Refresh the bootstrap for the current token.
   * This must be called after user instantiation
   * to retrieve the bootstrap.
   *
   * Can be called to refresh the bootstrap after
   * primary org update
   *
   * @param skipImpersonation
   * @returns {*}
   */
  async refresh(skipImpersonation = false) {
    this.logger.info({
      message: 'User.refresh start',
      contextObject: { skipImpersonation },
    });
    try {
      if (typeof this.token !== 'string') {
        throw new NotAuthorisedError(`Token missing or incorrect type: ${JSON.stringify(this.token)} (${typeof this.token})`);
      }

      this.bootstrap = await getUserBootstrap(
        this.token,
        this.cacheFacade,
        this.cacheKeyPrefix,
        this.authEnvBasePath,
        this.logger,
        this.BootstrapBuilder,
        skipImpersonation,
      );
      return this.bootstrap;
    } catch (err) {
      if (err instanceof NotAuthorisedError) throw err;
      throw new GenericAuthError(
        `User.refresh failed to refresh bootstrap: ${err}`,
        err,
      );
    }
  }

  /**
   * Return the sub of the token (this may not be the sub of the user
   * represented by the the bootstrap e.g. during impersonation)
   * @returns {*}
   */
  getTokenSub() {
    const decodedToken = jsonwebtoken.decode(this.token, { complete: true });
    // if we can't decode the token then throw NotAuthorisedError
    if (!decodedToken) throw new NotAuthorisedError('Token failed to decode');
    return get(decodedToken, `payload.${subClaim}`, false);
  }

  /**
   * Return a copy of the raw bootstrap data
   *
   * @returns {Promise<{}>}
   */
  async getRawBootstrap() {
    return cloneDeep(await get(this, 'bootstrap', {}));
  }

  /**
   * Return a copy of bootstrap capabilities
   *
   * @returns {Promise<CapArray>}
   */
  async getCapabilities() {
    return new CapArray(...get(await this.getRawBootstrap(), 'capabilities', []));
  }

  /**
   * Filters capabilities by eval function
   *
   * @returns {Promise<CapArray>}
   */
  async filterCapabilities(evalFunction) {
    return (await this.getCapabilities()).filter(evalFunction);
  }

  /**
   * Returns a capability object by name, if found,
   * if not found returns undefined
   * @param name
   * @returns {Promise<{}|boolean>}
   */
  async hasCapability(name) {
    return (await this.getCapabilities()).find(
      (cpblty) => cpblty.name === name,
    );
  }

  /**
   * Gets capabilities from bootstrap that match resource and operation
   *
   * @returns {Promise<CapArray>}
   */
  capabilitiesByResourceOp(resource, operation) {
    return this.filterCapabilities(
      (cpblty) => cpblty[`can${cptlze(operation)}`] && cpblty.resource === resource,
    );
  }

  /**
   * Get primary org id of user
   *
   * @returns {Promise<*>}
   */
  async getPrimaryOrgId() {
    return get(await this.getRawBootstrap(), 'primaryOrgId', notSet);
  }

  /**
   * Gets all user attributes, sets to 'NOT_SET' if attribute not in
   * Bootstrap
   *
   * @returns {Promise<*>}
   */
  async getAllUserAttributes() {
    return {
      userId: get(await this.getRawBootstrap(), 'userId', notSet),
      fullName: get(await this.getRawBootstrap(), 'fullName', notSet),
      status: get(await this.getRawBootstrap(), 'status', notSet),
      email: get(await this.getRawBootstrap(), 'email', notSet),
      primaryOrgId: get(await this.getRawBootstrap(), 'primaryOrgId', notSet),
      ownerOrgId: get(await this.getRawBootstrap(), 'ownerOrgId', notSet),
      organisations: get(await this.getRawBootstrap(), 'organisations', notSet),
    };
  }

  /**
   * Gets an property by name from Bootstrap,
   * sets to 'NOT_SET' if attribute not in
   *
   * Accepts dot notation
   *
   * @returns {Promise<*>}
   */
  async getAttribute(name) {
    return {
      [name]: get(await this.getRawBootstrap(), name, notSet),
    };
  }

  /**
   * Get JSON of bootstrap
   * @returns {Promise<string>}
   */
  async toJSON() {
    return JSON.stringify(await this.getRawBootstrap());
  }

  /**
   * Get owner org id of user
   *
   * @returns {Promise<*>}
   */
  async getOwnerOrgId() {
    return get(await this.getRawBootstrap(), 'ownerOrgId', notSet);
  }

  /**
   * Get users organisations
   *
   * @returns {Promise<*>}
   */
  async getOrganisations() {
    return get(await this.getRawBootstrap(), 'organisations', []);
  }

  /**
   * Get id of user
   *
   * @returns {Promise<*>}
   */
  async getUserId() {
    return get(await this.getRawBootstrap(), 'userId', notSet);
  }

  /**
   * Get status of user
   *
   * @returns {Promise<*>}
   */
  async getUserStatus() {
    return get(await this.getRawBootstrap(), 'status', notSet);
  }

  /**
   * Gets all rules
   *
   * @returns {Promise<*>}
   */
  async getRules() {
    return get(await this.getRawBootstrap(), 'rules', []);
  }

  /**
   * Get rules values (id's for location) of for rule type
   *
   * @returns {Promise<*>}
   */
  async getRuleValues(ruleType) {
    const theRule = (await this.getRules()).find((eachRule) => eachRule.type === ruleType);
    return new SetArray(...get(theRule, 'values', []));
  }

  /**
   * For a given rule type, returns true if items are a subset
   * of the values of the given ruleType
   *
   * @param ruleType
   * @param items
   * @returns {{evaluate: boolean}}
   */
  async ruleIsSubset(ruleType, items) {
    if (!Array.isArray(items)) throw new GenericAuthError('user.ruleIsSubset items must be array');
    const castItems = SetArray.from(items);
    return castItems.isSubSetOf(await this.getRuleValues(ruleType));
  }

  /**
   * For a given rule type, returns the intersection between
   * items and the values for the given rule type
   *
   * @param ruleType
   * @param items
   * @returns {{evaluate: boolean}}
   */
  async ruleIntersection(ruleType, items) {
    if (!Array.isArray(items)) throw new GenericAuthError('user.ruleIntersection items must be array');
    const castItems = SetArray.from(items);
    return castItems.intersectionWith(await this.getRuleValues(ruleType));
  }

  /**
   * For a given resource name, CRUD operation (create, read, delete, update)
   * and evaluator object, evaluates whether a user is authorised (returns true)
   * or not (throws NotAuthorisedError)
   *
   * The evaluator object may have 1 or more of the following properties set:
   * {
   *   orgIds   // array of orgIds of the resource being CRUDed
   *            // - must match users primary org
   *   userIds   // array of userIds of the resource being CRUDed
   *            // - all must match users id
   *   ruleIds  // array of ids to match against rules[ruleNameFromCapability]
   *            // - all must be present in bootstrap.rules[ruleNameFromCapability]
   * }
   * @param resource
   * @param crudOp
   * @param evaluators
   * @returns {{evaluate: NotAuthorisedError|boolean}}
   */
  can(resource, crudOp, evaluators) {
    return {
      evaluate: async function evaluate() {
        try {
          this.logger.info({
            message: 'User.can start',
            contextObject: { resource, crudOp, evaluators },
          });

          // Check user status - inactive throws NotAuthorisedError
          await this.notAuthorisedIfInactive();

          const matchedOnResourceOp = await this.capabilitiesByResourceOp(
            resource, crudOp,
          );

          // Do we have a matching capability for resource and crudOp?
          // Throw NotAuthorisedError if not
          await this.checkHasCapabilities(matchedOnResourceOp);

          // If any matchedOnResourceOp are 'all org' then authorise
          if (await this.checkHasAllOrg(matchedOnResourceOp, 'can')) return true;

          // Test each capability against Evaluators
          const authorised = await matchedOnResourceOp.asyncSome(
            await this.testCapabilityEvaluators(evaluators),
          );

          if (authorised) return true;

          throw new NotAuthorisedError();
        } catch (err) {
          if (err instanceof NotAuthorisedError) throw err;
          throw new GenericAuthError('User.can failed', err);
        }
      }.bind(this),
    };
  }

  /**
   * For a given resource name and CRUD operation (create, read, delete, update)
   * evaluates whether a user is authorised (returns true) or not
   * (throws NotAuthorisedError)
   *
   * It does not consider visibility type against evaluator object
   *
   * @param resource
   * @param crudOp
   * @returns {{evaluate: NotAuthorisedError|boolean}}
   */
  canCrud(resource, crudOp) {
    return {
      evaluate: async function evaluate() {
        try {
          this.logger.info({
            message: 'User.canCrud start',
            contextObject: { resource, crudOp },
          });

          // Check user status - inactive throws NotAuthorisedError
          await this.notAuthorisedIfInactive();

          const matchedOnResourceOp = await this.capabilitiesByResourceOp(
            resource, crudOp,
          );

          // Do we have a matching capability for resource and crudOp?
          // Throw NotAuthorisedError if not
          await this.checkHasCapabilities(matchedOnResourceOp);

          this.logger.info({
            message: 'User.canCrud, Authorised',
            contextObject: {},
          });

          return true;
        } catch (err) {
          if (err instanceof NotAuthorisedError) throw err;
          throw new GenericAuthError('User.canCrud failed', err);
        }
      }.bind(this),
    };
  }

  /**
   * For a given resource name and CRUD operation (create, read, delete, update)
   * and visibility 'all org' evaluates whether a user is authorised
   * (returns true) or not (throws NotAuthorisedError)
   *
   * This is useful for checking if the user is a Aura Admin for a given
   * resource name and CRUD operation
   *
   * @param resource
   * @param crudOp
   * @returns {{evaluate: NotAuthorisedError|boolean}}
   */
  canCrudAllOrgs(resource, crudOp) {
    return {
      evaluate: async function evaluate() {
        try {
          this.logger.info({
            message: 'User.canCrud start',
            contextObject: { resource, crudOp },
          });

          // Check user status - inactive throws NotAuthorisedError
          await this.notAuthorisedIfInactive();

          const matchedOnResourceOp = await this.capabilitiesByResourceOp(
            resource, crudOp,
          );

          // Do we have a matching capability for resource and crudOp?
          // Throw NotAuthorisedError if not
          await this.checkHasCapabilities(matchedOnResourceOp);

          // If any matchedOnResourceOp are 'all org' then authorise
          if (await this.checkHasAllOrg(matchedOnResourceOp, 'canCrudAllOrgs')) {
            this.logger.info({
              message: 'User.canCrudAllOrgs, Authorised',
              contextObject: { resource, crudOp },
            });
            return true;
          }
          // No match, throw NotAuthorisedError
          throw new NotAuthorisedError();
        } catch (err) {
          if (err instanceof NotAuthorisedError) {
            this.logger.info({
              message: 'User.canCrud Not Authorized',
              contextObject: { resource, crudOp },
            });
            throw err;
          }
          throw new GenericAuthError('User.canCrud failed', err);
        }
      }.bind(this),
    };
  }

  /**
   * For a given sub, evaluates whether a user is authorised
   * (token has sub, returns true) or not (throws NotAuthorisedError)
   *
   * This is useful checking basic rights such as viewing their
   * own bootstrap record
   *
   * @param sub
   * @returns {{evaluate: NotAuthorisedError|boolean}}
   */
  tokenHasSub(sub) {
    return {
      evaluate: async function evaluate() {
        try {
          this.logger.info({
            message: 'User.hasSub start',
            contextObject: { sub },
          });

          // Check user status - inactive throws NotAuthorisedError
          await this.notAuthorisedIfInactive();

          const tokenSub = this.getTokenSub();

          if (tokenSub === sub) {
            this.logger.info({
              message: 'User.hasSub, Authorised',
              contextObject: { sub, tokenSub },
            });
            return true;
          }

          // No match, throw NotAuthorisedError
          throw new NotAuthorisedError();
        } catch (err) {
          if (err instanceof NotAuthorisedError) throw err;
          throw new GenericAuthError('User.canCrud failed', err);
        }
      }.bind(this),
    };
  }

  /**
   * Cast evaluator arrays to setArrays to leverage setArray.isSubSetOf()
   *
   * If a function is passed in, then it will be executed to derive
   * evaluator param value
   *
   * @param evaluators
   * @returns {{ruleIds: (SetArray), orgIds: (SetArray), userIds: (SetArray)}}
   */
  async cleanseEvaluators(evaluators) {
    const castSetArray = (param) => (Array.isArray(param)
      ? SetArray.from(param) : new SetArray(param));
    return {
      orgIds: typeof evaluators.orgIds === 'function' ? castSetArray(await evaluators.orgIds()) : castSetArray(evaluators.orgIds),
      userIds: typeof evaluators.userIds === 'function' ? castSetArray(await evaluators.userIds()) : castSetArray(await evaluators.userIds),
      ruleIds: typeof evaluators.ruleIds === 'function' ? castSetArray(await evaluators.ruleIds()) : castSetArray(evaluators.ruleIds),
    };
  }

  /**
   * Test a capability visibility type against evaluators
   * Returns true for match
   * Returns falae for no match
   * @param evaluators
   * @returns {Promise<boolean>}
   */
  async testCapabilityEvaluators(evaluators) {
    const castEvaluators = await this.cleanseEvaluators(evaluators);
    const userPrimaryOrg = await this.getPrimaryOrgId();
    const userId = await this.getUserId();
    return async function testCapabilityEvaluators(cpblty) {
      this.logger.info({
        message: 'User.testCapabilityEvaluators start',
        contextObject: {
          cpblty, castEvaluators, evaluators, userPrimaryOrg, userId,
        },
      });
      switch (cpblty.visibility) {
        case ownOrg:
          if (castEvaluators.orgIds.isSubSetOf([userPrimaryOrg])) {
            this.logger.info({
              message: 'User.testCapabilityEvaluators ownOrg, Authorised',
              contextObject: { cpblty, castEvaluators },
            });
            return true;
          }
          break;
        case personal:
          if (castEvaluators.userIds.isSubSetOf([userId])) {
            this.logger.info({
              message: 'User.testCapabilityEvaluators personal, Authorised',
              contextObject: { cpblty, castEvaluators },
            });
            return true;
          }
          break;
        case rule: {
          const ruleValues = await this.getRuleValues(cpblty.ruleType);
          if (castEvaluators.ruleIds.isSubSetOf(ruleValues)) {
            this.logger.info({
              message: 'User.testCapabilityEvaluators rule, Authorised',
              contextObject: { cpblty, castEvaluators },
            });
            return true;
          }
          break;
        }
        default:
          throw new GenericAuthError(
            'user.testCapabilityEvaluators Unsupported visibility '
            + `type ${cpblty.visibility}`,
          );
      }
      // Nothing has matched, so return false
      this.logger.info({
        message: 'User.testCapabilityEvaluators no rules / evaluators matched, next capability',
        contextObject: {},
      });
      return false;
    }.bind(this);
  }

  /**
   * Wrapper for matching capability all org capabilities
   * @param capabilities
   * @returns {boolean}
   */
  checkHasAllOrg(capabilities, method) {
    return this.hasElements(
      capabilities.allOrgs(),
      `User.${method} matchedOnResourceOp has all org, Authorised`,
      `User.${method} matchedOnResourceOp does not have all orgs - next step`,
    );
  }

  /**
   * Wrapper for matching capability for resource and crudOp test
   * @param capabilities
   * @returns {boolean}
   */
  checkHasCapabilities(capabilities) {
    return this.notAuthorisedIfEmpty(
      capabilities,
      'User.can matchedOnResourceOp capabilities found, next step',
      'User.can matchedOnResourceOp length 0 - Not Authorised',
    );
  }

  /**
   * Not authorised if inactive
   *
   * @returns {boolean}
   */
  async notAuthorisedIfInactive() {
    this.logger.info({
      message: 'User.notAuthorisedIfInactive testing if inactive',
      contextObject: {},
    });

    const status = await this.getUserStatus();
    if (status === inactive || status === notSet) {
      this.logger.info({
        message: 'User.notAuthorisedIfInactive user is inactive or NOT_SET. Not Authorised',
        contextObject: { status },
      });
      throw new NotAuthorisedError();
    }
    return true;
  }

  /**
   * Array empty test - if empty throws not authorised
   * @param array
   * @param positiveMsg
   * @param negativeMsg
   * @returns {boolean}
   */
  notAuthorisedIfEmpty(array, positiveMsg, negativeMsg) {
    if (array.length === 0) {
      this.logger.info({
        message: negativeMsg,
        contextObject: { array },
      });
      throw new NotAuthorisedError();
    }
    this.logger.info({
      message: positiveMsg,
      contextObject: { array },
    });
    return true;
  }

  /**
   * Array has elements test - returns true if has elements
   * returns false if empty
   *
   * @param array
   * @param positiveMsg
   * @param negativeMsg
   * @returns {boolean}
   */
  hasElements(array, positiveMsg, negativeMsg) {
    // Do we have a matching capability for resource and crudOp?
    if (array.length === 0) {
      this.logger.info({
        message: negativeMsg,
        contextObject: { array },
      });
      return false;
    }
    this.logger.info({
      message: positiveMsg,
      contextObject: { array },
    });
    return true;
  }
}

module.exports = {
  User,
};
