// @flow

import { Fragment, Component } from 'react';
import { Spinner, Alert, Intent } from '@blueprintjs/core';
import RolePicker from '../pickers/user-role-picker';
import NoAreasPanel from '../no-areas-panel';
import {
  TableHeadings,
  TableHeadingColumn,
  FirstCellHeadingColumn,
  TableRowItem,
  TableRow,
  Table,
  StyledButton,
  AlertSpinner
} from './table';

import * as UserListModel from '../../models/user-list';
import * as UserModel from '../../models/user';
import Pagination from '../pagination';

type Props = {
  users: UserListModel.t,
  loading?: boolean,
  currentUser: ?UserModel.t,
  addRole(user: UserListModel.userT, roleId: number): void,
  removeRole(user: UserListModel.userT, roleId: number): void,
  deleteUser(userId: number, userType: UserListModel.userListTypeEnumT): void,
  permission: boolean,
};

type State = {
  userToDelete: ?UserListModel.userT,
  deleteUserInProgress: boolean,
  roleUpdatingForUser: ?number,
  page: number,
};

const itemsPerPage = 25;

class ActiveUsersTable extends Component<Props, State> {
  state = {
    userToDelete: null,
    deleteUserInProgress: false,
    roleUpdatingForUser: null,
    page: 0,
  };

  setUserToDelete(userToDelete: ?UserListModel.userT) {
    this.setState({ userToDelete });
  }

  async handleDeleteConfirm(userId: number) {
    const { deleteUser } = this.props;
    const { userToDelete } = this.state;

    if (userToDelete) {
      this.setState({ deleteUserInProgress: true });
      await deleteUser(userToDelete.id, userToDelete.userListType);
      this.setState({ userToDelete: null, deleteUserInProgress: false });
    }
  }

  async toggleRole(user: UserListModel.userT, roleId: number) {
    this.setState({ roleUpdatingForUser: user.id });
    if (user && user.roles && !!user.roles.find(r => r.id === roleId)) {
      await this.props.removeRole(user, roleId);
    } else {
      await this.props.addRole(user, roleId);
    }
    this.setState({ roleUpdatingForUser: null });
  }

  setPage(page: number) {
    this.setState({
      page,
    });
  }

  render() {
    const { users, loading, currentUser, permission } = this.props;
    const {
      userToDelete,
      deleteUserInProgress,
      roleUpdatingForUser,
      page,
    } = this.state;

    if (loading) {
      return <NoAreasPanel message={<Spinner size={22} />} />;
    }

    if (!permission) {
      return <NoAreasPanel message="You do not have permission to view the users list" />;
    }


    if (!users || users.length === 0) {
      return <NoAreasPanel message="No users" />;
    }

    const start = page * Math.max(0, itemsPerPage);
    const end = itemsPerPage > 0 ? Math.min(start + itemsPerPage, users.length) : users.length;
    if (start > end) this.setPage(0);
    const visibleUsers = users.slice(start, end);

    return (
      <Fragment>
        <Table>
          <thead>
            <TableHeadings>
              <FirstCellHeadingColumn>Active Users</FirstCellHeadingColumn>
              <TableHeadingColumn>Name</TableHeadingColumn>
              <TableHeadingColumn>Roles</TableHeadingColumn>
              <TableHeadingColumn>Actions</TableHeadingColumn>
            </TableHeadings>
          </thead>
          <tbody>
            {visibleUsers.map(user => {
              const isCurrentLoggedUser =
                !!currentUser && user.email === currentUser.email;

              return (
                <TableRow key={`active-user-key-${user.id}`}>
                  <TableRowItem>{user.email}</TableRowItem>
                  <TableRowItem>{user.fullName}</TableRowItem>
                  <TableRowItem>
                    <RolePicker
                      disabled={isCurrentLoggedUser}
                      selectedRoles={user.roles}
                      onClick={roleId => this.toggleRole(user, roleId)}
                      updating={roleUpdatingForUser === user.id}
                    />
                  </TableRowItem>
                  <TableRowItem>
                    <StyledButton
                      text="Remove user"
                      minimal
                      outlined
                      disabled={isCurrentLoggedUser}
                      onClick={() => this.setUserToDelete(user)}
                      intent={isCurrentLoggedUser ? '' : 'danger'}
                    />
                  </TableRowItem>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
        <Pagination pages={users.length / itemsPerPage} setPage={this.setPage.bind(this)} currentPage={page} />

        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete user"
          icon={deleteUserInProgress ? <AlertSpinner size="40" /> : 'trash'}
          intent={Intent.DANGER}
          isOpen={!!userToDelete}
          onCancel={() => {
            this.setUserToDelete(null);
          }}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete {userToDelete && userToDelete.email}
            ?
          </p>
        </Alert>
      </Fragment>
    );
  }
}

export default ActiveUsersTable;
