// @flow

import { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  ButtonGroup,
  Button,
  Intent,
  MenuItem,
  Menu,
  Popover,
  Position,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Desktop, Mobile } from '../layouts/devices-sizes';
import { BREAKDOWN_BY } from '../../constants/graph-options';
import { DISABLE_AGE_GENDER } from '../../constants/features';
import * as Selectors from '../../selectors';

import type { breakdownByEnum } from '../../constants/graph-options';

const StyledTooltip = styled(Tooltip2)`
  width: 100%;
  display: block;
`;

type Props = {
  selected: string,
  onChange(value: breakdownByEnum): any,
  showRole: boolean,
  showLocations: boolean,
  disabled?: boolean,
  disabledMessage?: string,
  recommendBreakdownByChange?: boolean,
  hideAgeAndGenderFilter: boolean,
};

const DemographicBreakdownPicker = ({
  onChange,
  selected,
  showRole,
  showLocations,
  disabled,
  disabledMessage,
  recommendBreakdownByChange,
  hideAgeAndGenderFilter,
}: Props) => {
  if (!showRole && hideAgeAndGenderFilter) return null;

  const InternalButton = ({ label, breakdownBy }) =>
    breakdownBy !== TOTAL_ONLY && disabledMessage && disabled ? (
      <Tooltip2 content={disabledMessage} position={Position.TOP}>
        {/* The blue print Button with the disable prop doesn't work well with the Tooltip */}
        {/* Possibly theres some mouse listeners that are intefering */}
        <button className="bp3-button bp3-disabled">
          <span className="bp3-button-text">{label}</span>
        </button>
      </Tooltip2>
    ) : (
      <Button
        intent={selected === breakdownBy ? Intent.PRIMARY : null}
        text={label}
        onClick={() => onChange(breakdownBy)}
      />
    );

  const InternalMenuItem = ({ label, breakdownBy }) =>
    breakdownBy !== TOTAL_ONLY && disabledMessage && disabled ? (
      <StyledTooltip content={disabledMessage} position={Position.TOP}>
        <MenuItem
          icon={selected === breakdownBy ? 'tick' : 'blank'}
          text={label}
          onClick={() => onChange(breakdownBy)}
          disabled
        />
      </StyledTooltip>
    ) : (
      <MenuItem
        icon={selected === breakdownBy ? 'tick' : 'blank'}
        text={label}
        onClick={() => onChange(breakdownBy)}
      />
    );

  const { TOTAL_ONLY, AGE, GENDER, ROLE, LOCATION } = BREAKDOWN_BY;

  const recommendationTooltipLabel = 'Recommended: Split by a demographic';

  return (
    <Fragment>
      <Desktop>
        <ButtonGroup>
          {recommendBreakdownByChange ? (
            <Tooltip2
              content={recommendationTooltipLabel}
              isOpen
              position={Position.TOP}
            >
              <InternalButton label="Total" breakdownBy={TOTAL_ONLY} />
            </Tooltip2>
          ) : (
            <InternalButton label="Total" breakdownBy={TOTAL_ONLY} />
          )}
          {showLocations && (
            <InternalButton label="Split by location" breakdownBy={LOCATION} />
          )}
          {!hideAgeAndGenderFilter && (
            <Fragment>
              <InternalButton label="Split by age" breakdownBy={AGE} />
              <InternalButton label="Split by gender" breakdownBy={GENDER} />
            </Fragment>
          )}
          {showRole && (
            <InternalButton label="Split by role" breakdownBy={ROLE} />
          )}
        </ButtonGroup>
      </Desktop>
      <Mobile>
        <Popover>
          <Button icon="split-columns" intent="success">
            Demographics
          </Button>
          <Menu>
            <InternalMenuItem label="Total" breakdownBy={TOTAL_ONLY} />
            {showLocations && (
              <InternalMenuItem
                label="Split by location"
                breakdownBy={LOCATION}
              />
            )}
            {!hideAgeAndGenderFilter && (
              <Fragment>
                <InternalMenuItem label="Split by age" breakdownBy={AGE} />
                <InternalMenuItem
                  label="Split by gender"
                  breakdownBy={GENDER}
                />
              </Fragment>
            )}
            {showRole && (
              <InternalMenuItem label="Split by role" breakdownBy={ROLE} />
            )}
          </Menu>
        </Popover>
      </Mobile>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    hideAgeAndGenderFilter: Selectors.userHasFeature(DISABLE_AGE_GENDER, state),
  };
}

export default connect(mapStateToProps)(DemographicBreakdownPicker);
