// @flow

import { Fragment } from 'react';
import OccupancyCard from './occupancy-card';
import OccupancyTitle from './ocuppancy-title';
import { Highlights, LiveFigure, TitleWrapper } from './common';
import { Tag } from '@blueprintjs/core';

type Props = {
  title?: string,
  errorText: string
};

const LocationErrorRow = ({ title, errorText }: Props) => (
  <Fragment>
    <TitleWrapper>
      <OccupancyTitle>{title}</OccupancyTitle>
    </TitleWrapper>
    <Highlights>
      <OccupancyCard>
        <Tag>Occupancy: Not available</Tag>
        <LiveFigure>
          <h3>{errorText}</h3>
        </LiveFigure>
      </OccupancyCard>
    </Highlights>
  </Fragment>
);

export default LocationErrorRow;
