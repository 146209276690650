// @flow

import { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Icon, Spinner } from '@blueprintjs/core';
import { genericRequest } from '../../services/thumbnailer';
import PlaceHolderImage from '../placeholder-image';

const StyledIcon = styled(Icon)`
  &&& {
    flex: 1;
  }
  fill: var(--dark-blue);
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;
const IconWrapper = styled.div`
  min-width: 250px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Text = styled.p`
  flex: 1;
  display: block;
  margin: 0;
  margin-top: 1rem;
`;

type Props = {
  imgUrl: string
};

type State = {
  previewImage: string | null,
  previewImageError: boolean
};

class SingleImageDisplayer extends Component<Props, State> {
  _isMounted = false;

  state = {
    previewImage: null,
    previewImageError: false
  };

  componentDidMount() {
    this._isMounted = true;

    this.loadImage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addPreviewImage = async (url: Object) => {
    if (this._isMounted) {
      return new Promise(resolve => {
        this.setState(
          {
            previewImage: URL.createObjectURL(url)
          },
          resolve
        );
      });
    }
  };

  addPreviewImageError = async (url: string) => {
    if (this._isMounted) {
      return new Promise(resolve => {
        this.setState(
          {
            previewImageError: true
          },
          resolve
        );
      });
    }
  };

  async loadImage() {
    const { imgUrl } = this.props;
    if (imgUrl) {
      try {
        const response = await genericRequest(imgUrl);

        if (response) {
          await this.addPreviewImage(response);
        }
      } catch (e) {
        await this.addPreviewImageError(imgUrl);
      }
    }
  }

  render() {
    const { previewImage, previewImageError } = this.state;

    return (
      <Fragment>
        {previewImage && (
          <PlaceHolderImage src={previewImage} width="100%" height="auto" />
        )}
        {previewImageError && (
          <IconWrapper>
            <StyledIcon iconSize={25} icon="media" />
            <Text>Error when loading the image.</Text>
          </IconWrapper>
        )}
        {!previewImage && !previewImageError && (
          <IconWrapper>
            <Spinner size={25} />
          </IconWrapper>
        )}
      </Fragment>
    );
  }
}

export default SingleImageDisplayer;
