
import SettingsNavbarTabs from './settings-navbar-tabs';
import { TopBar } from '../summary/layout';
import { Tablet } from '../../layouts/devices-sizes';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const SettingsPageHeader = ({ history }) => (
  <Tablet>
    <TopBar>
      <Container>
        <SettingsNavbarTabs history={history} />
      </Container>
    </TopBar>
  </Tablet>
);

export default SettingsPageHeader;
