// @flow

import { LOCATIONS, METRICS } from '../utils/metricsTableHelpers';

import type { labelObjectT } from '../utils/metricsTableHelpers';
import * as AvailableMetricsModel from './available-metrics';

export type filterT = {
  key: string,
  value: string,
};

export type t = {
  [subMenuKey: string]: {
    metrics: string[],
    taxonomies?: string[],
    filters?: filterT[],
  },
};

export type metricT = {
  key: string,
  label: string,
  description: string,
};

export type taxonomyConfgObjtT = {
  label: string,
  taxonomy: string,
  children: taxonomyConfgObjtT[],
};

export type filterConfgObjtT = {
  key: string,
  values: string[],
};

export type itemT = {
  metricGroupKey: string,
  metricKey: string,
  taxonomies?: Array<string>,
  taxonomy?: string,
  filter?: filterT,
  metric?: itemT,
};

export type activeMetricsListT = itemT[];

export type activeMetricsHeadingsT = {
  ...itemT,
  locationId?: string | string[],
  isLocationHeading?: boolean,
  isMetricHeading?: boolean,
  label?: number | string | labelObjectT,
};

export const getColumnsFromActiveMetrics = (
  activeMetrics: t
): activeMetricsListT => {
  const columns = Object.keys(activeMetrics).reduce(
    (totalColumns, activeMetricGroupKey) => {
      const currentTotalColumns = [...totalColumns];
      const { metrics, taxonomies, filters } =
        activeMetrics[activeMetricGroupKey];
      if (metrics && metrics.length > 0) {
        if (filters && filters.length > 0) {
          metrics.forEach((metricKey) => {
            filters.forEach((filter) => {
              currentTotalColumns.push({
                metricGroupKey: activeMetricGroupKey,
                metricKey,
                filter,
              });
            });
          });
        } else if (taxonomies && taxonomies.length > 0) {
          metrics.forEach((metricKey) => {
            taxonomies.forEach((taxonomy) => {
              currentTotalColumns.push({
                metricGroupKey: activeMetricGroupKey,
                metricKey,
                taxonomies: [taxonomy],
              });
            });
          });
        } else {
          metrics.forEach((metricKey) => {
            currentTotalColumns.push({
              metricGroupKey: activeMetricGroupKey,
              metricKey,
              taxonomies: undefined,
            });
          });
        }
      }
      return currentTotalColumns;
    },
    []
  );
  return columns;
};

export const isGeneralLocationHeading = (
  activeMetrics: activeMetricsHeadingsT | itemT
): boolean => {
  return (
    activeMetrics &&
    activeMetrics.isLocationHeading === true &&
    !activeMetrics.locationId &&
    activeMetrics.metricGroupKey === LOCATIONS &&
    activeMetrics.metricKey === LOCATIONS
  );
};

export const isGeneralMetricHeading = (
  activeMetrics: activeMetricsHeadingsT | itemT
): boolean => {
  return (
    activeMetrics &&
    activeMetrics.isMetricHeading === true &&
    activeMetrics.metricGroupKey === METRICS &&
    activeMetrics.metricKey === METRICS
  );
};

export const createActiveMetricsFromMetric = (activeMetric: itemT): t => {
  const { metricGroupKey, metricKey, taxonomies, filter } = activeMetric;

  return {
    [metricGroupKey]: {
      metrics: [metricKey],
      taxonomies,
      filters: [filter],
    },
  };
};

export const getListPropLength = (
  activeMetrics: t,
  subMenuKey: string,
  property: 'filters' | 'taxonomies'
): number => {
  return activeMetrics &&
    activeMetrics[subMenuKey] &&
    activeMetrics[subMenuKey][property] &&
    activeMetrics[subMenuKey][property].length > 0
    ? activeMetrics[subMenuKey][property].length
    : 0;
};

export type findCriteriaForActiveMetricT = {
  subMenuKey: string,
  metric?: string,
  taxonomy?: string,
  filter?: filterT,
};

export const isActiveMetricPropAlreadyInUse = (
  activeMetrics: t,
  { metric, taxonomy, filter, subMenuKey }: findCriteriaForActiveMetricT
): boolean => {
  let metricType = 'metrics';
  if (taxonomy) {
    metricType = 'taxonomies';
  }
  if (filter) {
    metricType = 'filters';
  }

  if (metricType === 'filters' && filter) {
    return !!(activeMetrics[subMenuKey][metricType] || []).find(
      (currentFilter) =>
        currentFilter.key === filter.key && currentFilter.value === filter.value
    );
  } else if (metricType === 'taxonomies' && taxonomy) {
    return (activeMetrics[subMenuKey][metricType] || []).includes(taxonomy);
  } else if (metricType === 'metrics' && metric) {
    return !!activeMetrics[subMenuKey][metricType].find(
      (currentMetric) => currentMetric === metric
    );
  }

  return false;
};

export type changeToActiveMetricsT = {
  subMenuKey: string,
  metricElementKey?: string,
  changeToTaxonomy?: string,
  changeToFilter?: filterT,
};

export const getUpdatedActiveMetrics = (
  existingActiveMetrics: t,
  {
    subMenuKey,
    metricElementKey,
    changeToTaxonomy,
    changeToFilter,
  }: changeToActiveMetricsT
): t => {
  let newMetrics = [];
  let currentEditing = 'metrics';
  if (changeToFilter) {
    currentEditing = 'filters';
  } else if (changeToTaxonomy) {
    currentEditing = 'taxonomies';
  }

  if (
    existingActiveMetrics[subMenuKey] &&
    existingActiveMetrics[subMenuKey][currentEditing] &&
    existingActiveMetrics[subMenuKey][currentEditing].length > 0
  ) {
    /**
     * Handle when there are metrics already existing
     **/

    // Handle filter items
    if (currentEditing === 'filters' && changeToFilter) {
      const isFilterAlreadyInUse = isActiveMetricPropAlreadyInUse(
        existingActiveMetrics,
        {
          subMenuKey,
          filter: changeToFilter,
        }
      );

      if (isFilterAlreadyInUse) {
        // Deletes current filter item already active
        newMetrics = (existingActiveMetrics[subMenuKey].filters || []).filter(
          (metric) =>
            metric.key === changeToFilter.key &&
            metric.value !== changeToFilter.value
        );
      } else {
        // Adds a new filter item
        const { key, value } = changeToFilter;
        newMetrics = [
          ...(existingActiveMetrics[subMenuKey][currentEditing] || []),
          {
            key,
            value,
          },
        ];
      }
    }

    // Handle taxonomies items
    if (currentEditing === 'taxonomies' && changeToTaxonomy) {
      const isTaxonomyAlreadyInUse = isActiveMetricPropAlreadyInUse(
        existingActiveMetrics,
        {
          subMenuKey,
          taxonomy: changeToTaxonomy,
        }
      );

      if (isTaxonomyAlreadyInUse) {
        // Deletes an already active taxonomy
        newMetrics = (
          existingActiveMetrics[subMenuKey][currentEditing] || []
        ).filter((taxonomy) => taxonomy !== changeToTaxonomy);
      } else {
        // Adds a new taxonomy
        newMetrics = [
          ...(existingActiveMetrics[subMenuKey][currentEditing] || []),
          changeToTaxonomy,
        ];
      }
    }

    // Handle metrics items
    if (currentEditing === 'metrics' && metricElementKey) {
      const isMetricAlreadyInUse = isActiveMetricPropAlreadyInUse(
        existingActiveMetrics,
        {
          subMenuKey,
          metric: metricElementKey,
        }
      );

      if (isMetricAlreadyInUse) {
        // Deletes an already active metric
        newMetrics = existingActiveMetrics[subMenuKey][currentEditing].filter(
          (metric) => metric !== metricElementKey
        );
      } else {
        // Adds a new metric
        newMetrics = [
          ...existingActiveMetrics[subMenuKey][currentEditing],
          metricElementKey,
        ];
      }
    }
  } else {
    /**
     * Handle when there are no metrics
     * (So it just adds one new metric to the currentEditing element)
     **/

    if (currentEditing === 'filters' && changeToFilter) {
      const { key, value } = changeToFilter;
      newMetrics = [
        {
          key,
          value,
        },
      ];
    }

    if (currentEditing === 'taxonomies' && changeToTaxonomy) {
      newMetrics = [changeToTaxonomy];
    }

    if (currentEditing === 'metrics' && metricElementKey) {
      newMetrics = [metricElementKey];
    }
  }

  const newActiveMetrics = {
    ...existingActiveMetrics,
    [subMenuKey]: {
      ...existingActiveMetrics[subMenuKey],
      //$FlowIgnore - no time to investigate right now
      [currentEditing]: newMetrics,
    },
  };

  // If there should be taxonomies, but there are none, then there can be no active metrics either, as they depend on the taxonomies to work
  // NOTE: this means taxonomies will always have to be selected before the metric.
  if (
    newActiveMetrics[subMenuKey] &&
    newActiveMetrics[subMenuKey].taxonomies &&
    newActiveMetrics[subMenuKey].taxonomies.length === 0
  ) {
    newActiveMetrics[subMenuKey].metrics = [];
  }

  return newActiveMetrics;
};

export const getErrorMessagesWhenMissingRequiredFields = (
  activeMetrics: t,
  availableMetrics: AvailableMetricsModel.t,
  skipContinueAnywayErrors: boolean
) => {
  const errors = [];

  Object.entries(activeMetrics).forEach(
    ([activeMetricKey, activeMetricValue]) => {
      if (
        // $FlowFixMe -> "property `metrics` is missing in  mixed [1]"
        (activeMetricValue.metrics && activeMetricValue.metrics.length > 0) ||
        // $FlowFixMe -> "property `taxonomies` is missing in  mixed [1]"
        (activeMetricValue.taxonomies &&
          activeMetricValue.taxonomies.length > 0) ||
        // $FlowFixMe -> "property `filters` is missing in  mixed [1]"
        (activeMetricValue.filters && activeMetricValue.filters.length > 0)
      ) {
        if (availableMetrics[activeMetricKey].taxonomies) {
          if (
            // $FlowFixMe -> "property `taxonomies` is missing in  mixed [1]"
            !activeMetricValue.taxonomies ||
            activeMetricValue.taxonomies.length === 0
          ) {
            errors.push({
              text: `Select at least 1 zone type in the ${availableMetrics[activeMetricKey].title} section`,
            });
          }

          if (
            // $FlowFixMe -> "property `metrics` is missing in  mixed [1]"
            (!activeMetricValue.metrics ||
              activeMetricValue.metrics.length === 0) &&
            !skipContinueAnywayErrors
          ) {
            errors.push({
              text: `Trying to add a ${availableMetrics[activeMetricKey].title} metric? You've selected a zone type, but no metrics`,
              allowContinueAnyway: true,
            });
          }
        }

        if (availableMetrics[activeMetricKey].filters) {
          if (
            // $FlowFixMe -> "property `filters` is missing in  mixed [1]"
            !activeMetricValue.filters ||
            activeMetricValue.filters.length === 0
          ) {
            errors.push({
              text: `Select at least 1 filter in the ${availableMetrics[activeMetricKey].title} section`,
            });
          }

          if (
            // $FlowFixMe -> "property `metrics` is missing in  mixed [1]"
            (!activeMetricValue.metrics ||
              activeMetricValue.metrics.length === 0) &&
            !skipContinueAnywayErrors
          ) {
            errors.push({
              text: `Trying to add a ${availableMetrics[activeMetricKey].title} metric? You've selected a filter, but no metrics`,
              allowContinueAnyway: true,
            });
          }
        }
      }
    }
  );

  return errors;
};
