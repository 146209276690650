// @flow

import styled from 'styled-components';
import { Button, Spinner } from '@blueprintjs/core';

export const TableHeadings = styled.tr``;
export const TableHeadingColumn = styled.th`
  text-align: start;
  padding: 1.5rem 0;
`;
export const FirstCellHeadingColumn = styled.th`
  text-align: start;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-top: 1.5rem;
  width: ${props => props.width && props.width};
`;

export const TableRowItem = styled.td`
  font-weight: normal;
  opacity: 0.9;
  border: none;
  padding: 0.5rem 0;
  padding-right: 1rem;
`;

export const TableRow = styled.tr`
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    background-color: rgb(239, 243, 246) !important;
  }

  ${TableRowItem}:first-child {
    padding-left: 1rem;
  }
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${TableRow}:nth-child(odd) {
    background-color: rgba(0, 0, 100, 0.01);
  }
`;

export const StyledButton = styled(Button)`
  opacity: 0.7;
`;

export const AlertSpinner = styled(Spinner)`
  margin-top: 0;
  margin-right: 20px;
`;
