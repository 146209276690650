// @flow

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import BarPieChart from '../bar-pie-chart';
import { prettyLabel } from '../../utils/prettyLabel';
import { getChartColors } from '../../styles/variables';
import { demographicValuesSortFn } from '../../utils/graphHelpers';
import * as QueryResponseModel from '../../models/query-response';

type Props = {
  type: string,
  data: QueryResponseModel.splitsT,
  unitType?: 'percentage' | 'seconds' | 'peopleCount' | 'number',
};

const BreakdownCell = ({ type, data, unitType }: Props) => {
  const theme = useContext(ThemeContext);
  const splits = data[type];
  if (!splits) return `Split by ${type} not available`;
  const keys = Object.keys(splits);
  return (
    <BarPieChart
      data={keys.sort(demographicValuesSortFn).map(key => ({
        key,
        label: prettyLabel(key),
        value: splits[key],
        color: getChartColors(theme)[`${type}_${key}`],
        unitType
      }))}
    />
  );
};

export default BreakdownCell;
