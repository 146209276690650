// @flow

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SettingsUsers from '../components/pages/settings/users';
import { USER_SETTINGS } from '../constants/page-settings';
import * as Selectors from '../selectors';
import * as PageSettingsActions from '../actions/page-settings';

import type { Dispatch } from 'redux';

function mapStateToProps(state) {
  return {
    user: Selectors.getUserModel(state),
    locations: Selectors.getLocations(state),
    settings: Selectors.getPageSettings(state, USER_SETTINGS)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      editSettings: PageSettingsActions.editUserSettingsPageSettings
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsUsers);
