const { BaseApi } = require('./base-api');

/**
 * Simple adapter for query api
 * This must be constructed with
 * a httpAdapter that implements
 * makeRequest method as defined
 * in AxiosHttpClient.makeRequest
 */
// getAuthToken cannot be static as it is called by BaseApi using {this}
// Late static binding not available in JS
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getAuthToken"] }] */
class QueryApi extends BaseApi {
  /**
   * Returns a payload object holding
   * the complete api call response
   *
   * @param url
   * @param queryPayload
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<Object>}
   */
  async genericQuery(
    { queryResource, queryPayload },
    shouldCache = false,
    cacheTtl = null,
    timeout = 30000,
  ) {
    this.logger({
      message: 'QueryApi.getQueryData',
      contextObject: { queryResource, queryPayload },
    });

    const url = `${this.envBasePath}/${queryResource}`;

    return this.makeRequest(
      'POST',
      url,
      await this.getHeaders(),
      queryPayload,
      shouldCache,
      cacheTtl,
      timeout,
    );
  }
}

module.exports = {
  QueryApi,
};
