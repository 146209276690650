// @flow

import { Component } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@blueprintjs/core';
import { Cell, IconsWrapper, Col } from './table';
import BreakdownCell from './breakdown-cell';
import ComparisonCell from './comparison-cell';
import { formatNumbersWithUnits } from '../../utils/formatNumbers';
import CellIcons from './cell-icons';
import HeadingLabel from './heading-label';
import { isAllLocationsCell } from '../../utils/metricsTableHelpers';

import * as PeriodModel from '../../models/period';
import type { breakdownByEnum } from '../../constants/graph-options';
import type { metricsTableCellT } from '../../utils/metricsTableHelpers';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
const StyledCol = styled(Col)`
  min-height: 18px;
`;
export const StyledCheckbox = styled(Checkbox)`
  &&& {
    margin-bottom: 0;
  }
`;

type Props = {
  cell: metricsTableCellT,
  index: number,
  breakdownBy: breakdownByEnum,
  setMetricPreviewImagesForModal(previews: string[]): void,
  width?: string,
  addBorderTop?: boolean,
  paddingLeft?: string,
  toggleLocation: (location: string | string[]) => void,
  isLocationSelected: boolean,
  excludeStaff: boolean,
  period: PeriodModel.t,
  compareToPastPeriod: ?PeriodModel.comparePeriodT
};
type State = {
  displayIcons: boolean
};
class ChartCell extends Component<Props, State> {
  state = {
    displayIcons: false
  };

  setDisplayIcons(value: boolean) {
    this.setState({
      displayIcons: value
    });
  }

  render() {
    const {
      cell,
      index,
      breakdownBy,
      setMetricPreviewImagesForModal,
      width,
      addBorderTop,
      paddingLeft,
      toggleLocation,
      isLocationSelected,
      excludeStaff,
      period,
      compareToPastPeriod
    } = this.props;
    const { displayIcons } = this.state;
    const cellKey = `${cell.metricGroupKey}-${cell.metricKey}-${
      cell.taxonomies ? cell.taxonomies.join(',') : ''
    }-${index}`;

    switch (cell.displayType) {
      case 'string': {
        return (
          <Cell
            key={cellKey}
            width={width}
            addBorderTop={addBorderTop}
            paddingLeft={paddingLeft}
            cellType={cell.displayType}
          >
            <Row>
              {cell.isLocationHeading && !isAllLocationsCell(cell) && (
                <StyledCheckbox
                  checked={isLocationSelected}
                  onChange={() => toggleLocation(cell.locationId)}
                />
              )}
              {cell.value && typeof cell.value === 'object' ? (
                <div>
                  <HeadingLabel labelObject={cell.value} />
                </div>
              ) : (
                cell.value
              )}
            </Row>
          </Cell>
        );
      }

      case 'number': {
        return (
          <Cell
            key={cellKey}
            width={width}
            addBorderTop={addBorderTop}
            paddingLeft={paddingLeft}
            cellType={cell.displayType}
            hasPreviews={!!cell.previews && cell.previews.length > 0}
            onMouseEnter={() => this.setDisplayIcons(true)}
            onMouseLeave={() => this.setDisplayIcons(false)}
          >
            <Row>
              <StyledCol>
                {cell.value &&
                  cell.unitType &&
                  formatNumbersWithUnits(cell.value, cell.unitType)}
                {cell.isComparison &&
                  typeof cell.valueToCompare === 'number' &&
                  typeof cell.value === 'number' && (
                    <ComparisonCell
                      valueToCompare={cell.valueToCompare}
                      value={cell.value}
                    />
                  )}
              </StyledCol>
              <IconsWrapper displayIcons={displayIcons}>
                <CellIcons
                  cell={cell}
                  excludeStaff={excludeStaff}
                  period={period}
                  setMetricPreviewImagesForModal={
                    setMetricPreviewImagesForModal
                  }
                  compareToPastPeriod={compareToPastPeriod}
                />
              </IconsWrapper>
            </Row>
          </Cell>
        );
      }

      case 'splits':
        return (
          <Cell
            key={cellKey}
            width={width}
            addBorderTop={addBorderTop}
            paddingLeft={paddingLeft}
            cellType={cell.displayType}
          >
            {cell.splits && (
              <BreakdownCell
                unitType={cell.unitType}
                type={breakdownBy}
                data={cell.splits}
              />
            )}
          </Cell>
        );

      default:
        return null;
    }
  }
}
export default ChartCell;
