const { CoreApiException } = require('./exceptions/core-api-exception');
const { BaseApi } = require('./base-api');
const { throwOnIntRangeOutOfBound } = require('./utilities/validation');

const scheduleResource = '/v1/scheduled-report-job/listWithinTimeParams';
const cameraServerResource = '/v1/cameraServer/list';
const RecordingResource = '/v1/recording';
const DashboardReportResource = '/v1/dashboard-report';
const SegmentResource = '/v1/segment';
const ConfigurationResource = '/configuration';
const ruleEvaluationResource = '/v1/rule-evaluation/';
const locationResource = '/v1/location';
const ruleEvaluationOrgResource = '/organisation/';

/**
 * Simple adapter for core api
 * This must be constructed with
 * a httpAdapter that implements
 * makeRequest method as defined
 * in AxiosHttpClient.makeRequest
 */
// getAuthToken cannot be static as it is called by BaseApi using {this}
// Late static binding not available in JS
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getAuthToken"] }] */
class CoreApi extends BaseApi {
  /**
   * Get camera servers
   *
   * params is an object that may include the following
   * property : value pairs -
   *    includes - list of relationships to include
   *    (locationDetailsWithoutTransactions, organisationDetails etc)
   *    separated by ',' - set to undefined to
   *
   *    status - list of api status values to constrain by
   *    separated by ','
   *
   *    name - list of cameraServer names to constrain by
   *    separated by ','
   *
   * shouldCache - bool indicating if result should be cached
   * NOTE - you need to construct with a cache facade to use this
   *
   * cachetTtl - how long to cache for (secs)
   *
   * Returns a payload object holding
   * the complete api call response
   *
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<[]>}
   */
  async getCameraServers(
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message: `CoreApi.getCameraServers called with params: ${params}`,
    });

    return this.makeRequest(
      'POST',
      `${this.envBasePath}${cameraServerResource}`,
      await this.getHeaders(),
      { ...params },
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Gets all locations matching the users allowed locations
   * (via the location rule within their bootstrap)
   *
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<[]>}
   */
  async getMyLocations(
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message: `CoreApi.getMyLocations called with params: ${params}`,
    });

    return this.makeRequest(
      'POST',
      `${this.envBasePath}${locationResource}/list`,
      await this.getHeaders(),
      { ...params },
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Get recording list
   *
   * params is an object that may include the following
   * property : value pairs -
   *    includes - list of relationships to include
   *    (locationDetailsWithoutTransactions, organisationDetails etc)
   *    separated by ',' - set to undefined to
   *
   *    filter - list of api filter values separated by ','
   *
   * shouldCache - bool indicating if result should be cached
   * NOTE - you need to construct with a cache facade to use this
   *
   * cachetTtl - how long to cache for (secs)
   *
   * Returns a payload object holding
   * the complete api call response
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getRecordingList(
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message: `CoreApi.getRecordingList called with params: ${params}`,
    });

    return this.makeRequest(
      'POST',
      `${this.envBasePath}${RecordingResource}/list`,
      await this.getHeaders(),
      { ...params },
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Get Segment Sum of Frames
   *
   * params is an object that may include the following
   * property : value pairs -
   *    recordings - list of recordings to search over
   *    start - period to start searching from
   *    end - period to stop searching from
   *    aggregation - the aggregationType to combine segments into
   *    timezone - the timezone of the recordings
   *    insideSchedule - whether to filter on insideSchedule or not
   *
   * shouldCache - bool indicating if result should be cached
   * NOTE - you need to construct with a cache facade to use this
   *
   * cachetTtl - how long to cache for (secs)
   *
   * Returns a payload object holding the complete api call response
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getSegmentSumFrames(
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message: `CoreApi.getSegmentSumFrames called with params: ${params}`,
    });

    return this.makeRequest(
      'POST',
      `${this.envBasePath}${SegmentResource}/sumFrames`,
      await this.getHeaders(),
      { ...params },
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Get Segment Avg of FPS
   *
   * params is an object that may include the following
   * property : value pairs -
   *    recordings - list of recordings to search over
   *    start - period to start searching from
   *    end - period to stop searching from
   *    aggregation - the aggregationType to combine segments into
   *    timezone - the timezone of the recordings
   *    insideSchedule - whether to filter on insideSchedule or not
   *
   * shouldCache - bool indicating if result should be cached
   * NOTE - you need to construct with a cache facade to use this
   *
   * cachetTtl - how long to cache for (secs)
   *
   * Returns a payload object holding the complete api call response
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getSegmentAvgFps(
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message: `CoreApi.getSegmentAvgFps called with params: ${params}`,
    });

    return this.makeRequest(
      'POST',
      `${this.envBasePath}${SegmentResource}/avgFps`,
      await this.getHeaders(),
      { ...params },
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Get recording by Id
   * @param recordingId
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getRecording(
    recordingId,
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message: `CoreApi.getRecording called with recording id ${recordingId} and params: ${params}`,
    });
    const url = this.decorateUrlQuery(
      `${this.envBasePath}${RecordingResource}/${recordingId}`,
      {
        ...params,
      },
    );

    return this.makeRequest(
      'GET',
      url,
      await this.getHeaders(),
      null,
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Get recording configuration
   * @param recordingId
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getRecordingConfiguration(
    recordingId,
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message:
        'CoreApi.getRecordingConfiguration called with' +
        `recording id ${recordingId} and params: ${params}`,
    });
    const url = this.decorateUrlQuery(
      `${this.envBasePath}${RecordingResource}/${recordingId}${ConfigurationResource}`,
      { ...params },
    );

    return this.makeRequest(
      'GET',
      url,
      await this.getHeaders(),
      null,
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Gets the reports to be run for a given
   * day number and hour number
   *
   * @param day
   * @param hour
   * @param timeout
   * @returns {Promise<Object>}
   */
  async getScheduledReports(dayOfMonth, dayOfWeek, hour, timeout = 180000) {
    this.logger({
      message: `CoreApi.getScheduledReports dayOfMonth: ${dayOfMonth}, dayOfWeek: ${dayOfWeek}, hour: ${hour}`,
    });
    throwOnIntRangeOutOfBound(
      dayOfMonth,
      1,
      31,
      new CoreApiException(
        `CoreApi.getScheduledReports dayOfMonth must be int in range 1 - 31, ${dayOfMonth} provided`,
      ),
    );

    throwOnIntRangeOutOfBound(
      dayOfWeek,
      0,
      6,
      new CoreApiException(
        `CoreApi.getScheduledReports dayOfWeek must be int in range 0 - 6, ${dayOfWeek} provided`,
      ),
    );

    throwOnIntRangeOutOfBound(
      hour,
      0,
      23,
      new CoreApiException(
        `CoreApi.getScheduledReports hour must be int in range 0 - 23, ${hour} provided`,
      ),
    );

    try {
      const response = await this.httpAdapter.makeRequest({
        logger: this.logger,
        method: 'GET',
        headers: await this.getHeaders(),
        url: this.decorateUrlQuery(`${this.envBasePath}${scheduleResource}`, {
          sendDayOfMonth: dayOfMonth,
          sendDayOfWeek: dayOfWeek,
          sendHour: hour,
        }),
        payload: null,
        shouldCache: false,
        timeout,
      });

      return response.payload;
    } catch (error) {
      throw new CoreApiException(
        `CoreApi.getScheduledReports API request has failed for dayOfMonth: ${dayOfMonth}, dayOfWeek: ${dayOfWeek}, hour: ${hour}`,
        error,
      );
    }
  }

  /**
   * Get recording uptime compatibility stub
   * TODO: remove this later
   * @param recordingId
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   * @deprecated
   */
  async getRecordingUptime(
    recordingId,
    params,
    shouldCache,
    cacheTtl,
    timeout,
  ) {
    const result = await this.getRecordingUptimes(
      [recordingId],
      params,
      shouldCache,
      cacheTtl,
      timeout,
    );
    return result.length ? result[0] : null;
  }

  /**
   * Get recording uptimes
   * @param recordingIds
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getRecordingUptimes(
    recordingIds,
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message:
        'CoreApi.getRecordingUptimes called with' +
        `recording ids ${recordingIds} and params: ${params}`,
    });
    const url = this.decorateUrlQuery(
      `${this.envBasePath}${RecordingResource}/uptimes`,
      { ...params },
    );

    return this.makeRequest(
      'POST',
      url,
      await this.getHeaders(),
      {
        recids: recordingIds,
        ...params,
      },
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Get dashboard report
   * @param dashboardReportId
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getDashboardReport(
    dashboardReportId,
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message:
        'CoreApi.getDashboardReport called with' +
        `dashboardReportId ${dashboardReportId} and params: ${params}`,
    });
    const url = this.decorateUrlQuery(
      `${this.envBasePath}${DashboardReportResource}/${dashboardReportId}`,
      { ...params },
    );

    return this.makeRequest(
      'GET',
      url,
      await this.getHeaders(),
      null,
      shouldCache,
      cacheTtl,
      timeout,
    );
  }

  /**
   * Calls the rule-evaluation endpoint with
   * a rule type, org id and rule payload
   *
   * Returns the result of the evaluation
   * @param ruleType
   * @param organisation
   * @param rulePayload
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   */
  async evaluateRule(
    ruleType,
    organisation,
    rulePayload = {},
    shouldCache = false,
    cacheTtl = null,
    timeout = 180000,
  ) {
    this.logger({
      message:
        'CoreApi.evaluateRule called with' +
        `rule type ${ruleType} and organisation: ${organisation}`,
    });
    const url =
      `${this.envBasePath}${ruleEvaluationResource}${ruleType}` +
      `${ruleEvaluationOrgResource}${organisation}`;

    return this.makeRequest(
      'POST',
      url,
      await this.getHeaders(),
      rulePayload,
      shouldCache,
      cacheTtl,
      timeout,
    );
  }
}

module.exports = {
  CoreApi,
};
