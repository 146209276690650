import styled from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';

const COLUMN_MOBILE_WIDTH = '150px';

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  position: relative;
`;
const TableHeaders = styled.thead`
  background: var(--medium-gray);
  border-top: 1px solid #e6eaee;
  border-bottom: 1px solid #e6eaee;
  text-align: left;
`;
const TableHeading = styled.th`
  color: #7f8fa4;
  padding: 9px 18px 9px 0;
  font-size: 1em;
  font-weight: 500;
  width: ${(props) =>
    props.columnsNumber > variables.columnsNumberNeededBeforeHavingFixWitdh
      ? '250px'
      : props.columnsNumber < 3
      ? '20%'
      : props.currentCellWidth
      ? `${props.currentCellWidth}px`
      : 'auto'};

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: ${COLUMN_MOBILE_WIDTH};
    padding: 9px 15px 9px 0;
  }

  &:first-child {
    padding-left: 30px;
    width: ${(props) =>
      props.columnsNumber > variables.columnsNumberNeededBeforeHavingFixWitdh
        ? '350px;'
        : '35%;'};

    @media (max-width: ${breakpoints.TABLET_SIZE}) {
      width: ${COLUMN_MOBILE_WIDTH};
      padding-left: 15px;
    }
  }

  &:last-child {
    padding-right: 30px;

    @media (max-width: ${breakpoints.TABLET_SIZE}) {
      padding-right: 15px;
    }
  }
`;
const TableBodyContainer = styled.tbody`
  padding: 0 30px;
`;
const TableRow = styled.tr`
  border-bottom: 1px solid #e6eaee;
  padding: 1rem;
`;
const TotalTableRow = styled.tr`
  border-bottom: 1px solid #e6eaee;
  border-top: 1px solid #e6eaee;
  background-color: var(--light-grey);
`;
export const Col = styled.div`
  flex: 1;
`;
export const IconsWrapper = styled(Col)`
  display: flex;
  opacity: ${({ displayIcons }) => (displayIcons ? '1' : '0')};
  justify-content: flex-end;
`;
const Cell = styled.td`
  ${({ cellType, hasPreviews }) =>
    cellType === 'string' ||
    cellType === 'splits' ||
    (cellType === 'number' && !hasPreviews)
      ? 'padding: 35px 35px 35px 0;'
      : ''}

  position: relative;

  &:first-child {
    padding-left: 30px;

    @media (max-width: ${breakpoints.TABLET_SIZE}) {
      padding-left: 15px;
    }
  }
  &:last-child {
    padding-right: 30px;

    @media (max-width: ${breakpoints.TABLET_SIZE}) {
      padding-right: 15px;
    }
  }

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 15px 15px 15px 0;
    ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft};` : '')}
    width: ${COLUMN_MOBILE_WIDTH}
  }

  ${({ addBorderTop }) =>
    addBorderTop ? 'border-top: 1px solid #e6eaee;' : ''}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft};` : '')}

  .bp3-popover-target {
    width: 100%;
  }

  .bp3-popover-wrapper-custom {
    margin: 0 1rem;
  }
`;
const LocationName = styled.h4`
  font-weight: 500;
  font-size: 1.2em;
  margin: 0;
  padding: 0;
`;
const TabHeading = styled.div`
  font-size: 1.2em;
  cursor: pointer;
  padding: 23px 30px;
  color: #354052;
  border-bottom: ${(props) => (props.active ? '3px solid #2EA2F8;' : 'none')};
  position: relative;
  ${({ rightBorder }) =>
    rightBorder &&
    `
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 50%;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #dfe3e9;
    }
  `};
`;

export {
  Table,
  TableHeaders,
  TableHeading,
  TableBodyContainer,
  TableRow,
  Cell,
  TotalTableRow,
  LocationName,
  TabHeading,
  TableWrapper,
};
