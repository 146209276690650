// @flow

import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import styled from 'styled-components';
import {
  getOccupancyAsPc,
  getOccupancy,
  getCapacity
} from '../../../utils/live-occupancy';
import { LIVE_OCC } from '../../../constants/routes';

import type { locationConfigT } from '../live-occupancy/location-occupancy-row';

const SmallFigureText = styled.p`
  font-size: 0.6em;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
`;
const LiveFigureText = styled.div`
  font-size: 4em;
  color: rgba(0, 0, 0, 0.75);
`;
const LiveSectionWrapper = styled.div`
  width: 40%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

type Props = {
  location: locationConfigT,
  redirectToUrlButton?: string,
  isDemoUser?: boolean,
};

const LiveSection = ({ location, redirectToUrlButton, isDemoUser = false }: Props) => {
  const showCapacityDetails =
    location.currentOccupancy && !location.missingData;

  return (
    <LiveSectionWrapper>
      <LiveFigureText>
        <h3>{getOccupancyAsPc({ location, excludeStaff: false, isDemoUser })}</h3>
        {showCapacityDetails && (
          <Fragment>
            <SmallFigureText>
              {getOccupancy({ location, excludeStaff: false })} people present
            </SmallFigureText>
            <SmallFigureText>({getCapacity(location)} maximum)</SmallFigureText>
          </Fragment>
        )}
      </LiveFigureText>
      <Link to={redirectToUrlButton || LIVE_OCC}>
        <Button icon="fullscreen" minimal outlined>
          Exit fullscreen
        </Button>
      </Link>
    </LiveSectionWrapper>
  );
};

export default LiveSection;
