// @flow
import { cloneDeep } from 'lodash';
import * as PERIOD_PRESETS from '../constants/period-presets';
import * as PREVIOUS_PERIOD_PRESETS from '../constants/previous-period-presets';
import { EXPLORER as EXPLORER_ROUTE } from '../constants/routes';
import { EXPLORER } from '../constants/page-settings';
import type { pageSettingsListT } from '../constants/page-settings';
import history from '../root/history';

import * as PeriodModel from '../models/period';
import * as PageSettingsModel from '../models/page-settings';
import * as ActiveMetricsModel from '../models/active-metrics';

export const validateUrlToState = (data: Object) => {
  let newState = cloneDeep(data);

  if (data.period && data.period.selectedPreset !== PERIOD_PRESETS.custom) {
    const period = PeriodModel.getExplorerPeriod(data.period.selectedPreset);
    newState.period.selectedDates = period.selectedDates;
  }

  if (data.period && data.period.selectedPreset === PERIOD_PRESETS.custom) {
    newState.period.selectedDates = newState.period.customPeriodDates;
  }

  if (data.compareToPast.active) {
    let comparePeriod;

    if (data.compareToPast.selectedPreset === PREVIOUS_PERIOD_PRESETS.custom) {
      comparePeriod = PeriodModel.getExplorerComparePeriod(
        newState.compareToPast.selectedPreset,
        newState.compareToPast.customPeriodDates
      );
    } else {
      comparePeriod = PeriodModel.getExplorerComparePeriod(
        newState.compareToPast.selectedPreset,
        newState.period.selectedDates
      );
    }

    newState.compareToPast.selectedDates = comparePeriod.selectedDates;
  }

  return newState;
};

export const validateStateToUrl = (data: Object) => {
  let newUrlState = cloneDeep(data);

  if (data.period && data.period.selectedPreset !== PERIOD_PRESETS.custom) {
    // Removes the seletecDates and customPeriodDates when selectedPreset is not custom
    newUrlState.period.selectedDates = undefined;
    newUrlState.period.customPeriodDates = undefined;
  }

  if (data.period && data.period.selectedPreset === PERIOD_PRESETS.custom) {
    // Removes the seletecDates when selectedPreset is customPeriod
    newUrlState.period.selectedDates = undefined;
  }

  if (data.compareToPast && data.compareToPast.active) {
    // Removes the period from the compareToPast prop
    newUrlState.compareToPast.selectedDates = undefined;
  }

  return newUrlState;
};

export const getUrlHashAsObject = (stringData: string): Object => {
  try {
    if (!stringData) return {};
    const decodedUrl = decodeURI(stringData);
    const dataWithoutHash = decodedUrl.substr(1);
    const data = JSON.parse(dataWithoutHash);
    return data;
  } catch (e) {
    console.error('Couldn\'t convert the hash into a valid object');
    throw e;
  }
};

export const convertFromUrlHashToObject = (
  stringData: string,
  page: pageSettingsListT = EXPLORER
): Object => {
  try {
    const data = getUrlHashAsObject(stringData);
    return validateUrlToState(data);
  } catch (e) {
    return PageSettingsModel.defaults[page];
  }
};

export const convertFromObjectToUrlHash = (data: Object): string => {
  const validatedState = validateStateToUrl(data);
  const stringData = JSON.stringify(validatedState);
  const urlEncodedData = encodeURI(stringData);

  return '#' + urlEncodedData;
};

export const redirectWithStateToExplorerPage = (
  metrics: Array<PageSettingsModel.metricItemT>,
  period: PeriodModel.t,
  compareToPast: ?PeriodModel.comparePeriodT
): void => {
  const defaultSettingsExplorerPage = PageSettingsModel.defaults[EXPLORER];
  const explorerPageSettings = {
    ...defaultSettingsExplorerPage,
    compareToPast,
    period,
    metrics
  };

  const objectUrlParsed = convertFromObjectToUrlHash(explorerPageSettings);
  const url = EXPLORER_ROUTE + objectUrlParsed;

  history.push(url);
};

export type getMetricFromGridItemArgsT = ActiveMetricsModel.itemT & {
  locationId: string | string[],
  excludeStaff: boolean
};

export const getMetricFromGridItem = ({
  metricGroupKey,
  metricKey,
  taxonomies,
  locationId,
  excludeStaff,
  filter
}: getMetricFromGridItemArgsT): PageSettingsModel.metricItemT => {
  const locations = typeof locationId === 'string' ? [locationId] : locationId;
  const metric = {
    metric: {
      metricGroupKey,
      metricKey,
      taxonomies,
      filter
    },
    location: locations,
    demographicFilter: {}
  };

  if (excludeStaff) {
    metric.demographicFilter = {
      role: 'customer'
    };
  }

  return metric;
};

export type validateRedirectToExplorerPageT = {
  locationsSelectedForComparison: Array<string>,
  metricsSelectedForComparison: Array<ActiveMetricsModel.itemT>
};

export const validateRedirectToExplorerPage = ({
  locationsSelectedForComparison,
  metricsSelectedForComparison
}: validateRedirectToExplorerPageT): string[] => {
  const errors = [];
  if (locationsSelectedForComparison.length === 0) {
    const error = 'Select at least one location to compare.';
    errors.push(error);
  }

  if (metricsSelectedForComparison.length === 0) {
    const error = 'Select at least one metric to compare.';

    errors.push(error);
  }

  return errors;
};
