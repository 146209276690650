import { SETTINGS_MANAGE_SINGLE_LOCATION } from '../../../../../../constants/routes';
import { GOOGLE_MAPS_API_KEY } from '../../../../../../config/vars';
import { CenteredCell, StyledRow } from '.';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BinaryUptimeIndicator } from './binary-uptime-indicator';
import { VariableUptimeIndicator } from './variable-uptime-indicator';
import { firstLetterUppercase } from '../../../../../../utils/firstLetterUppercase';
import { Classes, Spinner } from '@blueprintjs/core';

const getStatusDetails = (
  status,
): {
  intent: string,
  label: string,
  description?: string,
} => {
  if (status === 'setting up') {
    return {
      intent: 'primary',
      label: 'Setting up',
      description:
        'This location is still being set up. We may be waiting for you to install the devices or we may be fine-tuning our demographic detection AI. Please contact support@auravision.ai for more up to date information.',
    };
  }
  if (status === 'operational') {
    return { intent: 'success', label: 'Active' };
  }
  if (status === 'near contract end') {
    return {
      intent: 'warning',
      label: 'Near end of license',
      description:
        'Please contact your account manager or sales@auravision.ai to renew the license and ensure there is no data loss.',
    };
  }
  if (status === 'contract ended') {
    return {
      intent: 'danger',
      label: 'License ended. Data may be removed soon',
      description:
        'Please contact your account manager or sales@auravision.ai to discuss renewing this and any other lapsed licenses.',
    };
  }
  // if no status set, assume it's active
  return { intent: 'success', label: 'Active' };
};

export const LocationRow = ({
  name,
  status,
  id,
  coordinates,
  recordingsUp,
  recordingsTotal,
  loadingRecordingStatus,
}) => {
  const history = useHistory();

  const navigate = () =>
    history.push(`${SETTINGS_MANAGE_SINGLE_LOCATION}/${id}`);

  const mapsImg = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates}&zoom=7&size=300x150&markers=color:blue%7C${coordinates}&maptype=roadmap&key=${GOOGLE_MAPS_API_KEY}`;
  return (
    <StyledRow onClick={!window.isDemoOrg ? () => navigate() : null} hover={!window.isDemoOrg}>
      <td>{name}</td>
      <CenteredCell>
        <img src={mapsImg} alt="Google map of this location" />
      </CenteredCell>
      <CenteredCell>
        {loadingRecordingStatus ? (
          <Spinner className={Classes.SMALL} />
        ) : (
          <BinaryUptimeIndicator status={recordingsUp > 0} />
        )}
      </CenteredCell>
      <CenteredCell>
        {loadingRecordingStatus ? (
          <Spinner className={Classes.SMALL} />
        ) : (
          <>
            <VariableUptimeIndicator value={recordingsUp / recordingsTotal} />
            {recordingsUp} / {recordingsTotal}
          </>
        )}
      </CenteredCell>
      <CenteredCell>
        {firstLetterUppercase(getStatusDetails(status).label)}
      </CenteredCell>
    </StyledRow>
  );
};
