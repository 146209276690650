import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import CustomTooltip from './tooltip';
import { getChartColors } from '../../styles/variables';
import { axisTimeFormatter, getAxisInterval } from '../../utils/graphHelpers';
import { prettyPercentage } from '@auravisionlabs/aura-pkg-ui-formatter';

const RelativeBarGraph = ({
  chartData,
  activeLines,
  aggregationPeriod,
  hasFilterApplied,
  axisType,
}, chartRef) => {
  const theme = useContext(ThemeContext);
  const chartColors = getChartColors(theme);
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={chartData} ref={chartRef ? chartRef : null}>
        <XAxis
          dataKey="index"
          tickFormatter={value =>
            axisTimeFormatter(value, aggregationPeriod, axisType)
          }
          interval={getAxisInterval(aggregationPeriod)}
        />
        <YAxis
          domain={[0, 'dataMax']}
          tickFormatter={prettyPercentage}
          ticks={[0, 20, 40, 60, 80, 100]}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          formatter={prettyPercentage}
          content={
            <CustomTooltip
              axisType={axisType}
              hasFilterApplied={hasFilterApplied}
              aggregationPeriod={aggregationPeriod}
            />
          }
        />
        <Legend />
        {activeLines.map(l => (
          <Bar
            key={l.key}
            name={l.label}
            stackId={'a'}
            fill={chartColors[l.key] || l.color}
            dataKey={l.key}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

// $FlowFixMe Flow does not support forwarding refs properly
export default React.forwardRef(RelativeBarGraph);
