// @flow
import * as ACTIONS from '../constants/actions';
import { recordings } from '../services/api';
import * as RecordingModel from '../models/recording';
import * as HeatmapModel from '../models/heatmap';
import * as LocationModel from '../models/location';
import { errorToast } from '../utils/toaster';
import { sendMessage } from '../services/sql-api';
import type { FluxStandardAction } from 'flux-standard-action';
import { getOptions } from '../models/user';
import { fetchLocations, fetchOpeningTimes } from './locations';

export const fetch: FluxStandardAction<string, null, *> =
  (include) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTIONS.FETCH_RECORDINGS_REQUEST });

      const [recordingsResponse, locationsResponse, openingTimesResponse] =
        await Promise.all([
          fetchRecordings(),
          fetchLocations(),
          fetchOpeningTimes(),
        ]);
      
      if (
        !!recordingsResponse &&
        !!locationsResponse &&
        !!openingTimesResponse
      ) {
        const locations = locationsResponse
          .filter(
            (l) =>
              (getOptions(getState().user.model).showSettingUpLocations &&
                l.status === 'setting up') ||
              !['created', 'setting up'].includes(l.status),
          )
          .map((l) => {
            const matchedOpeningTimes = openingTimesResponse.filter(
              (ot) => ot.location_id === l.id,
            );

            return LocationModel.create({
              id: l.id,
              name: l.name,
              coordinates: l.coordinates,
              metadata: l.metadata,
              organisation: l.organisation_id,
              liveOccupancyConfiguration: undefined,
              // liveOccupancyConfiguration: r.liveOccupancyConfiguration
              //   ? {
              //       liveOccupancyStatsActive:
              //         r.liveOccupancyConfiguration.liveOccupancyStatsActive,
              //       locationCapacity:
              //         r.liveOccupancyConfiguration.locationCapacity,
              //       occupancyDerivedVia:
              //         r.liveOccupancyConfiguration.occupancyDerivedVia,
              //     }
              //   : undefined,
              status: l.status,
              currency: l.currency,
              openingTimes: matchedOpeningTimes.map((ot) => ({
                day: parseInt(ot.day),
                startHour: parseInt(ot.start_time.split(':')[0]),
                startMinute: parseInt(ot.start_time.split(':')[1]),
                stopHour: parseInt(ot.end_time.split(':')[0]),
                stopMinute: parseInt(ot.end_time.split(':')[1]),
              })),
              timezone: l.timezone,
            });
          });

        const payload = {
          recordings: recordingsResponse
            .filter((r) => !!r.camera_server_id || window.isDemoOrg)
            .map((r) => {
              const location = locations.find((l) => l.id === r.location_id);

              return RecordingModel.create({
                id: r.id,
                name: r.recording_name,
                timezone: r.timezone || 'Europe/London',
                // areaContexts are only needed for live occupancy
                // areaContexts: r.areaContexts,
                heatmaps: [],
                uploadingSchedule: undefined,
                cameraServer: r.camera_server_id,
                location: location
                  ? LocationModel.create({
                      id: location.id,
                      name: location.name,
                      coordinates: location.coordinates,
                      metadata: location.metadata,
                      organisation: location.organisation_id,
                      status: location.status,
                    })
                  : null,
              });
            })
            .filter((r) => !!r.location),
          locations,
        };

        dispatch({ type: ACTIONS.FETCH_RECORDINGS_SUCCESS, payload });
      } else {
        throw new Error();
      }
    } catch (error) {
      errorToast({ message: 'Error fetching camera data.', timeout: 2500 });
      dispatch({ type: ACTIONS.FETCH_RECORDINGS_ERROR });
    }
  };

export const fetchHeatmaps: FluxStandardAction<string, null, *> =
  (recordingId) => async (dispatch) => {
    try {
      const payload = { recordingId };

      dispatch({ type: ACTIONS.FETCH_HEATMAPS_REQUEST, payload });

      const response = await recordings.fetchHeatmaps(recordingId);
      const heatmaps = response.map((h) =>
        HeatmapModel.create({
          id: h.id,
          heatmapType: h.heatmapType,
          timePeriod: h.timePeriod,
          demographicDescriptor: h.demographicDescriptor,
          sourceUrl: h.sourceUrl,
        }),
      );

      const successPayload = {
        recordingId,
        heatmaps,
      };
      dispatch({
        type: ACTIONS.FETCH_HEATMAPS_SUCCESS,
        payload: successPayload,
      });
    } catch (error) {
      errorToast({ message: 'Error fetching heatmap data.', timeout: 2500 });
      dispatch({ type: ACTIONS.FETCH_HEATMAPS_ERROR });
    }
  };

export const fetchRecordings = async (
  recordingIds?: Array<string>,
): Promise<Array<Object> | null> => {
  try {
    const query = {
      entityType: 'recording',
      entities: undefined,
    };

    if (
      recordingIds &&
      Array.isArray(recordingIds) &&
      recordingIds.length > 0
    ) {
      query.entities = recordingIds;
    }

    const results = await sendMessage('detail', 'list', query);

    if (!results || !Array.isArray(results)) {
      return null;
    }

    return results.filter((r) => !!r.camera_server_id || window.isDemoOrg);
  } catch (error) {
    console.log(error);
    return null;
  }
};
