// @flow

import { Fragment, useContext } from 'react';
import * as UserModel from '../../models/user';
import * as ReportModel from '../../models/report';
import DesktopSidebar from './desktop';
import MobileSidebar from './mobile';
import {
  SUMMARY,
  HEATMAPS,
  EXPLORER,
  LIVE_OCC,
  REPORTS,
  CAMERA_PREVIEW,
  TRAINING,
  SETTINGS_USERS,
  SETTINGS_MANAGE_LOCATIONS,
  SETTINGS_TOOLS,
  HEADCOUNT_PLANNER,
  SETTINGS_MANAGE_SINGLE_LOCATION,
  SETTINGS_MANAGE_SINGLE_RECORDING,
} from '../../constants/routes';
import { Desktop, Mobile } from '../layouts/devices-sizes';
import { ThemeContext } from 'styled-components';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { withRouter } from 'react-router-dom';

type Props = {
  user: UserModel.t,
  showLivePage: boolean,
  showDemoLivePage: boolean,
  showHeadcountPlanner: Boolean,
  logout: (options: Object) => void | Promise<Object>,
  reports?: ReportModel.t[],
  location: Object,
};

type MenuItemT = {
  label: string,
  icon?: string,
  path: string,
  children?: MenuItemT[],
};

const Sidebar = (props: Props) => {
  const keyMetrics = {
    label: 'Key Metrics',
    icon: 'dashboard',
    path: SUMMARY,
  };

  const reports: MenuItemT = {
    label: 'Reports',
    icon: 'clipboard',
    path: REPORTS,
    children: props.reports
      ? props.reports
          .sort((a, z) =>
            typeof a.order === 'number' && typeof z.order === 'number'
              ? a.order - z.order
              : 0,
          )
          .map((report) => {
            const reportName = report.name ? report.name : 'Missing Name';
            return {
              label:
                report.status === 'draft'
                  ? `[DRAFT] ${reportName}`
                  : reportName,
              path: `${REPORTS}/${report.id || ''}`,
            };
          })
      : [],
  };
  const explorer: MenuItemT = {
    label: 'Explorer',
    icon: 'series-search',
    path: EXPLORER,
  };
  const heatmaps: MenuItemT = {
    label: 'Heatmaps',
    icon: 'heatmap',
    path: HEATMAPS,
  };
  const cameraPreview: MenuItemT = {
    label: 'Camera Preview',
    icon: 'camera',
    path: CAMERA_PREVIEW,
  };
  const headcountPlanner: MenuItemT = {
    label: 'Headcount Planner',
    icon: 'heat-grid',
    path: HEADCOUNT_PLANNER,
  };
  const liveOcc: MenuItemT = {
    label: 'Live Occupancy',
    icon: 'eye-open',
    path: LIVE_OCC,
  };
  const settings: MenuItemT = {
    label: 'Admin',
    icon: 'cog',
    path: SETTINGS_MANAGE_LOCATIONS,
    children: [
      {
        label: 'Locations',
        path: SETTINGS_MANAGE_LOCATIONS,
        alwaysExpanded: true,
      },
      {
        label: 'Users',
        path: SETTINGS_USERS,
      },
      {
        label: 'Tools',
        path: SETTINGS_TOOLS,
      },
    ],
  };

  if (
    (window.location.pathname.includes(`${SETTINGS_MANAGE_SINGLE_LOCATION}/`) ||
      window.location.pathname.includes(SETTINGS_MANAGE_SINGLE_RECORDING)) &&
    settings.children
  ) {
    const settingsLocations = settings.children.find(
      (c) => c.path === SETTINGS_MANAGE_LOCATIONS,
    );
    if (
      settingsLocations &&
      props.location &&
      props.location.state &&
      props.location.state.location &&
      props.location.state.location.name &&
      props.location.state.location.id
    ) {
      settingsLocations.children = [
        {
          label: props.location.state.location.name,
          path: `${SETTINGS_MANAGE_SINGLE_LOCATION}/${props.location.state.location.id}`,
          alwaysExpanded: true,
        },
      ];
    }
  }

  if (
    window.location.pathname.includes(SETTINGS_MANAGE_SINGLE_RECORDING) &&
    settings.children
  ) {
    const settingsLocations = settings.children.find(
      (c) => c.path === SETTINGS_MANAGE_LOCATIONS,
    );
    if (settingsLocations && settingsLocations.children) {
      const settingsLocation = settingsLocations.children.find((c) =>
        c.path.includes(SETTINGS_MANAGE_SINGLE_LOCATION),
      );
      if (
        settingsLocation &&
        props.location &&
        props.location.state &&
        props.location.state.recording &&
        props.location.state.recording.name &&
        props.location.state.recording.id
      ) {
        settingsLocation.children = [
          {
            label: props.location.state.recording.name,
            path: `${SETTINGS_MANAGE_SINGLE_RECORDING}/${props.location.state.recording.id}`,
          },
        ];
      }
    }
  }

  const training = {
    label: 'Training',
    icon: 'learning',
    path: TRAINING,
  };
  const divider = {
    type: 'divider',
  };

  const productMenuItems = [
    reports,
    keyMetrics,
    divider,
    explorer,
    heatmaps,
    cameraPreview,
    divider,
    training,
    divider,
    settings,
  ];
  const productMenuItemsMobile = [
    reports,
    keyMetrics,
    explorer,
    heatmaps,
    cameraPreview,
    training,
    settings,
  ];

  if (props.showHeadcountPlanner) {
    productMenuItems.splice(6, 0, headcountPlanner);
    productMenuItemsMobile.splice(5, 0, headcountPlanner);
  }
  if (props.showLivePage) {
    let desktopIndex = 6;
    let mobileIndex = 5;
    if (productMenuItems.includes(headcountPlanner, 5)) {
      desktopIndex++;
      mobileIndex++;
    }
    productMenuItems.splice(desktopIndex, 0, liveOcc);
    productMenuItemsMobile.splice(mobileIndex, 0, liveOcc);
  }

  // Override logout destination if the theme specifies it
  // This URL must be in the "Allowed Logout URLs" in the Auth0 dashboard
  const theme = useContext(ThemeContext);
  let logout = props.logout;
  if (theme && theme.overrides && theme.overrides.logout) {
    logout = () => props.logout({ returnTo: theme.overrides.logout });
  }

  // Add any extra menu items from the theme
  if (theme && theme.overrides && theme.overrides.menuItems) {
    theme.overrides.menuItems.forEach((item) => {
      const { label, icon, path } = item;
      const menuItem = { label, icon, path };

      if (item.position === 'top') {
        productMenuItems.unshift(menuItem);
        productMenuItemsMobile.unshift(menuItem);
      } else {
        productMenuItems.push(menuItem);
        productMenuItemsMobile.push(menuItem);
      }
    });
  }

  return (
    <Fragment>
      <Mobile className={'sidebar'}>
        <MobileSidebar
          {...props}
          logout={logout}
          productMenuItems={productMenuItemsMobile}
        />
      </Mobile>
      <Desktop className={'sidebar'}>
        <DesktopSidebar
          {...props}
          logout={logout}
          productMenuItems={productMenuItems}
        />
      </Desktop>
    </Fragment>
  );
};

export default withRouter(Sidebar);
