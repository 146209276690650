// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as ROUTES from '../constants/routes';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(() => <Redirect to={{ pathname: ROUTES.REPORTS }} />);
