import styled from 'styled-components';
import { Classes, Spinner } from '@blueprintjs/core';
import { breakpoints } from '../../styles/variables';

const Panel = styled.div`
  border: 0 var(--panel-border);
  margin: 0 auto;
  border-radius: 0;
`;
const PanelTopLine = styled.div`
  border-radius: var(--widget-border-radius) var(--widget-border-radius) 0 0;
  background: ${props => (props.withinBody ? '#fafbfc' : 'white')};
  border-bottom: 1px solid var(--mid-grey);
  padding: 7px 20px;
  color: var(--mid-grey);
  text-transform: uppercase;
  font-size: 0.8em;
`;
const PanelHeader = styled.div`
  background: var(--panel-header-background);
  border-bottom: var(--panel-border);
  padding: 2rem;
  border-radius: 0;

  &:last-child {
    border-bottom-right-radius: var(--widget-border-radius);
    border-bottom-left-radius: var(--widget-border-radius);
  }

  &:first-child {
    border-top-right-radius: var(--widget-border-radius);
    border-top-left-radius: var(--widget-border-radius);
  }

  &:only-child {
    border-bottom: none;
  }

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    padding: 1rem;
  }
`;
const PanelFooter = styled.div`
  background: var(--panel-footer-background);
  border-top: var(--panel-border);
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 0;

  &:last-child {
    border-bottom-right-radius: var(--widget-border-radius);
    border-bottom-left-radius: var(--widget-border-radius);
  }

  &:first-child {
    border-top-right-radius: var(--widget-border-radius);
    border-top-left-radius: var(--widget-border-radius);
  }

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    padding: 1rem;
  }
`;
const PanelBody = styled.div`
  background: var(--panel-background);
  padding: 1rem 2rem;
  border-radius: 0;

  &:last-child {
    border-bottom-right-radius: var(--widget-border-radius);
    border-bottom-left-radius: var(--widget-border-radius);
  }

  &:first-child {
    border-top-right-radius: var(--widget-border-radius);
    border-top-left-radius: var(--widget-border-radius);
  }

  &:only-child {
    padding: 2rem;
  }

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    padding: 1rem;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const SplitApartRow = styled(Row)`
  justify-content: space-between;
`;
const RowLoading = styled.div`
  padding: 2em 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NotFoundText = styled.p`
  text-align: center;
  padding: 4em 0;
  color: var(--mid-grey);
`;
const NotFoundContainer = styled.div`
  text-align: center;
  padding: 4em 0;
  color: var(--mid-grey);
`;

const PanelLoading = () => (
  <RowLoading>
    <Spinner className={Classes.SMALL} />
  </RowLoading>
);

export {
  Panel,
  PanelTopLine,
  PanelHeader,
  PanelBody,
  PanelFooter,
  Row,
  SplitApartRow,
  PanelLoading,
  NotFoundText,
  NotFoundContainer
};
