// @flow
import { demographicValuesSortFn } from '../utils/graphHelpers';

import { areTwoArraysEquals } from '../utils/generalHelpers';
import * as QueryResponseModel from './query-response';
import * as ActiveMetricsModel from './active-metrics';
import * as QueryApiRequestModel from './query-api-request';
import { isEqual } from 'lodash';

export type itemT = {
  locations?: string[],
  metricGroupKey: string,
  metricKey: string,
  taxonomies: string[],
  data?: QueryResponseModel.t,
  error?: QueryResponseModel.errorT,
  isComparison: boolean,
  isAllLocations: boolean,
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  excludeStaff: boolean,
  breakdownByDimensions?: string[],
};

export type t = itemT[];

export type findCriteriaT = {
  locations: Array<string>,
  metricGroupKey?: string, // TODO: isn't really a maybe type, but it fixes a lot of flow errors
  metricKey?: string, // TODO: isn't really a maybe type, but it fixes a lot of flow errors
  taxonomies?: string[],
  period: QueryApiRequestModel.periodT,
  aggregation: QueryApiRequestModel.aggregationEnumT,
  excludeStaff: boolean,
  isComparisonActive: boolean,
  isAllLocations: boolean,
  breakdownByDimensions?: string[],
};

type optionsT = {
  ignoreBreakdownBy?: boolean,
};

export const findAll = (
  findCriteria: findCriteriaT,
  queryResponseList: t,
  options?: optionsT,
): ?t => {
  const found = queryResponseList.filter((queryResponse) => {
    if (
      queryResponse &&
      queryResponse.locations &&
      Array.isArray(findCriteria.locations) &&
      queryResponse.locations.length === findCriteria.locations.length
    ) {
      const allIdsMatch = areTwoArraysEquals(
        findCriteria.locations,
        queryResponse.locations,
      );

      const allBreakdownBysMatch =
        (options && options.ignoreBreakdownBy) ||
        areTwoArraysEquals(
          findCriteria.breakdownByDimensions,
          queryResponse.breakdownByDimensions,
        );

      return (
        allIdsMatch &&
        allBreakdownBysMatch &&
        queryResponse.metricGroupKey === findCriteria.metricGroupKey &&
        queryResponse.metricKey === findCriteria.metricKey &&
        areTwoArraysEquals(queryResponse.taxonomies, findCriteria.taxonomies) &&
        queryResponse.aggregation === findCriteria.aggregation &&
        queryResponse.excludeStaff === findCriteria.excludeStaff &&
        isEqual(queryResponse.period, findCriteria.period) &&
        queryResponse.isComparison === findCriteria.isComparisonActive
      );
    }
    return null;
  });

  return found.filter((item) => !!item);
};

export const findOne = (
  findCriteria: findCriteriaT,
  queryResponseList: t,
): ?itemT => {
  const found = findAll(findCriteria, queryResponseList);
  if (found && found.length > 0) {
    return found[found.length - 1];
  }
  return null;
};

export const getSegments = (
  queryResponse: itemT,
): QueryResponseModel.segmentsT => {
  if (!queryResponse || !queryResponse.data || !queryResponse.data.segments) {
    return [];
  } else {
    return queryResponse.data.segments;
  }
};

export const getQueryResponseListWithSegments = (queryResponseList: t): t => {
  return queryResponseList.filter((qR) => {
    return qR.data && qR.data.segments;
  });
};

export const filterByLocationId = (
  queryResponseList: t,
  filteredLocationsIds: string[],
  isAllLocations: boolean = false,
): t => {
  if (isAllLocations) {
    return queryResponseList.filter((qR) => {
      return (
        areTwoArraysEquals(qR.locations || [], filteredLocationsIds) &&
        qR.isAllLocations
      );
    });
  }

  return queryResponseList.filter(
    (qR) =>
      areTwoArraysEquals(filteredLocationsIds, qR.locations || []) &&
      qR.isAllLocations === false,
  );
};

export const filterByIsComparison = (
  queryResponseList: t,
  isComparison: boolean,
): t => {
  return queryResponseList.filter((qR) => {
    return qR.isComparison === isComparison;
  });
};

export const filterByActiveMetrics = (
  queryResponseList: t,
  activeMetrics: ActiveMetricsModel.t,
): t => {
  return queryResponseList.filter((qR) => {
    const activeMetric = activeMetrics[qR.metricGroupKey];
    return (
      !!activeMetric &&
      activeMetric.metrics &&
      !!activeMetric.metrics.find((metric) => metric === qR.metricKey) &&
      (activeMetric.taxonomies
        ? areTwoArraysEquals(
            activeMetrics[qR.metricGroupKey].taxonomies || [],
            qR.taxonomies,
          )
        : true)
    );
  });
};

export const getSplitsKeysFromSegmentsUsingAKey = (
  queryResponseList: t,
  key: string,
): string[] => {
  const allKeysList = new Set();
  queryResponseList.forEach((qR) => {
    const segments = getSegments(qR);

    if (segments) {
      segments.forEach((segment) => {
        if (segment[key]) {
          allKeysList.add(segment[key]);
        }
      });
    }
  });
  return Array.from(allKeysList).sort(demographicValuesSortFn);
};
