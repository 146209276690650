// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import {
  Popover,
  Button,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import ButtonIconColorer from '../button-icon-colorer';
import {
  AGGREGATION_PERIOD,
  AGGREGATION_PERIOD_LABELS,
  MAX_DAYS_SUPPORTED
} from '../../constants/graph-options';

import type { aggregationEnum } from '../../constants/graph-options';

const {
  QUARTER_HOUR,
  HOUR,
  DAY,
  WEEK_ISO,
  WEEK_US,
  MONTH,
  COMBINED,
  DAY_OF_WEEK,
  HOUR_OF_DAY,
  DOW_HOD
} = AGGREGATION_PERIOD;

const labels = AGGREGATION_PERIOD_LABELS;

const StyledTooltip = styled(Tooltip2)`
  width: 100%;
  display: block;
`;

const isButtonDisabled = ({
  value,
  disabled
}: {
  value: string,
  disabled: string[]
}): boolean => {
  return disabled.includes(value);
};

const MenuElement = ({
  value,
  disabled,
  onClick,
  blocked
}: {
  value: aggregationEnum,
  disabled: string[],
  blocked: string[],
  onClick: (value: aggregationEnum) => any
}) => {
  const isValueBlocked = blocked.includes(value);

  return (
    <Fragment>
      {!isButtonDisabled({ value, disabled }) && (
        <Fragment>
          {isValueBlocked ? (
            <StyledTooltip
              position={Position.LEFT}
              content={`Select a date range of ${MAX_DAYS_SUPPORTED[value]} days or less to view ${labels[value]}.`}
            >
              <MenuItem
                disabled={true}
                text={labels[value]}
                onClick={() => onClick(value)}
              />
            </StyledTooltip>
          ) : (
            <MenuItem text={labels[value]} onClick={() => onClick(value)} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

type Props = {
  selected: string,
  onChange(value: aggregationEnum): any,
  aggregationCombined?: boolean,
  disabled?: string[],
  blocked?: string[],
  loading?: boolean
};

const AggregationPicker = ({
  selected,
  onChange,
  aggregationCombined,
  loading = false,
  disabled = [],
  blocked = []
}: Props) => {
  const commomProps = {
    onClick: onChange,
    disabled,
    blocked
  };
  const menu = (
    <Menu>
      <MenuElement {...commomProps} key={QUARTER_HOUR} value={QUARTER_HOUR} />
      <MenuElement {...commomProps} key={HOUR} value={HOUR} />
      <MenuElement {...commomProps} key={DAY} value={DAY} />
      <MenuElement {...commomProps} key={WEEK_ISO} value={WEEK_ISO} />
      <MenuElement {...commomProps} key={WEEK_US} value={WEEK_US} />
      <MenuElement {...commomProps} key={MONTH} value={MONTH} />
      <MenuElement {...commomProps} key={DAY_OF_WEEK} value={DAY_OF_WEEK} />
      <MenuElement {...commomProps} key={HOUR_OF_DAY} value={HOUR_OF_DAY} />
      <MenuElement {...commomProps} key={DOW_HOD} value={DOW_HOD} />
    </Menu>
  );

  return (
    <Popover
      disabled={aggregationCombined || loading}
      content={menu}
      position={Position.BOTTOM_RIGHT}
    >
      <ButtonIconColorer>
        <Button
          disabled={aggregationCombined || loading}
          icon="time"
          rightIcon="caret-down"
          text={aggregationCombined ? labels[COMBINED] : labels[selected]}
        />
      </ButtonIconColorer>
    </Popover>
  );
};

export default AggregationPicker;
