/**
 * Simple number range validator
 *
 * @param num
 * @param min
 * @param max
 * @param exception
 * @throws Error
 * @returns {boolean}
 */
module.exports.throwOnIntRangeOutOfBound = (num, min, max, exception) => {
  if (!Number.isInteger(num) || num < min || num > max) {
    throw exception;
  }
  return true;
};
