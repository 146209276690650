// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import * as RecordingModel from '../models/recording';

export type ReducerType = RecordingModel.t[];

const INITIAL_STATE = [];

export default function recordingsReducer(
  state: ReducerType = INITIAL_STATE,
  { type, payload }: FluxStandardAction<string, *>
): ReducerType {
  switch (type) {
    case ACTIONS.FETCH_RECORDINGS_SUCCESS:
      // TODO: in here, only add in any newly received recordings, else it will
      // clear out all segment and heatmap data for existing recordings too...
      return payload.recordings;

    case ACTIONS.FETCH_HEATMAPS_SUCCESS:
      return [
        ...state.filter(r => r.id !== payload.recordingId),
        ...state
          .filter(r => r.id === payload.recordingId)
          .map(r => ({
            ...r,
            heatmaps: payload.heatmaps
          }))
      ];

    case ACTIONS.LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}
