import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';
import { Panel, PanelBody } from '../../panel';

export const Wrapper = styled.div`
  flex: 1;
`;

export const TablesBody = styled(PanelBody)`
  overflow-x: auto;
  ${({ theme }) =>
    theme && `font-family: ${theme.properties['--fonts-default']}`}
  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: 100%;
    &&& {
      padding: 0;
    }
  }
`;

export const TablesWrapper = styled(Panel)`
  max-width: var(--widget-max-width);
  box-shadow: var(--widget-box-shadow);
  margin: 0 auto;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: 100%;
    &&& {
      padding: 0;
    }
  }
`;