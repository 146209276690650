import styled from 'styled-components';
import { MenuItem, Button, Tag } from '@blueprintjs/core';
import * as UserModel from '../../models/user';
import * as AuthAPI from '../../services/auth-api';
import { errorToast } from '../../utils/toaster';
import { dropTables } from '../../models/database';
import { Select } from '@blueprintjs/select';

const StyledButton = styled(Button)`
  margin-top: 1em;
  background: transparent !important;
  border: 1px solid var(--sidebar-text);

  & .bp3-button-text {
    color: var(--sidebar-text) !important;
  }
`;

const OrganisationSwitcher = ({ user }) => {
  const { availableOrgs, organisationId: currentOrgId } = user;

  const getItem = ({ name, id }) => {
    const getLabel = (orgId) =>
      orgId === currentOrgId ? (
        <Tag minimal intent="primary">
          Current
        </Tag>
      ) : undefined;

    const setPrimaryOrg = async (newOrgId) => {
      try {
        if (newOrgId !== currentOrgId) {
          await AuthAPI.user.update(user.id, {
            email: UserModel.getEmail(user),
            fullName: UserModel.getName(user),
            status: UserModel.getUserStatus(user),
            primaryOrgId: newOrgId,
          });

          window.location.hash = '';

          // delete the local cache when switching organisation
          await dropTables();

          window.location.reload();
        }
      } catch (e) {
        errorToast({
          message: 'Could not switch organisations. Please contact support.',
        });
        console.error(e);
      }
    };

    return (
      <MenuItem
        onClick={() => setPrimaryOrg(id)}
        key={id}
        label={getLabel(id)}
        text={name}
      />
    );
  };

  return (
    <Select
      itemRenderer={getItem}
      items={availableOrgs}
      minimal
      filterable={false}
    >
      <StyledButton minimal rightIcon="caret-down" text="Switch organisation" />
    </Select>
  );
};

export default OrganisationSwitcher;
