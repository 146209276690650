import { Fragment } from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { dayOfWeekLabel, ordinalSuffix } from '../../../utils/dates';

import {
  CADENCE_HOURLY,
  CADENCE_DAILY,
  CADENCE_WEEKLY,
  CADENCE_MONTHLY,
} from '../helpers/consts';

const EditRow = styled.div`
  display: flow;
  flex-flow: row wrap;
`;
const EditBlock = styled.div`
  padding: 0 0.5em 0.5em 0;
  display: flex;
  align-items: center;
`;

const ScheduleSelector = ({ selectedSchedule, setSelectedSchedule }) => {
  const showDayOfWeekEdit = selectedSchedule.cadence === CADENCE_WEEKLY;
  const showDayOfMonthEdit = selectedSchedule.cadence === CADENCE_MONTHLY;
  const showHourEdit = [
    CADENCE_DAILY,
    CADENCE_WEEKLY,
    CADENCE_MONTHLY,
  ].includes(selectedSchedule.cadence);

  const CadenceMenuItem = ({ cadence }) => {
    let selectedIcon;
    if (selectedSchedule.cadence) {
      selectedIcon =
        selectedSchedule.cadence === cadence
          ? { icon: 'tick' }
          : { icon: 'blank' };
    }
    return (
      <MenuItem
        text={capitalize(cadence)}
        onClick={(e) => setSelectedSchedule({ cadence })}
        {...selectedIcon}
      />
    );
  };
  const DayOfWeekMenuItem = ({ sendDay }) => {
    let selectedIcon;
    if (typeof selectedSchedule.sendDay === 'number') {
      selectedIcon =
        selectedSchedule.sendDay === sendDay
          ? { icon: 'tick' }
          : { icon: 'blank' };
    }
    return (
      <MenuItem
        text={`${dayOfWeekLabel(sendDay)}s`}
        onClick={(e) => setSelectedSchedule({ ...selectedSchedule, sendDay })}
        {...selectedIcon}
      />
    );
  };
  const DayOfMonthMenuItem = ({ sendDay }) => {
    let selectedIcon;
    if (typeof selectedSchedule.sendDay === 'number') {
      selectedIcon =
        selectedSchedule.sendDay === sendDay
          ? { icon: 'tick' }
          : { icon: 'blank' };
    }
    return (
      <MenuItem
        text={ordinalSuffix(sendDay)}
        onClick={(e) => setSelectedSchedule({ ...selectedSchedule, sendDay })}
        {...selectedIcon}
      />
    );
  };
  const HourMenuItem = ({ sendHour }) => {
    let selectedIcon;
    if (typeof selectedSchedule.sendHour === 'number') {
      selectedIcon =
        selectedSchedule.sendHour === sendHour
          ? { icon: 'tick' }
          : { icon: 'blank' };
    }
    return (
      <MenuItem
        text={localTzHour(sendHour)}
        onClick={(e) => setSelectedSchedule({ ...selectedSchedule, sendHour })}
        {...selectedIcon}
      />
    );
  };

  // TODO: share this code, it's also in dashboard-report-details-row
  const localTzHour = (hour) => {
    const utcTime = moment().utc().hour(hour);
    const userTz = moment.tz.guess();
    const localHour = moment.tz(utcTime, userTz).format('ha');
    const userTzAbbr = moment.tz.zone(userTz).abbr(utcTime);

    let label = localHour;
    if (localHour === '12am') label = 'Midnight';
    if (localHour === '12pm') label = 'Midday';
    return `${label} (${userTzAbbr})`;
  };

  return (
    <EditRow>
      <EditBlock>
        <Popover>
          <Button
            rightIcon="caret-down"
            text={
              selectedSchedule.cadence
                ? capitalize(selectedSchedule.cadence)
                : '...'
            }
          />
          <Menu>
            <CadenceMenuItem cadence={CADENCE_HOURLY} />
            <CadenceMenuItem cadence={CADENCE_DAILY} />
            <CadenceMenuItem cadence={CADENCE_WEEKLY} />
            <CadenceMenuItem cadence={CADENCE_MONTHLY} />
          </Menu>
        </Popover>
      </EditBlock>
      {showDayOfWeekEdit && (
        <Fragment>
          <EditBlock>on</EditBlock>
          <EditBlock>
            <Popover>
              <Button
                rightIcon="caret-down"
                text={
                  typeof selectedSchedule.sendDay === 'number'
                    ? `${dayOfWeekLabel(selectedSchedule.sendDay)}s`
                    : '...'
                }
              />
              <Menu>
                <DayOfWeekMenuItem sendDay={1} />
                <DayOfWeekMenuItem sendDay={2} />
                <DayOfWeekMenuItem sendDay={3} />
                <DayOfWeekMenuItem sendDay={4} />
                <DayOfWeekMenuItem sendDay={5} />
                <DayOfWeekMenuItem sendDay={6} />
                <DayOfWeekMenuItem sendDay={0} />
              </Menu>
            </Popover>
          </EditBlock>
        </Fragment>
      )}
      {showDayOfMonthEdit && (
        <Fragment>
          <EditBlock> on the</EditBlock>
          <EditBlock>
            <Popover>
              <Button
                rightIcon="caret-down"
                text={
                  typeof selectedSchedule.sendDay === 'number'
                    ? ordinalSuffix(selectedSchedule.sendDay)
                    : '...'
                }
              />
              <Menu>
                {Array.from(new Array(31)).map((_, i) => (
                  <DayOfMonthMenuItem sendDay={i + 1} key={i} />
                ))}
              </Menu>
            </Popover>
          </EditBlock>
          <EditBlock>day of the month</EditBlock>
        </Fragment>
      )}
      {showHourEdit && (
        <Fragment>
          <EditBlock>at</EditBlock>
          <EditBlock>
            <Popover>
              <Button
                rightIcon="caret-down"
                text={
                  typeof selectedSchedule.sendHour === 'number'
                    ? localTzHour(selectedSchedule.sendHour)
                    : '...'
                }
              />
              <Menu>
                {Array.from(new Array(24)).map((_, i) => (
                  <HourMenuItem sendHour={i} key={i} />
                ))}
              </Menu>
            </Popover>
          </EditBlock>
        </Fragment>
      )}
    </EditRow>
  );
};

export default ScheduleSelector;
