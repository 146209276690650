// @flow

import { combineReducers } from 'redux';
import user from './user';
import recordings from './recordings';
import locations from './locations';
import asyncProcesses from './asyncProcesses';
import pageSettings from './pageSettings';
import queryResponseList from './queryResponseList';
import reports from './reports';

import type { ReducerType as UserReducerT } from '../reducers/user';
import type { ReducerType as LocationsReducerT } from '../reducers/locations';
import type { ReducerType as RecordingsReducerT } from '../reducers/recordings';
import type { ReducerType as PageSettingsReducerT } from '../reducers/pageSettings';
import type { ReducerType as AsyncProcessesReducerT } from '../reducers/asyncProcesses';
import type { ReducerType as QueryResponseListReducerT } from '../reducers/queryResponseList';
import type { ReducerType as ReportsReducerT } from '../reducers/reports';

export type CombinedReducersT = {
  user: UserReducerT,
  locations: LocationsReducerT,
  recordings: RecordingsReducerT,
  asyncProcesses: AsyncProcessesReducerT,
  pageSettings: PageSettingsReducerT,
  queryResponseList: QueryResponseListReducerT,
  reports: ReportsReducerT
};

export default combineReducers({
  user,
  locations,
  recordings,
  asyncProcesses,
  pageSettings,
  queryResponseList,
  reports
});
