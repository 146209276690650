// @flow

import { useContext, useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  CartesianGrid,
  Legend,
  Bar,
  YAxis,
  Label,
  Line,
  Tooltip,
} from 'recharts';
import { ThemeContext } from 'styled-components';

import CustomizedYAxisTick from '../common/customized-y-axis-tick';
import CustomizedXAxisTick from '../common/customized-x-axis-tick';
import CustomizedTooltip from '../common/customized-tooltip';
import GraphLegend from '../common/graph-legend';
import { WidgetBodyWrapper } from '../common/styles';
import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';

import { DISPLAY_CHART_TYPE } from '../../../utils/widgets-ui';

import type { displayChartTypeEnum } from '../../../utils/widgets-ui';
import type { unitTypeT } from '../../../models/query-response';
import type { chartDataT } from '../../graph-tile';
import type { aggregationEnum } from '../../../constants/graph-options';
import { getDeviceType } from '../../../utils/device-type';

// TODO widgetPropsT is duplicated becase importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description?: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean,
};

type Props = widgetPropsT & {
  /** The data to display */
  metrics: {
    dataKey: string,
    label: string,
    unitType: unitTypeT,
    chartDisplayType: displayChartTypeEnum,
  }[],
  chartData: chartDataT[],
  aggregation: aggregationEnum,
};

const Chart = ({
  title,
  subtitle,
  description,
  metrics,
  chartData,
  aggregation,
  loading,
  errors,
}: Props) => {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    function handleResize() {
      setDeviceType(getDeviceType());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const theme = useContext(ThemeContext);
  const sortLineGraphToFront = (a, b) =>
    a.chartDisplayType === DISPLAY_CHART_TYPE.LINE ? 1 : -1;

  return (
    <WidgetWrapper
      loading={loading}
      errors={errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapper>
        <ResponsiveContainer width="100%" height={deviceType === 'phone' ? 300 : 700}>
          <ComposedChart
            data={chartData}
            margin={() => {
              if (deviceType === 'desktop') return { top: 30, right: 40, left: 40, bottom: 0 };
              if (deviceType === 'tablet') return { top: 10, right: 10, left: 10, bottom: 0 };
              if (deviceType === 'phone') return { top: 0, right: 0, left: 0, bottom: 0 };
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="index"
              height={60}
              tick={<CustomizedXAxisTick aggregation={aggregation} />}
              interval="preserveStartEnd"
            />
            <Tooltip
              content={
                <CustomizedTooltip
                  aggregation={aggregation}
                  metrics={metrics}
                />
              }
            />
            <Legend content={<GraphLegend metrics={metrics} />} />

            {metrics.sort(sortLineGraphToFront).map((metric) => {
              const { dataKey, chartDisplayType, label, unitType } = metric;

              if (chartDisplayType === DISPLAY_CHART_TYPE.BAR)
                return [
                  <Bar
                    yAxisId={dataKey}
                    dataKey={dataKey}
                    isAnimationActive={false}
                    fill={theme ? theme.properties['--chart-widget-bar'] : null}
                  />,
                  <YAxis
                    yAxisId={dataKey}
                    tick={
                      <CustomizedYAxisTick
                        unitType={unitType}
                        textAnchor="end"
                      />
                    }
                  >
                    <Label
                      value={label}
                      angle={-90}
                      position="center"
                      fill={theme ? theme.properties['--chart-widget-bar'] : null}
                      dx={-50}
                      style={{
                        textTransform: 'capitalize',
                      }}
                    />
                  </YAxis>,
                ];

              if (chartDisplayType === DISPLAY_CHART_TYPE.LINE)
                return [
                  <Line
                    yAxisId={dataKey}
                    type="monotone"
                    isAnimationActive={false}
                    dataKey={dataKey}
                    stroke={theme ? theme.properties['--chart-widget-line'] : null}
                    fill={theme ? theme.properties['--chart-widget-line'] : null}
                    strokeWidth={4}
                  />,
                  <YAxis
                    yAxisId={dataKey}
                    orientation="right"
                    tick={
                      <CustomizedYAxisTick
                        unitType={unitType}
                        textAnchor="start"
                      />
                    }
                  >
                    <Label
                      value={label}
                      angle={-90}
                      position="center"
                      fill={theme ? theme.properties['--chart-widget-bar'] : null}
                      dx={50}
                      style={{
                        textTransform: 'capitalize',
                      }}
                    />
                  </YAxis>,
                ];

              return null;
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </WidgetBodyWrapper>
    </WidgetWrapper>
  );
};

export default Chart;
