const { each } = require('awaity');
const { NotAuthorisedError } = require('../authoriser/error/notAuthorisedError');

/**
 * Auth middleware - all of the injected tests must not throw
 * NotAuthorisedError
 *
 * All of the injected tests must not throw any other kind of error
 *
 * Allows override of:
 * - OverrideNotAuthorisedError inject it here if pkg/NotAuthorisedError is not
 * caught in your implementation
 *
 * @param rules
 * @param OverrideNotAuthorisedError
 * @returns {function(...[*]=)}
 */
module.exports.authAll = (
  rules,
  OverrideNotAuthorisedError = NotAuthorisedError,
) => async (req, res, next) => {
  try {
    // If it's not already one, make the injected rules an array
    const arrayOfRules = Array.isArray(rules) ? rules : [rules];
    let oneNotAuth = false;
    let inappropriateThrow = false;

    // Evaluate each of the rules and track if any throw notAuthorised
    // or if any throw with an error that is not
    // NotAuthorisedError
    await each(arrayOfRules, async (rule) => {
      try {
        await rule.evaluate();
      } catch (err) {
        if (!(err instanceof NotAuthorisedError)) {
          inappropriateThrow = err;
        } else {
          oneNotAuth = true;
        }
      }
    });

    // If something threw that was not NotAuthorisedError
    // then bubble it
    if (inappropriateThrow) return next(inappropriateThrow);

    // If at least one threw notAuthorised the notAuthorised
    if (oneNotAuth) return next((new OverrideNotAuthorisedError()));

    // Otherwise no notAuthorised or other kind of error, so open
    // the gate!
    return next();
  } catch (err) {
    return next(err);
  }
};
