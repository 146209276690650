const { User } = require('./authoriser/user');
const { AuthApiException } = require('./services/adapters/errors/authApiException');
const { GenericAuthError } = require('./authoriser/error/genericAuthError');
const { NotAuthorisedError } = require('./authoriser/error/notAuthorisedError');
const { createUserFromToken } = require('./middlewares/createUserFromToken');
const { authAny } = require('./middlewares/authAny');
const { authAll } = require('./middlewares/authAll');
const authPkgConfig = require('./authoriser/config');

module.exports = {
  User,
  AuthApiException,
  GenericAuthError,
  NotAuthorisedError,
  createUserFromToken,
  authAny,
  authAll,
  authPkgConfig,
};
