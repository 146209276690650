// @flow

import { SUMMARY, EXPLORER, USER_SETTINGS } from '../constants/page-settings';
import {
  AXIS_GROUPING,
  AGGREGATION_PERIOD,
  CHART_TYPE,
  BREAKDOWN_BY
} from '../constants/graph-options';
import type {
  aggregationEnum,
  chartTypeEnum,
  breakdownByEnum,
  axisGroupingEnum
} from '../constants/graph-options';
import * as PERIOD_PRESETS from '../constants/period-presets';
import * as PREVIOUS_PERIOD_PRESETS from '../constants/previous-period-presets';

import * as PeriodModel from './period';
import * as ActiveMetricsModel from './active-metrics';
import * as UserListModel from './user-list';

export type periodT = {
  min?: string,
  max?: string,
  selectedStart: string,
  selectedEnd: string
};

export type demographicFilterT = {
  age?: string,
  role?: string,
  gender?: string,
};

export type summaryT = {
  period: PeriodModel.t,
  compareToPast: PeriodModel.comparePeriodT,
  excludeStaff: boolean,
  filteredLocations: string[],
  activeMetrics: ActiveMetricsModel.t,
  demographicFilter: demographicFilterT,
  page: number,
  itemsPerPage: number,
  breakdownBy: breakdownByEnum,
};

export type metricItemT = {
  metric: ActiveMetricsModel.itemT,
  location: string[],
  demographicFilter: demographicFilterT
};

export type explorerT = {
  period: PeriodModel.t,
  compareToPast: PeriodModel.comparePeriodT,
  metrics: Array<metricItemT>,
  aggregation: aggregationEnum,
  axisGrouping: axisGroupingEnum,
  chartType: chartTypeEnum,
  breakdownBy: breakdownByEnum,
};

export type userSettingsT = {
  users: UserListModel.t,
  roles: UserListModel.rolesT
};

export type t = {
  summary: summaryT,
  explorer: explorerT,
  userSettings: userSettingsT
};

export const defaults = {
  [SUMMARY]: {
    excludeStaff: false,
    period: PeriodModel.getExplorerPeriod(PERIOD_PRESETS.last7Days),
    compareToPast: PeriodModel.getExplorerComparePeriod(
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      PeriodModel.getExplorerPeriod(PERIOD_PRESETS.last7Days).selectedDates
    ),
    activeMetrics: {},
    filteredLocations: [],
    demographicFilter: {},
    page: 0,
    itemsPerPage: -1,
    breakdownBy: BREAKDOWN_BY.TOTAL_ONLY
  },
  [EXPLORER]: {
    period: PeriodModel.getExplorerPeriod(PERIOD_PRESETS.last7Days),
    compareToPast: {
      active: false,
      selectedPreset: PREVIOUS_PERIOD_PRESETS.none
    },
    metrics: [],
    aggregation: AGGREGATION_PERIOD.DAY,
    axisGrouping: AXIS_GROUPING.BY_METRIC,
    chartType: CHART_TYPE.LINE,
    breakdownBy: BREAKDOWN_BY.TOTAL_ONLY
  },
  [USER_SETTINGS]: {
    users: [],
    roles: []
  }
};
