import { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  Spinner,
  Alert,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import { emailer } from '../../../services/emailer-api';
import DashboardReportDetailsRow from './dashboard-report-details-row';
import { TAB_NEW_JOB } from '../index';
import * as Selectors from '../../../selectors';
import * as LocationModel from '../../../models/location';
import { CAN_MANAGE_REPORTS } from '../../../constants/permissions';
import { isEmail } from '../../../utils/generalHelpers';
import { errorToast } from '../../../utils/toaster';

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 1.5em;
`;

const DialogBody = styled.div`
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
`;

const ActiveJobsTab = ({
  loading,
  jobs,
  reports,
  setCurrentTab,
  deleteJob,
  editJob,
  locations,
  canManageReports,
  orgId,
  orgName,
}) => {
  const [jobToDeleteId, setJobToDeleteId] = useState(null);
  const [jobToTest, setJobToTest] = useState(null);
  const [testEmail, setTestEmail] = useState('');

  const onDeleteConfirm = async () => {
    const jobId = jobToDeleteId;
    setJobToDeleteId(null);
    await deleteJob(jobId);
  };

  const sendTestEmail = async () => {
    if (!testEmail || !isEmail(testEmail)) {
      errorToast({
        message: 'Email is not valid',
      });
      return false;
    }

    const jobClone = cloneDeep(jobToTest);
    const payload = {
      ...jobClone,
      organisation: {
        id: orgId,
        name: orgName,
      },
      distribution: {
        sendType: 'email - specific users',
        toEmails: [testEmail],
      },
      schedule: {
        cadence: 'Test',
      },
    };

    if (
      payload.dashboardReportToImage?.restrictToLocations &&
      payload.dashboardReportToImage?.restrictToLocations.length > 0
    ) {
      payload.dashboardReportToImage.restrictToLocations =
        payload.dashboardReportToImage.restrictToLocations.map((locId) => ({
          id: locId,
          name: LocationModel.getLocationName(locId, locations),
        }));
    }

    // Don't await a response to this API call because it will more than
    // likely timeout and we can't get a good response from it. Just send
    // the request and hope the lambda succeeds in sending the email. A better
    // way would be to split the API Gateway to return a response from the
    // lambda that will actually process the report.

    emailer.adHocReport(payload);

    errorToast({
      message: `Email will be sent to ${testEmail}. Building the report can take some time, please wait 1-2 minutes to receive it.`,
      intent: Intent.SUCCESS,
      icon: 'send-message',
      timeout: 0,
    });

    setJobToTest(null);
  };

  return (
    <div>
      {!loading &&
        jobs.map((job, index) => (
          <DashboardReportDetailsRow
            number={index + 1}
            reports={reports}
            job={job}
            locations={locations}
            key={index}
            setJobToDeleteId={setJobToDeleteId}
            setJobToTest={setJobToTest}
            editJob={editJob}
            showDeleteButton={canManageReports}
            showTestButton={canManageReports}
            showEditButton={canManageReports}
          />
        ))}
      {!loading && (!jobs || !jobs.length) && (
        <Card>No emails currently set up</Card>
      )}
      {loading && <Spinner />}
      <ButtonContainer>
        <Button
          icon="plus"
          intent="primary"
          onClick={(e) => setCurrentTab(TAB_NEW_JOB)}
        >
          Create new email report
        </Button>
      </ButtonContainer>
      <Alert
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent="danger"
        isOpen={jobToDeleteId}
        onCancel={(e) => setJobToDeleteId(null)}
        onConfirm={onDeleteConfirm}
      >
        Remove this email report? This means it won't run and won't send the
        email report to any of the users that would normally recieve it. However
        it can be reinstated at any time.
      </Alert>
      <Dialog
        title="Send test report"
        canEscapeKeyClose
        canOutsideClickClose
        isCloseButtonShown
        icon="lab-test"
        isOpen={jobToTest}
        onClose={(e) => setJobToTest(null)}
      >
        <DialogBody>
          <FormGroup label="Email to send test report to" labelFor="test-email">
            <InputGroup
              id="test-email"
              name="test-email"
              autoComplete="email"
              placeholder="Email to send test report to"
              value={testEmail}
              onChange={(e) => setTestEmail(e.target.value)}
            />
          </FormGroup>
          <Button
            onClick={sendTestEmail}
            style={{ marginLeft: 'auto' }}
            intent="primary"
          >
            Send
          </Button>
        </DialogBody>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    orgId: Selectors.getOrganisationId(state),
    orgName: Selectors.getOrganisationName(state),
    locations: Selectors.getLocations(state),
    canManageReports: Selectors.userHasPermission(CAN_MANAGE_REPORTS, state),
  };
}

export default connect(mapStateToProps)(ActiveJobsTab);
