// @flow

import { useState, useEffect, useCallback } from 'react';
import { intersection } from 'lodash';

import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import * as LocationModel from '../../../models/location';
import WidgetUI from '../../widgets-ui/col-heatmap';
import { getQueryPayloadType, buildPayload } from '../../../services/query-api';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { prettyTaxonomyLabel } from '../../../utils/taxonomyHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import Pagination from '../../pagination';
import { IS_EMAIL_VIEW } from '../../../config/vars';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';

const MetricHeatmapWidget = ({
  widget,
  availableMetrics,
  locations = [],
  uiOptions = {},
}: WidgetPropsModel.metricHeatmapT) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [fetchRequired, setFetchRequired] = useState(false);
  const [currentElement, isVisible] = useVisible(
    (vi) => uiOptions.disableViewportLoading || vi > 0
  );
  const [visibleLocations, setVisibleLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    IS_EMAIL_VIEW ? -1 : uiOptions.itemsPerPage || -1
  );

  const usingComps =
    widget.comparisonPeriod &&
    widget.comparisonPeriod.active &&
    widget.showChangeValue;

  const configureTableRows = useCallback(
    (excludeRows = []) => {
      let rows = [];

      switch (widget.breakdownBy) {
        case 'location':
        default:
          rows = visibleLocations.map((locationId) => ({
            label: LocationModel.getLocationName(locationId, locations),
            link: `/camera-preview?location=${locationId}`,
          }));
          break;
        case 'filter':
          if (!widget.breakdownFilters) {
            setError(true);
            console.error(
              'Breakdown by is filter, but there is no widget.breakdownFilters supplied'
            );
            return false;
          }
          rows = widget.breakdownFilters.map((filter) => ({
            label: `${filter.key}: ${filter.value}`,
          }));
          break;
        case 'taxonomy':
          if (!widget.breakdownTaxonomies) {
            setError(true);
            console.error(
              'Breakdown by is taxonomy, but there is no widget.breakdownTaxonomies supplied'
            );
            return false;
          }
          rows = widget.breakdownTaxonomies.map((taxonomy) => ({
            label: prettyTaxonomyLabel(taxonomy),
          }));
          break;
      }

      const filteredTableRows = rows.filter(
        (row, index) => !excludeRows.includes(index)
      );

      setTableRows(filteredTableRows);
    },
    [
      locations,
      visibleLocations,
      widget.breakdownBy,
      widget.breakdownFilters,
      widget.breakdownTaxonomies,
    ]
  );

  const stripEmptyRows = useCallback(
    (inputColumns) => {
      // if the breakdown is by location, allow empty rows
      if (widget.breakdownBy === 'location') return inputColumns;

      // work out which rows in which columns have no data
      const emptyIndexes = inputColumns.map((col) =>
        col.rows
          .map((row, index) => {
            if (row.value === null) return index;
            return null;
          })
          .filter((v) => v !== null)
      );

      // works out which rows have no data in every single column
      const emptyIndexesInAllRows = intersection(...emptyIndexes);

      // filter out rows from each column where we know every column has no data for that row
      const finalTableColumns = inputColumns.map((col) => ({
        ...col,
        rows: col.rows.filter(
          (row, index) => !emptyIndexesInAllRows.includes(index)
        ),
      }));

      // update the table rows so we don't show rows with no data
      configureTableRows(emptyIndexesInAllRows);

      return finalTableColumns;
    },
    [configureTableRows, widget.breakdownBy]
  );

  const loadWidgetData = useCallback(
    async (metricsDetails) => {
      setLoading(true);
      setError(false);
      setProgress(0);

      const totalQueries = usingComps
        ? metricsDetails.length * 2
        : metricsDetails.length;
      let completeQueries = 0;

      const builtColumns = [];
      for (const metric of metricsDetails) {
        if (widget.breakdownBy === 'filter' && !widget.breakdownFilters)
          builtColumns.push({ rows: [] });
        if (widget.breakdownBy === 'taxonomy' && !widget.breakdownTaxonomies)
          builtColumns.push({ rows: [] });

        // build the request body and fetch data for each metric for each period
        const path = apiEndpointByMetricKey(metric.metricKey);
        const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
          metric.details
        );
        const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

        const commonPayloadInputs = {
          period: widget.period,
          demographicFilter: widget.demographicFilter,
          aggregation: 'day', // we can hardcode for this widget because we know we don't need segments
          metricKey: metric.metricKey,
        };
        let rows = [];
        let unitType;
        let payloadInput = {};

        switch (widget.breakdownBy) {
          case 'location':
          default:
            payloadInput = {
              locations: visibleLocations,
              taxonomies: metric.taxonomy ? [metric.taxonomy] : undefined,
              breakdownByDimensions: ['entity'],
            };

            if (metric.filter) {
              payloadInput.taxonomies = [
                `${metric.filter.key}:${metric.filter.value}`,
              ];
            }

            rows = visibleLocations.map((locationId) => ({
              title: LocationModel.getLocationName(locationId, locations),
              locationId,
            }));
            break;
          case 'filter':
            // TODO: should throw an error if trying to do a filter breakdown with a
            // metric that doesn't use filters, e.g. total-time
            const taxonomies = widget.breakdownFilters
              ? widget.breakdownFilters.map(
                  (filter) => `${filter.key}:${filter.value}`
                )
              : undefined;
            payloadInput = {
              locations: widget.locations,
              taxonomies,
              breakdownByDimensions: ['taxonomy'],
            };
            rows = widget.breakdownFilters.map((filter) => ({
              title: `${filter.key}: ${filter.value}`,
              filter,
            }));
            break;
          case 'taxonomy':
            // TODO: should throw an error if trying to do a taxonomy breakdown with a
            // metric that doesn't use taxonomies, e.g. footfall
            payloadInput = {
              locations: widget.locations,
              taxonomies: widget.breakdownTaxonomies,
              breakdownByDimensions: ['taxonomy'],
            };
            rows = widget.breakdownTaxonomies.map((taxonomy) => ({
              title: prettyTaxonomyLabel(taxonomy),
              taxonomy,
            }));
            break;
        }

        const payloadInitialPeriod = buildPayload(queryPayloadType, {
          ...commonPayloadInputs,
          ...payloadInput,
        });

        let cells = [];

        try {
          const response = await fetchSQLQuery(
            payloadInitialPeriod,
            path,
            {
              returnErrors: true,
              return404AsError: false,
              metricKey: metric.metricKey,
            }
          );
          completeQueries++;
          setProgress(completeQueries / totalQueries);

          let compResponse = null;
          if (usingComps) {
            const payloadComparisonPeriod = buildPayload(queryPayloadType, {
              ...commonPayloadInputs,
              ...payloadInput,
              locations: payloadInput.locations,
              period: widget.comparisonPeriod,
            });

            compResponse = await fetchSQLQuery(
              payloadComparisonPeriod,
              path,
              {
                returnErrors: true,
                return404AsError: false,
                metricKey: metric.metricKey,
              }
            );
            completeQueries++;
            setProgress(completeQueries / totalQueries);
          }

          cells = rows.map((row) => {
            // extract the values and changeValues
            unitType = QueryResponseModel.getUnitType(
              response,
              metric.metricKey
            ); // this will get overwritten each time the loop runs but that's OK
            let value = 0;
            let compValue = 0;

            if (widget.breakdownBy === 'location' && row.locationId) {
              value = QueryResponseModel.getSummaryValue({
                response,
                locationId: row.locationId,
                metricKey: metric.metricKey,
              });
              compValue = compResponse
                ? QueryResponseModel.getSummaryValue({
                    response: compResponse,
                    locationId: row.locationId,
                    metricKey: metric.metricKey,
                  })
                : 0;
            } else if (widget.breakdownBy === 'filter' && row.filter) {
              value = QueryResponseModel.getSummaryValue({
                response,
                taxonomy: `${row.filter.key}:${row.filter.value}`,
                metricKey: metric.metricKey,
              });
              compValue = compResponse
                ? QueryResponseModel.getSummaryValue({
                    response: compResponse,
                    taxonomy: `${row.filter.key}:${row.filter.value}`,
                    metricKey: metric.metricKey,
                  })
                : 0;
            } else if (widget.breakdownBy === 'taxonomy' && row.taxonomy) {
              value = QueryResponseModel.getSummaryValue({
                response,
                taxonomy: row.taxonomy,
                metricKey: metric.metricKey,
              });
              compValue = compResponse
                ? QueryResponseModel.getSummaryValue({
                    response: compResponse,
                    taxonomy: row.taxonomy,
                    metricKey: metric.metricKey,
                  })
                : 0;
            }

            const { result } =
              compValue !== null && value !== null
                ? prettyValueToCompare(compValue, value)
                : { result: null };
            const changeValue = usingComps ? result : null;

            // decorate each response with information from availableMetrics, and the API response
            return {
              value,
              changeValue,
              unitType,
            };
          });
        } catch (e) {
          console.log(e);
        }

        if (!cells || cells.every((m) => !m.value)) {
          builtColumns.push({ rows: [] });
        } else {
          const showTaxonOrFilter = widget.breakdownBy === 'location';
          builtColumns.push({
            label: getColumnLabel(
              metric,
              availableMetrics,
              showTaxonOrFilter,
              showTaxonOrFilter
            ),
            description:
              !uiOptions.disableDescription &&
              AvailableMetricsModel.getMetricDescription(metric.details),
            unitType,
            rows: cells, // the UI needs a prop called rows, but cells makes a bit more sense here
          });
        }
      }

      const trimmedBuiltColumns = builtColumns.filter((c) => !!c);

      if (!trimmedBuiltColumns || !(trimmedBuiltColumns.length > 0)) {
        setError(true);
        setLoading(false);
        console.error('No data to show for Metric Heatmap widget');
      } else {
        const finalTableColumns: Array<Object> =
          stripEmptyRows(trimmedBuiltColumns);
        setTableColumns(finalTableColumns);
        setLoading(false);
      }
    },
    [
      availableMetrics,
      locations,
      stripEmptyRows,
      uiOptions.disableDescription,
      usingComps,
      visibleLocations,
      widget.breakdownBy,
      widget.breakdownFilters,
      widget.breakdownTaxonomies,
      widget.comparisonPeriod,
      widget.demographicFilter,
      widget.period,
      widget.locations,
    ]
  );

  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  const fetchData = useCallback(() => {
    if (isVisible) {
      configureTableRows();

      // get the information from available metrics data for the metric
      const metricsDetails = widget.metrics
        .map((m) => ({
          ...m,
          details: AvailableMetricsModel.findMetricDetails(
            availableMetrics,
            m.metricGroupKey,
            m.metricKey
          ),
        }))
        .filter((m) => !!m && !!m.details);

      if (!metricsDetails || !metricsDetails.length) {
        console.error(
          'No metrics found in availableMetrics for the Metric Heatmaps widget'
        );
        setError(true);
      } else {
        const breakdownLabel = {
          taxonomy: 'zone type',
          filter: 'filter',
          location: 'location',
        };
        setAutoTitle(`KPIs, by ${breakdownLabel[widget.breakdownBy]}`);
        loadWidgetData(metricsDetails);
      }
    }
  }, [
    availableMetrics,
    configureTableRows,
    isVisible,
    loadWidgetData,
    widget.breakdownBy,
    widget.metrics,
  ]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);
      fetchData();
    }
  }, [fetchRequired, fetchData, isVisible]);

  useEffect(() => {
    const start = page * Math.max(0, itemsPerPage);
    const end =
      itemsPerPage > 0
        ? Math.min(start + itemsPerPage, widget.locations.length)
        : widget.locations.length;
    setVisibleLocations(widget.locations.slice(start, end));
  }, [page, widget.locations, itemsPerPage]);

  useEffect(() => {
    setFetchRequired(true);
  }, [visibleLocations]);

  // Go back to the first page on changing page length
  useEffect(() => {
    setPage(0);
  }, [itemsPerPage, setPage]);

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget, true);

  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        progress={progress}
        title={title}
        subtitle={subtitle}
        tableColumns={tableColumns}
        tableRows={tableRows}
        colorScheme={widget.colorScheme}
        dataKey={widget.showChangeValue ? 'changeValue' : 'value'}
        sortByColumn={widget.sortByColumn}
        sortDirection={widget.sortDirection}
      >
        <Pagination
          pages={locations.length / itemsPerPage}
          setPage={setPage}
          currentPage={page}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
        />
      </WidgetUI>
    </div>
  );
};

export default MetricHeatmapWidget;
