import styled from 'styled-components';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon, Intent } from '@blueprintjs/core';

const WidgetTooltipWrapper = styled.div`
  cursor: help;
`;

export const WidgetTooltip = ({ text, description }) => (
  <WidgetTooltipWrapper>
    <Tooltip2 content={description}>{text ? text : (<Icon icon="info-sign" intent={Intent.PRIMARY} style={{marginInlineStart: '8px'}} />)}</Tooltip2>
  </WidgetTooltipWrapper>
);
