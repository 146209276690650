// @flow

import { Popover, Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import ButtonIconColorer from '../button-icon-colorer';
import {
  AXIS_GROUPING,
  AXIS_GROUPING_LABELS
} from '../../constants/graph-options';
import type { axisGroupingEnum } from '../../constants/graph-options';

const labels = AXIS_GROUPING_LABELS;

type Props = {
  selected: string,
  onChange(value: axisGroupingEnum): any,
  loading?: boolean
};

const AxisGroupingPicker = ({ selected, onChange, loading = false }: Props) => {
  const { SINGLE, BY_METRIC, BY_UNIT } = AXIS_GROUPING;
  const menu = (
    <Menu>
      <MenuItem
        key={BY_UNIT}
        text={labels[BY_UNIT]}
        onClick={() => onChange(BY_UNIT)}
      />
      <MenuItem
        key={BY_METRIC}
        text={labels[BY_METRIC]}
        onClick={() => onChange(BY_METRIC)}
      />
      <MenuItem
        key={SINGLE}
        text={labels[SINGLE]}
        onClick={() => onChange(SINGLE)}
      />
    </Menu>
  );

  return (
    <Popover disabled={loading} content={menu} position={Position.BOTTOM_RIGHT}>
      <ButtonIconColorer>
        <Button
          disabled={loading}
          icon="merge-columns"
          rightIcon="caret-down"
          text={labels[selected]}
        />
      </ButtonIconColorer>
    </Popover>
  );
};

export default AxisGroupingPicker;
