// @flow

import * as AvailableMetricsModel from './available-metrics';
import { getImpersonationId } from '../services/auth0';

export type featuresT = {
  [featureName: string]: string,
};
export type permissionsT = {
  [permissionName: string]: boolean,
};

export type t = {
  id: string,
  email: string,
  name?: string,
  password?: string,
  auth0Id: string,
  organisationName: string,
  isBeingImpersonated?: boolean,
  organisationId: string,
  features: featuresT,
  permissions: permissionsT,
  availableMetrics: AvailableMetricsModel.t,
  availableOrgs: { id: string, name: string }[],
  status: string,
  theme: string,
  options: {
    showSettingUpLocations: boolean,
    showDraftReports: boolean,
    startOfWeek: 'monday' | 'sunday',
  },
};

export const create = (args: t): t => args;

export const getOrganisationName = ({ organisationName }: t): string =>
  organisationName || '';

export const getAvailableMetrics = ({
  availableMetrics,
}: t): AvailableMetricsModel.t => {
  return availableMetrics;
};

export const getId = ({ id }: t): string | null => id || null;

export const getName = ({ name }: t): string => {
  return name ? name : '';
};
export const getEmail = ({ email }: t): string => {
  return email;
};

export const getOrganisationId = ({ organisationId }: t): string => {
  return organisationId;
};

export const getAuth0Id = ({ auth0Id }: t): string => {
  // if the user is being impersonated, use this as their auth0Id, otherwise
  // their auth0Id that is on the user model.
  // NB. the impersonated ID could be made up, it's only validated once the
  // refreshUser step has occurred
  const impersonatedAuth0Id = getImpersonationId();
  return impersonatedAuth0Id || auth0Id;
};

export const isBeingImpersonated = ({ isBeingImpersonated }: t): boolean =>
  !!isBeingImpersonated;

export const getUserStatus = (user: t): string => {
  const { status = 'active' } = user;
  return status;
};

export const getOptions = ({ options }: t): Object => options;
