// @flow

import { useState, useEffect } from 'react';
import { map } from 'awaity';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import WidgetUI from '../../widgets-ui/chart';
import {
  getQueryPayloadType,
  buildPayload
} from '../../../services/query-api';
import { AGGREGATION_PERIOD_LABELS_SENTENCE } from '../../../constants/graph-options';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { getDatesListInRangePeriod, getFormatDateByAggregation } from '../../../utils/dates';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';
import moment from 'moment';

const ChartWidget = ({
  widget,
  availableMetrics,
  uiOptions = {}
}: WidgetPropsModel.chartT) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [metricsForUI, setMetricsForUI] = useState([]);
  const [chartData, setChartData] = useState([]);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible((vi) => uiOptions.disableViewportLoading || vi > 0);

  const loadWidgetData = async metricsDetails => {
    setError(false);
    setLoading(true);

    let builtChartData = getDatesListInRangePeriod(
      widget.period.selectedDates,
      widget.aggregation
    ).map(index => ({ index }));

    const builtMetrics = await map(metricsDetails, async (metric, index) => {
      // build the request body and fetch data for each metric for each period
      const path = apiEndpointByMetricKey(metric.metricKey);

      const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
        metric.details
      );

      const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

      let taxonomies;
      if (metric.taxonomy) {
        taxonomies = [metric.taxonomy];
      } else if (metric.filter) {
        taxonomies = [`${metric.filter.key}:${metric.filter.value}`];
      }

      const payloadInput = {
        locations: widget.locations,
        period: widget.period,
        demographicFilter: widget.demographicFilter,
        taxonomies,
        aggregation: widget.aggregation,
        metricKey: metric.metricKey
      };

      const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

      let response;

      try {
        response = await fetchSQLQuery(payloadInitialPeriod, path, {
          returnErrors: true,
          return404AsError: false,
          metricKey: metric.metricKey,
        });

        const dataKey = `${metric.metricKey}-${index}`;

        const segmentKey = metric.metricKey.split(':')[0];

        if (response.segments) {
          response.segments.forEach(segment => {
            const formattedIndex = getFormatDateByAggregation(segment.index, widget.aggregation);
            const chartDataIndex = builtChartData.findIndex(
              cd => cd.index === formattedIndex
            );
            if (chartDataIndex > -1) {
              builtChartData[chartDataIndex][dataKey] = segment[segmentKey];
            } else {
              builtChartData.push({
                index: formattedIndex,
                [dataKey]: segment[segmentKey],
              });
            }
          });
        }

        // decorate each response with information from availableMetrics, and the API response
        return {
          label: getColumnLabel(metric, availableMetrics, true, true),
          description:
            !uiOptions.disableDescription &&
            AvailableMetricsModel.getMetricDescription(metric.details),
          chartDisplayType: metric.chartDisplayType,
          unitType: QueryResponseModel.getUnitType(response, metric.metricKey),
          dataKey
        };
      } catch (e) {
        // got an error
        return null;
      }
    });

    const trimmedBuiltMetrics = builtMetrics.filter(m => !!m);

    if (!trimmedBuiltMetrics || !trimmedBuiltMetrics.length) {
      setLoading(false);
      setError(true);
    }

    setMetricsForUI(trimmedBuiltMetrics);
    setChartData(builtChartData.sort((a, b) => moment(a).diff(moment(b))));
    setLoading(false);
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      // get the information from available metrics data for each metric
      const metricsDetails = widget.metrics
        .map(m => ({
          ...m,
          details: AvailableMetricsModel.findMetricDetails(
            availableMetrics,
            m.metricGroupKey,
            m.metricKey
          )
        }))
        .filter(m => !!m && !!m.details);

      const aggLabel = AGGREGATION_PERIOD_LABELS_SENTENCE[widget.aggregation.toLowerCase()];

      if (!metricsDetails || !metricsDetails.length) {
        console.error(
          'No metrics found in availableMetrics for the Chart widget'
        );
        setError(true);
        setAutoTitle(`KPIs, by ${aggLabel || widget.aggregation}`);
      } else {
        const [m1, m2] = metricsDetails;
        const m1Label = getColumnLabel(m1, availableMetrics, true, true);
        if (m2) {
          const m2Label = getColumnLabel(m2, availableMetrics, true, true);
          setAutoTitle(
            `${m1Label}, compared with ${m2Label}, by ${aggLabel ||
              widget.aggregation}`
          );
        } else {
          setAutoTitle(`${m1Label}, by ${aggLabel || widget.aggregation}`);
        }

        loadWidgetData(metricsDetails);
      }
    }
  }, [widget, fetchRequired, isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget, true);

  return (
    <div ref={currentElement}>
      <WidgetUI
        errors={error}
        loading={loading}
        title={title}
        subtitle={subtitle}
        metrics={metricsForUI}
        chartData={chartData}
        aggregation={widget.aggregation}
      />
    </div>
  );
};

export default ChartWidget;
