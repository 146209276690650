// @flow

import { FormGroup, InputGroup, Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import * as LocationModel from '../../models/location';
import currencyCodes from 'currency-codes';
import timezones from '../../constants/timezones';

const currencyCodesArray = currencyCodes.codes();

type Props = {
  location: LocationModel.t,
  canManageLocations: boolean,
  updateField: Object => void,
  updateSelect: (string, string) => void
};

const EditDetails = ({
  location,
  updateField,
  updateSelect,
  canManageLocations
}: Props) => {
  const filterPredicate = (query: string, item: string) => {
    return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  return (
    <>
      <FormGroup label="Name" labelFor="name">
        <InputGroup
          id="name"
          name="name"
          placeholder=""
          value={location.name || ''}
          type="text"
          onChange={updateField}
          disabled={!canManageLocations}
        />
      </FormGroup>
      <FormGroup label="Currency" labelFor="currency">
        <Select
          id="currency"
          name="currency"
          activeItem={location.currency || 'GBP'}
          items={currencyCodesArray}
          onItemSelect={value => updateSelect('currency', value)}
          itemPredicate={filterPredicate}
          disabled={!canManageLocations}
          itemRenderer={(currency, { handleClick }) => (
            <MenuItem
              key={currency}
              text={currency}
              active={currency === location.currency}
              onClick={handleClick}
            />
          )}
        >
          <Button
            text={location.currency}
            rightIcon="caret-down"
            disabled={!canManageLocations}
          />
        </Select>
      </FormGroup>
      <FormGroup label="Timezone" labelFor="timezone">
        <Select
          id="timezone"
          name="timezone"
          items={timezones}
          onItemSelect={value => updateSelect('timezone', value)}
          itemPredicate={filterPredicate}
          disabled={!canManageLocations}
          itemRenderer={(currentTimezone, { handleClick }) => (
            <MenuItem
              key={currentTimezone}
              text={currentTimezone}
              active={currentTimezone === location.timezone}
              onClick={handleClick}
            />
          )}
        >
          <Button
            text={location.timezone}
            rightIcon="caret-down"
            disabled={!canManageLocations}
          />
        </Select>
      </FormGroup>
      <FormGroup label="Coordinates" labelFor="coordinates">
        <InputGroup
          id="coordinates"
          name="coordinates"
          placeholder=""
          value={location.coordinates || ''}
          type="text"
          onChange={updateField}
          disabled={!canManageLocations}
        />
      </FormGroup>
      {location.liveOccupancyConfiguration && (
        <FormGroup label="Maximum Capacity" labelFor="capacity">
          <InputGroup
            id="capacity"
            name="capacity"
            placeholder=""
            value={location.liveOccupancyConfiguration.locationCapacity || ''}
            type="text"
            onChange={updateField}
            disabled={!canManageLocations}
          />
        </FormGroup>
      )}
    </>
  );
};

export default EditDetails;
