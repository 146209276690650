import { Tooltip2 } from '@blueprintjs/popover2';
import { HeaderCell } from '.';
import { Icon } from '@blueprintjs/core';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  opacity: 0.6;
  vertical-align: text-top;

  &:hover {
    opacity: 1;
  }
`;

export const SortableHeader = ({
  order,
  keyName,
  label,
  updateFilter,
  center = false,
  tooltipContent,
}) =>
  tooltipContent ? (
    <HeaderCell
      sortable
      onClick={() => updateFilter(keyName, !order)}
      center={center}
    >
      {label}
      {order !== undefined && (
        <Icon icon={!order ? 'caret-up' : 'caret-down'} size={18} />
      )}
      <Tooltip2 content={tooltipContent} placement="top">
        <StyledIcon icon="info-sign" size={18} />
      </Tooltip2>
    </HeaderCell>
  ) : (
    <HeaderCell
      sortable
      onClick={() => updateFilter(keyName, !order)}
      center={center}
    >
      {label}
      {order !== undefined && (
        <Icon icon={!order ? 'caret-up' : 'caret-down'} size={18} />
      )}
    </HeaderCell>
  );
