import styled from 'styled-components';
import { Spinner, Classes } from '@blueprintjs/core';

const Wrapper = styled.section`
  padding: 2em;
  background: var(--light-blue);
  width: 100%;
  text-align: center;
`;

const PreInitLoading = () => (
  <Wrapper>
    <Spinner className={`pre-init-spinner ${Classes.SMALL}`} />
  </Wrapper>
);

export default PreInitLoading;
