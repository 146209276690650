import React, { useContext } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import CustomTooltip from './tooltip';
import { getChartColors } from '../../styles/variables';
import { prettyNumber } from '@auravisionlabs/aura-pkg-ui-formatter';
import { axisTimeFormatter, getAxisInterval } from '../../utils/graphHelpers';
import { ThemeContext } from 'styled-components';

const LineGraph = ({
  chartData,
  activeLines,
  aggregationPeriod,
  hasFilterApplied,
  axisType,
}, chartRef) => {
  const theme = useContext(ThemeContext);
  return (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart data={chartData} ref={chartRef ? chartRef : null}>
        <XAxis
          dataKey="index"
          tickFormatter={(value) =>
            axisTimeFormatter(value, aggregationPeriod, axisType)
          }
          interval={getAxisInterval(aggregationPeriod)}
        />
        <YAxis tickFormatter={prettyNumber} />
        <CartesianGrid vertical={false} />
        <Tooltip
          formatter={prettyNumber}
          content={
            <CustomTooltip
              axisType={axisType}
              hasFilterApplied={hasFilterApplied}
              activeLines={activeLines}
              aggregationPeriod={aggregationPeriod}
            />
          }
        />
        <Legend />
        {activeLines.map((l) => {
          const chartColors = getChartColors(theme);
          return (
            <Area
              stackId={1}
              type="monotone"
              key={l.key}
              name={l.label}
              stroke={chartColors[l.key] || l.color}
              fill={chartColors[l.key] || l.color}
              strokeWidth="2"
              dot={{ r: 2, fill: chartColors[l.key] || l.color }}
              dataKey={l.key}
              activeDot={{ r: 6 }}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

// $FlowFixMe Flow does not support forwarding refs properly
export default React.forwardRef(LineGraph);
