// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import { Popover, Menu, MenuItem, Icon, Divider } from '@blueprintjs/core';
import { ASCENDANT, DESCENDANT, NONE } from '../../utils/metricsTableHelpers';
import { isEqual } from 'lodash';

import * as ActiveMetricsModel from '../../models/active-metrics';
import type { sortByT, sortDirectionT } from '../metrics-table';

export const IconStyled = styled(Icon)`
  margin: 0;
  margin-left: 0.5em;
  &:hover {
    cursor: pointer;
    color: gray;
  }
`;

type Props = {
  columnDetails: ActiveMetricsModel.itemT,
  isComparisonActive?: boolean,
  sortBy: sortByT,
  handleSortBy(sortBy: sortByT): void
};

const SortMetricsMenu = ({
  columnDetails,
  isComparisonActive,
  sortBy,
  handleSortBy
}: Props) => {
  const isIconActive = (
    sortDirectionMenu: sortDirectionT,
    isSortByComparisonMenu: boolean
  ): boolean => {
    return (
      columnDetails &&
      sortBy.columnDetails &&
      isEqual(sortBy.columnDetails, columnDetails) &&
      sortBy.sortDirection === sortDirectionMenu &&
      sortBy.isSortByComparison === isSortByComparisonMenu
    );
  };

  const displayIcon = (
    sortDirectionMenu: sortDirectionT,
    isSortByComparisonMenu: boolean
  ): string => {
    if (sortBy.sortDirection === NONE && sortDirectionMenu === NONE) {
      return 'tick';
    } else {
      return isIconActive(sortDirectionMenu, isSortByComparisonMenu)
        ? 'tick'
        : 'blank';
    }
  };

  const isGeneralLocationHeading = ActiveMetricsModel.isGeneralLocationHeading(
    columnDetails
  );

  return (
    <Popover>
      <IconStyled icon="double-caret-vertical" />
      <Menu>
        <MenuItem
          text="Sort by value (high to low)"
          onClick={() =>
            handleSortBy({
              columnDetails,
              sortDirection: DESCENDANT,
              isSortByComparison: false
            })
          }
          icon={displayIcon(DESCENDANT, false)}
        />
        <MenuItem
          text="Sort by value (low to high)"
          onClick={() =>
            handleSortBy({
              columnDetails,
              sortDirection: ASCENDANT,
              isSortByComparison: false
            })
          }
          icon={displayIcon(ASCENDANT, false)}
        />
        {!isGeneralLocationHeading && isComparisonActive && (
          <Fragment>
            <Divider />
            <MenuItem
              text="Sort by change (high to low)"
              onClick={() =>
                handleSortBy({
                  columnDetails,
                  sortDirection: DESCENDANT,
                  isSortByComparison: true
                })
              }
              icon={displayIcon(DESCENDANT, true)}
            />
            <MenuItem
              text="Sort by change (low to high)"
              onClick={() =>
                handleSortBy({
                  columnDetails,
                  sortDirection: ASCENDANT,
                  isSortByComparison: true
                })
              }
              icon={displayIcon(ASCENDANT, true)}
            />
          </Fragment>
        )}
        <Divider />
        <MenuItem
          text="No Sorting"
          onClick={() =>
            handleSortBy({
              columnDetails,
              sortDirection: NONE,
              isSortByComparison: false
            })
          }
          icon={displayIcon(NONE, false)}
        />
      </Menu>
    </Popover>
  );
};

export default SortMetricsMenu;
