import styled from 'styled-components';

const UptimeIndicator = styled.div`
  font-weight: bold;
  padding: 0.75rem 0;
  background-color: ${({ background }) => background};
  color: #fff;
  border-radius: 12px;
  width: 4rem;
  height: 2.5rem;
  font-size: 1rem;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VariableUptimeIndicator = ({
  value,
  lowerThreshold = 0.8,
  upperThreshold = 0.95,
}) => {
  const fixedValue = isNaN(value) ? 0 : value;
  const uptimePercent = isNaN(value) ? 0 : value * 100; 
  return (
    <Wrapper>
      <UptimeIndicator
        background={() => {
          if (fixedValue < lowerThreshold) return 'var(--error)';
          if (fixedValue >= lowerThreshold && fixedValue < upperThreshold)
            return 'var(--warning)';
          return 'var(--ok)';
        }}
      >
        {Math.round(uptimePercent)}%
      </UptimeIndicator>
    </Wrapper>
  );
};
