// @flow

import styled from 'styled-components';
import moment from 'moment-timezone';
import { Button } from '@blueprintjs/core';
import download from 'downloadjs';
import {
  CHART_TYPE,
  AGGREGATION_PERIOD,
  CHART_DATA
} from '../../constants/graph-options';
import { dayOfWeekLabel, hourOfDayLabel } from '../../utils/dates';
import DownloadOptionsBar from './bar';
import * as LocationModel from '../../models/location';

import type { activeLinesT, chartDataT } from '../graph-tile';

const StyledButton = styled(Button)`
  &&& {
    z-index: unset;
    position: unset;
  }
`;

type Props = {
  uptimeStats?: Object[], //TODO: properly type this
  chartData: chartDataT[],
  pcChartData?: chartDataT[],
  activeLines?: activeLinesT[],
  filename: string,
  chartType?: string,
  aggregationPeriod: string,
  loading?: boolean,
  locations: LocationModel.t[]
};

const DownloadOptions = ({
  uptimeStats,
  chartData,
  activeLines,
  pcChartData,
  filename,
  chartType,
  aggregationPeriod,
  locations,
  loading = false
}: Props) => {
  const buttonDisabled = !chartData.length;

  const formatIndexForExport = index => {
    if (chartType === CHART_TYPE.PIE) {
      return index;
    }

    switch (aggregationPeriod.toLowerCase()) {
      case AGGREGATION_PERIOD.DAY:
        return moment(index).format('YYYY-MM-DD');
      case AGGREGATION_PERIOD.WEEK_ISO:
        return moment(index).format('YYYY-MM-DD');
      case AGGREGATION_PERIOD.WEEK_US:
        return moment(index).format('YYYY-MM-DD');
      case AGGREGATION_PERIOD.MONTH:
        return moment(index).format('YYYY-MM');
      case AGGREGATION_PERIOD.DAY_OF_WEEK:
        if (typeof index !== 'number') return index;
        return dayOfWeekLabel(index);
      case AGGREGATION_PERIOD.HOUR_OF_DAY:
        if (typeof index !== 'number') return index;
        return hourOfDayLabel(index);
      default:
        return moment(index).format('YYYY-MM-DD HH:mm');
    }
  };

  const getLabelForHeader = index => {
    if (activeLines) {
      const line = activeLines.find(l => l.key === index) || {};
      return line.label ? line.label.replace(/,/g, '') : index;
    } else {
      return index;
    }
  };

  const onDownloadChart = () => {
    if (buttonDisabled) return false;

    let dataToExport = chartData;

    if (chartType === CHART_TYPE.REL_BAR && pcChartData) {
      dataToExport = pcChartData;
    }

    const headersSet = new Set();
    const prettyHeaders = new Set();
    dataToExport.forEach(row => {
      const keys = Object.keys(row);
      keys
        .filter(
          key =>
            !key.endsWith(CHART_DATA.CHANGE) &&
            !key.endsWith(CHART_DATA.COMPARE) &&
            key !== 'compareToPastIndex'
        )
        .forEach(key => {
          const keyParts = key.split('__')
          if (keyParts.includes('location')) {
            const mongoId = keyParts.find(part => part.match(/^[a-f\d]{24}$/i));
            const header = mongoId ? key.replace(mongoId, (locations.find(l => l.id === mongoId) || {name: key}).name) : key;
            prettyHeaders.add(header);
          } else {
            prettyHeaders.add(key);
          }
          headersSet.add(key)
        });
    });

    const headers = Array.from(headersSet).sort((a, b) =>
      b === 'index' ? 1 : 0
    );
    const rows = [[...prettyHeaders]];

    dataToExport.forEach(item => {
      const row = [];
      headers.forEach(header => {
        if (header === 'index') {
          row.push(formatIndexForExport(item[header]));
        } else {
          row.push(item[header]);
        }
      });
      rows.push(row);
    });

    // find and insert a label for the headers
    rows[0] = rows[0].map(item => {
      if (item === 'index') return item;
      return getLabelForHeader(item);
    });

    let payload = '';
    rows.forEach(row => {
      const rowStr = row.join(',');
      payload += rowStr + '\r\n';
    });

    const fullFilename = `${filename}_chart_data.csv`;

    download(payload, fullFilename, 'text/csv');
  };

  return (
    <DownloadOptionsBar>
      <StyledButton
        disabled={buttonDisabled || loading}
        onClick={onDownloadChart}
        icon="floppy-disk"
        data-automation-trigger="download-chart-csv"
      >
        Export chart data (CSV)
      </StyledButton>
    </DownloadOptionsBar>
  );
};

export default DownloadOptions;
