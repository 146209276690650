// @flow

import styled from 'styled-components';
import { Tag, Callout } from '@blueprintjs/core';
import * as ActiveMetricsModel from '../../../../models/active-metrics';
import TaxonomyMenu from '../../../pickers/taxonomy-menu';
import { taxonomiesConfigurationObjectCreator } from '../../../../utils/taxonomyHelpers';

type Props = {
  uniqTaxonNames: string[],
  activeMetrics: ActiveMetricsModel.t,
  setActiveMetrics: (updatedMetrics: Object) => void,
  subMenuKey: string
};

const TaxonomyTag = styled(Tag)`
  margin: 0 1rem;
`;
const StyledCallout = styled(Callout)`
  margin: 2rem 0;
  padding: 1.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const TaxonomyManager = ({
  uniqTaxonNames,
  activeMetrics,
  setActiveMetrics,
  subMenuKey
}: Props) => {
  const availableTaxonomyList = taxonomiesConfigurationObjectCreator(
    uniqTaxonNames
  );
  const subMenuConfiguration = {
    taxonomies: uniqTaxonNames,
    taxonomySelectLabel: 'Select live occupancy zones'
  };
  const numberOfActivesTaxonomies = ActiveMetricsModel.getListPropLength(
    activeMetrics,
    subMenuKey,
    'taxonomies'
  );

  return (
    <StyledCallout>
      <TaxonomyMenu
        availableTaxonomyList={availableTaxonomyList}
        subMenuKey={subMenuKey}
        // $FlowIgnore - subMenuConfiguration not really relavant in this usage
        subMenuConfiguration={subMenuConfiguration}
        editActiveMetrics={config => {
          const updatedMetrics = ActiveMetricsModel.getUpdatedActiveMetrics(
            activeMetrics,
            {
              subMenuKey: config.subMenuKey,
              changeToTaxonomy: config.taxonomy
            }
          );
          setActiveMetrics(updatedMetrics);
        }}
        activeMetrics={activeMetrics}
      />
      <TaxonomyTag large round intent="primary">
        {`${numberOfActivesTaxonomies} Selected`}
      </TaxonomyTag>
    </StyledCallout>
  );
};

export default TaxonomyManager;
