// @flow

import { Component, Fragment } from 'react';
import styled from 'styled-components';
import MetricsMenu from '../metrics-menu';
import MultipleMetricsTable from '../multiple-metrics-table';
import { PanelBody, PanelFooter } from '../panel';

import * as ActiveMetricsModel from '../../models/active-metrics';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as LocationModel from '../../models/location';
import * as PageSettingsModel from '../../models/page-settings';
import * as QueryResponseListModel from '../../models/query-response-list';
import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';
import { Button } from '@blueprintjs/core';

const StyledPanelBody = styled(PanelBody)`
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 0;
`;
const StyledPanelFooter = styled(PanelFooter)`
  justify-content: space-between;
`;

type Props = {
  fetchingQueryData: boolean,
  availableMetrics: AvailableMetricsModel.t,
  locations: LocationModel.t[],
  settings: PageSettingsModel.explorerT,
  showStaffFilters: boolean,
  handleGenericUpdate(
    newState: PageSettingsModel.explorerT,
    fetchQueryResponses: boolean
  ): void,
  queryResponseList: QueryResponseListModel.t
};
type State = {
  newActiveMetric: ActiveMetricsModel.t | {}
};

class MultipleMetricsManager extends Component<Props, State> {
  state = {
    newActiveMetric: {}
  };

  confirmAddMetric = () => {
    const { handleGenericUpdate } = this.props;
    const { newActiveMetric } = this.state;
    const activeMetrics = ActiveMetricsModel.getColumnsFromActiveMetrics(
      newActiveMetric
    );

    const updatedMetricsState = {
      ...this.props.settings,
      metrics: [...this.props.settings.metrics]
    };

    activeMetrics.forEach(metric => {
      const metricConfig = this.generateNewMetricConfig(metric);
      updatedMetricsState.metrics.push(metricConfig);
    });

    this.deleteMetricFromTheLocalState();
    handleGenericUpdate(updatedMetricsState, true);
  };

  deleteMetricFromTheLocalState = () => {
    this.setState({
      newActiveMetric: {}
    });
  };

  generateNewMetricConfig = (
    metric: ActiveMetricsModel.itemT
  ): PageSettingsModel.metricItemT => {
    const { locations } = this.props;
    const locationsIds = locations.map(loc => loc.id);

    const metricPayload: PageSettingsModel.metricItemT = {
      metric,
      location: locationsIds,
      demographicFilter: {}
    };

    return metricPayload;
  };

  addMetricToTheLocalState = ({
    subMenuKey,
    metricElementKey,
    taxonomy,
    filter
  }: editSummaryPageActiveMetricsArgsT) => {
    const { newActiveMetric } = this.state;
    const updatedNewActiveMetric = ActiveMetricsModel.getUpdatedActiveMetrics(
      newActiveMetric,
      {
        subMenuKey,
        metricElementKey,
        changeToTaxonomy: taxonomy,
        changeToFilter: filter
      }
    );

    this.setState({
      newActiveMetric: updatedNewActiveMetric
    });
  };

  updateMetricsLocations = (locations: Array<string>, index?: ?number) => {
    const { settings, handleGenericUpdate } = this.props;
    const { metrics } = settings;

    const newMetrics = metrics.map((metric, currentIndex) => {
      if (currentIndex === index) {
        return {
          ...metric,
          location: locations,
        };
      }

      return metric;
    });

    const updatedMetricsState = {
      ...settings,
      metrics: newMetrics
    };

    handleGenericUpdate(updatedMetricsState, true);
  };

  handleExcludeStaff = (index: number) => {
    const { settings, handleGenericUpdate } = this.props;
    const { metrics } = settings;

    const newMetrics = metrics.map((metric, currentIndex) => {
      if (currentIndex === index) {
        return metric.demographicFilter && metric.demographicFilter.role
          ? {
              ...metric,
              demographicFilter: {}
            }
          : {
              ...metric,
              demographicFilter: {
                role: 'customer'
              }
            };
      }

      return metric;
    });

    const updatedMetricsState = {
      ...settings,
      metrics: newMetrics
    };

    handleGenericUpdate(updatedMetricsState, true);
  };

  handleDeleteMetric = (index: number) => {
    const { settings, handleGenericUpdate } = this.props;
    const { metrics } = settings;

    const newMetrics = metrics.filter((metric, currentIndex) => {
      return currentIndex !== index;
    });

    const updatedMetricsState = {
      ...settings,
      metrics: newMetrics
    };

    handleGenericUpdate(updatedMetricsState, false);
  };

  handleDeleteAllMetrics = () => {
    const { settings, handleGenericUpdate } = this.props;

    const updatedMetricsState = {
      ...settings,
      metrics: []
    };

    handleGenericUpdate(updatedMetricsState, false);
  };

  render() {
    const {
      fetchingQueryData,
      availableMetrics,
      locations,
      settings,
      showStaffFilters,
      queryResponseList
    } = this.props;
    const { newActiveMetric } = this.state;
    const { metrics } = settings;

    return (
      <>
        <StyledPanelBody>
          <MultipleMetricsTable
            metrics={metrics}
            availableMetrics={availableMetrics}
            locations={locations}
            updateMetricsLocations={this.updateMetricsLocations.bind(this)}
            handleExcludeStaff={this.handleExcludeStaff.bind(this)}
            handleDeleteMetric={this.handleDeleteMetric.bind(this)}
            showStaffFilters={showStaffFilters}
            queryResponseList={queryResponseList}
            period={settings.period.selectedDates}
            aggregation={settings.aggregation}
            loading={fetchingQueryData}
          />
        </StyledPanelBody>
        <StyledPanelFooter>
          <MetricsMenu
            strictModeSelection
            minimalistic
            fetchingQueryData={fetchingQueryData}
            availableMetrics={availableMetrics}
            onMenuClose={this.confirmAddMetric.bind(this)}
            buttonLabel="Add Metrics"
            activeMetrics={newActiveMetric}
            editActiveMetrics={({
              subMenuKey,
              metricElementKey,
              taxonomy,
              filter
            }) =>
              this.addMetricToTheLocalState({
                subMenuKey,
                metricElementKey,
                taxonomy,
                filter
              })
            }
            resetState={this.deleteMetricFromTheLocalState.bind(this)}
          />
          <Button onClick={this.handleDeleteAllMetrics} intent="danger" icon="trash">Clear Metrics</Button>
        </StyledPanelFooter>
      </>
    );
  }
}

export default MultipleMetricsManager;
