const { each } = require('awaity');
const { NotAuthorisedError } = require('../authoriser/error/notAuthorisedError');

/**
 * Auth middleware - at least one of the injected tests must not throw
 * NotAuthorisedError
 *
 * All of the injected tests must not throw any other kind of error
 *
 * Allows override of:
 * - OverrideNotAuthorisedError inject it here if pkg/NotAuthorisedError is not
 * caught in your implementation
 *
 * @param rules
 * @param OverrideNotAuthorisedError
 * @returns {function(...[*]=)}
 */
module.exports.authAny = (
  rules,
  OverrideNotAuthorisedError = NotAuthorisedError,
) => async (req, res, next) => {
  try {
    // If it's not already one, make the injected rules an array
    const arrayOfRules = Array.isArray(rules) ? rules : [rules];
    let onePassed = false;
    let inappropriateThrow = false;

    // Evaluate each of the rules and track if any pass
    // or if any throw with an error that is not
    // NotAuthorisedError
    await each(arrayOfRules, async (rule) => {
      try {
        await rule.evaluate();
        onePassed = true;
      } catch (err) {
        if (!(err instanceof NotAuthorisedError)) {
          inappropriateThrow = err;
        }
      }
    });

    // If something threw that was not NotAuthorisedError
    // then bubble it
    if (inappropriateThrow) return next(inappropriateThrow);

    // If at least one passed the open the gate!
    if (onePassed) return next();

    // Otherwise at least one has not passed, so throw
    // NotAuthorisedError (or overridden error class)
    return next(new OverrideNotAuthorisedError());
  } catch (err) {
    return next(err);
  }
};
