// @flow

import { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Divider } from '@blueprintjs/core';
import { DateRangePicker } from '@blueprintjs/datetime';
import * as PERIOD_PRESETS from '../../constants/period-presets';
import * as PREVIOUS_PERIOD_PRESETS from '../../constants/previous-period-presets';
import {
  getStandardFormatDate,
  getDateRangeFromPresetPeriod
} from '../../utils/dates';
import * as QueryApiRequestModel from '../../models/query-api-request';
import * as PeriodModel from '../../models/period';

const MOMENT_INVALID_DATE = 'Invalid date';
const DatePickerWithConfirmWrapper = styled.div`
  display: flex;
`;

type Props = {
  handleOpenDatePickerWithConfirm(): void,
  handlePresetClick(period: PeriodModel.t | PeriodModel.comparePeriodT): void,
  isComparisonPeriod?: boolean,
  currentPeriod: PeriodModel.t,
  currentCompareToPeriod?: PeriodModel.comparePeriodT
};

type State = {
  period?: QueryApiRequestModel.periodT
};

class DatePickerWithConfirm extends Component<Props, State> {
  state = {
    period: undefined
  };

  updatePeriod = (dates: Array<Object>) => {
    const start = getStandardFormatDate(dates[0]);
    const end = getStandardFormatDate(dates[1]);

    if (start && end) {
      this.setState({
        period: {
          start,
          end
        }
      });
    }
  };

  handleConfirm() {
    const { period } = this.state;
    const {
      handleOpenDatePickerWithConfirm,
      handlePresetClick,
      isComparisonPeriod
    } = this.props;

    if (
      period &&
      period.start &&
      period.end &&
      period.start !== MOMENT_INVALID_DATE &&
      period.end !== MOMENT_INVALID_DATE
    ) {
      if (!isComparisonPeriod) {
        const explorerPagePeriod: PeriodModel.t = {
          selectedPreset: PERIOD_PRESETS.custom,
          selectedDates: period,
          customPeriodDates: period
        };

        handlePresetClick(explorerPagePeriod);
      } else if (isComparisonPeriod) {
        const explorerPagePeriod: PeriodModel.comparePeriodT = {
          active: true,
          selectedPreset: PREVIOUS_PERIOD_PRESETS.custom,
          selectedDates: period,
          customPeriodDates: period
        };

        handlePresetClick(explorerPagePeriod);
      }
    }

    handleOpenDatePickerWithConfirm();
  }

  render() {
    const {
      isComparisonPeriod,
      currentCompareToPeriod,
      currentPeriod
    } = this.props;

    const displayComparisonDate =
      isComparisonPeriod &&
      currentCompareToPeriod &&
      currentCompareToPeriod.active;

    const dateRange = getDateRangeFromPresetPeriod(currentPeriod);
    const dateRangeCompareToPeriod = getDateRangeFromPresetPeriod(
      displayComparisonDate ? currentCompareToPeriod : {}
    );
    const compareToPeriodStartDate =
      displayComparisonDate &&
      currentCompareToPeriod &&
      currentCompareToPeriod.selectedDates &&
      moment(currentCompareToPeriod.selectedDates.start).toDate();
    const currentPeriodStartDate = moment(
      currentPeriod.selectedDates.start
    ).toDate();

    return (
      <DatePickerWithConfirmWrapper>
        <DateRangePicker
          allowSingleDayRange
          maxDate={new Date()}
          shortcuts={false}
          onChange={this.updatePeriod.bind(this)}
          defaultValue={
            isComparisonPeriod ? dateRangeCompareToPeriod : dateRange
          }
          initialMonth={
            displayComparisonDate
              ? compareToPeriodStartDate
              : currentPeriodStartDate
          }
        />
        <Divider />
        <Button
          intent="success"
          icon="tick"
          text="Confirm"
          onClick={this.handleConfirm.bind(this)}
        />
      </DatePickerWithConfirmWrapper>
    );
  }
}

export default DatePickerWithConfirm;
