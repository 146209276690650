// @flow

import { removePresetFromCompPeriodAllowed } from '../../utils/datesPresetsConfigHelpers';

import * as PERIOD_PRESETS from '../../constants/period-presets';
import * as PREVIOUS_PERIOD_PRESETS from '../../constants/previous-period-presets';

import * as PeriodModel from '../../models/period';

export const DIVIDER = 'divider';

export const PRESET_TITLE_MODIFIER = {
  DAYS_COUNT: '<DAYS_COUNT>'
};

type dateFormatEnum = 'ddd, MMM Do' | 'MMM Do' | 'MMM Do, YYYY' | 'blank';
export const dayMonthDateFormat = 'ddd, MMM Do';
export const monthDateFormat = 'MMM Do';
export const monthDayYearFormat = 'MMM Do, YYYY';
export const blankFormat = 'blank';

export type defaultPresetsAllowedT = (PeriodModel.periodPresetsT | 'divider')[];

export type compPeriodAllowedT = (
  | PeriodModel.compareToPastPresetsT
  | 'divider'
)[];

export const defaultPresetsAllowed: defaultPresetsAllowedT = [
  PERIOD_PRESETS.lastFullDay,
  PERIOD_PRESETS.last7Days,
  PERIOD_PRESETS.lastFullWeek,
  PERIOD_PRESETS.lastFullUSWeek,
  PERIOD_PRESETS.weekToDate,
  DIVIDER,
  PERIOD_PRESETS.last4FullWeeks,
  PERIOD_PRESETS.last4FullUSWeeks,
  PERIOD_PRESETS.last30Days,
  PERIOD_PRESETS.lastFullMonth,
  PERIOD_PRESETS.monthToDate,
  DIVIDER,
  PERIOD_PRESETS.last3FullMonths,
  PERIOD_PRESETS.lastFullQuarter,
  PERIOD_PRESETS.quarterToDate,
  DIVIDER,
  PERIOD_PRESETS.lastFullYear,
  PERIOD_PRESETS.yearToDate,
  DIVIDER,
  PERIOD_PRESETS.custom
];

const commonPresets = [
  DIVIDER,
  PREVIOUS_PERIOD_PRESETS.custom,
  PREVIOUS_PERIOD_PRESETS.none
];

export type previousPeriodPresetConfigItemT = {
  title: string,
  format: {
    startDate: dateFormatEnum,
    endDate: dateFormatEnum
  }
};
type comparisonPeriodPresetConfigT = {
  [key: PeriodModel.compareToPastPresetsT]: previousPeriodPresetConfigItemT
};

export const comparisonPeriodPresetConfig: comparisonPeriodPresetConfigT = {
  [PREVIOUS_PERIOD_PRESETS.previousDay]: {
    title: 'previous day',
    format: {
      startDate: dayMonthDateFormat,
      endDate: blankFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.previousWeek]: {
    title: 'previous week',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.previousMonth]: {
    title: 'previous month',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.previousQuarter]: {
    title: 'previous quarter',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.previousYear]: {
    title: 'previous year',
    format: {
      startDate: monthDayYearFormat,
      endDate: monthDayYearFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.sameWeekLastYear]: {
    title: 'same week last year',
    format: {
      startDate: monthDayYearFormat,
      endDate: monthDayYearFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.previousPeriod]: {
    title: `previous ${PRESET_TITLE_MODIFIER.DAYS_COUNT} days`,
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.custom]: {
    title: 'custom',
    format: {
      startDate: monthDayYearFormat,
      endDate: monthDayYearFormat
    }
  },
  [PREVIOUS_PERIOD_PRESETS.none]: {
    title: 'no comparison',
    format: {
      startDate: blankFormat,
      endDate: blankFormat
    }
  }
};

export type periodPresetConfigItemT = {
  title: string,
  format: {
    startDate: dateFormatEnum,
    endDate: dateFormatEnum
  },
  compPeriodAllowed: compPeriodAllowedT
};
export type periodPresetConfigT = {
  [key: PeriodModel.periodPresetsT]: periodPresetConfigItemT
};
export const defaultPeriodPresetConfig: periodPresetConfigT = {
  [PERIOD_PRESETS.lastFullDay]: {
    title: 'Yesterday',
    format: {
      startDate: dayMonthDateFormat,
      endDate: blankFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousDay,
      PREVIOUS_PERIOD_PRESETS.previousWeek,
      PREVIOUS_PERIOD_PRESETS.previousYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.last7Days]: {
    title: 'Last 7 days',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.lastFullWeek]: {
    title: 'Last full week (Mon-Sun)',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousWeek,
      PREVIOUS_PERIOD_PRESETS.sameWeekLastYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.lastFullUSWeek]: {
    title: 'Last full week (Sun - Sat)',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousWeek,
      PREVIOUS_PERIOD_PRESETS.sameWeekLastYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.weekToDate]: {
    title: 'Week to date',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    },
    compPeriodAllowed: [PREVIOUS_PERIOD_PRESETS.previousWeek, ...commonPresets]
  },
  [PERIOD_PRESETS.last4FullWeeks]: {
    title: 'Last 4 full weeks (Mon-Sun)',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.last4FullUSWeeks]: {
    title: 'Last 4 full weeks (Sun - Sat)',
    format: {
      startDate: dayMonthDateFormat,
      endDate: dayMonthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.last30Days]: {
    title: 'Last 30 days',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.lastFullMonth]: {
    title: 'Last full month',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousMonth,
      PREVIOUS_PERIOD_PRESETS.previousYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.monthToDate]: {
    title: 'Month to date',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousMonth,
      PREVIOUS_PERIOD_PRESETS.previousYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.last90Days]: {
    title: 'Last 90 days',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.last3FullMonths]: {
    title: 'Last 3 full months',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousYear,
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.lastFullQuarter]: {
    title: 'Last full quarter',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousQuarter,
      PREVIOUS_PERIOD_PRESETS.previousYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.quarterToDate]: {
    title: 'Quarter to date',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousQuarter,
      PREVIOUS_PERIOD_PRESETS.previousYear,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.last12Months]: {
    title: 'Last 12 months',
    format: {
      startDate: monthDayYearFormat,
      endDate: monthDayYearFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  },
  [PERIOD_PRESETS.lastFullYear]: {
    title: 'Last full year',
    format: {
      startDate: monthDayYearFormat,
      endDate: monthDayYearFormat
    },
    compPeriodAllowed: [PREVIOUS_PERIOD_PRESETS.previousYear, ...commonPresets]
  },
  [PERIOD_PRESETS.yearToDate]: {
    title: 'Year to date',
    format: {
      startDate: monthDateFormat,
      endDate: monthDateFormat
    },
    compPeriodAllowed: [PREVIOUS_PERIOD_PRESETS.previousYear, ...commonPresets]
  },
  [PERIOD_PRESETS.custom]: {
    title: 'Custom',
    format: {
      startDate: monthDayYearFormat,
      endDate: monthDayYearFormat
    },
    compPeriodAllowed: [
      PREVIOUS_PERIOD_PRESETS.previousPeriod,
      ...commonPresets
    ]
  }
};

export const explorerPagePeriodPresetConfig = removePresetFromCompPeriodAllowed(
  defaultPeriodPresetConfig,
  PREVIOUS_PERIOD_PRESETS.custom
);
