// @flow

import { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Spinner, Alert, Intent } from '@blueprintjs/core';
import RolePicker from '../pickers/user-role-picker';
import NoAreasPanel from '../no-areas-panel';
import {
  TableHeadings,
  TableHeadingColumn,
  FirstCellHeadingColumn,
  TableRowItem,
  TableRow,
  Table,
  StyledButton,
  AlertSpinner
} from './table';

import * as UserListModel from '../../models/user-list';
import * as UserModel from '../../models/user';

import { inviteUser } from '../../services/auth-api';
import { errorToast } from '../../utils/toaster';
import { USER_TYPE } from '../../constants/user-types';

const StyledTableRowItem = styled(TableRowItem)`
  color: rgba(0, 0, 0, 0.6);

  ${StyledButton}:first-child {
    margin-right: 1rem;
  }
`;

type Props = {
  users?: UserListModel.t,
  loading?: boolean,
  currentUser: UserModel.t,
  deleteUser(userId: number, userType: UserListModel.userListTypeEnumT): void,
  addRole(user: UserListModel.userT, roleId: number): void,
  removeRole(user: UserListModel.userT, roleId: number): void
};

type State = {
  userToDelete: ?UserListModel.userT,
  deleteUserInProgress: boolean,
  roleUpdatingForUser: ?number
};

// TODO: this component is almost identical to the active-users table - consider merging them to reduce code
class InvitedUsersTable extends Component<Props, State> {
  state = {
    userToDelete: null,
    deleteUserInProgress: false,
    roleUpdatingForUser: null
  };

  setUserToDelete(userToDelete: ?UserListModel.userT) {
    this.setState({ userToDelete });
  }

  async handleDeleteConfirm(userId: number) {
    const { deleteUser } = this.props;
    const { userToDelete } = this.state;

    if (userToDelete) {
      this.setState({ deleteUserInProgress: true });
      await deleteUser(userToDelete.id, USER_TYPE.INVITED);
      this.setState({ userToDelete: null, deleteUserInProgress: false });
    }
  }

  async toggleRole(user: UserListModel.userT, roleId: number) {
    this.setState({ roleUpdatingForUser: user.id });
    if (user && user.roles && !!user.roles.find(r => r.id === roleId)) {
      await this.props.removeRole(user, roleId);
    } else {
      await this.props.addRole(user, roleId);
    }
    this.setState({ roleUpdatingForUser: null });
  }

  async resendEmail(user: UserListModel.userT) {
    const { currentUser } = this.props;

    if (user.roles) {
      const roles = user.roles.map(role => role.id);

      const { organisationId: orgId, organisationName: orgName } = currentUser;

      try {
        await inviteUser(orgName, user.email, roles, orgId);

        errorToast({
          message: 'Email sent',
          intent: Intent.SUCCESS
        });
      } catch (e) {
        errorToast({
          message: 'Failed to send the email.',
          intent: Intent.DANGER
        });
      }
    }
  }

  render() {
    const { users, loading } = this.props;
    const {
      userToDelete,
      deleteUserInProgress,
      roleUpdatingForUser
    } = this.state;

    if (loading) {
      return <NoAreasPanel message={<Spinner size={22} />} />;
    }

    if (!users || users.length === 0) {
      return <NoAreasPanel message="No users" />;
    }

    return (
      <Fragment>
        <Table>
          <thead>
            <TableHeadings>
              <FirstCellHeadingColumn>Invited Users</FirstCellHeadingColumn>
              <TableHeadingColumn>Roles</TableHeadingColumn>
              <TableHeadingColumn>Actions</TableHeadingColumn>
            </TableHeadings>
          </thead>
          <tbody>
            {users.map(user => {
              return (
                <TableRow key={`invited-users-key-${user.id}`}>
                  <StyledTableRowItem>{user.email}</StyledTableRowItem>
                  <StyledTableRowItem>
                    <RolePicker
                      selectedRoles={user.roles}
                      onClick={roleId => this.toggleRole(user, roleId)}
                      updating={roleUpdatingForUser === user.id}
                    />
                  </StyledTableRowItem>

                  <StyledTableRowItem>
                    <StyledButton
                      text="Remove user"
                      minimal
                      outlined
                      onClick={() => this.setUserToDelete(user)}
                    />
                    <StyledButton
                      text="Resend invitation email"
                      minimal
                      outlined
                      onClick={() => this.resendEmail(user)}
                    />
                  </StyledTableRowItem>
                </TableRow>
              );
            })}
          </tbody>
        </Table>

        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete user"
          icon={deleteUserInProgress ? <AlertSpinner size="40" /> : 'trash'}
          intent={Intent.DANGER}
          isOpen={!!userToDelete}
          onCancel={() => {
            this.setUserToDelete(null);
          }}
          onConfirm={this.handleDeleteConfirm.bind(this)}
        >
          <p>
            Are you sure you want to delete {userToDelete && userToDelete.email}
            ?
          </p>
        </Alert>
      </Fragment>
    );
  }
}

export default InvitedUsersTable;
