/* eslint max-classes-per-file: ["error", 3] */
const allOrg = 'all org';

/**
 * Extends array functionality with async some
 */
class AsyncArray extends Array {
  async asyncSome(predicate) {
    for (const e of this) {
      if (await predicate(e)) return true;
    }
    return false;
  }
}

/**
 * Extends asyncArray functionality with set helpers
 */
class SetArray extends AsyncArray {
  isSubSetOf(testAgainstArray) {
    if (!Array.isArray(testAgainstArray)) throw new Error('SetArray.isSubSetOf must be of type array');

    if (this.length === 0 || testAgainstArray.length === 0) return false;

    return this.every((val) => testAgainstArray.includes(val));
  }

  intersectionWith(testAgainstArray) {
    if (!Array.isArray(testAgainstArray)) throw new Error('SetArray.intersectionWith must be of type array');

    return this.filter((x) => testAgainstArray.includes(x));
  }
}

/**
 * Extends setArray functionality with capability helpers
 */
class CapArray extends SetArray {
  allOrgs() {
    return this.filter((elem) => elem.visibility === allOrg);
  }
}

module.exports = {
  CapArray,
  SetArray,
};
