import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PreInitLoading from '../pre-init-loading';

const LogoutPage = ({ logout }) => {
  const theme = useContext(ThemeContext);

  if (theme && theme.overrides && theme.overrides.logout) {
    logout({ returnTo: theme.overrides.logout });
  } else {
    logout({});
  }

  return <PreInitLoading />;
};

export default LogoutPage;
