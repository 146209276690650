// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import * as ReportModel from '../models/report';

export type ReducerType = ReportModel.t[];

const INITIAL_STATE = [];

export default function reportsReducer(
  state: ReducerType = INITIAL_STATE,
  { type, payload }: FluxStandardAction<string, *>
): ReducerType {
  switch (type) {
    case ACTIONS.FETCH_REPORTS_SUCCESS:
      return payload.dashboardReports || null;

    case ACTIONS.LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}
