import { useContext } from 'react';
import { axisTimeFormatter } from '../../../utils/graphHelpers';
import { ThemeContext } from 'styled-components';

const CustomizedXAxisTick = ({ x, y, payload, aggregation, }) => {
  const theme = useContext(ThemeContext);
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={15}
        textAnchor="start"
        fill={theme ? theme.properties['--widget-heading-text'] : null}
        transform="rotate(35)"
      >
        {axisTimeFormatter(payload.value, aggregation)}
      </text>
    </g>
  );
};

export default CustomizedXAxisTick;
