import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Card,
} from '@blueprintjs/core';

import * as Selectors from '../../../selectors';
import LocationFilterButtons from '../../location-filter-buttons';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LocationCard = styled(Card)`
  margin-top: 1.5em;
`;
const NoMarginH5 = styled.h5`
  margin-top: 0;
`;
const StyledSwitch = styled(Switch)`
  margin-left: 3em;
  margin-bottom: 0;
`;

const ReportSelector = ({
  reports,
  locations,
  selectedReport,
  setSelectedReport,
  restrictToLocations,
  setRestrictToLocations,
}) => {
  const [hideLocationMenu, setHideLocationMenu] = useState(true);

  useEffect(() => {
    if (
      restrictToLocations &&
      Array.isArray(restrictToLocations) &&
      restrictToLocations.length > 0
    ) {
      setHideLocationMenu(false);
    }
  }, [restrictToLocations]);

  const col1Icon = (report) =>
    isEmpty(selectedReport)
      ? undefined
      : selectedReport && selectedReport.id === report.id
      ? { icon: 'tick' }
      : { icon: 'blank' };

  const toggleSwitch = () => {
    const newValue = !hideLocationMenu;
    setHideLocationMenu(newValue);
    setRestrictToLocations([]);
  };

  return (
    <Fragment>
      <Row>
        <Popover>
          <Button rightIcon="caret-down" text={selectedReport && selectedReport.name ? selectedReport.name : '...'} />
          <Menu>
            {reports.map((report) => (
              <MenuItem
                key={report.id}
                text={report.name}
                onClick={(e) => setSelectedReport(report)}
                {...col1Icon(report)}
              />
            ))}
          </Menu>
        </Popover>
        <StyledSwitch
          checked={hideLocationMenu}
          onChange={toggleSwitch}
          label="Include all locations in report"
        />
      </Row>
      {!hideLocationMenu && (
        <LocationCard elevation={1}>
          <NoMarginH5>Restrict to these locations</NoMarginH5>
          <LocationFilterButtons
            locations={locations}
            filteredLocations={restrictToLocations || []}
            updateFilteredLocations={setRestrictToLocations}
          />
        </LocationCard>
      )}
    </Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    locations: Selectors.getLocations(state),
  };
}

export default connect(mapStateToProps)(ReportSelector);
