import styled from 'styled-components';
import { breakpoints } from '../../styles/variables';
import { Tag } from '@blueprintjs/core';
import { getAgeLabel } from '../../utils/prettyLabel';
import { firstLetterUppercase } from '../../utils/firstLetterUppercase';

const Grid = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  ${({ columns }) => `grid-template-columns: ${'1fr '.repeat(columns)};`}

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    display: block;
  }
`;
const Cell = styled.div`
  border: 0 var(--panel-border);
  margin: 1rem auto;
  border-radius: var(--widget-border-radius);
  padding: 0.5rem;
  background: var(--panel-background);
  box-shadow: var(--widget-box-shadow);
`;
const TagRow = styled.div`
  padding: 0.5rem 0;
`;

const DisplayTags = ({ age, gender, role }) => {
  return (
    <TagRow>
      {age && age !== '' && <Tag>{getAgeLabel(age)}</Tag>}
      {gender && gender !== '' && <Tag>{firstLetterUppercase(gender)}</Tag>}
      {role && role !== '' && <Tag>{firstLetterUppercase(role)}</Tag>}
      {(!age || age === '') &&
        (!gender || gender === '') &&
        (!role || role === '') && <Tag>All</Tag>}
    </TagRow>
  );
};

const HeatmapImageGrid = ({ heatmapItems }) => {
  const numCols =
    heatmapItems && heatmapItems.length && heatmapItems.length > 1 ? 2 : 1;
  return (
    <Grid columns={numCols}>
      {heatmapItems.map(({ url, age, gender, role }, i) => (
        <Cell key={i}>
          <DisplayTags age={age} gender={gender} role={role} />
          <img src={url} width="100%" loading="lazy" alt={'Heatmap'} />
        </Cell>
      ))}
    </Grid>
  );
};

export default HeatmapImageGrid;
