// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import HeadcountPlanner from '../components/pages/headcount-planner';
import { IS_DEMO_USER } from '../constants/features';

function mapStateToProps(state) {
  return {
    locations: Selectors.getLocations(state),
    availableMetrics: Selectors.getAvailableMetrics(state),
    isDemoUser: Selectors.userHasFeature(IS_DEMO_USER, state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadcountPlanner);
