// @flow

import { useState, useEffect, useContext } from 'react';
import {
  Popover,
  Card,
  Elevation,
  PopoverInteractionKind,
  Position,
  Icon,
  Tag,
} from '@blueprintjs/core';
import styled, { ThemeContext } from 'styled-components';
import * as RecordingModel from '../../../../models/recording';
import { liveOccupancy as liveOccupancyAPI } from '../../../../services/live-api';
import {
  getCapacity,
  getStatusColour,
  // getLastUpdatedTimestamp,
  getUrlQueryFromContexts,
  generateTaxonomyOccupancyItem,
  HISTORY_LENGTH,
  UPDATE_LIVE_TIMEOUT,
  get3QValue,
  generateIncrement,
} from '../../../../utils/live-occupancy';
import { prettyTaxonomyLabel } from '../../../../utils/taxonomyHelpers';
import { ColouredH3 } from '../common';
import ContextsNotFoundRow from './contexts-not-found-row';
import ImageDisplayerMenu from '../../../image-displayer-menu';
// import SecondsCountUp from '../../../seconds-count-up';
import type { locationConfigT } from '../location-occupancy-row';
import { useInterval } from '../../../widgets/common/hooks';
import { getRandomInt } from '../../../../utils/generalHelpers';

const ZoneNameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const NoPaddingTd = styled.td`
  padding: 1rem 0 !important;
`;
const StyledIcon = styled(Icon)`
  margin-left: 0.5rem;
  cursor: pointer;
`;
const LiveFigure = styled.div`
  margin: 0.5rem 0;
  font-size: 2em;
  font-weight: bold;
  color: #19212f;
  text-transform: none;
  letter-spacing: 0;

  opacity: ${(props) => (props.minimal ? '0.6' : '1')};
`;

type Props = {
  contexts: RecordingModel.areaContextsT,
  taxonomyName: string,
  setExpandedPreviewImages: (images: string[]) => void,
  excludeStaff: boolean,
  capacity?: number,
  isDemoUser: boolean,
};

const ContextOccupancyRow = ({
  contexts,
  taxonomyName,
  setExpandedPreviewImages,
  excludeStaff,
  capacity,
  isDemoUser,
}: Props) => {
  const theme = useContext(ThemeContext);
  const [occupancyData, setOccupancyData] = useState<locationConfigT>({});

  const [totalOccupancyHistory, setTotalOccupancyHistory] = useState([]);
  const [staffOccupancyHistory, setStaffOccupancyHistory] = useState([]);
  const [customerOccupancyHistory, setCustomerOccupancyHistory] = useState([]);
  const [demoCurrentOccupancy, setDemoCurrentOccupancy] = useState(0);

  const [occupancy, setOccupancy] = useState(-1);
  const [occupancyPercentage, setOccupancyPercentage] = useState(-1);

  // Clear the history after excluding/including staff
  useEffect(() => {
    setTotalOccupancyHistory([]);
    setStaffOccupancyHistory([]);
    setCustomerOccupancyHistory([]);
    updateValue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeStaff]);

  useEffect(() => {
    if (isDemoUser) {
      setDemoCurrentOccupancy(getRandomInt(0, capacity || 10));
    }
  }, [capacity, isDemoUser]);

  useEffect(() => {
    setOccupancy(
      excludeStaff
        ? get3QValue(customerOccupancyHistory)
        : get3QValue(totalOccupancyHistory)
    );
    setOccupancyPercentage(
      (excludeStaff
        ? Math.round((get3QValue(customerOccupancyHistory) /
            parseInt(getCapacity(occupancyData))) *
          100)
        : Math.round((get3QValue(totalOccupancyHistory) /
            parseInt(getCapacity(occupancyData))) *
          100)) || -1
    );
  }, [
    customerOccupancyHistory,
    excludeStaff,
    occupancyData,
    totalOccupancyHistory,
  ]);

  const updateValue = async () => {
    if (!isDemoUser) {
      const areasQueryUrlFormat = getUrlQueryFromContexts(contexts);
      if (areasQueryUrlFormat) {
        const res = await liveOccupancyAPI.fetchLiveOccupancyByArea(
          areasQueryUrlFormat
        );
        if (res) {
          const newData = generateTaxonomyOccupancyItem(taxonomyName, res);
          // overwrite the capacity with that provided in the prop
          newData.capacity = capacity;
          setOccupancyData(newData);
        }
      }
    } else if (isDemoUser) {
      const occupancy =
        demoCurrentOccupancy +
        generateIncrement(occupancyData);

      const newData = {
        id: '',
        capacity,
        currentOccupancy: occupancy,
        currentOccupancyStaff: Math.floor(occupancy * 0.2),
        currentOccupancyCustomers: Math.ceil(occupancy * 0.8),
      };
      setOccupancyData(newData);
    }
  };

  useInterval(updateValue, isDemoUser ? 2000 : UPDATE_LIVE_TIMEOUT);
  useEffect(() => {
    updateValue()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const historyLength = isDemoUser ? 5 : HISTORY_LENGTH;
    const getNewArray = (arr, item) =>
      [...arr, item].slice(-1 * historyLength);

    if (occupancyData) {
      if (typeof occupancyData.currentOccupancy === 'number')
        setTotalOccupancyHistory(
          getNewArray(totalOccupancyHistory, occupancyData.currentOccupancy)
        );
      if (typeof occupancyData.currentOccupancyStaff === 'number')
        setStaffOccupancyHistory(
          getNewArray(
            staffOccupancyHistory,
            occupancyData.currentOccupancyStaff
          )
        );
      if (typeof occupancyData.currentOccupancyCustomers === 'number')
        setCustomerOccupancyHistory(
          getNewArray(
            customerOccupancyHistory,
            occupancyData.currentOccupancyCustomers
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupancyData]);

  if (!contexts) return <ContextsNotFoundRow taxonomyName={taxonomyName} />;

  const imageUrls = RecordingModel.getImageUrlsFromAreaContexts(contexts);

  return (
    <tr>
      <NoPaddingTd>
        <ZoneNameDiv>
          <Tag large>{prettyTaxonomyLabel(taxonomyName)}</Tag>
          {!isDemoUser && <Popover
            className="bp3-popover-wrapper-custom"
            position={Position.TOP}
            interactionKind={PopoverInteractionKind.HOVER}
            transitionDuration={0}
            hoverCloseDelay={150}
            hoverOpenDelay={0}
            boundary="viewport"
            modifiers={{
              preventOverflow: {
                enabled: true,
              },
              hide: { enabled: false },
            }}
            content={
              <ImageDisplayerMenu
                previews={imageUrls}
                setMetricPreviewImagesForModal={setExpandedPreviewImages}
              />
            }
          >
            <StyledIcon iconSize={20} icon="camera" color="#5c7080" />
          </Popover>}
        </ZoneNameDiv>
      </NoPaddingTd>

      <td>
        <Card elevation={Elevation.TWO}>
          <LiveFigure minimal={!occupancyData.capacity}>
            {getCapacity(occupancyData) || '-'}
          </LiveFigure>
        </Card>
      </td>

      <td>
        <Card elevation={Elevation.TWO}>
          <LiveFigure>{occupancy >= 0 ? `${occupancy}` : '-'}</LiveFigure>
        </Card>
      </td>

      <td>
        <Card elevation={Elevation.TWO}>
          <LiveFigure minimal={!occupancyData.capacity}>
            <ColouredH3
              color={getStatusColour(
                { location: occupancyData, excludeStaff },
                theme
              )}
            >
              {occupancyPercentage >= 0 ? `${occupancyPercentage}%` : '-'}
            </ColouredH3>
          </LiveFigure>
        </Card>
      </td>

      {/*<td>
        <Card elevation={Elevation.TWO}>
          <LiveFigure>{getLastUpdatedTimestamp(occupancyData)}</LiveFigure>
          <LiveFigure>
            <SecondsCountUp lastTimestamp={occupancyData.latestTimestamp} />
          </LiveFigure>
        </Card>
      </td>*/}
    </tr>
  );
};

export default ContextOccupancyRow;
