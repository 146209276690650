import { ButtonGroup, Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;

const NavButton = styled(Button)`
  margin: 0 1em;

  &.hidden {
    visibility: hidden;
  }
`;

const PerPageSelect = styled(Select)`
  margin-inline-start: 2em;
`;

const PerPageSpan = styled.span`
  margin-inline-end: 1em;
`;

const Pagination = ({
  pages: rawPages,
  setPage,
  currentPage,
  setItemsPerPage,
  itemsPerPage,
}) => {
  const pages = Math.ceil(rawPages);
  const pageSizes = [
    {
      size: 10,
      label: '10',
    },
    {
      size: 20,
      label: '20',
    },
    {
      size: 50,
      label: '50',
    },
    {
      size: -1,
      label: 'All',
    },
  ];

  let links = [];
  const click = (page) => {
    setPage(page);
  };

  if (pages > 1) {
    for (let i = 0; i < pages; i++) {
      links.push(
        <Button
          key={`page_${i}`}
          onClick={() => click(i)}
          intent={i === currentPage ? 'primary' : null}
        >
          {i + 1}
        </Button>
      );
    }
  }

  return (
    <>
      {(links.length > 0 || typeof setItemsPerPage === 'function') && (
        <Wrapper>
          {pages > 1 && (
            <div>
              <NavButton
                icon="chevron-left"
                className={currentPage <= 0 ? 'hidden' : null}
                onClick={() => click(currentPage - 1)}
              />
              <ButtonGroup>{links}</ButtonGroup>
              <NavButton
                icon="chevron-right"
                className={currentPage >= pages - 1 ? 'hidden' : null}
                onClick={() => click(currentPage + 1)}
              />
            </div>
          )}
          {typeof setItemsPerPage === 'function' && (itemsPerPage > pageSizes[0].size || pages > 1) && (
            <PerPageSelect
              items={pageSizes}
              filterable={false}
              onItemSelect={(pageSize) => setItemsPerPage(pageSize.size)}
              itemRenderer={(pageSize, { handleClick }) => (
                <MenuItem
                  key={pageSize.size}
                  text={pageSize.label}
                  active={pageSize.size === itemsPerPage}
                  onClick={handleClick}
                />
              )}
            >
              <PerPageSpan>Items per page:</PerPageSpan>
              <Button
                text={(itemsPerPage > 0 ? itemsPerPage : 'All').toString()}
                rightIcon="caret-down"
              />
            </PerPageSelect>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Pagination;
