// @flow

import { Fragment, Component } from 'react';
import styled from 'styled-components';
import { NotFoundText } from '../panel';
import TableRowItem from './table-row-item';
import LocationThumbnailTableMenu from '../locations-thumbnails-table-menu';

import * as PageSettingsModel from '../../models/page-settings';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as LocationModel from '../../models/location';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as QueryApiRequestModel from '../../models/query-api-request';
import type { aggregationEnum } from '../../constants/graph-options';

const DefaultText = styled(NotFoundText)`
  padding: 0;
  margin: 0;
`;

type Props = {
  metrics: PageSettingsModel.metricItemT[],
  availableMetrics: AvailableMetricsModel.t,
  locations: LocationModel.t[],
  updateMetricsLocations(string[], metricId: ?number): void,
  handleExcludeStaff(index: number): void,
  handleDeleteMetric(index: number): void,
  showStaffFilters: boolean,
  queryResponseList: QueryResponseListModel.t,
  aggregation: aggregationEnum,
  period: QueryApiRequestModel.periodT,
  loading?: boolean
};

type State = {
  thumbnailPreviewsForMetrics: string[]
};

class MultipleMetricsTable extends Component<Props, State> {
  state = {
    thumbnailPreviewsForMetrics: []
  };

  setMetricPreviewImagesForModal(newPreviews: string[]) {
    this.setState({
      thumbnailPreviewsForMetrics: newPreviews
    });
  }

  render() {
    const {
      metrics,
      availableMetrics,
      locations,
      updateMetricsLocations,
      handleExcludeStaff,
      handleDeleteMetric,
      showStaffFilters,
      queryResponseList,
      period,
      aggregation,
      loading
    } = this.props;

    const { thumbnailPreviewsForMetrics } = this.state;

    return (
      <div>
        {metrics && metrics.length > 0 ? (
          <Fragment>
            <LocationThumbnailTableMenu
              setMetricPreviewImagesForModal={this.setMetricPreviewImagesForModal.bind(
                this
              )}
              previewImages={thumbnailPreviewsForMetrics}
            />
            {metrics.map((metricItemConfig, index) => {
              return (
                <TableRowItem
                  key={index}
                  index={index}
                  metricItemConfig={metricItemConfig}
                  availableMetrics={availableMetrics}
                  updateMetricsLocations={updateMetricsLocations}
                  handleDeleteMetric={handleDeleteMetric}
                  handleExcludeStaff={handleExcludeStaff}
                  showStaffFilters={showStaffFilters}
                  locations={locations}
                  queryResponseList={queryResponseList}
                  period={period}
                  aggregation={aggregation}
                  loading={loading}
                  setMetricPreviewImagesForModal={this.setMetricPreviewImagesForModal.bind(
                    this
                  )}
                />
              );
            })}
          </Fragment>
        ) : (
          <DefaultText>Add some metrics to start graphing data</DefaultText>
        )}
      </div>
    );
  }
}

export default MultipleMetricsTable;
