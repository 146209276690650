import { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Select } from '@blueprintjs/select';
import {
  Button,
  MenuItem,
  Alignment,
  Switch,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { Panel, PanelHeader, PanelBody } from '../panel';
import { breakpoints } from '../../styles/variables';
import DateSelector from './date-selector';
import { useLocation } from 'react-router-dom';
import Popup from './popup';
import { sendMessage } from '../../services/sql-api';
import { fetchThumbnailsForRecordings } from '../../actions/thumbnails';
import { fetchLineContextsForRecordings } from '../../actions/line-contexts';
import { fetchAreaContextsForRecordings } from '../../actions/area-contexts';
import { LOCATION_DWELL } from '../../constants/area-contexts';
import {
  LOCATION_ENTRY,
  LOCATION_PASS_BY,
} from '../../constants/line-contexts';

const Wrapper = styled.div`
  flex: 1;
`;

const RecordingGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem;

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const RecordingPanelHeader = styled(PanelHeader)`
  box-shadow: var(--widget-box-shadow);
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 285px;
  width: 100%;
`;

const RecordingPreview = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: var(--widget-border-radius);
  background: var(--panel-background);
  box-shadow: var(--widget-box-shadow);
`;

const RecordingPreviewSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  cursor: pointer;
  width: 100%;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    cursor: unset;
  }
`;

const DateLabel = styled.span`
  margin-inline-start: 1.2rem;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    display: none;
  }
`;

const StyledPanel = styled(Panel)`
  margin: 1rem 0;
  box-shadow: none;
  background: transparent;

  &:first-child {
    margin: 0;
  }
`;

const StyledPanelBody = styled(PanelBody)`
  padding: 0;
  margin: 0;
  background: transparent;

  &:only-child {
    padding: 0;
  }
`;

const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  box-shadow: var(--widget-box-shadow);

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    justify-content: center;
  }
`;

const StyledSwitch = styled(Switch)`
  margin: 0;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    display: none;
  }
`;

const SelectionGroup = styled.div`
  flex-shrink: 0;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    display: flex;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;

const TaxonomyTag = styled(Tag)`
  display: inline-block;
`;

const TaxonomyBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const CameraName = styled.p`
  font-size: 0.8rem;
  color: var(--gray);
`;

const sortByName = (a, b) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
};

const formatAreaContext = (areaContext) =>
  areaContext === LOCATION_DWELL ? 'Dwell' : areaContext;

const formatLineContext = (lineContext) => {
  if (lineContext === LOCATION_ENTRY) {
    return 'Entry';
  } else if (lineContext === LOCATION_PASS_BY) {
    return 'Pass By';
  }

  return lineContext;
};

const CameraPreviews = ({
  recordings,
  locations,
  isDemoUser,
  compare,
  setCompare,
}) => {
  const browserLocation = useLocation();

  const [selectedLocation, setSelectedLocation] = useState(locations[0]);
  const [selectedDate, setSelectedDate] = useState('');
  const [activeRecordings, setActiveRecordings] = useState([]);
  const [dates, setDates] = useState([]);
  const [popupSelectedDate, setPopupSelectedDate] = useState('');
  const [selectedRecording, setSelectedRecording] = useState(null);

  useEffect(() => setPopupSelectedDate(selectedDate), [selectedDate]);

  useEffect(() => {
    if (browserLocation && browserLocation.search) {
      const location = new URLSearchParams(browserLocation.search).get(
        'location',
      );
      if (location && location !== '') {
        setSelectedLocation(locations.find((loc) => loc.id === location));
      }
    }
  }, [browserLocation, locations]);

  // Set the active recordings based on location
  useEffect(() => {
    const sortedRecordings = recordings.sort(sortByName);
    setActiveRecordings(
      sortedRecordings.filter(
        (recording) =>
          recording.location && recording.location.id === selectedLocation.id,
      ),
    );
  }, [selectedLocation, recordings]);

  // Fetch thumbnails for the active recordings
  const fetchThumbnails = async (recordings) => {
    const [thumbnailResults, lineContextResults, areaContextResults] =
      await Promise.all([
        fetchThumbnailsForRecordings(recordings.map((rec) => rec.id)),
        fetchLineContextsForRecordings(recordings.map((rec) => rec.id)),
        fetchAreaContextsForRecordings(recordings.map((rec) => rec.id)),
      ]);

    if (thumbnailResults && thumbnailResults.size > 0) {
      setActiveRecordings(
        activeRecordings.map((rec) => ({
          ...rec,
          thumbnails: thumbnailResults.get(rec.id).reverse(),
          lineContexts: lineContextResults.get(rec.id)
            ? Array.from(
                new Set(
                  lineContextResults
                    .get(rec.id)
                    .map((lc) => (lc.taxonomy ? lc.taxonomy : lc.line_type)),
                ),
              )
            : [],
          areaContexts: areaContextResults.get(rec.id)
            ? Array.from(
                new Set(
                  areaContextResults
                    .get(rec.id)
                    .map((ac) => (ac.taxonomy ? ac.taxonomy : ac.area_type)),
                ),
              )
            : [],
        })),
      );
    }
  };

  // Assume all recordings for a location have the same dates
  useEffect(() => {
    if (activeRecordings && activeRecordings[0]) {
      if (!Array.isArray(activeRecordings[0].thumbnails)) {
        fetchThumbnails(activeRecordings);
      } else {
        const timezone = activeRecordings[0].timezone;
        const recordingDates = activeRecordings[0].thumbnails.map((t) =>
          moment(t.local_time).format('L'),
        );
        setDates(recordingDates);
        setSelectedDate(recordingDates[recordingDates.length - 1]);
      }
    }
  }, [activeRecordings]);

  const recordingsByLocation = {};

  recordings.forEach((rec) => {
    const locId = rec.location.id;
    if (!recordingsByLocation[locId]) {
      recordingsByLocation[locId] = [rec];
    } else {
      recordingsByLocation[locId].push(rec);
    }
  });

  const filterPredicate = (query, item) => {
    if (item && item.name) {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    }
  };

  return (
    <>
      <Wrapper>
        <StyledPanel>
          <StyledPanelHeader>
            <SelectionGroup>
              <Select
                items={locations}
                itemPredicate={filterPredicate}
                itemRenderer={(location, { handleClick }) => (
                  <MenuItem
                    key={location.name}
                    text={location.name}
                    active={
                      selectedLocation
                        ? location.name === selectedLocation.name
                        : false
                    }
                    onClick={handleClick}
                  />
                )}
                onItemSelect={(location) => setSelectedLocation(location)}
              >
                <Button
                  text={
                    selectedLocation
                      ? selectedLocation.name
                      : 'No locations available'
                  }
                  intent="primary"
                  rightIcon="caret-down"
                />
              </Select>
              <DateLabel>{selectedDate}</DateLabel>
            </SelectionGroup>
            {typeof compare !== 'undefined' && setCompare && (
              <StyledSwitch
                checked={compare}
                onChange={() => setCompare(!compare)}
                alignIndicator={Alignment.RIGHT}
                label="Compare"
              />
            )}
          </StyledPanelHeader>
        </StyledPanel>

        {dates.length > 1 && (
          <StyledPanel>
            <RecordingPanelHeader>
              <DateSelector
                dates={dates}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </RecordingPanelHeader>
          </StyledPanel>
        )}
        <StyledPanel>
          <StyledPanelBody>
            {selectedDate &&
            activeRecordings &&
            activeRecordings.length > 0 &&
            Array.isArray(activeRecordings[0].thumbnails) ? (
              <RecordingGrid>
                {activeRecordings.map((recording, i) => {
                  const thumb = recording.thumbnails.find(
                    (thumb) =>
                      moment(thumb.local_time).format('L') === selectedDate,
                  );
                  const src = thumb ? thumb.url : '';
                  return (
                    src && (
                      <RecordingPreview key={i}>
                        <RecordingPreviewSection>
                          <CameraName>{recording.name}</CameraName>

                          <Image
                            src={src}
                            loading="lazy"
                            onClick={() => {
                              if (
                                window.outerWidth >
                                parseInt(breakpoints.TABLET_SIZE)
                              ) {
                                setPopupSelectedDate(selectedDate);
                                setSelectedRecording(recording);
                              }
                            }}
                          />
                        </RecordingPreviewSection>
                        {!isDemoUser ? (
                          <RecordingPreviewSection>
                            {recording.lineContexts &&
                            recording.lineContexts.length > 0 ? (
                              <TaxonomyBlock>
                                <span>Lines:</span>
                                {recording.lineContexts.map((lc, i) => (
                                  <TaxonomyTag key={i} minimal>
                                    {formatLineContext(lc)}
                                  </TaxonomyTag>
                                ))}
                              </TaxonomyBlock>
                            ) : null}
                            {recording.areaContexts &&
                            recording.areaContexts.length > 0 ? (
                              <TaxonomyBlock>
                                <span>Areas:</span>
                                {recording.areaContexts.map((ac, i) => (
                                  <TaxonomyTag key={i} minimal>
                                    {formatAreaContext(ac)}
                                  </TaxonomyTag>
                                ))}
                              </TaxonomyBlock>
                            ) : null}{' '}
                          </RecordingPreviewSection>
                        ) : null}
                      </RecordingPreview>
                    )
                  );
                })}
              </RecordingGrid>
            ) : (
              <SpinnerContainer>
                <Spinner size={25} />
              </SpinnerContainer>
            )}
          </StyledPanelBody>
        </StyledPanel>
      </Wrapper>

      <Popup
        dates={dates}
        popupSelectedDate={popupSelectedDate}
        setPopupSelectedDate={setPopupSelectedDate}
        selectedRecording={selectedRecording}
        setSelectedRecording={setSelectedRecording}
      />
    </>
  );
};

export default CameraPreviews;
