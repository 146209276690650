export const SEND_TYPE_ALL = 'email - all org users';
export const SEND_TYPE_ALL_LABEL = 'All current users';
export const SEND_TYPE_SPECIFIC = 'email - specific users';
export const SEND_TYPE_SPECIFIC_LABEL = 'Specific email addresses';
export const CADENCE_HOURLY = 'hourly';
export const CADENCE_DAILY = 'daily';
export const CADENCE_WEEKLY = 'weekly';
export const CADENCE_MONTHLY = 'monthly';
export const SELECTED_REPORT_DEFAULT = '';
export const SELECTED_DISTRIBUTION_DEFAULT = {
  sendType: SEND_TYPE_ALL,
  sendTypeLabel: SEND_TYPE_ALL_LABEL,
};
export const SELECTED_SCHEDULE_DEFAULT = {};
