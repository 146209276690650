// @flow

import * as ACTIONS from '../constants/actions';
import { SUMMARY, EXPLORER, USER_SETTINGS } from '../constants/page-settings';

import * as ActiveMetricsModel from '../models/active-metrics';
import * as PageSettingsModel from '../models/page-settings';
import type { FluxStandardAction } from 'flux-standard-action';

export type ReducerType = PageSettingsModel.t;

const INITIAL_STATE = {
  [SUMMARY]: PageSettingsModel.defaults[SUMMARY],
  [EXPLORER]: PageSettingsModel.defaults[EXPLORER],
  [USER_SETTINGS]: PageSettingsModel.defaults[USER_SETTINGS]
};

export default function pageSettingsReducer(
  // $FlowFixMe -> property `end` is missing in  object literal [1] but exists in  `periodT` [2] in property `explorer.period.selectedDates`.
  state: ReducerType = INITIAL_STATE,
  { type, payload }: FluxStandardAction<string, *>
): ReducerType {
  switch (type) {
    case ACTIONS.EDIT_PAGE_SETTINGS:
      return {
        ...state,
        [payload.pageName]: payload.settings
      };

    case ACTIONS.LOGOUT:
      return INITIAL_STATE;

    case ACTIONS.EDIT_SUMMARY_PAGE_ACTIVE_METRICS: {
      const { subMenuKey, metricElementKey, taxonomy, filter } = payload;
      const { activeMetrics } = state[SUMMARY];
      const newActiveMetrics = ActiveMetricsModel.getUpdatedActiveMetrics(
        activeMetrics,
        {
          subMenuKey,
          metricElementKey,
          changeToTaxonomy: taxonomy,
          changeToFilter: filter
        }
      );

      const newState = {
        ...state,
        [SUMMARY]: {
          ...state[SUMMARY],
          activeMetrics: newActiveMetrics
        }
      };

      return newState;
    }

    case ACTIONS.CLEAR_SUMMARY_PAGE_ACTIVE_METRICS: {
      return {
        ...state,
        [SUMMARY]: {
          ...state[SUMMARY],
          activeMetrics: {
            movement: {
              metrics: [],
            },
          },
        },
      };
    }

    default:
      return state;
  }
}
