// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import {
  Popover,
  Button,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { CHART_TYPE } from '../../constants/graph-options';
import type { chartTypeEnum } from '../../constants/graph-options';
import type { disabledChartTypesItemT } from '../../utils/pickerHelpers';
import ButtonIconColorer from '../button-icon-colorer';

const { LINE, REL_BAR, PIE, AREA } = CHART_TYPE;
const labels = {
  [LINE]: 'Line',
  [AREA]: 'Stacked Line',
  [REL_BAR]: '% Bar',
  [PIE]: 'Pie'
};

const icons = {
  [LINE]: 'timeline-line-chart',
  [AREA]: 'timeline-area-chart',
  [REL_BAR]: 'timeline-bar-chart',
  [PIE]: 'pie-chart'
};

const StyledTooltip = styled(Tooltip2)`
  width: 100%;
`;

type Props = {
  selected: string,
  onChange(value: chartTypeEnum): any,
  disabledChartTypes: disabledChartTypesItemT[]
};

const ChartTypeItem = ({ chartType, disabledChartTypes, onChange }) => {
  const onClick = value => () => onChange(value);
  const disabledChartTypeConfig = disabledChartTypes.find(
    item => item.chartType === chartType
  );
  const isItemDisabled = !!disabledChartTypeConfig;
  const tooltipLabel =
    disabledChartTypeConfig && disabledChartTypeConfig.disabledMessage
      ? disabledChartTypeConfig.disabledMessage
      : '';

  return (
    <Fragment>
      {isItemDisabled ? (
        <StyledTooltip content={tooltipLabel}>
          <MenuItem
            text={labels[chartType]}
            onClick={onClick(chartType)}
            icon={icons[chartType]}
            disabled={true}
          />
        </StyledTooltip>
      ) : (
        <MenuItem
          text={labels[chartType]}
          onClick={onClick(chartType)}
          icon={icons[chartType]}
        />
      )}
    </Fragment>
  );
};

const ChartTypePicker = ({
  selected,
  onChange,
  disabledChartTypes = []
}: Props) => {
  const commonChartTypeItemProps = {
    disabledChartTypes,
    onChange
  };

  const menu = (
    <Menu>
      <ChartTypeItem {...commonChartTypeItemProps} chartType={AREA} />
      <ChartTypeItem {...commonChartTypeItemProps} chartType={LINE} />
      <ChartTypeItem {...commonChartTypeItemProps} chartType={REL_BAR} />
      <ChartTypeItem {...commonChartTypeItemProps} chartType={PIE} />
    </Menu>
  );

  return (
    <Popover content={menu} position={Position.BOTTOM_RIGHT}>
      <ButtonIconColorer>
        <Button
          rightIcon="caret-down"
          icon={icons[selected]}
          text={labels[selected]}
        />
      </ButtonIconColorer>
    </Popover>
  );
};

export default ChartTypePicker;
