const {
  prettyLongNumber,
  prettyTime,
  prettyNumber,
  prettyPercentage,
  prettyCurrency,
  prettyRate
} = require("./number-formats");

module.exports = {
  prettyLongNumber,
  prettyTime,
  prettyNumber,
  prettyPercentage,
  prettyCurrency,
  prettyRate
};
