import moment from 'moment';
import { useEffect, useState } from 'react';
import { buildPayload, getQueryPayloadType } from '../../services/query-api';
import { normaliseData, smoothData, padData, assignHours } from './helpers';
import styled from 'styled-components';
import WidgetWrapper from './widget-wrapper';
import { apiEndpointByMetricKey } from '../../constants/api-endpoints';
import { Panel, PanelHeader } from '../panel';
import * as PERIOD_PRESETS from '../../constants/period-presets';
import { defaultPeriodPresetConfig } from '../date-and-comparison-date-selector/dates-presets-config';
import * as PeriodModel from '../../models/period';
import Options from './options';
import AdvancedOptions from './advanced-options';
import { ErrorWrapper } from '../widgets-ui/common/widget-wrapper';
import { Icon, Tag, Intent } from '@blueprintjs/core';
import { fetchSQLQuery } from '../../services/sql-api';

const Wrapper = styled.div`
  flex: 1;
  max-width: var(--widget-max-width);
  width: 100%;
  margin: 0 auto;
`;

const StyledPanelHeader = styled(PanelHeader)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledPanel = styled(Panel)`
  margin-bottom: 0.5rem;
  box-shadow: var(--widget-box-shadow);
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 1rem;
`;

const Note = styled(Tag)`
  margin-top: 1rem;
  align-self: flex-start;
  padding: 0.5rem;
`;

const metrics = [
  {
    name: 'Location Average Occupants',
    key: 'average_occupancy',
    type: 'location',
  },
  {
    name: 'Location Entries',
    key: 'entries',
    type: 'location',
  },
  {
    name: 'Area Occupancy',
    key: 'area_average_occupancy',
    type: 'area',
  },
  {
    name: 'Area Entries',
    key: 'area_entries',
    type: 'area',
  },
  {
    name: 'Internal Entries',
    key: 'internal_entries',
    type: 'within-location',
  },
];

// TODO: up to 15 minute granularity
const HeadcountPlanner = ({ locations, availableMetrics, isDemoUser }) => {
  const [period, setPeriod] = useState(
    PeriodModel.getExplorerPeriod(PERIOD_PRESETS.last4FullWeeks)
  );
  const [rawData, setRawData] = useState([]);
  const [smoothedData, setSmoothedData] = useState([]);
  const [normalisedData, setNormalisedData] = useState([]);
  const [multipliedData, setMultipliedData] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const [response, setResponse] = useState(null);
  const [showSteps, setShowSteps] = useState(false);
  const [totalOpenHours, setTotalOpenHours] = useState(0);
  const [headcountHours, setHeadcountHours] = useState(0);
  const [colHeadings, setColHeadings] = useState([]);
  const [windowSize, setWindowSize] = useState(5);
  const [includedDays, setIncludedDays] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [advancedOptionsVisible, setAdvancedOptionsVisible] = useState(false);

  const [dataAvailable, setDataAvailable] = useState(false);

  const [endDateCorrected, setEndDateCorrected] = useState(false);
  const [datesCorrected, setDatesCorrected] = useState(false);

  const updateTaxonomy = (taxonomy) => {
    setSelectedTaxonomy(taxonomy);
    setSelectedFilter(null);
  };

  const updateFilter = (filter) => {
    setSelectedTaxonomy(null);
    setSelectedFilter(filter);
  };

  const loadWidgetData = async (payloadInput, endpoint, metricKey) => {
    setError(false);
    setLoading(true);
    setDataAvailable(true);
    const queryPayloadType = getQueryPayloadType(endpoint, {
      url: endpoint,
    });

    try {
      const response = await fetchSQLQuery(
        buildPayload(queryPayloadType, payloadInput),
        endpoint,
        {
          returnErrors: true,
          return404AsError: false,
          metricKey,
        }
      );
      setLoading(false);
      setResponse(response);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  };

  const clearData = () => {
    setRawData([]);
    setSmoothedData([]);
    setNormalisedData([]);
    setMultipliedData([]);
  };

  useEffect(() => {
    if (selectedLocation && selectedMetric && period) {
      if (
        (selectedMetric.type === 'area' && !selectedTaxonomy) ||
        (selectedMetric.type === 'within-location' && !selectedFilter)
      ) {
        return;
      }

      // correct period to whole weeks
      let start = new moment(period.selectedDates.start);
      const startDay = start.day();
      let end = new moment(period.selectedDates.end);
      let endDay = end.day();
      if (startDay - endDay !== 1 && !(startDay === 0 && endDay === 6)) {
        // reduce the period by 1 day until it is the day of week before the start day of week
        while (startDay - endDay !== 1 && !(startDay === 0 && endDay === 6)) {
          end.subtract(1, 'days');
          endDay = end.day();
        }

        setEndDateCorrected(true);

        if (end <= start) {
          end = new moment(start).add(6, 'days');
        }

        if (end > new moment()) {
          end = new moment();
          start = new moment().subtract(6, 'days');
          setEndDateCorrected(false);
          setDatesCorrected(true);
        }

        const startString = start.format('YYYY-MM-DD');
        const endString = end.format('YYYY-MM-DD');

        setPeriod({
          selectedPreset: 'custom',
          selectedDates: {
            end: endString,
            start: startString,
          },
          customPeriodDates: {
            end: endString,
            start: startString,
          },
        });
        return;
      }

      const path = apiEndpointByMetricKey(selectedMetric.key);
      loadWidgetData(
        {
          period,
          locations: [selectedLocation.id],
          taxonomies: selectedTaxonomy
            ? [selectedTaxonomy]
            : selectedFilter
            ? [`${selectedFilter.key}:${selectedFilter.value}`]
            : undefined,
          aggregation: 'dayofweek-hourofday', // it has to be this aggregation type for this component to work
          demographicFilter: {
            role: 'customer',
          },
          breakdownByDimensions: ['entity'],
        },
        path,
        selectedMetric.key
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLocation,
    selectedTaxonomy,
    period,
    selectedMetric,
    selectedFilter,
  ]);

  useEffect(() => {
    if (response && selectedLocation) {
      // Separate overall, customer and staff values
      const segments = response && response.segments ? response.segments : [];
      const openingTimes = selectedLocation.openingTimes;
      const data = [];
      let earliestStart = 24;
      let latestEnd = 0;
      let totalOpenHours = 0;

      // For each day
      openingTimes.forEach((item) => {
        if (item.stopHour - item.startHour > 0) {
          const dayNum = item.day;
          const hours = Array.from(
            { length: item.stopHour - item.startHour },
            (v, k) => k + item.startHour
          );

          if (item.startHour < earliestStart) earliestStart = item.startHour;
          if (item.stopHour > latestEnd) latestEnd = item.stopHour;

          if (!Array.isArray(data[dayNum])) data[dayNum] = [];

          if (segments.length === 0) {
            hours.forEach((hour) => {
              data[dayNum][hour] = null;
            });
          } else {
            // Populate the hourly data
            hours.forEach((hour) => {
              const match = segments.find(
                (segment) =>
                  segment.index === `${dayNum}-${hour}`
              );
              if (
                match &&
                match.location !== null &&
                match[`sum_${selectedMetric.key}`] !== null
              ) {
                data[dayNum][hour] = match[`sum_${selectedMetric.key}`];
              } else {
                data[dayNum][hour] = 0; // to avoid empty cells from missing data
              }
            });
          }

          if (includedDays[item.day]) {
            totalOpenHours += hours.length;
          }
        }
      });

      if (
        data
          .filter((item) => item !== null)
          .filter(
            (item) =>
              Array.isArray(item) &&
              item.filter((hour) => hour !== null).length > 0
          ).length === 0
      ) {
        setDataAvailable(false);
        setRawData([]);
      } else {
        setDataAvailable(true);

        // Pad shorter days
        const paddedData = padData(data, earliestStart, latestEnd);

        const filteredData = paddedData.map((day, i) =>
          includedDays[i] ? day : day.map((hour) => null)
        );

        setTotalOpenHours(totalOpenHours);
        setRawData(filteredData);
        setColHeadings(
          Array.from(
            { length: latestEnd - earliestStart },
            (v, k) => k + earliestStart
          ).map((h) => `${h} - ${h + 1}`)
        );
      }
    }
  }, [
    headcountHours,
    includedDays,
    response,
    selectedLocation,
    selectedMetric,
  ]);

  // Allocate hours
  useEffect(() => {
    const allocatedHours = assignHours(normalisedData, headcountHours);
    setMultipliedData(allocatedHours);
  }, [headcountHours, normalisedData]);

  // Smooth data
  useEffect(() => {
    const smoothedData = rawData.map((day) => smoothData(day, windowSize));
    setSmoothedData(smoothedData);
  }, [rawData, windowSize]);

  // Normalise data
  useEffect(() => {
    const normalisedData = normaliseData(smoothedData);
    setNormalisedData(normalisedData);
  }, [smoothedData]);

  const sectionTitle = `${
    selectedLocation ? ` - ${selectedLocation.name}` : ''
  }${selectedTaxonomy ? ` - ${selectedTaxonomy}` : ''}${
    selectedFilter ? ` - ${selectedFilter.key}:${selectedFilter.value}` : ''
  }${selectedMetric ? ` - ${selectedMetric.name}` : ''}`;

  let subtitle = `${
    period.selectedPreset !== 'custom'
      ? `${defaultPeriodPresetConfig[period.selectedPreset].title} (${moment(
          period.selectedDates.start
        ).format(
          defaultPeriodPresetConfig[period.selectedPreset].format.startDate
        )} - ${moment(period.selectedDates.end).format(
          defaultPeriodPresetConfig[period.selectedPreset].format.endDate
        )})`
      : `${moment(period.selectedDates.start).format(
          defaultPeriodPresetConfig[period.selectedPreset].format.startDate
        )} - ${moment(period.selectedDates.end).format(
          defaultPeriodPresetConfig[period.selectedPreset].format.endDate
        )}`
  }`;

  return (
    <Wrapper>
      <StyledPanel>
        <StyledPanelHeader>
          <Options
            availableMetrics={availableMetrics}
            selectedTaxonomy={selectedTaxonomy}
            setSelectedTaxonomy={updateTaxonomy}
            selectedFilter={selectedFilter}
            setSelectedFilter={updateFilter}
            selectedLocation={selectedLocation}
            locations={locations}
            setResponse={setResponse}
            setSelectedLocation={setSelectedLocation}
            metrics={metrics}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            headcountHours={headcountHours}
            setHeadcountHours={setHeadcountHours}
            totalOpenHours={totalOpenHours}
            setAdvancedOptionsVisible={setAdvancedOptionsVisible}
            advancedOptionsVisible={advancedOptionsVisible}
            period={period}
            setPeriod={setPeriod}
            clearData={clearData}
            clearDateCorrected={() => {
              setEndDateCorrected(false);
              setDatesCorrected(false);
            }}
          />
          {endDateCorrected && (
            <Note intent={Intent.WARNING}>
              Note: your selected end date has been rounded down to the nearest
              whole week
            </Note>
          )}
          {datesCorrected && (
            <Note intent={Intent.WARNING}>
              Note: your selected dates have been rounded to the nearest whole
              week
            </Note>
          )}
        </StyledPanelHeader>
      </StyledPanel>
      {advancedOptionsVisible && (
        <StyledPanel>
          <StyledPanelHeader>
            <AdvancedOptions
              windowSize={windowSize}
              setWindowSize={setWindowSize}
              showSteps={showSteps}
              setShowSteps={setShowSteps}
              includedDays={includedDays}
              setIncludedDays={setIncludedDays}
            />
          </StyledPanelHeader>
        </StyledPanel>
      )}
      {(loading || error || multipliedData) && (
        <>
          {showSteps && (
            <>
              <WidgetWrapper
                title={`Raw Data${sectionTitle}`}
                subtitle={subtitle}
                data={rawData}
                colHeadings={colHeadings}
                loading={loading}
                error={error}
                colorScheme="reverseTrafficLight"
              />
              <WidgetWrapper
                title={`Smoothed Data${sectionTitle}`}
                subtitle={subtitle}
                data={smoothedData}
                colHeadings={colHeadings}
                loading={loading}
                error={error}
                colorScheme="reverseTrafficLight"
              />
              <WidgetWrapper
                title={`Normalised Data${sectionTitle}`}
                subtitle={subtitle}
                data={normalisedData}
                colHeadings={colHeadings}
                loading={loading}
                error={error}
                colorScheme="reverseTrafficLight"
              />
            </>
          )}
          <WidgetWrapper
            title={`Headcount${sectionTitle}`}
            subtitle={subtitle}
            data={multipliedData}
            colHeadings={colHeadings}
            loading={loading}
            error={error}
            colorScheme="reverseTrafficLight"
          />
        </>
      )}
      {!loading &&
        !error &&
        selectedLocation &&
        selectedMetric &&
        ((selectedMetric.type === 'area' && selectedTaxonomy) ||
          (selectedMetric.type === 'within-location' && selectedTaxonomy) ||
          selectedMetric.type === 'location') &&
        !dataAvailable && (
          <StyledPanel>
            <ErrorWrapper>
              <StyledIcon icon="issue" iconSize={22} />
              <div>
                There is no data available. There may not be available data for
                this location, the chosen area, or the specified date range.
              </div>
            </ErrorWrapper>
          </StyledPanel>
        )}
    </Wrapper>
  );
};

export default HeadcountPlanner;
