const KEY = 'aura-persisted-store';

export const persist = store => {
  try {
    const input = JSON.stringify(store);
    window.localStorage.setItem(KEY, input);
  } catch (error) {
    // do nothing
  }
};

export const hydrate = () => {
  try {
    const store = window.localStorage.getItem(KEY);
    return JSON.parse(store);
  } catch (error) {
    return null;
  }
};
