// @flow

import { THUMBNAILER_BASE } from '../config/vars';
import { errorToast } from '../utils/toaster';
import history from '../root/history';
import { LOGOUT } from '../constants/routes';
import { getAuth0Client } from '../services/auth0';

type callArgs = {
  path: string,
  fetchOptions?: Object,
  otherOptions?: Object,
  useBaseApi?: boolean
};

// TODO: move this method, and the one from api.js to a new file that can be shared
// BE CAREFUL with a difference at the end where you need to do res.blob() instead
// of res.json()
const call = async ({
  path,
  fetchOptions,
  otherOptions = {},
  useBaseApi = true
}: callArgs): Promise<any> => {
  const fetchOpts = {
    ...fetchOptions,
    headers: {
      'Content-Type': 'application/json',
      Authorization: ''
    }
  };

  const auth0Client = getAuth0Client();
  let url = path;
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    if (token) {
      fetchOpts.headers.Authorization = `Bearer ${token}`;
    }
  }

  if (useBaseApi) {
    url = THUMBNAILER_BASE + path;
  }

  return fetch(url, (fetchOpts: Object))
    .then(res => {
      if (res.ok) {
        return res;
      }

      // if response is 401 show a toast prompting the user to log back in
      if (res.status === 401 && !otherOptions.disable401Toast) {
        errorToast({
          message: 'Couldn\'t authenticate. You probably need to log back in',
          action: {
            text: 'Log in',
            onClick: e => history.push(LOGOUT)
          },
          timeout: 0
        });
      }

      const error: { response?: Object } = new Error(res.statusText);
      error.response = res;
      throw error;
    })
    .then(res => res.blob());
};

export const statElems = {
  getImage: (recordingId: string) =>
    call({
      path: `/v1/allStatElems/${recordingId}`,
      fetchOptions: {
        method: 'POST',
      }
    })
};

export const genericRequest = (path: string) =>
  call({
    path,
    fetchOptions: { method: 'GET' },
    useBaseApi: false
  });
