// @flow

import { Fragment } from 'react';
import SubMenuItemSection from './submenu-item-section';
import SubMenuHeader from './sub-menu-header';

import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';
import * as ActiveMetricsModel from '../../models/active-metrics';
import * as AvailableMetricsModel from '../../models/available-metrics';

export type subMenuConfigurationT = {
  taxonomies: string[],
  title: string,
  description: string,
  metrics: ActiveMetricsModel.metricT[],
  filters?: AvailableMetricsModel.filtersT,
  taxonomySelectLabel?: string,
};

type Props = {
  subMenuConfiguration: subMenuConfigurationT,
  icon: string,
  handleCurrentSubMenuOpen(name: string): void,
  currentSubMenuOpen: string,
  activeMetrics: ActiveMetricsModel.t,
  editActiveMetrics(config: editSummaryPageActiveMetricsArgsT): void,
  subMenuKey: string,
  showTaxonomyTagWarning: boolean,
};

const SubMenu = ({
  subMenuConfiguration,
  icon,
  handleCurrentSubMenuOpen,
  currentSubMenuOpen,
  activeMetrics,
  editActiveMetrics,
  subMenuKey,
  showTaxonomyTagWarning,
}: Props) => {
  if (!subMenuConfiguration) return null;
  const subMenu = activeMetrics && activeMetrics[subMenuKey];
  const tags =
    subMenu &&
    subMenu.metrics &&
    subMenu.metrics.filter(
      (metric) =>
        !!subMenuConfiguration.metrics.find((mec) => mec.key === metric)
    ).length;
  const areTaxonomiesAvailable =
    subMenuConfiguration &&
    subMenuConfiguration.taxonomies &&
    subMenuConfiguration.taxonomies.length > 0;
  const areFiltersAvailable = !!subMenuConfiguration.filters;
  const availableAndActiveTaxonomies = areTaxonomiesAvailable
    ? subMenu && subMenu.taxonomies && subMenu.taxonomies.length > 0
    : true;
  const availableAndActiveFilters = areFiltersAvailable
    ? subMenu && subMenu.filters && subMenu.filters.length > 0
    : true;
  const showSubMenuOpen = currentSubMenuOpen === subMenuConfiguration.title;

  return (
    <Fragment>
      <SubMenuHeader
        handleCurrentSubMenuOpen={handleCurrentSubMenuOpen}
        subMenuConfiguration={subMenuConfiguration}
        showSubMenuOpen={showSubMenuOpen}
        editActiveMetrics={editActiveMetrics}
        subMenuKey={subMenuKey}
        activeMetrics={activeMetrics}
        icon={icon}
        tags={tags}
        showTaxonomyTagWarning={showTaxonomyTagWarning}
      />
      {showSubMenuOpen &&
        availableAndActiveTaxonomies &&
        availableAndActiveFilters && (
          <SubMenuItemSection
            metrics={subMenuConfiguration.metrics}
            activeMetrics={activeMetrics}
            editActiveMetrics={editActiveMetrics}
            subMenuKey={subMenuKey}
          />
        )}
    </Fragment>
  );
};

export default SubMenu;
