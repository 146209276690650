// @flow

import { Fragment } from 'react';
import styled from 'styled-components';
import { Menu, MenuDivider, Popover, Button, Icon } from '@blueprintjs/core';
import { isEqual } from 'lodash';

import * as ActiveMetricsModel from '../../models/active-metrics';
import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';
import type { filterT } from '../../models/active-metrics';

const sortByKey = (a, b) => {
  const numberA = Number(a.key);
  const numberB = Number(b.key);

  if (!isNaN(numberA) && !isNaN(numberB)) {
    return numberA > numberB ? 1 : -1;
  }

  if (a.key > b.key) {
    return 1;
  }
  if (a.key < b.key) {
    return -1;
  }

  return 0;
};
const BASE_LEFT_PADDING = 0.5;
const IconActiveFilter = styled(Icon)`
  &&& {
    margin-left: auto;
    max-height: 13px;
    color: rgba(0, 0, 0, 0.5);
  }
`;
export const MenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  padding-left: ${({ space }) =>
    space < 1 ? space : 1 * space + BASE_LEFT_PADDING}em;
  padding-right: ${BASE_LEFT_PADDING}em;
  &:hover {
    cursor: pointer;
    background-color: rgba(167, 182, 194, 0.3);
  }
`;
const MenuStyled = styled(Menu)`
  max-height: 80vh;
  overflow-y: auto;
`;

type Props = {
  availableFilterList: ActiveMetricsModel.filterConfgObjtT[],
  editActiveMetrics(config: editSummaryPageActiveMetricsArgsT): void,
  subMenuKey: string,
  activeMetrics: ActiveMetricsModel.t,
  selectedFilter?: ActiveMetricsModel.filterT,
};

const isFilterActive = (
  activeMetrics: ActiveMetricsModel.t,
  subMenuKey: string,
  filter: filterT
): boolean => {
  const result =
    activeMetrics[subMenuKey] &&
    activeMetrics[subMenuKey].filters &&
    !!activeMetrics[subMenuKey].filters.find((activeFilter) =>
      isEqual(activeFilter, filter)
    );
  return result || false;
};

const displayFilters = (
  childFilters: string[],
  subMenuKey: string,
  activeMetrics: ActiveMetricsModel.t,
  editActiveMetrics: (config: editSummaryPageActiveMetricsArgsT) => void,
  index: number,
  filter: string,
  numLevelsDeep: number
) => {
  return (
    childFilters &&
    childFilters.length > 0 &&
    childFilters.sort().map((childFilter, idx) => {
      const isActive = isFilterActive(activeMetrics, subMenuKey, {
        key: filter,
        value: childFilter,
      });
      const key = `${filter}-${index}-${childFilter}-${idx}`;
      const headerConfig = {
        subMenuKey,
        filter: {
          key: filter,
          value: childFilter,
        },
      };

      return (
        <Fragment key={key}>
          <MenuItemStyled
            space={numLevelsDeep}
            onClick={() => editActiveMetrics(headerConfig)}
          >
            {childFilter}
            {isActive && <IconActiveFilter icon="tick" />}
          </MenuItemStyled>
        </Fragment>
      );
    })
  );
};

const FilterMenu = ({
  availableFilterList,
  editActiveMetrics,
  subMenuKey,
  activeMetrics,
  selectedFilter,
}: Props) => {
  const showMenuDivider = (index) => index !== availableFilterList.length - 1;

  return (
    <Popover usePortal={false}>
      <Button icon="exchange">{selectedFilter ? `${selectedFilter.key}:${selectedFilter.value}` : 'Filter'}</Button>
      <MenuStyled>
        {availableFilterList.sort(sortByKey).map(({ key, values }, index) => (
          <Fragment key={key}>
            <MenuItemStyled space={BASE_LEFT_PADDING}>
              {key}
              {isFilterActive(activeMetrics, subMenuKey, {
                key: subMenuKey,
                value: key,
              }) && <IconActiveFilter icon="tick" />}
            </MenuItemStyled>
            {displayFilters(
              values,
              subMenuKey,
              activeMetrics,
              editActiveMetrics,
              index,
              key,
              1
            )}
            {showMenuDivider(index) && <MenuDivider />}
          </Fragment>
        ))}
      </MenuStyled>
    </Popover>
  );
};

export default FilterMenu;
