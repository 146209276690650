// @flow

import styled from 'styled-components';
import { Card, Icon, Button } from '@blueprintjs/core';
import InformationSection from './information-section';

import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';
import * as ActiveMetricsModel from '../../models/active-metrics';

const MetricItemIcon = styled(Icon)`
  margin-right: 0.5em;
`;
export const MetricItemButton = styled(Button)`
  margin: 0.5em;

  span {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    label {
      position: relative;
      top: 50%;
      transform: translate(0, 25%);
    }
  }
`;
const WrapperButtonContent = styled.div`
  display: flex;
`;
const StyledCard = styled(Card)`
  border-radius: 0;
`;

type Props = {
  metrics: ActiveMetricsModel.metricT[],
  activeMetrics: ActiveMetricsModel.t,
  editActiveMetrics(config: editSummaryPageActiveMetricsArgsT): void,
  subMenuKey: string
};

const SubMenuItemSection = ({
  metrics,
  activeMetrics,
  editActiveMetrics,
  subMenuKey
}: Props) => {
  const isCheckBoxActive = key =>
    !!activeMetrics &&
    !!activeMetrics[subMenuKey] &&
    !!activeMetrics[subMenuKey].metrics &&
    !!activeMetrics[subMenuKey].metrics.find(metric => metric === key);

  return (
    <StyledCard>
      {metrics &&
        metrics.map(({ label, key, description }) => {
          const headerConfig = {
            subMenuKey,
            metricElementKey: key
          };

          return (
            <MetricItemButton
              key={key}
              onClick={() => editActiveMetrics(headerConfig)}
            >
              <MetricItemIcon
                icon={isCheckBoxActive(key) ? 'small-tick' : 'blank'}
                color={isCheckBoxActive(key) ? 'green' : undefined}
              />
              <WrapperButtonContent>
                <div>{label}</div>
                <InformationSection description={description} />
              </WrapperButtonContent>
            </MetricItemButton>
          );
        })}
    </StyledCard>
  );
};

export default SubMenuItemSection;
