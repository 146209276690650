import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';

export const InactiveButton = styled.div`
  background: var(--button-inactive-background);
  border: var(--button-border);
  color: var(--button-inactive-text);
  flex: 0 0 auto;
  position: relative;
  margin-right: -1px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
  padding: 5px 16px 5px 12px;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
`;

export const InactiveButtonIcon = styled(Icon)`
  padding-right: 6px;
  opacity: 0.7;
`;
