// @flow

import { useState, useEffect, Fragment } from 'react';

import {
  WidgetGrid,
  WidgetCells,
  WidgetBox,
  WidgetOverflow,
  WidgetBodyWrapper,
  MetricTitle
} from '../common/styles';
import WidgetWrapper from '../common/widget-wrapper';
import WidgetHeader from '../common/widget-header';
import RankBar from '../common/rank-bar';

import type { unitTypeT } from '../../../models/query-response';

// TODO widgetPropsT is duplicated becase importing shared types doesn't display in prop table: https://github.com/storybookjs/storybook/issues/11289
type widgetPropsT = {
  /** The title of the chart */
  title: string,
  /** The subtitle of the chart */
  subtitle: string,
  /** The Description to display in the Tooltip */
  description: string,
  /** Displays the loading state */
  loading?: boolean,
  /** Displays the error state */
  errors?: boolean
};

type Props = widgetPropsT & {
  unitType?: unitTypeT,
  /** What to show as a value next to the bar; if true it will show the actual value, otherwise it uses the relative percentage value */
  /** The data to display */
  rows: {
    age: string,
    female: {
      value: number,
      changeValue: number,
      barPercentage?: number,
      valuePercentage?: number
    },
    male: {
      value: number,
      changeValue: number,
      barPercentage?: number,
      valuePercentage?: number
    }
  }[]
};

const DemographicRank = ({
  title,
  subtitle,
  description,
  rows,
  unitType,
  loading,
  errors
}: Props) => {
  const [extendedRows, setExtendedRows] = useState([]);

  useEffect(() => {
    if (rows && rows.length) {
      const sumOfAllValues = rows.reduce(
        (items, item) => items + item.female.value + item.male.value,
        0
      );

      const arrayOfAllValues = rows.reduce((items, item) => {
        items.push(item.female.value, item.male.value);
        return items;
      }, []);

      const computeValuePercentage = (value: number) =>
        Math.round((100 * value * 100) / sumOfAllValues) / 100;

      const computeBarPercentage = (value: number) =>
        Math.round((100 * value * 100) / Math.max(...arrayOfAllValues)) / 100;

      setExtendedRows(
        rows.map((metric, index) => {
          return {
            ...metric,
            female: {
              ...metric.female,
              barPercentage: computeBarPercentage(metric.female.value),
              valuePercentage: computeValuePercentage(metric.female.value)
            },
            male: {
              ...metric.male,
              barPercentage: computeBarPercentage(metric.male.value),
              valuePercentage: computeValuePercentage(metric.male.value)
            }
          };
        })
      );
    }
  }, [rows]);

  return (
    <WidgetWrapper
      loading={loading}
      errors={errors}
      header={
        <WidgetHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
      }
    >
      <WidgetBodyWrapper>
        <WidgetOverflow>
          <WidgetGrid>
            <span />
            <WidgetCells>
              <MetricTitle align="right">Female</MetricTitle>
              <MetricTitle>Male</MetricTitle>
            </WidgetCells>
            {extendedRows.map((metric, index) => {
              const { age, female, male } = metric;
              return (
                <Fragment key={index}>
                  <MetricTitle align="right" whiteSpace="nowrap">
                    <WidgetBox align="flex-end" hasBorder={false}>
                      {age}
                    </WidgetBox>
                  </MetricTitle>
                  <WidgetCells>
                    <RankBar
                      align="right"
                      value={
                        unitType === 'percentage'
                          ? female.valuePercentage
                          : female.value
                      }
                      unitType={unitType ? unitType : 'peopleCount'}
                      changeValue={female.changeValue}
                      barWidth={female.barPercentage}
                    />
                    <RankBar
                      value={
                        unitType === 'percentage' ? male.valuePercentage : male.value
                      }
                      unitType={unitType ? unitType : 'peopleCount'}
                      changeValue={male.changeValue}
                      barWidth={male.barPercentage}
                    />
                  </WidgetCells>
                </Fragment>
              );
            })}
          </WidgetGrid>
        </WidgetOverflow>
      </WidgetBodyWrapper>
    </WidgetWrapper>
  );
};

export default DemographicRank;
