// @flow

import { Component } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import {
  Menu,
  MenuItem,
  Divider,
  Button,
  Popover,
  ButtonGroup,
  Icon
} from '@blueprintjs/core';
import { InactiveButton } from '../inactive-button';
import { getColumnLabel } from '../../utils/metricsTableHelpers';

import * as ActiveMetricsModel from '../../models/active-metrics';
import * as AvailableMetricsModel from '../../models/available-metrics';

export const InverMetricsButton = styled(Button)`
  margin-right: auto;
`;
const StyledIcon = styled(Icon)`
  margin-left: 2rem;
`;

type Props = {
  activeMetrics: ActiveMetricsModel.t,
  availableMetrics: AvailableMetricsModel.t,
  metricsSelectedForComparison: Array<ActiveMetricsModel.itemT>,
  locationsSelectedForComparison: string[],
  redirectSelectedMetricToExplorerPage: (viewCombined?: boolean) => void,
  toggleMetric: (metric: ActiveMetricsModel.itemT) => void,
  disabled: boolean
};

type State = {
  isMenuOpen: boolean,
  locationGroupingType: 'combined' | 'separate'
};

export default class MetricsDropdownMenu extends Component<Props, State> {
  state = {
    isMenuOpen: false,
    locationGroupingType: 'combined'
  };

  handleIsMenuOpen = (isOpen: boolean) => {
    this.setState({
      isMenuOpen: isOpen
    });
  };

  redirectToExplorerPage = (viewCombined?: boolean = false) => {
    const { redirectSelectedMetricToExplorerPage } = this.props;

    redirectSelectedMetricToExplorerPage(viewCombined);
  };

  setLocationGroupingType(locationGroupingType: 'combined' | 'separate') {
    this.setState({ locationGroupingType });
  }

  render() {
    const { isMenuOpen, locationGroupingType } = this.state;
    const {
      activeMetrics,
      availableMetrics,
      metricsSelectedForComparison,
      locationsSelectedForComparison,
      toggleMetric,
      disabled
    } = this.props;
    const activeMetricsHeadings = ActiveMetricsModel.getColumnsFromActiveMetrics(
      activeMetrics
    );

    const numSelectedLocations = locationsSelectedForComparison.length;

    return (
      <ButtonGroup style={{display: disabled ? 'none' : 'inline-flex'}}>
        {numSelectedLocations > 0 && (
          <InactiveButton>
            {locationsSelectedForComparison.length} location
            {numSelectedLocations > 1 ? 's' : ''} selected
          </InactiveButton>
        )}
        <Popover
          isOpen={isMenuOpen}
          onClose={() => this.handleIsMenuOpen(false)}
        >
          <Button
            rightIcon="chevron-down"
            onClick={() => this.handleIsMenuOpen(true)}
            disabled={disabled}
          >
            Compare
          </Button>
          <Menu>
            {activeMetricsHeadings.map((activeMetric, index) => {
              const key = `${activeMetric.metricGroupKey}-${activeMetric.metricKey}-${index}`;
              const isMetricActive = !!metricsSelectedForComparison.find(sM =>
                isEqual(sM, activeMetric)
              );
              return (
                <MenuItem
                  shouldDismissPopover={false}
                  label={
                    isMetricActive ? (
                      <StyledIcon icon="tick" />
                    ) : (
                      <StyledIcon icon="blank" />
                    )
                  }
                  key={key}
                  text={getColumnLabel(
                    activeMetric,
                    availableMetrics,
                    true,
                    true
                  )}
                  onClick={() => toggleMetric(activeMetric)}
                />
              );
            })}
            <Divider />
            {numSelectedLocations > 1 && [
              <ButtonGroup key="1">
                <Button
                  active={locationGroupingType === 'combined'}
                  onClick={e => this.setLocationGroupingType('combined')}
                >
                  Combine locations
                </Button>
                <Button
                  active={locationGroupingType === 'separate'}
                  onClick={e => this.setLocationGroupingType('separate')}
                >
                  Separate locations
                </Button>
              </ButtonGroup>,
              <Divider key="2" />
            ]}
            <Button
              intent="primary"
              fill
              icon="series-search"
              onClick={() =>
                this.redirectToExplorerPage(locationGroupingType === 'combined')
              }
            >
              Compare
            </Button>
          </Menu>
        </Popover>
      </ButtonGroup>
    );
  }
}
