// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import * as QueryResponseList from '../models/query-response-list';

export type ReducerType = QueryResponseList.t;

const INITIAL_STATE = [];

export default function queryResponsesListReducer(
  state: ReducerType = INITIAL_STATE,
  { type, payload }: FluxStandardAction<string, *>
): ReducerType {
  switch (type) {
    case ACTIONS.CLEAR_QUERY_RESPONSE_LIST:
      return [];

    case ACTIONS.FETCH_QUERY_DATA_SUCCESS: {
      const {
        res,
        locations,
        metricGroupKey,
        metricKey,
        taxonomies,
        isComparison,
        isAllLocations,
        period,
        aggregation,
        excludeStaff,
        breakdownByDimensions
      } = payload;
      const queryResponseItem = {
        excludeStaff,
        locations,
        metricGroupKey,
        metricKey,
        taxonomies,
        isComparison,
        isAllLocations,
        period,
        aggregation,
        breakdownByDimensions,
        data: res
      };
      const newState = [...state, queryResponseItem];

      return newState;
    }

    case ACTIONS.FETCH_QUERY_DATA_METRICS_ERROR: {
      const {
        res,
        locations,
        metricGroupKey,
        metricKey,
        taxonomies,
        isAllLocations,
        isComparison,
        period,
        aggregation,
        excludeStaff,
        breakdownByDimensions
      } = payload;
      const queryResponseItem = {
        locations,
        metricGroupKey,
        metricKey,
        taxonomies,
        error: res,
        isComparison,
        isAllLocations,
        aggregation,
        period,
        excludeStaff,
        breakdownByDimensions
      };
      const newState = [...state, queryResponseItem];

      return newState;
    }

    default:
      return state;
  }
}
