import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  background: var(--main-background);
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
`;

const EmailLayout = ({ children }) => <Wrapper>{children}</Wrapper>;

export default EmailLayout;
