// @flow
export const apiEndpoints = {
  // LOCATION
  ENTRIES: 'entries',
  PASSERS_BY: 'passers-by',
  AVERAGE_DWELL_TIME: 'average-dwell-time',
  AVERAGE_OCCUPANCY: 'average-occupancy',
  CAPTURE_RATE: 'capture-rate',

  // AREA
  AREA_TOTAL_DWELL_TIME: 'area/data',
  AREA_AVERAGE_DWELL_TIME: 'area/data',
  AREA_ENTRIES: 'area/data',
  AREA_ENTRIES_PER_LOCATION_ENTRANT: 'area/entries-per-location-entrant',
  AREA_DWELL_PER_LOCATION_ENTRANT: 'area/dwell-per-location-entrant',
  AREA_MAX_OCCUPANCY: 'area/max-occupancy',
  AREA_AVERAGE_OCCUPANCY: 'area/average-occupancy',
  AREA_UTILISATION: 'area/utilisation',

  // TRANSACTIONS
  SALES_TRANSACTION_COUNT: 'transactions/data',
  SALES_VOLUME: 'transactions/data',
  SALES_ATV: 'transactions/data',
  SALES_UNITS: 'transactions/data',
  SALES_UPT: 'transactions/data',
  SALES_CONVERSION_RATE: 'transactions/conversion-rate',

  // INTERNAL
  INTERNAL_LINE_CROSSINGS: 'internal/line-crossings',
  INTERNAL_ENTRIES: 'internal/line-entries',
  INTERNAL_PASSERS_BY: 'internal/line-passers-by',
  INTERNAL_CAPTURE_RATE: 'internal/capture-rate',
};

export const apiEndpointByMetricKey = (id: string): string =>
  apiEndpoints[id.split('__')[0].toUpperCase()]
    ? apiEndpoints[id.split('__')[0].toUpperCase()]
    : '';
