// @flow

import { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { get, uniq } from 'lodash';
import DownloadOptions from '../../download-options';
import { ButtonGroup } from '@blueprintjs/core';
import { Panel, PanelBody, PanelHeader } from '../../panel';
import GraphTile from '../../graph-tile';
import TopBarLoading from '../../top-bar-loading';
import Layout from '../../layouts/default-logged-in';
import MultipleMetricsManager from '../../multiple-metrics-manager';
import DemographicBreakdownPicker from '../../pickers/demographic-breakdown';
import ChartTypePicker from '../../pickers/chart-type';
import AggregationPicker from '../../pickers/aggregation';
import AxisGroupingPicker from '../../pickers/axisGrouping';
import {
  getAggreationsButtonsBlocked,
  getChartTypeButtonsBlocked,
} from '../../../utils/pickerHelpers';
import {
  CHART_TYPE,
  BREAKDOWN_BY,
  AGGREGATION_PERIOD,
} from '../../../constants/graph-options';
import * as ROUTES from '../../../constants/routes';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';
import {
  convertFromUrlHashToObject,
  convertFromObjectToUrlHash,
} from '../../../utils/urlHelpers';
import { getGraphConfig } from '../../../utils/graphHelpers';
import NoAreasPanel from '../../no-areas-panel';
import DateAndComparisonDateSelectors from '../../date-and-comparison-date-selector';
import { explorerPagePeriodPresetConfig } from '../../date-and-comparison-date-selector/dates-presets-config';

import * as PeriodModel from '../../../models/period';
import * as ActiveMetricModel from '../../../models/active-metrics';
import * as LocationModel from '../../../models/location';
import * as RecordingModel from '../../../models/recording';
import * as PageSettingsModel from '../../../models/page-settings';
import * as QueryResponseListModel from '../../../models/query-response-list';
import * as QueryApiRequestModel from '../../../models/query-api-request';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import type {
  breakdownByEnum,
  chartTypeEnum,
  aggregationEnum,
  axisGroupingEnum,
} from '../../../constants/graph-options';
import type { BrowserHistory } from 'history/createBrowserHistory';
import type { queryWrapperT } from '../../../actions/query-responses';
import { breakpoints } from '../../../styles/variables';
import { GENDERS, ROLES, SORTED_AGE_KEYS } from '../../../constants/demographic-types';

const BodySection = styled.div`
  flex: 1;
`;
const StyledPanel = styled(Panel)`
  margin: 1rem 0;
  overflow: hidden;
  box-shadow: var(--widget-box-shadow);

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: 1280px) {
    justify-content: center;
  }

  .bp3-button-group .bp3-popover-wrapper {
    flex: none;
  }
`;
const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
`;
const Col = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledCol = styled(Col)``;
const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: auto;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    margin-left: 0;
    flex-wrap: wrap;

    .bp3-button {
      margin-bottom: 0.5rem;
    }
  }
`;

type Props = {
  locations: LocationModel.t[],
  fetchingQueryData: boolean,
  organisationName: string,
  settings: PageSettingsModel.explorerT,
  showStaffFilters: boolean,
  recordings: RecordingModel.t[],
  history: BrowserHistory,
  editSettings(settings: PageSettingsModel.explorerT): void,
  fetchQueryResponses: (queryWrapper: queryWrapperT) => void,
  queryResponseList: QueryResponseListModel.t,
  editMetrics(metric: PageSettingsModel.metricItemT): void,
  availableMetrics: AvailableMetricsModel.t,
  theme: Object,
};

class ExplorerPage extends Component<Props> {
  componentDidMount() {
    trackEvent(ENTER_PAGE, 'EXPLORER_METRICS');

    const { settings, editSettings } = this.props;

    this.props.history.listen((location, action) => {
      const path = this.getPath();
      const hash = this.getHash();

      if (path === ROUTES.EXPLORER && hash) {
        this.updateStateFromUrl(hash);
      }
    });

    const hash = this.getHash();

    if (hash) {
      const data = convertFromUrlHashToObject(hash);

      editSettings({
        ...data,
      });
      this.fetchAllQueryResponses(data);
    } else {
      this.updateHashUrl(settings);
    }
  }

  handleGenericUpdate = (
    state: PageSettingsModel.explorerT,
    updateQueryResponseList: boolean
  ) => {
    const { editSettings } = this.props;

    if (state.metrics.length > 1) {
      state.breakdownBy = BREAKDOWN_BY.TOTAL_ONLY;
    }

    if (state.chartType === CHART_TYPE.REL_BAR && state.metrics.length !== 1) {
      state.chartType = CHART_TYPE.LINE;
    }

    editSettings(state);
    this.updateHashUrl(state);
    if (updateQueryResponseList) {
      this.fetchAllQueryResponses(state);
    }
  };

  getHash(): string {
    return get(this.props, 'history.location.hash', '');
  }

  getPath(): string {
    return get(this.props, 'history.location.pathname', '');
  }

  updateHashUrl = (state: PageSettingsModel.explorerT) => {
    const hash = convertFromObjectToUrlHash(state);

    this.props.history.push(hash);
  };

  updateStateFromUrl = (hash: string) => {
    const { editSettings } = this.props;
    const data = convertFromUrlHashToObject(hash);

    editSettings({
      ...data,
    });
  };

  handlePeriodChange = (
    period: PeriodModel.t,
    compareToPast: PeriodModel.comparePeriodT
  ) => {
    const { settings } = this.props;
    const { aggregation: currentAggregation } = settings;
    const aggregationsButtonsBlocked =
      period && getAggreationsButtonsBlocked(period);
    const aggregation = aggregationsButtonsBlocked.includes(currentAggregation)
      ? AGGREGATION_PERIOD.DAY
      : currentAggregation;

    this.handleGenericUpdate(
      {
        ...settings,
        aggregation,
        compareToPast,
        period,
      },
      true
    );
  };

  handleCompareToPastPeriod = (period: PeriodModel.comparePeriodT) => {
    const { settings } = this.props;

    this.handleGenericUpdate(
      {
        ...settings,
        compareToPast: period,
      },
      true
    );
  };

  changeBreakdownBy = (breakdownBy: breakdownByEnum) => {
    const { settings } = this.props;

    this.handleGenericUpdate(
      {
        ...settings,
        breakdownBy,
      },
      true
    );
  };

  changeAggregationPeriod = (aggregation: aggregationEnum) => {
    const { settings } = this.props;

    this.handleGenericUpdate(
      {
        ...settings,
        aggregation,
      },
      true
    );
  };

  changeAxisGrouping = (axisGrouping: axisGroupingEnum) => {
    const { settings } = this.props;

    this.handleGenericUpdate(
      {
        ...settings,
        axisGrouping,
      },
      false
    );
  };

  changeChartType = (chartType: chartTypeEnum) => {
    const { settings } = this.props;

    this.handleGenericUpdate(
      {
        ...settings,
        chartType,
      },
      false
    );
  };

  fetchQueryResponses(
    period: QueryApiRequestModel.periodT,
    isComparisonPeriod: boolean,
    settings: PageSettingsModel.explorerT
  ) {
    const { fetchQueryResponses } = this.props;
    const { metrics, aggregation } = settings;

    metrics.forEach((metric) => {
      const { location, demographicFilter } = metric;
      const activeMetrics = ActiveMetricModel.createActiveMetricsFromMetric(
        metric.metric
      );

      const excludeStaff =
        (demographicFilter && demographicFilter.role === 'customer') ||
        !this.props.showStaffFilters;

      let breakdownBy =
        settings.breakdownBy && settings.breakdownBy !== 'total_only'
          ? [settings.breakdownBy]
          : undefined;
      if (['location', 'organisation'].includes(settings.breakdownBy)) {
        breakdownBy = ['entity'];
      }

      const queryWrapper = {
        queryTracking: {
          excludeStaff,
          period,
          locations: location,
          ages:
            settings.breakdownBy === 'age'
              ? SORTED_AGE_KEYS
              : undefined,
          genders:
            settings.breakdownBy === 'gender' ? GENDERS : undefined,
          roles:
            settings.breakdownBy === 'role' ? ROLES : undefined,
          aggregation,
          breakdownByDimensions: breakdownBy,
        },
        extraMetaConfig: {
          activeMetrics,
          isAllLocations: false,
          isComparison: isComparisonPeriod,
        },
      };

      fetchQueryResponses(queryWrapper);
    });
  }

  fetchAllQueryResponses(settings: PageSettingsModel.explorerT) {
    const { period, compareToPast } = settings;

    // Fetch current set days
    this.fetchQueryResponses(period.selectedDates, false, settings);

    // Fetch comparison days
    if (compareToPast.active && compareToPast.selectedDates) {
      this.fetchQueryResponses(compareToPast.selectedDates, true, settings);
    }
  }

  render() {
    const {
      fetchingQueryData,
      settings,
      availableMetrics,
      locations,
      showStaffFilters,
      queryResponseList,
      theme,
    } = this.props;
    const {
      period,
      compareToPast,
      breakdownBy,
      aggregation,
      axisGrouping,
      chartType,
      metrics = [],
    } = settings;

    const { activeLines, chartData, pcChartData } = getGraphConfig(
      {
        metrics,
        availableMetrics,
        queryResponseList,
        period,
        aggregation,
        chartType,
        breakdownBy,
        compareToPast,
        locations,
        showStaffFilters,
      },
      theme
    );

    const aggregationsButtonsBlocked =
      period && getAggreationsButtonsBlocked(period);
    const chartTypeButtonsBlocked = getChartTypeButtonsBlocked(metrics);
    const displayBreakdownTooltipInformation =
      chartType &&
      chartType === CHART_TYPE.REL_BAR &&
      breakdownBy === BREAKDOWN_BY.TOTAL_ONLY;

    return (
      <Layout>
        <BodySection>
          <StyledPanel>
            <StyledPanelHeader>
              <Title>Metrics</Title>
              {fetchingQueryData && <TopBarLoading />}
            </StyledPanelHeader>
            <MultipleMetricsManager
              handleGenericUpdate={this.handleGenericUpdate.bind(this)}
              fetchingQueryData={fetchingQueryData}
              availableMetrics={availableMetrics}
              locations={locations}
              settings={settings}
              showStaffFilters={showStaffFilters}
              queryResponseList={queryResponseList}
            />
          </StyledPanel>
          <StyledPanel>
            <StyledPanelHeader>
              <StyledCol>
                <DateAndComparisonDateSelectors
                  currentSelectedDatePreset={settings.period.selectedPreset}
                  handlePresetClick={this.handlePeriodChange.bind(this)}
                  currentSelectedComparisonDatePreset={
                    settings.compareToPast.active &&
                    settings.compareToPast.selectedPreset
                      ? settings.compareToPast.selectedPreset
                      : 'none'
                  }
                  handleComparisonPresetClick={this.handleCompareToPastPeriod.bind(
                    this
                  )}
                  period={period}
                  compareToPastPeriod={settings.compareToPast}
                  periodPresetConfig={explorerPagePeriodPresetConfig}
                  disableCompareDatesView
                />
              </StyledCol>
              <StyledCol>
                {breakdownBy && (
                  <DemographicBreakdownPicker
                    selected={breakdownBy}
                    onChange={this.changeBreakdownBy.bind(this)}
                    showRole={showStaffFilters}
                    showLocations={true}
                    disabled={metrics.length > 1}
                    disabledMessage="To breakdown by demographic, select only one metric"
                    chartType={chartType}
                    recommendBreakdownByChange={
                      displayBreakdownTooltipInformation
                    }
                  />
                )}
              </StyledCol>
            </StyledPanelHeader>
          </StyledPanel>
          <StyledPanel>
            <StyledPanelHeader>
              <StyledButtonGroup>
                {chartType === CHART_TYPE.LINE &&
                  uniq(activeLines.map((l) => l.axisKey)).length > 1 && (
                    <AxisGroupingPicker
                      selected={axisGrouping}
                      onChange={this.changeAxisGrouping.bind(this)}
                      loading={fetchingQueryData}
                    />
                  )}
                {aggregation && chartType && (
                  <AggregationPicker
                    selected={aggregation}
                    onChange={this.changeAggregationPeriod.bind(this)}
                    aggregationCombined={chartType === CHART_TYPE.PIE}
                    blocked={aggregationsButtonsBlocked}
                    loading={fetchingQueryData}
                  />
                )}
                {chartType && (
                  <ChartTypePicker
                    selected={chartType}
                    onChange={this.changeChartType.bind(this)}
                    disabledChartTypes={chartTypeButtonsBlocked}
                  />
                )}
              </StyledButtonGroup>
            </StyledPanelHeader>
            {metrics.length > 0 ? (
              <>
                <PanelBody>
                  <GraphTile
                    chartData={chartData}
                    pcChartData={pcChartData}
                    activeLines={activeLines}
                    chartType={chartType}
                    aggregationPeriod={aggregation}
                    axisGrouping={axisGrouping}
                    isCompareToPastActive={
                      compareToPast && compareToPast.active
                    }
                  />
                </PanelBody>
                <DownloadOptions
                  filename={`${period.selectedDates.start}-${period.selectedDates.end}`}
                  chartData={chartData}
                  pcChartData={pcChartData}
                  chartType={chartType}
                  aggregationPeriod={aggregation}
                  locations={locations}
                  loading={fetchingQueryData}
                />
              </>
            ) : (
              <NoAreasPanel message="Add some metrics to start graphing data" />
            )}
          </StyledPanel>
        </BodySection>
      </Layout>
    );
  }
}

export default withTheme(ExplorerPage);
