import { Slider } from '@blueprintjs/core';
import styled from 'styled-components';
import { getDeviceType } from '../../utils/device-type';

const DateSlider = styled(Slider)`
  max-width: 80%;
  margin: 1rem auto;
`;

const DateSelector = ({ dates, selectedDate, setSelectedDate }) => (
  <DateSlider
    min={0}
    max={dates.length - 1}
    labelRenderer={(value) => {
      if (getDeviceType() === 'desktop') {
        return dates[value];
      }
        return dates[value].split('/').slice(0, 2).join('/');
    }}
    value={dates.indexOf(selectedDate)}
    showTrackFill={false}
    onChange={(value) => setSelectedDate(dates[value])}
  />
);

export default DateSelector;
