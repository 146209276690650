// @flow

import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { prettyPercentage } from '@auravisionlabs/aura-pkg-ui-formatter';
import { prettyValueToCompare } from '../../utils/summaryPageHelpers';

type Props = {
  valueToCompare: number,
  value: number
};
const ComparisonCellWrapper = styled.div`
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  font-weight: 100;
  margin-top: 0.5rem;
  color: ${({ color }) => (color ? color : '')};
`;
const StyledIcon = styled(Icon)`
  max-height: 0.9rem;
  margin-right: 0.4rem;
`;

const comparisonCell = ({ valueToCompare, value }: Props) => {
  const { result } = prettyValueToCompare(valueToCompare, value);

  if (!result) return null;

  return (
    <ComparisonCellWrapper color={result > 0 ? 'var(--positive)' : 'var(--negative)'}>
      <StyledIcon
        icon={result > 0 ? 'trending-up' : 'trending-down'}
        color={result > 0 ? 'var(--positive)' : 'var(--negative)'}
      />
      {prettyPercentage(result, 1, '-')}
    </ComparisonCellWrapper>
  );
};

export default comparisonCell;
