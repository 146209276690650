// @flow

export const lastFullDay = 'lastFullDay';
export const last7Days = 'last7Days';
export const lastFullWeek = 'lastFullWeek';
export const lastFullUSWeek = 'lastFullUSWeek';
export const weekToDate = 'weekToDate';
export const last4FullWeeks = 'last4FullWeeks';
export const last4FullUSWeeks = 'last4FullUSWeeks';
export const last30Days = 'last30Days';
export const lastFullMonth = 'lastFullMonth';
export const monthToDate = 'monthToDate';
export const last90Days = 'last90Days';
export const last3FullMonths = 'last3FullMonths';
export const lastFullQuarter = 'lastFullQuarter';
export const quarterToDate = 'quarterToDate';
export const last12Months = 'last12Months';
export const lastFullYear = 'lastFullYear';
export const yearToDate = 'yearToDate';
export const custom = 'custom';

export type periodPresetsEnum =
  | 'lastFullDay'
  | 'last7Days'
  | 'lastFullWeek'
  | 'lastFullUSWeek'
  | 'weekToDate'
  | 'last4FullWeeks'
  | 'last4FullUSWeeks'
  | 'last30Days'
  | 'lastFullMonth'
  | 'monthToDate'
  | 'last90Days'
  | 'last3FullMonths'
  | 'lastFullQuarter'
  | 'quarterToDate'
  | 'last12Months'
  | 'lastFullYear'
  | 'yearToDate'
  | 'custom';
