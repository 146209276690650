// NOTE: these are all VERY hardcoded. The IDs and names must match exactly
// those coming from the DB. This mapping is used to provide relevant labels
// and descriptions, and directions to the front end on how it should handle/
// render each role. IF A ROLE ID/NAME EVER CHANGES ON THE API, THIS WILL NEED
// TO CHANGE TOO. The long term alternative is to build this information into
// the Authorisation API so it's all managed in one place

export const ROLES = {
  DATA_VIEWER: {
    id: 201,
    label: 'View data',
    match: 'Analytics data viewer',
    description: 'View charts and reports on the dashboard',
    showAsOption: true,
    required: true
  },
  MANAGE_REPORTS: {
    id: 208,
    label: 'Manage reports',
    match: 'Analytics data viewer - manage reports',
    description: 'Create, edit and delete reports and emails',
    showAsOption: true,
    required: false
  },
  MANAGE_LOCATIONS: {
    id: 211,
    label: 'Manage locations',
    match: 'Client Admin - manage locations',
    description: 'Edit location details',
    showAsOption: true,
    required: false
  },
  AUTH_ADMIN: {
    id: 2,
    label: 'Manage users',
    match: 'Client Admin - Auth Service Administration',
    description: 'Invite, edit and remove other users',
    showAsOption: true,
    required: false
  },
  AUTH_NORMAL: {
    id: 3,
    label: 'Normal',
    match: 'Data Viewer - Auth Service Access',
    description: '',
    showAsOption: false,
    required: true
  }
};
