// @flow

import { call } from './http';
import { EMAILER_API_BASE } from '../config/vars';

export const emailer = {
  adHocReport: (payload: string): Promise<Object> => call({
    path: `${EMAILER_API_BASE}/reports/run`,
    fetchOptions: { method: 'POST', body: JSON.stringify(payload) },
    otherOptions: { disableErrorToasts: true, disable401Toast: true }
  }),
};
