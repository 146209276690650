// @flow

import _ from 'lodash';
import * as LocationModel from './location';

export type errorT = {
  status: number,
  name: string,
  message: string,
};

export type demographicsDescriptorT = {
  age: string,
  gender: string,
  role: string,
};

export type categoriesT = {
  [string]: demographicsDescriptorT,
};

export type matchingAreasT = {
  areaId: string,
  recording: string,
  taxonomy?: string,
};

export type unitTypeT = 'percentage' | 'seconds' | 'peopleCount' | 'number';

export type metaT = {
  query: {
    aggregationType: string,
    excludeStaff: boolean,
    locations: {
      include: string[],
    },
    period: {
      start: string,
      end: string,
    },
    type: string,
  },
  matches: {
    locations: {
      count: number,
      items: LocationModel.t[],
    },
    areas: {
      count: number,
      items: matchingAreasT[],
    },
  },
  units?: Object,
};

export type AgeSplitsT = {
  [string]: number,
};

export type TaxonomySplitsT = {
  [string]: number,
};

export type LocationSplitsT = {
  [string]: number,
};

export type GenderSplitsT = {
  female: number,
  male: number,
};

export type RoleSplitsT = {
  customer: number,
  staff: number,
};

export type splitsT = {
  age?: AgeSplitsT,
  gender?: GenderSplitsT,
  role?: RoleSplitsT,
  location?: LocationSplitsT,
  taxonomy?: TaxonomySplitsT,
  total?: number,
};

export type segmentsItemT = {
  index?: string,
  organisation?: string,
  location?: string,
  recording?: string,
  age?: string,
  role?: string,
  gender?: string,
  taxonomy?: string,
  total?: string,
};

export type segmentsT = segmentsItemT[];

export type thumbnailsT = {
  url: string,
  recording_id: string,
  location_id: string,
}[];

export type t = {
  meta?: metaT,
  segments?: segmentsT,
  summary?: segmentsT,
  thumbnails?: thumbnailsT,
};

export const getSummaryValue = ({
  response,
  metricKey,
  role,
  age,
  gender,
  taxonomy,
  locationId,
}: {
  response: t,
  metricKey: string,
  role?: string,
  age?: string,
  gender?: string,
  taxonomy?: string,
  locationId?: string,
}): number | null => {
  if (response && response.summary && response.summary[0]) {
    if (
      (response.summary[0].age && !age) ||
      (response.summary[0].gender && !gender) ||
      (response.summary[0].role && !role) ||
      (response.summary[0].taxonomy && !taxonomy) ||
      (response.summary[0].location && !locationId)
    ) {
      throw new Error('Cannot return a value without a breakdown');
    }

    const found = response.summary.find(
      (item) =>
        (role ? item.role === role : true) &&
        (age ? item.age === age : true) &&
        (gender ? item.gender === gender : true) &&
        (taxonomy ? item.taxonomy === taxonomy : true) &&
        (locationId ? item.location === locationId : true)
    );

    if (found && found[metricKey.split(':')[0]]) {
      return found[metricKey.split(':')[0]];
    }

    return null;
  }
  return null;
};

const allFacets = ['age', 'gender', 'role', 'taxonomy', 'location'];
export const getSummaryByBreakdown = ({
  response,
  metricKey,
  filterBy,
}: {
  response: t,
  metricKey: string,
  filterBy?: {
    role?: string,
    age?: string,
    gender?: string,
    taxonomy?: string,
    location?: string,
  },
}): splitsT => {
  const splits: splitsT= {};

  let filterByFacets = [];
  let facets = allFacets;

  if (filterBy) {
    filterByFacets = allFacets.filter((facet) => !!filterBy[facet]);
    facets = allFacets.filter((facet) => !filterBy[facet]);
  }

  if (response && response.summary && response.summary.length) {
    response.summary
      .filter(
        filterBy
          ? (item) =>
              filterByFacets.every((facet) => item[facet] === filterBy[facet])
          : () => true
      )
      .forEach((item) => {
        if (item) {
          const itemFacets = _.intersection(Object.keys(item), facets);

          facets.forEach((key) => {
            if (key) {
              const otherFacets = itemFacets.filter((facet) => facet !== key);

              const multipleFacets = otherFacets.length > 1;

              if (!multipleFacets) {
                if (!splits[key]) {
                  splits[key] = {};
                }
                if (item[key]) {
                  const itemKey = item[key];
                  // $FlowFixMe
                  splits[key][itemKey] = item[metricKey.split(':')[0]];
                }
              } else {
                // TODO: recursive split building for more than 2 facets
                console.error(
                  'Splits by more than one facet are not supported'
                );
              }
            }
          });
          if (facets.every((facet) => !item[facet])) {
            splits.total = item[metricKey.split(':')[0]];
          }
        }
      });
  }
  return splits;
};

export const getSegmentOverallValue = (
  segment?: segmentsItemT,
  metricKey: string
): number =>
  segment && segment[metricKey.split(':')[0]]
    ? segment[metricKey.split(':')[0]]
    : 0;

export const getSegments = (obj: t): segmentsT => obj.segments || [];

export const getUnitType = (obj: t, field: string): unitTypeT =>
  obj && obj.meta && obj.meta.units && obj.meta.units[field]
    ? obj.meta.units[field]
    : 'number';

export const getPreviews = (response: t): string[] =>
  response.thumbnails ? response.thumbnails.map((t) => t.url) : [];

export const getPreviewsByLocation = (
  response: t,
  location: string
): string[] =>
  response.thumbnails
    ? response.thumbnails
        .filter((t) => t.location_id === location)
        .map((t) => t.url)
    : [];

export const getPreviewsByRecording = (
  response: t,
  recording: string
): string[] =>
  response.thumbnails
    ? response.thumbnails
        .filter((t) => t.recording_id === recording)
        .map((t) => t.url)
    : [];
