// @flow

import { SplitFactory } from '@splitsoftware/splitio';
import { ALL_FEATURES_LIST } from '../constants/features';
import { SPLIT_API_KEY, IS_EMAIL_VIEW } from '../config/vars';
import * as UserModel from '../models/user';

let splitClient;

export const getFeatures = (user: Object): UserModel.featuresT => {
  if (typeof splitClient !== 'undefined') {
    const attributes = {
      organisationName: user.organisation.name,
    };

    return splitClient.getTreatments(ALL_FEATURES_LIST, attributes);
  }

  return {};
};

export const splitInit = () => {
  let config = {
    core: {
      authorizationKey: SPLIT_API_KEY,
      key: '2', // TODO: what does this need to be/should be?
    },
    startup: {
      readyTimeout: 2.5,
    },
  };

  if (IS_EMAIL_VIEW) {
    config = {
      ...config,
      startup: {
        eventsFirstPushWindow: 6000,
      },
      scheduler: {
        featuresRefreshRate: 6000,
        segmentsRefreshRate: 6000,
        metricsRefreshRate: 6000,
        impressionsRefreshRate: 6000,
        eventsPushRate: 6000,
      },
    };
  }

  const factory = SplitFactory(config);
  splitClient = factory.client();
  return splitClient;
};
