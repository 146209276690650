import { Fragment } from 'react';
import styled from 'styled-components';
import { Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import {
  prettyNumber,
  prettyPercentage,
  prettyTime,
  prettyLongNumber
} from '@auravisionlabs/aura-pkg-ui-formatter';

const Wrapper = styled.div`
  display: flex;
  height: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  &&& {
    width: 100% !important;
  }
`;
// this is a bit of a hack to make the popover wrapper element use full width of the table cell
const TooltipWithFullWidth = styled(Tooltip2)`
  width: 100%;
`;
const LineColor = styled.div`
  background: ${props => props.color};
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 8px;
`;
const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;
const TooltipRowKey = styled.strong`
  padding-right: 14px;
`;
const Bar = styled.div`
  width: ${props => props.width};
  background: ${props => props.color};
`;

const BarPieChart = ({ data }) => {
  const getDataWithPercentages = data => {
    const total = data.reduce((acc, curItem) => acc + curItem.value, 0);
    return data.map(item => ({
      ...item,
      pc: item.value / total
    }));
  };

  const enhancedData = getDataWithPercentages(data);

  const tooltipContent = (
    <Fragment>
      {enhancedData.map(item => {
        let value;
        switch (item.unitType) {
          case 'percentage':
            value = prettyPercentage(item.value, 2, '-');
            break;
          case 'seconds':
            value = prettyTime(item.value);
            break;
          case 'peopleCount':
          case 'number':
            value = prettyLongNumber(item.value);
            break;
          default:
            value = prettyNumber(item.value);
        }
        return (
          <TooltipRow key={item.key}>
            <LineColor color={item.color} />
            <TooltipRowKey>{item.label}</TooltipRowKey> {value} (
            {prettyPercentage(item.pc * 100, 1)})
          </TooltipRow>
        );
      })}
    </Fragment>
  );

  return (
    <TooltipWithFullWidth
      content={tooltipContent}
      hoverOpenDelay={0}
      position={Position.LEFT}
    >
      <Wrapper>
        {enhancedData.map(item => (
          <Bar key={item.key} width={`${item.pc * 100}%`} color={item.color} />
        ))}
      </Wrapper>
    </TooltipWithFullWidth>
  );
};

export default BarPieChart;
