// @flow


import KpiWidget from '../../widgets/kpi';
import RankWidget from '../../widgets/rank';
import DemographicRankWidget from '../../widgets/demographic-rank';
import ChartWidget from '../../widgets/chart';
import CalendarHeatmapWidget from '../../widgets/calendar-heatmap';
import DateHeatmapWidget from '../../widgets/date-heatmap';
import MetricHeatmapWidget from '../../widgets/metric-heatmap';
import MapWidget from '../../widgets/map';
import PeakTimeHeatmapWidget from '../../widgets/peak-time-heatmap';
import * as WidgetPropsModel from '../../../models/widget-props';

type Props = {
  widgetType: WidgetPropsModel.widgetTypeT
};

const WidgetRenderer = ({ widgetType, ...props }: Props) => {
  const getComponent = (widgetType: WidgetPropsModel.widgetTypeT) => {
    switch (widgetType) {
      case 'kpi':
        return KpiWidget;
      case 'rank':
        return RankWidget;
      case 'demographicRank':
        return DemographicRankWidget;
      case 'chart':
        return ChartWidget;
      case 'calendarHeatmap':
        return CalendarHeatmapWidget;
      case 'dateHeatmap':
        return DateHeatmapWidget;
      case 'metricHeatmap':
        return MetricHeatmapWidget;
      case 'map':
        return MapWidget;
      case 'peakTimeHeatmap':
        return PeakTimeHeatmapWidget;
      default:
        return false;
    }
  };

  const WidgetComponent = getComponent(widgetType);

  if (WidgetComponent) {
    // $FlowIgnore - props are unknown as they are based on the widget type
    return <WidgetComponent {...props} />;
  }

  return null;
};

export default WidgetRenderer;
